import React, { useEffect } from "react";
import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  Text,
  Area,
  AreaChart,
} from "recharts";
import { formatDate, formatDateXAxis } from "./util";
export default function GroupLineChart({
  lineData,
  colors,
  fullScreen,
  occLine,
}) {
  const [maxHeight, setMaxHeight] = useState(1);
  useEffect(() => {
    var height = 1;
    lineData.map((item) => {
      if (Number(item[occLine]) > height) {
        height = Number(item[occLine]);
      }
    });
    setMaxHeight(Math.ceil(height));
    // console.log("max Height", Math.ceil(height));
  }, [lineData]);

  const [graphWidth, setGraphWidth] = useState();
  useEffect(() => {
    var screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (fullScreen) {
      setGraphWidth(Math.round((screenWidth * 44) / 100));
    } else {
      setGraphWidth(Math.round((screenWidth * 35) / 100));
    }
  }, [fullScreen]);

  return (
    <AreaChart
      width={graphWidth}
      height={300}
      data={lineData?.length > 0 && lineData}
      margin={{
        top: 20,
      }}
    >
      {lineData.length < 1 && <CartesianGrid strokeDasharray="3 3" />}
      {lineData.length < 1 && (
        <g transform={`translate(${graphWidth / 2},${300 / 2})`}>
          <Text textAnchor="middle" verticalAnchor="middle">
            No data available
          </Text>
        </g>
      )}

      <XAxis
        dataKey="date"
        padding={{ left: 30, right: 30 }}
        tick={{ fontSize: 12, fill: "black", angle: -15, textAnchor: "end" }}
        tickFormatter={formatDateXAxis}
      />
      <YAxis
        domain={[0, maxHeight]}
        label={{
          value: `${occLine == "Room sold" ? "Room sold" : "Occupancy (%)"}`,
          angle: -90,
          position: "insideLeft",
        }}
      />
      <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
      {/* <Legend /> */}
      <Area
        type="monotone"
        dataKey={occLine}
        stroke={colors[3]}
        fillOpacity={1}
        fill={`url(#${"occLine"})`}
      />
      <defs>
        <linearGradient id={"occLine"} x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor={colors[3]} stopOpacity={0.8} />
          <stop offset="95%" stopColor={colors[3]} stopOpacity={0} />
        </linearGradient>
      </defs>
    </AreaChart>
  );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${formatDate(label)}`}</p>
        <div>
          {payload.map((pld) => (
            <div style={{ display: "inline-block", padding: 5 }}>
              <div style={{ color: "black" }}>
                {pld?.dataKey} : {pld?.value}
                {pld?.dataKey === "Occupancy" && "%"}
              </div>
              {/* <div style={{ color: "gray" }}> drivers</div> */}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};
