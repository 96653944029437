import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "material-react-toastify";
import React, { useState, useEffect } from "react";
import { handleSpringDataMutationRequest } from "../../../api";
import { getAllImagesAndUrl } from "../../../pages/BookingDashboard/apis/BrandingModalApi";
import axios from "axios";

function PaymentConfirmation() {
  const url = new URL(window.location.href);
  const paymentId = url.searchParams.get("paymentId");
  const bookingId = url.searchParams.get("bookingId");
  const paymentType = url.searchParams.get("paymentType");
  const [remark, setremark] = useState("");
  const [counter, setcounter] = useState("0");

  const [ImageLogo, setImageLogo] = useState(null);
  const [loader, setloader] = useState(false);
  const getAllData = async () => {
    try {
      const data = await getAllImagesAndUrl();
      setImageLogo(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const save = (checked) => {
    if (remark === "") {
      toast.error("Please enter remark");
    } else if (remark.length > 200) {
      toast.error("Please enter less than 200 characters in remark");
    } else {
      setloader(true);
      axios
        .post(
          `${process.env.REACT_APP_SPRING_API_URL}web-payment-api/approve-reject-payment?status=${checked}&id=${paymentId}&paymentType=${paymentType}&bookingId=${bookingId}`,
          { remark: remark }
        )
        .then((res) => {
          toast.success("Successfull");
          setcounter(checked);
          setloader(false);
        })
        .catch((err) => {
          toast.error(err.response.data);
          setloader(false);
        });
    }

    // .then((resp) => resp.data)
    // .catch((err) => {
    //   // throw new Error(err);
    //   return err;
    // });
    // handleSpringDataMutationRequest(
    //   "POST",
    //   `web-payment-api/approve-reject-payment?status=${checked}&id=${paymentId}&paymentType=${paymentType}`,
    //   { remark: remark }
    // )
    //   .then((res) => {
    //     toast.success("Successfull");
    //     setloader(false);
    //   })
    //   .catch((err) => {
    //     toast.error("Something went wrong");
    //     setloader(false);
    //   });
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        backgroundColor: "#ffffff",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        wid
      />

      <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
        <img
          style={{
            maxHeight: "100px",
            maxWidth: "200px",
            cursor: "pointer",
          }}
          src={ImageLogo?.logoUrl}
        ></img>
      </Grid>
      {counter === "YES" && (
        <div
          style={{
            marginLeft: "2rem",
            marginRight: "2rem",
            marginTop: "1rem",
          }}
        >
          <h3>Approved Successfully</h3>
        </div>
      )}
      {counter === "NO" && (
        <div
          style={{
            marginLeft: "2rem",
            marginRight: "2rem",
            marginTop: "1rem",
          }}
        >
          <h3>Rejected Successfully</h3>
        </div>
      )}

      {counter === "0" && (
        <>
          <div
            style={{
              marginLeft: "2rem",
              marginRight: "2rem",
              marginTop: "1rem",
            }}
          >
            <div className="d-flex w-75" style={{ marginBottom: "2rem" }}>
              <div className="d-flex w-50">
                <h3>Booking id : </h3>
                <h3>{bookingId}</h3>
              </div>
            </div>
            <h3>Remark</h3>
            <TextField
              label="Remark"
              className="input-lg w-75 mt-3"
              placeholder="Enter remark here"
              onChange={(e) => setremark(e.target.value)}
              value={remark}
              name="Remark"
              id="Remark"
            />
          </div>
          <div className="w-100" style={{ marginTop: "2rem" }}>
            <Button
              variant="custom-button"
              style={{
                marginLeft: "2rem",
                float: "right",
                marginRight: "29rem",
              }}
              onClick={() => save("YES")}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                "Approve"
              )}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => save("NO")}
              style={{ marginLeft: "2rem", float: "right" }}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                "Reject"
              )}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default PaymentConfirmation;
