import {
  Stack,
  Button,
  Box,
  ClickAwayListener,
  Divider,
  Typography,
  TextField,
} from "@mui/material";
import { toast } from "material-react-toastify";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { djangoHandleDataRequests } from "../../../../api";
import ShopWrapper from "../ShopWrapper";
import moment from "moment";
import { DateRange } from "react-date-range";
import { SecondaryButton } from "../../../../Utils/ButtonHelper";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import ReportsLoader from "../../../../Utils/ReportsLoader";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const CategorySummaryReport = ({ data }) => {
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  // getting the hotel currency
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId } = useParams();

  // Getting the start date as well as the end date to load the data
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [searchInp, setSearchInp] = useState("");
  // Get All report data
  const [reportsData, setreportsData] = useState([]);
  useEffect(() => {
    getAllRestaurantReportData();
  }, []);

  //Function to get report data
  const getAllRestaurantReportData = () => {
    // setrowsdats(null);
    setreportsData(null);
    shopId &&
      djangoHandleDataRequests(
        `pos/CategorySummaryReport/?shop_id=${shopId}&start_date=${moment(
          dateRange[0].startDate
        ).format("YYYY-MM-DD 00:00:00")}&end_date=${moment(
          dateRange[0].endDate
        ).format("YYYY-MM-DD 23:59:59")}&hotel_id=${hotelId}`
      )
        .then((res) => {
          setreportsData(res);
          // setrowsdats(productsGenerator(res.length, res));
        })
        .catch((err) =>
          toast.error("Error in getting  data! Please try again later.")
        );
  };

  //Rows data generator
  // const productsGenerator = (quantity, data) => {
  //   let updatedData = data?.map(
  //     (item) => item?.payment_type.length > 0 && item
  //   );
  //   const items = [];
  //   for (let i = 0; i < quantity; i++) {
  //     items.push({
  //       shop_name: updatedData[i].shop_name,
  //       date: updatedData[i].date,
  //       time_stamp: updatedData[i].time_stamp,
  //       payment_type: updatedData[i].payment_type,
  //       product_name: updatedData[i].product_name,
  //       product_price: updatedData[i].product_price,
  //       product_qty: updatedData[i].product_qty,
  //       sub_total: updatedData[i].sub_total,
  //       total_discount: updatedData[i].total_discount,
  //       total_tax: updatedData[i].total_tax,
  //       total: updatedData[i].total,
  //       area: updatedData[i].area,
  //       table_name: updatedData[i].table_name,
  //       room_id:
  //         String(updatedData[i].room_id) === "-1" ? "" : updatedData[i].room_id,
  //       table_staff: updatedData[i].table_staff,
  //       product_category_name: updatedData[i].product_category_name,
  //       product_hsn_code: updatedData[i].product_hsn_code,
  //     });
  //   }
  //   return items;
  // };

  //open and close date picker
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  //columns of table
  const [rowsdats, setrowsdats] = useState(null);
  const [reportsColumns, setreportsColumns] = useState([]);

  // Report columns
  const columns = [
    {
      dataField: "shop_name",
      text: "Shop name",
      sort: true,
    },
    {
      dataField: "time_stamp",
      text: "Date & time",
      sort: true,
    },
    // {
    //   dataField:"payment_type",
    //   text:"Payment type",
    //   sort:true
    // },
    {
      dataField: "product_category_name",
      text: "Item category",
      sort: true,
    },
    {
      dataField: "product_name",
      text: "Item name",
      sort: true,
    },
    {
      dataField: "product_price",
      text: `Item price (${hotelCurrency})`,
      sort: true,
    },
    {
      dataField: "product_qty",
      text: `Item qty`,
      sort: true,
    },
    {
      dataField: "sub_total",
      text: `Subtotal`,
      sort: true,
    },
    {
      dataField: "total_discount",
      text: `Total discount (${hotelCurrency})`,
      sort: true,
    },
    {
      dataField: "total_tax",
      text: `Total tax (${hotelCurrency})`,
      sort: true,
    },
    {
      dataField: "total",
      text: `Total (${hotelCurrency})`,
      sort: true,
    },
    {
      dataField: "table_name",
      text: `Table`,
    },
    {
      dataField: "room_id",
      text: `Room ID`,
    },
    {
      dataField: "table_staff",
      text: `Staff name`,
    },
    {
      dataField: "product_hsn_code",
      text: `Item HSN code`,
    },
  ];

  // useEffect(() => {
  //   const names = [
  //     "Shop Name",
  //     "Date & Time",
  //     "Payment type",
  //     "Item category",
  //     "Item name",
  //     `Item price (${hotelCurrency})`,
  //     "Item qty",
  //     `Subtotal (${hotelCurrency})`,
  //     `Total dicount (${hotelCurrency})`,
  //     `Total tax (${hotelCurrency})`,
  //     `Total (${hotelCurrency})`,
  //     "Area",
  //     "Table number",
  //     "Room id",
  //     "Staff name",
  //     "Item hsn code",
  //   ];
  //   const array = [
  //     "shop_name",
  //     "time_stamp",
  //     "payment_type",
  //     "product_category_name",
  //     "product_name",
  //     "product_price",
  //     "product_qty",
  //     "sub_total",
  //     "total_discount",
  //     "total_tax",
  //     "total",
  //     "area",
  //     "table_name",
  //     "room_id",
  //     "table_staff",
  //     "product_hsn_code",
  //   ];
  //   // if (reportsData?.length > 0) {
  //   let arr = [];
  //   // const array = Object.keys(reportsData[0]);
  //   for (var i = 0; i < array?.length; i++) {
  //     arr.push({
  //       dataField: array[i],
  //       text: names[i],
  //       sort: true,
  //       style: {
  //         wordWrap: "break-word",
  //         overflow: "hidden",
  //       },
  //       headerStyle: {
  //         wordWrap: "break-word",
  //         overflow: "hidden",
  //       },
  //     });
  //   }

  //   const payment_type_index = arr.findIndex(
  //     (ob) => ob.dataField.toLowerCase() === "payment_type"
  //   );
  //   arr.splice(payment_type_index, 1);
  //   const area_index = arr.findIndex(
  //     (ob) => ob.dataField.toLowerCase() === "area"
  //   );
  //   arr.splice(area_index, 1);

  //   setreportsColumns(arr);
  //   // }
  // }, [reportsData]);

  return (
    <ShopWrapper
      backBtn={true}
      contentArea={
        <>
          <h1 className="mt-3 mb-3">Item wise category summary report</h1>
          <Divider className="mt-2 mb-2" />
          {reportsData ? (
            <div className="App">
              {/* {reportsColumns.length > 0 && ( */}
              <ToolkitProvider
                keyField="id"
                data={reportsData}
                columns={columns}
                exportCSV
                search
                noDataIndication="No data found"
              >
                {(props) => (
                  <>
                    <Stack
                      direction="row"
                      className="position-relative w-100 justify-content-between mt-4"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <Stack
                        className="position-relative w-50"
                        direction="row"
                        spacing={2}
                      >
                        <SearchBar
                          {...props.searchProps}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        />
                        <Stack spacing={4} className="position-relative">
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <Box sx={{ position: "relative" }}>
                              <button
                                onClick={handleClick}
                                className="border border-1 rounded p-2"
                                style={{
                                  backgroundColor: "transparent",
                                  height: "37.5px",
                                }}
                              >
                                {moment(dateRange[0].startDate).format(
                                  "DD MMM YYYY"
                                )}{" "}
                                {" to "}
                                {moment(dateRange[0].endDate).format(
                                  "DD MMM YYYY"
                                )}
                              </button>
                              {open ? (
                                <Box sx={styles}>
                                  <Stack spacing={3}>
                                    <DateRange
                                      editableDateInputs={true}
                                      onChange={(item) =>
                                        setDateRange([item.selection])
                                      }
                                      moveRangeOnFirstSelection={false}
                                      ranges={dateRange}
                                    />
                                    <Stack
                                      className="position-relative w-100 d-flex justify-content-between"
                                      direction="row"
                                    >
                                      <SecondaryButton
                                        text="Cancel"
                                        onClick={() => setOpen(false)}
                                      />
                                      <Button
                                        variant="custom-button"
                                        onClick={() => {
                                          getAllRestaurantReportData();
                                          handleClickAway();
                                        }}
                                      >
                                        Apply
                                      </Button>
                                    </Stack>
                                  </Stack>
                                </Box>
                              ) : null}
                            </Box>
                          </ClickAwayListener>
                        </Stack>
                      </Stack>
                      <ExportCSVButton
                        {...props.csvProps}
                        style={{
                          display: "block",
                          background: "black",
                          color: "white",
                          marginTop: -1.5,
                          marginBottom: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        Export CSV
                      </ExportCSVButton>
                    </Stack>
                    <Stack className="mt-4">
                      {reportsData.length > 0 ? (
                        <BootstrapTable
                          pagination={paginationFactory({ sizePerPage: 10 })}
                          {...props.baseProps}
                        />
                      ) : (
                        <Typography
                          className="position-relative mt-8 text-align-center"
                          style={{
                            fontSize: "15px",
                            marginTop: "15px",
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          No data found!
                        </Typography>
                      )}
                    </Stack>
                  </>
                )}
              </ToolkitProvider>
              {/* )} */}
            </div>
          ) : (
            <ReportsLoader />
          )}
        </>
      }
    />
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(CategorySummaryReport);
