// ** React Imports
import React from "react";
import {Button, CircularProgress, Divider, Drawer, Grid, Hidden, IconButton, Typography,} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Close} from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  drawer: {
    height: "max-content",
    marginLeft: "auto",
    flexShrink: 0,
    width: "45%",
    ["@media (min-width:481px) and (max-width:768px)"]: {
      width: "55%",
    },
    ["@media (min-width:769px)"]: {
      width: "45%",
    },
    ["@media (min-width:320px) and (max-width:480px)"]: {
      width: "70%",
    },
  },
  drawerPaper: {
    width: "45%",
    marginLeft: "auto",
    ["@media (min-width:481px) and (max-width:768px)"]: {
      width: "55%",
    },
    ["@media (min-width:769px)"]: {
      width: "45%",
    },
    ["@media (min-width:320px) and (max-width:480px)"]: {
      width: "70%",
    },
  },
}));

const ConfirmationModal = (props) => {
  // ** Props
  const classes = useStyles();
  const {
    onClose,
    open,
    onBackdropClick,
    title,
    confirmAction,
    loading,
  } = props;

  return (
    <Hidden implementation="css">
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={"bottom"}
        onBackdropClick={() => {
          if (!onBackdropClick) {
            return;
          }
          onClose();
        }}
        open={open}
        onClose={() => {
          if (!onClose) {
            return;
          }
          onClose();
        }}
        transitionDuration={{ enter: 250, exit: 250 }}
      >
        <Grid container padding={2}>
          <Grid container item xs={12}>
            <Grid item marginRight={"auto"}>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{ width: "inherit", marginBottom: 1, marginTop: 1 }} />
          <Grid container item xs={12}>
            {props.displayData}
          </Grid>
          <Divider sx={{ width: "inherit", marginBottom: 1, marginTop: 1 }} />
          <Grid container item xs={12}>
            <Grid container justifyContent={"flex-end"} item>
              <Button
                color="error"
                variant="outlined"
                sx={{ marginRight: 2 }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                disabled={loading}
                variant="custom-button"
                onClick={confirmAction}
              >
                Confirm
                {loading && (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </Hidden>
  );
};

export default ConfirmationModal;
