import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalTitle, ModalBody } from "react-bootstrap";
import "./SupportStyle.css";
import {
  Divider,
  Grid,
  IconButton,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Button,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./ReferalModal.css";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../../../app/api";
import { toast } from "material-react-toastify";

const ReferalModal = (props) => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [hotelName, sethotelName] = useState("");
  const [hotelAddress, sethotelAddress] = useState("");
  const [loader, setloader] = useState(false);
  const [getAllReferal, setgetAllReferal] = useState([]);
  const [referalLists, setreferalLists] = useState("DISABLE");
  const submitReferal = () => {
    if (name === "") {
      toast.error("Please enter name");
    } else if (email === "") {
      toast.error("Please enter email");
    } else if (phone === "") {
      toast.error("Please enter phone number");
    } else if (hotelName === "") {
      toast.error("Please enter hotel name");
    } else if (hotelAddress === "") {
      toast.error("Please enter hotel city");
    } else {
      const payload = {
        id: -1,
        referrerHotelId: props?.data?.hotelId,
        email: email,
        phone: phone,
        name: name,
        hotelName: hotelName,
        hotelAddress: hotelAddress,
        code: "",
        status: "INVITED",
        credited: "NO",
        referredHotelId: "",
      };
      setloader(true);
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/referral/create`,
        payload
      )
        .then((res) => {
          getAllData();
          toast.success("Successfully referred");
          sethotelAddress("");
          sethotelName("");
          setphone("");
          setemail("");
          setname("");
          setreferalLists("DISABLE");
          setloader(false);
        })
        .catch((err) => {
          toast.error("Already Referred");
          setloader(false);
          setreferalLists("DISABLE");
        });
    }
  };

  const getAllData = () => {
    handleSpringDataRequest("core/api/v1/referral/get-all")
      .then((res) => {
        setgetAllReferal(res);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllData();
  }, []);

  const onToggleList = (e) => {
    if (referalLists === "DISABLE") {
      setreferalLists("ENABLE");
    } else if (referalLists === "ENABLE") {
      setreferalLists("DISABLE");
    }
  };
 
  return (
    <div>
      <Modal
        show={props?.show}
        onHide={props?.hide}
        dialogClassName={"myModalBody65333"}
        contentClassName={"myModalContent323233"}
      >
        <ModalTitle
          style={{
            fontSize: "1.5rem",
            marginLeft: "0.5rem",
            marginTop: "1.5rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              style={{ fontWeight: "bold", paddingLeft: "31px" }}
            >
              Referrals
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton style={{ marginLeft: "2rem" }} onClick={props?.hide}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </ModalTitle>
        <Divider style={{ marginTop: "0.5rem" }} />
        <ModalBody>
          {" "}
          <div className="d-flex w-100">
            <div
              className="d-flex flex-column"
              style={{ width: "49%", marginRight: "2%" }}
            >
              <h1>Earn more rewards with referrals</h1>
              {props?.data?.hotelCurrency === "INR" && (
                <p>
                  <strong>Rs.2000</strong> for every successful referral on
                  indian property
                </p>
              )}
              {props?.data?.hotelCurrency !== "INR" && (
                <p>
                  <strong>$200</strong> for every successful referral of property
                </p>
              )}
              <p>1. Submit referral</p>
              <p>2. Check the status</p>
              <p>{`3. Claim the ${
                props?.data?.hotelCurrency === "INR" ? "Rs.2000" : "$200"
              } reward upon successful onboarding`}</p>
            </div>
            <div className="d-flex flex-column" style={{ width: "49%" }}>
              <div className="d-flex align-items-center justify-content-between">
                <h3>Invite fellow hoteliers</h3>
                <Button
                  variant="custom-button"
                  onClick={submitReferal}
                  disabled={loader}
                >
                  {loader ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
              <div className="d-flex">
                <TextField
                  label="Name"
                  className="input-lg w-100 mt-3  mr-2"
                  placeholder="Enter name here"
                  onChange={(e) => setname(e.target.value)}
                  value={name}
                  name="Name"
                  id="Name"
                />
                <TextField
                  label="Phone"
                  className="input-lg w-100 mt-3"
                  placeholder="Enter phone number here"
                  onChange={(e) => setphone(e.target.value)}
                  value={phone}
                  name="phone"
                  id="phone"
                />
              </div>
              <TextField
                label="Email"
                className="input-lg w-100 mt-3"
                placeholder="Enter email here"
                onChange={(e) => setemail(e.target.value)}
                value={email}
                name="email"
                id="email"
              />
              <div className="d-flex">
                <TextField
                  label="Hotel Name"
                  className="input-lg w-100 mt-3 mr-2"
                  placeholder="Enter hotel name here"
                  onChange={(e) => sethotelName(e.target.value)}
                  value={hotelName}
                  name="hotelName"
                  id="hotelName"
                />
                <TextField
                  label="Hotel city"
                  className="input-lg w-100 mt-3"
                  placeholder="Enter hotel city here"
                  onChange={(e) => sethotelAddress(e.target.value)}
                  value={hotelAddress}
                  name="hotelAddress"
                  id="hotelAddress"
                />
              </div>
            </div>
          </div>
          <img
            src={
              props?.data?.hotelCurrency === "INR"
                ? "https://prop-images.s3.us-east-2.amazonaws.com/bookingChannels/indiaReferal.png"
                : "https://prop-images.s3.us-east-2.amazonaws.com/bookingChannels/usreferal.png"
            }
            style={{
              width: "100%",
              height: "40%",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
            alt="fireSpot"
          />
          {getAllReferal?.length > 0 &&
            getAllReferal?.map((num) => (
              <Card
                variant="outlined"
                style={{
                  borderRadius: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <CardContent>
                  <div className="d-flex justify-content-between">
                    <h4>{num.hotelName}</h4>
                    {num.status === "ACTIVE" && (
                      <p
                        className="badge badge-info"
                        //   style={styles.discountColor}
                      >
                        {num?.status}
                      </p>
                    )}
                    {num.status === "INPROGRESS" && (
                      <p
                        className="badge badge-warning"
                        //   style={styles.inActive}
                      >
                        {num?.status}
                      </p>
                    )}
                     {num.status === "CREDITED" && (
                      <p
                        className="badge badge-success"
                        //   style={styles.discountColor}
                      >
                        {num?.status}
                      </p>
                    )}
                    {num.status === "INVITED" && (
                      <p
                        className="badge badge-secondary"
                        //  style={styles.pending}
                      >
                        {num?.status}
                      </p>
                    )}
                  </div>
                  <p>{num.email}</p>
                  <p className="mb-0">Your referral's unique onboarding link</p>
                  <p style={{ color: "blue" }}>
                    {process.env.REACT_APP_ENV === "BETA"
                      ? `https://beta.join.stayflexi.com/?utm_source=${num.code}`
                      : `https://join.stayflexi.com/?utm_source=${num.code}`}
                  </p>
                </CardContent>
              </Card>
            ))}
        </ModalBody>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(ReferalModal);

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
