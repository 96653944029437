import React, { Fragment } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";

const borderColor = "#E5e5e5";

// formatting the date
const formatDate = (date) => {
  return moment(date).format("MMM DD YYYY hh:mm A");
};

const InvoiceDualText = ({ boldText, normalText }) => {
  return (
    <View style={styles.dualText}>
      <Text style={styles.boldText}>{boldText}:</Text>
      <Text style={styles.normalText}>{normalText}</Text>
    </View>
  );
};

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={[styles.time, { fontSize: "10px" }]}>Log time</Text>
    <Text style={[styles.user, { fontSize: "10px" }]}>User</Text>
    <Text style={[styles.action, { fontSize: "10px" }]}>Logged action</Text>
  </View>
);

const InvoiceTableRow = ({ items }) => {
  const rows = items.map((item, index) => (
    <View style={styles.row} key={index}>
      <Text
        style={[
          styles.time,
          {
            marginTop: 3.5,
            marginBottom: 1.5,
            fontSize: "10px",
            marginLeft: 2.5,
          },
        ]}
      >
        {formatDate(item?.log_timestamp)}
      </Text>
      <Text
        style={[
          styles.user,
          {
            fontSize: "10px",
            marginTop: 3.5,
            marginBottom: 1.5,
            marginLeft: 2.5,
          },
        ]}
      >
        {item?.user_email}
      </Text>
      <Text
        style={[
          styles.action,
          {
            fontSize: "10px",
            marginTop: 3.5,
            marginBottom: 1.5,
            marginLeft: 2.5,
          },
        ]}
      >
        {item.log_text}
      </Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

const InvoiceTaxBreakdownHeaderItems = ({ items }) => {
  const rows = Object.keys(items)?.map((item, index) => (
    <View style={styles.row} key={index}>
      <Text style={[styles.description, { fontSize: "10px" }]}>{item}</Text>
      <Text
        style={[{ width: "50%", textAlign: "center" }, { fontSize: "10px" }]}
      >
        {items[item]}
      </Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

// get the image extension
const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

function FolioLog({ invoice, hotelData, symbol, logs, hotelLogo }) {
  return (
    <View style={[styles.section, { marginTop: 2.5 }]}>
      <View
        style={[
          styles.dualText,
          {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 5,
          },
        ]}
      >
        <View style={styles.parentImage}>
          <Image style={styles.image} src={getExtension(hotelLogo)} />
        </View>
        <View>
          <Text style={{ marginBottom: 5 }}>Folio History Log</Text>
          <Text
            style={{ fontSize: "10px", fontWeight: "bold", marginBottom: 3 }}
          >{`Folio#${invoice?.bookingid}`}</Text>
          <InvoiceDualText
            boldText={"Check-in"}
            normalText={formatDate(invoice?.checkin)}
          />
          <InvoiceDualText
            boldText={"Check-out"}
            normalText={formatDate(invoice?.checkout)}
          />

          <View style={styles.dualText}>
            <InvoiceDualText
              boldText={"Nights"}
              normalText={invoice?.num_nights}
            />
            <InvoiceDualText boldText={"Adults"} normalText={invoice?.adults} />
            <InvoiceDualText
              boldText={"Children"}
              normalText={invoice?.children}
            />
          </View>
          <InvoiceDualText
            boldText={"Room No.(s)"}
            normalText={invoice?.roomids}
          />
          <InvoiceDualText
            boldText={"Source"}
            normalText={invoice?.booking_source_displayname}
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ width: "50%" }}>
          <Text style={{ fontSize: "12px", marginBottom: 3.5 }}>Bill From</Text>
          <Text style={[styles.boldText, { marginBottom: 3 }]}>
            {hotelData?.hotel_name}
          </Text>
          <Text
            style={[styles.boldText, { marginBottom: 3, flexWrap: "wrap" }]}
          >
            {hotelData?.hotel_address}
          </Text>
          <View style={styles.dualText}>
            <Text style={styles.boldText}>Email : </Text>
            <Text style={styles.boldText}>{hotelData?.hotel_email}</Text>
          </View>
          <View style={styles.dualText}>
            <Text style={styles.boldText}>Phone:</Text>
            <Text style={styles.boldText}>{hotelData?.hotel_contact}</Text>
          </View>
        </View>

        <View style={{ width: "50%", textAlign: "right" }}>
          <Text
            style={{
              fontSize: "12px",
              paddingRight: "5px",
              marginBottom: 3,
            }}
          >
            Bill to
          </Text>
          <Text style={[styles.boldText, { marginBottom: 3.5 }]}>
            {invoice?.customer_name}
          </Text>
          {!invoice?.customer_address_full_str.startsWith("null") && (
            <Text
              style={[
                styles.boldText,
                {
                  marginBottom: 3.5,
                  marginLeft: 10,
                  flexWrap: "wrap",
                },
              ]}
            >
              {invoice?.customer_address_full_str}
            </Text>
          )}
          <View
            style={[
              {
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: 3.5,
              },
              { textAlign: "right" },
            ]}
          >
            <Text style={styles.boldText}>Email : </Text>
            <Text style={styles.boldText}>{invoice?.customer_email}</Text>
          </View>
          <View
            style={[
              {
                flexDirection: "row",
                marginBottom: 3.5,
                justifyContent: "flex-end",
              },
              { textAlign: "right" },
            ]}
          >
            <Text style={styles.boldText}>Phone:</Text>
            <Text style={styles.boldText}>{invoice?.customer_phone}</Text>
          </View>
        </View>
      </View>
      {invoice?.items && (
        <View style={styles.tableContainer}>
          <InvoiceTableHeader />
          <InvoiceTableRow items={logs?.invoice.folio_log_data} />
        </View>
      )}
    </View>
  );
}

export default FolioLog;

// Create styles
const styles = StyleSheet.create({
  parentImage: {
    height: "75px",
    width: "125px",
  },
  image: {
    height: "auto",
    width: "auto",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 3,
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "10px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "black",
    fontSize: "10px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  time: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  user: {
    width: "25%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  action: {
    width: "50%",
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
  },
  amount: {
    textAlign: "center",
    width: "15%",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
  },
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    // alignItems: "center",
    height: "40",
    fontStyle: "bold",
  },
});
