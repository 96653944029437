import { isEmpty, isObject, isUndefined } from "lodash";
import { OpenCloseOptions } from "../InventoryCalendar/constants/InventoryConstants";

export class InventoryTableDataModel {
  allRoomTypes = [];
  aggregate = {};
  aggregateTableArray = [];
  hotelName = "";
  constructor(data, containsRestrictions,hotelName) {
    if (
      isEmpty(data) ||
      !isObject(data) ||
      data.length <= 0 ||
      isUndefined(data)
    ) {
      return;
    }

    this.allRoomTypes = data.allRoomTypes;
    this.mapAllRoomRows(data.allRoomTypes, containsRestrictions);

    this.aggregate = data.aggregate;
    this.mapAggregateRows(data.aggregate);
    this.hotelName = hotelName
  }

  formAggregateRows(data, occupancy) {
    let temp_arr = {};
    if (occupancy === "occupancy") {
      data.forEach((val, index) => {
        let key = (index + 1).toString();
        temp_arr[key] = Math.round(val.count) + "%";
      });
      return temp_arr;
    } else {
      data.forEach((val, index) => {
        let key = (index + 1).toString();
        temp_arr[key] = val.count;
      });
      return temp_arr;
    }
  }

  mapAggregateRows(data) {
    let occupancy = this.formAggregateRows(
      data["occuPercentList"],
      "occupancy"
    );
    occupancy.name = "Occupancy (%)";

    let available = this.formAggregateRows(data["availableRoomCount"]);
    available.name = "Total Available";

    let booked = this.formAggregateRows(data["bookedRoomCount"]);
    booked.name = "Total Booked";

    let unassigned = this.formAggregateRows(data["unassResCount"]);
    unassigned.name = "Total Unassigned";

    let hold = this.formAggregateRows(data["holdRoomCount"]);
    hold.name = "On-Hold";

    let blockedRoomCount = this.formAggregateRows(data["blockedRoomCount"]);
    blockedRoomCount.name = "Total Blocked Rooms";

    let offlineRoomCount = this.formAggregateRows(data["offlineRoomCount"]);
    offlineRoomCount.name = "Total Offline Rooms";

    this.aggregateTableArray.push(
      occupancy,
      available,
      booked,
      unassigned,
      hold,
      blockedRoomCount,
      offlineRoomCount
    );
  }

  mapAllRoomRows(roomInfo, containsRestrictions) {
    roomInfo.forEach((data, index) => {
      data["dataArray"] = [];
      let available = this.formAggregateRows(data["availableRoomCount"]);
      available.name = "Online Available";
      available.alert = true;

      let offline = this.formAggregateRows(data["offlineRoomCount"]);
      offline.name = "Offline Available";

      let booked = this.formAggregateRows(data["bookedRoomCount"]);
      booked.name = "Booked";

      let unassigned = this.formAggregateRows(data["unassResCount"]);
      unassigned.name = "Unassigned";

      let blocked = this.formAggregateRows(data["blockedRoomCount"]);
      blocked.name = "Blocked";

      let total = this.formAggregateRows(data["totalRoomCount"]);
      total.name = "Total";

      let roomRestrictions = {};
      if (containsRestrictions) {
        roomRestrictions.name = OpenCloseOptions["Close Room"];
        data.restrictionInfoList.forEach((restriction, index) => {
          roomRestrictions[(index + 1).toString()] =
            restriction["stopRTSell"] === "true";
        });
      }

      data["dataArray"].push(
        available,
        offline,
        booked,
        unassigned,
        blocked,
        total
      );
      data["restrictionArray"] = [];
      if (containsRestrictions) data["restrictionArray"].push(roomRestrictions);
      data.ratePlans.forEach((rate) => {
        const temp = this.setInfoMap(rate);
        rate["displayHeader"] = [
          Object.assign({}, temp[rate.defaultOccupancy - 1]),
        ];
        rate["displayHeader"][0].name = rate.ratePlanName;
        rate["displayHeader"][0].selectable = false;
        rate["rateArray"] = temp;
        rate["restrictionArray"] = [];
        rate["bootstrapTableArray"] = [];
        if (containsRestrictions) {
          const closeRateObj = {
            name: OpenCloseOptions["Close Rate"],
            unique: "Close Rate",
          };
          const closeOnArrivalObj = {
            name: OpenCloseOptions["Close On Arrival"],
            unique: "Close On Arrival",
          };
          const closeOnDeptObj = {
            name: OpenCloseOptions["Close On Departure"],
            unique: "Close On Departure",
          };
          const minLenObj = {
            name: OpenCloseOptions["Set Min Los"],
            unique: "Set Min Los",
            selectable: true,
            additional: true,
          };
          const maxLenObj = {
            name: OpenCloseOptions["Set Max Los"],
            unique: "Set Max Los",
            selectable: true,
            additional: true,
          };
          const cutOffObj = {
            name: OpenCloseOptions["Set Cutoff"],
            unique: "Set Cutoff",
            selectable: true,
            additional: true,
          };
          const maxOffSetObj = {
            name: OpenCloseOptions["Set Max Adv Offset"],
            unique: "Set Max Adv Offset",
            selectable: true,
            additional: true,
          };

          rate.restrictionInfos.forEach((restriction, index) => {
            closeRateObj[(index + 1).toString()] =
              restriction["stopSell"] === "true";

            closeOnArrivalObj[(index + 1).toString()] =
              restriction["closedOnArrival"] === "true";
            closeOnDeptObj[(index + 1).toString()] =
              restriction["closedOnDeparture"] === "true";

            minLenObj[(index + 1).toString()] = restriction["minLos"];
            maxLenObj[(index + 1).toString()] = restriction["maxLos"];
            cutOffObj[(index + 1).toString()] = restriction["cutOff"];
            maxOffSetObj[(index + 1).toString()] =
              restriction["maxAdvancedOffset"];
          });
          rate["restrictionArray"].push(
            closeRateObj,
            closeOnArrivalObj,
            closeOnDeptObj
          );
          rate["bootstrapTableArray"].push(
            minLenObj,
            maxLenObj,
            cutOffObj,
            maxOffSetObj
          );
        }
      });
      data["disabledArray"] = [
        booked.name,
        unassigned.name,
        blocked.name,
        offline.name,
        total.name,
      ];
    });
  }

  // mergeRatePlans(data) {
  //   data.forEach((val, index) => {
  //     val.ratePlans.forEach((rate) => {
  //       rate["rateArray"] = this.setInfoMap(rate.rateInfoMap)
  //     })
  //   })
  // }

  setInfoMap(rateMapItem) {
    let rateMap = rateMapItem.rateInfoMap;
    let test_arr = Array(Object.keys(rateMap[0].occupancyPricing).length + 2);
    let selectableArray = new Set();

    rateMap.forEach((item, index) => {
      let objKey = (index + 1).toString();
      let count = -1;
      for (const [secondary_key, secondary_value] of Object.entries(
        item.occupancyPricing
      )) {
        count = count + 1;
        if (isUndefined(test_arr[count])) {
          test_arr[count] = {};
        }

        let sim_obj = Object.assign(test_arr[count]);
        if (isUndefined(sim_obj.name)) {
          sim_obj.name = `${count + 1}XAdults`;
        }
        sim_obj[objKey] = secondary_value;
        test_arr[count] = sim_obj;
        test_arr[count].selectable = true;
        if (count + 1 < rateMapItem.defaultOccupancy) {
          test_arr[count].selectable = false;
          selectableArray.add(`${count + 1}XAdults`);
        }
      }
      if (isUndefined(test_arr[test_arr.length - 2])) {
        test_arr[test_arr.length - 2] = {};
      }
      if (isUndefined(test_arr[test_arr.length - 1])) {
        test_arr[test_arr.length - 1] = {};
      }
      test_arr[test_arr.length - 2][objKey] = item.childPrice;
      test_arr[test_arr.length - 1][objKey] = item.childPrice1;
    });
    test_arr[
      test_arr.length - 2
    ].name = `Child Price (${rateMapItem.lChildAge}-${rateMapItem.rChildAge})`;
    test_arr[
      test_arr.length - 1
    ].name = `Child Prices (${rateMapItem.lChildAge1}-${rateMapItem.rChildAge1})`;

    test_arr[test_arr.length - 1].selectable = true;
    test_arr[test_arr.length - 2].selectable = true;
    rateMapItem.selectableArray = selectableArray;

    return test_arr;
  }
}
