import { isEmpty, isNull, isObject, isUndefined } from "lodash";

export class PriceModel {
  bookingId = "";
  hotelId = "";
  checkin = "";
  checkout = "";
  roomTypeId = "";
  ratePlanId = "";
  numAdults = 0;
  numChildren = 0;
  newRate = 0;
  newRateType = "TOTAL_PRETAX";
  ctaId = null;
  resIds = [];
  destRoomTypeId = "";
  ctaId = null;
  destRoomId = "";
  constructor(data) {
    if (isEmpty(data) || !isObject(data) || data.length <= 0) {
      return;
    }
    if (!isUndefined(data.ratePlanId) && !isNull(data.ratePlanId)) {
      this.assignNewBookingData(data);
    } else {
      this.assignNewEditData(data);
    }
  }
  assignNewBookingData = (data) => {
    this.hotelId = data.hotelId;
    this.checkin = data.checkin;
    this.checkout = data.checkout;
    this.ratePlanId = data.ratePlanId;
    this.numAdults = data.numAdults;
    this.numChildren = data.numChildren;
    this.newRate = data.newRate;
    this.roomTypeId = data.roomTypeId;
  };

  assignNewEditData = (data) => {
    this.hotelId = data.hotelId;
    this.checkin = data.checkin;
    this.checkout = data.checkout;
    if (!isUndefined(data.resIds)) {
      this.resIds = [data.resIds];
    }
    if (!isUndefined(data.bookingId)) {
      this.bookingId = data.bookingId;
    }
    this.newRate = data.newRate;
    if (!isUndefined(data.destRoomTypeId)) {
      this.destRoomTypeId = data.destRoomTypeId;
    }
    if (!isUndefined(data.destRoomId)) {
      this.destRoomId = data.destRoomId;
    }
  };

  getQuickBookingparams = () => {
    return {
      hotelId: this.hotelId,
      checkin: this.checkin,
      checkout: this.checkout,
      roomTypeId: this.roomTypeId,
      ratePlanId: this.ratePlanId,
      numAdults: this.numAdults,
      numChildren: this.numChildren,
      newRate: this.newRate,
      newRateType: this.newRateType,
      ctaId: this.ctaId,
    };
  };

  resetParams = () => {
    this.newRateType = "TOTAL_PRETAX";
    this.newRate = null;
  };

  getEditBookingparams = () => {
    return {
      hotelId: this.hotelId,
      checkin: this.checkin,
      checkout: this.checkout,
      newRate: this.newRate,
      newRateType: this.newRateType,
      bookingId: this.bookingId,
    };
  };

  getCheckinBookingparams = () => {
    return {
      hotelId: this.hotelId,
      checkin: this.checkin,
      newRate: this.newRate,
      newRateType: this.newRateType,
      resIds: this.resIds,
    };
  };

  getCheckoutBookingparams = () => {
    return {
      hotelId: this.hotelId,
      checkout: this.checkout,
      newRate: this.newRate,
      newRateType: this.newRateType,
      resIds: this.resIds,
    };
  };

  getUpgradeBookingparams = () => {
    return {
      hotelId: this.hotelId,
      reservationId: this.resIds[0],
      destRoomTypeId: this.destRoomTypeId,
      newRateType: this.newRateType,
      newRate: this.newRate,
      destRoomId: this.destRoomId,
    };
  };
}
