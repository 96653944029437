import {
  CircularProgress,
  Divider,
  Grid,
  OutlinedInput,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  TextField,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import InfoIcon from "@mui/icons-material/Info";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../api";
import { connect, shallowEqual, useSelector } from "react-redux";
import "../../../../pages/AccessControl/Views/AccessControlModal.css";
import { toast } from "material-react-toastify";
import { Table } from "react-bootstrap";

const AddNewUser = (props) => {
  const [loader, setloader] = useState(false);
  // const [email, setemail] = useState(props?.getAllSubusers[0]?.userEmail);

  const [isCheckedAll, setIsCheckedAll] = useState(false);

  const [hotelRolesData, sethotelRolesData] = useState();
  const [disblaedButton, setdisblaedButton] = useState(false);

  const [searchTerm, setsearchTerm] = useState("");

  const [userRoleAccessData, setuserRoleAccessData] = useState([]);
  const [checked, setChecked] = useState(false);

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [hotelArray, sethotelArray] = useState([props.bookingData[0].hotelId]);
  const [roleFeaturesSearch, setroleFeaturesSearch] = useState("");

  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0)
      sethotelArray(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };

  const getAllData = () => {
    handleSpringDataRequest(
      `core/api/v1/group-roles/get-all?email=${localStorage.getItem(
        "groupEmail"
      )}`
    )
      .then((res) => {
        sethotelRolesData(res);
      })
      .catch((err) => toast.error("Error in fetching roles"));
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    let accessMapData = [];
    let ratePlan = hotelRolesData?.map((res, idx) => {
      let obj = {};
      obj["id"] = res.id;
      obj["role"] = res?.role_name;
      obj["checked"] = false;
      accessMapData.push(obj);
    });
    setdisblaedButton(false);
    setuserRoleAccessData(accessMapData);
  }, [hotelRolesData]);

  const getStringData = () => {
    return "";
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const createUser = () => {
    setloader(true);
    const ids = userRoleAccessData
      .filter((row) => row.checked === true)
      .map((item) => item.id);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/group-user-role-feature-set/create?emailId=${localStorage.getItem(
        "HotelEmail"
      )}`,
      {
        id: null,
        group_id: props.allGroupId,
        user_email: email,
        roles_set: ids?.join(","),
        notif_control_set: getStringData(),
      }
    )
      .then((res) => {
        toast.success(
          `${
            hotelArray.length > 5
              ? "Successfull - This process take some time to assign hotel access"
              : "Successfull"
          }`
        );
        props.getData();
        props.hide();
        setloader(false);
        // UpdateAccess(res);
      })
      .catch((err) => {
        console.error(err);
        setloader(false);
      });
  };

  const save = () => {
    for (let i = 0; i < hotelArray.length; i++) {
      setloader(true);

      handleSpringDataMutationRequest(
        "PUT",
        `user/subUserAuth?hotel_id=${hotelArray[i]}&hotelId=${hotelArray[i]}`,
        {
          username: email,
          password: password,
          token: "",
          isPresent: false,
          loggedInUser: "",
        }
      )
        .then((res) => {
          if (i === hotelArray.length - 1) {
            createUser();
          }
        })
        .catch((err) => {
          toast.error(err);
          setloader(false);
        });
    }
  };

  const handleCheckAll = (isChecked) => {
    userRoleAccessData.forEach((row) => (row.checked = isChecked));
    setIsCheckedAll(isChecked);
  };
  const handleCheck = (rowId) => {
    const updatedData = userRoleAccessData.map((row) => {
      if (row.id === rowId) {
        row.checked = !row.checked;
      }
      return row;
    });

    setuserRoleAccessData(updatedData);
  };

  // HOTEL ACCESS CONTROL

  const [roleName, setroleName] = useState("");

  const [selectedModeofPayment, setselectedModeofPayment] =
    useState("UNRESTRICTED");

  const [flag, setflag] = useState(false);
  const [description, setdescription] = useState("");
  const [getfetaures, setgetfetaures] = useState({});

  const features = [
    "AccountSettings",
    "Audit",
    "ConfigPrivileges",
    "CustomerMgmt",
    "CustomerSelfServiceCheckinCheckout",
    "CustomerSelfServiceEarlyCheckinLateCheckout",
    "CustomerSelfServicePos",
    "CustomerSelfServiceRoomUpgrade",
    "Dashboard",
    "DirectBilling",
    "DirectBillingReports",
    "EnquiryConfiguration",
    "EnquiryDashboard",
    "ExpenseManager",
    "ExpenseReport",
    "ExpenseEListing",
    "ExpenseListing",
    "ExpenseTListing",
    "ExpenseWListing",
    "FlashManagerReport",
    "FlexControl",
    "FlexibleSlot",
    "FolioReport",
    "FrontDeskReport",
    "GuestCheckInCheckOut",
    "HKStaff",
    "HKSupervisor",
    "EditRates",
    "EditInventory",
    "InventoryCalendar",
    "IshaEnquiry",
    "IshaExtraGuestDetails",
    "ManageChannels",
    "ManageRooms",
    "MobileCreateBooking",
    "MobileNotifications",
    "MobileSearchBooking",
    "MobileStatistics",
    "NightAuditConfiguration",
    "PaymentAutomation",
    "PaymentViewAccess",
    "PaymentWriteAccess",
    "PCBEBasicConfig",
    "PCBECustomization",
    "Shops",
    "POSCancelOrder",
    "POSComplimentoryOrder",
    "POSAddUpdateItemCategories",
    "POSAddUpdateItemDiscounts",
    "POSAddUpdateMenuItem",
    "POSAddUpdateShop",
    "POSAddUpdateStaff",
    "POSAddUpdateOrderTable",
    "POSPlaceOrder",
    "POSUpdateOrder",
    "POSHourlyItemSalesSummaryReport",
    "POSItemWiseCategorySummaryReport",
    "POSOrderWiseSalesSummaryReportForDay",
    "POSViewOrders",
    "POSReports",
    "POSDashboard",
    "AllOutletsDayWiseSalesSummaryReport",
    "AllOutletsHourlyItemsSalesSummaryReport",
    "AllOutletsItemWiseCategorySummaryReport",
    "AllOutletsOrderWiseSalesSummaryReport",
    "ShopReports",
    "ShopwiseRevenueReport",
    "AddHotelAddons",
    "EditBookingPrice",
    "PastDateCheckin",
    "PastDateCheckout",
    "PCMainMenu",
    "PastDateCreateBooking",
    "BasicConfig",
    "PCFeeCategory",
    "Policies",
    "AccessControl",
    "ManageRoomTypes",
    "TaxCategories",
    "CumulativeTax",
    "InvoicesConfig",
    "FlexibleSlot",
    "PCGroupTaxCategory",
    "LockCodeManagement",
    "PCAmendmentPolicy",
    "PCFlexibleHourlyPriceConfig",
    "PCHotelTnC",
    "PropertyConfiguration",
    "NotificationAccess",
    "PerRoomCheckinInstructions",
    "PCSettingsPropertySetting",
    "BookingEngine",
    "FlexControl",
    "WhatsAppNotification",
    "PropertyStatistics",
    "RebookPolicies",
    "AccountReceivableAgingReport",
    "AccountReceivableBalanceReport",
    "ArrivalsReport",
    "BARPricingReport",
    "BEBookingsReport",
    "BookingEngineReports",
    "BookingNotesReport",
    "BookingImport",
    "CashCounterReport",
    "ChannelManagerReports",
    "CMBookingsReport",
    "CounterReportByPaymentType",
    "CustomersNotesReports",
    "CustomerNotesReport",
    "CustomerReports",
    "CustomersReport",
    "CustomizedReports",
    "DeparturesReport",
    "EarlyCheckinReport",
    "ExpenseManagerReports",
    "ExpenseReports",
    "FlashManagerReport",
    "FolioReport",
    "FrontDeskReport",
    "InHouseReport",
    "InventoryReport",
    "LateCheckoutReport",
    "LogReports",
    "NewBookingsReport",
    "NightAuditReport",
    "OccupancyReport",
    "OnHoldReport",
    "PaymentGatewayReport",
    "PaymentReport",
    "PaymentReportByPaymentType",
    "PropertyReports",
    "RatePlanCountReport",
    "RefundsReport",
    "Reports",
    "ReportsSchedule",
    "ReputationManagement",
    "ReservationGrid",
    "RMMainMenu",
    "RMPromocodes",
    "RMRateShopper",
    "RollbackBooking",
    "RoomBooking",
    "RoomRevenueReport",
    "RoomRevenueReportOTAWise",
    "RoomRevenueReport",
    "RoomTaxesReport",
    "RoomUpgradeReport",
    "SalesReport",
    "SelfCheckinsReport",
    "ServiceReport",
    "ServiceRevenueReport",
    "ServiceSalesReport",
    "ShiftReport",
    "TaxReports",
    "TransferReport",
    "UserLogReport",
    "MasterReport",
    "PaymentConfirmationReport",
    "Reports",
    "ReputationManagement",
    "ReservationGrid",
    "AddNewRoomToGroupBooking",
    "CancelBooking",
    "PaymentChargeAccess",
    "CheckIn",
    "CheckOut",
    "CustomerInformationCaptureAndDisplay",
    "EarlyCheckoutAlert",
    "EditRatePlan",
    "EditReservation",
    "GuestCheckInCheckOut",
    "LockAndUnlockBooking",
    "ModifyCheckIn",
    "ModifyCheckOut",
    "MoveSwapRoomRemark",
    "RollbackBooking",
    "ScantyBagage",
    "SplitRoom",
    "TaxExemptStatus",
    "UnassignRoom",
    "DynamicPricing",
    "Promotions",
    "RatePlans",
    "RateTemplate",
    "RMPromocodes",
    "RMRateShopper",
    "RMMainMenu",
    "ApplyCouponCode",
    "RoomBooking",
    "SalesEngineSelfCheckins",
    "SalesEngineSelfServices",
    "SalesEngineSelfUpgrades",
    "ReportsSchedule",
    "Statistics",
    "StockManagement",
    "Tags",
    "UnifiedInbox",
    "UserLogReport",
    "ViewManagePayments",
    "WhatsAppNotification",
  ];
  const [status, setstatus] = useState("ACTIVE");

  const [permissions, setPermissions] = useState();
  const [newpermissions, setNewPermissions] = useState();
  const [selectedRole, setselectedRole] = useState();

  const [newsearchTerm, setnewsearchTerm] = useState("");

  const getALLfeature = () => {
    handleSpringDataRequest(
      `core/api/v1/accesscontrol/get-all-global-features?hotel_id=${props.bookingData[0].hotelId}&hotelId=${props.bookingData[0].hotelId}`
    )
      .then((res) => {
        setgetfetaures(res);
      })
      .catch((err) => toast.error("Something went wrong"));
  };

  useEffect(() => {
    getALLfeature();
  }, []);

  useEffect(() => {
    getAllDataForHotel();
  }, [getfetaures]);

  const getAllDataForHotel = () => {
    if (Object.entries(getfetaures).length > 0) {
      handleSpringDataRequest(
        `core/api/v1/accesscontrol/get-initial-access-map?hotel_id=${props.bookingData[0].hotelId}&hotelId=${props.bookingData[0].hotelId}`
      )
        .then((res) => {
          const accessMap = Object.entries(res);

          const accessMapData = [];
          const myData = [];
          const ratePlan = accessMap.map((res) => {
            let obj = {};
            obj["name"] = res[0];
            obj["permissions"] = JSON.parse(res[1]);
            obj["displayName"] = getfetaures[res[0]].display_name;
            if (features.includes(res[0])) {
              accessMapData.push(obj);
            } else {
              myData.push(obj);
            }
          });
          myData.sort(function (a, b) {
            return a.displayName.localeCompare(b.displayName);
          });

          accessMapData.sort(function (a, b) {
            return a.displayName.localeCompare(b.displayName);
          });

          setPermissions(accessMapData);
          setNewPermissions(myData);
        })
        .catch((err) => {
          toast.error("Error in fetching roles");
        });
    }
  };

  const saveRole = () => {
    let flag = false;
    permissions.map((item) => {
      if (item.permissions.read === "YES") {
        flag = true;
        setflag(true);
        return;
      }
    });
    if (flag) {
      if (roleName === undefined || roleName === null || roleName == "") {
        toast.error("Please enter role name");
      } else {
        for (let i = 0; i < hotelArray.length; i++) {
          const payload = {
            id: null,
            hotel_id: hotelArray[i],
            role_name: roleName,
            role_desc: description,
            status: status,
          };
          setloader(true);

          handleSpringDataMutationRequest(
            "POST",
            `core/api/v1/hotel-roles/create?hotelId=${hotelArray[i]}&hotel_id=${hotelArray[i]}`,
            payload
          )
            .then((res) => {
              saveRoles(res, hotelArray[i], i);
            })
            .catch((err) => {
              toast.error("Something went wrong");
              setloader(false);
            });
        }
      }
    } else {
      toast.error("Please select atleast one access");
    }
  };

  const createUsersForHotel = (ids, hotelId) => {
    setloader(true);

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/user-role-feature-set/create?hotel_id=${hotelId}&hotelId=${hotelId}`,
      {
        id: null,
        hotel_id: hotelId,
        user_email: email,
        roles_set: ids,
        notif_control_set: getStringData(),
      }
    )
      .then((res) => {
        toast.success("Successfull");
        setloader(false);
        // UpdateAccess(res);
      })
      .catch((err) => {
        console.error(err);
        setloader(false);
      });
  };

  const saveRoles = (resp, hotelId, len) => {
    const accessMapData = [];
    const ratePlan = permissions.map((res) => {
      if (res.name === "AllowCheckinUnrestrictedPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "UNRESTRICTED") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = resp;
        obj["hotel_id"] = hotelId;
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }
      if (res.name === "AllowCheckinPartialPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "PARTIAL PAYMENT") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = resp;
        obj["hotel_id"] = hotelId;
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }

      if (res.name === "AllowCheckinFullPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "FULL PAYMENT") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = resp;
        obj["hotel_id"] = hotelId;
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }

      if (
        res.name !== "AllowCheckinUnrestrictedPayment" &&
        res.name !== "AllowCheckinFullPayment" &&
        res.name !== "AllowCheckinPartialPayment"
      ) {
        let obj = {};
        obj["id"] = null;
        obj["access"] = JSON.stringify(res.permissions);
        obj["role_id"] = resp;
        obj["hotel_id"] = hotelId;
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }
    });

    const ratePlans = newpermissions.map((res) => {
      if (res.name === "AllowCheckinUnrestrictedPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "UNRESTRICTED") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = resp;
        obj["hotel_id"] = hotelId;
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }
      if (res.name === "AllowCheckinPartialPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "PARTIAL PAYMENT") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = resp;
        obj["hotel_id"] = hotelId;
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }

      if (res.name === "AllowCheckinFullPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "FULL PAYMENT") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = resp;
        obj["hotel_id"] = hotelId;
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }

      if (
        res.name !== "AllowCheckinUnrestrictedPayment" &&
        res.name !== "AllowCheckinFullPayment" &&
        res.name !== "AllowCheckinPartialPayment"
      ) {
        let obj = {};
        obj["id"] = null;
        obj["access"] = JSON.stringify(res.permissions);
        obj["role_id"] = resp;
        obj["hotel_id"] = hotelId;
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }
    });

    setloader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/roles-feature-set/create-update-bulk?hotelId=${hotelId}&hotel_id=${hotelId}`,
      accessMapData
    )
      .then((res) => {
        createUsersForHotel(resp, hotelId);
        if (len === hotelArray.length - 1) {
          save();
        }
      })
      .catch((err) => {
        toast.error("Something went wrong | Try again");
        setloader(false);
      });
  };

  const handleCheckboxChange = (event, row, permission) => {
    const updatedPermissions = permissions?.map((p) => {
      if (p.name === row.name) {
        return {
          ...p,
          permissions: {
            ...p.permissions,
            [permission]: event.target.checked ? "YES" : "NO",
          },
        };
      }
      return p;
    });
    setPermissions(updatedPermissions);
  };

  const handleCheckboxesChange = (event, row, permission) => {
    const updatedPermissions = newpermissions?.map((p) => {
      if (p.name === row.name) {
        return {
          ...p,
          permissions: {
            ...p.permissions,
            [permission]: event.target.checked ? "YES" : "NO",
          },
        };
      }
      return p;
    });
    setNewPermissions(updatedPermissions);
  };

  const handleSelectAll = () => {
    const allHotelIds = props.bookingData.map((name) => name.hotelId);
    sethotelArray(allHotelIds);
  };
  const handleDeselectAll = () => {
    sethotelArray([]);
  };

  const selectAllMenuItem = (
    <MenuItem key="selectAll" onClick={handleSelectAll}>
      Select All
    </MenuItem>
  );

  const deselectAllMenuItem = (
    <MenuItem key="deselectAll" onClick={handleDeselectAll}>
      Deselect All
    </MenuItem>
  );
  const menuItems =
    hotelArray.length === props.bookingData.length
      ? deselectAllMenuItem
      : selectAllMenuItem;

  const SaveDetails = () => {
    setloader(true);
    const accessMapData = [];
    const ratePlan = permissions.map((res) => {
      if (res.name === "AllowCheckinUnrestrictedPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "UNRESTRICTED") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = "1";
        obj["hotel_id"] = "2";
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }
      if (res.name === "AllowCheckinPartialPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "PARTIAL PAYMENT") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = "1";
        obj["hotel_id"] = "2";
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }

      if (res.name === "AllowCheckinFullPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "FULL PAYMENT") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = "1";
        obj["hotel_id"] = "2";
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }

      if (
        res.name !== "AllowCheckinUnrestrictedPayment" &&
        res.name !== "AllowCheckinFullPayment" &&
        res.name !== "AllowCheckinPartialPayment"
      ) {
        let obj = {};
        obj["id"] = null;
        obj["access"] = JSON.stringify(res.permissions);
        obj["role_id"] = "1";
        obj["hotel_id"] = "2";
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }
    });

    const ratePlans = newpermissions.map((res) => {
      if (res.name === "AllowCheckinUnrestrictedPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "UNRESTRICTED") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = "1";
        obj["hotel_id"] = "2";
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }
      if (res.name === "AllowCheckinPartialPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "PARTIAL PAYMENT") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = "1";
        obj["hotel_id"] = "2";
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }

      if (res.name === "AllowCheckinFullPayment") {
        let obj = {};
        obj["id"] = null;
        if (selectedModeofPayment === "FULL PAYMENT") {
          obj["access"] = JSON.stringify({
            read: "YES",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        } else {
          obj["access"] = JSON.stringify({
            read: "NO",
            add: "NO",
            update: "NO",
            delete: "NO",
          });
        }
        obj["role_id"] = "1";
        obj["hotel_id"] = "2";
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }

      if (
        res.name !== "AllowCheckinUnrestrictedPayment" &&
        res.name !== "AllowCheckinFullPayment" &&
        res.name !== "AllowCheckinPartialPayment"
      ) {
        let obj = {};
        obj["id"] = null;
        obj["access"] = JSON.stringify(res.permissions);
        obj["role_id"] = "1";
        obj["hotel_id"] = "2";
        obj["feature_id"] = res.name;
        accessMapData.push(obj);
      }
    });
    const payload = {
      role_name: roleName,
      role_description: description,
      hotelids: hotelArray,
      access_map: accessMapData,
      user_email: email,
    };
    if (roleName === null || roleName === undefined || roleName === "") {
      toast.error("Please enter role name");
      setloader(false);
    } else if (props.hotelEmails.includes(email)) {
      toast.error("Please enter different email");
      setloader(false);
    } else {
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/groupAccesscontrol/create-subuser-role-and-access`,
        payload
      )
        .then((res) => save())
        .catch((err) => console.error("Something went wrong"));
    }
  };

  console.log("newpermissions", newpermissions);
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.hide}
        dialogClassName={"myModalBody142321"}
        // animation={false}
        contentClassName={"myModalContentssdsx"}
        // style={{ maxWidth: "65vw" }}
      >
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <Button variant="white" onClick={props.hide}>
              {" "}
              X{" "}
            </Button>
          </div>
        </ModalTitle>
        <ModalBody>
          <div style={{ width: "100%" }}>
            <div>
              <Grid
                item
                container
                xs={12}
                sx={{ width: "100%", height: "100%" }}
              >
                <div className="d-flex flex-column mb-3">
                  <h2>Manage user</h2>
                  <p>
                    This page allows you to control which user has access to
                    which group features. You can assign users different roles
                    based on their responsibilities, and you can change or
                    remove their access at any time.
                  </p>
                </div>

                <Card sx={{ width: "100%", height: "100%" }}>
                  <CardContent>
                    <Grid item xs={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* <div style={styles.questionPadding}>
                          <div className="w-100">
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Subusers
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => setemail(e.target.value)}
                                className="mr-4"
                                size="small"
                                label="Subusers"
                                value={email}
                              >
                                {props.getAllSubusers?.map(
                                  (hours) =>
                                    !props.userEmails.includes(
                                      hours.userEmail
                                    ) &&
                                    hours.rolesSet !== "ALL" && (
                                      <MenuItem value={hours?.userEmail}>
                                        {`${hours?.userEmail} - ${hours?.hotelId}`}
                                      </MenuItem>
                                    )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div> */}

                        <div style={styles.questionPadding}>
                          <div className="w-100">
                            <h4>Select hotels</h4>
                            <div className="mb-2 mt-3">
                              <FormControl fullWidth style={{ width: "40rem" }}>
                                <InputLabel
                                  style={{ width: "40rem" }}
                                  id="demo-simple-select-label"
                                >
                                  Select hotels
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Select hotels"
                                  value={hotelArray}
                                  style={{ width: "40rem" }}
                                  onChange={handleChanges}
                                  multiple
                                  required="true"
                                  placeholder="Select user email"
                                >
                                  {menuItems}
                                  {props.bookingData.map((name) => (
                                    <MenuItem
                                      key={name.hotelId}
                                      value={name.hotelId}
                                    >
                                      {`${name.hotelName} - ${name.hotelId}`}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex w-100">
                          {" "}
                          <div className="w-100 mr-4">
                            <h4>User email?</h4>
                            <div className="mb-2 ">
                              <TextField
                                label="Email"
                                className="input-lg w-100 mt-3"
                                placeholder="Enter email here"
                                onChange={(e) => setemail(e.target.value)}
                                value={email}
                                name="schedule_name"
                                id="schedule_name"
                              />
                            </div>
                          </div>
                          <div className="w-100">
                            <h4>Password?</h4>
                            <div className="mb-2 ">
                              <TextField
                                label="Password"
                                className="input-lg w-100 mt-3"
                                placeholder="Enter password here"
                                onChange={(e) => setpassword(e.target.value)}
                                value={password}
                                name="schedule_name"
                                id="schedule_name"
                                type="password"
                              />
                            </div>
                          </div>
                        </div>

                        <Grid item xs={12} style={{ marginTop: "1rem" }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="d-flex  w-100">
                              <div className="w-100 mr-4">
                                <h6>Hotel role name</h6>
                                <OutlinedInput
                                  label="Required"
                                  notched={false}
                                  className="input-lg w-100 mb-2 "
                                  placeholder="Enter name here"
                                  onChange={(e) => {
                                    setroleName(e.target.value);
                                  }}
                                  value={roleName}
                                />
                              </div>
                              <div className="w-100">
                                <h6>Hotel role description</h6>

                                <OutlinedInput
                                  label="Required"
                                  notched={false}
                                  className="input-lg w-100 mb-4"
                                  placeholder="Enter description"
                                  onChange={(e) => {
                                    setdescription(e.target.value);
                                  }}
                                  value={description}
                                />
                              </div>
                            </div>
                            <div className="d-flex mt-3 mb-3 align-items-center">
                              <h2 className="mb-0 mr-3">Hotel Features</h2>
                              <OutlinedInput
                                onChange={(e) =>
                                  setroleFeaturesSearch(e.target.value)
                                }
                                placeholder="Search By Feature Name"
                                label="Search By Feature Name"
                                // className="w-75"
                                notched={false}
                                type="search"
                                size="small"
                                style={{
                                  borderRadius: "5px",
                                  width: "35%",
                                }}
                              />
                            </div>

                            {permissions?.length > 0 && (
                              <div
                                style={{
                                  overflowY: "scroll",
                                  height: "48rem",
                                  width: "100%",
                                }}
                              >
                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>
                                        <Switch
                                          className="mr-2 mb-0"
                                          disabled={searchTerm.length > 0}
                                          checked={permissions?.every(
                                            (p) => p.permissions.read === "YES"
                                          )}
                                          onChange={(e) =>
                                            setPermissions(
                                              permissions?.map((p) => ({
                                                ...p,
                                                permissions: {
                                                  ...p.permissions,
                                                  read: e.target.checked
                                                    ? "YES"
                                                    : "NO",
                                                },
                                              }))
                                            )
                                          }
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                        {/* <input
                                      className="mr-2 mb-0"
                                      type="checkbox"
                                      disabled={searchTerm.length > 0}
                                      checked={permissions?.every(
                                        (p) => p.permissions.read === "YES"
                                      )}
                                      onChange={(e) =>
                                        setPermissions(
                                          permissions?.map((p) => ({
                                            ...p,
                                            permissions: {
                                              ...p.permissions,
                                              read: e.target.checked
                                                ? "YES"
                                                : "NO",
                                            },
                                          }))
                                        )
                                      }
                                    /> */}
                                        All Access
                                      </th>
                                      {/* <th>
                                    <input
                                      disabled={true}
                                      className="mr-2 mb-0"
                                      type="checkbox"
                                      checked={permissions.every(
                                        (p) => p.permissions.add === "YES"
                                      )}
                                      onChange={(e) =>
                                        setPermissions(
                                          permissions.map((p) => ({
                                            ...p,
                                            permissions: {
                                              ...p.permissions,
                                              add: e.target.checked
                                                ? "YES"
                                                : "NO",
                                            },
                                          }))
                                        )
                                      }
                                    />
                                    Add
                                  </th>
                                  <th>
                                    <input
                                      disabled={true}
                                      className="mr-2 mb-0"
                                      type="checkbox"
                                      checked={permissions.every(
                                        (p) => p.permissions.update === "YES"
                                      )}
                                      onChange={(e) =>
                                        setPermissions(
                                          permissions.map((p) => ({
                                            ...p,
                                            permissions: {
                                              ...p.permissions,
                                              update: e.target.checked
                                                ? "YES"
                                                : "NO",
                                            },
                                          }))
                                        )
                                      }
                                    />
                                    Update
                                  </th>
                                  <th>
                                    <input
                                      disabled={true}
                                      className="mr-2 mb-0"
                                      type="checkbox"
                                      checked={permissions.every(
                                        (p) => p.permissions.delete === "YES"
                                      )}
                                      onChange={(e) =>
                                        setPermissions(
                                          permissions.map((p) => ({
                                            ...p,
                                            permissions: {
                                              ...p.permissions,
                                              delete: e.target.checked
                                                ? "YES"
                                                : "NO",
                                            },
                                          }))
                                        )
                                      }
                                    />
                                    Delete
                                  </th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {permissions
                                      ?.filter((val) => {
                                        if (roleFeaturesSearch === "") {
                                          return val;
                                        } else if (
                                          val.displayName
                                            .toLowerCase()
                                            .includes(
                                              roleFeaturesSearch.toLowerCase()
                                            )
                                        ) {
                                          return val;
                                        }
                                      })
                                      ?.map(
                                        (row) =>
                                          row.name !==
                                            "AllowCheckinUnrestrictedPayment" &&
                                          row.name !==
                                            "AllowCheckinPartialPayment" &&
                                          row.name !==
                                            "AllowCheckinFullPayment" &&
                                          row.name !== "RoomBooking" &&
                                          row.name !== "ManageRooms" &&
                                          row.name !== "Statistics" &&
                                          row.name !== "CustomerMgmt" &&
                                          row.name !== "Audit" &&
                                          row.name !== "RebookPolicies" &&
                                          row.name !==
                                            "CustomerSelfServiceCheckinCheckout" &&
                                          row.name !==
                                            "CustomerSelfServiceRoomUpgrade" &&
                                          row.name !==
                                            "SalesEngineSelfUpgrades" &&
                                          row.name !==
                                            "SalesEngineSelfCheckins" &&
                                          row.name !==
                                            "SalesEngineSelfServices" &&
                                          row.name !== "AccountSettings" &&
                                          row.name !== "UnifiedInbox" &&
                                          row.name !== "ManageChannels" &&
                                          row.name !== "HidePMSHelpLink" &&
                                          row.name !== "ConfigPrivileges" &&
                                          row.name !== "CMPlus" &&
                                          row.name !== "ResGridCustomerName" &&
                                          row.name !== "PaymentWriteAccess" &&
                                          row.name !== "PCBEBasicConfig" &&
                                          row.name !== "PCBECustomization" &&
                                          row.name !== "ChannelMapping" &&
                                          row.name !== "ExpenseListing" &&
                                          row.name !== "ExpenseConfig" &&
                                          row.name !== "ExpenseReport" &&
                                          row.name !==
                                            "CustomerSelfServiceEarlyCheckinLateCheckout" &&
                                          row.name !==
                                            "CustomerSelfServicePos" && (
                                            <tr key={row.name}>
                                              <td>
                                                <span className="d-flex align-item-center">
                                                  {" "}
                                                  <p
                                                    className="mr-1 mb-0"
                                                    style={{
                                                      marginTop: "7px",
                                                    }}
                                                  >{`${
                                                    getfetaures[row.name]
                                                      .display_name
                                                  }`}</p>{" "}
                                                  <Tooltip
                                                    title={`${
                                                      getfetaures[row.name]
                                                        .display_description
                                                    }`}
                                                  >
                                                    <IconButton>
                                                      <InfoIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                </span>
                                              </td>
                                              <td>
                                                <Switch
                                                  checked={
                                                    row.permissions.read ===
                                                    "YES"
                                                  }
                                                  onChange={(e) =>
                                                    handleCheckboxChange(
                                                      e,
                                                      row,
                                                      "read"
                                                    )
                                                  }
                                                />
                                              </td>
                                              {/* <td>
                                        <input
                                          disabled={true}
                                          type="checkbox"
                                          checked={
                                            row.permissions.add === "YES"
                                          }
                                          onChange={(e) =>
                                            handleCheckboxChange(e, row, "add")
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          disabled={true}
                                          type="checkbox"
                                          checked={
                                            row.permissions.update === "YES"
                                          }
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              row,
                                              "update"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          disabled={true}
                                          type="checkbox"
                                          checked={
                                            row.permissions.delete === "YES"
                                          }
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              row,
                                              "delete"
                                            )
                                          }
                                        />
                                      </td> */}
                                            </tr>
                                          )
                                      )}
                                  </tbody>
                                </Table>
                              </div>
                            )}
                          </Grid>
                        </Grid>

                        <div className="d-flex mt-3 mb-3 align-items-center">
                          <h2 className="mb-0 mr-3">Hotel Restrictions </h2>
                          <OutlinedInput
                            onChange={(e) => setnewsearchTerm(e.target.value)}
                            placeholder="Search By Restriction Name"
                            label="Search By Restriction Name"
                            // className="w-75"
                            notched={false}
                            type="search"
                            size="small"
                            style={{
                              borderRadius: "5px",
                              width: "35%",
                            }}
                          />
                        </div>
                        {newpermissions?.length > 0 && (
                          <div
                            style={{
                              overflowY: "scroll",
                              height: "35rem",
                              width: "100%",
                            }}
                          >
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>
                                    <Switch
                                      className="mr-2 mb-0"
                                      disabled={newsearchTerm.length > 0}
                                      checked={newpermissions?.every(
                                        (p) => p.permissions.read === "YES"
                                      )}
                                      onChange={(e) =>
                                        setNewPermissions(
                                          newpermissions?.map((p) => ({
                                            ...p,
                                            permissions: {
                                              ...p.permissions,
                                              read: e.target.checked
                                                ? "YES"
                                                : "NO",
                                            },
                                          }))
                                        )
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                    {/* <input
                                      className="mr-2 mb-0"
                                      type="checkbox"
                                      disabled={searchTerm.length > 0}
                                      checked={permissions?.every(
                                        (p) => p.permissions.read === "YES"
                                      )}
                                      onChange={(e) =>
                                        setPermissions(
                                          permissions?.map((p) => ({
                                            ...p,
                                            permissions: {
                                              ...p.permissions,
                                              read: e.target.checked
                                                ? "YES"
                                                : "NO",
                                            },
                                          }))
                                        )
                                      }
                                    /> */}
                                    All Access
                                  </th>
                                  {/* <th>
                                    <input
                                      disabled={true}
                                      className="mr-2 mb-0"
                                      type="checkbox"
                                      checked={permissions.every(
                                        (p) => p.permissions.add === "YES"
                                      )}
                                      onChange={(e) =>
                                        setPermissions(
                                          permissions.map((p) => ({
                                            ...p,
                                            permissions: {
                                              ...p.permissions,
                                              add: e.target.checked
                                                ? "YES"
                                                : "NO",
                                            },
                                          }))
                                        )
                                      }
                                    />
                                    Add
                                  </th>
                                  <th>
                                    <input
                                      disabled={true}
                                      className="mr-2 mb-0"
                                      type="checkbox"
                                      checked={permissions.every(
                                        (p) => p.permissions.update === "YES"
                                      )}
                                      onChange={(e) =>
                                        setPermissions(
                                          permissions.map((p) => ({
                                            ...p,
                                            permissions: {
                                              ...p.permissions,
                                              update: e.target.checked
                                                ? "YES"
                                                : "NO",
                                            },
                                          }))
                                        )
                                      }
                                    />
                                    Update
                                  </th>
                                  <th>
                                    <input
                                      disabled={true}
                                      className="mr-2 mb-0"
                                      type="checkbox"
                                      checked={permissions.every(
                                        (p) => p.permissions.delete === "YES"
                                      )}
                                      onChange={(e) =>
                                        setPermissions(
                                          permissions.map((p) => ({
                                            ...p,
                                            permissions: {
                                              ...p.permissions,
                                              delete: e.target.checked
                                                ? "YES"
                                                : "NO",
                                            },
                                          }))
                                        )
                                      }
                                    />
                                    Delete
                                  </th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {newpermissions
                                  ?.filter((val) => {
                                    if (newsearchTerm === "") {
                                      return val;
                                    } else if (
                                      val.displayName
                                        .toLowerCase()
                                        .includes(newsearchTerm.toLowerCase())
                                    ) {
                                      return val;
                                    }
                                  })
                                  ?.map(
                                    (row) =>
                                      row.name !==
                                        "AllowCheckinUnrestrictedPayment" &&
                                      row.name !==
                                        "AllowCheckinPartialPayment" &&
                                      row.name !== "AllowCheckinFullPayment" &&
                                      row.name !== "RoomBooking" &&
                                      row.name !== "ManageRooms" &&
                                      row.name !== "Statistics" &&
                                      row.name !== "CustomerMgmt" &&
                                      row.name !== "Audit" &&
                                      row.name !== "RebookPolicies" &&
                                      row.name !==
                                        "CustomerSelfServiceCheckinCheckout" &&
                                      row.name !==
                                        "CustomerSelfServiceRoomUpgrade" &&
                                      row.name !== "SalesEngineSelfUpgrades" &&
                                      row.name !== "SalesEngineSelfCheckins" &&
                                      row.name !== "SalesEngineSelfServices" &&
                                      row.name !== "AccountSettings" &&
                                      row.name !== "UnifiedInbox" &&
                                      row.name !== "ManageChannels" &&
                                      row.name !== "HidePMSHelpLink" &&
                                      row.name !== "ConfigPrivileges" &&
                                      row.name !== "CMPlus" &&
                                      row.name !== "ResGridCustomerName" &&
                                      row.name !== "PaymentWriteAccess" &&
                                      row.name !== "PCBEBasicConfig" &&
                                      row.name !== "PCBECustomization" &&
                                      row.name !== "ChannelMapping" &&
                                      row.name !==
                                        "CustomerSelfServiceEarlyCheckinLateCheckout" &&
                                      row.name !== "CustomerSelfServicePos" && (
                                        <tr key={row.name}>
                                          <td>
                                            <span className="d-flex align-item-center">
                                              {" "}
                                              <p
                                                className="mr-1 mb-0"
                                                style={{ marginTop: "7px" }}
                                              >{`${
                                                getfetaures[row.name]
                                                  .display_name
                                              }`}</p>{" "}
                                              <Tooltip
                                                title={`${
                                                  getfetaures[row.name]
                                                    .display_description
                                                }`}
                                              >
                                                <IconButton>
                                                  <InfoIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </span>
                                          </td>
                                          <td>
                                            <Switch
                                              checked={
                                                row.permissions.read === "YES"
                                              }
                                              onChange={(e) =>
                                                handleCheckboxesChange(
                                                  e,
                                                  row,
                                                  "read"
                                                )
                                              }
                                            />
                                          </td>
                                          {/* <td>
                                        <input
                                          disabled={true}
                                          type="checkbox"
                                          checked={
                                            row.permissions.add === "YES"
                                          }
                                          onChange={(e) =>
                                            handleCheckboxChange(e, row, "add")
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          disabled={true}
                                          type="checkbox"
                                          checked={
                                            row.permissions.update === "YES"
                                          }
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              row,
                                              "update"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          disabled={true}
                                          type="checkbox"
                                          checked={
                                            row.permissions.delete === "YES"
                                          }
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              row,
                                              "delete"
                                            )
                                          }
                                        />
                                      </td> */}
                                        </tr>
                                      )
                                  )}
                              </tbody>
                            </Table>
                          </div>
                        )}

                        <FormControl
                          size="small"
                          fullWidth
                          style={{ width: "18rem", marginTop: "1rem" }}
                        >
                          <InputLabel
                            style={{ width: "15=8rem" }}
                            id="demo-simple-select-label"
                          >
                            Payment Necessary during checkin
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(e) =>
                              setselectedModeofPayment(e.target.value)
                            }
                            size="small"
                            style={{ marginLeft: "0rem", width: "18rem" }}
                            className="mr-4"
                            label="Payment Necessary during checkin"
                            value={selectedModeofPayment}
                          >
                            <MenuItem value={"UNRESTRICTED"}>
                              {"UNRESTRICTED"}
                            </MenuItem>
                            <MenuItem value={"PARTIAL PAYMENT"}>
                              {"PARTIAL PAYMENT"}
                            </MenuItem>
                            <MenuItem value={"FULL PAYMENT"}>
                              {"FULL PAYMENT"}
                            </MenuItem>
                          </Select>
                        </FormControl>

                        <div className="d-flex justify-content-between w-100 mt-4">
                          <div className="d-flex mt-3 mb-3 align-items-center">
                            <h2 className="mb-0 mr-3">Group roles </h2>
                            <div>
                              <OutlinedInput
                                onChange={(e) => setsearchTerm(e.target.value)}
                                placeholder="Search By Group roles name"
                                label="Search By Group Role Name"
                                // className="w-75"
                                notched={false}
                                type="search"
                                size="small"
                                style={{
                                  borderRadius: "5px",
                                  width: "18rem",
                                  marginRight: "2rem",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            overflowY: "scroll",
                            height: "40rem",
                            width: "100%",
                          }}
                        >
                          <Table striped bordered hover>
                            <thead
                              style={{
                                position: "sticky",
                                top: "0px",
                                backgroundColor: "gray",
                              }}
                            >
                              <tr>
                                <th hidden>Id</th>
                                <th>Role</th>

                                <th colSpan={3}>
                                  <Switch
                                    className="mr-2"
                                    checked={isCheckedAll}
                                    onChange={(e) => {
                                      if (
                                        userRoleAccessData[0]?.role !==
                                        "SUBADMIN"
                                      )
                                        handleCheckAll(!isCheckedAll);
                                    }}
                                  />
                                  Check all
                                </th>
                              </tr>
                            </thead>
                            {userRoleAccessData !== undefined &&
                              userRoleAccessData.length > 0 && (
                                <tbody>
                                  {userRoleAccessData
                                    ?.filter((val) => {
                                      if (searchTerm === "") {
                                        return val;
                                      } else if (
                                        val.role
                                          .toLowerCase()
                                          .includes(searchTerm.toLowerCase())
                                      ) {
                                        return val;
                                      }
                                    })
                                    .map((row) => (
                                      <tr key={row.id}>
                                        <td hidden>{row.id}</td>
                                        <td>{row.role}</td>
                                        <td>
                                          <Switch
                                            checked={row.checked}
                                            onChange={(e) => {
                                              if (row.role !== "SUBADMIN")
                                                handleCheck(row.id);
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              )}
                          </Table>
                        </div>
                      </Grid>
                    </Grid>
                    <Button
                      color="primary"
                      className="float-right mr-4 mb-3"
                      variant="custom-button"
                      disabled={loader}
                      onClick={() => SaveDetails()}
                    >
                      {loader ? (
                        <CircularProgress
                          size={15}
                          color="inherit"
                          sx={{ ml: 1.5 }}
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNewUser;

const styles = {
  iconColor: {
    borderRadius: "100%",
    bordeRadius: "100%",
    height: "40px",
    width: "40px",
    padding: "4px",
    marginRight: "-10px",
    color: "#COCOCO",
  },
  inputSize: {
    width: "50px",
    bordeRadius: "5px",
    heigth: "30px",
  },
};
