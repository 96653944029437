import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_API_URL}`;
const SPRING_URL = `${process.env.REACT_APP_SPRING_API_URL}`;

const GETALLPROMOTION = "promotion/getAllPromotions/";
const ADDNEWPROMOTION = "promotion/addPromotion/";

const ACTIVATEPROMOTION = "promotion/activatePromotion/";
const DEACTIVATEPROMOTION = "promotion/deactivatePromotion/";
const GETALLRATEPLAN = "rate/getAllRatePlans/";
const SAVEPAYMENTCARDINFO = "payments/saveCustomerPaymentCard/";
const DELETEPAYMENTCARD = "invoice/deleteCustomerCardOwn/";
// const GETALLSAVEDCARDS = "payments/getAllCustomerPaymentCards/";
const GETALLSAVEDCARDS = "core/api/v1/customer-card/get-saved-customer-card";
const CUSTOMPGINFO = "payments/getHotelCustomPgInfo/";
const url = new URL(window.location.href);
const hotelId = url.searchParams.get("hotelId");

export const getAllPromotions = async () => {
  return axios
    .get(COMMON_URL + GETALLPROMOTION)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addNewPromotions = async (data) => {
  return axios
    .post(COMMON_URL + ADDNEWPROMOTION, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const activiatePromotions = async (id) => {
  return axios
    .get(COMMON_URL + ACTIVATEPROMOTION + "?hotel_id=" + hotelId + "&id=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deactiviatePromotions = async (id) => {
  return axios
    .get(
      COMMON_URL + DEACTIVATEPROMOTION + "?hotel_id=" + hotelId + "&id=" + id
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllRateplanData = async () => {
  return axios
    .get(COMMON_URL + GETALLRATEPLAN)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const saveCardInfo = async (data) => {
  return axios
    .post(COMMON_URL + SAVEPAYMENTCARDINFO, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
export const saveCardInfoWithHotelId = async (data, hotelId) => {
  return axios
    .post(
      COMMON_URL +
        SAVEPAYMENTCARDINFO +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      data
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getcustomPGinfo = async () => {
  return axios
    .get(COMMON_URL + CUSTOMPGINFO)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getcustomPGinfoWithHotelId = async (hotelId) => {
  return axios
    .get(
      COMMON_URL + CUSTOMPGINFO + "?hotelId=" + hotelId + "&hotel_id=" + hotelId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getSavedCards = async (id) => {
  return axios
    .get(SPRING_URL + GETALLSAVEDCARDS + "?email=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteCardInfo = async (data) => {
  return axios
    .get(COMMON_URL + DELETEPAYMENTCARD + "?card_id=" + data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
