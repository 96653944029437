import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { channelNamesMap } from "../constants/Calendar-constants";
import { get } from "lodash";
import Currencies from "../../../Utils/Currencies.json";

const useStyles = makeStyles((theme) => ({
  paper: {
    zIndex: 2,
    borderColor: "white",
    backgroundColor: "white",
    opacity: 0.5,
  },
  typography: {
    padding: theme.spacing(1),
  },
}));

const getSource = (channelId) => {
  console.log(get(channelNamesMap, channelId));
  if (get(channelNamesMap, channelId) !== undefined) {
    return get(channelNamesMap, channelId);
  } else if (
    channelId !== null &&
    channelId !== undefined &&
    channelId !== ""
  ) {
    return channelId;
  } else {
    return "Walk-In Booking";
  }
};

const CustomPopper = (props) => {
  const { htmlEle } = props;
  const classes = useStyles();
  const [hotelSymbol, setHotelSymbol] = useState("");

  useEffect(() => {
    const data = Currencies?.filter((item) => {
      if (item.code === localStorage.getItem("hotelCurrency")) {
        return setHotelSymbol(item.symbol);
      }
    });
  }, []);

  return (
    <Card>
      <CardHeader
        sx={{ paddingBottom: 1 }}
        avatar={<Avatar aria-label="recipe">{htmlEle.event.title[0]}</Avatar>}
        title={htmlEle.event.title}
      ></CardHeader>
      <CardContent sx={{ padding: 1 }}>
        <Typography className={classes.typography}>
          {htmlEle.event.title !== "Blocked" ? "Checkin :" : ""}
          {" " +
            moment(htmlEle.event.extendedProps.actual_start).format(
              "MMM Do, YYYY h A"
            )}
        </Typography>
        <Typography className={classes.typography}>
          {htmlEle.event.title !== "Blocked" ? "Checkout :" : ""}
          {" " +
            moment(htmlEle.event.extendedProps.actual_end).format(
              "MMM Do, YYYY h A"
            )}
        </Typography>
        {htmlEle.event.extendedProps.status !== "COMBO" &&
          htmlEle.event.extendedProps.status !== "BLOCKED" && (
            <Typography className={classes.typography}>
              Source :
              {" " + getSource(htmlEle.event.extendedProps.bookingSource)}
            </Typography>
          )}
        {htmlEle.event.extendedProps.balanceDue > 0 && (
          <Typography className={classes.typography}>
            {"Balance due : "}
            {" " + `${hotelSymbol} ${htmlEle.event.extendedProps.balanceDue}`}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default CustomPopper;
