import {Button} from "@mui/material";
import React from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import * as Sentry from "@sentry/browser";

export default function ErrorPage1({ error, resetErrorBoundary }) {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`,
        }}
      >
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "150px" }}
        >
          Our apologies
        </h1>
        {Sentry.captureException(error)}
        <Button variant="outlined"
          sx={{
            display: "contents",
            width: "max-content",
            background: "linear-gradient(-150deg, #1ab394, #1a91ae)",
            color: "black",
          }}
          size="large"
          onClick={resetErrorBoundary}
        >
          Sorry, something went wrong. Please try again.
        </Button>
      </div>
    </div>
  );
}
