import React from 'react';
import AdvancedPortfolio from "./AdvancedPortfolio";
import AdvPortfolioSummary from "./AdvPortfolioSummary"

function AdvancedPortfolioWrapper({onCloseAdvFolio}) {

    return (
        <>
            <div className="advPortfolioWrapper" 
            style={{
                display:"flex",
                flexDirection:"row",
                justifyContent:"space-between",
                position:"relative",
                width:"100%"
            }}>
                <div style={{width:"72%"}}><AdvancedPortfolio onClose={onCloseAdvFolio} /></div>
                <div style={{width:"28%", marginLeft:"25px", marginTop:"90px"}}><AdvPortfolioSummary /></div>
            </div>
        </>
    )
}

export default AdvancedPortfolioWrapper
