import React, { useState, useEffect, SyntheticEvent } from "react";
import {
  Stack,
  TextField,
  Typography,
  Divider,
  Tabs,
  Tab,
  Box,
  Button,
  ClickAwayListener,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import * as HiIcons from "react-icons/hi";
import { DateRange } from "react-date-range";
import moment from "moment";
import {
  OutlinedButton,
  SecondaryButton,
} from "../../../../../Utils/ButtonHelper";
import { djangoHandleDataRequests } from "../../../../../api";
import { toast } from "material-react-toastify";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import * as BiIcons from "react-icons/bi";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: -200,
  zIndex: 100,
  border: "1px solid",
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
};

function ProductStats({ customClass, startDate, endDate }) {
  const { shopId } = useParams();

  // tabs
  const [productType, setProductType] = useState(0);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleProductChange = (event, newValue) => setProductType(newValue);
  // Click away listener tin order to toggle calendar state
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  //get product stats data
  const [productStats, setproductStats] = useState({});

  useEffect(() => {
    getProductStatsData();
  }, [startDate, endDate]);

  const getProductStatsData = () => {
    const new_end_date = new Date(new Date(endDate).getTime() + 86400000);
    shopId &&
      djangoHandleDataRequests(
        `pos/getSellingProducts?shop_id=${shopId}&hotel_id=12353&start_date=${startDate}&end_date=${moment(
          new_end_date
        ).format("YYYY-MM-DD")}`
      )
        .then((res) => setproductStats(res))
        .catch((err) => toast.error("Error fetching the details"));
  };

  const [sellingProduct, setSellingProduct] = useState([]);

  useEffect(() => {
    if (String(productType) === "0") {
      setSellingProduct(productStats?.top_selling);
    } else {
      setSellingProduct(productStats?.low_selling);
    }
  }, [productType]);

  console.log("selling prod : ", sellingProduct);

  return (
    <Stack className={customClass}>
      <Stack className="position-relative">
        <Stack className="d-flex justify-content-between pt-4" direction="row">
          <Typography variant="h6">Product</Typography>
        </Stack>
        <Stack className="mt-7">
          <Box sx={{ width: "100%" }}>
            <Tabs
              onChange={handleProductChange}
              value={productType}
              selectionFollowsFocus
            >
              <Tab label="Top selling" {...a11yProps(0)} />
              <Tab label="Low selling" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={productType} index={0}>
            <Stack spacing={2} className="mt-2 ml-2">
              {productStats.top_selling &&
              productStats.top_selling.length > 0 ? (
                productStats?.top_selling?.map((item) => (
                  <Stack className="mt-3" key={item.product_name}>
                    {item?.product_name && item.product_name.toUpperCase()}
                  </Stack>
                ))
              ) : (
                <Stack
                  className="mt-10 position-relative w-100 align-items-center"
                  spacing={2}
                  style={{ marginTop: 10 }}
                >
                  <Typography style={{ color: "grey" }}>
                    No data found!
                  </Typography>
                </Stack>
              )}
            </Stack>
          </TabPanel>
          <TabPanel value={productType} index={1}>
            <Stack spacing={2} className="mt-2 ml-2">
              {productStats.low_selling &&
              productStats.low_selling.length > 0 ? (
                productStats?.low_selling?.map((item) => (
                  <Stack className="mt-3" key={item.product_name}>
                    {item?.product_name && item.product_name.toUpperCase()}
                  </Stack>
                ))
              ) : (
                <Stack
                  className="mt-10 position-relative w-100 align-items-center"
                  spacing={2}
                  style={{ marginTop: 10 }}
                >
                  <Typography style={{ color: "grey" }}>
                    No data found!
                  </Typography>
                </Stack>
              )}
            </Stack>
          </TabPanel>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ProductStats;
