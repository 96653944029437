import dotenv from "dotenv";

dotenv.config();

// export const BASE_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT
// console.log(process.env.REACT_APP_API_BASE_ENDPOINT)
// console.log("api url", process.env.REACT_APP_API_URL)
// console.log("spring api url", process.env.REACT_APP_SPRING_API_URL)
// console.log("elastic search url : ", process.env.REACT_APP_ELASTIC_SEARCH)

export const BASE_ENDPOINT = process.env.REACT_APP_API_URL;
export const BASE_SPRING_ENDPOINT = process.env.REACT_APP_SPRING_API_URL;
export const ELASTIC_URL = process.env.REACT_APP_ELASTIC_URL;
export const ELASTIC_TOKEN = process.env.REACT_APP_ELASTIC_TOKEN;
export const CM_BOOKING_SPRING_ENDPOINT =
  process.env.REACT_APP_SPRING_API_PULL_CM_BOOKING_URL;
export const LOAD_SERVER_ENDPOINT = process.env.REACT_APP_LOAD_SERVER_URL;

// export const hotelId = localStorage.getItem("hotelId")
