import React, { useState, useEffect } from "react";
import { label } from "./Utils/Labels.json";
// import { tables } from "./Utils/Tables.json";
import {
  Stack,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Checkbox,
  Modal,
  Divider,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import * as AiIcons from "react-icons/ai";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import ShopWrapper from "../ShopWrapper";
import {
  handleSpringDataRequest,
  djangoHandleDataRequests,
} from "../../../../api";
import { toast } from "material-react-toastify";
// import { tables } from "./Utils/Tables.json";
import { styled } from "@mui/material/styles";
import moment from "moment";
import {
  OutlinedButton,
  SecondaryButton,
} from "../../../../Utils/ButtonHelper";
import TablesLoader from "./Utils/TablesLoader";
import * as BiIcons from "react-icons/bi";
import { connect } from "react-redux";
import UnauthorisedAccess from "../UnauthorisedAccess/UnauthorisedAccess";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "fit-content",
  maxHeight: 550,
  overflowY: "scroll",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
};

function PlaceOrderPage({ shopAccessControl }) {
  // Get the data from the url
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId, shopName, shopType } = useParams();
  const history = useHistory();
  const location = useLocation();
  // Get backgorund color based on the table status
  function getBackgroundColor(tableStatus) {
    if (tableStatus.toLowerCase() === "available") {
      return "#E9E9E9";
    } else if (tableStatus.toLowerCase() === "running") {
      return "#7EF9FF";
    } else if (tableStatus.toLowerCase() === "printed") {
      return "#FE8941";
    } else if (tableStatus === "kot_print") {
      return "#FFD800";
    }
  }

  //get All tables
  const [tables, setTables] = useState(null);

  /* Uncomment when done with testing */
  const handleAllTables = () => {
    shopId &&
      handleSpringDataRequest(
        `core/api/v1/order-table/get-all?shopId=${shopId}`
      )
        .then((res) =>
          setTables(
            res.filter(
              (table) =>
                !["DELETED", "INACTIVE"].includes(table?.table_active_status)
            )
          )
        )
        .catch((err) =>
          toast.error("Error in getting tables ! Please try again later.")
        );
  };
  useEffect(() => {
    handleAllTables();
  }, []);

  // Toggle between table view and room view

  // view type
  const [viewType, setViewType] = useState(true);

  // getting the available rooms
  const [inhouseData, setInhouseData] = useState(null);
  const [custDetails, setcustDetails] = useState([]);
  var currentDate = new Date();
  var formattedDate = moment(currentDate).format("ddd MMM DD YYYY");

  function getDashboardData() {
    console.log(formattedDate);
    djangoHandleDataRequests(
      `reports/generateDashDataLite/?date=${formattedDate}&is_today=${moment(
        formattedDate
      )
        .isSame(moment(moment().toDate()).format("ddd MMM DD YYYY"))
        .toString()}`
    )
      .then((res) => {
        setInhouseData(res.ADMIN_CONFIRMED);
      })
      .catch((err) => toast.error("Sorry cannot fetch the data!"));
  }

  useEffect(() => {
    getDashboardData();
  }, [shopId]);

  // Storing booking id to use in order to verify the details
  const [bookingId, setBookingId] = useState("");
  const [roomId, setRoomId] = useState("");

  // modal for showing the details for rooms
  const [customerDetails, setCustomerDetails] = useState(false);
  const handleOpenCustomerDetails = () => setCustomerDetails(true);
  const handleCloseCustomerDetails = () => setCustomerDetails(false);

  /* API */
  // Generate an ID for the random order and then redirect to the orders page
  const handleGenerateRandomId = () => {
    handleSpringDataRequest("core/api/v1/orders/get-random-order-id")
      .then((res) =>
        history.push({
          pathname: `/outlet/${shopId}/${shopName}/${shopType}/randomOrder/random/${res}/placeOrder`,
          search: `&hotelId=${hotelId}`,
          state: { index: location.state.index },
        })
      )
      .catch((err) =>
        toast.error("Error in generating the ID. Please try again later!")
      );
  };

  useEffect(() => {
    inhouseData &&
      inhouseData.length > 0 &&
      setcustDetails([
        inhouseData?.filter((data) => data?.booking_id === bookingId)[0],
      ]);
  }, [bookingId]);

  // Search values
  const [searchForFood, setSearchForFood] = useState("");

  const [selectedResIdForRoom, setSelectedResIdForRoom] = useState("");

  return (
    <ShopWrapper
      contentArea={
        shopAccessControl &&
        shopAccessControl?.["POSPlaceOrder"] &&
        JSON.parse(shopAccessControl?.["POSPlaceOrder"])?.read === "YES" ? (
          <Stack spacing={4} className="position-relative w-100 mt-4">
            <Stack
              direction="row"
              spacing={2}
              className="d-flex flex-row justify-content-between mr-2 position-relative w-100 mt-4"
            >
              <Stack direction="row" spacing={1}>
                {/* <Checkbox
                checked={viewType}
                onChange={(e) => setViewType(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
                color="secondary"
              />
              <Typography
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                Show room view as well
              </Typography> */}
                <TextField
                  id="outlined-basic"
                  value={searchForFood}
                  onChange={(e) => setSearchForFood(e.target.value)}
                  label="Search"
                  size="small"
                  placeholder="Search using table name or room number"
                  sx={{
                    width: "250px",
                  }}
                />
                {viewType === false ? (
                  <Button
                    style={{
                      border: " 2px solid black",
                      textTransform: "capitalize",
                      color: "black",
                      width: "110px",
                      height: "35px",
                    }}
                    onClick={() => setViewType(true)}
                  >
                    Room View
                  </Button>
                ) : (
                  <Button
                    variant="custom-button"
                    style={{
                      width: "110px",
                      height: "35px",
                    }}
                    onClick={() => setViewType(false)}
                  >
                    Room View
                  </Button>
                )}
                <Button
                  variant="custom-button"
                  onClick={() => handleGenerateRandomId()}
                >
                  Delivery
                </Button>
              </Stack>
              <Stack direction="row">
                {label.map((item) => (
                  <Stack
                    direction="row"
                    className="pl-2 pr-2"
                    spacing={1}
                    style={{ width: "fit-content" }}
                  >
                    <Box
                      className="rounded-circle"
                      style={{
                        backgroundColor: `${item.color}`,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography variant="h6" style={{ fontSize: "12px" }}>
                      {item.type}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
            {/* <Stack
            className="mt-4 position-relative d-flex flex-wrap justify-content-end"
            style={{ width: "fit-content" }}
          >
            <Typography
              style={{
                textDecoration: "underline",
                marginTop: "auto",
                marginBottom: "auto",
                cursor: "pointer",
                color: "grey",
              }}
              onClick={() => handleGenerateRandomId()}
            >
              {" "}
              Delivery {">"}{" "}
            </Typography>
          </Stack> */}
            <Stack spacing={2} className="mt-8">
              <Typography variant="h6" style={{ fontSize: "15px" }}>
                Tables
              </Typography>
              {tables ? (
                <Stack
                  direction="row"
                  className="position-relative w-100 justify-content-start mt-4 d-flex flex-wrap wrap"
                >
                  {tables.length > 0 ? (
                    tables
                      .filter((item) =>
                        searchForFood.trim().length === 0
                          ? true
                          : item?.table_name
                              ?.toLowerCase()
                              .includes(searchForFood?.toLowerCase())
                      )
                      .map((table, index) => (
                        <Link
                          to={{
                            pathname: `/outlet/${shopId}/${shopName}/${shopType}/tableOrder/table/${table.id}/${table.table_name}/placeOrder`,
                            search: `?hotelId=${hotelId}`,
                            state: { index: location.state.index },
                          }}
                          style={{
                            textDecoration: "none!important",
                            color: "black",
                          }}
                          key={index}
                        >
                          <Box
                            style={{
                              height: "100px",
                              width: "100px",
                              cursor: "pointer",
                              textAlign: "center",
                              cursor: "pointer",
                              backgroundColor: getBackgroundColor(
                                table.table_status
                              ),
                            }}
                            className="d-flex position-relative border rounded mr-2 d-flex justify-content-center mb-2 mt-8"
                          >
                            <Typography
                              style={{
                                fontSize: "17.5px",
                              }}
                              className="position-relative h-100 d-flex justify-content-center align-items-center"
                            >
                              {table.table_name}
                            </Typography>
                          </Box>
                        </Link>
                      ))
                  ) : (
                    <Stack
                      className="mt-10 position-relative w-100 align-items-center"
                      spacing={2}
                      style={{ marginTop: 10 }}
                    >
                      {/* <BiIcons.BiSad size="75px" color="red" /> */}
                      <Typography style={{ color: "grey" }}>
                        Please create tables before proceeding.
                        {/* <Link
                        to={{
                          pathname: `/outlet/${shopId}/${shopName}/table`,
                          search: `&hotelId=${hotelId}`,
                          state: { index: 9 },
                        }}
                      >
                        tables
                      </Link>{" "} */}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              ) : (
                <TablesLoader />
              )}
            </Stack>
            {viewType === true && (
              <Stack className="mt-8">
                <Typography variant="h6" style={{ fontSize: "15px" }}>
                  Occupied rooms
                </Typography>
                {inhouseData ? (
                  <Stack
                    direction="row"
                    className="position-relative w-100 justify-content-start mt-4 d-flex flex-wrap wrap"
                  >
                    {inhouseData.length > 0 ? (
                      inhouseData
                        ?.filter((item) =>
                          searchForFood?.trim()?.length === 0
                            ? true
                            : item.room_ids
                                ?.toLowerCase()
                                ?.includes(searchForFood?.toLowerCase())
                        )
                        .map((data, index) => (
                          // <Link
                          //   to={{
                          //     pathname: `/outlet/${shopId}/${shopName}/table/${table.id}/${table.table_name}/placeOrder`,
                          //     search: `?hotelId=${hotelId}`,
                          //   }}
                          //   style={{
                          //     textDecoration: "none!important",
                          //     color: "black",
                          //   }}
                          // >
                          <Box
                            onClick={() => {
                              handleOpenCustomerDetails();
                              setBookingId(data?.booking_id);
                              setSelectedResIdForRoom(data?.reservation_id);
                              setRoomId(data?.room_ids);
                            }}
                            style={{
                              height: "100px",
                              width: "100px",
                              cursor: "pointer",
                              textAlign: "center",
                              cursor: "pointer",
                              backgroundColor: "#FFE5B4",
                              // backgroundColor: getBackgroundColor(table.table_status),
                            }}
                            key={index}
                            className="d-flex position-relative border rounded mr-2 d-flex justify-content-center mb-2 mt-8"
                          >
                            <Typography
                              style={{
                                fontSize: "17.5px",
                              }}
                              className="position-relative h-100 d-flex justify-content-center align-items-center"
                            >
                              {data.room_ids}
                            </Typography>
                          </Box>
                          // </Link>
                        ))
                    ) : (
                      <Stack
                        className="mt-10 position-relative w-100 align-items-center"
                        spacing={2}
                        style={{ marginTop: 10 }}
                      >
                        {/* <BiIcons.BiSad size="75px" color="red" /> */}
                        <Typography style={{ color: "grey" }}>
                          You have <b>No inhouse</b> customers currently.
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                ) : (
                  <TablesLoader />
                )}
              </Stack>
            )}

            {/* Opening the modal to verify the information */}
            <Modal
              open={customerDetails}
              onClose={handleCloseCustomerDetails}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Stack
                  direction="row"
                  className="position-relative w-100 justify-content-between p-4"
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    style={{ fontSize: "12.5px" }}
                    component="h2"
                  >
                    Verify customer details
                  </Typography>
                  <Typography
                    variant="h6"
                    id="modal-modal-description"
                    style={{ cursor: "pointer", fontSize: "15px" }}
                    onClick={() => handleCloseCustomerDetails()}
                  >
                    X
                  </Typography>
                </Stack>
                <Divider />
                <Stack className="position-relative w-100 p-4 mt-4" spacing={2}>
                  {inhouseData
                    ?.filter((data) => data?.booking_id === bookingId)
                    .map((item, index) => (
                      <>
                        <Stack
                          direction="row"
                          className="position-relative w-100 justify-content-between"
                          key={index}
                        >
                          <Typography
                            variant="h6"
                            style={{ fontSize: "12.5px" }}
                          >
                            Booking Id
                          </Typography>
                          <Typography style={{ fontSize: "12.5px" }}>
                            {item?.booking_id}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          className="position-relative w-100 justify-content-between"
                        >
                          <Typography
                            variant="h6"
                            style={{ fontSize: "12.5px" }}
                          >
                            Booking user name
                          </Typography>
                          <Typography style={{ fontSize: "12.5px" }}>
                            {item?.booking_user_name}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          className="position-relative w-100 justify-content-between"
                        >
                          <Typography
                            variant="h6"
                            style={{ fontSize: "12.5px" }}
                          >
                            In house customer name
                          </Typography>
                          <Typography style={{ fontSize: "12.5px" }}>
                            {item?.user_name}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          className="position-relative w-100 justify-content-between"
                        >
                          <Typography
                            variant="h6"
                            style={{ fontSize: "12.5px" }}
                          >
                            User phone
                          </Typography>
                          <Typography style={{ fontSize: "12.5px" }}>
                            {item?.user_phone}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          className="position-relative w-100 justify-content-between"
                        >
                          <Typography
                            variant="h6"
                            style={{ fontSize: "12.5px" }}
                          >
                            Check in date
                          </Typography>
                          <Typography style={{ fontSize: "12.5px" }}>
                            {item?.cin}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          className="position-relative w-100 justify-content-between"
                        >
                          <Typography
                            variant="h6"
                            style={{ fontSize: "12.5px" }}
                          >
                            Checkout date
                          </Typography>
                          <Typography style={{ fontSize: "12.5px" }}>
                            {item?.cout}
                          </Typography>
                        </Stack>
                      </>
                    ))}
                </Stack>
                <Stack
                  direction="row"
                  className="position-relative w-100 mt-4 p-4 justify-content-between"
                >
                  <SecondaryButton
                    text="Cancel"
                    onClick={() => handleCloseCustomerDetails()}
                  />
                  <Link
                    to={{
                      pathname: `/outlet/${shopId}/${shopName}/${shopType}/roomOrder/room/${roomId}/placeOrder`,
                      search: `&hotelId=${hotelId}`,
                      // state: custDetails,
                      state: {
                        index: location.state.index,
                        resId: selectedResIdForRoom,
                      },
                    }}
                  >
                    <Button variant="custom-button">Proceed</Button>
                  </Link>
                </Stack>
              </Box>
            </Modal>
          </Stack>
        ) : (
          <UnauthorisedAccess />
        )
      }
    />
  );
}

// export default PlaceOrderPage;

function mapStateToProps(state) {
  return {
    data: state.auth.selectedHotel,
    shopAccessControl: state.auth.shopAccessControl,
  };
}
export default connect(mapStateToProps)(PlaceOrderPage);
