import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormHelperText,
  Grid,
  OutlinedInput,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function CopyReservation() {
  // Getting the start date as well as the end date to load the data
  const [dateVal, setDateVal] = useState(dayjs(new Date()));
  const handleChange = (newVal) => {
    setDateVal(newVal);
  };
  const [dateVal2, setDateVal2] = useState(dayjs(new Date()));
  const handleChanges = (newVal) => {
    setDateVal2(newVal);
  };

  const [checkinSlot, setcheckinSlot] = useState("4");
  const [checkoutSlot, setcheckoutSlot] = useState("12");
  const [roomType, setroomType] = useState("Single Room");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [city, setcity] = useState("");
  const [address, setaddress] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [nationality, setnationality] = useState("");
  const [occupation, setoccupation] = useState("");
  const [company, setcompany] = useState("");
  const [emergencyContactName, setemergencyContactName] = useState("");
  const [emergencyContactNumber, setemergencyContactNumber] = useState("");
  const [purposeVisit, setpurposeVisit] = useState("");
  const [programAttended, setprogramAttended] = useState("");
  const [ocoCheckin, setocoCheckin] = useState("");
  const [overSeas, setoverSeas] = useState("");

  return (
    <div className="mt-4 d-flex flex-column">
      <div className="mt-4 d-flex ml-4">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Checkin"
            // inputFormat="MM/DD/YYYY"
            inputFormat="dd MMM yyyy"
            value={dateVal}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
        <FormControl
          fullWidth
          style={{ width: "6rem", marginLeft: "1rem" }}
          size="small"
        >
          <InputLabel
            style={{ width: "6rem", marginLeft: "1rem" }}
            id="demo-simple-select-label"
          >
            Checkin SLot
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => setcheckinSlot(e.target.value)}
            style={{ width: "6rem", marginLeft: "1rem" }}
            className="mr-4"
            size="small"
            label="Checkin SLot"
            value={checkinSlot}
          >
            <MenuItem value={"4"}>4 AM</MenuItem>
            <MenuItem value={"12"}>12 PM</MenuItem>
            <MenuItem value={"16"}>4 PM</MenuItem>
          </Select>
        </FormControl>
        <div style={{ marginLeft: "3rem" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Checkout"
              // inputFormat="MM/DD/YYYY"
              inputFormat="dd MMM yyyy"
              value={dateVal2}
              onChange={handleChanges}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>
        <FormControl
          fullWidth
          style={{ width: "6rem", marginLeft: "1rem" }}
          size="small"
        >
          <InputLabel
            style={{ width: "6rem", marginLeft: "1rem" }}
            id="demo-simple-select-label"
          >
            Checkout slot
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => setcheckoutSlot(e.target.value)}
            style={{ width: "6rem", marginLeft: "1rem" }}
            className="mr-4"
            size="small"
            label="Checkout slot"
            value={checkoutSlot}
          >
            <MenuItem value={"4"}>4 AM</MenuItem>
            <MenuItem value={"12"}>12 PM</MenuItem>
            <MenuItem value={"16"}>4 PM</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          style={{ width: "6rem", marginLeft: "1rem" }}
          size="small"
        >
          <InputLabel
            style={{ width: "6rem", marginLeft: "1rem" }}
            id="demo-simple-select-label"
          >
            Room type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => setroomType(e.target.value)}
            style={{ width: "6rem", marginLeft: "1rem" }}
            className="mr-4"
            size="small"
            label="Room type"
            value={roomType}
          >
            <MenuItem value={"Single Room"}>Single Room</MenuItem>
            <MenuItem value={"Double Room"}>Double Room</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="d-flex mt-3">
        <TextField
          label="Name"
          className="input-lg mr-3 ml-2"
          placeholder="Enter  name here"
          onChange={(e) => setname(e.target.value)}
          value={name}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Email"
          className="input-lg mr-3"
          placeholder="Enter  email here"
          onChange={(e) => setemail(e.target.value)}
          value={email}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Phone"
          className="input-lg mr-3"
          placeholder="Enter  phone here"
          onChange={(e) => setphone(e.target.value)}
          value={phone}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="DOB"
          className="input-lg mr-3"
          placeholder="Enter  DOB here"
          //   onChange={(e) => setphone(e.target.value)}
          value={"17/07/1998"}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Address"
          className="input-lg mr-3"
          placeholder="Enter  address here"
          onChange={(e) => setphone(e.target.value)}
          value={phone}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="City"
          className="input-lg mr-3"
          placeholder="Enter  city here"
          onChange={(e) => setcity(e.target.value)}
          value={city}
          name="schedule_name"
          id="schedule_name"
        />
      </div>
      <div className="d-flex mt-4">
        <TextField
          label="State"
          className="input-lg mr-3 ml-2"
          placeholder="Enter  state here"
          onChange={(e) => setstate(e.target.value)}
          value={state}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Country"
          className="input-lg mr-3 "
          placeholder="Enter  Country here"
          onChange={(e) => setcountry(e.target.value)}
          value={country}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Zipcode"
          className="input-lg mr-3 "
          placeholder="Enter  Zipcode here"
          onChange={(e) => setzipcode(e.target.value)}
          value={zipcode}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="nationality"
          className="input-lg mr-3 "
          placeholder="Enter  nationality here"
          onChange={(e) => setnationality(e.target.value)}
          value={nationality}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Occupation"
          className="input-lg mr-3 "
          placeholder="Enter  Occupation here"
          onChange={(e) => setoccupation(e.target.value)}
          value={occupation}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Company"
          className="input-lg mr-3 "
          placeholder="Enter  Company here"
          onChange={(e) => setcompany(e.target.value)}
          value={company}
          name="schedule_name"
          id="schedule_name"
        />
      </div>
      <div className="d-flex mt-4">
        <TextField
          label="Emergency name"
          className="input-lg mr-3 ml-2"
          placeholder="Enter  Emergency name here"
          onChange={(e) => setemergencyContactName(e.target.value)}
          value={emergencyContactName}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Emergency contact"
          className="input-lg mr-3 "
          placeholder="Enter Emergency contact here"
          onChange={(e) => setemergencyContactNumber(e.target.value)}
          value={emergencyContactNumber}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Purpose of visit"
          className="input-lg mr-3 "
          placeholder="Purpose of visit"
          onChange={(e) => setpurposeVisit(e.target.value)}
          value={purposeVisit}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Program Attended"
          className="input-lg mr-3 "
          placeholder="Program Attended"
          onChange={(e) => setprogramAttended(e.target.value)}
          value={programAttended}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="Approved by overSeas"
          className="input-lg mr-3 "
          placeholder="Enter  Approved by overSeas here"
          onChange={(e) => setoverSeas(e.target.value)}
          value={overSeas}
          name="schedule_name"
          id="schedule_name"
        />
        <TextField
          label="OCO Checkin"
          className="input-lg mr-3 "
          placeholder="Enter  OCO Checkin here"
          onChange={(e) => setocoCheckin(e.target.value)}
          value={ocoCheckin}
          name="schedule_name"
          id="schedule_name"
        />
      </div>
      <Button variant="custom-button"> Save</Button>
    </div>
  );
}

export default CopyReservation;
