import React from "react";

function TablesLoader() {
  return (
    <div
      className={`placeOrderTables position-relative w-100 d-flex justify-content-start flex-wrap wrap mr-5`}
    >
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
      <div className="tableBoxes"> </div>
    </div>
  );
}

export default TablesLoader;
