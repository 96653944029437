import React, { useState } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import "../TaxCategory/View/TaxCategoryModal.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import ImageUploading from "react-images-uploading";
import { connect } from "react-redux";
import { setLogoAndFavicon } from "./apis/BrandingModalApi";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

const BrandingModal = (props) => {
  //images

  const [images, setImages] = useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const [faviconImages, setFaviconImages] = useState([]);

  const onChangeFavicon = (faviconImageList, addUpdateIndex) => {
    setFaviconImages(faviconImageList);
  };

  const [loading, setloading] = useState(false);
  const [encryptMode, setEncryptMode] = useState("SSL");
  const save = async () => {
    setloading(true);
    const payload = {
      hostUrl: window.location.hostname,
      groupEmail: props.data.email,
      logoData: logoImage && logoImage[1],
      logoType: logoImgType && logoImgType[0],
      faviconData: faviconImage && faviconImage[1],
      faviconType: faivconImgType && faivconImgType[0],
      status: "ENABLED",
    };
    const res = await setLogoAndFavicon(payload);
    setloading(false);
    if (res === "Operation Successful") {
      toast.success("Successfully saved");
    } else {
      toast.error("Something went wrong !");
    }
  };

  var logoImage = images[images.length - 1]?.data_url.split(",");
  var faviconImage =
    faviconImages[faviconImages.length - 1]?.data_url.split(",");
  var faivconformat =
    faviconImages[faviconImages.length - 1]?.data_url.split(",");
  var faivconimgFormat = faivconformat && faivconformat[0]?.split("/");
  var faivconImgType = faivconimgFormat && faivconimgFormat[1]?.split(";");

  var logoformat = images[images.length - 1]?.data_url.split(",");
  var logoimgFormat = logoformat && logoformat[0]?.split("/");
  var logoImgType = logoimgFormat && logoimgFormat[1]?.split(";");

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.hide}
        dialogClassName={"myModalBody"}
        // animation={false}
        contentClassName={"myModalContent"}
      >
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <Button variant="white" onClick={props.hide}>
              {" "}
              X{" "}
            </Button>
          </div>
        </ModalTitle>
        <ModalBody>
          <div>
            <div className="d-flex flex-column">
              <h2>Branding</h2>
              <p>Use this page for your branding.</p>
            </div>
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">User interface</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="d-flex flex-column ">
                      <div className="d-flex align-items-center mr-4">
                        <h5 className="mb-0" style={{ marginRight: "33px" }}>
                          Logo
                        </h5>
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper d-flex align-items-center">
                              <label htmlFor="contained-button-file">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  Upload
                                </Button>
                              </label>
                              <label htmlFor="icon-button-file">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                ></IconButton>
                              </label>

                              <div className="image-item">
                                <img
                                  src={
                                    imageList[imageList.length - 1]?.data_url
                                  }
                                  alt=""
                                  width="100px"
                                  height="60px"
                                />
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                      <div className="d-flex align-items-center mt-4">
                        <h5 className="mb-0 mr-3">Favicon</h5>
                        <ImageUploading
                          multiple
                          value={faviconImages}
                          onChange={onChangeFavicon}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({
                            faviconImageList,
                            onImageUpload,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper d-flex align-items-center">
                              <label htmlFor="contained-button-file">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  Upload
                                </Button>
                              </label>
                              <label htmlFor="icon-button-file">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                ></IconButton>
                              </label>

                              <div className="image-item">
                                <img
                                  src={
                                    faviconImages[faviconImages.length - 1]
                                      ?.data_url
                                  }
                                  alt=""
                                  width="100px"
                                  height="60px"
                                />
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                    <Button
                      className="float-right mr-4 mb-3 mt-4"
                      variant="custom-button"
                      onClick={save}
                      disabled={loading}
                    >
                      Save
                      {loading && (
                        <CircularProgress
                          size={15}
                          color="inherit"
                          sx={{ ml: 1.5 }}
                        />
                      )}
                    </Button>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">SMTP email settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid container spacing={2}>
                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          <Typography>Email address</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <div className="d-flex flex-column">
                            <OutlinedInput
                              label=""
                              notched={false}
                              placeholder="Enter email id"
                              style={{
                                height: "2.5rem",
                              }}
                              onChange={(e) => {}}
                              fullWidth
                            />
                            <p className="text-muted mb-0">
                              Enter the smtp email address
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          <Typography>Email label</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <div className="d-flex flex-column">
                            <OutlinedInput
                              label=""
                              notched={false}
                              placeholder="Enter email label"
                              style={{
                                height: "2.5rem",
                              }}
                              onChange={(e) => {}}
                              fullWidth
                            />
                            <p className="text-muted mb-0">
                              {`Enter the smtp label like John will <John.will@example.com>`}
                            </p>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          <Typography>Email server</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <div className="d-flex flex-column">
                            <OutlinedInput
                              label=""
                              notched={false}
                              placeholder="Enter email server"
                              style={{
                                height: "2.5rem",
                              }}
                              onChange={(e) => {}}
                              fullWidth
                            />
                            <p className="text-muted mb-0">
                              Enter the smtp server
                            </p>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          <Typography>Username</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <div className="d-flex flex-column">
                            <OutlinedInput
                              label=""
                              notched={false}
                              placeholder="Enter username"
                              style={{
                                height: "2.5rem",
                              }}
                              onChange={(e) => {}}
                              fullWidth
                            />
                            <p className="text-muted mb-0">
                              Enter the smtp username
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          <Typography>Password</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <div className="d-flex flex-column">
                            <OutlinedInput
                              label=""
                              notched={false}
                              placeholder="Enter password"
                              style={{
                                height: "2.5rem",
                              }}
                              onChange={(e) => {}}
                              fullWidth
                            />
                            <p className="text-muted mb-0">
                              Enter the smtp passsword
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          <Typography>Encryption mode</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <div className="d-flex flex-column">
                            <FormControl
                              fullWidth
                              style={{
                                height: "2.5rem",
                              }}
                            >
                              <InputLabel
                                style={{
                                  height: "2.5rem",
                                }}
                                id="demo-simple-select-label"
                              ></InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => setEncryptMode(e.target.value)}
                                style={{ marginLeft: "0rem", height: "2.5rem" }}
                                className="mr-4"
                                label=""
                                value={encryptMode}
                                notched={false}
                              >
                                <MenuItem value={"SSL"}>SSL</MenuItem>
                                <MenuItem value={"TLS"}>TLS</MenuItem>
                              </Select>
                            </FormControl>
                            <p className="text-muted mb-0">
                              Enter the smtp encryption mode
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          <Typography>Port</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <div className="d-flex flex-column">
                            <OutlinedInput
                              label=""
                              notched={false}
                              placeholder="Enter port"
                              style={{
                                height: "2.5rem",
                              }}
                              onChange={(e) => {}}
                              fullWidth
                            />
                            <p className="text-muted mb-0">
                              Enter the smtp port
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Button
                      className="float-right mr-4 mb-3 mt-4"
                      variant="custom-button"
                    >
                      Save
                    </Button>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth };
}
export default connect(mapStateToProps)(BrandingModal);

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
