import React from "react";

function DeniedAccess() {
  return (
    /*<div>
      <img
        src={require("./noaccess_no_dog.png")}
        style={{
          width: "30%",
          height: "30%",
          marginLeft: "31%",
          marginTop: "14%",
        }}
      />
    </div>*/
      <div style={{
          width: "50%",
          height: "30%",
          marginLeft: "30%",
          marginTop: "10%",
          fontSize: "30px",
          color: "#737070"
      }}>
          Permission Denied. Sorry, you do not have access to this space.
      </div>
  );
}

export default DeniedAccess;
