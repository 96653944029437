import React from "react";
import Stack from "@mui/material/Stack";

function PayNowLoader() {
  return (
    <div>
      <Stack spacing={5}>
        <div className="paynowloader"></div>
      </Stack>
    </div>
  );
}

export default PayNowLoader;
