import { CircularProgress, TextField } from "@mui/material";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import CardSwipe from "react-card-swipe";
import { djangoHandleDataMutationRequest } from "../../../api";
import { getCardType } from "../../../Utils/cardType";
import amplitude from "amplitude-js";

function AddCard({
  eventProperties,
  hotelId,
  custEmail,
  closeAddCard,
  updateInvoice,
  loggedUserEmail,
  updateHotelPgData,
  updateSummaryData,
  bookingId,
}) {
  // fields for for add Card

  const [addCardFirstName, setAddCardFirstName] = useState("");
  const [addCardLastName, setAddCardLastName] = useState("");
  const [addCardNumber, setAddCardNumber] = useState("");
  const [addCardType, setAddCardType] = useState();
  const [addCardCVV, setAddCardCVV] = useState("");
  const [addCardExpiry, setAddCardExpiry] = useState("");
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    number: false,
    cvv: false,
    expiry: false,
  });

  useEffect(() => {
    setAddCardNumber(addCardNumber?.replace(/\s/g, ""));
    if (addCardNumber?.length > 1) {
      const type = getCardType(addCardNumber?.slice(0, 16))?.type;
      setAddCardType(type);
    }
  }, [addCardNumber, bookingId]);

  // add Card loading
  const [addCardLoading, setAddCardLoading] = useState(false);

  // handling the add card flow

  const handleAddCard = () => {
    if (
      addCardFirstName?.length > 0 &&
      !/^[0-9]*$/.test(addCardFirstName) &&
      addCardLastName?.length > 0 &&
      !/^[0-9]*$/.test(addCardLastName) &&
      checkExpiry() &&
      checkLuhn() &&
      addCardNumber.length > 12 &&
      addCardCVV?.length <= 4
    ) {
      setAddCardLoading(true);
      djangoHandleDataMutationRequest("POST", "invoice/saveCustomerCardOwn/", {
        hotelId: hotelId,
        name: addCardFirstName + " " + addCardLastName,
        first_name: addCardFirstName,
        last_name: addCardLastName,
        card_no: addCardNumber?.replace(/\s/g, "")?.slice(0, 16),
        card_issuer: addCardType?.slice(0, 4),
        cvv: addCardCVV,
        exp_date: addCardExpiry,
        cust_email: custEmail,
        logged_user_email: loggedUserEmail,
        booking_id: bookingId,
      })
        .then((res) => toast.success("New card added!"))
        .then((res) =>
          amplitude
            .getInstance()
            .logEvent(
              "USER PREFORMED ADD CARD OPERATION THROUGH FOLIO PAGE",
              eventProperties
            )
        )
        .then((res) => updateInvoice())
        .then((res) => updateSummaryData())
        .then((res) => updateHotelPgData())
        .then((res) => setAddCardLoading(false))
        .then((res) => closeAddCard())
        .then((res) => setAddCardFirstName())
        .then((res) => setAddCardLastName())
        .then((res) => setAddCardNumber())
        .then((res) => setAddCardType())
        .then((res) => setAddCardCVV())
        .then((res) => setAddCardExpiry())
        .catch((err) => {
          toast.error(err);
          // setAddCardLoading(false);
        })
        .then((res) => setAddCardLoading(false));
    }

    // if (
    //   addCardCVV?.length > 4 ||
    //   addCardCVV?.length <= 2 ||
    //   addCardCVV === undefined
    // ) {
    //   error["cvv"] = true;
    // }

    if (addCardFirstName?.length === 0) {
      error["firstName"] = true;
    }

    if (addCardLastName?.length === 0) {
      error["lastName"] = true;
    }

    if (!checkLuhn() || addCardNumber.length < 12) {
      error["number"] = true;
    }

    if (!checkExpiry()) {
      error["expiry"] = true;
    }

    setError({ ...error });
  };

  const checkExpiry = () => {
    const currentYear = new Date()?.getFullYear();
    const currentMonth = new Date()?.getMonth();
    const minExpiry =
      (currentMonth > 8 ? `${currentMonth + 1}` : `0${currentMonth + 1}`) +
      `${currentYear}`.slice(2, 4);
    const expiryValidity =
      addCardExpiry?.length === 4 &&
      (addCardExpiry?.slice(2, 4) > minExpiry?.slice(2, 4)
        ? true
        : addCardExpiry?.slice(2, 4) === minExpiry?.slice(2, 4)
        ? addCardExpiry?.slice(0, 2) >= minExpiry?.slice(0, 2)
        : false);
    return expiryValidity;
  };

  useEffect(() => {
    setError({
      firstName: false,
      lastName: false,
      number: false,
      cvv: false,
      expiry: false,
    });
  }, [
    addCardNumber,
    addCardExpiry,
    addCardFirstName,
    addCardLastName,
    addCardCVV,
  ]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  const checkLuhn = () => {
    let whitelist = [
      "4111111111111111",
      "4012888888881881",
      "4005562231212149",
      "5454545454545454",
      "5405222222222226",
      "6011000995500000",
      "371449635398431",
    ];
    if (whitelist.includes(addCardNumber)) {
      return true;
    }
    let s = 0;
    let doubleDigit = false;
    for (let i = addCardNumber?.length - 1; i >= 0; i--) {
      let digit = +addCardNumber[i];
      if (doubleDigit) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      s += digit;
      doubleDigit = !doubleDigit;
    }
    let flag = s % 10 === 0;
    if (flag) {
      // console.log("Luhn check passed");
    }
    return flag;
  };

  //card swipe

  const [swipeData, setswipeData] = useState({});

  const handleSwipeSuccess = (swipeData) => {
    setswipeData(swipeData);
    console.log(swipeData);
    // const data = JSON.stringify(swipeData);
    const expMonth = swipeData?.expMonth;
    const expYear = swipeData?.expYear;
    setAddCardFirstName(swipeData?.firstName);
    setAddCardLastName(swipeData?.lastName);
    setAddCardNumber(swipeData?.account);
    setAddCardExpiry(expMonth + expYear);
  };

  useEffect(() => {
    CardSwipe.init({
      success: handleSwipeSuccess,
      debug: false,
    });
  }, []);

  return (
    <div>
      {/* <React.Fragment>
        <pre>{JSON.stringify(swipeData)}</pre>
      </React.Fragment> */}
      <div className="amountWrapper">
        <div className="fieldWrapper d-flex justify-content-between">
          <TextField
            required
            id="outlined-name"
            type={"text"}
            label="First name"
            name="firstName"
            value={addCardFirstName}
            onChange={(e) =>
              /^[a-zA-Z0-9 ]*$/.test(e.target.value) &&
              setAddCardFirstName(e.target.value)
            }
            error={error.firstName}
            helperText={
              error.firstName
                ? addCardFirstName?.length === 0
                  ? "Enter first name"
                  : "Enter correct name"
                : ""
            }
            sx={{ width: "48%" }}
          />
          <TextField
            required
            id="outlined-name"
            label="Last name"
            name="lastName"
            type={"text"}
            value={addCardLastName}
            onChange={(e) =>
              /^[a-zA-Z0-9 ]*$/.test(e.target.value)
                ? setAddCardLastName(e.target.value)
                : setAddCardLastName(addCardLastName)
            }
            error={error.lastName}
            helperText={
              error.lastName
                ? addCardLastName?.length === 0
                  ? "Enter last name"
                  : "Enter correct name"
                : ""
            }
            sx={{ width: "48%" }}
          />
        </div>
        <div className="fieldWrapper d-flex">
          <TextField
            required
            id="outlined-name"
            label="Card Number"
            name="cardNumber"
            type={"text"}
            value={addCardNumber}
            onChange={(e) =>
              e.target.value.length <= 16 &&
              (/^[0-9]*$/.test(e.target.value)
                ? setAddCardNumber(e.target.value)
                : setAddCardNumber(addCardNumber))
            }
            error={error.number || !checkLuhn() ? true : false}
            helperText={
              error.number || !checkLuhn() ? "invalid card number" : ""
            }
            sx={{ width: "100%" }}
          />
          {getCardType(addCardNumber?.slice(0, 16)) && (
            <img
              style={{
                width: "60px",
                height: "40px",
                marginTop: "5px",
                marginLeft: "-80px",
                objectFit: "contain",
              }}
              src={getCardType(addCardNumber?.slice(0, 16))?.imageUrl}
              alt=" "
            />
          )}
        </div>
        <div className="fieldWrapper d-flex justify-content-between">
          <TextField
            // required
            id="outlined-name"
            label="Card CVV"
            name="cardCVV"
            type={"text"}
            // value={cardNumberCVVFormat(addCardCVV)}
            value={addCardCVV}
            onChange={(e) =>
              e.target.value.length <= 4 &&
              (/^[0-9]*$/.test(e.target.value)
                ? setAddCardCVV(e.target.value)
                : setAddCardCVV(addCardCVV))
            }
            sx={{ width: "48%" }}
            // error={addCardCVV?.length > 4 || error.cvv}
            // helperText={
            //   addCardCVV?.length > 4 || error.cvv ? "invalid cvv" : ""
            // }
          />

          <TextField
            required
            id="outlined-name"
            label="Card Expiry (MMYY)"
            name="cardExpiry"
            type={"text"}
            value={addCardExpiry}
            onChange={(e) =>
              e.target.value?.length < 5 &&
              (/^[0-9]*$/.test(e.target.value)
                ? setAddCardExpiry(e.target.value)
                : setAddCardExpiry(addCardExpiry))
            }
            error={
              (!checkExpiry() && addCardExpiry?.length > 0) ||
              error.expiry ||
              Number(addCardExpiry.substring(0, 2)) > 12 ||
              Number(addCardExpiry.substring(2, 4)) > 99
            }
            helperText={
              (!checkExpiry() && addCardExpiry?.length > 0) ||
              error.expiry ||
              Number(addCardExpiry.substring(0, 2)) > 12 ||
              Number(addCardExpiry.substring(2, 4)) > 99
                ? "invalid expiry"
                : ""
            }
            sx={{ width: "48%" }}
          />
        </div>
      </div>
      <div className="settleDuesSubmitBtns">
        <button className="cancelBtn">Cancel</button>
        {addCardLoading === false ? (
          <button className="submit w-auto" onClick={() => handleAddCard()}>
            Add Card
          </button>
        ) : (
          <button className="submit">
            <CircularProgress size="15px" />
          </button>
        )}
      </div>
    </div>
  );
}

export default AddCard;
