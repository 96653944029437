import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { toast, ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import CardSwipe from "react-card-swipe";
import {
  handleSpringDataMutationRequest,
  djangoHandleDataMutationRequest,
} from "../../../api";
import { getAllImagesAndUrl } from "../../../pages/BookingDashboard/apis/BrandingModalApi";
import { getCardType } from "../../../Utils/cardType";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";

function AddCardPayment() {
  const url = new URL(window.location.href);
  const bookingId = url.searchParams.get("bookingId");
  const custEmail = url.searchParams.get("custEmail");
  const hotelId = url.searchParams.get("hotelId");
  const [addCardFirstName, setAddCardFirstName] = useState("");
  const [addCardLastName, setAddCardLastName] = useState("");
  const [addCardNumber, setAddCardNumber] = useState("");
  const [addCardType, setAddCardType] = useState();
  const [addCardCVV, setAddCardCVV] = useState("");
  const [addCardExpiry, setAddCardExpiry] = useState("");
  const [hotelLogo, sethotelLogo] = useState("");
  const [info, setinfo] = useState(null);
  const [hotelImages, sethotelImages] = useState([]);
  const [isSuccess, setisSuccess] = useState(false);
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    number: false,
    cvv: false,
    expiry: false,
  });

  const [ImageLogo, setImageLogo] = useState(null);
  const [loader, setloader] = useState(false);
  const getAllData = async () => {
    try {
      const data = await getAllImagesAndUrl();
      setImageLogo(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setisSuccess(false);
    getAllData();
    getHotelLogo();
    getUpdatedData();
    getHotelImages();
  }, []);

  useEffect(() => {
    setAddCardNumber(addCardNumber?.replace(/\s/g, ""));
    if (addCardNumber?.length > 1) {
      const type = getCardType(addCardNumber?.slice(0, 16))?.type;
      setAddCardType(type);
    }
  }, [addCardNumber, bookingId]);

  // add Card loading
  const [addCardLoading, setAddCardLoading] = useState(false);

  // handling the add card flow

  const getHotelLogo = () => {
    axios
      .get(
        `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/image/get-hotel-logo?hotelId=${hotelId}&hotel_id=${hotelId}`
      )
      .then((res) => sethotelLogo(res.data))

      .catch((err) => {
        console.error(err.response.data);
      });
  };

  const handleAddCard = () => {
    if (
      addCardFirstName?.length > 0 &&
      !/^[0-9]*$/.test(addCardFirstName) &&
      addCardLastName?.length > 0 &&
      !/^[0-9]*$/.test(addCardLastName) &&
      checkExpiry() &&
      checkLuhn() &&
      addCardNumber.length > 12 &&
      addCardCVV?.length <= 4
    ) {
      setAddCardLoading(true);

      axios
        .post(
          `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/customer-card/add-new-card?hotelId=${hotelId}&hotel_id=${hotelId}`,
          {
            hotelId: hotelId,
            cardName: addCardFirstName + " " + addCardLastName,
            cardNum: addCardNumber?.replace(/\s/g, "")?.slice(0, 16),
            cardCvv: addCardCVV,
            cardExpiry: addCardExpiry,
            custEmail: custEmail,
            bookingId: bookingId,
          }
        )
        .then((res) => toast.success("Successfull"))
        .then((res) => setisSuccess(true))
        .then((res) => setAddCardLoading(false))
        .then((res) => setAddCardFirstName(""))
        .then((res) => setAddCardLastName(""))
        .then((res) => setAddCardNumber(""))
        .then((res) => setAddCardType(""))
        .then((res) => setAddCardCVV(""))
        .then((res) => setAddCardExpiry(""))
        .then((res) => {
          setAddCardExpiry();
          setloader(false);
        })
        .catch((err) => {
          toast.error(err.response.data);
          setloader(false);
          setisSuccess(false);
          // setAddCardLoading(false);
        })
        .then((res) => setAddCardLoading(false));
    }

    if (addCardFirstName?.length === 0) {
      error["firstName"] = true;
    }

    if (addCardLastName?.length === 0) {
      error["lastName"] = true;
    }

    if (!checkLuhn() || addCardNumber.length < 12) {
      error["number"] = true;
    }

    if (!checkExpiry()) {
      error["expiry"] = true;
    }

    setError({ ...error });
  };

  const checkExpiry = () => {
    const currentYear = new Date()?.getFullYear();
    const currentMonth = new Date()?.getMonth();
    const minExpiry =
      (currentMonth > 8 ? `${currentMonth + 1}` : `0${currentMonth + 1}`) +
      `${currentYear}`.slice(2, 4);
    const expiryValidity =
      addCardExpiry?.length === 4 &&
      (addCardExpiry?.slice(2, 4) > minExpiry?.slice(2, 4)
        ? true
        : addCardExpiry?.slice(2, 4) === minExpiry?.slice(2, 4)
        ? addCardExpiry?.slice(0, 2) >= minExpiry?.slice(0, 2)
        : false);
    return expiryValidity;
  };

  useEffect(() => {
    setError({
      firstName: false,
      lastName: false,
      number: false,
      cvv: false,
      expiry: false,
    });
  }, [
    addCardNumber,
    addCardExpiry,
    addCardFirstName,
    addCardLastName,
    addCardCVV,
  ]);

  useEffect(() => {
    // console.log(error);
  }, [error]);

  const checkLuhn = () => {
    let whitelist = [
      "4111111111111111",
      "4012888888881881",
      "4005562231212149",
      "5454545454545454",
      "5405222222222226",
      "6011000995500000",
      "371449635398431",
    ];
    if (whitelist.includes(addCardNumber)) {
      return true;
    }
    let s = 0;
    let doubleDigit = false;
    for (let i = addCardNumber?.length - 1; i >= 0; i--) {
      let digit = +addCardNumber[i];
      if (doubleDigit) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      s += digit;
      doubleDigit = !doubleDigit;
    }
    let flag = s % 10 === 0;
    if (flag) {
      // console.log("Luhn check passed");
    }
    return flag;
  };

  //card swipe

  const handleSwipeSuccess = (swipeData) => {
    // const data = JSON.stringify(swipeData);
    const expMonth = swipeData?.expMonth;
    const expYear = swipeData?.expYear;
    setAddCardFirstName(swipeData?.firstName);
    setAddCardLastName(swipeData?.lastName);
    setAddCardNumber(swipeData?.account);
    setAddCardExpiry(expMonth + expYear);
  };

  useEffect(() => {
    CardSwipe.init({
      success: handleSwipeSuccess,
      debug: false,
    });
  }, []);

  const getUpdatedData = () => {
    axios
      .get(
        `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/folio/invoice-data-render/?bookingId=${bookingId}&fromDB=true&hotelId=${hotelId}&hotel_id=${hotelId}`
      )
      .then((res) => setinfo(res.data))

      .catch((err) => {
        console.error(err.response.data);
      });
  };
  const getHotelImages = () => {
    axios
      .get(
        `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/image/get-hotel-images/?hotelId=${hotelId}&hotel_id=${hotelId}`
      )
      .then((res) => {
        sethotelImages(res.data.imageList);
      })

      .catch((err) => {
        console.error(err.response.data);
      });
  };
  console.log("info", info, hotelImages);

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        backgroundColor: "#ffffff",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        wid
      />

      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={3}
        xl={3}
        className="hotelLogoWrapper"
      >
        <img className="hotelLogo" src={hotelLogo} alt="No image found"></img>
      </Grid>

      {!isSuccess ? (
        <div className="d-flex w-100">
          <div className="contentWrapper">
            {" "}
            <div className="d-flex mt-3" style={{ marginLeft: "2rem" }}>
              <div>
                <div className="d-flex align-items-center mb-4">
                  {" "}
                  <h4 className="mb-0"> {`Hotel name : `}</h4>
                  <p
                    className="mb-0 ml-3"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {info?.hotel?.hotel_name}
                  </p>
                </div>

                <div className="d-flex align-items-center mb-4">
                  {" "}
                  <h4 className="mb-0">{`Booking id : `}</h4>
                  <p
                    className="mb-0 ml-3"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {bookingId}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-4">
                  {" "}
                  <h4 className="mb-0">{`Name : `}</h4>
                  <p className="mb-0 ml-3" style={{ fontSize: "16px" }}>
                    {info?.invoice?.customer_name}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <h4 className="mb-0">{`Check-in : `}</h4>
                  <p className="mb-0 ml-3" style={{ fontSize: "16px" }}>
                    {moment(
                      info?.invoice?.checkin.replace("T", " "),
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("MMM DD YYYY h:mm a")}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-4">
                  {" "}
                  <h4 className="mb-0">{`Check-out : `}</h4>
                  <p className="mb-0 ml-3" style={{ fontSize: "16px" }}>
                    {" "}
                    {moment(
                      info?.invoice?.checkout.replace("T", " "),
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("MMM DD YYYY h:mm a")}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                marginLeft: "2rem",
                marginRight: "2rem",
                marginTop: "1rem",
              }}
            >
              <div className="amountWrapper" style={{ width: "100%" }}>
                <div className="d-flex">
                  <DoneIcon style={{ color: "#2F7000" }} />
                  <p style={{ color: "#2F7000", marginRight: "2rem" }}>
                    We use secure transmission
                  </p>
                  <DoneIcon style={{ color: "#2F7000" }} />
                  <p style={{ color: "#2F7000" }}>
                    We protect your personal information
                  </p>
                </div>
                <div className="fieldWrapper d-flex justify-content-between">
                  <TextField
                    required
                    id="outlined-name"
                    type={"text"}
                    label="First name"
                    name="firstName"
                    value={addCardFirstName}
                    onChange={(e) =>
                      /^[a-zA-Z0-9 ]*$/.test(e.target.value) &&
                      setAddCardFirstName(e.target.value)
                    }
                    error={error.firstName}
                    helperText={
                      error.firstName
                        ? addCardFirstName?.length === 0
                          ? "Enter first name"
                          : "Enter correct name"
                        : ""
                    }
                    sx={{ width: "48%" }}
                  />
                  <TextField
                    required
                    id="outlined-name"
                    label="Last name"
                    name="lastName"
                    type={"text"}
                    value={addCardLastName}
                    onChange={(e) =>
                      /^[a-zA-Z0-9 ]*$/.test(e.target.value)
                        ? setAddCardLastName(e.target.value)
                        : setAddCardLastName(addCardLastName)
                    }
                    error={error.lastName}
                    helperText={
                      error.lastName
                        ? addCardLastName?.length === 0
                          ? "Enter last name"
                          : "Enter correct name"
                        : ""
                    }
                    sx={{ width: "48%" }}
                  />
                </div>
                <div
                  className="fieldWrapper d-flex"
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                >
                  <TextField
                    required
                    id="outlined-name"
                    label="Card Number"
                    name="cardNumber"
                    type={"text"}
                    value={addCardNumber}
                    onChange={(e) =>
                      e.target.value.length <= 16 &&
                      (/^[0-9]*$/.test(e.target.value)
                        ? setAddCardNumber(e.target.value)
                        : setAddCardNumber(addCardNumber))
                    }
                    error={error.number || !checkLuhn() ? true : false}
                    helperText={
                      error.number || !checkLuhn() ? "invalid card number" : ""
                    }
                    sx={{ width: "100%" }}
                  />
                  {getCardType(addCardNumber?.slice(0, 16)) && (
                    <img
                      style={{
                        width: "60px",
                        height: "40px",
                        marginTop: "5px",
                        marginLeft: "-80px",
                        objectFit: "contain",
                      }}
                      src={getCardType(addCardNumber?.slice(0, 16))?.imageUrl}
                      alt=" "
                    />
                  )}
                </div>
                <div className="fieldWrapper d-flex justify-content-between">
                  <TextField
                    // required
                    id="outlined-name"
                    label="Card CVV"
                    name="cardCVV"
                    type={"text"}
                    // value={cardNumberCVVFormat(addCardCVV)}
                    value={addCardCVV}
                    onChange={(e) =>
                      e.target.value.length <= 4 &&
                      (/^[0-9]*$/.test(e.target.value)
                        ? setAddCardCVV(e.target.value)
                        : setAddCardCVV(addCardCVV))
                    }
                    sx={{ width: "48%" }}
                    // error={addCardCVV?.length > 4 || error.cvv}
                    // helperText={
                    //   addCardCVV?.length > 4 || error.cvv ? "invalid cvv" : ""
                    // }
                  />

                  <TextField
                    required
                    id="outlined-name"
                    label="Card Expiry (MMYY)"
                    name="cardExpiry"
                    type={"text"}
                    value={addCardExpiry}
                    onChange={(e) =>
                      e.target.value?.length < 5 &&
                      (/^[0-9]*$/.test(e.target.value)
                        ? setAddCardExpiry(e.target.value)
                        : setAddCardExpiry(addCardExpiry))
                    }
                    error={
                      (!checkExpiry() && addCardExpiry?.length > 0) ||
                      error.expiry ||
                      (addCardExpiry?.length > 0 &&
                        Number(addCardExpiry.substring(0, 2)) > 12) ||
                      (addCardExpiry?.length > 0 &&
                        Number(addCardExpiry.substring(2, 4)) > 99)
                    }
                    helperText={
                      (!checkExpiry() && addCardExpiry?.length > 0) ||
                      error.expiry ||
                      (addCardExpiry?.length > 0 &&
                        Number(addCardExpiry.substring(0, 2)) > 12) ||
                      (addCardExpiry?.length > 0 &&
                        Number(addCardExpiry.substring(2, 4)) > 99)
                        ? "invalid expiry"
                        : ""
                    }
                    sx={{ width: "48%" }}
                  />
                </div>
              </div>
              <div className="settleDuesSubmitBtns">
                <Button
                  className="submit w-auto"
                  variant="custom-button"
                  onClick={() => handleAddCard()}
                  type="submit"
                  disabled={addCardLoading}
                  style={{ float: "right", marginTop: "1rem" }}
                >
                  {addCardLoading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Save Card"
                  )}
                </Button>
              </div>
            </div>
            <div style={{ marginTop: "5rem", marginLeft: "2rem" }}>
              <img
                // style={{
                //   maxHeight: "60px",
                //   maxWidth: "200px",
                //   cursor: "pointer",
                // }}
                className="visaLogo"
                src="https://logos-world.net/wp-content/uploads/2020/04/Visa-Logo.png"
              ></img>
              <img
                style={{
                  maxHeight: "60px",
                  maxWidth: "200px",
                  cursor: "pointer",
                  marginRight: "1rem",
                  marginLeft: "1rem",
                }}
                src="https://www.freepnglogos.com/uploads/mastercard-png/mastercard-logo-png-transparent-svg-vector-bie-supply-0.png"
              ></img>
              <img
                style={{
                  maxHeight: "60px",
                  maxWidth: "200px",
                  cursor: "pointer",
                }}
                src="https://www.discover.com/company/images/newsroom/media-downloads/discover.png"
              ></img>
              <img
                // style={{
                //   maxHeight: "60px",
                //   maxWidth: "200px",
                //   cursor: "pointer",
                //   mixBlendMode: "darken",
                //   marginRight: "1rem",
                //   marginLeft: "1rem",
                // }}
                className="amexLogo"
                src="https://storage.googleapis.com/prod-images-bucket/cardLogos/amex1.png"
              ></img>
              <img
                // style={{
                //   maxHeight: "60px",
                //   maxWidth: "200px",
                //   cursor: "pointer",
                //   mixBlendMode: "darken",
                // }}
                className="pciLogo"
                src="https://storage.googleapis.com/prod-images-bucket/cardLogos/pci.png"
              ></img>

              <img
                style={{
                  maxHeight: "60px",
                  maxWidth: "200px",
                  cursor: "pointer",
                  marginRight: "1rem",
                  marginLeft: "1rem",
                }}
                src="https://storage.googleapis.com/prod-images-bucket/cardLogos/rupay.png"
              ></img>
              <img
                // style={{
                //   maxHeight: "60px",
                //   maxWidth: "200px",
                //   cursor: "pointer",
                // }}
                className="maestroLogo"
                src="https://storage.googleapis.com/prod-images-bucket/cardLogos/maestro.png"
              ></img>
            </div>
          </div>
          <img
            // style={{
            //   cursor: "pointer",
            //   marginLeft: "2rem",
            //   marginTop: "3rem",
            //   width: "32%",
            //   borderRadius: "10px",
            // }}
            src={hotelImages.length > 0 && hotelImages[0]?.imageUrl}
            alt="No image found"
            className="hotelImage"
          ></img>
        </div>
      ) : (
        <div
          className="d-flex"
          style={{ marginLeft: "2rem", marginTop: "6rem" }}
        >
          <DoneIcon
            style={{ color: "#2F7000", fontSize: "3rem", marginTop: "2rem" }}
          />
          <p
            style={{ color: "#2F7000", marginRight: "2rem", fontSize: "3rem" }}
          >
            Successfully added card
          </p>
        </div>
      )}
    </div>
  );
}

export default AddCardPayment;
