import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_API_URL}`;
const SPLITROOM = "hotelAdmin/splitRoom/";
const SPLITROOMCOST = "hotelAdmin/splitRoomCost/";

const url = new URL(window.location.href);
const hotelId = url.searchParams.get("hotelId");

export const splitRooms = async (data) => {
  return axios
    .post(COMMON_URL + SPLITROOM, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const spliRoomCosts = async (data) => {
  return axios
    .post(COMMON_URL + SPLITROOMCOST, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
