/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import * as BiIcons from "react-icons/bi";
import { connect } from 'react-redux';
import * as auth from "../../../../../app/modules/Auth/_redux/authRedux";
import { absoluteURL } from "../../../../../app/Utils/index";


function mapStateToProps(state){
  return {data:state.auth}
}


function UserProfileDropdown(props) {
  const { user } = useSelector((state) => state.auth);
  const {email, selectedHotel} = props.data
  // console.log("email : ", email, selectedHotel)
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");


  return (
    <Dropdown drop="down" alignRight >
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="symbol symbol-35 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {selectedHotel.hotelName[0]}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
        <Dropdown.Menu  className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu">
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
        <div className="userDropdownWrapper">
            
          <div className="navi navi-spacer-x-0 pt-5">
            <div className="d-flex flex-column px-8 py-5">
              <div className="profileDetailsWrapper">
                <div className="profileImg">
                  {selectedHotel.hotelName[0]}
                </div>
                <div className="profileDetails">
                  <div className="hotelName">
                    {selectedHotel.hotelName}
                  </div>
                  <div className="hotelEmail">
                    {email}
                  </div>
                </div>
              </div>
            </div>
            <div className="navi-separator mt-3"></div>
          </div>

          <div className="navi navi-spacer-x-0 pt-5">
            <div className="d-flex flex-column">
              <div className="profileWrapper">
                <ul className="profileListItems">
                  <li className="profileItem"><Link onClick={() => {props.selectHotel(null)}} style={{textDecoration:"none", color: 'black'}} to="/hotel-dashboard">All hotels</Link></li>
                  <li className="profileItem"><Link style={{textDecoration:"none", color:"black"}} to="/logout">Sign out</Link></li>
                </ul>
              </div>
            </div>

            {/* <div className="navi-separator mt-3"></div> */}

            {/* <div className="navi-footer px-8 py-5">
              <Link
                to="/logout"
                className="btn btn-light-primary font-weight-bold"
              >
                Sign Out
              </Link>
            </div> */}
          </div>
        </div>
        </Dropdown.Menu>
    </Dropdown>
  );
}

export default connect(mapStateToProps, auth.actions)(UserProfileDropdown)
