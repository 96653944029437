import React, { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import GroupMasterReport from "./GroupMasterReport";
import GroupSalesReport from "./GroupSalesReport";
import GroupChannelManagerReport from "./GroupChannelManagerReport";
import { useHistory, useLocation } from "react-router-dom";
import GroupRoomRevenueReport from "./GroupRoomRevenueReport";
import GroupPaymentsReport from "./GroupPaymentsReport";
import axios from "axios";

// Card
const Card = ({ report }) => {
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    async function getLastUpdated() {
      if (report.hasOwnProperty("lastUpdated")) {
        const time = await report.lastUpdated();
        setLastUpdated(time);
      }
    }
    getLastUpdated();
  }, [report.stateVal, report]);

  return (
    <div className="reportsTypeCards">
      <div className="reportsTypeBody">
        <h2>{report.name}</h2>
        {lastUpdated && (
          <h5>
            <i style={{ fontSize: "12.5px" }}>Last updated {lastUpdated}</i>
          </h5>
        )}
        <p>{report.description}</p>
      </div>
    </div>
  );
};

function GroupReportsContainer({ email }) {
  const history = useHistory();
  const location = useLocation();
  // Set the state of reports and render accordingly
  // const [reportState, setReportState] = useState("default");
  // Reset to default state
  // function performResetOperation() {
  //   setReportState("default");
  // }

  // Check when the group reports were updated
  // const [lastUpdatedGrpReports, setLastUpdatedGrpReports] = useState(null);
  async function handleGetUpdateStautsOfGrpReports(reportType) {
    let res = "";
    try {
      res = await axios.get(
        `https://loadapi.stayflexi.com/core/api/v1/reports/last-updated-time-group-reports/?reportType=${reportType}`
      );
    } catch (error) {
      res = "";
    }
    return res.data;
  }

  // Group reports
  const grpReports = [
    {
      name: "Group master report",
      active: true,
      stateVal: "grpMaster",
      url: "/hotel-dashboard/group-master-report",
      description:
        "Get all the details of bookings, customer information," +
        "payments, and taxes across all the hotels in a single report. ",
      componentRender: (
        <GroupMasterReport grpEmail={email} resetToDefault={""} />
      ),
      lastUpdated: () => handleGetUpdateStautsOfGrpReports("master"),
    },
    {
      name: "Group sales report",
      active: true,
      stateVal: "grpSales",
      url: "/hotel-dashboard/group-sales-report",
      description:
        "Date-wise performance report of the all the hotels in the group. " +
        "Occupancy, ADR, RevPAR, Revenue, payments, " +
        "and other vital metrics.",
      componentRender: (
        <GroupSalesReport grpEmail={email} resetToDefault={""} />
      ),
      lastUpdated: () => handleGetUpdateStautsOfGrpReports("sales"),
    },
    {
      name: "Group channel manager report",
      active: true,
      stateVal: "grpCmReport",
      url: "/hotel-dashboard/group-cm-report",
      description:
        "Report of all the bookings received from various channels " +
        "across all the hotels in the group.",
      componentRender: (
        <GroupChannelManagerReport grpEmail={email} resetToDefault={""} />
      ),
      lastUpdated: () => handleGetUpdateStautsOfGrpReports("channelManager"),
    },
    {
      name: "Group room revenue report",
      active: true,
      stateVal: "grpRoomRev",
      url: "/hotel-dashboard/group-roomrev-report",
      description: "Group room revenue report",
      componentRender: (
        <GroupRoomRevenueReport grpEmail={email} resetToDefault={""} />
      ),
      lastUpdated: () => handleGetUpdateStautsOfGrpReports("roomRevenue"),
    },
    {
      name: "Group gateway payments report",
      active: true,
      stateVal: "grpGatewayPayment",
      url: "/hotel-dashboard/group-payments-report",
      description: "Group gateway payment report",
      componentRender: (
        <GroupPaymentsReport grpEmail={email} resetToDefault={""} />
      ),
      lastUpdated: () => handleGetUpdateStautsOfGrpReports("payment"),
    },
  ];

  return (
    <Stack style={{ margin: "10px", width: "100%", height: "100%" }}>
      <Typography
        style={{
          position: "relative",
          marginLeft: "10px",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        Group reports provide data for a 6-month period in the past as well as a
        6-month outlook into the future. If you need information beyond this
        12-month range, please refer to the individual property reports.
      </Typography>
      <Stack
        direction={"row"}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          margin: "10px",
        }}
      >
        {grpReports
          .filter((report) => report.active === true)
          .map((item, index) => (
            <Stack
              onClick={() =>
                history.push({
                  pathname: item.url,
                  state: {
                    selectedSection: location.state.selectedSection,
                    childSectionName: "group-reports",
                    childSection: true,
                    groupEmail: email,
                  },
                })
              }
              key={index}
              style={{ marginBottom: "12.5px", marginRight: "12.5px" }}
            >
              <Card report={item} />
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
}

export default GroupReportsContainer;
