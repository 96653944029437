import axios from "axios";
import { toast } from "material-react-toastify";
import { handleSpringDataRequest } from "../api";

const url = new URL(window.location.href);
const hotelId = url.searchParams.get("hotelId");
const COMMON_URL = `${process.env.REACT_APP_API_URL}`;
const JAVAURL = `${process.env.REACT_APP_SPRING_API_URL}`;
const VIEWFOLIOS = "core/api/v1/folio/invoice-data-render/";
const CHECKINS = "core/api/v1/reservation/adminConfirmBooking/";
const CHECKOUTS = "core/api/v1/reservation/checkout-booking/";

export const viewFolio = async (id) => {
  return axios
    .get(COMMON_URL + VIEWFOLIOS + "?bookingId=" + id + "&fromDB=false")
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const checkin = async (id) => {
  try {
    const res = await axios.get(JAVAURL + CHECKINS + "?bookingId=" + id);
    await handleGetDoorLockDetails(id);
    return res.data;
  } catch (err) {
    toast.error(err.response.data);
  }
  // return axios
  //   .get(JAVAURL + CHECKINS + "?bookingId=" + id)
  //   .then((resp) => resp.data)
  //   .catch((err) => {
  //     toast.error(err.response.data);
  //   });
};

export const handleGetDoorLockDetails = async (bookingId) => {
  if (sessionStorage.getItem(`xytronEnabled${hotelId}`) === "true") {
    try {
      const doorLockDetails = await handleSpringDataRequest(
        `core/api/v1/xytron/get-door-lock-details?bookingId=${bookingId}`
      );
      await handleMakeXytronCard(doorLockDetails);
    } catch (err) {
      console.log(err);
    }
  }
};

const handleMakeXytronCard = async (doorLockDetails) => {
  for (let details of doorLockDetails) {
    try {
      delete details["booking_status"];
      const res = await axios.post(
        "http://127.0.0.1:8081/makecard.php",
        details
      );
      // setMakeCardLogs((prev) => ({ ...prev, [details["booking_id"]]: res }));
    } catch (err) {
      console.log(err);
      // setMakeCardLogs((prev) => ({ ...prev, [details["booking_id"]]: err }));
    }
  }
};

export const checkout = async (id) => {
  return axios
    .post(
      JAVAURL + CHECKOUTS + "?bookingId=" + id + "&releaseInventory=" + "false",
      {}
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
