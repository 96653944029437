// ** React Imports
import { Close, ErrorOutline } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  CircularProgress,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import * as yup from "yup";
import {
  getCheckinCheckoutSlotsWithHotelId,
  getEditCheckinPricePayloadWithHotelId,
  getEditCheckoutPricePayloadWithHotelId,
  performEditCheckinWithHotelId,
  performEditCheckoutWithHotelId,
  getCheckinCheckoutSlots,
} from "../api/ReservationApi";
import {
  customErrorMessage,
  dateFnsFormat,
  dateFormat,
  displaySlots,
  displayTimes,
} from "../constants/Calendar-constants";
import { PriceModel } from "./PricesModel";
import CommonSlider from "../Views/CommonSlider";
import DimmingDotsLoader from "../Views/LoaderCustom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  textField: {
    padding: theme.spacing(2),
  },
  footer: {
    margin: theme.spacing(3),
    float: "right",
  },
}));

const validationSchema = yup.object({
  booking_start: yup.date(),
  booking_end: yup.date(),
  perNightPrice: yup.number(),
  priceBeforeTax: yup.number(),
  priceAfterTax: yup.number(),
  taxValue: yup.number(),
  numNights: yup.number().nullable(),
});

const GroupExtendModalView = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const {
    eventData,
    handleAddEventSidebar,
    open,
    addEvent,
    viewType,
    hotelId,
    hotelSlotType,
    hotelCurrency,
    currency,
  } = props;

  const [checkoutDate, setcheckoutDate] = useState(new Date());

  let [pricingRequestModel, setPricingRequestModel] = useState(
    new PriceModel({})
  );
  let [loadingData, setLoadingData] = useState(false);
  let [load, setLoad] = useState(false);
  const [checkinSlots, setCheckinSlots] = useState([]);
  const [checkoutSlots, setCheckoutSlots] = useState([]);
  const [selectedCheckinSlot, setSelectedCheckinSlot] = useState(0);
  const [selectedCheckoutSlot, setSelectedCheckoutSlot] = useState(0);
  const actionTitle =
    eventData.oldEvent.startStr != eventData.event.startStr
      ? "checkin"
      : "checkout";
  const [showError, setShowError] = useState(false);
  const bookingId = eventData.event.extendedProps.bookingId;

  const performCheckin = async (params) => {
    try {
      setLoad(true);
      if (actionTitle === "checkin") {
        await performEditCheckinWithHotelId(params, hotelId);
        eventData.event.setExtendedProp(
          "actual_start",
          formik.values.booking_start
        );
        eventData.event.setExtendedProp(
          "actual_end",
          formik.values.booking_start
        );
        addEvent({
          start: getCustomDate().start,
          end: getCustomDate().end,
        });
      } else {
        if (moment(eventData.oldEvent.end).isAfter(eventData.event.end)) {
          await performEditCheckoutWithHotelId(params, hotelId, bookingId);
          eventData.event.setExtendedProp(
            "actual_start",
            formik.values.booking_start
          );
          eventData.event.setExtendedProp(
            "actual_end",
            formik.values.booking_start
          );
          addEvent({
            start: getCustomDate().start,
            end: getCustomDate().end,
          });
        } else {
          await performEditCheckoutWithHotelId(params, hotelId, bookingId);
          eventData.event.setExtendedProp(
            "actual_start",
            formik.values.booking_start
          );
          eventData.event.setExtendedProp(
            "actual_end",
            formik.values.booking_start
          );
          addEvent({
            start: getCustomDate().start,
            end: getCustomDate().end,
          });
          // }
        }
      }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      handleAddEventSidebar();
    }
  };

  const fetchUpdatedBookingPricing = async () => {
    try {
      setLoad(true);
      const resp =
        actionTitle === "checkin"
          ? await getEditCheckinPricePayloadWithHotelId(
              pricingRequestModel.getCheckinBookingparams(),
              hotelId
            )
          : await getEditCheckoutPricePayloadWithHotelId(
              pricingRequestModel.getCheckoutBookingparams(),
              hotelId
            );
      setLoad(false);
      setData(resp);
    } catch (e) {
      console.log(e);
    }
  };

  const setData = (resp) => {
    formik.setFieldValue(
      "perNightPrice",
      Number(resp.perNightPrice).toFixed(2)
    );
    formik.setFieldValue(
      "priceBeforeTax",
      Number(resp.priceBeforeTax).toFixed(2)
    );
    formik.setFieldValue(
      "priceAfterTax",
      Number(resp.priceAfterTax).toFixed(2)
    );
    formik.setFieldValue("taxValue", Number(resp.taxValue).toFixed(2));
  };

  const changeSlots = async (date, type) => {
    let checkin =
      type === "booking_start"
        ? moment(date).format(dateFormat)
        : moment(formik.values.booking_start).format(dateFormat);
    let checkout =
      type === "booking_start"
        ? moment(formik.values.booking_end).format(dateFormat)
        : moment(date).format(dateFormat);

    const ss = await fetch(
      `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/reservation/getCheckinAndCheckoutSlots?checkin=${checkin}&checkout=${checkout}` +
        "&hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token").includes("master")
            ? localStorage.getItem("token").split("||")[0] + "||master"
            : localStorage.getItem("token"),
        },
      }
    );

    const slots = await ss.json();

    // const slots = await getCheckinCheckoutSlotsWithHotelId(
    //   {
    //     checkin:
    //       type === "booking_start"
    //         ? moment(date).format("DD-MM-YYYY HH:00:00")
    //         : moment(formik.values.booking_start).format("DD-MM-YYYY HH:00:00"),
    //     checkout:
    //       type === "booking_start"
    //         ? moment(formik.values.booking_end).format("DD-MM-YYYY HH:00:00")
    //         : moment(date).format("DD-MM-YYYY HH:00:00"),
    //   },
    //   hotelId
    // );
    if (type === "booking_start") {
      let index_1 = checkinSlots.indexOf(selectedCheckinSlot);
      let arr_1 = slots.checkinSlots.sort((a, b) => a - b);
      const temp_date = date;
      temp_date.setHours(arr_1[index_1]);
      formik.setFieldValue("booking_start", temp_date);
      setCheckinSlots(arr_1);
      setSelectedCheckinSlot(arr_1[index_1]);
    } else {
      let index = checkoutSlots.indexOf(selectedCheckoutSlot);
      let arr = slots.checkoutSlots.sort((a, b) => a - b);
      const temp_date_1 = date;
      temp_date_1.setHours(arr[index]);
      formik.setFieldValue("booking_end", temp_date_1);
      setCheckoutSlots(arr);
      setSelectedCheckoutSlot(arr[index]);
    }
  };

  // const checkDateSelectionCheckin = async (checkinsDate, isTime) => {

  //   let checkoutDate = moment(new Date(checkinsDate)).format(
  //     "DD-MM-YYYY HH:00:00"
  //   );
  //   try {
  //     if (isTime) formik.setFieldValue("booking_start", checkoutDate);
  //     else await changeSlots(checkoutDate, "booking_start");
  //     pricingRequestModel.checkin = moment(checkoutDate).format(dateFormat);
  //     pricingRequestModel.resetParams();
  //     fetchUpdatedBookingPricing();
  //     delete formik.errors.booking_start;
  //     delete formik.errors.booking_end;
  //   } catch (e) {
  //     formik.setFieldError("booking_start", "Room selection not available");
  //     formik.setFieldError("booking_end", "Room selection not available");
  //   }
  // };

  const checkDateSelectionCheckin = async (checkinDate, isTime) => {
    try {
      if (isTime) formik.setFieldValue("booking_start", checkinDate);
      else await changeSlots(checkinDate, "booking_start");
      pricingRequestModel.checkin = moment(checkinDate).format(dateFormat);
      pricingRequestModel.resetParams();
      fetchUpdatedBookingPricing();
      delete formik.errors.booking_start;
      delete formik.errors.booking_end;
    } catch (e) {
      formik.setFieldError("booking_start", "Room selection not available");
      formik.setFieldError("booking_end", "Room selection not available");
    }
  };

  // const checkDateSelectionCheckout = async (checkoTDate, isTime) => {
  //   let checkoutDate = moment(new Date(checkoTDate)).format(
  //     "DD-MM-YYYY HH:00:00"
  //   );
  //   try {
  //     if (isTime) formik.setFieldValue("booking_end", checkoutDate);
  //     else await changeSlots(checkoutDate, "booking_end");
  //     pricingRequestModel.checkout = checkoutDate;
  //     pricingRequestModel.resetParams();
  //     fetchUpdatedBookingPricing();
  //     delete formik.errors.booking_start;
  //     delete formik.errors.booking_end;
  //   } catch (e) {
  //     formik.setFieldError("booking_end", "Room selection not available");
  //     formik.setFieldError("booking_start", "Room selection not available");
  //   }
  // };

  const checkDateSelectionCheckout = async (checkoutDate, isTime) => {
    try {
      if (isTime) formik.setFieldValue("booking_end", checkoutDate);
      else await changeSlots(checkoutDate, "booking_end");
      pricingRequestModel.checkout = moment(checkoutDate).format(dateFormat);
      pricingRequestModel.resetParams();
      fetchUpdatedBookingPricing();
      delete formik.errors.booking_start;
      delete formik.errors.booking_end;
    } catch (e) {
      formik.setFieldError("booking_end", "Room selection not available");
      formik.setFieldError("booking_start", "Room selection not available");
    }
  };

  const setCustomCheckin = (slots, checkoutSlotsData) => {
    const temp_date = new Date(eventData.event.start);
    const temp_date_1 = new Date(eventData.event.extendedProps.actual_end);
    if (slots.length === 1) {
      if (temp_date.getDate() === temp_date_1.getDate()) {
        temp_date.setDate(temp_date.getDate());
      }
      if (moment(temp_date).format("hh:mm:ss") === "12:00:00") {
        temp_date.setDate(temp_date.getDate() - 1);
      }
      temp_date.setHours(slots[0]);
      temp_date_1.setHours(eventData.event.extendedProps.actual_end.hour());
      formik.setFieldValue("booking_start", temp_date);
      formik.setFieldValue("booking_end", temp_date_1);
      setSelectedCheckinSlot(slots[0]);
      setSelectedCheckoutSlot(eventData.event.extendedProps.actual_end.hour());
    } else if (slots.length === 3) {
      if (viewType === "resourceTimelineWeek") {
        const slot_number_start =
          displaySlots[eventData.event.start.getHours()];
        temp_date.setHours(slots[slot_number_start]);
        temp_date_1.setHours(eventData.event.extendedProps.actual_end.hour());
        formik.setFieldValue("booking_start", temp_date);
        formik.setFieldValue("booking_end", temp_date_1);
        setSelectedCheckinSlot(slots[slot_number_start]);
        setSelectedCheckoutSlot(
          eventData.event.extendedProps.actual_end.hour()
        );
      } else {
        if (temp_date.getDate() === temp_date_1.getDate()) {
          temp_date.setHours(slots[0]);
          temp_date_1.setHours(eventData.event.extendedProps.actual_end.hour());
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(slots[0]);
          setSelectedCheckoutSlot(
            eventData.event.extendedProps.actual_end.hour()
          );
        } else {
          temp_date.setHours(slots[0]);
          temp_date_1.setHours(eventData.event.extendedProps.actual_end.hour());
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(slots[0]);
          setSelectedCheckoutSlot(
            eventData.event.extendedProps.actual_end.hour()
          );
        }
      }
    } else {
      if (viewType !== "resourceTimelineDay") {
        if (temp_date.getDate() === temp_date_1.getDate()) {
          temp_date.setHours(slots[slots.length / 2 - 1]);
          temp_date_1.setHours(eventData.event.extendedProps.actual_end.hour());
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(slots[slots.length / 2 - 1]);
          setSelectedCheckoutSlot(
            eventData.event.extendedProps.actual_end.hour()
          );
        } else {
          temp_date.setHours(slots[slots.length / 2 - 1]);
          temp_date_1.setHours(eventData.event.extendedProps.actual_end.hour());
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(slots[slots.length / 2 - 1]);
          setSelectedCheckoutSlot(
            eventData.event.extendedProps.actual_end.hour()
          );
        }
      } else {
        setSelectedCheckinSlot(temp_date.getHours());
        setSelectedCheckoutSlot(temp_date_1.getHours());
      }
    }
    return { start: temp_date, end: temp_date_1 };
  };

  const setCustomCheckout = (slots, checkoutSlotsData) => {
    const temp_date = new Date(eventData.event.extendedProps.actual_start);
    const temp_date_1 =
      hotelSlotType === "NOON"
        ? new Date(
            new Date(eventData.event.end).setDate(eventData.event.end.getDate())
          )
        : new Date(eventData.event.end);
    if (slots.length === 1) {
      if (temp_date.getDate() === temp_date_1.getDate()) {
        temp_date_1.setDate(temp_date_1.getDate());
      }
      if (
        moment(temp_date_1).format("hh:mm:ss") !== "12:00:00" &&
        moment(temp_date_1).format("hh:mm:ss") !== "08:00:00"
      ) {
        temp_date_1.setDate(temp_date_1.getDate() + 1);
      }

      temp_date.setHours(eventData.event.extendedProps.actual_start.hour());
      temp_date_1.setHours(checkoutSlotsData[0]);
      formik.setFieldValue("booking_start", temp_date);
      formik.setFieldValue("booking_end", temp_date_1);
      setSelectedCheckinSlot(slots[0]);
      setSelectedCheckoutSlot(checkoutSlotsData[0]);
    } else if (slots.length === 3) {
      if (viewType === "resourceTimelineWeek") {
        // const slot_number_start = displaySlots[eventData.start.getHours()];
        const slot_number_end = displaySlots[eventData.event.end.getHours()];
        temp_date.setHours(eventData.event.extendedProps.actual_start.hour());
        temp_date_1.setHours(checkoutSlotsData[slot_number_end]);
        formik.setFieldValue("booking_start", temp_date);
        formik.setFieldValue("booking_end", temp_date_1);
        setSelectedCheckinSlot(
          eventData.event.extendedProps.actual_start.hour()
        );
        setSelectedCheckoutSlot(checkoutSlotsData[slot_number_end]);
      } else {
        if (temp_date.getDate() === temp_date_1.getDate()) {
          temp_date.setHours(eventData.event.extendedProps.actual_start.hour());
          temp_date_1.setHours(checkoutSlotsData[checkoutSlotsData.length - 1]);
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(
            eventData.event.extendedProps.actual_start.hour()
          );
          setSelectedCheckoutSlot(
            checkoutSlotsData[checkoutSlotsData.length - 1]
          );
        } else {
          temp_date.setHours(eventData.event.extendedProps.actual_start.hour());
          temp_date_1.setHours(checkoutSlotsData[0]);
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(
            eventData.event.extendedProps.actual_start.hour()
          );
          setSelectedCheckoutSlot(checkoutSlotsData[0]);
        }
      }
    } else {
      if (viewType !== "resourceTimelineDay") {
        if (temp_date.getDate() === temp_date_1.getDate()) {
          temp_date.setHours(eventData.event.extendedProps.actual_start.hour());
          temp_date_1.setHours(checkoutSlotsData[checkoutSlotsData.length - 2]);
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(
            eventData.event.extendedProps.actual_start.hour()
          );
          setSelectedCheckoutSlot(
            checkoutSlotsData[checkoutSlotsData.length - 2]
          );
        } else {
          temp_date.setHours(eventData.event.extendedProps.actual_start.hour());
          temp_date_1.setHours(checkoutSlotsData[0]);
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(
            eventData.event.extendedProps.actual_start.hour()
          );
          setSelectedCheckoutSlot(checkoutSlotsData[0]);
        }
      } else {
        setSelectedCheckinSlot(temp_date.getHours());
        setSelectedCheckoutSlot(temp_date_1.getHours());
      }
    }
    return { start: temp_date, end: temp_date_1 };
  };

  const getCustomDate = () => {
    const date1 = new Date(formik.values.booking_start);
    var date2 = new Date(formik.values.booking_end);
    if (viewType === "resourceTimelineWeek") {
      if (checkinSlots.length === 1) {
        date1.setHours(displayTimes[1]);
        date2 = new Date(date2.setDate(date2.getDate() - 1)).setHours(
          date1.getHours() + 1
        );
      } else if (checkinSlots.length === 3) {
        const slot1 = checkinSlots.indexOf(selectedCheckinSlot);
        const slot2 = checkoutSlots.indexOf(selectedCheckoutSlot);
        date1.setHours(displayTimes[slot1]);
        date2.setHours(displayTimes[slot2]);
      }
    }
    return { start: date1, end: date2 };
  };

  const fetchCheckinPricing = async () => {
    setLoadingData(true);
    try {
      let checkin = moment(new Date(eventData.event.start)).format(
        "DD-MM-YYYY HH:00:00"
      );
      let checkout =
        actionTitle === "checkout"
          ? hotelSlotType === "NOON"
            ? moment(
                new Date(
                  new Date(eventData.event.end).setDate(
                    eventData.event.end.getDate() + 1
                  )
                )
              ).format("DD-MM-YYYY HH:00:00")
            : moment(new Date(eventData.event.end)).format(
                "DD-MM-YYYY HH:00:00"
              )
          : moment(new Date(eventData.event.extendedProps.actual_end)).format(
              "DD-MM-YYYY HH:00:00"
            );
      const ss = await fetch(
        `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/reservation/getCheckinAndCheckoutSlots?checkin=${checkin}&checkout=${checkout}` +
          "&hotelId=" +
          hotelId +
          "&hotel_id=" +
          hotelId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token").includes("master")
              ? localStorage.getItem("token").split("||")[0] + "||master"
              : localStorage.getItem("token"),
          },
        }
      );
      const slots = await ss.json();

      // await getCheckinCheckoutSlots({
      //   checkin: moment(new Date(eventData.event.start)).format(
      //     "DD-MM-YYYY HH:00:00"
      //   ),
      //   checkout:
      //     actionTitle === "checkout"
      //       ? hotelSlotType === "NOON"
      //         ? moment(
      //             new Date(
      //               new Date(eventData.event.end).setDate(
      //                 eventData.event.end.getDate() + 1
      //               )
      //             )
      //           ).format("DD-MM-YYYY HH:00:00")
      //         : moment(new Date(eventData.event.end)).format(
      //             "DD-MM-YYYY HH:00:00"
      //           )
      //       : moment(new Date(eventData.event.extendedProps.actual_end)).format(
      //           "DD-MM-YYYY HH:00:00"
      //         ),
      // });

      const changed =
        actionTitle === "checkin"
          ? setCustomCheckin(
              slots.checkinSlots.sort((a, b) => a - b),
              slots.checkoutSlots.sort((a, b) => a - b)
            )
          : setCustomCheckout(
              slots.checkinSlots.sort((a, b) => a - b),
              slots.checkoutSlots.sort((a, b) => a - b)
            );
      setCheckinSlots(slots.checkinSlots.sort((a, b) => a - b));
      setCheckoutSlots(slots.checkoutSlots.sort((a, b) => a - b));

      await fetchInitialPricing(changed.start, changed.end);
      setLoadingData(false);
    } catch (e) {
      setLoadingData(false);
      setShowError(true);
    }
  };

  const fetchInitialPricing = async (checkin, checkout) => {
    const pricesModel = new PriceModel({
      hotelId: hotelId,
      resIds: eventData.event.extendedProps.reservationId,
      checkin: moment(new Date(checkin)).format("DD-MM-YYYY HH:00:00"),
      checkout: moment(new Date(checkout)).format("DD-MM-YYYY HH:00:00"),
      newRate: null,
    });
    setPricingRequestModel(pricesModel);
    const resp =
      actionTitle === "checkin"
        ? await getEditCheckinPricePayloadWithHotelId(
            pricesModel.getCheckinBookingparams(),
            hotelId
          )
        : await getEditCheckoutPricePayloadWithHotelId(
            pricesModel.getCheckoutBookingparams(),
            hotelId
          );
    setData(resp);
  };

  // ** Props
  const classes = useStyles();
  useEffect(() => {
    fetchCheckinPricing();
  }, []);

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      booking_start: eventData.event.start,
      booking_end: eventData.event.end,
      perNightPrice: 0,
      priceBeforeTax: 0,
      priceAfterTax: 0,
      taxValue: 0,
    },
    validationSchema: validationSchema,
    validate: () => {
      let errors = {};
      if (formik.errors.booking_start)
        errors.booking_start = formik.errors.booking_start;
      if (formik.errors.booking_end)
        errors.booking_end = formik.errors.booking_end;

      return errors;
    },
    onSubmit: (values) => {
      let temporary = {
        hotelId: hotelId,
        resIds: [eventData.event.extendedProps.reservationId],
        checkinDate:
          actionTitle === "checkin"
            ? moment(values.booking_start).format(dateFormat)
            : moment(values.booking_end).format(dateFormat),
        gAdditionalAmt: values.priceAfterTax,
      };
      performCheckin(temporary);
    },
  });
  const [checkinDates, setcheckinDates] = useState(new Date());
  const [checkoutDates, setcheckoutDates] = useState(new Date());

  useEffect(() => {
    setcheckinDates(moment(formik.values.booking_start).format("YYYY-MM-DD"));
    setcheckoutDates(moment(formik.values.booking_end).format("YYYY-MM-DD"));
  }, [formik.values.booking_end, formik.values.booking_start]);

  return (
    <div className={classes.root}>
      <CommonSlider open={open} onClose={handleAddEventSidebar}>
        <Grid container sx={{ width: "100%", height: "100%" }}>
          <Grid
            item
            height={"max-content"}
            container
            className={classes.textField}
          >
            <Grid item xs={10} alignSelf="center">
              <Typography variant="h5">
                {actionTitle === "checkin"
                  ? "Modify Checkin"
                  : "Modify Checkout"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                sx={{ float: "right" }}
                onClick={handleAddEventSidebar}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          {loadingData && (
            <Grid
              sx={{ width: "100%", height: "max-content" }}
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <DimmingDotsLoader />
            </Grid>
          )}
          <Divider />
          {!showError && !loadingData && (
            <form
              noValidate
              style={{ height: "inherit" }}
              onSubmit={formik.handleSubmit}
            >
              <Grid container className={classes.textField}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {actionTitle === "checkin" && (
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8} md={8} lg={10} xl={10}>
                        <DatePicker
                          inputFormat={dateFnsFormat}
                          maxDate={formik.values.booking_end}
                          fullWidth
                          disableMaskedInput
                          id="booking_start"
                          name="booking_start"
                          className={classes.textField}
                          value={formik.values.booking_start}
                          label="Checkin"
                          onChange={checkDateSelectionCheckin}
                          onBlur={formik.handleBlur}
                          renderInput={(params) => {
                            params.error = Boolean(formik.errors.booking_start);
                            return (
                              <TextField
                                required
                                helperText={formik.errors.booking_start}
                                fullWidth
                                {...params}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                        <TextField
                          select
                          value={selectedCheckinSlot}
                          onChange={(e) => {
                            const temp = formik.values.booking_start;
                            temp.setHours(
                              e.target.value === 24 ? 0 : e.target.value
                            );
                            setSelectedCheckinSlot(e.target.value);
                            checkDateSelectionCheckin(temp, true);
                          }}
                          label="Time"
                          fullWidth
                        >
                          {checkinSlots.map((slot) => (
                            <MenuItem key={slot} value={slot}>
                              {`${slot > 12 ? slot - 12 : slot} ${
                                (slot - 12) / 12 >= 0 && (slot - 12) / 12 !== 1
                                  ? "PM"
                                  : "AM"
                              }`}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  )}
                  {actionTitle === "checkout" && (
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8} md={8} lg={10} xl={10}>
                        <DatePicker
                          fullWidth
                          id="booking_end"
                          mask="____ ___, ____"
                          disableMaskedInput
                          name="booking_end"
                          className={classes.textField}
                          minDate={formik.values.booking_start}
                          placeholder="Booking End Date"
                          value={formik.values.booking_end}
                          label={`Checkout ( ${moment(
                            new Date(checkoutDates)
                          ).diff(new Date(checkinDates), "days")} Nights )`}
                          variant="inline"
                          autoOk={true}
                          inputFormat={dateFnsFormat}
                          onChange={checkDateSelectionCheckout}
                          onBlur={formik.handleBlur}
                          renderInput={(params) => {
                            params.error = Boolean(formik.errors.booking_end);
                            return (
                              <TextField
                                required
                                helperText={formik.errors.booking_end}
                                fullWidth
                                {...params}
                              />
                            );
                          }}
                          disabled={load ? true : false}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                        <TextField
                          select
                          value={selectedCheckoutSlot}
                          disabled={load ? true : false}
                          onChange={(e) => {
                            const temp = formik.values.booking_end;
                            temp.setHours(
                              e.target.value === 24 ? 0 : e.target.value
                            );
                            setSelectedCheckoutSlot(e.target.value);
                            checkDateSelectionCheckout(temp, true);
                          }}
                          label="Time"
                          fullWidth
                        >
                          {checkoutSlots.map((slot) => (
                            <MenuItem key={slot} value={slot}>
                              {`${slot > 12 ? slot - 12 : slot} ${
                                (slot - 12) / 12 >= 0 && (slot - 12) / 12 !== 1
                                  ? "PM"
                                  : "AM"
                              }`}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  )}
                </LocalizationProvider>
              </Grid>
              <Grid container className={classes.textField} spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                    name="perNightPrice"
                    id="perNightPrice"
                    disabled={
                      load
                        ? true
                        : actionTitle !== "checkin" &&
                          moment(formik.values.booking_end).format(
                            "DD-MM-YYYY"
                          ) === moment(new Date()).format("DD-MM-YYYY")
                        ? true
                        : false
                    }
                    error={
                      formik.touched.perNightPrice &&
                      Boolean(formik.errors.perNightPrice)
                    }
                    helperText={
                      formik.touched.perNightPrice &&
                      formik.errors.perNightPrice
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currency}
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Per night excluding taxes"
                    type="number"
                    value={formik.values.perNightPrice}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue("perNightPrice", e.target.value);
                      setTimeout(() => {
                        if (e.target.value !== "" && e.target.value !== null) {
                          pricingRequestModel.newRate = e.target.value;
                          pricingRequestModel.newRateType = "PER_NIGHT_PRETAX";
                          fetchUpdatedBookingPricing();
                        }
                      }, 2000);
                    }}
                    label="Per night excluding taxes"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                    name="priceBeforeTax"
                    id="priceBeforeTax"
                    disabled={
                      load
                        ? true
                        : actionTitle !== "checkin" &&
                          moment(formik.values.booking_end).format(
                            "DD-MM-YYYY"
                          ) === moment(new Date()).format("DD-MM-YYYY")
                        ? true
                        : false
                    }
                    error={
                      formik.touched.priceBeforeTax &&
                      Boolean(formik.errors.priceBeforeTax)
                    }
                    helperText={
                      formik.touched.priceBeforeTax &&
                      formik.errors.priceBeforeTax
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currency}
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Total excluding tax"
                    type="number"
                    value={formik.values.priceBeforeTax}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue("priceBeforeTax", e.target.value);
                      setTimeout(() => {
                        if (e.target.value !== "" && e.target.value !== null) {
                          pricingRequestModel.newRate = e.target.value;
                          pricingRequestModel.newRateType = "TOTAL_PRETAX";
                          fetchUpdatedBookingPricing();
                        }
                      }, 2000);
                    }}
                    label="Total excluding tax"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currency}
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                    name="taxValue"
                    id="taxValue"
                    placeholder="Total tax"
                    disabled
                    type="number"
                    value={formik.values.taxValue}
                    label="Total tax"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currency}
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                    name="priceAfterTax"
                    id="priceAfterTax"
                    placeholder="Total with tax"
                    type="number"
                    disabled={
                      load
                        ? true
                        : actionTitle !== "checkin" &&
                          moment(formik.values.booking_end).format(
                            "DD-MM-YYYY"
                          ) === moment(new Date()).format("DD-MM-YYYY")
                        ? true
                        : false
                    }
                    error={Boolean(formik.errors.priceAfterTax)}
                    helperText={formik.errors.priceAfterTax}
                    value={formik.values.priceAfterTax}
                    onChange={(e) => {
                      formik.setFieldValue("priceAfterTax", e.target.value);
                      setTimeout(() => {
                        if (e.target.value !== "" && e.target.value !== null) {
                          pricingRequestModel.newRate = e.target.value;
                          pricingRequestModel.newRateType = "TOTAL_WITHTAX";
                          fetchUpdatedBookingPricing();
                        }
                      }, 2000);
                    }}
                    onBlur={formik.handleBlur}
                    label="Total with tax"
                  />
                </Grid>
              </Grid>
              <Divider />
              <div>
                <Fab
                  className={classes.footer}
                  type="submit"
                  disabled={
                    (actionTitle !== "checkin" &&
                      moment(formik.values.booking_end).format("DD-MM-YYYY") ===
                        moment(new Date()).format("DD-MM-YYYY")) ||
                    load ||
                    loadingData
                  }
                  variant="extended"
                  color="primary"
                >
                  {actionTitle === "checkin"
                    ? "Modify checkin"
                    : "Modify checkout"}
                  {load && (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  )}
                </Fab>
              </div>
            </form>
          )}
          {showError && (
            <Grid
              sx={{ width: "100%", height: "max-content" }}
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <ErrorOutline sx={{ width: "50%", height: "50%" }} color="error">
                {/* <Grid item >
                <Typography> These was some error please try again !</Typography>
              </Grid> */}
              </ErrorOutline>
              <Grid item>
                <Typography>
                  {" "}
                  These was some error please try again !
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default GroupExtendModalView;
