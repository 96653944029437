import React, { lazy, Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import Calendar from "./pages/CalendarPage/ResourceTimeLine";
import AllRestaurantDayWiseReport from "./pages/Shops/Shop/Reports/AllRestaurantDayWiseReport";
import HourlyItemSalesReport from "./pages/Shops/Shop/Reports/HourlyItemSalesReport";
import CategorySummaryReport from "./pages/Shops/Shop/Reports/CategorySummaryReport";
import OrderWiseSalesSummaryReport from "./pages/Shops/Shop/Reports/OrderWiseSalesSummaryReport";

import Reports from "./pages/Shops/Shop/Reports/Reports";
import RestaurantDayWiseBalanceSummaryReoprt from "./pages/Shops/Shop/Reports/RestaurantDayWiseBalanceSummaryReoprt";
import ReportSchedule from "./pages/RevenueManagement/ReportScheduler/ReportSchedule";
import IshaEreceiptPreview from "./pages/Folios/IshaEreceiptPreview";
import KitchenConfig from "./pages/Stock/KitchenConfig";
import PurchaseOrder from "./pages/Stock/PurchaseOrder";
import WarehouseStockReport from "./pages/Stock/Reports/WarehouseStockReport";
import KitchenStockReport from "./pages/Stock/Reports/KitchenStockReport";
import PurchaseOrderReport from "./pages/Stock/Reports/PurchaseOrderReport";
import ClosingStockWarehouseReport from "./pages/Stock/Reports/ClosingStockWarehouseReport";
import ClosingStockKitchenReport from "./pages/Stock/Reports/ClosingStockKitchenReport";
import WarehouseCumulativeReport from "./pages/Stock/Reports/WarehouseCumulativeReport";
import StockDashboard from "./pages/Stock/StockDashboard";
import WareHouseConsumptionReport from "./pages/Stock/Reports/WareHouseConsumptionReport";
import FoodCostingReport from "./pages/Stock/Reports/FoodCostingReport";
import HousekeepingRawMaterial from "./pages/Stock/Housekeeping/HousekeepingRawMaterial";
import AddHousekeepingMaterial from "./pages/Stock/Housekeeping/AddHousekeepingMaterial";
import PaymentsDashboard from "./pages/PaymentConfig/PaymentsDashboard";
import NightAuditDashboard from "./pages/NightAudits/NightAuditDashboard";
import DashboardRecommendation from "./pages/DashboardRecommendation";

// Kot modification report
const KotModificationReport = lazy(() =>
  import("./pages/Shops/Shop/Reports/KotModificationReport")
);

// Kot report
const KotReport = lazy(() => import("./pages/Shops/Shop/Reports/KotReport"));
const ChannelMapping = lazy(() =>
  import("./pages/ChannelMapping/ChannelMapping")
);

const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const Report = lazy(() => import("./pages/Reports/Report"));
const AgGridTable = lazy(() =>
  import("./pages/Reports/MasterReport/AgGridTable")
);
const IshaEnquiryPage = lazy(() =>
  import("../_metronic/layout/components/header/header-menu/IshaEnquiryPage")
);
const CustomizedReport = lazy(() =>
  import("./pages/Reports/MasterReport/CustomizedReport")
);
const SalesReport = lazy(() =>
  import("./pages/Reports/ManagerialReports/SalesReport/SalesReport")
);
const PaymentsReport = lazy(() =>
  import("./pages/Reports/ManagerialReports/PaymentsReport/PaymentsReport")
);
const CounterReport = lazy(() =>
  import("./pages/Reports/ManagerialReports/CounterReport/CounterReport")
);
const ServicesReport = lazy(() =>
  import("./pages/Reports/ManagerialReports/ServicesReport/ServicesReport")
);
const InventoryReport = lazy(() =>
  import("./pages/Reports/ManagerialReports/InventoryReport/InventoryReport")
);
const RoomRevenueReport = lazy(() =>
  import(
    "./pages/Reports/ManagerialReports/RoomRevenueReport/RoomRevenueReport"
  )
);
const RoomRevenueReportSource = lazy(() =>
  import("./pages/Reports/AdditionalReports/RoomRevenueReportSource")
);
const TaxReport = lazy(() =>
  import("./pages/Reports/ManagerialReports/TaxReport/TaxReport")
);
const RoomBookingsReport = lazy(() =>
  import(
    "./pages/Reports/FrontDeskReports/RoomBookingsReport/RoomBookingsReport"
  )
);
const OccupancyReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/OccupancyReport/OccupancyReport")
);
const BookingEngineReport = lazy(() =>
  import(
    "./pages/Reports/FrontDeskReports/BookingEngineReport/BookingEngineReport"
  )
);
const CustomerReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/CustomerReport/CustomerReport")
);
const ChannelManagerReport = lazy(() =>
  import(
    "./pages/Reports/FrontDeskReports/ChannelManagerReport/ChannelManagerReport"
  )
);
const FlexCollectReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/FlexCollectReport/FlexCollectReport")
);
const TravelAgentReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/TravelAgentReport/TravelAgentReport")
);
const POSReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/POSReport/POSReport")
);
const POSDetailReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/POSDetailReport/POSDetailReport")
);
const FlashManagerReport = lazy(() =>
  import(
    "./pages/Reports/ManagerialReports/FlashManagerReport/FlashManagerReport"
  )
);
const AuditReport = lazy(() =>
  import("./pages/Reports/ManagerialReports/AuditReport/AuditReport")
);

const FolioReport = lazy(() =>
  import("./pages/Reports/AdditionalReports/FolioReport/FolioReport")
);

const UserLogsReport = lazy(() => import("./pages/Reports/UserLogsReport"));
const ShiftReport = lazy(() => import("./pages/Reports/ShiftReport"));

const Shops = lazy(() => import("./pages/Shops/Shops"));

const AddNewShopAdditionalDetails = lazy(() =>
  import("./pages/Shops/AddNewShopAdditionalDetails")
);
const ShopConfiguration = lazy(() => import("./pages/Shops/ShopConfiguration"));
const StartOrder = lazy(() => import("./pages/Shops/StartOrder"));
const InventoryCalendar = lazy(() =>
  import("./pages/InventoryCalendar/Inventory")
);

const HouseKeeping = lazy(() => import("./pages/HouseKeeping/HouseKeeping"));
const CartInvoice = lazy(() => import("./pages/Shops/CartInvoice"));
const AdvancedPortfolioWrapper = lazy(() =>
  import("./pages/Folios/Advanced Portfolio/AdvancedPortfolioWrapper")
);
const PropertyDetails = lazy(() =>
  import("./pages/PropertyConfiguration/PropertyDetails/PropertyDetails")
);
const POSViewOrders = lazy(() => import("./pages/Shops/POSViewOrders"));
const CreatedOrder = lazy(() => import("./pages/Shops/CreatedOrder"));

const PropertyDashboard = lazy(() =>
  import("./pages/PropertyConfiguration/PropertyDetails/PropertyDashboard")
);

const AccountInfo = lazy(() => import("./pages/AccountInfo/AccountInfo"));

const AccountSetting = lazy(() =>
  import("./pages/AccountSettings/AccountSetting")
);

const BookingDashboard = lazy(() =>
  import("./pages/BookingDashboard/BookingDashboard")
);

const RevenueDashboard = lazy(() =>
  import("./pages/RevenueManagement/Dashboard/RevenueDashBoard")
);
const InventoryStockReport = lazy(() =>
  import("./pages/Stock/Reports/InventoryStockReport")
);

const AllShops = lazy(() => import("./pages/Shops/AllShops/AllShops"));
const AddNewShop = lazy(() => import("./pages/Shops/AddNewShop/AddShop"));
const Shop = lazy(() => import("./pages/Shops/Shop/ShopWrapper"));
const TableView = lazy(() =>
  import("./pages/Shops/Shop/PlaceOrders/Components/TableView")
);
const OutletConfiguration = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/OutletDetails")
);
const ShopDashboard = lazy(() =>
  import("./pages/Shops/Shop/Dashboard/DashboardPage")
);
const PlaceOrderPage = lazy(() =>
  import("./pages/Shops/Shop/PlaceOrders/PlaceOrderPage")
);
const OutletPage = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/OutletConfiguration")
);
const MenuItems = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/MenuItems")
);
const ConfigureItems = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/ConfigureItems")
);
const EditItems = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/EditItems")
);
const ProductCategories = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/ProductCategory")
);
const ConfigureCategory = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/ConfigureCategory")
);
const EditCategory = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/EditCategory")
);
const Staff = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/Staff")
);
const EditStaff = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/EditStaff")
);
const Discount = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/Discount")
);
const ConfigureDiscount = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/ConfigureDiscount")
);
const EditDiscount = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/EditDiscount")
);
const Table = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/Table")
);
const ConfigureTable = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/ConfigureTable")
);
const ConfigureStaff = lazy(() =>
  import("./pages/Shops/Shop/Configuration/Components/ConfigureStaff")
);
const ViewOrders = lazy(() =>
  import("./pages/Shops/Shop/ViewOrders/ViewOrders")
);

// Folios
const Folio = lazy(() => import("./pages/Folios/Folios"));

// Room Folio
const RoomFolio = lazy(() => import("./pages/RoomFolio/RoomFolio"));

const DirectBilling = lazy(() => import("./pages/CTA/CTA"));

const ExpanseDrawer = lazy(() =>
  import("./pages/SelfBasicConfig/ExpanseDrawer")
);

const ExpenseManagerDashBoard = lazy(() =>
  import("./pages/SelfBasicConfig/ExpenseManagerDashBoard")
);

const ReputationManagementDashBoard = lazy(() =>
  import("./pages/ReputationManagement/ReputationManagementDashBoard")
);

// Reports
const ReportWrapper = lazy(() => import("./pages/Reports/ReportWrapper"));
const ArrivalsReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/ArrivalsReport")
);
const DepartureReports = lazy(() =>
  import("./pages/Reports/FrontDeskReports/DepartureReports")
);
const InhouseReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/InhouseReport")
);
const NewBookingsReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/NewBookingsReport")
);
const NoShowReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/NoShowReport")
);
const OnholdReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/OnholdReport")
);

const DaywiseAllOutletSales = lazy(() =>
  import("./pages/Reports/POS Reports/DaywiseAllOutletsSales")
);
const AllOutletsHourlyItemsSalesSummary = lazy(() =>
  import("./pages/Reports/POS Reports/AllOutletsHourlyItemsSalesSummary")
);
const AllOutletsItemWiseCategory = lazy(() =>
  import("./pages/Reports/POS Reports/AllOutletsItemwiseCategory")
);
const AllOutletsOrderSales = lazy(() =>
  import("./pages/Reports/POS Reports/AllOutletsOrderSales")
);
const BookingNotesReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/BookingNotesReport")
);
const CustomerNotesReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/CustomerNotesReport")
);
const ShopwiseRevReport = lazy(() =>
  import("./pages/Reports/POS Reports/ShopwiseRevReport")
);

const SelfCheckinReport = lazy(() =>
  import("./pages/Reports/PropertyReports/SelfCheckinReport")
);

const RoomUpgradeReport = lazy(() =>
  import("./pages/Reports/PropertyReports/RoomUpgradeReport")
);

const EarlyCheckinReport = lazy(() =>
  import("./pages/Reports/PropertyReports/EarlyCheckinReport")
);

const LateCheckoutReport = lazy(() =>
  import("./pages/Reports/PropertyReports/LateCheckoutReport")
);

const DoNotRentReport = lazy(() =>
  import("./pages/Reports/CustomerReports/DoNotRentReport")
);

const ExpenseReport = lazy(() =>
  import("./pages/Reports/ExpenseReports/ExpenseReport")
);

const RoomRevByOTAReport = lazy(() =>
  import("./pages/Reports/ChannelManagerReports/RoomRevByOTA")
);

const RefundsReport = lazy(() =>
  import("./pages/Reports/PaymentsReports/RefundsReport")
);

const TransfersReport = lazy(() =>
  import("./pages/Reports/PaymentsReports/TransfersReport")
);

const PaymentsUserReport = lazy(() =>
  import("./pages/Reports/PaymentsReports/PaymentsUserReport")
);

const PaymentsReportByPaymentType = lazy(() =>
  import("./pages/Reports/PaymentsReports/PaymentsReportByPaymentType")
);

const CounterReportByPaymentType = lazy(() =>
  import("./pages/Reports/PaymentsReports/CounterReportByPaymentType")
);

const ServiceSalesReport = lazy(() =>
  import("./pages/Reports/ManagerialReports/ServiceSalesReport")
);

const DirectBillingARBalanceReport = lazy(() =>
  import("./pages/Reports/DirectBillingReports/DirectBillingArBalanceReport")
);

const DirectBillingArAgingReport = lazy(() =>
  import("./pages/Reports/DirectBillingReports/DirectBillingArAgingReport")
);

const BarPricingReport = lazy(() =>
  import("./pages/Reports/PropertyReports/BarPricingReport")
);

const CommissionReport = lazy(() =>
  import("./pages/Reports/PaymentsReports/CommissionReport")
);

const MealPlanCountReport = lazy(() =>
  import("./pages/Reports/FrontDeskReports/MealPlanCountReport")
);

// Inventory and Stock Management
const RawMaterialSupplier = lazy(() =>
  import("./pages/Stock/RawMaterialSupplier")
);
const WarehouseRawMaterial = lazy(() =>
  import("./pages/Stock/WarehouseRawMaterial")
);
const KitchenRawMaterial = lazy(() =>
  import("./pages/Stock/KitchenRawMaterial")
);
const ClosingStock = lazy(() => import("./pages/Stock/ClosingStock"));
const RawMaterialWastage = lazy(() =>
  import("./pages/Stock/RawMaterialWastage")
);
const ProductRecipe = lazy(() => import("./pages/Stock/ProductRecipe"));

// Chat
const Chat = lazy(() => import("./pages/Chat/Inbox"));

export default function BasePage() {
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Shops - Revamped */}
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/viewOrders"
          component={ViewOrders}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/reports"
          component={Reports}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/report/1"
          component={AllRestaurantDayWiseReport}
        />
        <ContentRoute
          exact
          path="/stock/report/1"
          component={WarehouseStockReport}
        />
        <ContentRoute
          exact
          path="/stock/report/7"
          component={FoodCostingReport}
        />
        <ContentRoute
          exact
          path="/stock/report/2"
          component={KitchenStockReport}
        />
        <ContentRoute
          exact
          path="/stock/report/3"
          component={PurchaseOrderReport}
        />
        <ContentRoute
          exact
          path="/stock/report/4"
          component={ClosingStockWarehouseReport}
        />
        <ContentRoute
          exact
          path="/stock/report/5"
          component={ClosingStockKitchenReport}
        />
        <ContentRoute
          exact
          path="/stock/report/0"
          component={WarehouseCumulativeReport}
        />
        <ContentRoute
          exact
          path="/stock/report/6"
          component={WareHouseConsumptionReport}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/report/4"
          component={RestaurantDayWiseBalanceSummaryReoprt}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/report/2"
          component={HourlyItemSalesReport}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/report/3"
          component={CategorySummaryReport}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/report/5"
          component={OrderWiseSalesSummaryReport}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/report/6"
          component={KotModificationReport}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/report/7"
          component={KotReport}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/configureStaff"
          component={ConfigureStaff}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/placeOrder"
          component={PlaceOrderPage}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/tableOrder/:type/:tableId/:tableName/placeOrder"
          component={TableView}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/roomOrder/:type/:roomId/placeOrder"
          component={TableView}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/randomOrder/:type/:randomId/placeOrder"
          component={TableView}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/table"
          component={Table}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/configureTable"
          component={ConfigureTable}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/:discountId/:discountName/editDiscount"
          component={EditDiscount}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/configureDiscount"
          component={ConfigureDiscount}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/discounts"
          component={Discount}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/:staffId/:staffName/editStaff"
          component={EditStaff}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/staff"
          component={Staff}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/:categoryId/:categoryName/editCategory"
          component={EditCategory}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/configureCategory"
          component={ConfigureCategory}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/categories"
          component={ProductCategories}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/:categoryId/:itemId/:itemName"
          component={EditItems}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/:categoryId/item"
          component={ConfigureItems}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/menu"
          component={MenuItems}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/outletConfiguration"
          component={OutletConfiguration}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/outlet"
          component={OutletPage}
        />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType/dashboard"
          component={ShopDashboard}
        />
        <ContentRoute
          exact
          path="/allOutlets/addOutlet"
          component={AddNewShop}
        />
        <ContentRoute exact path="/allOutlets" component={AllShops} />
        <ContentRoute
          exact
          path="/outlet/:shopId/:shopName/:shopType"
          component={Shop}
        />

        {/* Folios */}
        <ContentRoute path="/folio/:bookingId" component={Folio} />
        <ContentRoute path="/reports/folio" component={FolioReport} />
        <ContentRoute path="/propertyDetails" component={PropertyDetails} />

        {/* RoomFolio */}
        <ContentRoute path="/roomFolio/:resId" component={RoomFolio} />
        <ContentRoute
          path="/folios/AdvancedPortfolio"
          component={AdvancedPortfolioWrapper}
        />
        <ContentRoute
          path="/shops/startOrder/cartInvoice"
          component={CartInvoice}
        />
        <ContentRoute path="/houseKeeping" component={HouseKeeping} />
        <ContentRoute
          path="/shops/:shopId/viewOrders"
          component={POSViewOrders}
        />
        <ContentRoute
          path="/shops/:shopId/startOrder/order/:orderId"
          component={CreatedOrder}
        />
        <ContentRoute path="/shops/:shopId/startOrder" component={StartOrder} />
        <ContentRoute
          path="/shops/:shopId/shopConfiguration"
          component={ShopConfiguration}
        />
        <ContentRoute
          path="/shops/addShop-addDetails"
          component={AddNewShopAdditionalDetails}
        />
        <ContentRoute path="/shops" component={Shops} />

        <ContentRoute
          path="/reports/flash-manager"
          component={FlashManagerReport}
        />
        <ContentRoute path="/reports/night-audit" component={AuditReport} />
        <ContentRoute
          path="/inventory-calendar"
          component={InventoryCalendar}
        />
        <ContentRoute path="/enquiry-page" component={IshaEnquiryPage} />
        <ContentRoute path="/posDetailReport" component={POSDetailReport} />
        <ContentRoute path="/posReport" component={POSReport} />
        <ContentRoute path="/travelAgentReport" component={TravelAgentReport} />
        <ContentRoute path="/flexCollectReport" component={FlexCollectReport} />
        <ContentRoute
          exact
          path="/reports/cm-bookings"
          component={ChannelManagerReport}
        />
        <ContentRoute path="/reports/customers" component={CustomerReport} />
        <ContentRoute path="/reports/expense" component={ExpenseReport} />
        <ContentRoute
          path="/reports/be-bookings"
          component={BookingEngineReport}
        />
        <ContentRoute path="/reports/occupancy" component={OccupancyReport} />
        <ContentRoute path="/reports/room-taxes" component={TaxReport} />
        <ContentRoute
          path="/reports/room-revenue"
          component={RoomRevenueReport}
        />
        <ContentRoute
          path="/isha-preview-e-receipt"
          component={IshaEreceiptPreview}
        />
        <ContentRoute
          path="/reports/room-revenue-src"
          component={RoomRevenueReportSource}
        />
        <ContentRoute path="/reports/inventory" component={InventoryReport} />
        <ContentRoute path="/reports/service" component={ServicesReport} />
        <ContentRoute path="/reports/counter" component={CounterReport} />
        <ContentRoute
          path="/reports/payment-gateway"
          component={PaymentsReport}
        />
        <ContentRoute exact path="/reports/sales" component={SalesReport} />
        <ContentRoute exact path="/reports/master" component={AgGridTable} />
        <ContentRoute
          exact
          path="/reports/arrivals"
          component={ArrivalsReport}
        />
        <ContentRoute
          exact
          path="/reports/departures"
          component={DepartureReports}
        />
        <ContentRoute
          exact
          path="/reports/in-house"
          component={InhouseReport}
        />
        <ContentRoute exact path="/reports/on-hold" component={OnholdReport} />
        <ContentRoute
          exact
          path="/reports/all-outlets-day-sales-summary"
          component={DaywiseAllOutletSales}
        />
        <ContentRoute
          exact
          path="/reports/all-outlets-hourly-items-sales"
          component={AllOutletsHourlyItemsSalesSummary}
        />
        <ContentRoute
          exact
          path="/reports/all-outlets-item-category-summary"
          component={AllOutletsItemWiseCategory}
        />
        <ContentRoute
          exact
          path="/reports/all-outlets-orders-sales-summary"
          component={AllOutletsOrderSales}
        />
        <ContentRoute
          exact
          path="/reports/booking-notes"
          component={BookingNotesReport}
        />
        <ContentRoute
          exact
          path="/reports/customer-notes"
          component={CustomerNotesReport}
        />
        <ContentRoute
          exact
          path="/reports/shopwise-rev"
          component={ShopwiseRevReport}
        />
        <ContentRoute
          exact
          path="/reports/self-checkin"
          component={SelfCheckinReport}
        />
        <ContentRoute
          exact
          path="/reports/room-upgrade"
          component={RoomUpgradeReport}
        />
        <ContentRoute
          exact
          path="/reports/early-cin"
          component={EarlyCheckinReport}
        />
        <ContentRoute
          exact
          path="/reports/late-cout"
          component={LateCheckoutReport}
        />
        <ContentRoute
          exact
          path="/reports/dnr-list"
          component={DoNotRentReport}
        />
        <ContentRoute
          exact
          path="/reports/room-revenue-ota"
          component={RoomRevByOTAReport}
        />
        <ContentRoute exact path="/reports/refunds" component={RefundsReport} />
        <ContentRoute
          exact
          path="/reports/transfers"
          component={TransfersReport}
        />
        <ContentRoute
          exact
          path="/reports/payments-type-report"
          component={PaymentsReportByPaymentType}
        />
        <ContentRoute
          exact
          path="/reports/payments-user-report"
          component={PaymentsUserReport}
        />
        <ContentRoute
          exact
          path="/reports/counter-type-report"
          component={CounterReportByPaymentType}
        />
        <ContentRoute
          exact
          path="/reports/payments-type-report"
          component={PaymentsReportByPaymentType}
        />
        {/* <ContentRoute
          exact
          path="/reports/payments-user-report"
          component={PaymentsUserReport}
        /> */}
        <ContentRoute
          exact
          path="/reports/service-sales"
          component={ServiceSalesReport}
        />
        <ContentRoute
          exact
          path="/reports/db-ar-balance-report"
          component={DirectBillingARBalanceReport}
        />
        <ContentRoute
          exact
          path="/reports/db-ar-aging-report"
          component={DirectBillingArAgingReport}
        />

        <ContentRoute path="/reports/custom" component={CustomizedReport} />
        {/* <ContentRoute path="/reports" component={Report} /> */}
        <ContentRoute exact path="/reports" component={ReportWrapper} />
        <ContentRoute path="/calendar" component={Calendar} />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/channelMapping" component={ChannelMapping} />
        <ContentRoute path="/propertyDetails" component={PropertyDetails} />
        <ContentRoute
          path="/folios/AdvancedPortfolio"
          component={AdvancedPortfolioWrapper}
        />
        <ContentRoute
          path="/shops/startOrder/cartInvoice"
          component={CartInvoice}
        />
        <ContentRoute path="/houseKeeping" component={HouseKeeping} />
        <ContentRoute path="/shops/:shopId/startOrder" component={StartOrder} />
        <ContentRoute
          path="/shops/shopConfiguration"
          component={ShopConfiguration}
        />
        <ContentRoute
          path="/shops/addShop-addDetails"
          component={AddNewShopAdditionalDetails}
        />
        <ContentRoute path="/shops" component={Shops} />
        <ContentRoute
          path="/inventory-calendar"
          component={InventoryCalendar}
        />
        <ContentRoute path="/posDetailReport" component={POSDetailReport} />
        <ContentRoute path="/posReport" component={POSReport} />
        <ContentRoute path="/travelAgentReport" component={TravelAgentReport} />
        <ContentRoute path="/flexCollectReport" component={FlexCollectReport} />
        <ContentRoute path="/customers" component={CustomerReport} />
        <ContentRoute
          path="/reports/new-bookings"
          component={NewBookingsReport}
        />
        <ContentRoute path="/reports/no-show" component={NoShowReport} />

        {/* <ContentRoute path="/taxReport" component={TaxReport} /> */}
        <ContentRoute path="/roomRevenueReport" component={RoomRevenueReport} />
        <ContentRoute path="/inventory" component={InventoryReport} />
        <ContentRoute path="/customizedReport" component={CustomizedReport} />
        <ContentRoute
          exact
          path="/reports/user-log"
          component={UserLogsReport}
        />
        <ContentRoute path="/reports/shift" component={ShiftReport} />
        <ContentRoute path="/calendar" component={Calendar} />
        <ContentRoute path="/account-settings" component={AccountSetting} />
        <ContentRoute path="/account-info" component={AccountInfo} />
        <ContentRoute path="/booking-dashboard" component={BookingDashboard} />
        <ContentRoute
          path="/property-dashboard"
          component={PropertyDashboard}
        />
        <ContentRoute path="/directBilling" component={DirectBilling} />
        <ContentRoute
          path="/reports/bar-pricing"
          component={BarPricingReport}
        />
        <ContentRoute path="/reports/commission" component={CommissionReport} />
        <ContentRoute
          path="/reports/meal-plan-count"
          component={MealPlanCountReport}
        />
        <ContentRoute
          path="/expense-dashboard"
          component={ExpenseManagerDashBoard}
        />

        <ContentRoute
          path="/reputation-dashboard"
          component={ReputationManagementDashBoard}
        />
        <ContentRoute
          path="/payment-configuration"
          component={PaymentsDashboard}
        />

        <ContentRoute path="/nightAudit" component={NightAuditDashboard} />
        <ContentRoute
          path="/recommendations"
          component={DashboardRecommendation}
        />
        <ContentRoute path="/reportScheduler" component={ReportSchedule} />
        <ContentRoute path="/revenue-dashboard" component={RevenueDashboard} />
        {/* Inventory & Stock Management */}
        <ContentRoute path="/stock/rms" component={RawMaterialSupplier} />
        <ContentRoute path="/stock/hkrms" component={HousekeepingRawMaterial} />
        <ContentRoute
          path="/stock/hkrecipe"
          component={AddHousekeepingMaterial}
        />

        <ContentRoute path="/stock/kc" component={KitchenConfig} />
        <ContentRoute path="/stock/wrm" component={WarehouseRawMaterial} />
        <ContentRoute path="/stock/krm" component={KitchenRawMaterial} />
        <ContentRoute path="/stock/cls" component={ClosingStock} />
        <ContentRoute path="/stock/rmw" component={RawMaterialWastage} />
        <ContentRoute path="/stock/pr" component={ProductRecipe} />
        <ContentRoute path="/stock/po" component={PurchaseOrder} />
        <ContentRoute path="/stock/reports" component={InventoryStockReport} />
        <ContentRoute
          path="/stock/stock-dashboard"
          component={StockDashboard}
        />
        <ContentRoute path="/inbox/:customerId?" component={Chat} />
        <Redirect
          from="*"
          to={{ pathname: "/dashboard", search: `?hotelId=${hotelId}` }}
        />
      </Switch>
    </Suspense>
  );
}
