export const InventoryDateformat = "DD-MM-YYYY";

export const DropdownOptions = [
  { text: "Set Pricing", val: "set-price" },
  { text: "Set Inventory", val: "set-inventory" },
  { text: "Rate Multiplier", val: "rate-multiplier" },
  { text: "Inventory Capping", val: "inventory-cap" },
  { text: "Set Online", val: "set-online" },
  { text: "Set Offline", val: "set-offline" },
  { text: "Block Rooms", val: "block-room" },
  { text: "Unblock Rooms", val: "unblock-room" },
  { text: "Delete Rooms", val: "delete-room" },
  { text: "Restore Deleted Rooms", val: "restore" },
  { text: "Update Rates from OTA", val: "update-ota" },
  { text: "Set Sold Out", val: "sold-out" },
  { text: "Edit Room Ids", val: "edit-room" },
  { text: "Reassign Room Type", val: "reassign" },
  { text: "Close/Open Rate Plan", val: "Close Rate" },
  { text: "Close/Open Room Type", val: "Close Room" },
  { text: "Close/Open On Arrival", val: "Close On Arrival" },
  { text: "Close/Open On Departure", val: "Close On Departure" },
  { text: "Min Length of Stay", val: "Set Min Los" },
  { text: "Max Length of Stay", val: "Set Max Los" },
  { text: "Min Advanced Booking Offset", val: "Set Cutoff" },
  { text: "Max Advanced Booking Offset", val: "Set Max Adv Offset" },
];

export const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const OpenCloseOptions = {
  "Close Rate": "Close/Open Rate Plan",
  "Close Room": "Close/Open Room Type",
  "Close On Arrival": "Close/Open On Arrival",
  "Close On Departure": "Close/Open On Departure",
  "Set Min Los": "Min Length of Stay",
  "Set Max Los": "Max Length of Stay",
  "Set Cutoff": "Min Advanced Booking Offset (in hrs)",
  "Set Max Adv Offset": "Max Advanced Booking Offset (in hrs)",
};

export const ratesInventory = ["Rates", "Invenory"];
