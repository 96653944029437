import {
  Document,
  PDFDownloadLink,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { AES, enc } from "crypto-js";
import React, { useEffect, useState } from "react";
import InvoiceDoc from "../Folios/InvoiceDoc";
import { handleSpringDataRequest } from "../../api";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Currencies from "../../Utils/Currencies.json";
import { CircularProgress } from "@mui/material";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const FolioInvoiceRender = () => {
  const [urlVal, setUrlVal] = useState("");
  const url = new URL(window.location.href);
  const secretKey = "zZIhcIWLSVS2DyAl2l6nyPbXxUsUM36O";
  // const urlname = url.searchParams.get("name");
  const hash = url.searchParams.get("hash");
  const bookingId = AES.decrypt(hash, secretKey).toString(enc.Utf8);
  const hotelId = bookingId?.split("_")[1];

  const [invoiceData, setInvoiceData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [customFolioConfig, setCustomFolioConfig] = useState(null);
  const [hotelSymbol, setHotelSymbol] = useState("");
  const [defaultCtadropdown, setDefaultdropdown] = useState([]);
  const [cancelPolicyCondition, setCancelPolicyCondition] = useState(false);

  const currency = "INR";
  // getting the hotel currency
  const getHotelCurrency = () =>
    Currencies?.filter((item) => {
      if (item.code === currency) {
        sessionStorage.setItem("symbol", item.symbol);
        return setHotelSymbol(item.symbol);
      }
    });

  const getCustomFolioConfig = async () => {
    const data = await axios
      .get(
        `${process.env.REACT_APP_API_URL}invoice/getCustomFolioConfig/?hotelId=${hotelId}`
      )
      .then((res) => res?.data)
      .then((res) => setCustomFolioConfig(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error("Error getting the folio config data"));
  };
  // Getting the hotel logo
  const [base64Img, setBase64Img] = useState(null);
  const handleBase64Img = async () => {
    const data = await axios
      .get(
        `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/image/get-hotel-base64-logo/?hotelId=${hotelId}`
      )
      .then((res) => res?.data)
      .then((res) => setBase64Img(res))
      .catch((err) => toast.error("Error in fethcing the hotel logo!"));
  };

  const getCtaDropdown = async () => {
    const data = await axios
      .get(
        `${process.env.REACT_APP_API_URL}invoice/getBookingSrcDropdownData/?hotelId=${hotelId}`
      )
      .then((res) => res?.data)
      .then((res) => {
        setDefaultdropdown(res);
      })
      .catch((err) => toast.error("Error fethcing cta dropdown."));
  };

  const getHotelTermsConditions = async () => {
    localStorage.setItem("tnc", "");
    const data = await axios
      .get(
        `${process.env.REACT_APP_SPRING_API_URL}web-api/get-hotel-tc?hotelId=${hotelId}`
      )
      .then((res) => res?.data)
      .then((res) => {
        if (res?.message) {
          localStorage.setItem("tnc", JSON.parse(res.message)?.termCondition);
        }
      })
      .catch((err) =>
        toast.error("Error in getting the hotel terms and conditions.")
      );
  };
  useEffect(async () => {
    const invoiceData = await axios
      .get(
        `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/folio/invoice-data-render/?hotel_id=${hotelId}&hotelId=${hotelId}&bookingId=${bookingId}&fromDB=false`
      )
      .then((res) => res?.data)
      .then((res) => {
        setInvoiceData(res);
        let ifShowNewCancellationPolicy = true;
        if (res?.invoice?.hasOwnProperty("cancellationPolicies")) {
          for (let policy of res?.invoice?.cancellationPolicies) {
            const policyArr = policy.split(":");
            const policyDesc = policyArr[1].trim();
            if (policyDesc.length === 0) {
              ifShowNewCancellationPolicy = false;
              break;
            }
          }
        } else {
          ifShowNewCancellationPolicy = false;
        }
        setCancelPolicyCondition(ifShowNewCancellationPolicy);
      })
      .catch((err) => toast.error("Error fetching invoice data details"));
    getHotelCurrency();
    handleBase64Img();
    const summaryData = await axios
      .get(
        `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/folio/invoice-summary-data/?hotel_id=${hotelId}&hotelId=${hotelId}&bookingId=${bookingId}&fromDB=false`
      )
      .then((res) => res?.data)
      .then((res) => setSummaryData(res))
      .catch((err) => toast.error("Error fetching invoice summary details"));
    getCustomFolioConfig();
    const groupHotelEmail = "";
    getCtaDropdown();
    getHotelTermsConditions();
  }, []);

  useEffect(() => {
    // console.log("invoice data: ", invoiceData);
    // console.log("summary data: ", summaryData);
    // console.log("customFolioConfig data: ", customFolioConfig);
    // console.log("hotelSymbol data: ", hotelSymbol);
    // console.log("base64Img data: ", base64Img);
    // console.log("defaultCtadropdown data: ", defaultCtadropdown);
  }, [
    invoiceData,
    summaryData,
    customFolioConfig,
    hotelSymbol,
    base64Img,
    defaultCtadropdown,
    cancelPolicyCondition,
  ]);

  const redirect = (url) => {
    window.location.href = url;
  };

  return (
    <>
      {invoiceData !== null &&
        summaryData !== null &&
        customFolioConfig !== null &&
        defaultCtadropdown !== undefined &&
        base64Img && (
          <PDFDownloadLink
            fileName="test.pdf"
            document={
              <Document>
                <Page>
                  <InvoiceDoc
                    invoice={invoiceData?.invoice}
                    symbol={hotelSymbol}
                    hotelLogo={base64Img}
                    hotelData={invoiceData?.hotel}
                    summaryData={summaryData}
                    customDetails={customFolioConfig}
                    customerIdentification={invoiceData?.invoice.identification}
                    //   selfCheckin={selfCheckins && selfCheckins}
                    groupHotelEmail={""}
                    ctaDetails={
                      defaultCtadropdown?.filter(
                        (cta) =>
                          cta?.cta_name === invoiceData?.invoice?.booking_source
                      )[0]
                    }
                    ifShowNewCancellationPolicy={cancelPolicyCondition}
                  />
                </Page>
              </Document>
            }
          >
            {({ blob, url, loading, error }) => {
              //   console.log("loading: ", loading, " url: ", url);
              if (!loading && url !== null) {
                // setUrlVal(url);
                redirect(url);
              }
              return (
                <>
                  {!loading && url !== null ? (
                    ""
                  ) : (
                    <></>
                    // <CircularProgress
                    //   size="100px"
                    //   style={{ marginLeft: "45%" }}
                    // />
                  )}
                </>
              );
            }}
          </PDFDownloadLink>
        )}
      <CircularProgress size="100px" style={{ marginLeft: "45%" }} />
    </>
  );
};

export default FolioInvoiceRender;
