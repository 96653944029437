import React from "react";

export const CardLoaders = () => {
  return (
    <div
      className={`cardsLoader position-relative w-100 d-flex justify-content-between flex-wrap wrap mr-5 mt-4`}
    >
      <div className="cardLoader"> </div>
      <div className="cardLoader"> </div>
      <div className="cardLoader"> </div>
      <div className="cardLoader"> </div>
    </div>
  );
};

export const ShopCardLoaders = () => {
  return (
    <div
      className={`cardsLoader position-relative w-100 d-flex justify-content-between flex-wrap wrap mr-5 mt-4`}
    >
      <div className="shopCardLoader"> </div>
      <div className="shopCardLoader"> </div>
      <div className="shopCardLoader"> </div>
    </div>
  );
};

export const ShopImgLoaders = () => {
  return (
    <div
      className={`cardsLoader position-relative w-40 d-flex justify-content-between flex-wrap wrap mr-5 mt-4`}
    >
      <div className="imgLoader"> </div>
    </div>
  );
};

export const MenuItemLoaders = () => {
  return (
    <div
      className={`menuItemLoaders position-relative w-100 d-flex justify-content-start flex-wrap wrap`}
    >
      <div className="menuItemLoader"> </div>
      <div className="menuItemLoader"> </div>
      <div className="menuItemLoader"> </div>
      <div className="menuItemLoader"> </div>
      <div className="menuItemLoader"> </div>
    </div>
  );
};

export const CategoryLoaders = () => {
  return (
    <div
      className={`categoryLoaders position-relative w-100 d-flex justify-content-start flex-wrap wrap`}
    >
      <div className="categoryLoader"> </div>
      <div className="categoryLoader"> </div>
      <div className="categoryLoader"> </div>
      <div className="categoryLoader"> </div>
      <div className="categoryLoader"> </div>
    </div>
  );
};

export const ItemViewLoaders = () => {
  return (
    <div
      className={`itemViewLoaders position-relative w-100 d-flex justify-content-center flex-wrap wrap`}
    >
      <div className="itemViewLoader"> </div>
      <div className="itemViewLoader"> </div>
      <div className="itemViewLoader"> </div>
      <div className="itemViewLoader"> </div>
    </div>
  );
};

export const MenuLoaders = () => {
  return (
    <div className="menuLoaders position-relaitve w-100 d-flex justify-content-center flex-wrap wrap">
      <div className="menuLoader"> </div>
      <div className="menuLoader"> </div>
      <div className="menuLoader"> </div>
      <div className="menuLoader"> </div>
      <div className="menuLoader"> </div>
      <div className="menuLoader"> </div>
      <div className="menuLoader"> </div>
    </div>
  );
};
