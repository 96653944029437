import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_API_URL}`;
const JAVA_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const GETBOOKINGNOTES = "invoice/getBookingNotes/";
const DELETEBOOKINGNOTES = "invoice/deleteBookingNote/";
const GETCUSTOMERNOTES = "invoice/getCustomerNotes/";
const DELETECUSTOMERNOTES = "invoice/deleteCustomerNote/";
const CREATEBOOKINGNOTE = "invoice/recordBookingNote/";
const CREATECUSTOMERNOTE = "invoice/recordCustomerNote/";

export const getAllBookingNotes = async (bookingId) => {
  return axios
    .get(COMMON_URL + GETBOOKINGNOTES + "?bookingID=" + bookingId)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllCustomerNotes = async (email) => {
  return axios
    .get(COMMON_URL + GETCUSTOMERNOTES + "?cust_email=" + email)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteTheBookingNotes = async (bookingId, id, email, note) => {
  return axios
    .get(
      COMMON_URL +
        DELETEBOOKINGNOTES +
        "?bookingID=" +
        bookingId +
        "&id=" +
        id +
        "&logged_in_user=" +
        email +
        "&note=" +
        note
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteTheCustomerNotes = async (
  email,
  id,
  bookingId,
  user,
  note
) => {
  return axios
    .get(
      COMMON_URL +
        DELETECUSTOMERNOTES +
        "?cust_email=" +
        email +
        "&id=" +
        id +
        "&bookingId=" +
        bookingId +
        "&logged_in_user=" +
        user +
        "&note=" +
        note
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addNewBookingNote = async (data) => {
  return axios
    .post(COMMON_URL + CREATEBOOKINGNOTE, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addNewCustomerNote = async (data) => {
  return axios
    .post(COMMON_URL + CREATECUSTOMERNOTE, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addNewDoNotRentStatus = async (data) => {
  return axios
    .put(
      JAVA_URL +
        `core/api/v1/do-not-rent/create?emailId=${data.emailId}&severity=${data.severity}`
    )
    .then((resp) => resp)
    .catch((err) => {
      throw new Error(err);
    });
};

export const removeNewDoNotRentStatus = async (data) => {
  return axios
    .put(
      JAVA_URL +
        `core/api/v1/do-not-rent/update?emailId=${data.emailId}&status=${data.status}&severity=${data.severity}`
    )
    .then((resp) => resp)
    .catch((err) => {
      throw new Error(err);
    });
};

export const upadateNotes = async (email) => {
  return axios
    .get(
      JAVA_URL +
        "core/api/v1/reservation/update-do-not-rent" +
        "?email=" +
        email
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
