import React, { useCallback, useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-material.css";
import PDFExportPanel from "./pdfExport/PDFExportPanel.js";
import "ag-grid-enterprise";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import ReportWrapper from "./ReportWrapper";
import {
  MenuItem,
  TextField,
  Stack,
  InputLabel,
  FormControl,
  Select,
  Button,
  ClickAwayListener,
  Portal,
  Box,
  Switch,
  FormGroup,
  FormControlLabel,
  Typography,
  Divider,
  Checkbox,
  CircularProgress,
  Modal,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import CustomReportModal from "./CustomReportModal";
import ReportsLoader from "../../Utils/ReportsLoader";
import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api/index";
import CustomDateComponent from "./customDateComponent";
import Currencies from "../../Utils/Currencies.json";
import { defaultStaticRanges } from "../../Utils/DefaultRange";
import amplitude from "amplitude-js";
import {
  AMPLITUDE_KEY,
  baseUrl,
  ENV,
  springBaseUrl,
} from "../CalendarPage/constants/AmplitudeConstants";
import { sideBar } from "../../Utils/Utils";
import "react-responsive-modal/styles.css";
// import { Modal } from "react-responsive-modal";
import { connect } from "react-redux";
import { toast } from "material-react-toastify";
import {
  handleDate,
  handleFolioDate,
  handleNumDisplay,
  generateOriginalValue,
} from "./utils/ReportUtils";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {
  formatHeaderName,
  formatSingleDateRange,
} from "./utils/ReportUtils.js";
import CustomReportPdf from "./utils/CustomReportPdf.js";
import * as RiIcons from "react-icons/ri";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { set } from "date-fns";
import { GenerateRoomRevCols } from "./ManagerialReports/RoomRevenueReport/RoomRevReportColumns.js";
import LockIcon from "@mui/icons-material/Lock";
import Lottie from "react-lottie";
import successAnimationData from "../../../app/pages/CalendarPage/Views/lotties/celebration.json";
import { getCsmDetails } from "../../../_metronic/layout/components/extras/dropdowns/apis/NotificationAPI.js";
import { getAllImagesAndUrl } from "../BookingDashboard/apis/BrandingModalApi.js";

const styles = {
  position: "fixed",
  width: "fitContent",
  top: "45%",
  left: "45%",
  transform: "translate(-50%, -50%)",
  p: 1,
  boxShadow: 24,
  bgcolor: "white",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  maxHeight: 550,
  pb: 2,
  overflow: "none",
  overflowY: "scroll",
  outline: "none",
  paddingBottom: "10px",
};

const clickAwayListenerStyle = {
  position: "absolute",
  top: 35,
  right: 0,
  left: 0,
  zIndex: 1,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  lineHeight: 24,
  borderRadius: "5px",
};

function AgGridWrapper({
  data,
  reportName,
  enableSearch = true,
  enablePagination = true,
  disableDatePicker = false, //when true no datepicker will be shown
  enableRangeCalendar = true, //when true show datepicker with range selector
  enableDateTypeFilters = true,
  enableReportExport = true,
  enableTallyExport = false,
  enablePdfExport = true,
  enableCustomizedReport = true,
  columns,
  reportActualType,
  createPinnedData = null,
  flex = false,
  dashReports = false,
  pgReport = false,
  dynamicReport = false,
  posReport = false,
  enableRoomStatusFilter = false,
  userLogReport = false,
  folioReport = false,
  pinnedRow = null,
  hideIcon,
  updateCurrentCols,
}) {
  // Getting the url params
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");

  // console.log("Inwside AGGrid Wrapper hideIcon", hideIcon);

  // Setting up the hotel currency
  const hotelCurrency = data.accessControl.hotel.hotel_currency;
  const [hotelSymbol, setHotelSymbol] = useState("");

  // Setting up the currency symbol
  const getHotelCurrency = () =>
    Currencies?.filter((item) => {
      if (item.code === hotelCurrency) {
        return setHotelSymbol(item.symbol);
      }
    });

  useEffect(() => {
    getHotelCurrency();
  }, []);

  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //Amplitude check on Master page
  const amplitudeEventProperties = {
    hotel_id: data?.hotelId,
    environment: ENV,
    email: data?.accessControl?.email,
    role: data?.accessControl?.ADMINRIGHTS,
  };

  useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_KEY, data?.hotelId, {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
    });
    amplitude
      .getInstance()
      .identify(
        new amplitude.Identify()
          .set("hotel_id", hotelId)
          .set("email", data?.accessControl?.email)
          .set("role", data?.accessControl?.ADMINRIGHTS)
      );
    amplitude
      .getInstance()
      .logEvent(
        `${reportName.toUpperCase()} PAGE LOADED`,
        amplitudeEventProperties
      );
  }, []);

  //   Setting up the document name while downloading
  const [docName, setDocName] = useState();
  useEffect(() => {
    setDocName(`${reportName.toLowerCase()}_` + data?.hotelId);
  }, []);

  // Creating the reference for the reports table
  const reportTableRef = useRef();
  //   Setting the AG grid properties
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [allCols, setAllCols] = useState(null);
  const [colmap, setColmap] = useState(null);

  // default column properties
  const defaultColDefs = {
    sortable: true, //sort the table
    floatingFilter: true, // getting the filter below the columns
    filter: "agMultiColumnFilter", // setting the multi column filter for the table
    display: "subMenu", // how the text filter and the set filter will look like
    components: {
      agDateInput: CustomDateComponent,
    },
    resizable: true,
    cellRendererParams: {
      checkbox: true,
    },
    flex: flex && 1,
  };

  //   Quick Filters & Operations
  // dynamically change the page size
  const onPaginationChange = (pageSize) => {
    gridApi.paginationSetPageSize(pageSize);
  };

  // quick search
  const onFilterTextChange = (event) => {
    // setFilterCount((prevCount) => prevCount + 1);
    gridApi.setQuickFilter(event.target.value);
  };

  // Selection of rows and related operations
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRowsLength, setSelectedRowsLength] = useState(0);

  const onSelectionChanged = (params) => {
    createPinnedData(params, rowsData);
    setSelectedRowsLength(params.api.getSelectedRows().length);
    setSelectedRows(params.api.getSelectedRows());
  };

  // Getting the number of enteries
  const [numEnteries, setNumEnteries] = useState(0);
  const [selectedNumEnteries, setSelectedNumEnteries] = useState(0);
  useEffect(() => {
    setSelectedNumEnteries(selectedRowsLength);
    setNumEnteries(rowsData?.length);
  }, [rowsData, selectedRowsLength]);

  // For POS reports - Fetching all the outlets created
  const [allShops, setAllShops] = useState(null);
  const handleGetAllShops = () =>
    handleSpringDataRequest(
      `core/api/v1/shop/category/get-all?email=${localStorage.getItem("email")}`
    )
      .then((res) => setAllShops(res))
      .catch((err) => toast.error("Error in getting the shop details!"));

  useEffect(() => {
    if (posReport) {
      handleGetAllShops();
    }
  }, [posReport]);

  const [rowsData, setRowsData] = useState(null);

  // Fetching the report data
  const fetchReportData = (roomRevReportType = "roomRevenueReport") => {
    setRowsData(null);
    if (posReport) {
      let temp = [];
      allShops &&
        allShops.forEach((shop) => {
          djangoHandleDataRequests(
            `pos/${reportActualType}/?shop_id=${shop?.id}&start_date=${moment(
              datePicker[0].startDate
            ).format("YYYY-MM-DD 00:00:00")}&end_date=${moment(
              datePicker[0].endDate
            ).format("YYYY-MM-DD 23:59:59")}&hotel_id=${hotelId}`
          )
            .then((res) => {
              temp = [...temp, ...res];
              setRowsData(temp);
            })
            .catch((err) => toast.error("Error in fetching the data."));
        });
    } else if (dynamicReport) {
      const checkCol = columns.map((item) => item.field);
      // console.log("check cols : ", checkCol);
      djangoHandleDataRequests(
        `reports/getReportData/?report_type=${reportActualType}&start_date=${handleDate(
          datePicker[0].startDate
        )}&end_date=${handleDate(datePicker[0].endDate)}`
      ).then((res) => {
        if (res.report_data) {
          let tempArr = [];
          res.report_data.map((items) =>
            Object.keys(items).map((item) => tempArr.push(item))
          );
          let tempArrSet = Array.from(new Set(tempArr));
          // console.log("temp arr set : ", tempArrSet);
          const data = ["taxReport"].includes(reportActualType)
            ? tempArrSet
                .filter(
                  (items) => !checkCol.includes(items) && items !== "booking_id"
                )
                .map((item) => ({
                  field: item,
                  headerName: formatHeaderName(item),
                  valueGetter: (params) => generateOriginalValue(params, item),
                  valueFormatter: (params) => handleNumDisplay(params, "float"),
                  pinnedRowValueFormatter: (params) =>
                    handleNumDisplay(params, "float"),
                  aggFunc: "sum",
                }))
            : tempArrSet
                .filter(
                  (items) => !checkCol.includes(items) && items !== "booking_id"
                )
                .map((item) => ({
                  field: item,
                  headerName: formatHeaderName(item),
                }));
          // columns.push(...data);
          updateCurrentCols(data);
        }
        // console.log("ag grid wrapper for tax report : ", data);
        setRowsData(res.report_data);
      });
    } else if (dashReports) {
      djangoHandleDataRequests(
        `reports/generateDashDataLite/?date=${moment(date).format(
          "ddd MMM DD YYYY"
        )}&is_today=${moment(formattedDate)
          .isSame(moment(moment().toDate()).format("ddd MMM DD YYYY"))
          .toString()}`
      )
        .then((res) => setRowsData(res[reportActualType]))
        .catch((err) => toast.error("Error in fetching the data."));
    } else if (folioReport) {
      djangoHandleDataRequests(
        `invoice/getHistoricalInvoices/?daterange=${
          handleFolioDate(datePicker[0].startDate) +
          " - " +
          handleFolioDate(datePicker[0].endDate)
        }&balanceDueOnly=${balanceDueOnly}&date_filter_mode=${filterDateType}`
      )
        .then((res) => setRowsData(res.data))
        .catch((err) => toast.error("Error in fetching the report data"));
    } else if (reportActualType === "inventoryLogsReport") {
      handleSpringDataRequest(
        `core/api/v1/cmLogs/getCalendarLogs?date=${moment(date).format(
          "DD-MM-YYYY"
        )}`
      )
        .then((res) => setRowsData(res))
        .catch((err) => toast.error(err));
    } else if (
      reportActualType === "get-hotel-commission-report" ||
      reportActualType === "get-meal-plan-count-report"
    ) {
      handleSpringDataRequest(
        `core/api/v1/reports/${reportActualType}?fromDate=${moment(
          datePicker[0].startDate
        ).format("DD-MM-YYYY")}&toDate=${moment(datePicker[0].endDate).format(
          "DD-MM-YYYY"
        )}`
      )
        .then((res) => setRowsData(res))
        .catch((err) => toast.error(err));
    } else if (reportActualType === "roomRevenueReport") {
      let rrReportColsInstance = new GenerateRoomRevCols(
        roomRevReportType,
        hotelCurrency
      );
      const updateRRCols = rrReportColsInstance.generateColumns()[0];
      // console.log("updateRRCols : ", updateRRCols);
      columns.length = 0;
      djangoHandleDataRequests(
        `reports/getReportData/?report_type=${roomRevReportType}${
          enableRangeCalendar
            ? `&start_date=${handleDate(
                datePicker[0].startDate
              )}&end_date=${handleDate(datePicker[0].endDate)}`
            : `&start_date=${formatSingleDateRange(
                date,
                "default"
              )}&end_date=${formatSingleDateRange(date, "default")}`
        }${enableDateTypeFilters ? `&date_filter_mode=${filterDateType}` : ""}${
          pgReport ? `&showAll=${showAll}` : ""
        }${
          enableRoomStatusFilter ? `&date_filter_mode=${roomStatusFilter}` : ""
        }`
      )
        .then((res) => {
          columns.push(...updateRRCols);
          setTimeout(() => {
            setRowsData(res.report_data);
          }, 500);
        })
        .catch((err) => "Error in fetching the data");
    } else {
      djangoHandleDataRequests(
        `reports/getReportData/?report_type=${reportActualType}${
          enableRangeCalendar
            ? `&start_date=${handleDate(
                datePicker[0].startDate
              )}&end_date=${handleDate(datePicker[0].endDate)}`
            : `&start_date=${formatSingleDateRange(
                date,
                "default"
              )}&end_date=${formatSingleDateRange(date, "default")}`
        }${enableDateTypeFilters ? `&date_filter_mode=${filterDateType}` : ""}${
          pgReport ? `&showAll=${showAll}` : ""
        }${
          enableRoomStatusFilter ? `&date_filter_mode=${roomStatusFilter}` : ""
        }${reportActualType === "&occupancyReport" ? `is_update=false` : ""}`
      )
        .then((res) => setRowsData(res.report_data))
        .catch((err) => "Error in fetching the data");
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setAllCols(params.columnApi.columnController.columnDefs);
    const mp = new Map();
    const dt = params.columnApi.columnController.columnDefs.map((res, idx) => {
      mp.set(res.field, {
        header: res.headerName,
        hidden: false,
        type: "",
        order: idx + 1,
      });
    });
    setColmap(mp);
    createPinnedData && createPinnedData(params, rowsData);
  };

  // settings the grid Option
  const gridOptions = {
    pagination: true,
    // generation of the pinned bottom row data
    onFilterChanged: (params) => {
      createPinnedData(params, rowsData);
    },
  };

  /*  CALENDAR */
  /* 1. MULTI RANGE */
  /* 2. SINGLE DATE */

  // Setting up the date range (multi range)
  const [datePicker, setDatePicker] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  // Setting up date (single date range)
  const currentDate = moment().format("yyyy-MM-DD");
  const [formattedDate, setFormattedDate] = useState(currentDate);
  const [date, setDate] = useState(new Date());

  // Setting up the date time range
  const [local, setLocal] = useState({
    format: "DD-MM-YYYY HH:mm:ss",
    sundayFirst: false,
  });
  const applyCallback = (start, end) => {
    const newStart = handleDate(start);
    const newEnd = handleDate(end);
    setDatePicker({
      startDate: start,
      endDate: end,
    });
    setRowsData(null);
    handleSpringDataRequest(
      `common/get-user-logs?fromDate=${newStart}&toDate=${newEnd}`
    )
      .then((res) => setRowsData(res))
      .catch((err) => toast.error("Error in fetching the user log report."));
    // calendarData();
  };

  // different date type fiters eg : checkin date, check out date, etc.
  const [filterDateType, setFilterDateType] = useState("staythrough");
  const onChangeDateFilter = (filterType) => {
    setFilterDateType(filterType);
  };

  // Filtering based on the availability of the rooms
  const [roomStatusFilter, setRoomStatusFilter] = useState("ALL");
  const onChangeRoomStatusFilter = (event) => {
    return setRoomStatusFilter(event.target.value);
  };

  //   Downloading reports in various formats
  // Click away listener for download options
  const [openDownloadType, setOpenDownloadType] = useState(false);
  const handleOpenDownloadType = () => {
    setOpenDownloadType((prev) => !prev);
  };

  const handleClickAwayDownloadType = () => {
    setOpenDownloadType(false);
  };

  const exportOptions = {
    processCellCallback: (params) => {
      const requiredCols = columns
        .filter((col) => col.dataType === "float")
        .map((col) => col.field);
      if (requiredCols.includes(params.column.getColDef().field)) {
        if (params.value) {
          const val = Number(params.value);
          return val.toFixed(2);
        }
      }
      return params.value;
    },
  };

  const onExportClick = () => {
    const params = selectedRowsLength === 0 ? {} : { onlySelected: true };
    gridApi.exportDataAsCsv(
      Object.assign({}, params, {
        processCellCallback: exportOptions.processCellCallback,
      })
    );
  };

  const onExportExcel = () => {
    const params = selectedRowsLength === 0 ? {} : { onlySelected: true };
    gridApi.exportDataAsExcel(
      Object.assign({}, params, {
        processCellCallback: exportOptions.processCellCallback,
      })
    );
  };

  //Creating state for storing Tally export data
  const [tallyData, setTallyData] = useState({});

  //Exporting to Tally
  const onExportTally = () => {
    for (let i = 0; i < tallyData.length; i++) {
      delete tallyData[i].bookingid_display_name;
      delete tallyData[i].status_display_name;
      delete tallyData[i].booking_src_display_name;
    }
    if (rowsData.length > 0) {
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/tally/exportMasterReport`,
        tallyData
      )
        .then((res) => {
          if (res.includes("Error importing data")) {
            toast.error(res);
          } else {
            toast.success("Export to tally successful.");
          }
          fetchReportData();
        })
        .catch((err) => {
          toast.error("Please try again later.");
        });
    }
  };

  useEffect(() => {
    setTallyData(rowsData);
  }, [rowsData]);

  // const getDownloadType = (dType) => {
  //   dType === "1" && onExportClick();
  //   dType === "2" && handleOpen();
  //   dType === "3" && onExportExcel();
  //   dType === "4" && onExportTally();
  // };

  // Show all status for payment reports
  const [showAll, setShowAll] = useState(false);
  // useEffect(() => {
  //   fetchReportData();
  // }, [showAll]);

  //   Handling the date range modal
  const [openDateRange, setOpenDateRange] = useState(false);
  const handleOpenDateRange = () => setOpenDateRange(true);
  const handleClickAway = () => setOpenDateRange(false);

  //   Handling the modal for PDF export
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //   Custom report modal
  const [addCustomReport, setAddCustomReport] = useState(false);
  const openAddCustomReport = () => {
    setAddCustomReport(true);
  };
  const hideAddCustomReport = () => {
    setAddCustomReport(false);
  };

  // For folio report toggling between the balance due toggle
  const [balanceDueOnly, setBalanceDueOnly] = useState(false);
  const onToggleBalanceDue = (e) => {
    if (balanceDueOnly === false) {
      setBalanceDueOnly(true);
    } else if (balanceDueOnly === true) {
      setBalanceDueOnly(false);
    }
  };

  // useEffect(() => {
  //   if (folioReport) {
  //     fetchReportData();
  //   }
  // }, [balanceDueOnly]);

  useEffect(() => {
    if (reportActualType === "roomRevenueReport") {
      handleCallRoomRevMode();
    } else {
      fetchReportData();
    }
  }, [
    filterDateType,
    roomStatusFilter,
    date,
    allShops,
    showAll,
    balanceDueOnly,
  ]);

  function onSaveGridColumnState() {
    if (!gridColumnApi) return;
    let reportActualTypeName = reportActualType;
    if (reportActualType === "roomRevenueReport") {
      reportActualTypeName += roomRevMode;
    }
    let columnState = gridColumnApi?.getColumnState();
    // console.log("current column state : ", columnState);
    sessionStorage.setItem(reportActualTypeName, JSON.stringify(columnState));
    for (let i = 0; i < columns.length; i++) {
      const updatedState = columnState?.filter(
        (item) => item.colId === columns[i]["field"]
      );
      columns[i]["hide"] = updatedState[0]?.hide;
      columns[i]["sort"] = updatedState[0]?.sort;
    }
  }

  function onFirstDataRendered() {
    let reportActualTypeName = reportActualType;
    if (reportActualType === "roomRevenueReport") {
      reportActualTypeName += roomRevMode;
    }
    let columnState = null;
    if (sessionStorage?.getItem(reportActualTypeName)) {
      columnState = JSON.parse(sessionStorage?.getItem(reportActualTypeName));
    }
    if (columnState) {
      return gridColumnApi?.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  }

  // Function to update the row data on filter changed
  let [updatedRowsData, setUpdatedRowsData] = useState(rowsData);
  useEffect(() => {
    setUpdatedRowsData(rowsData);
  }, [rowsData]);

  function updateRowData(params) {
    // console.log("params while updating the rows data : ", params);
    createPinnedData(params);
    let updatedData = [];
    gridApi.forEachNodeAfterFilterAndSort((i) => {
      updatedData.push(i.data);
    });
    return setUpdatedRowsData(updatedData);
  }

  // Getting the hotel logo
  const [hotelLogo, setHotelLogo] = useState(null);
  const getHotelLogo = useCallback(() => {
    handleSpringDataRequest("core/api/v1/image/get-hotel-base64-logo/")
      .then((res) => setHotelLogo(res))
      .catch((err) => console.log(err));
  });
  useEffect(() => {
    getHotelLogo();
  }, [hotelId]);

  // if row data gets changed
  function handleRowData(params) {
    createPinnedData(params, rowsData);
  }

  // Deselect all the rows after certain operation
  function handleDeselectAllRows() {
    return reportTableRef?.current?.api?.deselectAll();
  }

  // Only for FOLIO REPORT
  // Handle bulk cancel booking
  // We need options to void the charges, mark booking as no show
  // Defining the modal for the same
  const [operationType, setOperationType] = useState("VOID");
  const [performBulkOperationModal, setPerformBulkOperationModal] =
    useState(false);
  function handleOpenBulkOperationModal() {
    setPerformBulkOperationModal(true);
  }
  function handleCloseBulkOperationModal() {
    setPerformBulkOperationModal(false);
    handleDeselectAllRows();
    setOperationType("VOID");
  }

  // Void options
  const [voidOption, setVoidOption] = useState("CHARGE_ONE_NIGHT");
  function handleSetVoidOption() {
    if (operationType === "VOID") {
      setVoidOption("CHARGE_ONE_NIGHT");
    } else {
      setVoidOption("NO_VOID");
    }
  }
  useEffect(() => {
    handleSetVoidOption();
  }, [operationType]);
  // Set booking to no show
  const [bookingNoShow, setBookingNoShow] = useState(false);
  // Loader
  const [bulkPerformOperationLoader, setBulkPerformOperationLoader] =
    useState(false);

  // Handle bulk cancel booking
  function handleBulkCancelBooking() {
    setBulkPerformOperationLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/bulk-cancel-booking?isNoShow=${bookingNoShow}&voidType=${voidOption}`,
      selectedRows.map(
        (row) => `SFBOOKING_${hotelId}_${row?.bookingid_display}`
      )
    )
      .then((res) => {
        toast.success(res);
        setTimeout(() => {
          fetchReportData();
        }, 500);
      })
      .catch((err) => toast.error(err))
      .finally(() => handleCloseBulkOperationModal())
      .finally(() => setBulkPerformOperationLoader(false))
      .finally(() => setOperationType("VOID"));
  }

  // Handle bulk void booking
  function handleBulkVoidBooking() {
    setBulkPerformOperationLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/bulk-settlement-booking?voidType=${voidOption}`,
      selectedRows.map(
        (row) => `SFBOOKING_${hotelId}_${row?.bookingid_display}`
      )
    )
      .then((res) => {
        toast.success(res);
        setTimeout(() => {
          fetchReportData();
        }, 500);
      })
      .catch((err) => toast.error(err))
      .finally(() => handleCloseBulkOperationModal())
      .finally(() => setBulkPerformOperationLoader(false))
      .finally(() => setOperationType("VOID"));
  }

  // Filters set for the room revenue report
  const [roomRevMode, setRoomRevMode] = useState("bookingid");
  function handleOnChangeRoomRevMode(e) {
    setRoomRevMode(e.target.value);
  }

  // console.log("room rev mode : ", roomRevMode);

  // In case the room rev mode changes call the related report data
  function handleCallRoomRevMode() {
    switch (roomRevMode) {
      case "bookingid":
        fetchReportData("roomRevenueReport");
        break;
      case "source":
        fetchReportData("roomRevenueReportSource");
        break;
      case "rateplan":
        fetchReportData("roomRevenueReportRateplan");
        break;
      default:
        fetchReportData("roomRevenueReport");
        break;
    }
  }

  useEffect(() => {
    handleCallRoomRevMode();
  }, [roomRevMode]);

  //PAYMENT
  const [opens, setOpens] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);

  const handleMenuModal = () => {
    setOpens(true);
  };

  const [paymentLoader, setpaymentLoader] = useState(false);
  const startTrial = () => {
    setpaymentLoader(true);
    const payload = {
      hotelId: hotelId,
      groupId: localStorage.getItem("mainGroupId"),
      description: `Advanced reports upgrade`,
      type: "ADDON",
      amount: hotelCurrency?.toUpperCase() === "INR" ? "999" : "49",
      addon_feature_id: 228,
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/feature-upgrade/single-feature-upgrade`,
      payload
    )
      .then(() => {
        setpaymentLoader(false);
        setOpen(false);
        setconfirmModal(true);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setpaymentLoader(false);
      });
  };

  const [csmData, setCsmData] = useState(null);
  useEffect(async () => {
    const groupId = data?.accessControl?.hotel?.group_id;
    if (groupId && csmData == null) {
      await getCsmDetails(groupId).then((resp) => {
        if (String(resp?.status) === "200") {
          const data = resp?.data;
          setCsmData(data);
        }
      });
    }
  }, [data?.accessControl?.hotel?.group_id]);

  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
  }, []);

  return (
    <>
      <ReportWrapper
        backBtn={true}
        showReportTypes={false}
        reportName={reportName}
        hideDrawer={true}
        hideIcon={hideIcon}
        filters={
          <Stack direction="row" spacing={2}>
            {enableSearch && (
              <TextField
                id="outlined-basic"
                onChange={onFilterTextChange}
                label="Search"
                size="small"
                sx={{ minWidth: 120 }}
              />
            )}
            {enablePagination && (
              <Box sx={{ minWidth: 75 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Pages</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={gridApi?.paginationPageSize}
                    label="Pages"
                    onChange={(e) => onPaginationChange(e.target.value)}
                  >
                    <MenuItem value={"10"}>10</MenuItem>
                    <MenuItem value={"20"}>25</MenuItem>
                    <MenuItem value={"50"}>50</MenuItem>
                    <MenuItem value={"100"}>100</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            {enableRangeCalendar ? (
              <ClickAwayListener onClickAway={handleClickAway}>
                {!disableDatePicker && (
                  <Box sx={{ position: "relative" }}>
                    <Stack
                      style={{
                        padding: "2px 3px 2px 3px",
                        borderRadius: "5px",
                        width: "175px",
                        alignContent: "center",
                        justifyContent: "center",
                        height: "35px",
                        backgroundColor: "transparent",
                        border: "1px solid #C4C4C4",
                        textAlign: "center",
                      }}
                      onClick={handleOpenDateRange}
                    >
                      {moment(datePicker[0].startDate).format("DD MMM YYYY") +
                        " - " +
                        moment(datePicker[0].endDate).format("DD MMM YYYY")}
                    </Stack>
                    {openDateRange ? (
                      <Portal>
                        <Box sx={styles}>
                          <Stack
                            style={{ position: "relative", width: "100%" }}
                          >
                            <DateRangePicker
                              editableDateInputs={true}
                              onChange={(item) =>
                                setDatePicker([item.selection])
                              }
                              moveRangeOnFirstSelection={false}
                              ranges={datePicker}
                              staticRanges={defaultStaticRanges}
                            />
                            <Button
                              variant="custom-button"
                              onClick={() => {
                                const dateDiff = moment(
                                  datePicker[0].endDate
                                ).diff(datePicker[0].startDate, "days");

                                if (
                                  JSON.parse(
                                    data?.accessControl?.user_feature_map
                                      ?.ReportsSchedule
                                  ).read === "YES" &&
                                  !["BETA"].includes(ENV?.toUpperCase())
                                ) {
                                  reportActualType === "roomRevenueReport"
                                    ? handleCallRoomRevMode()
                                    : fetchReportData();
                                  handleClickAway();
                                } else {
                                  if (dateDiff > 180) {
                                    handleMenuModal();
                                  } else {
                                    reportActualType === "roomRevenueReport"
                                      ? handleCallRoomRevMode()
                                      : fetchReportData();
                                    handleClickAway();
                                  }
                                }
                              }}
                              style={{
                                display: "flex",
                                position: "relative",
                                alignSelf: "flex-end",
                                width: "fit-content",
                                marginTop: "-40px",
                              }}
                            >
                              Apply
                            </Button>
                          </Stack>
                        </Box>
                      </Portal>
                    ) : null}
                  </Box>
                )}
              </ClickAwayListener>
            ) : (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {!disableDatePicker && (
                  <DesktopDatePicker
                    label="Select date"
                    // inputFormat="yyyy-MM-dd"
                    inputFormat="dd MMM yyyy"
                    value={date}
                    onChange={(item) => setDate(item)}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                )}
              </LocalizationProvider>
            )}
            {enableDateTypeFilters && (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Filters</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterDateType}
                    label="Filters"
                    onChange={(e) => onChangeDateFilter(e.target.value)}
                  >
                    <MenuItem value={"staythrough"}>Stay Through</MenuItem>
                    <MenuItem value={"checkin"}>Checkin Date</MenuItem>
                    <MenuItem value={"checkout"}>Checkout Date</MenuItem>
                    <MenuItem value={"booking_made_on"}>
                      Booking Made Date
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            {reportActualType === "roomRevenueReport" && (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Filters</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={roomRevMode}
                    label="Filters"
                    onChange={(e) => handleOnChangeRoomRevMode(e)}
                  >
                    <MenuItem value={"bookingid"}>By bookingid</MenuItem>
                    <MenuItem value={"source"}>By source</MenuItem>
                    <MenuItem value={"rateplan"}>By rateplan</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            {enableRoomStatusFilter && (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Filters</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={roomStatusFilter}
                    label="Filters"
                    onChange={onChangeRoomStatusFilter}
                  >
                    <MenuItem value={"ALL"}>All</MenuItem>
                    <MenuItem value={"OCCUPIED"}>Occupied</MenuItem>
                    <MenuItem value={"AVAILABLE"}>Available</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            {folioReport && (
              <Stack direction="row" spacing={1}>
                <Typography sx={{ marginTop: "auto", marginBottom: "auto" }}>
                  Balance due only
                </Typography>
                <Switch
                  sx={{ marginTop: "auto", marginBottom: "auto" }}
                  checked={balanceDueOnly === false ? false : true}
                  onChange={onToggleBalanceDue}
                />
              </Stack>
            )}
            {enableReportExport && (
              // <Box sx={{ minWidth: 120 }}>
              //   <FormControl fullWidth size="small">
              //     <InputLabel id="demo-simple-select-label">
              //       Export as
              //     </InputLabel>
              //     <Select
              //       labelId="demo-simple-select-label"
              //       id="demo-simple-select"
              //       value={"0"}
              //       label="Export as"
              //       onChange={(e) => getDownloadType(e.target.value)}
              //     >
              //       <MenuItem value={"0"}>Select option</MenuItem>
              //       <MenuItem value={"1"}>Export as CSV</MenuItem>
              //       {enablePdfExport && (
              //         <MenuItem value={"2"}>Export as PDF</MenuItem>
              //       )}
              //       <MenuItem value={"3"}>Export as Excel</MenuItem>
              //       {enableTallyExport && (
              //         <MenuItem value={"4"}>Export as Tally</MenuItem>
              //       )}
              //     </Select>
              //   </FormControl>
              // </Box>
              <ClickAwayListener
                onClickAway={handleClickAwayDownloadType}
                className="exportOptionWrapper"
              >
                <Box sx={{ position: "relative" }}>
                  <button
                    type="button"
                    className="exportBtns"
                    onClick={() => setOpenDownloadType((prev) => !prev)}
                  >
                    Export report{" "}
                    {openDownloadType ? (
                      <RiIcons.RiArrowUpSLine />
                    ) : (
                      <RiIcons.RiArrowDownSLine />
                    )}
                  </button>
                  {openDownloadType ? (
                    <Box sx={clickAwayListenerStyle}>
                      <Typography
                        onClick={() => onExportClick()}
                        className="exportOption"
                        sx={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          marginLeft: "10px",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                      >
                        Export as CSV
                      </Typography>
                      <Typography
                        className="exportOption"
                        sx={{
                          // marginBottom: "10px",
                          marginTop: "10px",
                          marginLeft: "10px",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                      >
                        <CustomReportPdf
                          reportColumns={columns}
                          reportData={
                            selectedRowsLength > 0
                              ? selectedRows
                              : updatedRowsData
                          }
                          reportName={reportName}
                          dateRange={{
                            startDate: enableRangeCalendar
                              ? datePicker[0]["startDate"]
                              : date,
                            endDate: enableRangeCalendar
                              ? datePicker[0]["endDate"]
                              : date,
                          }}
                          pinnedRow={pinnedRow}
                          hotelLogo={hotelLogo}
                        />
                      </Typography>
                      <Typography
                        onClick={() => onExportExcel()}
                        className="exportOption"
                        sx={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          marginLeft: "10px",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                      >
                        Export as Excel
                      </Typography>
                      {enableTallyExport && (
                        <Typography
                          onClick={() => onExportTally()}
                          className="exportOption"
                          sx={{
                            marginBottom: "10px",
                            marginTop: "10px",
                            marginLeft: "10px",
                            fontSize: "15px",
                            cursor: "pointer",
                          }}
                        >
                          Export as Tally
                        </Typography>
                      )}
                    </Box>
                  ) : null}
                </Box>
              </ClickAwayListener>
            )}
            {folioReport && (
              <Stack spacing={1} direction="row">
                <Button
                  variant="custom-button"
                  sx={{ height: "35px" }}
                  disabled={selectedRowsLength === 0}
                  onClick={() => {
                    handleOpenBulkOperationModal();
                  }}
                >
                  Bulk operations
                </Button>
              </Stack>
            )}
            {pgReport && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showAll}
                      onChange={() => setShowAll((prev) => !prev)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Show all"
                />
              </FormGroup>
            )}
            {enableCustomizedReport && (
              <Stack>
                <Button
                  variant="custom-button"
                  onClick={() => {
                    const resp =
                      JSON.parse(
                        data?.accessControl?.user_feature_map?.ReportsSchedule
                      ).read === "YES"
                        ? openAddCustomReport()
                        : handleMenuModal();
                  }}
                  startIcon={
                    JSON.parse(
                      data?.accessControl?.user_feature_map.ReportsSchedule
                    ).read !== "YES" && <LockIcon />
                  }
                >
                  Create customized report
                </Button>
              </Stack>
            )}
          </Stack>
        }
        contentArea={
          <Stack>
            <Stack className="agGridWrapr position-relative">
              <Stack
                spacing={2}
                className="agGridTableWrapper position-relative w-100"
              >
                {rowsData ? (
                  <Stack className="agTable">
                    <Stack className="ag-theme-material">
                      <AgGridReact
                        ref={reportTableRef}
                        className="agGridTable"
                        onFirstDataRendered={onFirstDataRendered}
                        onColumnVisible={onSaveGridColumnState}
                        onFilterChanged={updateRowData}
                        onGridReady={onGridReady}
                        onRowDataChanged={handleRowData}
                        rowData={rowsData}
                        columnDefs={columns}
                        defaultColDef={defaultColDefs}
                        enableBrowserTooltips={true}
                        onSelectionChanged={onSelectionChanged}
                        enableMultiRowDragging={false}
                        rowMultiSelectWithClick={false}
                        paginationPageSize={50}
                        alwaysShowBothConditions={true}
                        rowSelection={"multiple"}
                        suppressPivotMode={true}
                        suppressColumnMove={true}
                        suppressRowGroups={true}
                        sideBar={sideBar()}
                        groupMultiAutoColumn={true}
                        exportOptions={exportOptions}
                        statusBar={{
                          statusPanels: [
                            {
                              statusPanel:
                                "agTotalAndFilteredRowCountComponent",
                              align: "left",
                            },
                            {
                              statusPanel: "agTotalRowCountComponent",
                              align: "center",
                            },
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" },
                          ],
                        }}
                        gridOptions={gridOptions}
                        rowHeight={32.5}
                      />
                    </Stack>
                  </Stack>
                ) : (
                  <ReportsLoader />
                )}
              </Stack>
            </Stack>
          </Stack>
        }
      />
      {enableCustomizedReport && addCustomReport && (
        <CustomReportModal
          show={addCustomReport}
          hide={hideAddCustomReport}
          cols={allCols}
          name={reportActualType}
          heading={`Create Customized Report - ${reportName}`}
          colmap={colmap}
          hotelID={hotelId}
        ></CustomReportModal>
      )}
      {enablePdfExport && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="pdfExportWrapper">
              <PDFExportPanel
                gridApi={gridApi}
                columnApi={gridColumnApi}
                report_Type={reportName}
                startdate={
                  enableRangeCalendar
                    ? moment(datePicker[0].startDate).format("MMM DD YYYY")
                    : moment(date).format("MMM DD YYYY")
                }
                enddate={
                  enableRangeCalendar
                    ? moment(datePicker[0].endDate).format("MMM DD YYYY")
                    : moment(date).format("MMM DD YYYY")
                }
                numEnteries={rowsData ? rowsData?.length : 0}
                selectedNumEnteries={selectedNumEnteries}
                hotelLogo={data.hotelLogoUrl}
                docName={docName}
              />
            </div>
          </Box>
        </Modal>
      )}

      {folioReport && selectedRowsLength > 0 && (
        <Modal
          open={performBulkOperationModal}
          onClose={handleCloseBulkOperationModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="scrollbar">
            <Stack
              direction={"row"}
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                Perform bulk operations
              </Typography>
              <Typography
                sx={{ fontSize: "15px", fontWeight: "bold", cursor: "pointer" }}
                onClick={handleCloseBulkOperationModal}
              >
                X
              </Typography>
            </Stack>
            <Divider />
            <Stack spacing={2} sx={{ marginTop: "15px", padding: "10px" }}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Bulk operation type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={operationType}
                  onChange={(e) => {
                    setOperationType(e.target.value);
                    e.target.value === "NO SHOW"
                      ? setBookingNoShow(true)
                      : setBookingNoShow(false);
                  }}
                >
                  <FormControlLabel
                    value="VOID"
                    control={<Radio />}
                    label="Bulk void"
                  />
                  <FormControlLabel
                    value="CANCELLED"
                    control={<Radio />}
                    label="Bulk cancel"
                  />
                  <FormControlLabel
                    value="NO SHOW"
                    control={<Radio />}
                    label="Bulk no show"
                  />
                </RadioGroup>
              </FormControl>
              <Stack spacing={1}>
                <Typography>
                  Marking the following bookings as {operationType}
                </Typography>
                <Stack
                  direction="row"
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {selectedRows?.map((row) => (
                    <Typography
                      sx={{
                        borderRadius: "5px",
                        backgroundColor: "#f1f1ef",
                        color: "black",
                        padding: "2.5px",
                        fontSize: "12px",
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      {row?.bookingid_display}
                    </Typography>
                  ))}
                  {/* {operationType === "VOID" &&
                    selectedRows?.filter(
                      (booking) =>
                        !["CANCELLED", "NO_SHOW", "ON_HOLD"].includes(
                          booking?.status
                        )
                    ).length > 0 && (
                      <Typography sx={{ marginTop: "15px", color: "red" }}>
                        It seems as if few bookings have status other than the{" "}
                        <b>CANCELLED</b>, <b>NO SHOW</b> or <b>ON HOLD</b>.
                        Kindly select the relevant bookings before proceeding.
                      </Typography>
                    )} */}
                  {operationType === "NO SHOW" &&
                    selectedRows?.filter(
                      (booking) => !["CONFIRMED"].includes(booking?.status)
                    ).length > 0 && (
                      <Typography sx={{ marginTop: "15px", color: "red" }}>
                        Only <b>CONFIRMED</b> bookings can be marked as NO SHOW.
                        Kindly select the relevant bookings before proceeding.
                      </Typography>
                    )}
                </Stack>
              </Stack>
              <TextField
                select
                variant="outlined"
                label="Please select"
                value={voidOption}
                fullWidth
                name="options"
                onChange={(e) => {
                  setVoidOption(e.target.value);
                }}
              >
                {operationType !== "VOID" && (
                  <MenuItem key={"NO_VOID"} value={"NO_VOID"}>
                    Don't Void
                  </MenuItem>
                )}
                <MenuItem key={"CHARGE_ONE_NIGHT"} value={"CHARGE_ONE_NIGHT"}>
                  Charge one night and void remaining
                </MenuItem>
                <MenuItem key={"VOID"} value={"VOID"}>
                  Void all remaining charges
                </MenuItem>
              </TextField>
              <Stack
                direction={"row"}
                sx={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  disabled={bulkPerformOperationLoader}
                  onClick={handleCloseBulkOperationModal}
                >
                  Cancel
                </Button>
                <Button
                  variant={"custom-button"}
                  disabled={
                    bulkPerformOperationLoader ||
                    (operationType === "NO SHOW" &&
                      selectedRows?.filter(
                        (booking) => !["CONFIRMED"].includes(booking?.status)
                      ).length > 0)
                  }
                  onClick={() => {
                    operationType === "VOID"
                      ? handleBulkVoidBooking()
                      : handleBulkCancelBooking();
                  }}
                >
                  {bulkPerformOperationLoader ? (
                    <CircularProgress size={"15px"} />
                  ) : operationType === "VOID" ? (
                    "Confirm bulk void booking"
                  ) : operationType === "CANCELLED" ? (
                    "Confirm bulk cancel booking"
                  ) : (
                    "Confirm bulk no show booking"
                  )}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Modal>
      )}
      <Modal
        open={opens}
        onClose={() => setOpens(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            height: "42rem",
            width: "82rem",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Upgrade for new features</div>
              <div className="close" onClick={() => setOpens(false)}>
                X
              </div>
            </div>

            <div className="d-flex">
              <div className="amountWrapper">
                <h1 style={{ marginTop: "2rem" }}>
                  Unlock the potential of your data. Upgrade to advanced
                  reports.
                </h1>
                <h3 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                  Advances reports let’s you create custom reports, report
                  schedules and view reports for more than 6 months date range.
                </h3>

                <div className="d-flex justify-content-center">
                  <span
                    class="badge badge-warning"
                    style={{
                      fontWeight: "bold",
                      height: "4rem",
                      width: "30rem",
                      marginTop: "3rem",
                      fontSize: "20px",
                      lineHeight: "28px",
                      marginRight: "18px",
                    }}
                  >
                    {`Limited time offer, ${
                      hotelCurrency?.toUpperCase() === "INR"
                        ? "66.66%"
                        : "75.00%"
                    } discounted`}
                  </span>
                </div>

                <div
                  style={{
                    position: "absolute",
                    bottom: "2rem",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="custom-button"
                    style={{
                      position: "absolute",
                      bottom: "4rem",
                      width: "46%",
                      fontWeight: "bold",
                      height: "4rem",
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      if (data?.accessControl?.ADMINRIGHTS === "ADMIN") {
                        startTrial();
                      } else {
                        toast.warning(
                          "Please contact admin to  enable this feature"
                        );
                      }
                    }}
                    disabled={paymentLoader}
                  >
                    {paymentLoader ? (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    ) : hotelCurrency?.toUpperCase() === "INR" ? (
                      <div>
                        Upgrade for INR
                        <span style={{ textDecoration: "line-through" }}>
                          3000
                        </span>{" "}
                        999 / month
                      </div>
                    ) : (
                      <div>
                        Upgrade for{" "}
                        <span style={{ textDecoration: "line-through" }}>
                          $199
                        </span>{" "}
                        $49 / month
                      </div>
                    )}
                  </Button>
                  {String(whiteLabelled) === "DISABLED" && (
                    <p
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginTop: "2rem",
                        color: "#1BC5BD",
                      }}
                      onClick={() =>
                        window.open(
                          Object.entries(csmData).length > 0
                            ? csmData.account_csm_calendar_link
                            : "https://calendly.com/bharath-gowda/30min",
                          "_blank"
                        )
                      }
                    >
                      Talk to sales
                    </p>
                  )}
                </div>
              </div>

              <div>
                <img
                  style={{
                    height: "36rem",
                    width: "40rem",
                  }}
                  src={
                    "https://storage.googleapis.com/prod-images-bucket/misc/SF%20Mobile%20app%20New%20look%20(1).jpg"
                  }
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={confirmModal}
        onClose={() => setconfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            height: "25rem",
            width: "70rem",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Upgrade for new features</div>
              <div className="close" onClick={() => setconfirmModal(false)}>
                X
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              <Lottie options={successAnimation} height={150} width={150} />
              <h1>
                Successfully upgraded please logout and login to access this
                feature
              </h1>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(AgGridWrapper);
