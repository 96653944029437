import axios from "axios";

//axios call constants
const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const GETWEBNOTIFICATIONS = "core/api/v1/web-notif/get-web-notifications";
const SETNOTIFICATIONOPENED = "core/api/v1/web-notif/set-notification-opened";
const CONFIGDEVICE = "core/api/v1/web-notif/config-device";
const MARKALLASREAD = "core/api/v1/web-notif/mark-all-notif-read";
const GETCSMDETAILS = "core/api/v1/account/get-csm-details";
const GETBANNERNOTIF = "core/api/v1/web-notif/get-banner-notifications";

export const getBannerNotifications = async (hotelId) => {
  return axios
    .get(COMMON_URL + GETBANNERNOTIF, { params: { hotelId: hotelId } })
    .catch((err) => {
      console.error(err);
    });
};

export const getCsmDetails = async (groupId) => {
  return axios
    .get(COMMON_URL + GETCSMDETAILS, { params: { groupId: groupId } })
    .catch((err) => {
      console.error(err);
    });
};

export const getWebNotifications = async (hotelId) => {
  return axios
    .post(COMMON_URL + GETWEBNOTIFICATIONS, { hotelId: hotelId })
    .catch((err) => {
      console.error(err);
    });
};

export const setNotificationOpened = async (hotelId, notifId) => {
  return axios
    .get(COMMON_URL + SETNOTIFICATIONOPENED, {
      params: {
        hotelId: hotelId,
        notifId: notifId,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      console.error(err);
    });
};

export const configDevice = async (data) => {
  return axios
    .post(COMMON_URL + CONFIGDEVICE, data)
    .then((resp) => resp.data)
    .catch((err) => {
      console.error(err);
    });
};

export const markAllAsRead = async (data) => {
  return axios
    .post(COMMON_URL + MARKALLASREAD, data)
    .then((resp) => resp.data)
    .catch((err) => {
      console.error(err);
    });
};
