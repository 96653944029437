import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import getDocDefinition from "./docDefinition";
import getDocDefinitionHousekeeping from "./docDefinitionHousekeeping";
import docDefinitionShiftReport from "./docDefinitionShiftReport";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function printDoc(
  printParams,
  gridApi,
  columnApi,
  report_Type,
  startdate,
  enddate,
  enteries,
  housekeepingReport,
  finalizedAddress,
  docName,
  directBilling,
  shiftReport,
  shiftSummaryData
) {
  const docDefinition = housekeepingReport
    ? getDocDefinitionHousekeeping(
        printParams,
        gridApi,
        columnApi,
        report_Type,
        startdate,
        enddate,
        enteries
      )
    : shiftReport
    ? docDefinitionShiftReport(
        printParams,
        gridApi,
        columnApi,
        report_Type,
        startdate,
        enddate,
        enteries,
        shiftSummaryData
      )
    : getDocDefinition(
        printParams,
        gridApi,
        columnApi,
        report_Type,
        startdate,
        enddate,
        enteries,
        finalizedAddress,
        directBilling,
        shiftReport
      );
  const data = pdfMake?.createPdf(docDefinition)?.download(docName);
}

export default printDoc;
