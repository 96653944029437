import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import Webcam from "react-webcam";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { format } from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";
import { isNull, isUndefined } from "lodash";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import CardSwipe from "react-card-swipe";
import ImageUploading from "react-images-uploading";
import { connect } from "react-redux";
import { cardNumberCVVFormat, getCardType } from "../../../Utils/cardType";
import {
  getUserId,
  setBookingUserIdImages,
} from "../../BookingDashboard/apis/BrandingModalApi";
import {
  getcustomPGinfo,
  saveCardInfo,
} from "../../RevenueManagement/Promotions/apis/PromotionApis";
import {
  getFinalPrice,
  getPriceList,
  getGeoCodeDetails,
} from "../api/ReservationApi";
import {
  getCountriesAndStates,
  dateFnsFormat,
  getApprovedByOverseas,
  getIshaPurposeOfVisit,
  getIshaProgramsAttended,
  getNationalityList,
} from "../constants/Calendar-constants";
import Stack from "@mui/material/Stack";
import { getAllCustomers } from "../../FlexibleSlot/api/FlexibleSlotApi";
import { CheckCircle } from "@mui/icons-material";
import {
  checkPromoCode,
  checkPromoCodeQuickBook,
  getAllPromocodes,
  getAllPromocodesPriceList,
} from "../../Promocodes/apis/PromocodeApi";
import { getHotelAddons } from "../../HotelAddons/HotelAddonsAPI";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ReactImageUploading from "react-images-uploading";
import UploadIcon from "@mui/icons-material/Upload";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import NewGuestView from "./NewGuestView";
import { AiOutlineClose } from "react-icons/ai";
import { handleSpringDataRequest } from "../../../api";
const DetailsReview = (props) => {
  const {
    data,
    checkin,
    checkout,
    formik,
    currentSelection,
    showRooms,
    priceWithTax,
    taxNames,
    perDayList,
    hotelCurrency,
    datas,
    setUpdatedOtherGuestDetails,
    setUpdatedOtherGuestDocuments,
    setUpdatedOtherGuestIdProofs,
    setIshaDocuments,
    roomIds,
  } = props;

  const countries = getCountriesAndStates();
  const nationalities = getNationalityList();
  const ishaApprovedByOverseas = getApprovedByOverseas();
  const ishaPurposeOfVisit = getIshaPurposeOfVisit();
  const ishaProgramsAttended = getIshaProgramsAttended();
  const diffTime = Math.abs(checkout - checkin);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const hotelCountry = data?.accessControl.hotel.hotel_country;

  const [showExpiresin, setshowExpiresin] = useState(false);
  const [expirationTime, setexpirationTime] = useState("HOURS");
  const [ExpirationSource, setExpirationSource] = useState("AFTER");
  const [EnquiryExpiryTime, setEnquiryExpiryTime] = useState("");
  const [dayList, setDayList] = useState({});
  const [subtotal, setSubtotal] = useState({ sub: 0, tax: 0 });
  const [dicountType, setdicountType] = useState("percent");
  const [discountAmount, setdiscountAmount] = useState(0);
  const [showDiscountButton, setshowDiscountButton] = useState(true);
  const [discountApplied, setdiscountApplied] = useState("");

  // Add guest details right drawer
  const [selectedPrograms, setSelectedPrograms] = useState(["-"]);
  const handleProgramChange = (event) => {
    const {
      target: { value },
    } = event;
    let attendedPrograms = typeof value === "string" ? value.split(",") : value;

    setSelectedPrograms(attendedPrograms);
    formik.setFieldValue("programsAttended", attendedPrograms.toString());
  };

  useEffect(() => {
    getFinalData();
  }, []);

  const getFinalData = async () => {
    const arr = [];
    Array.from(currentSelection).forEach((roomId) => {
      let obj = Object.assign({}, priceWithTax[roomId]);
      obj.allPerDayPrices = perDayList[roomId].allPerDayPrices;
      arr.push(obj);
    });
    try {
      const data = await getPriceList(arr);
      const finaldata = await getFinalPrice(
        JSON.stringify(data),
        data.rateAndTax.rate + data.rateAndTax.tax,
        data.rateAndTax.rate + data.rateAndTax.tax
      );
      if (
        JSON.parse(
          props?.data?.accessControl?.user_feature_map?.EnquiryDashboard
        ).read === "YES"
      ) {
        let subTotalTax = 0;
        let subtotalAmount = 0;

        const dataaaa = arr.map((e) => {
          subTotalTax = subTotalTax + e.taxes;
          subtotalAmount = subtotalAmount + e.subtotal;
        });
        setSubtotal({
          sub: subtotalAmount,
          tax: subTotalTax,
        });
        formik.setFieldValue(
          "totalPrice",
          (subtotalAmount + subTotalTax).toFixed(2)
        );
      } else {
        setDayList(data);
        setSubtotal({
          sub: finaldata.rateAndTax.rate,
          tax: finaldata.rateAndTax.tax,
        });
        formik.setFieldValue(
          "totalPrice",
          (finaldata.rateAndTax.rate + finaldata.rateAndTax.tax).toFixed(2)
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  const finalPriceChange = async (val) => {
    const finaldata = await getFinalPrice(
      JSON.stringify(dayList),
      dayList.rateAndTax.rate + dayList.rateAndTax.tax,
      val
    );
    setSubtotal({
      sub: finaldata.rateAndTax.rate,
      tax: finaldata.rateAndTax.tax,
    });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const toggleShowExpiresin = (e) => {
    if (e.target.checked === true) {
      formik.setFieldValue("is_enquiry", true);
      setshowExpiresin(true);
      localStorage.setItem("IsEnquiry", true);
    } else {
      formik.setFieldValue("is_enquiry", false);
      localStorage.setItem("IsEnquiry", false);
      setshowExpiresin(false);
    }
  };
  const taxIdChanged = (e) => {
    formik.setFieldValue("taxId", e.target.value);
  };

  //Scan Images
  const [Scannerloader, setScannerloader] = useState(false);
  const [imageFront, setImageFront] = useState();
  const [imageBack, setImageBack] = useState();
  const [ScannerData, setScannerData] = useState();

  const getScannerUrl = () => {
    if (`${process.env.REACT_APP_ENV}` === "BETA") {
      return "https://text.motelpro.com/ScanDocDemo?images=front+back";
    } else if (`${process.env.REACT_APP_ENV}` === "PROD") {
      return "http://127.0.0.1:55080/scandoc?images=front+back";
    } else {
      return "http://127.0.0.1:55080/scandoc?images=front+back";
    }
  };

  const ScannedData = async () => {
    const URL = getScannerUrl();
    setScannerloader(true);
    const ApiData = await fetch(URL)
      .then((datas) => {
        return datas.json();
      })
      .catch((err) => {
        throw new Error(err);
      });
    setScannerloader(false);
    setScannerData(ApiData?.apiresponse);
    setImageFront(ApiData?.apiresponse?.imgfront);
    setImageBack(ApiData?.apiresponse?.imgback);

    if (ApiData?.apiresponse?.errormsg?.cd === 0) {
      // formik.setFieldValue(
      //   "customer_name",
      //   ApiData?.apiresponse?.docinfo?.fullname
      // );
      formik.setFieldValue("state", ApiData?.apiresponse?.docinfo?.state);
      formik.setFieldValue("zipcode", ApiData?.apiresponse?.docinfo?.zipcode);
      formik.setFieldValue("city", ApiData?.apiresponse?.docinfo?.city);
      formik.setFieldValue("address1", ApiData?.apiresponse?.docinfo?.addr1);
      formik.setFieldValue("address2", ApiData?.apiresponse?.docinfo?.addr2);
      formik.setFieldValue("country", "United States");
      formik.setFieldValue("govt_id_type", "Drivers License");
      formik.setFieldValue(
        "govt_id_value",
        ApiData?.apiresponse?.docinfo?.idno
      );

      let customer_name = "";
      if (ApiData?.apiresponse?.docinfo?.fullname) {
        customer_name = ApiData?.apiresponse?.docinfo?.fullname;
      } else {
        customer_name =
          ApiData?.apiresponse?.docinfo?.fname +
          ApiData?.apiresponse?.docinfo?.lname;
      }
      formik.setFieldValue("customer_name", customer_name);
      let dummy_email =
        customer_name.replace(/\s+/g, ".").toLowerCase() +
        data?.hotelId.toString() +
        Math.round(Date.now() / 1000) +
        "@example.com";
      formik.setFieldValue("customer_email", dummy_email);

      const userIdPayload = {
        emailId: dummy_email,
        frontData: ApiData?.apiresponse?.imgfront,
        frontType: "png",
        backData: ApiData?.apiresponse?.imgback,
        backType: "png",
        hotelId: data?.hotelId.toString(),
      };

      const rsp = await setBookingUserIdImages(userIdPayload);
      if (rsp === "Operation Successful") {
        toast.success("Successfully uploaded id");
      } else {
        toast.error("Something went wrong");
      }
      await getUserId(formik.values.customer_email);
    } else {
      toast.error(ApiData?.apiresponse?.errormsg?.txt);
    }
  };
  const [idloader, setidloader] = useState(false);
  const [cardloader, setcardloader] = useState(false);

  const uploadId = async () => {
    let dummy_email =
      formik.values.customer_email === ""
        ? formik.values.customer_name.replace(/\s+/g, ".").toLowerCase() +
          data?.hotelId +
          Math.round(Date.now() / 1000) +
          "@example.com"
        : formik.values.customer_email;
    const userIdPayload = {
      emailId: dummy_email,
      frontData:
        image !== undefined ? image.split(",")[1] : logoImage && logoImage[1],
      frontType: image !== undefined ? "jpeg" : logoImgType && logoImgType[0],
      backData:
        backImage !== undefined
          ? backImage.split(",")[1]
          : faviconImage && faviconImage[1],
      backType:
        backImage !== undefined ? "jpeg" : faivconImgType && faivconImgType[0],
      passportImageData:
        passportImage != undefined
          ? passportImage.split(",")[1]
          : passportImages[0]
          ? passportImages[0].data_url?.split(",")[1]
          : null,
      passportImageType:
        passportImage != undefined
          ? "jpeg"
          : passportImages[0]
          ? passportImages[0].file.type
          : null,
      hotelId: data?.hotelId,
    };
    formik.setFieldValue("customer_email", dummy_email);
    if (
      userIdPayload?.frontData !== undefined ||
      userIdPayload?.backData !== undefined
    ) {
      setidloader(true);
      const data = await setBookingUserIdImages(userIdPayload);
      setidloader(false);
      if (data === "Operation Successful") {
        toast.success("Successfully uploaded id");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  //payment details

  //custome pg info
  useEffect(async () => {
    await getAllData();
  }, []);

  const [customPgInfo, setcustomPgInfo] = useState("");
  const getAllData = async () => {
    try {
      const data = await getcustomPGinfo();
      setcustomPgInfo(data?.active_pg_name);
    } catch (e) {
      console.error(e);
    }
  };
  const paymentChargeAccess =
    data?.accessControl?.hotel.accesscontrol.user_access_map
      ?.payment_charge_access;
  const SaveCardDetails = async () => {
    var emailId =
      formik.values.customer_email === ""
        ? formik.values.customer_name.replace(/\s+/g, ".").toLowerCase() +
          data?.hotelId +
          Math.round(Date.now() / 1000) +
          "@example.com"
        : formik.values.customer_email;
    const paymentPayload = {
      card_first_name: addCardFirstName,
      card_last_name: addCardLastName,
      card_number: addCardNumber?.replace(/\s/g, "")?.slice(0, 16),
      card_exp_date: addCardExpiry,
      card_cvv: addCardCVV,
      email: emailId,
      hotelId: data?.hotelId,
    };
    formik.setFieldValue("customer_email", emailId);
    if (
      addCardFirstName?.length > 0 &&
      !/^[0-9]*$/.test(addCardFirstName) &&
      addCardLastName?.length > 0 &&
      !/^[0-9]*$/.test(addCardLastName) &&
      checkExpiry() &&
      checkLuhn() &&
      addCardNumber.length > 12 &&
      addCardCVV?.length <= 4
    ) {
      if (paymentChargeAccess === "YES") {
        if (
          customPgInfo === "FORTIS" ||
          customPgInfo === "TSYS" ||
          customPgInfo === "SHIFT4"
        ) {
          setcardloader(true);
          const data = await saveCardInfo(paymentPayload);
          setcardloader(false);
          if (data.message === "Successfully saved card information") {
            toast.success("Successfully saved card information");
          } else {
            toast.error("Something went wrong");
          }
        }
      }
    }

    // if (
    //   addCardCVV?.length > 4 ||
    //   addCardCVV?.length <= 2 ||
    //   addCardCVV === undefined
    // ) {
    //   error["cvv"] = true;
    // }

    if (addCardFirstName?.length === 0) {
      error["firstName"] = true;
    }

    if (addCardLastName?.length === 0) {
      error["lastName"] = true;
    }

    if (!checkLuhn() || addCardNumber.length < 12) {
      error["number"] = true;
    }

    if (!checkExpiry()) {
      error["expiry"] = true;
    }

    setError({ ...error });
  };

  const [addCardFirstName, setAddCardFirstName] = useState("");
  const [addCardLastName, setAddCardLastName] = useState("");
  const [addCardNumber, setAddCardNumber] = useState("");
  const [addCardType, setAddCardType] = useState();
  const [addCardCVV, setAddCardCVV] = useState("");
  const [addCardExpiry, setAddCardExpiry] = useState("");
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    number: false,
    cvv: false,
    expiry: false,
  });

  useEffect(() => {
    setAddCardNumber(addCardNumber?.replace(/\s/g, ""));
    if (addCardNumber?.length > 1) {
      const type = getCardType(addCardNumber?.slice(0, 16))?.type;
      setAddCardType(type);
    }
  }, [addCardNumber]);

  const [addGuestState, setAddGuestState] = useState([]);
  const [totalAdults, setTotalAdults] = useState(0);
  const [roomMap, setRoomMap] = useState([]);
  // Updation for the drawer open for each guest detail modal
  useEffect(() => {
    //Clear all data in case there is a change in rooms
    //setOtherGuestDetails([]);
    //setOtherGuestDocuments([]);
    // setOtherGuestIdProofs([]);
    let tempArr = [];
    let tempTotalAdults = 0;
    let roomMapping = [];
    if (datas?.length > 0) {
      datas.map((roomDetail) => {
        Object.keys(roomDetail.selectionData)
          .slice(0, roomDetail.roomCount)
          .map((selectionCount) => {
            tempTotalAdults += Number(
              roomDetail.selectionData[Number(selectionCount)].A
            );
            roomMapping.push({
              adults: roomDetail.selectionData[Number(selectionCount)].A,
              children: roomDetail.selectionData[Number(selectionCount)].C,
              roomTypeName: roomDetail.original.roomTypeName,
              ratePlanName: roomDetail.ratePlan.ratePlanName,
              roomTypeId: roomDetail.roomTypeId,
              ratePlanId: roomDetail.ratePlan.ratePlanId,
            });
          });
      });
    }
    setRoomMap(roomMapping);
    setTotalAdults(tempTotalAdults);
    if (tempTotalAdults > 1) {
      let tempData = [];
      let tempOtherData = [];
      roomMapping.map((room) => {
        let totalInsertion = Number(room?.adults) - 1;
        let temp_arr = [];
        tempOtherData.push([]);
        while (totalInsertion > 0) {
          temp_arr.push({ right: false });
          totalInsertion = totalInsertion - 1;
        }
        tempData.push(temp_arr);
      });

      setAddGuestState(tempData);

      setOtherGuestDetails(tempOtherData);
    }
    // if (tempTotalAdults > 1) {
    //   let totalInsertion = tempTotalAdults - 1;
    //   while (totalInsertion > 0) {
    //     tempArr.push({ right: false });
    //     totalInsertion = totalInsertion - 1;
    //   }
    //   setAddGuestState(tempArr);
    // }
  }, [datas]);

  useEffect(() => {
    // console.log("addguestState: ", addGuestState);
    // console.log("total adults: ", totalAdults);
    console.log("");
  }, [addGuestState, totalAdults]);

  //Add other guest details modal
  const [otherGuestDetails, setOtherGuestDetails] = useState([]);

  //Passing the drawer New Guest Information to formik
  const addGuestData = (data, roomInd) => {
    let tempArr = otherGuestDetails[roomInd];
    tempArr.push(data);
    const updatedArr = otherGuestDetails.map((guestArr, ind) => {
      if (roomInd === ind) {
        const updatedState = tempArr;
        return updatedState;
      }
      return guestArr;
    });
    setOtherGuestDetails(updatedArr);
    setUpdatedOtherGuestDetails(updatedArr);
  };

  //Updation already existing guestData
  const updateGuestData = (data, roomInd, index) => {
    // Updating guest state
    const updatedArr = otherGuestDetails.map((guestArr, ind) => {
      if (roomInd === ind) {
        const updatedState = guestArr.map((guestData, guestInd) => {
          if (guestInd === index) {
            const updatedGuestState = data;
            return data;
          }
          return guestData;
        });
        return updatedState;
      } else {
        return guestArr;
      }
    });
    setOtherGuestDetails(updatedArr);
    setUpdatedOtherGuestDetails(updatedArr);
  };

  useEffect(() => {
    console.log("");
    // // console.log(otherGuestDetails);
    //setUpdatedOtherGuests(otherGuestDetails);
  }, [otherGuestDetails]);
  //TOGGLE add guest details drawer
  const toggleAddGuestDrawer =
    (anchor, open, roomInd, index) => async (event) => {
      if (
        event?.type === "keydown" &&
        (event?.key === "Tab" || event?.key === "Shift")
      ) {
        return;
      }
      // Updating add guest state
      const updatedArr = addGuestState.map((anchorList, ind) => {
        if (roomInd == ind) {
          const updatedRoomState = anchorList.map((anchorData, guestInd) => {
            if (index == guestInd) {
              const updatedState = { ...anchorData, [anchor]: open };
              return updatedState;
            }
            return anchorData;
          });
          return updatedRoomState;
        } else {
          return anchorList;
        }
      });
      setAddGuestState(updatedArr);
    };

  // STORING CAMERA/DEVICE UPLOAD OF OTHER GUESTS
  const [otherGuestIdProofs, setOtherGuestIdProofs] = useState([]);

  const addGuestIdProof = (idProof) => {
    let tempArr = [...otherGuestIdProofs];
    tempArr.push(idProof);
    setOtherGuestIdProofs(tempArr);
    setUpdatedOtherGuestIdProofs(tempArr);
  };

  const updateGuestIdProof = (idProof, email) => {
    const updatedArr = otherGuestIdProofs.map((idData, index) => {
      if (idData?.emailId === email) {
        const updatedState = idProof;
        return updatedState;
      } else {
        return idData;
      }
    });
    setOtherGuestIdProofs(updatedArr);
    setUpdatedOtherGuestIdProofs(updatedArr);
  };

  useEffect(() => {
    console.log("guest details: ", otherGuestDetails);
    console.log("guest documents: ", otherGuestDocuments);
    console.log("guest id proofs: ", otherGuestIdProofs);
  }, [otherGuestDetails, otherGuestDocuments, otherGuestIdProofs]);

  //Guest documents
  const [otherGuestDocuments, setOtherGuestDocuments] = useState([]);
  const addOtherGuestDocuments = (documents) => {
    let tempArr = [...otherGuestDocuments];
    tempArr.push(documents);
    setOtherGuestDocuments(tempArr);
    setUpdatedOtherGuestDocuments(tempArr);
  };
  const updateOtherGuestDocuments = (documents, email) => {
    const updatedArr = otherGuestDocuments.map((docData, index) => {
      if (docData?.emailId === email) {
        const updatedState = documents;
        return updatedState;
      } else {
        return docData;
      }
    });
    setOtherGuestDocuments(updatedArr);
    setUpdatedOtherGuestDocuments(updatedArr);
  };

  // Add guest detail
  const [globalAdults, setGlobalAdults] = useState(0);
  const addGuestDetail = (anchor, roomInd, index) => {
    const defaultAddressDetail = {
      address1: formik.values.address1,
      address2: formik.values.address2,
      zipcode: formik.values.zipcode,
      city: formik.values.city,
      state: formik.values.state,
      country: formik.values.country,
    };

    return (
      <Box
        sx={{ width: "100%" }}
        // role="presentation"
      >
        <div className="folioUpdateWrapper">
          <div className="folioInformationActionWrapper">
            <div className="folioInformation">
              <div className="fId">
                Room {roomInd + 1} add guest {index + 2} details
              </div>
            </div>
            <div
              className="closeToggleDrawer"
              onClick={toggleAddGuestDrawer("right", false, roomInd, index)}
            >
              <AiOutlineClose size="20px" color="black" />
            </div>
          </div>
          <Divider />

          {otherGuestDetails?.length > roomInd &&
          otherGuestDetails[roomInd]?.length > index ? (
            <NewGuestView
              onClose={toggleAddGuestDrawer(anchor, false, roomInd, index)}
              addGuestFunc={addGuestData}
              updateGuestFunc={updateGuestData}
              existingData={otherGuestDetails}
              roomIndex={roomInd}
              dataIndex={index}
              email={otherGuestDetails[roomInd][index]?.guestEmail}
              primaryAddress={defaultAddressDetail}
              existingIdProofData={otherGuestIdProofs}
              addIdProof={addGuestIdProof}
              updateIdProof={updateGuestIdProof}
              existingDocumentsData={otherGuestDocuments}
              addDocumentData={addOtherGuestDocuments}
              updateDocumentData={updateOtherGuestDocuments}
              checkin={checkin}
              roomDetail={roomMap[roomInd]}
              checkout={checkout}
            />
          ) : (
            <NewGuestView
              onClose={toggleAddGuestDrawer(anchor, false, roomInd, index)}
              addGuestFunc={addGuestData}
              updateGuestFunc={updateGuestData}
              existingData={otherGuestDetails}
              roomIndex={roomInd}
              dataIndex={index}
              primaryAddress={defaultAddressDetail}
              existingIdProofData={otherGuestIdProofs}
              addIdProof={addGuestIdProof}
              updateIdProof={updateGuestIdProof}
              existingDocumentsData={otherGuestDocuments}
              addDocumentData={addOtherGuestDocuments}
              updateDocumentData={updateOtherGuestDocuments}
              roomDetail={roomMap[roomInd]}
              checkin={checkin}
              checkout={checkout}
            />
          )}
        </div>
      </Box>
    );
  };

  const checkExpiry = () => {
    const currentYear = new Date()?.getFullYear();
    const currentMonth = new Date()?.getMonth();
    const minExpiry =
      (currentMonth > 8 ? `${currentMonth + 1}` : `0${currentMonth + 1}`) +
      `${currentYear}`.slice(2, 4);
    const expiryValidity =
      addCardExpiry?.length === 4 &&
      (addCardExpiry?.slice(2, 4) > minExpiry?.slice(2, 4)
        ? true
        : addCardExpiry?.slice(2, 4) === minExpiry?.slice(2, 4)
        ? addCardExpiry?.slice(0, 2) >= minExpiry?.slice(0, 2)
        : false);
    return expiryValidity;
  };

  useEffect(() => {
    setError({
      firstName: false,
      lastName: false,
      number: false,
      cvv: false,
      expiry: false,
    });
  }, [
    addCardNumber,
    addCardExpiry,
    addCardFirstName,
    addCardLastName,
    addCardCVV,
  ]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  const checkLuhn = () => {
    let whitelist = [
      "4111111111111111",
      "4012888888881881",
      "4005562231212149",
      "5454545454545454",
      "5405222222222226",
      "6011000995500000",
      "371449635398431",
    ];
    if (whitelist.includes(addCardNumber)) {
      return true;
    }
    let s = 0;
    let doubleDigit = false;
    for (let i = addCardNumber?.length - 1; i >= 0; i--) {
      let digit = +addCardNumber[i];
      if (doubleDigit) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      s += digit;
      doubleDigit = !doubleDigit;
    }
    let flag = s % 10 === 0;
    if (flag) {
      // console.log("Luhn check passed");
    }
    return flag;
  };

  const [counterss, setcounterss] = useState(0);

  const [priceAfterTax, setpriceAfterTaxes] = useState(
    formik.values.totalPrice
  );

  const [priceBeforetax, setpriceBeforetax] = useState(subtotal.sub);
  const [taxPrice, settaxPrice] = useState(subtotal.tax);

  useEffect(() => {
    if (
      formik.values.totalPrice &&
      counterss === 0 &&
      subtotal.sub &&
      subtotal.tax
    ) {
      setpriceAfterTaxes(formik.values.totalPrice);
      settaxPrice(subtotal.tax);
      setpriceBeforetax(subtotal.sub);
      setcounterss(1);
    }
  }, [formik.values.totalPrice, subtotal.sub, subtotal.tax]);

  //images

  const [images, setImages] = useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const [faviconImages, setFaviconImages] = useState([]);
  const [loading, setloading] = useState(false);

  const onChangeFavicon = (faviconImageList, addUpdateIndex) => {
    setFaviconImages(faviconImageList);
  };

  var logoImage = images[images.length - 1]?.data_url.split(",");
  var faviconImage =
    faviconImages[faviconImages.length - 1]?.data_url.split(",");
  var faivconformat =
    faviconImages[faviconImages.length - 1]?.data_url.split(",");
  var faivconimgFormat = faivconformat && faivconformat[0]?.split("/");
  var faivconImgType = faivconimgFormat && faivconimgFormat[1]?.split(";");

  var logoformat = images[images.length - 1]?.data_url.split(",");
  var logoimgFormat = logoformat && logoformat[0]?.split("/");
  var logoImgType = logoimgFormat && logoimgFormat[1]?.split(";");

  const [swipeData, setswipeData] = useState({});

  const handleSwipeSuccess = (swipeData) => {
    setswipeData(swipeData);
    // const data = JSON.stringify(swipeData);
    const expMonth = swipeData?.expMonth;
    const expYear = swipeData?.expYear;
    setAddCardFirstName(swipeData?.firstName);
    setAddCardLastName(swipeData?.lastName);
    setAddCardNumber(swipeData?.account);
    setAddCardExpiry(expMonth + expYear);
  };

  useEffect(() => {
    CardSwipe.init({
      success: handleSwipeSuccess,
      debug: false,
    });
  }, []);
  const inputProps = {
    step: 0.01,
  };

  const [customerNameData, setcustomerNameData] = useState([]);
  const [customerPhoneData, setcustomerPhoneData] = useState([]);
  const [customerEmailData, setcustomerEmailData] = useState([]);
  const [showData, setshowData] = useState(true);
  const [showPromoButton, setshowPromoButton] = useState(true);

  useEffect(async () => {
    if (formik.values.customer_name !== "") {
      const data = await getAllCustomers(formik.values.customer_name, "name");
      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerNameData(sortedArr);
    }
    if (formik.values.customer_name.length === 1) {
      setcustomerNameData([]);
    }
    if (formik.values.customer_name === "") {
      setshowData(true);
    }
  }, [formik.values.customer_name]);

  useEffect(async () => {
    if (formik.values.customer_email !== "") {
      const data = await getAllCustomers(formik.values.customer_email, "email");
      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerEmailData(sortedArr);
    }
    if (formik.values.customer_email.length === 1) {
      setcustomerEmailData([]);
    }
    if (formik.values.customer_email === "") {
      setshowData(true);
    }
  }, [formik.values.customer_email]);

  useEffect(async () => {
    if (formik.values.customer_phone !== "") {
      const data = await getAllCustomers(
        formik.values.customer_phone,
        "contact"
      );

      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      setcustomerPhoneData(sortedArr);
    }
    if (formik.values.customer_phone.length === 1) {
      setcustomerPhoneData([]);
    }
    if (formik.values.customer_phone === "") {
      setshowData(true);
    }
  }, [formik.values.customer_phone]);

  const populateCustomerFields = (item) => {
    formik.setFieldValue("customer_name", item.name);
    formik.setFieldValue("customer_phone", item.contact);
    formik.setFieldValue("customer_email", item.email);
    //Gender
    if (item?.gender != null && item?.gender != "NA") {
      formik.setFieldValue("gender", item?.gender);
    }
    //DOB
    if (item?.dateOfBirth && item?.dateOfBirth != "NA") {
      formik.setFieldValue("customer_dob", item?.dateOfBirth);
    }
    //govtIdType
    if (item?.govtIdType) {
      formik.setFieldValue("govtIdType".item?.govtIdType);
    }
    //govtIdValue
    if (item?.govtIdValue) {
      formik.setFieldValue("govtIdValue", item?.govtIdValue);
    }
    //addressLine1
    if (item?.addressLine1) {
      formik.setFieldValue("address1", item?.addressLine1);
    }
    //addressLine2
    if (item?.addressLine2) {
      formik.setFieldValue("address2", item?.addressLine2);
    }
    //city
    if (item?.city && item?.city != "NA") {
      formik.setFieldValue("city", item?.city);
    }
    //state
    if (item?.state && item?.state != "NA") {
      formik.setFieldValue("state", item?.state);
    }
    //Country
    if (item?.country && item?.country != "NA") {
      formik.setFieldValue("country", item?.country);
    }
    //Zipcode
    if (item?.zipcode && item?.zipcode != "NA") {
      formik.setFieldValue("zipcode", item?.zipcode);
    }
    //nationality
    if (item?.nationality) {
      formik.setFieldValue("nationality", item?.nationality);
    }
    //occupation
    if (item?.occupation) {
      formik.setFieldValue("occupation", item?.occupation);
    }
    //company name
    if (item?.companyName) {
      formik.setFieldValue("companyName", item?.companyName);
    }
    setcustomerEmailData([]);
    setcustomerNameData([]);
    setcustomerPhoneData([]);
    setshowData(false);
  };

  const emptyValues = () => {
    setcustomerNameData([]);
    setcustomerEmailData([]);
    setcustomerPhoneData([]);
  };

  const webcamRef = React.useRef(null);

  const [image, setImage] = useState(undefined);
  const [backImage, setBackImage] = useState(undefined);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  const backcapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setBackImage(imageSrc);
  }, [webcamRef]);

  const videoConstraints = {
    width: 400,
    height: 300,
    facingMode: "user",
  };

  const [imageUpload, setimageUpload] = useState("Device");

  const [showFrontIdModal, setshowFrontIdModal] = useState(false);

  const handleCloseFrontIdModal = () => setshowFrontIdModal(false);
  const handleOpenFrontIdModal = () => {
    setshowFrontIdModal(true);
  };

  const [showBackIdModal, setshowBackIdModal] = useState(false);

  const handleCloseBackIdModal = () => setshowBackIdModal(false);
  const handleOpenBackIdModal = () => {
    setshowBackIdModal(true);
  };

  const getGeoCodeValues = (zipcode) => {
    try {
      getGeoCodeDetails(zipcode).then((resp) => {
        const re = /^[0-9\b]+$/;
        //Cases where the city is not mentioned in response
        if (!re.test(resp.city)) {
          formik.setFieldValue("city", resp.city);
        } else {
          formik.setFieldValue("city", "");
        }
        formik.setFieldValue("state", resp.state);
        formik.setFieldValue("country", resp.country);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [code, setcode] = useState("secretcode");

  const [PromocodeData, setPromocodeData] = useState();
  const [promoValue, setpromoValue] = useState("");
  const [promoPricingList, setpromoPricingList] = useState("");
  const [PromoValidation, setPromoValidation] = useState("");
  const [secretCoupon, setsecretCoupon] = useState("");
  const [RoomsReuqested, setRoomsReuqested] = useState([]);

  useEffect(() => {
    getPromoAllData();
  }, []);

  useEffect(() => {
    datas && getPricingLists();
  }, [datas]);

  //Setting the date of birth selection in formik(
  const setDateOfBirthSelection = (dob, isTime) => {
    try {
      formik.setFieldValue("customer_dob", dob);
    } catch (e) {
      console.log(e);
    }
  };
  const govIdUS = ["Passport", "Drivers License", "Others"];

  // Government IDs --- Indian properties
  const govIdIndia = ["Passport", "Drivers License", "Aadhar", "Others"];

  //ETA, ETD, OCO Checkin
  const [ETA, setETA] = useState(new Date());
  const [ETD, setETD] = useState(new Date());

  const handleETAChange = (e) => {
    setETA(e);
    formik.setFieldValue("ETA", moment(ETA).format("YYYY-MM-DD HH:mm:ss"));
  };

  const handleETDChange = (e) => {
    setETD(e);
    formik.setFieldValue("ETD", moment(ETD).format("YYYY-MM-DD HH:mm:ss"));
  };

  useEffect(() => {
    setETA(new Date(checkin));
    setETD(new Date(checkout));
  }, [checkin, checkout]);

  const [showFrontImagePopup, setshowFrontImagePopup] = useState(false);
  const [showPopUpImages, setshowPopUpImages] = useState("");

  const handleshowFrontImagePopupClose = () => setshowFrontImagePopup(false);
  const handleFrontImagePopupShow = (data) => {
    setshowPopUpImages(data);
    setshowFrontImagePopup(true);
  };

  //Passport image - isha requirement
  const [passportImageUpload, setPassportImageUpload] = useState("Camera");
  const [showPassportModal, setShowPassportModal] = useState(false);
  const handleClosePassportModal = () => setShowPassportModal(false);
  const handleOpenPassportModal = () => setShowPassportModal(true);
  const [passportImage, setPassportImage] = useState(undefined);
  const passportCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPassportImage(imageSrc);
  }, [webcamRef]);
  const [passportImages, setPassportImages] = useState([]);

  const getPricingLists = async () => {
    let arr = [];
    let roomsRequested = [];
    if (datas?.length > 0) {
      for (let i = 0; i < datas?.length; i++) {
        const data = await getAllPromocodesPriceList(
          moment(checkin).format("DD-MM-YYYY HH:00:00"),
          moment(checkout).format("DD-MM-YYYY HH:00:00"),
          datas[i]?.roomTypeId,
          datas[i]?.ratePlan?.ratePlanId
        );
        roomsRequested.push({
          roomTypeId: datas[i]?.roomTypeId,
          ratePlanId: datas[i]?.ratePlan?.ratePlanId,
          adultChildList: [
            {
              numAdults: 1,
              numChildren: 0,
            },
          ],
          dayLevelPriceList: data,
        });
        arr.push(data);
      }
    }
    setRoomsReuqested(roomsRequested);
    setpromoPricingList(arr);
  };

  const applyingPromo = async () => {
    setPromoValidation("");
    let total = 0;
    const datssa = datas?.map((res) => {
      total += res?.roomCount;
    });

    const promoDatas = PromocodeData.filter((e) => e.promoCode === promoValue);
    const payload = {
      hotelId: data?.hotelId,
      promoCode: promoValue,
      checkin: moment(checkin).format("DD-MM-YYYY HH:00:00"),
      checkout: moment(checkout).format("DD-MM-YYYY HH:00:00"),
      numRooms: total,
      amountBeforeTax: subtotal.sub.toFixed(2),
      taxAmount: subtotal.tax.toFixed(2),
      percentage: promoDatas[0].discount,
      roomsRequested: RoomsReuqested,
    };
    const discount = Number(promoDatas[0].discount);

    const dataas = await checkPromoCodeQuickBook(payload);
    setPromoValidation(dataas);

    if (dataas.validPromo === true) {
      setshowPromoButton(false);
      setSubtotal({
        sub: dataas?.amountAfterDiscount,
        tax: dataas?.taxAfterDiscount,
      });

      formik.setFieldValue(
        "totalPrice",
        dataas?.amountAfterDiscount + dataas?.taxAfterDiscount
      );

      formik.setFieldValue("appliedPromocode", promoValue);
      formik.setFieldValue("promoAmount", dataas?.discountValue);
    }
  };

  const removePromo = () => {
    formik.setFieldValue("totalPrice", Number(priceAfterTax).toFixed(2));
    setshowPromoButton(true);
    setSubtotal({
      sub: priceBeforetax,
      tax: taxPrice,
    });
    formik.setFieldValue("promoAmount", 0);
    setPromoValidation("");
    setpromoValue("");
  };

  const getPromoAllData = async () => {
    try {
      const data = await getAllPromocodes();
      const mydata = JSON.parse(data?.message);
      const filterData = mydata?.filter((e) => e.activeStatus === "ACTIVE");
      setPromocodeData(filterData);
    } catch (e) {
      console.error(e);
    }
  };

  const [hotelAddons, sethotelAddons] = useState([]);
  const [selectedAddons, setselectedAddons] = useState([]);

  useEffect(async () => {
    const data = await getHotelAddons();
    const selectedAddons = data.filter(function (el) {
      return el.room_type_id.includes(datas[0]?.roomTypeId);
    });
    var todaysDate = moment(new Date()).unix();

    const arr = [];
    const datsasa = selectedAddons?.map((e, index) => {
      var startDateUnix = moment(
        selectedAddons[index].start_date,
        "DD-MM-YYYY HH:MM:SS"
      ).unix();
      var endDateUnix = moment(
        selectedAddons[index].end_date,
        "DD-MM-YYYY HH:MM:SS"
      ).unix();
      if (todaysDate >= startDateUnix && todaysDate <= endDateUnix) {
        arr.push(e);
      }
    });

    sethotelAddons(arr);
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setselectedAddons(typeof value === "string" ? value.split(",") : value);
  };

  const [selectedAddonsArray, setselectedAddonsArray] = useState([]);
  const [addonsProductArray, setaddonsProductArray] = useState([]);

  useEffect(() => {
    const arr = [];
    const selectedAddonDastas = [];
    if (selectedAddons.length > 0) {
      for (let i = 0; i < selectedAddons.length; i++) {
        const data = hotelAddons.filter((e) => e.id === selectedAddons[i]);
        arr.push(data[0]);
        const obj = {
          id: data[0].id,
          name: data[0]?.name,
          quantity: 1,
          priceWithoutTax: data[0].addon_value,
          taxPercentage: data[0].tax_percentage,
          priceWithTax:
            data[0].addon_value +
            data[0].addon_value * (data[0].tax_percentage / 100),
          description: data[0].description,
        };
        selectedAddonDastas.push(obj);
      }
    }
    setselectedAddonsArray(arr);
    setaddonsProductArray(selectedAddonDastas);
    formik.setFieldValue("addons", selectedAddonDastas);
  }, [selectedAddons]);

  const columns = [
    {
      dataField: "name",
      text: "Name",

      editable: false,
    },
    {
      dataField: "quantity",
      text: "Quantity",
    },
    {
      dataField: "priceWithoutTax",
      text: "Price without tax",
    },
    {
      dataField: "taxPercentage",
      text: "Tax percentage",

      editable: false,
    },
    {
      dataField: "priceWithTax",
      text: "Price with tax",
      sort: true,
      editable: false,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Price should be numeric",
          };
        }
        return true;
      },
    },
  ];

  //Add attachments
  const [document1, setDocument1] = useState("");
  const [document2, setDocument2] = useState("");
  const handleSetDocumentState = (name, docType, base64) => {
    switch (name) {
      case "document1":
        setDocument1({ data: base64, type: docType });
        break;
      case "document2":
        setDocument2({ data: base64, type: docType });
        break;
      default:
      // code block
    }
  };

  useEffect(() => {
    let document_payload = {
      emailId: formik.values.customer_email,
      hotelId: data?.hotelId,
    };
    if (document1?.data) {
      document_payload["attachmentOne"] = {
        base64Data: document1?.data,
        dataType: document1?.type,
      };
    }
    if (document2?.data) {
      document_payload["attachmentTwo"] = {
        base64Data: document2?.data,
        dataType: document2?.type,
      };
    }
    setIshaDocuments(document_payload);
  }, [document1, document2]);

  //Converting documents to base64
  const convertToBase64 = (eve) => {
    console.log(eve.target.files[0]);
    console.log("name of doc : ", eve.target.name);
    const file = eve.target.files[0];
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
      .then((data) => {
        let type = data.split("/")[1].split(";")[0];
        if (type === "pdf") {
          type = "application/pdf";
        } else {
          type = "image/" + type;
        }
        const base64 = data.split(",")[1];
        handleSetDocumentState(eve.target.name, type, base64);
      })
      .catch((err) => console.log("error caught : ", err));
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput1 = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);

  const applyDiscount = () => {
    const price = formik.values.totalPrice;
    handleSpringDataRequest(
      `core/api/v1/reservation/apply-discount?amountBeforeTax=${subtotal?.sub?.toFixed(
        2
      )}&discountType=${dicountType.toUpperCase()}&discount=${discountAmount}&taxAmount=${subtotal?.tax?.toFixed(
        2
      )}`
    )
      .then((res) => {
        formik.setFieldValue("promoAmount", price - Number(res?.message));
        formik.setFieldValue("totalPrice", res?.message.toFixed(2));

        finalPriceChange(res?.message);
      })
      .catch((err) => toast.error("Something went wrong"));

    setshowDiscountButton(false);
    formik.setFieldValue("appliedPromocode", "DISCOUNT");

    setdiscountApplied("true");
  };

  const removeDiscount = () => {
    formik.setFieldValue("totalPrice", Number(priceAfterTax).toFixed(2));
    finalPriceChange(Number(priceAfterTax).toFixed(2));
    setdiscountAmount("");
    setshowDiscountButton(true);
    setdiscountApplied("");
    formik.setFieldValue("promoAmount", 0);
    setPromoValidation("");
    setpromoValue("");
  };

  return (
    <Grid item sx={{ padding: 2 }} container spacing={2} xs={12}>
      <Grid container item xs={8} spacing={2}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader
              sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
              title="Primary Guest Details"
            ></CardHeader>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Stack position="relative">
                    <div>
                      <TextField
                        required
                        name="customer_name"
                        id="customer_name"
                        value={formik.values.customer_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="new-password"
                        error={
                          formik.touched.customer_name &&
                          Boolean(formik.errors.customer_name)
                        }
                        helperText={
                          formik.touched.customer_name &&
                          formik.errors.customer_name
                        }
                        fullWidth
                        label="Customer Name"
                      ></TextField>
                    </div>

                    <div
                      className="position-absolute"
                      style={{ top: "4.5rem", zIndex: "10000" }}
                    >
                      {customerNameData?.length > 0 && showData && (
                        <Card
                          style={{
                            width: "36rem",
                            height: "60%",
                            overflowY: "yes",
                            zIndex: "1000",
                          }}
                        >
                          <IconButton
                            style={{
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "1rem",
                            }}
                            color="primary"
                          >
                            <CancelIcon onClick={() => emptyValues()} />
                          </IconButton>
                        </Card>
                      )}
                      {customerNameData?.length > 0 &&
                        showData &&
                        customerNameData?.map((item, index) => (
                          <Card
                            style={{
                              width: "36rem",
                              height: "60%",
                              overflowY: "yes",
                              zIndex: "1000",
                            }}
                            onClick={() => populateCustomerFields(item)}
                          >
                            <CardContent style={{ padding: "3%" }}>
                              <div className="d-flex flex-column">
                                {/* <BsSearch className="mb-0 mr-2" /> */}
                                <div className="d-flex ">
                                  <h4 className="mb-0 mr-4">{item.name}</h4>
                                  {item.donotRent === "YES" && (
                                    <>
                                      <p className="badge badge-danger">
                                        Do not rent
                                      </p>
                                      {item?.severity === "LOW" && (
                                        <p className="badge badge-info ml-2">
                                          LOW
                                        </p>
                                      )}
                                      {item?.severity === "MEDIUM" && (
                                        <p className="badge badge-warning ml-2">
                                          MEDIUM
                                        </p>
                                      )}
                                      {item?.severity === "HIGH" && (
                                        <p className="badge badge-dark ml-2">
                                          HIGH
                                        </p>
                                      )}
                                      {item?.severity === "BLACKLIST" && (
                                        <p className="badge badge-danger ml-2">
                                          BLACKLIST
                                        </p>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="d-flex mb-0">
                                  <p className="mr-3">{item.email}</p>
                                  <p>{item.contact}</p>
                                </div>
                                <p className="mb-0">{item.notes}</p>
                              </div>
                            </CardContent>
                          </Card>
                        ))}
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack position="relative">
                    <div>
                      <TextField
                        name="customer_email"
                        id="customer_email"
                        fullWidth
                        autoComplete="new-password"
                        type="text"
                        placeholder="Email Address"
                        value={formik.values.customer_email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Enter Customer Email"
                      ></TextField>
                      <div
                        className="position-absolute"
                        style={{ top: "4.5rem", zIndex: "10000" }}
                      >
                        {customerEmailData?.length > 0 && showData && (
                          <Card
                            style={{
                              width: "36rem",
                              height: "60%",
                              overflowY: "yes",
                              zIndex: "1000",
                            }}
                          >
                            <IconButton
                              style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "1rem",
                              }}
                              color="primary"
                            >
                              <CancelIcon onClick={() => emptyValues()} />
                            </IconButton>
                          </Card>
                        )}
                        {customerEmailData?.length > 0 &&
                          showData &&
                          customerEmailData?.map((item, index) => (
                            <Card
                              style={{
                                width: "36rem",
                                height: "60%",
                                overflowY: "yes",
                                zIndex: "1000",
                              }}
                              onClick={() => populateCustomerFields(item)}
                            >
                              <CardContent style={{ padding: "3%" }}>
                                <div className="d-flex flex-column">
                                  {/* <BsSearch className="mb-0 mr-2" /> */}
                                  <div className="d-flex ">
                                    <h4 className="mb-0 mr-4">{item.name}</h4>
                                    {item.donotRent === "YES" && (
                                      <>
                                        <p className="badge badge-danger">
                                          Do not rent
                                        </p>
                                        {item?.severity === "LOW" && (
                                          <p className="badge badge-info ml-2">
                                            LOW
                                          </p>
                                        )}
                                        {item?.severity === "MEDIUM" && (
                                          <p className="badge badge-warning ml-2">
                                            MEDIUM
                                          </p>
                                        )}
                                        {item?.severity === "HIGH" && (
                                          <p className="badge badge-dark ml-2">
                                            HIGH
                                          </p>
                                        )}
                                        {item?.severity === "BLACKLIST" && (
                                          <p className="badge badge-danger ml-2">
                                            BLACKLIST
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="d-flex mb-0">
                                    <p className="mr-3">{item.email}</p>
                                    <p>{item.contact}</p>
                                  </div>
                                  <p className="mb-0">{item.notes}</p>
                                </div>
                              </CardContent>
                            </Card>
                          ))}
                      </div>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack position="relative">
                    <div>
                      <TextField
                        name="customer_phone"
                        id="customer_phone"
                        fullWidth
                        autoComplete="new-password"
                        placeholder="Phone Number"
                        onBlur={formik.handleBlur}
                        value={formik.values.customer_phone}
                        onChange={formik.handleChange}
                        label="Phone Number With Country Code "
                        error={
                          formik.touched.customer_phone &&
                          Boolean(formik.errors.customer_phone)
                        }
                        helperText={
                          formik.touched.customer_phone &&
                          formik.errors.customer_phone
                        }
                      ></TextField>
                      <div
                        className="position-absolute"
                        style={{ top: "4.5rem", zIndex: "10000" }}
                      >
                        {customerPhoneData?.length > 0 && showData && (
                          <Card
                            style={{
                              width: "36rem",
                              height: "60%",
                              overflowY: "yes",
                              zIndex: "1000",
                            }}
                          >
                            <IconButton
                              style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "1rem",
                              }}
                              color="primary"
                            >
                              <CancelIcon onClick={() => emptyValues()} />
                            </IconButton>
                          </Card>
                        )}
                        {customerPhoneData?.length > 0 &&
                          showData &&
                          customerPhoneData?.map((item, index) => (
                            <Card
                              style={{
                                width: "36rem",
                                height: "60%",
                                overflowY: "yes",
                                zIndex: "1000",
                              }}
                              onClick={() => populateCustomerFields(item)}
                            >
                              <CardContent style={{ padding: "3%" }}>
                                <div className="d-flex flex-column">
                                  {/* <BsSearch className="mb-0 mr-2" /> */}
                                  <div className="d-flex ">
                                    <h4 className="mb-0 mr-4">{item.name}</h4>
                                    {item.donotRent === "YES" && (
                                      <>
                                        <p className="badge badge-danger">
                                          Do not rent
                                        </p>
                                        {item?.severity === "LOW" && (
                                          <p className="badge badge-info ml-2">
                                            LOW
                                          </p>
                                        )}
                                        {item?.severity === "MEDIUM" && (
                                          <p className="badge badge-warning ml-2">
                                            MEDIUM
                                          </p>
                                        )}
                                        {item?.severity === "HIGH" && (
                                          <p className="badge badge-dark ml-2">
                                            HIGH
                                          </p>
                                        )}
                                        {item?.severity === "BLACKLIST" && (
                                          <p className="badge badge-danger ml-2">
                                            BLACKLIST
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="d-flex mb-0">
                                    <p className="mr-3">{item.email}</p>
                                    <p>{item.contact}</p>
                                  </div>
                                  <p className="mb-0">{item.notes}</p>
                                </div>
                              </CardContent>
                            </Card>
                          ))}
                      </div>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    className="position-relative"
                    name="customer_gender"
                    id="customer_gender"
                    fullWidth
                    placeholder="Gender"
                    value={formik.values.customer_gender}
                    onChange={(e) => {
                      formik.setFieldValue("customer_gender", e.target.value);
                    }}
                    label="Gender"
                    variant="outlined"
                  >
                    <MenuItem key={"Male"} value={"Male"}>
                      {"Male"}
                    </MenuItem>
                    <MenuItem key={"Female"} value={"Female"}>
                      {"Female"}
                    </MenuItem>
                    <MenuItem key={"Others"} value={"Others"}>
                      {"Others"}
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat={dateFnsFormat}
                      fullWidth
                      disableMaskedInput
                      disableCloseOnSelect={false}
                      disableFuture
                      mask="___ ___, ____"
                      id="customer_dob"
                      name="customer_dob"
                      value={formik.values.customer_dob}
                      label="Date of Birth"
                      onChange={setDateOfBirthSelection}
                      onYearChange={(value) => {
                        formik.setFieldValue("customer_dob", value);
                        setDateOfBirthSelection(value, null);
                      }}
                      InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                      onAccept={(value) => {
                        const newYear = new Date(
                          formik.values.customer_dob
                        ).getFullYear();
                        const year = new Date(value).getFullYear();
                        const sameYear = year == newYear;
                        setDateOfBirthSelection(
                          sameYear ? value : formik.values.customer_dob || value
                        );
                      }}
                      autoOk={true}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => {
                        params.error = Boolean(formik.errors.customer_dob);
                        return (
                          <TextField
                            helperText={formik.errors.customer_dob}
                            fullWidth
                            {...params}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  {JSON.parse(
                    props?.data?.accessControl?.user_feature_map
                      ?.IshaExtraGuestDetails
                  ).read === "YES" && formik.values.address1?.length < 12 ? (
                    <>
                      <TextField
                        error
                        fullWidth
                        id="address1"
                        name="address1"
                        value={formik.values.address1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Address Line"
                        helperText={"Address length is minimum 12 characters"}
                      />
                    </>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        id="address1"
                        name="address1"
                        value={formik.values.address1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Address Line"
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="address2"
                    name="address2"
                    value={formik.values.address2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    label="Address Line 1"
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="zipcode"
                    name="zipcode"
                    value={formik.values.zipcode}
                    onChange={(e) => {
                      const re = /^[0-9A-Z\b]+$/;
                      if (e.target.value == "" || re.test(e.target.value)) {
                        formik.handleChange(e);
                        if (
                          e.target.value.length >= 5 &&
                          e.target.value.length <= 10
                        ) {
                          //call the api function and get the city state and country vals
                          getGeoCodeValues(e.target.value);
                        }
                      }
                    }}
                    onBlur={formik.handleBlur}
                    fullWidth
                    label="Zipcode"
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    onChange={(eve, val) => {
                      if (val !== formik.values.country)
                        formik.setFieldValue("state", null);
                      formik.setFieldValue("country", val);
                    }}
                    options={Object.keys(countries)}
                    value={formik.values.country}
                    renderInput={(params) => {
                      params.inputProps.autoComplete = "new-password";
                      return <TextField {...params} label="Country" />;
                    }}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    renderInput={(params) => {
                      params.inputProps.autoComplete = "new-password";
                      return <TextField {...params} label="State" />;
                    }}
                    onChange={(eve, val) => {
                      formik.setFieldValue("state", val);
                    }}
                    options={
                      isNull(formik.values.country) ||
                      isUndefined(countries[formik.values.country])
                        ? []
                        : countries[formik.values.country]
                    }
                    value={formik.values.state}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="city"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="City"
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    renderInput={(params) => {
                      params.inputProps.autoComplete = "new-password";
                      return <TextField {...params} label="Nationality" />;
                    }}
                    onChange={(eve, val) => {
                      formik.setFieldValue("nationality", val);
                    }}
                    options={nationalities}
                    // options={
                    //   isNull(formik.values.nationality) ||
                    //   isUndefined(countries[formik.values.country])
                    //     ? []
                    //     : countries[formik.values.country]
                    // }
                    value={formik.values.nationality}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="occupation"
                    id="occupation"
                    type="text"
                    fullWidth
                    placeholder="occupation"
                    value={formik.values.occupation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Occupation"
                    variant="outlined"
                  />
                </Grid>
                {JSON.parse(
                  props?.data?.accessControl?.user_feature_map
                    ?.IshaExtraGuestDetails
                ).read === "YES" && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        name="companyName"
                        id="companyName"
                        fullWidth
                        type="text"
                        placeholder="Company Name"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Company Name"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name="approvedByOverseas"
                        id="approvedByOverseas"
                        select
                        className="position-relative"
                        fullWidth
                        type="text"
                        placeholder="Approved by overseas"
                        value={formik.values.approvedByOverseas}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Approved by overseas"
                        variant="outlined"
                      >
                        {ishaApprovedByOverseas?.length > 0 &&
                          ishaApprovedByOverseas.map((purpose, index) => {
                            return (
                              <MenuItem key={purpose} value={purpose}>
                                {purpose}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        select
                        name="purposeOfVisit"
                        id="purposeOfVisit"
                        fullWidth
                        placeholder="Purpose of visit"
                        value={formik.values.purposeOfVisit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Purpose of visit"
                        variant="outlined"
                      >
                        {ishaPurposeOfVisit?.length > 0 &&
                          ishaPurposeOfVisit.map((purpose, index) => {
                            return (
                              <MenuItem key={purpose} value={purpose}>
                                {purpose}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          Programs Attended
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          name="programsAttended"
                          id="programsAttended"
                          label="Programs attended"
                          value={selectedPrograms}
                          onChange={handleProgramChange}
                          required="true"
                          multiple
                          style={{ width: "19.5rem" }}
                          // MenuProps={MenuProps}
                          placeholder="Choose Programs Attended"
                        >
                          {ishaProgramsAttended?.length > 0 &&
                            ishaProgramsAttended.map((program, index) => {
                              return (
                                <MenuItem key={program} value={program}>
                                  {program}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name="emergencyContactName"
                        id="emergencyContactName"
                        fullWidth
                        type="text"
                        placeholder="Emergency Contact Name"
                        value={formik.values.emergencyContactName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Emergency Contact Name"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {formik.values.customer_phone != "" &&
                      formik.values.emergencyContact ==
                        formik.values.customer_phone ? (
                        <TextField
                          name="emergencyContact"
                          id="emergencyContact"
                          fullWidth
                          type="text"
                          placeholder="Emergency Contact"
                          value={formik.values.emergencyContact}
                          onChange={formik.handleChange}
                          error
                          helperText={"Enter different number"}
                          onBlur={formik.handleBlur}
                          label="Emergency Contact"
                          variant="outlined"
                        />
                      ) : (
                        <TextField
                          name="emergencyContact"
                          id="emergencyContact"
                          fullWidth
                          type="text"
                          placeholder="Emergency Contact"
                          value={formik.values.emergencyContact}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Emergency Contact"
                          variant="outlined"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Estimated time of arrival"
                          value={ETA}
                          onChange={handleETAChange}
                          style={{ width: "19.5rem" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ width: "19.5rem" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Estimated time of departure"
                          value={ETD}
                          onChange={handleETDChange}
                          style={{ width: "19.5rem" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ width: "19.5rem" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        select
                        className="position-relative"
                        name="ocoCheckIn"
                        id="ocoCheckIn"
                        fullWidth
                        placeholder="OCO checkin complete"
                        value={formik.values.ocoCheckIn}
                        onChange={(e) => {
                          formik.setFieldValue("ocoCheckIn", e.target.value);
                        }}
                        label="OCO checkin complete"
                        variant="outlined"
                      >
                        <MenuItem key={"Yes"} value={"Yes"}>
                          {"Yes"}
                        </MenuItem>
                        <MenuItem key={"No"} value={"No"}>
                          {"No"}
                        </MenuItem>
                      </TextField>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Special request"
                    id="special_requests"
                    name="special_requests"
                    value={formik.values.special_requests}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></TextField>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ marginBottom: "15px" }} />
                  <div className="d-flex flex-column">
                    <div className="d-flex  align-items-center">
                      <FormControl sx={{ m: 1, width: "250px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select code / discount
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select code / discount"
                          value={code}
                          onChange={(e) => setcode(e.target.value)}
                          required="true"
                          // MenuProps={MenuProps}
                          placeholder="Select rate plan"
                        >
                          <MenuItem value={"secretcode"}>
                            Secret coupon code
                          </MenuItem>
                          <MenuItem value={"promocode"}>Promocode</MenuItem>
                          <MenuItem value={"discount"}>Discount</MenuItem>
                        </Select>
                      </FormControl>
                      {code === "secretcode" && (
                        <div className="d-flex align-items-center">
                          <TextField
                            label="Enter secret coupon code"
                            value={promoValue}
                            onChange={(e) => setpromoValue(e.target.value)}
                            fullWidth
                          ></TextField>
                        </div>
                      )}

                      {code === "promocode" && (
                        <>
                          {PromocodeData?.length > 0 ? (
                            <FormControl sx={{ m: 1, width: "250px" }}>
                              <InputLabel id="demo-simple-select-label">
                                Select code
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select rates"
                                value={promoValue}
                                onChange={(e) => {
                                  setpromoValue(e.target.value);
                                }}
                                required="true"
                                // MenuProps={MenuProps}
                                placeholder="Select rate plan"
                              >
                                {PromocodeData.map((e) => (
                                  <MenuItem value={e.promoCode}>
                                    {e.promoCode}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <h4>No promocodes</h4>
                          )}
                        </>
                      )}
                      {code === "discount" && (
                        <div className="d-flex">
                          <FormControl style={{ width: "8rem" }}>
                            <InputLabel id="demo-simple-select-label">
                              Select discount type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Select discount type"
                              value={dicountType}
                              onChange={(e) => setdicountType(e.target.value)}
                              required="true"
                              // MenuProps={MenuProps}
                              placeholder="Select discount type"
                            >
                              <MenuItem value={"percent"}>Percent</MenuItem>
                              <MenuItem value={"flat"}>Flat</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            label="Enter discount value"
                            value={discountAmount}
                            style={{ width: "6rem", marginLeft: "1rem" }}
                            onChange={(e) => {
                              if (dicountType === "percent") {
                                if (
                                  e.target.value >= 0 &&
                                  e.target.value <= 100
                                )
                                  setdiscountAmount(e.target.value);
                              } else {
                                if (e.target.value >= 0)
                                  setdiscountAmount(e.target.value);
                              }
                            }}
                          ></TextField>
                        </div>
                      )}
                      {showPromoButton &&
                        code !== "discount" &&
                        PromocodeData?.length > 0 && (
                          <Button
                            style={{ marginLeft: "0.5rem", height: "3rem" }}
                            variant="custom-button"
                            onClick={() => applyingPromo()}
                          >
                            Apply
                          </Button>
                        )}
                      {showDiscountButton && code === "discount" && (
                        <Button
                          style={{ marginLeft: "0.5rem", height: "3rem" }}
                          variant="custom-button"
                          onClick={() => applyDiscount()}
                        >
                          Apply
                        </Button>
                      )}
                      {code !== "discount" && PromocodeData?.length > 0 && (
                        <Button
                          style={{ marginLeft: "0.5rem", height: "3rem" }}
                          variant="custom-button"
                          onClick={() => removePromo()}
                        >
                          Remove
                        </Button>
                      )}
                      {code === "discount" && (
                        <Button
                          style={{ marginLeft: "0.5rem", height: "3rem" }}
                          variant="custom-button"
                          onClick={() => removeDiscount()}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                    {PromoValidation?.validPromo === false && (
                      <p className="text-danger" style={{ marginLeft: "1rem" }}>
                        Enter a valid coupon code or offer
                      </p>
                    )}{" "}
                    {PromoValidation?.validPromo === true && (
                      <p
                        className="text-success"
                        style={{ marginLeft: "1rem" }}
                      >
                        Successfully applied coupon code or offer
                      </p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ marginBottom: "15px" }} />
                  <div className="d-flex  align-items-center">
                    <FormControl
                      sx={{ m: 1, width: "33rem", marginBottom: "1rem" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select addons
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select addons"
                        value={selectedAddons}
                        onChange={handleChange}
                        required="true"
                        multiple
                        style={{ width: "33rem" }}
                        // MenuProps={MenuProps}
                        placeholder="Select addons"
                      >
                        {hotelAddons.map((e) => (
                          <MenuItem value={e.id}>{e.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {selectedAddons?.length > 0 && (
                    <div>
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={addonsProductArray}
                        columns={columns}
                        // defaultSorted={defaultSorted}
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                          afterSaveCell: (oldValue, newValue, row, column) => {
                            row.priceWithTax =
                              parseInt(row.quantity) *
                              (parseFloat(row.priceWithoutTax) +
                                parseFloat(row.priceWithoutTax) *
                                  (parseFloat(row.taxPercentage) / 100));
                          },
                        })}
                      />
                    </div>
                  )}
                  <Divider style={{ marginTop: "15px" }} />
                </Grid>
                <div
                  className="d-flex align-items-center "
                  style={{ marginTop: "1rem", marginLeft: "1rem" }}
                >
                  <Checkbox
                    {...label}
                    onChange={(e) => toggleShowExpiresin(e)}
                  />
                  <h4 className="mr-3 mb-0">Enquiry</h4>
                </div>
                {showExpiresin && (
                  <div className="d-flex mt-4 align-items-center">
                    <h6 className="mb-0 mr-2 ml-3">Enquiry expires in</h6>
                    <TextField
                      style={{
                        width: "9rem",
                        marginRight: "1rem",
                      }}
                      name="expiry_time"
                      id="expiry_time"
                      label="Expiry time"
                      value={formik.values.expiry_time}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormControl
                      style={{
                        width: "10rem",
                        height: "2.5rem",
                        marginRight: "1rem",
                      }}
                    >
                      <InputLabel id="expiry_type">Expiry type</InputLabel>
                      <Select
                        labelId="expiry_type"
                        name="expiry_type"
                        id="expiry_type"
                        label="Expiry type"
                        value={formik.values.expiry_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="mr-4"
                        style={{ width: "10rem", height: "2.5rem" }}
                      >
                        <MenuItem value={"MINUTES"}>Minutes</MenuItem>
                        <MenuItem value={"HOURS"}>Hours</MenuItem>
                        <MenuItem value={"DAYS"}>Days</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl style={{ width: "10rem", height: "2.5rem" }}>
                      <InputLabel id="duration_type">Duration type</InputLabel>
                      <Select
                        labelId="duration_type"
                        name="duration_type"
                        id="duration_type"
                        label="Duration type"
                        value={formik.values.duration_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ width: "10rem", height: "2.5rem" }}
                      >
                        <MenuItem value={"BEFORE"}>Before checkin</MenuItem>
                        <MenuItem value={"AFTER"}>After booking</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {hotelCountry === "United States" && (
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                title="Card Details"
              ></CardHeader>
              <Divider />
              <CardContent>
                <div>
                  <div className="amountWrapper">
                    <div className="fieldWrapper d-flex justify-content-between">
                      <TextField
                        required
                        id="outlined-name"
                        type={"text"}
                        label="First name"
                        name="firstName"
                        value={addCardFirstName}
                        onChange={(e) =>
                          /^[a-zA-Z ]*$/.test(e.target.value) &&
                          setAddCardFirstName(e.target.value)
                        }
                        error={error.firstName}
                        helperText={
                          error.firstName
                            ? addCardFirstName?.length === 0
                              ? "Enter first name"
                              : "Enter correct name"
                            : ""
                        }
                        sx={{ width: "48%" }}
                      />
                      <TextField
                        required
                        id="outlined-name"
                        label="Last name"
                        name="lastName"
                        type={"text"}
                        value={addCardLastName}
                        onChange={(e) =>
                          /^[a-zA-Z ]*$/.test(e.target.value)
                            ? setAddCardLastName(e.target.value)
                            : setAddCardLastName(addCardLastName)
                        }
                        error={error.lastName}
                        helperText={
                          error.lastName
                            ? addCardLastName?.length === 0
                              ? "Enter last name"
                              : "Enter correct name"
                            : ""
                        }
                        sx={{ width: "48%" }}
                      />
                    </div>
                    <div className="fieldWrapper d-flex mt-4">
                      <TextField
                        required
                        id="outlined-name"
                        label="Card Number"
                        name="cardNumber"
                        type={"number"}
                        value={addCardNumber}
                        onChange={(e) =>
                          e.target.value.length <= 16 &&
                          setAddCardNumber(e.target.value)
                        }
                        error={
                          (!checkLuhn() && addCardNumber?.length > 0) ||
                          error.number
                            ? true
                            : false
                        }
                        helperText={
                          (!checkLuhn() && addCardNumber?.length > 0) ||
                          error.number
                            ? "invalid card number"
                            : ""
                        }
                        sx={{ width: "100%" }}
                      />
                      {getCardType(addCardNumber?.slice(0, 16)) && (
                        <img
                          style={{
                            width: "60px",
                            height: "40px",
                            marginTop: "5px",
                            marginLeft: "-80px",
                            objectFit: "contain",
                          }}
                          src={
                            getCardType(addCardNumber?.slice(0, 16))?.imageUrl
                          }
                          alt=" "
                        />
                      )}
                    </div>
                    <div className="fieldWrapper d-flex justify-content-between mt-4">
                      <TextField
                        // required
                        id="outlined-name"
                        label="Card CVV"
                        name="cardCVV"
                        type={"number"}
                        value={cardNumberCVVFormat(addCardCVV)}
                        onChange={(e) => setAddCardCVV(e.target.value)}
                        sx={{ width: "48%" }}
                        // error={addCardCVV?.length > 4 || error.cvv}
                        // helperText={
                        //   addCardCVV?.length > 4 || error.cvv ? "invalid cvv" : ""
                        // }
                      />
                      <TextField
                        required
                        id="outlined-name"
                        label="Card Expiry (MMYY)"
                        name="cardExpiry"
                        type={"number"}
                        value={addCardExpiry}
                        onChange={(e) =>
                          e.target.value?.length < 5 &&
                          setAddCardExpiry(e.target.value)
                        }
                        error={
                          (!checkExpiry() && addCardExpiry?.length > 0) ||
                          error.expiry
                        }
                        helperText={
                          (!checkExpiry() && addCardExpiry?.length > 0) ||
                          error.expiry
                            ? "invalid expiry"
                            : ""
                        }
                        sx={{ width: "48%" }}
                      />
                    </div>
                  </div>
                  <Button
                    variant="custom-button"
                    style={{
                      marginTop: "1rem",
                      marginLeft: "auto",
                      float: "right",
                      marginBottom: "1rem",
                    }}
                    onClick={SaveCardDetails}
                    disabled={cardloader}
                  >
                    {cardloader ? (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    ) : (
                      "   Save card"
                    )}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {/* COMMENTING UNTILL DESIGN IS CONFIRMED*/}
        {/* {totalAdults > 1 && (
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                title="Accompanying guest details"
              ></CardHeader>
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  {addGuestState?.length > 0 &&
                    roomMap?.length > 0 &&
                    roomMap.map((room, roomInd) => {
                      let roomAdults = room?.adults;
                      return (
                        <Grid item xs={12}>
                          {"Room " +
                            (roomInd + 1) +
                            " " +
                            room?.roomTypeName +
                            " " +
                            room?.ratePlanName}
                          {roomAdults > 1 &&
                            Array.from(
                              { length: roomAdults - 1 },
                              (_, index) => {
                                return (
                                  <>
                                    <Button
                                      variant="custom-button"
                                      className="ml-4"
                                      onClick={toggleAddGuestDrawer(
                                        "right",
                                        true,
                                        roomInd,
                                        index
                                      )}
                                    >
                                      Add Guest {index + 2} Details
                                    </Button>
                                    <Drawer
                                      anchor={"right"}
                                      open={
                                        addGuestState[roomInd][index]["right"]
                                      }
                                      onClose={toggleAddGuestDrawer(
                                        "right",
                                        false,
                                        roomInd,
                                        index
                                      )}
                                      PaperProps={{
                                        sx: { width: 700 },
                                      }}
                                    >
                                      {addGuestDetail("right", roomInd, index)}
                                    </Drawer>
                                  </>
                                );
                              }
                            )}
                        </Grid>
                      );
                    })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )} */}
        {JSON.parse(
          props?.data?.accessControl?.user_feature_map?.IshaExtraGuestDetails
        ).read === "YES" && (
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                title="Complimentary Request"
              ></CardHeader>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      name="requestorName"
                      id="requestorName"
                      fullWidth
                      type="text"
                      placeholder="Requestor name"
                      value={formik.values.requestorName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Requestor name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      name="requestorContact"
                      id="requestorContact"
                      type="text"
                      fullWidth
                      placeholder="Requestor contact"
                      value={formik.values.requestorContact}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Requestor contact"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      name="requestorDept"
                      id="requestorDept"
                      fullWidth
                      type="text"
                      placeholder="Requestor department"
                      value={formik.values.requestorDept}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Requestor department"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      name="requestorApprover"
                      id="requestorApprover"
                      type="text"
                      fullWidth
                      placeholder="Requestor approver"
                      value={formik.values.requestorApprover}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Requestor approver"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader
              sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
              title="Additional Details"
            ></CardHeader>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    label="Tax exempt ID"
                    value={formik.values.taxId}
                    onChange={taxIdChanged}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Autocomplete
                    fullWidth
                    disabled={
                      formik.values.taxId === "" || formik.values.taxId === null
                    }
                    renderInput={(params) => {
                      return <TextField {...params} label="Taxes To Exempt" />;
                    }}
                    disableClearable
                    onChange={(e, v) => {
                      if (v.length > 0)
                        formik.setFieldValue("selectedTaxes", v);
                    }}
                    multiple
                    options={isNull(taxNames) ? [] : taxNames}
                    value={
                      formik.values.taxId === ""
                        ? []
                        : isNull(taxNames)
                        ? []
                        : formik.values.selectedTaxes
                    }
                  ></Autocomplete>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Button
                    for="document1"
                    variant="custom-button"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      hiddenFileInput1.current.click();
                    }}
                  >
                    Attach file 1
                    <input
                      style={{
                        color: document1 !== "" ? "#20c997" : "black",
                        display: "none",
                      }}
                      id="document1"
                      type="file"
                      className="form-control"
                      accept=".pdf, .jpg, .jpeg, .png"
                      name="document1"
                      ref={hiddenFileInput1}
                      onChange={(eve) => {
                        let imageName =
                          document.getElementById("document1Name");
                        let inputImage = eve.target.files[0];
                        imageName.innerText = inputImage.name;
                        convertToBase64(eve);
                      }}
                    />
                    <br />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
                  <Button
                    for="document2"
                    variant="custom-button"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      hiddenFileInput2.current.click();
                    }}
                  >
                    Attach file 2
                    <input
                      style={{
                        color: document2 !== "" ? "#20c997" : "black",
                        display: "none",
                      }}
                      id="document2"
                      type="file"
                      className="form-control"
                      accept=".pdf, .jpg, .jpeg, .png"
                      name="document2"
                      ref={hiddenFileInput2}
                      onChange={(eve) => {
                        let imageName =
                          document.getElementById("document2Name");
                        let inputImage = eve.target.files[0];
                        imageName.innerText = inputImage.name;
                        convertToBase64(eve);
                      }}
                    />
                    <br />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
                  <span
                    id="document1Name"
                    style={{ color: "green", fontSize: "small" }}
                  ></span>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
                  <span
                    id="document2Name"
                    style={{ color: "green", fontSize: "small" }}
                  ></span>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader
              sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
              title="ID Details"
            ></CardHeader>
            <Divider />
            <CardContent>
              <Grid container item xs={12} spacing={2}>
                {JSON.parse(
                  props?.data?.accessControl?.user_feature_map
                    ?.IshaExtraGuestDetails
                ).read === "YES" && (
                  <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Upload Passport Size Photo
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={passportImageUpload}
                          onChange={(e) =>
                            setPassportImageUpload(e.target.value)
                          }
                          label="Passport Upload Image"
                        >
                          <MenuItem value={"Device"}>Device upload</MenuItem>
                          <MenuItem value={"Camera"}>Camera upload</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      {passportImageUpload === "Camera" && (
                        <div className="mt-2 d-flex">
                          <div className="d-flex flex-column mr-3">
                            {passportImage !== undefined && (
                              <img
                                src={`${passportImage}`}
                                style={{
                                  width: "21rem",
                                  heigth: "20rem",
                                  borderRadius: "1rem",
                                }}
                              />
                            )}
                            <Button
                              variant="custom-button"
                              className="mt-2 mr-4"
                              onClick={() => handleOpenPassportModal()}
                            >
                              Upload Passport Size Photo
                            </Button>
                          </div>
                        </div>
                      )}
                    </Grid>
                    {passportImageUpload === "Device" && (
                      <Grid item xs={12}>
                        <div className="d-flex">
                          <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                            <h5
                              className="mb-3"
                              style={{ marginRight: "13px" }}
                            >
                              Passport Size Photo
                            </h5>
                            <ReactImageUploading
                              value={passportImages}
                              onChange={(image) => {
                                setPassportImages(image);
                              }}
                              dataURLKey="data_url"
                            >
                              {({
                                imageList,
                                onImageUpload,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                              }) => (
                                // write your building UI
                                <Stack
                                  // p="2"
                                  // bgColor="gray.50"
                                  // borderRadius="lg"
                                  className="upload__image-wrapper"
                                  sx={{
                                    borderRadius: "10px",
                                    height: "150px",
                                    backgroundColor: "#d3d3d336",
                                    padding: "1rem",
                                  }}
                                >
                                  {imageList.length === 0 && (
                                    <IconButton
                                      children={
                                        <UploadIcon
                                          icon="bi:upload"
                                          htmlColor="black"
                                        />
                                      }
                                      variant="outline"
                                      // height="100%"
                                      sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#d3d3d3",
                                        height: "100%",
                                      }}
                                      onClick={onImageUpload}
                                      {...dragProps}
                                    ></IconButton>
                                  )}
                                  <Stack spacing="2">
                                    {imageList.map((image, index) => (
                                      <Stack
                                        sx={{
                                          position: "relative",
                                          padding: "0.5rem",
                                        }}
                                        key={index}
                                      >
                                        <img
                                          src={
                                            image["data_url"].includes("base64")
                                              ? image["data_url"]
                                              : image["data_url"] +
                                                "?lastmod=" +
                                                format(
                                                  new Date(),
                                                  "ddMMyyyyhhmmsstt"
                                                )
                                          }
                                          style={{
                                            height: "125px",
                                            width: "100%",
                                            borderRadius: "1rem",
                                            boxShadow: "md",
                                            border: "1px solid",
                                            borderColor: "gray.200",
                                          }}
                                          onClick={() =>
                                            handleFrontImagePopupShow("front")
                                          }
                                          alt=""
                                        />

                                        <IconButton
                                          children={
                                            <CloseIcon
                                              fontSize="xs"
                                              htmlColor="black"
                                            />
                                          }
                                          size="xs"
                                          sx={{
                                            position: "absolute",
                                            borderRadius: "100%",
                                            backgroundColor: "white",
                                            top: "0",
                                            // colorScheme="teal"
                                            marginTop: "0px !important",
                                            left: "0",
                                            "&:hover": {
                                              color: "black",
                                              backgroundColor: "#ffffffcc",
                                            },
                                          }}
                                          onClick={() => onImageRemove(index)}
                                        />
                                        <IconButton
                                          children={
                                            <ChangeCircleIcon
                                              fontSize="xs"
                                              htmlColor="black"
                                            />
                                          }
                                          size="xs"
                                          sx={{
                                            position: "absolute",
                                            borderRadius: "100%",
                                            top: "0",
                                            backgroundColor: "white",
                                            // colorScheme="teal"
                                            marginTop: "0px !important",
                                            right: "0",
                                            "&:hover": {
                                              color: "black",
                                              backgroundColor: "#ffffffcc",
                                            },
                                          }}
                                          onClick={() => onImageUpdate(index)}
                                        />
                                      </Stack>
                                    ))}
                                  </Stack>
                                </Stack>
                              )}
                            </ReactImageUploading>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </>
                )}
                <Grid item xs={4}>
                  <TextField
                    select
                    variant="outlined"
                    label="Govt ID"
                    value={formik.values.govtIdType}
                    fullWidth
                    placeholder="Select an ID type"
                    id="govtIdType"
                    name="govtIdType"
                    onChange={(e) => {
                      formik.setFieldValue("govtIdType", e.target.value);
                    }}
                  >
                    {hotelCurrency === "INR"
                      ? govIdIndia.map((item, index) => (
                          <MenuItem key={index + item} value={item}>
                            {item}
                          </MenuItem>
                        ))
                      : govIdUS.map((item, index) => (
                          <MenuItem key={index + item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="GOVT ID Number"
                    placeholder="GOVT ID Number"
                    id="govtIdValue"
                    name="govtIdValue"
                    value={formik.values.govtIdValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Upload Image
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={imageUpload}
                      onChange={(e) => setimageUpload(e.target.value)}
                      label="Upload Image"
                    >
                      <MenuItem value={"Device"}>Device upload</MenuItem>
                      <MenuItem value={"Camera"}>Camera upload</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div className="d-flex flex-column">
                <div className="d-flex align-item-center mb-3">
                  {hotelCurrency === "USD" &&
                    data?.accessControl?.hotel.enable_id_scanner ===
                      "AMBIRSCAN" && (
                      <Button
                        variant="custom-button"
                        style={{
                          marginTop: "10px",
                          marginLeft: "2rem",
                          marginRight: "1rem",
                          height: "32px",
                        }}
                        disabled={Scannerloader}
                        onClick={ScannedData}
                      >
                        {Scannerloader ? (
                          <CircularProgress
                            size={15}
                            color="inherit"
                            sx={{ ml: 1.5 }}
                          />
                        ) : (
                          "Scan id"
                        )}
                      </Button>
                    )}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center ">
                    {ScannerData?.docinfo?.fullname !== undefined && (
                      <div>
                        <img
                          className="mr-3 ml-3"
                          src={`data:image/png;base64,${imageFront}`}
                          style={{
                            borderRadius: "1rem",
                            width: "120px",
                            height: "70px",
                          }}
                        />
                        <img
                          className="mr-3"
                          src={`data:image/png;base64,${imageBack}`}
                          style={{
                            borderRadius: "1rem",
                            width: "120px",
                            height: "70px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {imageUpload === "Camera" && (
                    <div className="mt-4 d-flex">
                      <div className="d-flex flex-column mr-3">
                        {image !== undefined && (
                          <img
                            src={`${image}`}
                            style={{
                              width: "20rem",
                              heigth: "16rem",
                              borderRadius: "1rem",
                            }}
                          />
                        )}
                        <Button
                          variant="custom-button"
                          className="mr-4 mt-3"
                          onClick={() => handleOpenFrontIdModal()}
                        >
                          Upload front id
                        </Button>
                      </div>
                      <div className="d-flex flex-column">
                        {backImage !== undefined && (
                          <img
                            src={`${backImage}`}
                            style={{
                              width: "20rem",
                              heigth: "16rem",
                              borderRadius: "1rem",
                            }}
                          />
                        )}
                        <Button
                          variant="custom-button"
                          className="mt-3"
                          onClick={() => handleOpenBackIdModal()}
                        >
                          Upload Back id
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="custom-button"
                          style={{
                            // position: "absolute",
                            marginLeft: "12px",
                            marginTop: "10px",
                          }}
                          onClick={uploadId}
                          disabled={idloader}
                        >
                          {idloader ? (
                            <CircularProgress
                              size={15}
                              color="inherit"
                              sx={{ ml: 1.5 }}
                            />
                          ) : (
                            "Upload ID"
                          )}
                        </Button>
                      </div>
                    </div>
                  )}
                  {imageUpload === "Device" && (
                    <div className="d-flex">
                      <div className="d-flex align-items-center mr-4">
                        <h5 className="mb-0" style={{ marginRight: "13px" }}>
                          ID front
                        </h5>
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper d-flex align-items-center">
                              <label htmlFor="contained-button-file">
                                <Button
                                  variant="custom-button"
                                  color="primary"
                                  component="span"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  Upload
                                </Button>
                              </label>
                              <label htmlFor="icon-button-file">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                ></IconButton>
                              </label>

                              <div className="image-item">
                                {imageList[imageList.length - 1]?.data_url !==
                                undefined ? (
                                  <img
                                    src={
                                      imageList[imageList.length - 1]?.data_url
                                    }
                                    alt=""
                                    width="100px"
                                    height="60px"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                      <div className="d-flex align-items-center">
                        <h5 className="mb-0 mr-3">ID back</h5>
                        <ImageUploading
                          multiple
                          value={faviconImages}
                          onChange={onChangeFavicon}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({
                            faviconImageList,
                            onImageUpload,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper d-flex align-items-center">
                              <label htmlFor="contained-button-file">
                                <Button
                                  variant="custom-button"
                                  color="primary"
                                  component="span"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  Upload
                                </Button>
                              </label>
                              <label htmlFor="icon-button-file">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                ></IconButton>
                              </label>

                              <div className="image-item">
                                {faviconImages[faviconImages.length - 1]
                                  ?.data_url !== undefined ? (
                                  <img
                                    src={
                                      faviconImages[faviconImages.length - 1]
                                        ?.data_url
                                    }
                                    alt=""
                                    width="100px"
                                    height="60px"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                      {((logoImage && logoImage[1] !== undefined) ||
                        passportImage ||
                        (passportImages && passportImages[0] != undefined)) && (
                        <Button
                          variant="custom-button"
                          style={{
                            // position: "absolute",
                            marginLeft: "12px",
                          }}
                          onClick={uploadId}
                          disabled={idloader}
                        >
                          {idloader ? (
                            <CircularProgress
                              size={15}
                              color="inherit"
                              sx={{ ml: 1.5 }}
                            />
                          ) : (
                            "Upload ID"
                          )}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Modal
        show={showFrontIdModal}
        onHide={handleCloseFrontIdModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton> Front id</Modal.Header>
        <Modal.Body>
          {!image ? (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          ) : (
            <img src={image} alt="test-ilustartion" />
          )}
          <Button
            variant="custom-button"
            style={{ marginTop: "5px" }}
            onClick={() => (!image ? capture() : setImage(undefined))}
          >
            {!image ? "Capture photo" : "Retake photo"}
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={showBackIdModal}
        onHide={handleCloseBackIdModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton>Back id</Modal.Header>
        <Modal.Body>
          {!backImage ? (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          ) : (
            <img src={backImage} alt="test-ilustartion" />
          )}
          <Button
            variant="custom-button"
            onClick={() =>
              !backImage ? backcapture() : setBackImage(undefined)
            }
            style={{ marginTop: "5px" }}
          >
            {!backImage ? "Capture photo" : "Retake photo"}
          </Button>
        </Modal.Body>
      </Modal>
      {/* Modal for Camera capture for Passport Image */}
      <Modal
        show={showPassportModal}
        onHide={handleClosePassportModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton> Passport size Photo</Modal.Header>
        <Modal.Body>
          {!passportImage ? (
            <div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          ) : (
            <img src={passportImage} alt="test-ilustartion" />
          )}
          <Button
            variant="custom-button"
            onClick={() =>
              !passportImage ? passportCapture() : setPassportImage(undefined)
            }
          >
            {!passportImage ? "Capture photo" : "take photo"}
          </Button>
        </Modal.Body>
      </Modal>
      <Grid item container xs={4} spacing={2} style={{ height: "1rem" }}>
        <Grid item container xs={12}>
          <Card variant="outlined">
            <CardHeader
              sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
              title="Booking Details"
            ></CardHeader>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Check-in: {checkin.toDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Check-out: {checkout.toDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Total length of stay: {`${diffDays} Days`}
                  </Typography>
                </Grid>
                <Divider sx={{ width: "inherit", padding: 1 }} />
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Room Selections Summary
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {datas?.map((roomDetail) => {
                  return (
                    <Grid
                      key={roomDetail.roomTypeId}
                      paddingTop={1}
                      item
                      container
                      xs={12}
                    >
                      <Grid item xs={12}>
                        <Typography
                          paddingTop={1}
                          paddingBottom={1}
                          variant="body1"
                        >
                          {roomDetail.original.roomTypeName},{" "}
                          {roomDetail.ratePlan.ratePlanName}
                        </Typography>

                        <Grid item xs={12} container spacing={1}>
                          {Object.keys(roomDetail.selectionData)
                            .slice(0, roomDetail.roomCount)
                            .map((selectionCount) => {
                              return (
                                <Grid
                                  item
                                  key={selectionCount + roomDetail.roomTypeId}
                                  xs={12}
                                >
                                  <Typography variant="body2">
                                    {`Room ${selectionCount} | Adults: ${
                                      roomDetail.selectionData[
                                        Number(selectionCount)
                                      ].A
                                    }, Children: ${
                                      roomDetail.selectionData[
                                        Number(selectionCount)
                                      ].C
                                    }`}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          {showRooms && (
                            <Grid item xs={12}>
                              <Typography variant="body2">
                                Selected Rooms Ids :{" "}
                                {roomDetail.selectedRooms
                                  .reduce((results, selectedId, indx) => {
                                    if (selectedId)
                                      results.push(
                                        roomDetail["combos"]["onlineRoomIds"][
                                          indx
                                        ]
                                      );
                                    return results;
                                  }, [])
                                  .join(",")}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        <Divider />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader
              sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
              title="Your price summary"
            ></CardHeader>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                {/* <Grid item container xs={12}>
                  <Grid item container justifyContent="flex-start" xs={6}>
                    <Typography variant="body2">
                      Traditional Model Price
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <Typography sx={{ textDecoration: "line-through" }}>
                      {completePrice}
                    </Typography>
                  </Grid>
                </Grid> */}
                <Grid item container xs={12}>
                  <Grid item container justifyContent="flex-start" xs={6}>
                    <Typography variant="body2">Room Price</Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <Typography>{`${hotelCurrency} ${subtotal?.sub?.toFixed(
                      2
                    )}`}</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container justifyContent="flex-start" xs={6}>
                    <Typography variant="body2">Taxes</Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <Typography>{`${hotelCurrency} ${subtotal?.tax?.toFixed(
                      2
                    )}`}</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container justifyContent="flex-start" xs={6}>
                    <Typography variant="body2">Total price</Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <TextField
                      id="standard-basic"
                      hiddenLabel
                      variant="standard"
                      value={formik.values.totalPrice}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {hotelCurrency}
                          </InputAdornment>
                        ),
                      }}
                      disabled={
                        JSON.parse(
                          props?.data?.accessControl?.user_feature_map
                            ?.EnquiryDashboard
                        ).read !== "YES"
                          ? JSON.parse(
                              props?.data?.accessControl?.user_feature_map
                                ?.EnquiryDashboard
                            ).read === "YES"
                            ? false
                            : true
                          : true
                      }
                      style={{ width: "6.4rem" }}
                      inputProps={inputProps}
                      onChange={(e) => {
                        formik.setFieldValue("totalPrice", e.target.value);
                      }}
                      onBlur={(e) => {
                        finalPriceChange(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid item container xs={12}>
                  <Grid item container justifyContent="flex-end" xs={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Total price"
                      value={formik.values.totalPrice}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {hotelCurrency}
                          </InputAdornment>
                        ),
                      }}
                      inputProps={inputProps}
                      onChange={(e) => {
                        formik.setFieldValue("totalPrice", e.target.value);
                      }}
                      onBlur={(e) => {
                        finalPriceChange(e.target.value);
                      }}
                    ></TextField>
                  </Grid>
                </Grid> */}
                {/* code commented for future purpose
                {addGuestState?.length > 0 &&
                  totalAdults > 1 &&
                  Array.from({ length: totalAdults - 1 }, (_, index) => {
                    let roomInd = 0;
                    return (
                      <>
                        <Grid container style={{ marginTop: "15px" }}>
                          <Grid item xs={4}>
                            <Button
                              variant="custom-button"
                              className="mr-4 mt-3"
                              onClick={toggleAddGuestDrawer(
                                "right",
                                true,
                                roomInd,
                                index
                              )}
                            >
                              Add Guest {index + 2} Details
                            </Button>
                          </Grid>
                        </Grid>
                        <Drawer
                          anchor={"right"}
                          open={addGuestState[roomInd][index]["right"]}
                          onClose={toggleAddGuestDrawer(
                            "right",
                            false,
                            roomInd,
                            index
                          )}
                          PaperProps={{
                            sx: { width: 700 },
                          }}
                        >
                          {addGuestDetail("right", roomInd, index)}
                        </Drawer>
                      </>
                    );
                  })} */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(DetailsReview);
