import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  TextField,
  Select,
} from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

import { connect } from "react-redux";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { createschedule, getAllReporttype } from "./ReportScheduleApi";
import moment from "moment";

const AddNewReportSchedule = (props) => {
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const [name, setName] = useState(
    props.selectedReport?.name ? props.selectedReport?.name : ""
  );
  const [checkinDate, setcheckinDate] = useState(new Date());
  const [Frequency, setFrequency] = useState(
    props.selectedReport?.frequency ? props.selectedReport?.frequency : "DAILY"
  );
  const [weekDay, setweekDay] = useState(
    props.selectedReport?.interval ? props.selectedReport?.interval : "SUNDAY"
  );
  const [day, setday] = useState(1);
  const [allReportTYpe, setallReportTYpe] = useState([]);
  const [selectedReportType, setselectedReportType] = useState(
    props.selectedReport?.reportType ? props.selectedReport?.reportType : ""
  );
  const [firstNDays, setfirstNDays] = useState(
    props.selectedReport?.lastNDays ? props.selectedReport?.lastNDays : 0
  );
  const [lastNDays, setlastNDays] = useState(
    props.selectedReport?.nextNDays ? props.selectedReport?.nextNDays : 0
  );
  const [reportEmail, setreportEmail] = useState(
    props.selectedReport?.emails ? props.selectedReport?.emails : ""
  );
  const [deliveryTime, setdeliveryTime] = useState(
    props.selectedReport?.deliveryTime
      ? props.selectedReport?.deliveryTime
      : "05:00"
  );

  const handleChange = (newValue) => {
    setcheckinDate(newValue);
  };

  const [loader, setloader] = useState(false);

  const createReportSchedules = async () => {
    if (name === "") {
      toast.error("Please enter report name");
    } else {
      setloader(true);
      const payload = {
        hotel_id: localStorage.getItem("hotelId"),
        name: name,
        report_type: selectedReportType,
        frequency: Frequency,
        date: deliveryTime,
        time_interval:
          Frequency === "DAILY"
            ? "DAILY"
            : Frequency === "WEEKLY"
            ? weekDay
            : Frequency === "MONTHLY"
            ? day
            : "DAILY",
        status: "ACTIVE",
        emails: reportEmail.split(","),
        id: props.selectedReport?.id ? props.selectedReport?.id : "",
        last_n_days: Frequency === "DAILY" ? 0 : Number(firstNDays),
        next_n_days: Frequency === "DAILY" ? 0 : Number(lastNDays),
      };
      if (reportEmail.length > 0) {
        const data = await createschedule(payload);
        if (data.code === "200") {
          toast.success("Successfully added");
          setloader(false);
        } else {
          toast.error("Something went wrong");
          setloader(false);
        }
        props.getAllReports();
        props.closeModal();
      } else {
        toast.error("Please enter email");
        setloader(false);
      }
    }
  };

  useEffect(async () => {
    const data = await getAllReporttype();
    setallReportTYpe(JSON.parse(data.message));
    if (
      props.selectedReport?.reportType === undefined ||
      props.selectedReport?.reportType === null
    ) {
      setselectedReportType(JSON.parse(data.message)[0]);
    } else {
      setselectedReportType(props.selectedReport?.reportType);
    }
  }, []);
  return (
    <div>
      <Grid item container xs={12} sx={{ width: "100%", height: "100%" }}>
        <div className="d-flex flex-column">
          <h2>Report Schedules</h2>
          <p>
            Use this page to create and edit audit report generation schedules.
            You can generate daily or weekly audit reports at the specific time,
            and have it mailed to the email address of your choice
          </p>
        </div>

        <Card sx={{ width: "100%", height: "100%" }}>
          <CardContent>
            <Grid item xs={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={styles.questionPadding}>
                  <div>
                    <h4>Schedule Name</h4>
                    <div className="mb-2 ">
                      <TextField
                        label="Name"
                        className="input-lg w-100 mt-3"
                        placeholder="Enter tax name here"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        name="schedule_name"
                        id="schedule_name"
                      />
                    </div>
                    <div className="d-flex ">
                      <NewReleasesIcon color="primary" />
                      <p className="ml-1 text-muted">
                        Name this Report schedule. For e.g Daily Audit Schedule
                        or Weekly Audit Amenity.
                      </p>
                    </div>
                  </div>
                </div>
                <div style={styles.questionPadding}>
                  <div>
                    <h4 className="mb-2 ">Report Type ?</h4>
                    <div className="mb-2 ">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Report Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={(e) =>
                            setselectedReportType(e.target.value)
                          }
                          style={{ marginLeft: "0rem" }}
                          className="mr-4"
                          label="Report Type"
                          value={selectedReportType}
                        >
                          {allReportTYpe?.map((name) => (
                            <MenuItem value={name}>{name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="d-flex ">
                      <NewReleasesIcon color="primary" />
                      <p className="ml-1 text-muted">
                        Specify the type of report to be emailed.
                      </p>
                    </div>
                  </div>
                </div>

                <div style={styles.questionPadding}>
                  <div>
                    <h4 className="mb-2 ">Schedule Frequency?</h4>
                    <div className="mb-2 ">
                      <div className="d-flex">
                        {selectedReportType !== "Audit Report" &&
                          selectedReportType !== "Flash Manager Report" &&
                          selectedReportType !== "Arrivals Report" && (
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Schedule Frequency
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => setFrequency(e.target.value)}
                                style={{ marginLeft: "0rem" }}
                                className="mr-4"
                                label="Schedule Frequency"
                                value={Frequency}
                              >
                                <MenuItem value={"DAILY"}>Daily</MenuItem>

                                <MenuItem value={"WEEKLY"}>Weekly</MenuItem>

                                {/*  <MenuItem value={"MONTHLY"}>Monthly</MenuItem> */}
                              </Select>
                            </FormControl>
                          )}
                        {(selectedReportType === "Audit Report" ||
                          selectedReportType === "Flash Manager Report" ||
                          selectedReportType === "Arrivals Report") && (
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Schedule Frequency
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(e) => setFrequency(e.target.value)}
                              style={{ marginLeft: "0rem" }}
                              className="mr-4"
                              label="Schedule Frequency"
                              value={Frequency}
                            >
                              <MenuItem value={"DAILY"}>Daily</MenuItem>

                              {/*  <MenuItem value={"MONTHLY"}>Monthly</MenuItem> */}
                            </Select>
                          </FormControl>
                        )}
                        {Frequency === "WEEKLY" && (
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Schedule Frequency
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(e) => setweekDay(e.target.value)}
                              style={{ marginLeft: "0rem" }}
                              className="mr-4"
                              label="Schedule Frequency"
                              value={weekDay}
                            >
                              <MenuItem value={"SUNDAY"}>Sunday</MenuItem>
                              <MenuItem value={"MONDAY"}>Monday</MenuItem>
                              <MenuItem value={"TUESDAY"}>Tuesday</MenuItem>
                              <MenuItem value={"WEDNESDAY"}>Wednesday</MenuItem>
                              <MenuItem value={"THURSDAY"}>Thursday</MenuItem>
                              <MenuItem value={"FRIDAY"}>Friday</MenuItem>
                              <MenuItem value={"SATURDAY"}>Saturday</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                        {Frequency === "MONTHLY" && (
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Schedule Frequency
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(e) => setday(e.target.value)}
                              style={{ marginLeft: "0rem" }}
                              className="mr-4"
                              label="Schedule Frequency"
                              value={day}
                            >
                              <MenuItem value={"1"}>1</MenuItem>
                              <MenuItem value={"2"}>2</MenuItem>
                              <MenuItem value={"3"}>3</MenuItem>
                              <MenuItem value={"4"}>4</MenuItem>
                              <MenuItem value={"5"}>5</MenuItem>
                              <MenuItem value={"6"}>6</MenuItem>
                              <MenuItem value={"7"}>7</MenuItem>
                              <MenuItem value={"8"}>8</MenuItem>
                              <MenuItem value={"9"}>9</MenuItem>
                              <MenuItem value={"10"}>10</MenuItem>
                              <MenuItem value={"11"}>11</MenuItem>
                              <MenuItem value={"12"}>12</MenuItem>
                              <MenuItem value={"13"}>13</MenuItem>
                              <MenuItem value={"14"}>14</MenuItem>
                              <MenuItem value={"15"}>15</MenuItem>
                              <MenuItem value={"16"}>16</MenuItem>
                              <MenuItem value={"17"}>17</MenuItem>
                              <MenuItem value={"18"}>18</MenuItem>
                              <MenuItem value={"19"}>19</MenuItem>
                              <MenuItem value={"20"}>20</MenuItem>
                              <MenuItem value={"21"}>21</MenuItem>
                              <MenuItem value={"22"}>22</MenuItem>
                              <MenuItem value={"23"}>23</MenuItem>
                              <MenuItem value={"24"}>24</MenuItem>
                              <MenuItem value={"25"}>25</MenuItem>
                              <MenuItem value={"11"}>26</MenuItem>
                              <MenuItem value={"27"}>27</MenuItem>
                              <MenuItem value={"28"}>28</MenuItem>
                              <MenuItem value={"29"}>29</MenuItem>
                              <MenuItem value={"30"}>30</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </div>
                    </div>
                    <div className="d-flex">
                      {Frequency === "WEEKLY" && (
                        <TextField
                          label="How many days into the past?"
                          className="input-lg w-100 mt-3 mr-2"
                          placeholder="How many days into the past?"
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 30)
                              setfirstNDays(e.target.value);
                          }}
                          value={firstNDays}
                          name="schedule_name"
                          id="schedule_name"
                        />
                      )}
                      {Frequency === "WEEKLY" && (
                        <TextField
                          label="How many days into the future?"
                          className="input-lg w-100 mt-3 ml-2"
                          placeholder="How many days into the future?"
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 30)
                              setlastNDays(e.target.value);
                          }}
                          value={lastNDays}
                          name="schedule_name"
                          id="schedule_name"
                        />
                      )}
                    </div>
                    <div className="d-flex ">
                      <NewReleasesIcon color="primary" />
                      <p className="ml-1 text-muted">
                        If you select 7 days into past and 7 days into future,
                        we will send you report for 15 days as per the frequency
                        you have selected.
                      </p>
                    </div>
                  </div>
                </div>

                <div style={styles.questionPadding}>
                  <div>
                    <h4 className="mb-2 ">Report Delivery Time ?</h4>
                    <div className="mb-2 ">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Schedule Frequency
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={(e) => setdeliveryTime(e.target.value)}
                          style={{ marginLeft: "0rem" }}
                          className="mr-4"
                          label="Schedule Frequency"
                          value={deliveryTime}
                        >
                          <MenuItem value={"00:00"}>00:00 AM</MenuItem>
                          <MenuItem value={"00:15"}>00:15 AM</MenuItem>
                          <MenuItem value={"00:30"}>00:30 AM</MenuItem>
                          <MenuItem value={"00:45"}>00:45 AM</MenuItem>
                          <MenuItem value={"01:00"}>01:00 AM</MenuItem>
                          <MenuItem value={"01:15"}>01:15 AM</MenuItem>
                          <MenuItem value={"01:30"}>01:30 AM</MenuItem>
                          <MenuItem value={"01:45"}>01:45 AM</MenuItem>
                          <MenuItem value={"02:00"}>02:00 AM</MenuItem>
                          <MenuItem value={"02:15"}>02:15 AM</MenuItem>
                          <MenuItem value={"02:30"}>02:30 AM</MenuItem>
                          <MenuItem value={"02:45"}>02:45 AM</MenuItem>
                          <MenuItem value={"03:00"}>03:00 AM</MenuItem>
                          <MenuItem value={"03:15"}>03:15 AM</MenuItem>
                          <MenuItem value={"03:30"}>03:30 AM</MenuItem>
                          <MenuItem value={"03:45"}>03:45 AM</MenuItem>
                          <MenuItem value={"04:00"}>04:00 AM</MenuItem>
                          <MenuItem value={"04:15"}>04:15 AM</MenuItem>
                          <MenuItem value={"04:30"}>04:30 AM</MenuItem>
                          <MenuItem value={"04:45"}>04:45 AM</MenuItem>
                          <MenuItem value={"05:00"}>05:00 AM</MenuItem>
                          <MenuItem value={"05:15"}>05:15 AM</MenuItem>
                          <MenuItem value={"05:30"}>05:30 AM</MenuItem>
                          <MenuItem value={"05:45"}>05:45 AM</MenuItem>
                          <MenuItem value={"06:00"}>06:00 AM</MenuItem>
                          <MenuItem value={"06:15"}>06:15 AM</MenuItem>
                          <MenuItem value={"06:30"}>06:30 AM</MenuItem>
                          <MenuItem value={"06:45"}>06:45 AM</MenuItem>
                          <MenuItem value={"07:00"}>07:00 AM</MenuItem>
                          <MenuItem value={"07:15"}>07:15 AM</MenuItem>
                          <MenuItem value={"07:30"}>07:30 AM</MenuItem>
                          <MenuItem value={"07:45"}>07:45 AM</MenuItem>
                          <MenuItem value={"08:00"}>08:00 AM</MenuItem>
                          <MenuItem value={"08:15"}>08:15 AM</MenuItem>
                          <MenuItem value={"08:30"}>08:30 AM</MenuItem>
                          <MenuItem value={"08:45"}>08:45 AM</MenuItem>
                          <MenuItem value={"08:00"}>08:00 AM</MenuItem>
                          <MenuItem value={"09:15"}>09:15 AM</MenuItem>
                          <MenuItem value={"09:30"}>09:30 AM</MenuItem>
                          <MenuItem value={"09:45"}>09:45 AM</MenuItem>
                          <MenuItem value={"10:00"}>10:00 AM</MenuItem>
                          <MenuItem value={"10:15"}>10:15 AM</MenuItem>
                          <MenuItem value={"10:30"}>10:30 AM</MenuItem>
                          <MenuItem value={"10:45"}>10:45 AM</MenuItem>
                          <MenuItem value={"11:00"}>11:00 AM</MenuItem>
                          <MenuItem value={"11:15"}>11:15 AM</MenuItem>
                          <MenuItem value={"11:30"}>11:30 AM</MenuItem>
                          <MenuItem value={"11:45"}>11:45 AM</MenuItem>
                          <MenuItem value={"12:00"}>12:00 PM</MenuItem>
                          <MenuItem value={"12:15"}>12:15 PM</MenuItem>
                          <MenuItem value={"12:30"}>12:30 PM</MenuItem>
                          <MenuItem value={"12:45"}>12:45 PM</MenuItem>
                          <MenuItem value={"12:00"}>12:00 PM</MenuItem>
                          <MenuItem value={"12:15"}>12:15 PM</MenuItem>
                          <MenuItem value={"12:30"}>12:30 PM</MenuItem>
                          <MenuItem value={"12:45"}>12:45 PM</MenuItem>
                          <MenuItem value={"13:00"}>13:00 PM</MenuItem>
                          <MenuItem value={"13:15"}>13:15 PM</MenuItem>
                          <MenuItem value={"13:30"}>13:30 PM</MenuItem>
                          <MenuItem value={"13:45"}>13:45 PM</MenuItem>
                          <MenuItem value={"14:00"}>14:00 PM</MenuItem>
                          <MenuItem value={"14:15"}>14:15 PM</MenuItem>
                          <MenuItem value={"14:30"}>14:30 PM</MenuItem>
                          <MenuItem value={"14:45"}>14:45 PM</MenuItem>
                          <MenuItem value={"15:00"}>15:00 PM</MenuItem>
                          <MenuItem value={"15:15"}>15:15 PM</MenuItem>
                          <MenuItem value={"15:30"}>15:30 PM</MenuItem>
                          <MenuItem value={"15:45"}>15:45 PM</MenuItem>
                          <MenuItem value={"16:00"}>16:00 PM</MenuItem>
                          <MenuItem value={"16:15"}>16:15 PM</MenuItem>
                          <MenuItem value={"16:30"}>16:30 PM</MenuItem>
                          <MenuItem value={"16:45"}>16:45 PM</MenuItem>
                          <MenuItem value={"17:00"}>17:00 PM</MenuItem>
                          <MenuItem value={"17:15"}>17:15 PM</MenuItem>
                          <MenuItem value={"17:30"}>17:30 PM</MenuItem>
                          <MenuItem value={"17:45"}>17:45 PM</MenuItem>
                          <MenuItem value={"18:00"}>18:00 PM</MenuItem>
                          <MenuItem value={"18:15"}>18:15 PM</MenuItem>
                          <MenuItem value={"18:30"}>18:30 PM</MenuItem>
                          <MenuItem value={"18:45"}>18:45 PM</MenuItem>
                          <MenuItem value={"19:00"}>19:00 PM</MenuItem>
                          <MenuItem value={"19:15"}>19:15 PM</MenuItem>
                          <MenuItem value={"19:30"}>19:30 PM</MenuItem>
                          <MenuItem value={"19:45"}>19:45 PM</MenuItem>
                          <MenuItem value={"20:00"}>20:00 PM</MenuItem>
                          <MenuItem value={"20:15"}>20:15 PM</MenuItem>
                          <MenuItem value={"20:30"}>20:30 PM</MenuItem>
                          <MenuItem value={"20:45"}>20:45 PM</MenuItem>
                          <MenuItem value={"21:00"}>21:00 PM</MenuItem>
                          <MenuItem value={"21:15"}>21:15 PM</MenuItem>
                          <MenuItem value={"21:30"}>21:30 PM</MenuItem>
                          <MenuItem value={"21:45"}>21:45 PM</MenuItem>
                          <MenuItem value={"22:00"}>22:00 PM</MenuItem>
                          <MenuItem value={"22:15"}>22:15 PM</MenuItem>
                          <MenuItem value={"22:30"}>22:30 PM</MenuItem>
                          <MenuItem value={"22:45"}>22:45 PM</MenuItem>
                          <MenuItem value={"23:00"}>23:00 PM</MenuItem>
                          <MenuItem value={"23:15"}>23:15 PM</MenuItem>
                          <MenuItem value={"23:30"}>23:30 PM</MenuItem>
                          <MenuItem value={"23:45"}>23:45 PM</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="d-flex ">
                      <NewReleasesIcon color="primary" />
                      <p className="ml-1 text-muted">
                        Specify at what time of the day you want night audit
                        report to be emailed.
                      </p>
                    </div>
                  </div>
                </div>

                <div style={styles.questionPadding}>
                  <div>
                    <h4>Report Delivery Email</h4>
                    <div className="mb-2 ">
                      <TextField
                        label="Email"
                        className="input-lg w-100 mt-3"
                        placeholder="Enter tax name here"
                        onChange={(e) => setreportEmail(e.target.value)}
                        value={reportEmail}
                        name="schedule_name"
                        id="schedule_name"
                      />
                    </div>
                    <div className="d-flex ">
                      <NewReleasesIcon color="primary" />
                      <p className="ml-1 text-muted">
                        Specify the email address you want this email to be
                        delivered and it should be comma seperated with no
                        spaces
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Button
              color="primary"
              className="float-right mr-4 mb-3"
              variant="custom-button"
              type="submit"
              disabled={loader}
              onClick={createReportSchedules}
            >
              {loader ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                "Save"
              )}
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(AddNewReportSchedule);

const styles = {
  iconColor: {
    borderRadius: "100%",
    bordeRadius: "100%",
    height: "40px",
    width: "40px",
    padding: "4px",
    marginRight: "-10px",
    color: "#COCOCO",
  },
  inputSize: {
    width: "50px",
    bordeRadius: "5px",
    heigth: "30px",
  },
  questionPadding: {
    display: "flex",
    marginBottom: "40px ",
  },
};
