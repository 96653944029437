import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { djangoHandleDataRequests } from "../../../api/index";
import noBooking from "./not_found.svg";
import MockLoaderTable from "./MockLoaderTable";
import { connect } from "react-redux";

function PaymentGatewayLogTable({
  paymentGatewayLogData,
  bookingId,
  hotelId,
  updateInvoice,
  upDateViewManage,
  updateSummaryData,
  loggedUserEmail,
  setOpenManagePay,
  data,
  pgName,
  resId,
}) {
  // getting the services data for the invoice

  const products = paymentGatewayLogData;

  // get background color
  const getBgColor = (cell) => {
    if (cell === "SUCCESS") {
      return "#1BC5BD";
    } else if (cell === "AUTH") {
      return "#165ED0";
    } else {
      return "red";
    }
  };

  const columns = [
    {
      dataField: "log_timestamp",
      text: "Log Time",
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
      formatter: (cell) => {
        return <Typography style={{ fontSize: "12px" }}>{cell}</Typography>;
      },
    },
    {
      dataField: "user_email",
      text: "Action By",
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
      formatter: (cell) => {
        return (
          <Typography
            style={{ width: "auto", wordBreak: "break-all", fontSize: "12px" }}
          >
            {cell}
          </Typography>
        );
      },
    },
    {
      dataField: "pm_gateway_name",
      text: "Payment Gateway",
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
      formatter: (cell) => {
        return <Typography style={{ fontSize: "12px" }}>{cell}</Typography>;
      },
    },
    {
      dataField: "approved",
      text: "Accounts payment status",
      sort: true,
      headerStyle: {
        width: "15%",
        fontSize: "15px",
      },
      style: {
        wordBreak: "break-all",
        width: "15%",
        fontSize: "15px",
      },
      editable: false,
      hidden:
        JSON.parse(
          data?.accessControl?.user_feature_map?.PaymentConfirmationFolio
        ).read !== "YES",
      formatter: (cell) => {
        if (cell.toLowerCase() === "yes") {
          return "APPROVED";
        } else if (cell.toLowerCase() === "no") {
          return "REJECTED";
        } else {
          return cell;
        }
      },
    },
    {
      dataField: "pm_amount",
      text: "Amount",
      headerStyle: {
        fontSize: "15px",
      },
      formatter: (cell) => {
        return (
          <Typography style={{ fontSize: "12px" }}>
            {parseFloat(cell).toFixed(2)}
          </Typography>
        );
      },
      editable: false,
    },
    {
      dataField: "refund_amount",
      text: "Refunded Amount",
      headerStyle: {
        fontSize: "15px",
      },
      formatter: (cell) => {
        return (
          <Typography style={{ fontSize: "12px" }}>
            {parseFloat(cell).toFixed(2)}
          </Typography>
        );
      },
      editable: false,
    },
    {
      dataField: "pm_status",
      text: "Status",
      headerStyle: { fontSize: "15px" },
      formatter: (cell) => {
        return (
          <Typography
            style={{
              color: "white",
              backgroundColor: getBgColor(cell),
              padding: "5px 8px 5px 8px",
              borderRadius: "15px",
              width: "fit-content",
              fontSize: "12px",
            }}
          >
            {cell}
          </Typography>
        );
      },
    },
    {
      dataField: "pm_payment_type",
      text: "Payment Source",
      headerStyle: {
        fontSize: "15px",
      },
      formatter: (value, row) => {
        return (
          <Typography style={{ fontSize: "12px" }}>
            {`${value} ${row?.pm_payment_issuer} ${row?.pm_card_last4}`}
          </Typography>
        );
      },
      editable: false,
    },
    {
      dataField: "pm_currency_code",
      text: "Currency",
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
      formatter: (cell) => {
        return <Typography style={{ fontSize: "12px" }}>{cell}</Typography>;
      },
    },
    {
      dataField: "pm_status",
      text: " ",
      sort: false,
      headerStyle: {
        fontSize: "15px",
      },
      formatter: function (value, row, index) {
        let pm_gateway_name = row?.pm_gateway_name;
        const isMasterUser =
          localStorage.getItem("masterUser") === "true" ? true : false;
        const paymentArray = isMasterUser
          ? ["TSYS", "FORTIS", "SHIFT4", "STRIPE", "RAZORPAY"]
          : ["TSYS", "FORTIS", "SHIFT4", "STRIPE"];
        if (paymentArray?.includes(pm_gateway_name)) {
          if (
            value === "AUTH" &&
            ["STRIPE", "TSYS", "FORTIS", "SHIFT4"].includes(pm_gateway_name)
          ) {
            return (
              <div key={row.payment_id} className="d-flex">
                <button
                  className="btn viewManageCTABtns mr-2"
                  onClick={() => handleChargePGOpen(row.payment_id)}
                >
                  Capture auth
                </button>
                <button
                  className="btn viewManageCTABtns"
                  onClick={() => handleVoidPGOpen(row.payment_id)}
                >
                  Void auth
                </button>
              </div>
            );
          } else if (
            parseFloat(row.refund_amount) < parseFloat(row.pm_amount) &&
            ["SUCCESS", "CAPTURED", "SUCCEEDED"].includes(
              row?.pm_status?.trim()?.toUpperCase()
            )
          ) {
            // let max_amount = parseFloat(row.pm_amount) - parseFloat(row.refund_amount);
            let actions =
              JSON.parse(
                data?.accessControl?.user_feature_map?.PaymentGatewayRefund
              ).read === "YES" ? (
                <button
                  className="btn viewManageCTABtns"
                  onClick={() => handleRefundPGOpen(row.payment_id)}
                >
                  Refund
                </button>
              ) : (
                <span> </span>
              );
            // if(["SHIFT4"].includes(row.pm_gateway_name)) {
            //     actions+=' <a href="#" class="btn btn-xs btn-primary" onclick="performVoid(' + row.payment_id +')">VOID</a>';
            // }
            return actions;
          }
        }
      },
      editable: false,
    },
  ];

  const [openRefund, setOpenRefund] = useState(false);
  const [paymentId, setPaymentId] = useState();
  function handleRefundPGOpen(val) {
    setOpenRefund(true);
    setPaymentId(val);
  }
  const handleRefundPGClose = () => setOpenRefund(false);

  //refund  states

  const [pgRefundAmt, setPGRefundAmt] = useState("");

  // refund modal loading
  const [openRefundLoading, setOpenRefundLoading] = useState(false);

  // refund modal --- api
  function handleRefundPG() {
    setOpenRefundLoading(true);
    djangoHandleDataRequests(
      `payments/refundPayment/?booking_id=${bookingId}&payment_id=${paymentId}&amount=${Number(
        pgRefundAmt
      )}&logged_user_email=${loggedUserEmail}${
        resId !== undefined ? "&reservation_id=" + resId : ""
      }`
    )
      .then((res) => {
        toast.success("Successfully refunded the amount", {
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 8000,
          hideProgressBar: true,
          bodyClassName: "importantToast",
        });
      })
      .then((res) => upDateViewManage())
      .then((res) => setOpenManagePay())
      .catch((err) => {
        toast.error(err, {
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 8000,
          hideProgressBar: true,
          bodyClassName: "importantToast",
        });
      })
      .finally(() => {
        setOpenRefundLoading(false);
        handleRefundPGClose();
        setPGRefundAmt();
      });
  }

  //charge void modal
  const [openCharge, setOpenCharge] = useState(false);
  // const [paymentId, setPaymentId] = useState();
  function handleChargePGOpen(val) {
    setOpenCharge(true);
    setPaymentId(val);
  }
  const handleChargePGClose = () => setOpenCharge(false);

  //charge void states

  const [pGChargeAmt, setPGChargeAmt] = useState("");

  // charge void modal loading
  const [openChargeLoading, setOpenChargeLoading] = useState(false);

  // charge void modal --- api
  function handleChargePG() {
    setOpenChargeLoading(true);
    djangoHandleDataRequests(
      `payments/capturePayment/?hotelId=${hotelId}&booking_id=${bookingId}&payment_id=${paymentId}&amount=${Number(
        pGChargeAmt
      )}&logged_user_email=${loggedUserEmail}`
    )
      .then((res) => {
        toast.success("Successfully captured the amount", {
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 8000,
          hideProgressBar: true,
          bodyClassName: "importantToast",
        });
      })
      .then((res) => upDateViewManage())
      .catch((error) => {
        toast.error(error, {
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 8000,
          hideProgressBar: true,
          bodyClassName: "importantToast",
        });
      })
      .finally(() => {
        setOpenChargeLoading(false);
        handleRefundPGClose();
        setPGRefundAmt();
      });
  }

  // void modal
  const [openVoid, setOpenVoid] = useState(false);
  function handleVoidPGOpen(val) {
    setOpenVoid(true);
    setPaymentId(val);
  }
  const handleVoidPGClose = () => setOpenVoid(false);

  //  void modal loading
  const [openVoidLoading, setOpenVoidLoading] = useState(false);

  //  void modal --- api
  function handleVoidPG() {
    setOpenVoidLoading(true);
    djangoHandleDataRequests(
      `payments/performVoid/?hotelId=${hotelId}&booking_id=${bookingId}&payment_id=${paymentId}&logged_user_email=${loggedUserEmail}`
    )
      .then((res) => {
        toast.success("Successfully voided the payment", {
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 8000,
          hideProgressBar: true,
          bodyClassName: "importantToast",
        });
      })
      .then((res) => upDateViewManage())
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 8000,
          hideProgressBar: true,
          bodyClassName: "importantToast",
        });
        setPaymentId();
      })
      .finally(() => {
        setOpenVoidLoading(false);
        handleVoidPGClose();
      });
  }

  if (paymentGatewayLogData?.length === 0)
    return (
      <div
        className="d-flex flex-column align-items-center"
        style={{ marginTop: "9rem" }}
      >
        <img src={noBooking} alt="" />
        <p className="mt-3">No payments in this category</p>
      </div>
    );

  return (
    <>
      {products ? (
        <div className="invoiceSummTableWrapper">
          <ToolkitProvider
            keyField="payment_id"
            data={products}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="folioTableWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    bodyStyle={{ background: "#00ff00" }}
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bordered={false}
                    pagination={paginationFactory()}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <MockLoaderTable />
      )}
      {/* charge modal */}
      <Modal
        open={openCharge}
        onClose={handleChargePGClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Capture PG payment</div>
              <div className="close" onClick={() => handleChargePGClose()}>
                X
              </div>
            </div>
            <div className="fieldWrapper" style={{ marginTop: "20px" }}>
              <TextField
                id="outlined-name"
                label="Capture amount"
                name="deposit_amount"
                placeholder={`Enter charge amount less than or equal to ${
                  parseFloat(
                    paymentGatewayLogData?.filter(
                      (item) => item.payment_id === paymentId
                    )[0]?.pm_amount
                  ) -
                  parseFloat(
                    paymentGatewayLogData?.filter(
                      (item) => item.payment_id === paymentId
                    )[0]?.refund_amount
                  )
                }`}
                value={pGChargeAmt}
                onChange={(e) =>
                  /^[0-9.]*$/.test(e.target.value)
                    ? setPGChargeAmt(e.target.value)
                    : setPGChargeAmt(pGChargeAmt)
                }
                sx={{ width: "100%" }}
              />
            </div>

            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleChargePGClose()}
              >
                Cancel
              </button>
              {openChargeLoading === false ? (
                <button
                  className="submit"
                  disabled={
                    pGChargeAmt >
                    paymentGatewayLogData?.filter(
                      (item) => item.payment_id === paymentId
                    )[0]?.pm_amount -
                      paymentGatewayLogData?.filter(
                        (item) => item.payment_id === paymentId
                      )[0]?.refund_amount
                  }
                  onClick={() => handleChargePG()}
                  style={{ width: "150px" }}
                >
                  Capture auth
                </button>
              ) : (
                <button className="submit" style={{ width: "150px" }}>
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* void modal */}
      <Modal
        open={openVoid}
        onClose={handleVoidPGClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Perform void</div>
              <div className="close" onClick={() => handleVoidPGClose()}>
                X
              </div>
            </div>
            <div className="fieldWrapper" style={{ marginTop: "20px" }}>
              <p>You are about to perform void. Do you want to proceed?</p>
            </div>

            <div className="settleDuesSubmitBtns">
              <button className="cancelBtn" onClick={() => handleVoidPGClose()}>
                Cancel
              </button>
              <button
                className="submit"
                onClick={() => handleVoidPG()}
                style={{ width: "150px" }}
                disabled={openVoidLoading}
              >
                {!openVoidLoading ? (
                  "Void auth"
                ) : (
                  <CircularProgress size="15px" />
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* refund modal to refund the service items amount */}
      <Modal
        open={openRefund}
        onClose={handleRefundPGClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Refund payment</div>
              <div className="close" onClick={() => handleRefundPGClose()}>
                X
              </div>
            </div>
            <div className="fieldWrapper" style={{ marginTop: "20px" }}>
              <TextField
                id="outlined-name"
                label="Refund Amount"
                placeholder={`Enter refund amount less than ${
                  parseFloat(
                    paymentGatewayLogData?.filter(
                      (item) => item.payment_id === paymentId
                    )[0]?.pm_amount
                  ) -
                  parseFloat(
                    paymentGatewayLogData?.filter(
                      (item) => item.payment_id === paymentId
                    )[0]?.refund_amount
                  )
                }`}
                type="text"
                name="refund_amount"
                value={pgRefundAmt}
                onChange={(e) =>
                  /^[0-9.]*$/.test(e.target.value)
                    ? setPGRefundAmt(e.target.value)
                    : setPGRefundAmt(pgRefundAmt)
                }
                sx={{ width: "100%" }}
              />
            </div>
            {parseFloat(
              paymentGatewayLogData?.filter(
                (item) => item.payment_id === paymentId
              )[0]?.pm_amount
            ) -
              parseFloat(
                paymentGatewayLogData?.filter(
                  (item) => item.payment_id === paymentId
                )[0]?.refund_amount
              ) <
              pgRefundAmt && (
              <div style={{ color: "red", textAlign: "center" }}>
                {`Enter refund amount less than or equal to ${
                  parseFloat(
                    paymentGatewayLogData?.filter(
                      (item) => item.payment_id === paymentId
                    )[0]?.pm_amount
                  ) -
                  parseFloat(
                    paymentGatewayLogData?.filter(
                      (item) => item.payment_id === paymentId
                    )[0]?.refund_amount
                  )
                }`}
              </div>
            )}

            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleRefundPGClose()}
              >
                Cancel
              </button>
              {openRefundLoading === false ? (
                <button
                  className="submit"
                  // disabled={
                  //   pgRefundAmt >
                  //   paymentGatewayLogData?.filter(
                  //     (item) => item.payment_id === paymentId
                  //   )[0]?.pm_amount -
                  //     paymentGatewayLogData?.filter(
                  //       (item) => item.payment_id === paymentId
                  //     )[0]?.refund_amount
                  // }
                  onClick={() => handleRefundPG()}
                  style={{ width: "150px" }}
                  disabled={
                    pgRefundAmt > 0 &&
                    parseFloat(
                      paymentGatewayLogData?.filter(
                        (item) => item.payment_id === paymentId
                      )[0]?.pm_amount
                    ) -
                      parseFloat(
                        paymentGatewayLogData?.filter(
                          (item) => item.payment_id === paymentId
                        )[0]?.refund_amount
                      ) >=
                      pgRefundAmt
                      ? false
                      : true
                  }
                >
                  Refund
                </button>
              ) : (
                <button className="submit" style={{ width: "150px" }}>
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(PaymentGatewayLogTable);
