// ** React Imports
import React from "react";
import { Drawer, Hidden } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: "95%",
    ["@media (min-width:481px) and (max-width:768px)"]: {
      width: "95%",
    },
    ["@media (min-width:769px)"]: {
      width: "95%",
    },
    ["@media (min-width:320px) and (max-width:480px)"]: {
      width: "95%",
    },
  },
  drawerPaper: {
    width: "95%",
    ["@media (min-width:481px) and (max-width:768px)"]: {
      width: "95%",
    },
    ["@media (min-width:769px)"]: {
      width: "95%",
    },
    ["@media (min-width:320px) and (max-width:480px)"]: {
      width: "95%",
    },
  },
}));

const ModalCommonSlider = (props) => {
  // ** Props
  const classes = useStyles();
  const { onClose, open, onBackdropClick } = props;

  return (
    <Hidden implementation="css">
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={"right"}
        onBackdropClick={() => {
          if (!onBackdropClick) {
            return;
          }
          onBackdropClick();
        }}
        open={open}
        onClose={() => {
          if (!onClose) {
            return;
          }
          onClose();
        }}
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        {props.children}
      </Drawer>
    </Hidden>
  );
};

export default ModalCommonSlider;
