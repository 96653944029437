import React from "react";
import { Button, Modal, ModalBody, ModalTitle } from "react-bootstrap";
import "../../RevenueManagement/RatePlans/Views/RatePlanModal.css";
import AddNewReportSchedule from "./AddNewReportSchedule";

const ReportScheduleModal = (props) => {
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.hide}
        dialogClassName={"myModalBodysxsse"}
        contentClassName={"myModalContent"}
      >
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <Button variant="white" onClick={props.hide}>
              {" "}
              X{" "}
            </Button>
          </div>
        </ModalTitle>
        <ModalBody>
          <AddNewReportSchedule
            getAllReports={props.getAllReports}
            closeModal={props.hide}
            selectedReport={props.selectedReport}
          ></AddNewReportSchedule>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ReportScheduleModal;

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
