import axios from "axios";
import React from "react";
import moment from "moment";

// Convert hours into the days
export const readableTimeConverter = (hours) => {
  return hours <= "24"
    ? `${hours} hours `
    : `${Math.floor(parseInt(hours) / 24)} days ${Math.floor(
        parseInt(hours) % 24
      )} hours `;
};

export const handleNum = (num) => {
  return parseInt(num).toFixed(2);
};

// Handle the IP address of the user
export const getIpAddress = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  return res.data.IPv4;
};

// Rounding off prices to 2 digitss while making it an integer for Indian hotels
export const handlePrices = (price, currency) => {
  if (currency.startsWith("Rs")) {
    return parseInt(price);
  } else {
    return parseFloat(price).toFixed(2);
  }
};

export const dashboardActions = (
  dashData,
  switchOn,
  departureCount,
  arrivalCount
) => [
  {
    action: "NEW_BOOKINGS",
    color: "#96C7C1",
    heading: "New bookings",
    value: dashData?.newbookings,
  },
  {
    action: "ADMIN_CONFIRMED",
    color: "#CDF2CA",
    heading: "In-house",
    value: dashData?.inhouse,
  },
  {
    action: "CHECKINS",
    color: "#F7D59C",
    heading: "Arrivals",
    // value: dashData?.checkins,
    value: arrivalCount,
  },
  {
    action: "CHECKOUTS",
    color: "#6B7AA1",
    heading: "Departures",
    // value: dashData?.checkouts,
    value: departureCount,
  },
  {
    action: "CANCELLED",
    color: "#F29191",
    heading: "Cancellations",
    value: switchOn ? dashData?.today_cancellations : dashData?.cancellations,
  },
  {
    action: "ON_HOLD",
    color: "#6E7C7C",
    heading: "On hold",
    value: dashData?.holds,
  },
  {
    action: "NO_SHOW",
    color: "#D3E0DC",
    heading: "No shows",
    value: dashData?.no_shows,
  },
];

export const filtersForDashboard = (val, search) => {
  if (search == "") {
    return val;
  } else if (
    val.booking_user_name.toLowerCase().includes(search.toLowerCase())
  ) {
    return val;
  } else if (
    val.booking_user_phone.toLowerCase().includes(search.toLowerCase())
  ) {
    return val;
  } else if (val.booking_id.toLowerCase().includes(search.toLowerCase())) {
    return val;
  } else if (val.booking_source.toLowerCase().includes(search.toLowerCase())) {
    return val;
  } else if (val?.room_ids?.toLowerCase().includes(search.toLowerCase())) {
    return val;
  } else if (
    val?.rate_plan_name?.toLowerCase().includes(search.toLowerCase())
  ) {
    return val;
  } else if (val?.room_type?.toLowerCase().includes(search.toLowerCase())) {
    return val;
  } else if ("pending".includes(search.toLowerCase())) {
    if (val.pending_arrivals || val.pending_departures) {
      return val;
    }
  } else if ("pending arrival".includes(search.toLowerCase())) {
    if (val.pending_arrivals) {
      return val;
    }
  } else if ("pending departure".includes(search.toLowerCase())) {
    if (val.pending_departures) {
      return val;
    }
  } else if ("checked-in".includes(search.toLowerCase())) {
    if (val.reservation_status === "ADMIN_CONFIRMED") {
      return val;
    }
  } else if ("out".includes(search.toLowerCase())) {
    if (val.reservation_status === "DONE") {
      return val;
    }
  } else if ("confirmed".includes(search.toLowerCase())) {
    if (val.reservation_status === "CONFIRMED") {
      return val;
    }
  } else if ("cancelled".includes(search.toLowerCase())) {
    if (val.reservation_status === "CANCELLED") {
      return val;
    }
  } else if ("on_hold".includes(search.toLowerCase())) {
    if (val.reservation_status === "ON_HOLD") {
      return val;
    }
  } else if ("no_show".includes(search.toLowerCase())) {
    if (val.reservation_status === "ON_SHOW") {
      return val;
    }
  } else if (
    val.cin.toLowerCase().includes(search.toLowerCase()) ||
    val.cout.toLowerCase().includes(search.toLowerCase()) ||
    val.booking_made_on.toLowerCase().includes(search.toLowerCase())
  ) {
    return val;
  }
};

// get the current date and time
export const getCurrentDataAndTime = () => {
  return moment().format("YYYY-MM-DD HH:mm:ss");
};

// Check if the name entered is valid or not
// By valid : Name must not have any special characters
export const checkValidName = (name) => {
  const specialChars = [
    "{",
    "}",
    "-",
    "_",
    "#",
    "@",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "/",
  ];
  if (specialChars.filter((chars) => name.includes(chars)).length > 0) {
    return false;
  } else {
    return true;
  }
};

// Ag Grid sidebar
export const sideBar = () => {
  return {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
    defaultToolPanel: "",
  };
};

// Check if the checkin time is before the permissible time

// Get today's date
const todayDate = moment().format("YYYY-MM-DDTHH:mm:ss");

export const getTimestamp = (dateReceived) => {
  const [dateComponents, timeComponents] = dateReceived?.split("T");
  const [year, month, day] = dateComponents?.split("-");
  const hours =
    timeComponents?.split(":")[0] === undefined
      ? 0
      : timeComponents?.split(":")[0];
  const minutes =
    timeComponents?.split(":")[1] === undefined
      ? 0
      : timeComponents?.split(":")[1];
  const seconds =
    timeComponents?.split(":")[2] === undefined
      ? 0
      : timeComponents?.split(":")[2];
  const newDate = new Date(+year, month - 1, +day, +hours, +minutes, +seconds);
  const timeStamp = newDate.getTime();
  return Number(timeStamp);
};

export const validateCheckin = (startHr, endHr) => {
  if (getTimestamp(todayDate) >= startHr) {
    return true;
  } else {
    return false;
  }
};

// Function to validate the address
export function validateAddress(address) {
  if (address.trim().length === 0) {
    return false;
  } else if (address.includes("null")) {
    return false;
  } else if (address.includes("NA")) {
    return false;
  } else {
    return true;
  }
}

// Handling the complete address string
export function handleFullAddString(add) {
  const addArr = add.split(",");
  let newAdd = "";
  for (let i of addArr) {
    if (i.trim() === "NA" || i === null || i.trim().length === 0) {
      // pass
    } else {
      newAdd += i + ",";
    }
  }
  // Removing the last comma
  const lastCommaIdx = newAdd.lastIndexOf(",");
  newAdd = newAdd.substring(0, lastCommaIdx);
  return newAdd;
}

// Format images
// get the image extension
export const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

// Getting the rounded off num
// If decimal pointer 5 then leave it else round off
export function roundOffValues(val) {
  let originalVal = val;
  let intVal = parseInt(val);
  let diff = Number(originalVal) - Number(intVal);
  if (parseFloat(diff) === 5.0) {
    return Number(val.toFixed(2));
  } else {
    return parseFloat(Math.round(val)).toFixed(2);
  }
}

// Check if the master array contains the subarray
export function checkIfSub(master, sub) {
  let check = true;
  sub.map((item) => {
    let idx = master.indexOf(item);
    console.log("idx : ", idx);
    if (idx === -1) {
      check = false;
    }
  });
  return check;
}

// Handle the booking id
export function getBookingId(id) {
  const bookingIdArr = id.split("_");
  if (bookingIdArr.length > 0) {
    return bookingIdArr[2];
  } else {
    return id;
  }
}

// Date formats
export const longDateWith12HrTime = "MMM DD, YYYY hh:mm A";

// Convert a file into the base 64
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const bookingStatusDisplayName = (status) => {
  const formattedStatus = status?.toUpperCase();
  if (status === "CHECKED_OUT") {
    return "CHECKED OUT";
  } else if (formattedStatus === "ADMIN_CONFIRMED") {
    return "CHECKED IN";
  } else if (formattedStatus === "CHECKED_IN") {
    return "CHECKED IN";
  } else if (formattedStatus === "CONFIRMED") {
    return "CONFIRMED";
  } else if (formattedStatus === "ON-HOLD") {
    return "ON HOLD";
  } else if (formattedStatus === "BOOKING_ENQUIRY") {
    return "ENQUIRY";
  } else if (formattedStatus === "CANCELLED") {
    return "CANCELLED";
  } else if (formattedStatus === "NO_SHOW") {
    return "NO SHOW";
  } else {
    return formattedStatus;
  }
};
