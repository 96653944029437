import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetHotel: "[Set Hotel] Action",
  SetAllHotels: "[Set AllHotels] Action",
  GetAccessControl: "[get AllHotels] Access",
  SetHotelType: "[Set HotelType] Action",
  SetAccountBalance: "[Set AccountBalance] Action",
  SetAccountingVersion: "[Set AccountingVersion] Action",
  SetXytronEnabled: "[Set XytronEnabled] Action",
  SetShopAccessControl: "[Set ShopAccessControl] Action",
  SetFolioConfig: "[Set FolioConfig] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  hotels: undefined,
  token: undefined,
  selectedHotel: undefined,
  email: undefined,
  accessControl: undefined,
  hotelType: "STAYFLEXI",
  accountBalance: undefined,
  accountingVersion: undefined,
  xytronEnabled: undefined,
  shopAccessControl: {},
  folioConfig: "NA",
};

export const reducer = persistReducer(
  {
    storage,
    key: "v726-demo1-auth",
    whitelist: [
      "user",
      "authToken",
      "token",
      "hotels",
      "selectedHotel",
      "email",
      "accessControl",
      "hotelType",
      "accountBalance",
      "accountingVersion",
      "xytronEnabled",
      "shopAccessControl",
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { token, hotels, email } = action.payload.authToken;
        return { token, user: "admin", hotels, email };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.SetHotel: {
        const { selectedHotel } = action.payload;
        return { ...state, selectedHotel };
      }

      case actionTypes.SetAllHotels: {
        const { hotels } = action.payload;
        return { ...state, hotels };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return JSON.parse(JSON.stringify(initialAuthState));
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.GetAccessControl: {
        const { accessControl } = action.payload;
        return { ...state, accessControl };
      }
      case actionTypes.SetHotelType: {
        const { hotelType } = action.payload;
        return { ...state, hotelType };
      }

      case actionTypes.SetXytronEnabled: {
        const { xytronEnabled } = action.payload;
        return { ...state, xytronEnabled };
      }

      case actionTypes.SetAccountBalance: {
        const { accountBalance } = action.payload;
        return { ...state, accountBalance };
      }
      case actionTypes.SetAccountingVersion: {
        const { accountingVersion } = action.payload;
        return { ...state, accountingVersion };
      }

      case actionTypes.SetShopAccessControl: {
        const { shopAccessControl } = action.payload;
        return { ...state, shopAccessControl };
      }

      case actionTypes.SetFolioConfig: {
        const { folioConfig } = action.payload;
        return { ...state, folioConfig };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  selectHotel: (authToken) => ({
    type: actionTypes.SetHotel,
    payload: { selectedHotel: authToken },
  }),
  setAllHotels: (hotels) => ({
    type: actionTypes.SetAllHotels,
    payload: { hotels },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  GetAccessControl: (accessControl) => ({
    type: actionTypes.GetAccessControl,
    payload: { accessControl },
  }),
  setHotelType: (hotelType) => ({
    type: actionTypes.SetHotelType,
    payload: { hotelType },
  }),
  setXytronEnabled: (xytronEnabled) => ({
    type: actionTypes.SetXytronEnabled,
    payload: { xytronEnabled },
  }),
  setAccountBalance: (accountBalance) => ({
    type: actionTypes.SetAccountBalance,
    payload: { accountBalance },
  }),
  setAccountingVersion: (accountingVersion) => ({
    type: actionTypes.SetAccountingVersion,
    payload: { accountingVersion },
  }),
  setShopAccessControl: (shopAccessControl) => ({
    type: actionTypes.SetShopAccessControl,
    payload: { shopAccessControl },
  }),
  setFolioConfig: (folioConfig) => ({
    type: actionTypes.SetFolioConfig,
    payload: { folioConfig },
  }),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();
  //   yield put(actions.fulfillUser(user));
  // });
}
