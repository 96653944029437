import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import { sideBar } from "../../../Utils/Utils";
import CustomDateComponent from "../../Reports/customDateComponent";

function PaymentAgGridWrapper({ rowData, columns, pinnedBottomRowData }) {
  //Adding Total row-------------------
  const [gridApi, setGridApi] = useState(null);
  useEffect(() => {
    if (gridApi) {
      // Set the pinned bottom row data
      gridApi.setPinnedBottomRowData(pinnedBottomRowData);
    }
  }, [gridApi, pinnedBottomRowData]);
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // default column properties
  const defaultColDefs = {
    sortable: true, //sort the table
    floatingFilter: true, // getting the filter below the columns
    tooltipField: "message",
    filter: "agMultiColumnFilter", // setting the multi column filter for the table
    display: "subMenu", // how the text filter and the set filter will look like
    components: {
      agDateInput: CustomDateComponent,
    },
    resizable: true,
    cellRendererParams: {
      checkbox: true,
    },
  };
  //----------row selection types---------
  const rowSelectionType = "multiple";

  // settings the grid Option
  const gridOptions = {
    pagination: true,
    rowSelection: "multiple",
  };

  return (
    <Stack className="agGridWrapr">
      <Stack className="agGridTableWrapper position-relative w-100">
        <Stack className="agTable">
          <Stack className="ag-theme-material">
            <AgGridReact
              className="agGridTable"
              rowData={rowData}
              onGridReady={onGridReady}
              columnDefs={columns}
              defaultColDef={defaultColDefs}
              enableBrowserTooltips={true}
              rowMultiSelectWithClick={false}
              paginationPageSize={50}
              alwaysShowBothConditions={true}
              rowSelection={rowSelectionType}
              enableMultiRowDragging={false}
              suppressPivotMode={true}
              suppressColumnMove={true}
              suppressRowGroups={true}
              sideBar={sideBar()}
              groupMultiAutoColumn={true}
              rowHeight={32.5}
              statusBar={{
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center",
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" },
                ],
              }}
              gridOptions={gridOptions}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default PaymentAgGridWrapper;
