import { isEmpty, isNull, isObject, isUndefined } from "lodash";

export class PriceComparisonModel {
  allRoomTypesData = {};
  availableArray = [];
  channels = [];
  originalData = {};
  constructor(data) {
    if (
      isEmpty(data) ||
      !isObject(data) ||
      data.length <= 0 ||
      isUndefined(data)
    ) {
      return;
    }
    this.channels = Object.keys(data);
    this.originalData = data;
    this.channels.forEach((channel) => {
      data[channel]["allRoomTypes"].forEach((roomType) => {
        let temporaryObj = this.allRoomTypesData[roomType.roomTypeId];
        if (isNull(temporaryObj) || isUndefined(temporaryObj)) {
          this.allRoomTypesData[roomType.roomTypeId] = temporaryObj = {};
        }
        temporaryObj.name = roomType.roomTypeName;
        if (!temporaryObj["channelArray"]) {
          temporaryObj["channelArray"] = [];
        }
        const obj = this.mapAggregateRows(roomType, channel);
        obj.selectable = true;
        temporaryObj["channelArray"].push(obj);

        if (!temporaryObj["ratePlanSet"]) {
          temporaryObj["ratePlanSet"] = new Set();
        }

        if (!temporaryObj["ratePlans"]) {
          temporaryObj["ratePlans"] = {};
        }

        roomType.ratePlans.forEach((ratePlan) => {
          temporaryObj["ratePlanSet"].add(ratePlan.ratePlanId);
          temporaryObj["ratePlans"][ratePlan.ratePlanId] = {
            channelsMap: [],
            originalArray: [],
            ratePlanName: ratePlan.ratePlanName,
            ratePlanId: ratePlan.ratePlanId,
          };
        });
        this.allRoomTypesData[roomType.roomTypeId] = temporaryObj;
      });
    });
    this.channels.forEach((channel) => {
      data[channel]["allRoomTypes"].forEach((roomType) => {
        let roomTypeDataObj = this.allRoomTypesData[roomType.roomTypeId];
        roomType.ratePlans.forEach((ratePlan) => {
          if (roomTypeDataObj.ratePlanSet.has(ratePlan.ratePlanId)) {
            let ret_data = this.setInfoMap(ratePlan);
            let temp_default = Object.assign(
              {},
              ret_data[ratePlan.defaultOccupancy - 1]
            );
            temp_default.name = channel;
            if (
              roomTypeDataObj.ratePlans[ratePlan.ratePlanId].channelsMap
                .length > 0
            ) {
              temp_default.custom_index =
                roomTypeDataObj.ratePlans[
                  ratePlan.ratePlanId
                ].channelsMap.length;
            } else {
              temp_default.custom_index = 0;
            }
            temp_default.defaultOccupancy = ratePlan.defaultOccupancy;
            temp_default.expand = ret_data;
            temp_default.nonSelectable = ratePlan.selectableArray;
            roomTypeDataObj.ratePlans[ratePlan.ratePlanId].channelsMap.push(
              temp_default
            );
            roomTypeDataObj.ratePlans[ratePlan.ratePlanId].originalArray =
              ret_data;
          }
        });
      });
    });
  }

  formAggregateRows(data) {
    let temp_arr = {};
    data.forEach((val, index) => {
      let key = (index + 1).toString();
      temp_arr[key] = val.count;
    });
    return temp_arr;
  }

  mapAggregateRows(data, channelName) {
    let available = this.formAggregateRows(data["availableRoomCount"]);
    available.name = channelName;
    return available;
  }

  setInfoMap(rateMapItem) {
    let rateMap = rateMapItem.rateInfoMap;
    let test_arr = Array(Object.keys(rateMap[0].occupancyPricing).length + 2);
    let selectableArray = new Set();

    let requiredObj = {};
    rateMap.forEach((item, index) => {
      let objKey = (index + 1).toString();
      let count = -1;
      for (const [secondary_key, secondary_value] of Object.entries(
        item.occupancyPricing
      )) {
        count = count + 1;
        if (isUndefined(test_arr[count])) {
          test_arr[count] = {};
        }

        let sim_obj = Object.assign(test_arr[count]);
        if (isUndefined(sim_obj.name)) {
          sim_obj.name = `${count + 1}XAdults`;
          sim_obj.custom_index = count + 1;
        }
        sim_obj[objKey] = secondary_value;
        test_arr[count] = sim_obj;
        test_arr[count].selectable = true;
        if (count + 1 < rateMapItem.defaultOccupancy) {
          test_arr[count].selectable = false;
          selectableArray.add(`${count + 1}XAdults`);
        }
      }
      if (isUndefined(test_arr[test_arr.length - 2])) {
        test_arr[test_arr.length - 2] = {};
      }
      if (isUndefined(test_arr[test_arr.length - 1])) {
        test_arr[test_arr.length - 1] = {};
      }
      test_arr[test_arr.length - 2][objKey] = item.childPrice;
      test_arr[test_arr.length - 1][objKey] = item.childPrice1;
    });
    test_arr[
      test_arr.length - 2
    ].name = `Child Price (${rateMapItem.lChildAge}-${rateMapItem.rChildAge})`;
    test_arr[
      test_arr.length - 1
    ].name = `Child Prices (${rateMapItem.lChildAge1}-${rateMapItem.rChildAge1})`;
    test_arr[test_arr.length - 1].selectable = true;
    test_arr[test_arr.length - 2].selectable = true;
    rateMapItem.selectableArray = selectableArray;

    return test_arr;
  }
}
