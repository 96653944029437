import { isUndefined } from "lodash-es";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";

class Logout extends Component {
  componentDidMount() {
    sessionStorage.removeItem("propertyStep");
    sessionStorage.removeItem("accessStep");
    localStorage.removeItem("rootEmail");
    this.props.logout();
  }

  render() {
    const { token } = this.props;
    return token ? (
      <LayoutSplashScreen />
    ) : `${localStorage.getItem("token")}`.split("||")[1] === "master" ? (
      <Redirect to="/masterAdmin/login" />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}

export default connect(
  ({ auth }) => ({
    token: !isUndefined(auth.token) && !isUndefined(auth.hotels),
  }),
  auth.actions
)(Logout);
