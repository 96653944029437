import React from "react";
import Isha from "./Isha.png";

function IshaHeader() {
  return (
    <div className="ishaHeader">
      {" "}
      <img
        src={Isha}
        style={{ heigth: "8rem", width: "10rem", marginLeft: "2rem" }}
        alt="logo"
      />
    </div>
  );
}

export default IshaHeader;
