import React, { useEffect, useState } from "react";
import { ClickAwayListener } from "@mui/material";

function ClickAwayListenerHelper({
  openProp,
  content,
  btnClassName,
  clickAwayButtonName,
  right,
  left,
  bottom,
  top,
}) {
  const [open, setOpen] = useState(openProp);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  const handleClickAway = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleClickAway();
  }, [openProp]);

  return (
    <div className="clickAwayListenerWrapper">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="clickAwayContentWrapper">
          {btnClassName ? (
            <button
              type="button"
              className={btnClassName}
              onClick={handleClick}
            >
              {clickAwayButtonName}
            </button>
          ) : (
            <button type="button" className="clickAwayBtn">
              {clickAwayButtonName}
            </button>
          )}
          {open ? (
            <div
              className="clickAwayListenerContent"
              style={{ left: left, right: right, bottom: bottom, top: top }}
            >
              {content}
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default ClickAwayListenerHelper;
