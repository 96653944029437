import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import SearchableDropdown from "./SearchableDropdown";
import { TextField, Button } from "@mui/material";
import { toast } from "material-react-toastify";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import { shallowEqual, useSelector } from "react-redux";

function SearchModal({
  show,
  hide,
  allCompanyDetails,
  searchableValue,
  setsearchableValue,
  isFolio,
  bookingId,
  invoiceData,
  getUpdatedData,
}) {
  const [companyName, setcompanyName] = useState("");
  const [companyEmail, setcompanyEmail] = useState("");
  const [companyAddress, setcompanyAddress] = useState("");
  const [companyPhone, setcompanyPhone] = useState("");
  const [isDisabled, setisDisabled] = useState(false);

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isFolio === "folio") {
      const data = allCompanyDetails?.filter(
        (item) => item.gst_number === invoiceData?.company_gst_number
      );
      if (data.length >= 1) {
        setcompanyName(data[0]?.company_name);
        setcompanyEmail(data[0]?.company_email);
        setcompanyAddress(data[0]?.company_address);
        setcompanyPhone(data[0]?.company_phone);
        setsearchableValue(data[0]?.gst_number);
        setisDisabled(true);
      } else if (data.length === 0) {
        setcompanyName("");
        setcompanyEmail("");
        setcompanyAddress("");
        setcompanyPhone("");
        setisDisabled(false);
      }
    }
  }, [invoiceData, allCompanyDetails]);

  useEffect(() => {
    const data = allCompanyDetails?.filter(
      (item) => item.gst_number === searchableValue
    );

    if (data.length >= 1) {
      setcompanyName(data[0]?.company_name);
      setcompanyEmail(data[0]?.company_email);
      setcompanyAddress(data[0]?.company_address);
      setcompanyPhone(data[0]?.company_phone);
      setisDisabled(true);
    } else if (data.length === 0) {
      setcompanyName("");
      setcompanyEmail("");
      setcompanyAddress("");
      setcompanyPhone("");
      setisDisabled(false);
    }
  }, [searchableValue]);
  const setValues = () => {
    if (searchableValue === "") {
      toast.error("Please Enter Tax Identification Number");
    } else {
      const data = allCompanyDetails?.filter(
        (item) => item.gst_number === searchableValue
      );
      if (data.length >= 1) {
        if (isFolio === "folio") {
          handleSpringDataRequest(
            `core/api/v1/reservation/update-company-gst-number?bookingId=${bookingId}&companyGstNumber=${searchableValue}`
          )
            .then((res) => {
              toast.success("Successfully added");
              getUpdatedData();
            })
            .catch((err) => toast.error("Something went wrong"));
          hide();
        } else {
          hide();
        }
      } else {
        if (companyName === "") {
          toast.error("Please enter company name");
        } else {
          handleSpringDataMutationRequest(
            "POST",
            `core/api/v1/guest-company-details/create`,
            {
              hotel_id: hotel?.hotelId,
              gst_number: searchableValue === null ? 0 : searchableValue,
              company_name: companyName,
              company_email: companyEmail,
              company_address: companyAddress,
              company_phone: companyPhone,
            }
          )
            .then((res) => {
              toast.success("Successfully added");
              hide();
            })
            .catch((err) => toast.error("Something went wrong"));
          if (isFolio === "folio") {
            handleSpringDataRequest(
              `core/api/v1/reservation/update-company-gst-number?bookingId=${bookingId}&companyGstNumber=${searchableValue}`
            )
              .then((res) => hide())
              .catch((err) => toast.error("Something went wrong"));
          }
        }
      }
    }
  };

  return (
    <Modal
      open={show}
      onClose={hide}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="settleDuesModal"
        style={{ boxShadow: "0px 0px 25px 10px #0000001a", height: "30rem" }}
      >
        <div className="settleDuesWrapper">
          <div className="settleDuesHeader">
            <div className="settleDuesHeading">Add Company details</div>
            <div className="close" onClick={() => hide()}>
              X
            </div>
          </div>

          <div className="amountWrapper">
            <div className="d-flex flex-column">
              <div className="mt-4">
                <SearchableDropdown
                  options={allCompanyDetails && allCompanyDetails}
                  label="gst_number"
                  id="id"
                  selectedVal={searchableValue}
                  handleChange={(val) => setsearchableValue(val)}
                />
              </div>
              <div className="d-flex">
                <TextField
                  label="Company Name"
                  className="input-lg w-100 mt-4"
                  placeholder="Enter Company GST here"
                  onChange={(e) => setcompanyName(e.target.value)}
                  disabled={isDisabled}
                  value={companyName}
                  name="company_name"
                  id="company_name"
                  disabled={isDisabled}
                />
                <TextField
                  label="Company Phone"
                  className="input-lg w-100 mt-4 ml-3"
                  placeholder="Enter company phone here"
                  disabled={isDisabled}
                  onChange={(e) => setcompanyPhone(e.target.value)}
                  value={companyPhone}
                  name="company_phone"
                  id="company_phone"
                  disabled={isDisabled}
                />
              </div>
              <div>
                {" "}
                <TextField
                  label="Company Email"
                  className="input-lg w-100 mt-4"
                  placeholder="Enter Company Email here"
                  disabled={isDisabled}
                  onChange={(e) => setcompanyEmail(e.target.value)}
                  value={companyEmail}
                  name="company_email"
                  id="company_email"
                  disabled={isDisabled}
                />
              </div>
              <div className="d-flex">
                <TextField
                  label="Company Address"
                  className="input-lg w-100 mt-4"
                  placeholder="Enter Company Address here"
                  disabled={isDisabled}
                  onChange={(e) => setcompanyAddress(e.target.value)}
                  value={companyAddress}
                  name="company_Address"
                  id="company_Address"
                  disabled={isDisabled}
                />
              </div>
              <Button
                className="mt-4"
                variant="custom-button"
                onClick={() => setValues()}
              >
                Add Details
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default SearchModal;
