import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { djangoHandleDataMutationRequest } from "../../../api/index";
import noBooking from "./not_found.svg";
import MockLoaderTable from "./MockLoaderTable";
import { connect } from "react-redux";

function CashDepositsTable({
  cashDepositsData,
  bookingId,
  hotelId,
  updateInvoice,
  upDateViewManage,
  loggedUserEmail,
  updateSummaryData,
  data,
  resId,
}) {
  // getting the services data for the invoice

  const products = cashDepositsData;

  const columns = [
    {
      dataField: "log_timestamp",
      text: "Log Time",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_date",
      text: "Service Date",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "user_email",
      text: "Action By",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_description",
      text: "Description",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "approved",
      text: "Accounts payment status",
      sort: true,
      headerStyle: {
        width: "15%",
      },
      style: {
        wordBreak: "break-all",
        width: "15%",
      },
      editable: false,
      formatter: (cell) => {
        if (cell.toLowerCase() === "yes") {
          return "APPROVED";
        } else if (cell.toLowerCase() === "no") {
          return "REJECTED";
        } else {
          return cell;
        }
      },
      hidden:
        JSON.parse(
          data?.accessControl?.user_feature_map?.PaymentConfirmationFolio
        ).read !== "YES",
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_id",
      text: " ",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      formatter: (cell) => {
        return cashDepositsData?.filter((item) => item.service_id === cell)[0]
          ?.amount !== 0 ? (
          <div key={cell} className="d-flex">
            {JSON.parse(
              data?.accessControl?.user_feature_map?.CashDepositCharge
            ).read === "YES" && (
              <button
                className="btn viewManageCTABtns mr-2"
                onClick={() => handleChargeDepositOpen(cell)}
              >
                Charge
              </button>
            )}
            {JSON.parse(
              data?.accessControl?.user_feature_map?.CashDepositCharge
            ).read === "YES" && (
              <button
                className="btn viewManageCTABtns"
                onClick={() => handleVoidDepositOpen(cell)}
              >
                Void
              </button>
            )}
          </div>
        ) : (
          ""
        );
      },
      editable: false,
    },
  ];

  //charge void modal
  const [openCharge, setOpenCharge] = useState(false);
  const [serviceId, setServiceId] = useState();
  function handleChargeDepositOpen(val) {
    setOpenCharge(true);
    setServiceId(val);
  }
  const handleChargeDepositClose = () => setOpenCharge(false);

  //charge void states

  const [cashDepositChargeAmt, setCashDepositChargeAmt] = useState("");
  const [cashDepositChargeDate, setCashDepositChargeDate] = useState(
    new Date()
  );
  const [cashDepositChargeName, setCashDepositChargeName] = useState();

  useEffect(() => {
    const filteredData = cashDepositsData?.filter(
      (item) => item.service_id === serviceId
    )[0];
    setCashDepositChargeAmt(filteredData?.amount);
    setCashDepositChargeName(filteredData?.service_description);
  }, [serviceId]);

  // charge void modal loading
  const [openChargeLoading, setOpenChargeLoading] = useState(false);

  // charge void modal --- api
  function handleChargeDeposit() {
    setOpenChargeLoading(true);
    djangoHandleDataMutationRequest("POST", "invoice/chargeDeposit/", {
      hotelId: hotelId,
      bookingID: bookingId,
      reservation_id:
        resId !== undefined ? resId : `SFRES${bookingId?.slice(9)}`,
      deposit_service_id: serviceId,
      amount: Number(cashDepositChargeAmt),
      offline_card_date: moment(cashDepositChargeDate).format(
        "YYYY-MM-DD HH:mm:SS"
      ),
      service_amount_desc: cashDepositChargeName,
      logged_user_email: loggedUserEmail,
    })
      .then((res) => toast.success("Successfully charged the service"))
      .then((res) => upDateViewManage())
      .then((res) => setOpenChargeLoading(false))
      .then((res) => handleChargeDepositClose())
      .then((res) => setCashDepositChargeAmt())
      .then((res) => setCashDepositChargeDate(new Date()))
      .then((res) => setCashDepositChargeName())
      .catch((err) => setOpenChargeLoading(false))
      .catch((err) => handleChargeDepositClose())
      .catch((err) => setCashDepositChargeAmt())
      .catch((err) => setCashDepositChargeDate(new Date()))
      .catch((err) => setCashDepositChargeName())
      .catch((err) => toast.error("Ooops! Couldn't charge the service."));
  }

  // void modal
  const [openVoid, setOpenVoid] = useState(false);
  function handleVoidDepositOpen(val) {
    setOpenVoid(true);
    setServiceId(val);
  }
  const handleVoidDepositClose = () => setOpenVoid(false);

  // void states

  const [cashDepositVoidDate, setCashDepositVoidDate] = useState(new Date());
  const [cashDepositVoidReason, setCashDepositVoidReason] = useState();

  //  void modal loading
  const [openVoidLoading, setOpenVoidLoading] = useState(false);

  //  void modal --- api
  function handleVoidDeposit() {
    setOpenVoidLoading(true);
    djangoHandleDataMutationRequest("POST", "invoice/voidDeposit/", {
      hotelId: hotelId,
      booking_id: bookingId,
      payment_type: cashDepositsData?.filter(
        (item) => item.service_id === serviceId
      )[0]?.service_amount_type,
      service_id: serviceId.toString(),
      offline_card_refund_date: moment(cashDepositVoidDate).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      reservation_id: resId,
      refund_reason: cashDepositVoidReason,
      logged_user_email: loggedUserEmail,
    })
      .then((res) => toast.success("Successfully voided the service"))
      .then((res) => upDateViewManage())
      .then((res) => setOpenVoidLoading(false))
      .then((res) => handleVoidDepositClose())
      .then((res) => setServiceId())
      .then((res) => setCashDepositVoidDate(new Date()))
      .then((res) => setCashDepositVoidReason())
      .catch((error) => toast.error("Ooops! Couldn't void the service."))
      .catch((error) => setOpenVoidLoading(false))
      .catch((error) => handleVoidDepositClose())
      .catch((error) => setCashDepositVoidDate(new Date()))
      .catch((error) => setCashDepositVoidReason())
      .catch((error) => setServiceId());
  }
  if (cashDepositsData?.length === 0)
    return (
      <div
        className="d-flex flex-column align-items-center"
        style={{ marginTop: "9rem" }}
      >
        <img src={noBooking} alt="" />
        <p className="mt-3">No payments in this category</p>
      </div>
    );

  return (
    <>
      {products ? (
        <div className="invoiceSummTableWrapper">
          <ToolkitProvider
            keyField="service_id"
            data={products}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="folioTableWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bordered={false}
                    pagination={paginationFactory()}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <MockLoaderTable />
      )}

      {/* charge modal */}
      <Modal
        open={openCharge}
        onClose={handleChargeDepositClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Charge cash deposit</div>
              <div className="close" onClick={() => handleChargeDepositClose()}>
                X
              </div>
            </div>
            <div className="fieldWrapper mt-3">
              <TextField
                id="outlined-name"
                label="Amount description"
                name="amount_description"
                value={cashDepositChargeName}
                onChange={(e) => setCashDepositChargeName(e.target.value)}
                sx={{ width: "100%" }}
              />
            </div>

            <div className="fieldWrapper mt-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Charge date"
                  value={cashDepositChargeDate}
                  onChange={(newValue) => {
                    setCashDepositChargeDate(newValue);
                  }}
                  name="charge_date"
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="fieldWrapper mt-3">
              <TextField
                id="outlined-name"
                label="Amount"
                type={"text"}
                name="deposit_amount"
                value={cashDepositChargeAmt}
                onChange={(e) =>
                  /^[0-9.]*$/.test(e.target.value)
                    ? setCashDepositChargeAmt(e.target.value)
                    : setCashDepositChargeAmt(cashDepositChargeAmt)
                }
                sx={{ width: "100%" }}
              />
            </div>

            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleChargeDepositClose()}
              >
                Cancel
              </button>
              {openChargeLoading === false ? (
                <button
                  className="submit"
                  onClick={() => handleChargeDeposit()}
                  style={{ width: "150px" }}
                >
                  Charge
                </button>
              ) : (
                <button className="submit" style={{ width: "150px" }}>
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* void modal */}
      <Modal
        open={openVoid}
        onClose={handleVoidDepositClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Void cash deposit</div>
              <div className="close" onClick={() => handleVoidDepositClose()}>
                X
              </div>
            </div>

            <div className="fieldWrapper mt-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Void date"
                  value={cashDepositVoidDate}
                  onChange={(newValue) => {
                    setCashDepositVoidDate(newValue);
                  }}
                  name="charge_date"
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="fieldWrapper mt-3">
              <TextField
                id="outlined-name"
                label="Void reason"
                name="amount_description"
                value={cashDepositVoidReason}
                onChange={(e) => setCashDepositVoidReason(e.target.value)}
                sx={{ width: "100%" }}
              />
            </div>

            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleVoidDepositClose()}
              >
                Cancel
              </button>
              {openVoidLoading === false ? (
                <button
                  className="submit"
                  onClick={() => handleVoidDeposit()}
                  style={{ width: "150px" }}
                >
                  Void
                </button>
              ) : (
                <button className="submit" style={{ width: "150px" }}>
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(CashDepositsTable);
