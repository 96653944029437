import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Button } from "@mui/material";

function GroupDayLevelPricing({
  dayLevelPricindData,
  setdayLevelTotalTaxAmount,
  setdayLevelPreTaxAmount,
  setdayLevelTotalTax,
  setisDaylevelPricingSet,
  onCancelClick,
  currency,
}) {
  const columns = [
    {
      dataField: "date",
      text: "Date",
      editable: false,
      formatter: (cell, row) => {
        return cell.split(" ")[0];
      },
    },
    {
      dataField: "amountBeforeTax",
      text: `Amount Before Tax (${currency})`,
      formatter: (cell, row) => {
        return parseFloat(cell).toFixed(2);
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Price should be numeric",
          };
        }
        return true;
      },
      style: (cell, row, rowIndex, colIndex) => {
        return {
          fontWeight: "500",
          textDecoration: "underline",
        };
      },
    },
    {
      dataField: "tax",
      text: `Tax Amount (${currency})`,
      editable: false,
      formatter: (cell, row) => {
        return parseFloat(cell).toFixed(2);
      },
    },
    {
      dataField: "amountAfterTax",
      text: `Total Amount With Tax (${currency})`,
      formatter: (cell, row) => {
        return parseFloat(cell).toFixed(2);
      },
      style: (cell, row, rowIndex, colIndex) => {
        return {
          fontWeight: "500",
          textDecoration: "underline",
        };
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Price should be numeric",
          };
        }
        return true;
      },
    },
  ];

  const getDetailsAmount = () => {
    let totalTax = 0;
    let amountWithTax = 0;
    let amountBeforeTax = 0;

    const datas = dayLevelPricindData?.map((item) => {
      const rope = item?.perDayPrice.map((res) => {
        amountWithTax =
          parseFloat(amountWithTax) + parseFloat(res.amountAfterTax);
        totalTax = parseFloat(totalTax) + parseFloat(res.tax);
        amountBeforeTax =
          parseFloat(amountBeforeTax) + parseFloat(res.amountBeforeTax);
      });
    });

    setdayLevelTotalTaxAmount(parseFloat(amountWithTax));
    setdayLevelPreTaxAmount(parseFloat(amountBeforeTax));
    setdayLevelTotalTax(parseFloat(totalTax));
  };
  return (
    <div>
      {" "}
      <div
        style={{ marginLeft: "1rem", marginTop: "1rem", marginRight: "1rem" }}
      >
        {" "}
        {dayLevelPricindData?.length > 0 &&
          dayLevelPricindData?.map((item) => (
            <>
              <h3 className="mt-3 mb-3">
                {`${item.roomTypeName} - ${item.ratePlanName}`}
              </h3>
              <BootstrapTable
                bootstrap4
                keyField="timestamp"
                data={item?.perDayPrice}
                columns={columns}
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  afterSaveCell: (oldValue, newValue, row, column) => {
                    if (currency !== "INR") {
                      if (column?.dataField === "amountBeforeTax") {
                        row.amountBeforeTax = parseFloat(newValue);
                        row.tax = parseFloat(
                          (parseFloat(row.amountBeforeTax) *
                            parseFloat(row.taxPercent)) /
                            100
                        );
                        row.amountAfterTax = parseFloat(
                          parseFloat(row.amountBeforeTax) + parseFloat(row.tax)
                        );
                      }
                      if (column?.dataField === "amountAfterTax") {
                        row.amountAfterTax = parseFloat(newValue);
                        row.amountBeforeTax = parseFloat(
                          (parseFloat(row.amountAfterTax) * 100) /
                            (100 + parseFloat(row.taxPercent))
                        );

                        row.tax = parseFloat(
                          (parseFloat(row.amountBeforeTax) *
                            parseFloat(row.taxPercent)) /
                            100
                        );
                      }
                    } else {
                      if (column?.dataField === "amountBeforeTax") {
                        row.amountBeforeTax = parseFloat(newValue);
                        const taxPercent =
                          parseFloat(row.amountBeforeTax) <= 7500 ? 12 : 18;
                        row.tax = parseFloat(
                          (parseFloat(row.amountBeforeTax) *
                            parseFloat(taxPercent)) /
                            100
                        );
                        row.amountAfterTax = parseFloat(
                          parseFloat(row.amountBeforeTax) + parseFloat(row.tax)
                        );
                      }
                      if (column?.dataField === "amountAfterTax") {
                        row.amountAfterTax = parseFloat(newValue);
                        {
                        }
                        if (
                          parseFloat(row.amountAfterTax) >= 8400 &&
                          parseFloat(row.amountAfterTax) <= 8850
                        ) {
                        } else {
                          const taxPercent =
                            parseFloat(row.amountAfterTax) < 8400 ? 12 : 18;
                          row.amountBeforeTax = parseFloat(
                            (parseFloat(row.amountAfterTax) * 100) /
                              (100 + parseFloat(taxPercent))
                          );

                          row.tax = parseFloat(
                            (parseFloat(row.amountBeforeTax) *
                              parseFloat(taxPercent)) /
                              100
                          );
                        }
                      }
                      if (column?.dataField === "amountAfterTax") {
                        row.amountAfterTax = parseFloat(newValue);
                        if (
                          parseFloat(row.amountAfterTax) >= 8400 &&
                          parseFloat(row.amountAfterTax) <= 8850
                        ) {
                          row.amountBeforeTax = parseFloat(7500);

                          row.tax = parseFloat(
                            parseFloat(row.amountAfterTax) -
                              parseFloat(row.amountBeforeTax)
                          );
                        }
                      }
                    }
                  },
                })}
              />
            </>
          ))}
      </div>
      <div style={{ float: "right", marginRight: "2rem" }}>
        <Button
          variant="custom-button"
          onClick={() => {
            setisDaylevelPricingSet(false);
            onCancelClick();
          }}
          style={{ marginRight: "1rem" }}
        >
          Cancel
        </Button>
        <Button
          variant="custom-button"
          onClick={() => {
            getDetailsAmount();
            setisDaylevelPricingSet(true);
            onCancelClick();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default GroupDayLevelPricing;
