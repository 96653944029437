import React, { useEffect, useState } from "react";
// import { customerInfo, hotelInfo } from "./helpers/HotelCustomerInfo.json";
import ClickAwayListenerHelper from "../../../Utils/ClickAwayListenerHelper";
import * as BsIcons from "react-icons/bs";
// import InvoiceSummaryTable from "./InvoiceSummaryTable";
// import CustomerInformation from "./FolioUpdateOptions/CustomerInformation";
// import BookingInformation from "./FolioUpdateOptions/BookingInformation";
// import ModifyDates from "./FolioUpdateOptions/ModifyDates";
// import UpgradeRoom from "./FolioUpdateOptions/UpgradeRoom";
// import SplitRoom from "./FolioUpdateOptions/SplitRoom";
import "react-responsive-modal/styles.css";
import { djangoHandleDataRequests } from "../../../api/index";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import * as MdIcons from "react-icons/md";
import Stack from "@mui/material/Stack";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import moment from "moment";

function AdvancedPortfolio({ data, bookingId }) {
  const hotelId = data.hotelId;

  // advanced portfolio report search bar

  const { SearchBar } = Search;

  // advanced invoice data
  const [advancedInvoiceData, setAdvancedInvoiceData] = useState(null);
  const handleAdvancedInvoiceData = () => {
    djangoHandleDataRequests(
      `invoice/advancedInvoiceDataRender/?hotelId=${hotelId}&bookingID=${bookingId}`
    )
      .then((res) => setAdvancedInvoiceData(res))
      .catch((error) =>
        toast.error("Error fethcing the advanced invoice data.")
      );
  };

  useEffect(() => {
    handleAdvancedInvoiceData();
  }, [bookingId]);

  console.log("advanced invoice data : ", advancedInvoiceData);

  // populating the advanced invoice data table

  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (advancedInvoiceData) {
      setProducts(advancedInvoiceData.invoice.items);
    }
  }, [advancedInvoiceData]);

  const columns = [
    {
      dataField: "service_date",
      text: "Service date",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_desc",
      text: "Service desc",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_amount_type",
      text: "Service amount type",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_amount",
      text: "Service amount",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
    },
    {
      dataField: "service_tax",
      text: "Service tax",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_total",
      text: "Service total",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "service_date",
      order: "desc",
    },
    {
      dataField: "service_amount",
      order: "desc",
    },
    {
      dataField: "service_desc",
      order: "desc",
    },
    {
      dataField: "service_tax",
      order: "desc",
    },
    {
      dataField: "service_total",
      order: "desc",
    },
  ];

  // formatting the date
  const handleFormatDate = (date) => {
    let formatted_date = moment(date).format("MMMM d, YYYY hh:mm A");
    return formatted_date;
  };

  return (
    <div className="invoiceWrapper">
      <div className="hotelDetailsWrapper">
        <div className="hotelDetails">
          <div className="avatar">
            {advancedInvoiceData && advancedInvoiceData.hotel.hotel_name[0]}
          </div>
          <div className="basicInfo">
            {advancedInvoiceData && (
              <div className="hotelName">
                {advancedInvoiceData.hotel.hotel_name}
              </div>
            )}
            {advancedInvoiceData && (
              <div className="hotelAddress">
                {advancedInvoiceData.hotel.hotel_address}
              </div>
            )}
          </div>
        </div>
        <div className="folioInfo">
          <div className="folioId">Folio#{bookingId}</div>
          <div className="folioActionWrapper">
            <ClickAwayListenerHelper
              clickAwayButtonName={
                <Tooltip title="Folio actions">
                  <IconButton>
                    <BsIcons.BsThreeDots size="15px" color="black" />
                  </IconButton>
                </Tooltip>
              }
              btnClassName="folioActionBtn"
              content={
                <div className="folioActions">
                  <div className="item">Print Registration card</div>
                  <div className="item">Email booking confirmation</div>
                  <div className="item">Capture guest signature</div>
                  <div className="item">Hold booking</div>
                  <div className="item">Set booking no show</div>
                  <div className="item">Folio log</div>
                  <div className="item">Send magic link</div>
                  <div className="horizontalLine" />
                  <div className="item"> Tax exempt status </div>
                </div>
              }
              right="22.5px"
            />
          </div>
          <div className="cancelBooking">
            <Tooltip title="Cancel booking">
              <IconButton>
                <MdIcons.MdCancel size="25px" color="black" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="horizontalLine" />
      <div className="customerDetailsWrapper">
        <div className="custDetailHeader">Bill to : </div>
        {advancedInvoiceData && (
          <div className="nameSource">
            {advancedInvoiceData.invoice.bookingInfo.user_name} -{" "}
            {advancedInvoiceData.invoice.bookingInfo.booking_source_displayname}
          </div>
        )}
        <div className="customerDetail">
          <div className="details">
            <Stack spacing={2}>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Address</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {" "}
                      {
                        advancedInvoiceData.invoice.bookingInfo
                          .user_address_line1
                      }
                      ,{" "}
                      {
                        advancedInvoiceData.invoice.bookingInfo
                          .user_address_line2
                      }{" "}
                    </div>
                  )}
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Email</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {advancedInvoiceData.invoice.bookingInfo.user_email}
                    </div>
                  )}
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Phone</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {advancedInvoiceData.invoice.bookingInfo.user_contact}
                    </div>
                  )}
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Customer identification</div>
                  <div className="custSubVal">null</div>
                </Stack>
              </div>
            </Stack>
          </div>
          <div className="details">
            <Stack spacing={2}>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Checkin</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {handleFormatDate(
                        advancedInvoiceData.invoice.bookingInfo.booking_checkin
                      )}
                    </div>
                  )}
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Checkout</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {handleFormatDate(
                        advancedInvoiceData.invoice.bookingInfo.booking_checkout
                      )}
                    </div>
                  )}
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Actual checkin</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {handleFormatDate(
                        advancedInvoiceData.invoice.bookingInfo
                          .booking_actual_checkin
                      )}
                    </div>
                  )}
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Actual checkout</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {handleFormatDate(
                        advancedInvoiceData.invoice.bookingInfo
                          .booking_actual_checkout
                      )}
                    </div>
                  )}
                </Stack>
              </div>
            </Stack>
          </div>

          <div className="details">
            <Stack spacing={2}>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Room ID</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {advancedInvoiceData.invoice.bookingInfo.room_id_list}
                    </div>
                  )}
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Nights</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {advancedInvoiceData.invoice.bookingInfo.no_of_days}
                    </div>
                  )}
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Adults</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {advancedInvoiceData.invoice.bookingInfo.adults}
                    </div>
                  )}
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  <div className="custSubHeading">Children</div>
                  {advancedInvoiceData && (
                    <div className="custSubVal">
                      {advancedInvoiceData.invoice.bookingInfo.children}
                    </div>
                  )}
                </Stack>
              </div>
            </Stack>
          </div>
        </div>
        <div className="horizontalLine" />
        {advancedInvoiceData && (
          <div className="invoiceTableActions">
            <div className="invoiceSummTableWrapper">
              <ToolkitProvider
                keyField="service_id"
                data={products}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <div className="filtersWrapper">
                      <div className="searchBar">
                        <SearchBar
                          {...props.searchProps}
                          className="folioActionSearchBar"
                        />
                      </div>
                      <div className="actionsWrapper">
                        <div className="removeButton">Remove</div>
                        <div className="otherActions">
                          {/* <ClickAwayListenerHelper
                                    clickAwayButtonName={
                                    <BsIcons.BsThreeDots size="15px" color="black" />
                                    }
                                    btnClassName="otherActionsButton"
                                    content={
                                    <div className="tableActions">
                                        <div
                                        className="addNewService"
                                        onClick={onClickAddServiceBtn}
                                        >
                                        {" "}
                                        Add New Service{" "}
                                        </div>
                                        <div className="createPOSOrder">
                                        {" "}
                                        Create POS Order{" "}
                                        </div>
                                    </div>
                                    }
                                    right="15px"
                                /> */}
                        </div>
                      </div>
                    </div>
                    <div className="servicesTable">
                      <BootstrapTable
                        {...props.baseProps}
                        // keyField="service_id"
                        tabIndexCell
                        headerWrapperClasses="foo"
                        bodyClasses="tableBody"
                        bordered={false}
                        // selectRow={selectRow}
                        pagination={paginationFactory()}
                        // cellEdit={ cellEditFactory({
                        //     mode: 'click',
                        //     blurToSave: true,
                        //     afterSaveCell: (oldValue, newValue, row, column) => {
                        //         handleEditServiceAmt(
                        //             oldValue,
                        //             row.service_total
                        //             )
                        //         },
                        //     })}
                        // rowStyle = {rowStyle}
                      />
                    </div>
                  </>
                )}
              </ToolkitProvider>
            </div>
          </div>
        )}
      </div>
    </div>
    // <div className="advancePortfolioWrapper">
    //     <div className = "advFolioActionsWrapper">
    //         <div className="folioPage">
    //             {/* <Link to="/folios"> */}
    //             <IoIcons.IoIosArrowBack size="35" color="black" onClick={onClose} style={{cursor:'pointer'}} />
    //             {/* </Link> */}
    //         </div>
    //         <ClickAwayListenerHelper
    //         btnClassName = "advFolioActionButton"
    //         clickAwayButtonName = "Folio Actions"
    //         content = {
    //             <div className="advFolioItems">
    //                 <div className="advFolioItem"> Print Folio </div>
    //                 <div className="advFolioItem"> Bulk Update Price </div>
    //                 <div className="advFolioItem"> Load Custom Folio </div>
    //             </div>
    //         }
    //         right = "42px"
    //         />
    //     </div>
    //     {/* advanced folio report */}
    //     <div className="advancedFolioReport">
    //         <div className="folioId">Folio #SFBOOKING_12570_12353</div>
    //         <div className="hotelCustInfoWrapper">
    //             <div className="hotelInfo">
    //                 <div className="hotelName">From:  {hotelInfo[0].hotelName}</div>
    //                 <div className="sep" />
    //                 <div className="hotelAdd">{hotelInfo[0].address}</div>
    //                 <div className="hotelEmail">{hotelInfo[0].email}</div>
    //                 <div className="hotelContact">{hotelInfo[0].contact}</div>
    //             </div>
    //             <div className="customerInfo">
    //                 <div className="custName">To:  {customerInfo[0].name}</div>
    //                 <div className="sep" />
    //                 <div className="custAdd">{customerInfo[0].customer_address}</div>
    //                 <div className="custEmail">{customerInfo[0].email}</div>
    //                 <div className="custContact">{customerInfo[0].customer_contact}</div>
    //                 <div className="dates">
    //                     <div className="checkIn">
    //                         <div className="headerDates">CheckIn Date</div>
    //                         <div className="date">Mon, 31 May 2021</div>
    //                     </div>
    //                     <div className="checkOut">
    //                         <div className="headerDates">CheckOut Date</div>
    //                         <div className="date">Tue, 1 June 2021</div>
    //                     </div>
    //                 </div>
    //                 <div className="travelInfo">
    //                     <div className="nights">
    //                         <div className="headerNight">Nights</div>
    //                         <div className="val">1</div>
    //                     </div>
    //                     <div className="adults">
    //                         <div className="headerAdults">Adults</div>
    //                         <div className="val">1</div>
    //                     </div>
    //                     <div className="children">
    //                         <div className="headerChildren">Children</div>
    //                         <div className="val">1</div>
    //                     </div>
    //                 </div>
    //                 <div className="roomAlloted">
    //                     <div className="headerRoom">Room No.(s)</div>
    //                     <div className="ids"> 21 </div>
    //                 </div>
    //             </div>

    //         </div>

    //         {/* advacned folio table */}
    //         <div className="advFolioTable">
    //             <ToolkitProvider
    //             keyField='id'
    //             data = {products}
    //             columns = {columns}
    //             search
    //             >
    //             {
    //                 props => (
    //                     <>
    //                     <div className="actions">
    //                         <SearchBar { ...props.searchProps}  className="invoiceSearch" />
    //                     </div>
    //                     {/* <ClearSearchButton { ...props.searchProps} /> */}
    //                     <BootstrapTable
    //                     {...props.baseProps}
    //                     tabIndexCell
    //                     headerWrapperClasses="foo"
    //                     bodyClasses="tableBody"
    //                     bordered = {false}
    //                     pagination = {paginationFactory()}
    //                     defaultSorted = {defaultSorted}
    //                     />
    //                     </>
    //                 )
    //             }
    //             </ToolkitProvider>
    //         </div>
    //     </div>
    // </div>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(AdvancedPortfolio);
