export default function setupAxios(axios, store) {
  const Spring = `${process.env.REACT_APP_SPRING_API_URL}`
  const Django = `${process.env.REACT_APP_API_URL}`

  axios.interceptors.request.use(
    (config) => {
      let data = store.getState()
      const { auth: { token, selectedHotel } } = store.getState();
      const isDjango = config.url.split("/")[0] === Django
      if (token) {
        config.headers.Authorization = token;
      }
      if (selectedHotel) {
        if (!config.params) { config.params = {} }


        config.params.hotel_id = selectedHotel.hotelId

        config.params.hotelId = selectedHotel.hotelId

      }
      return config;
    },
    err => Promise.reject(err)
  );
}
