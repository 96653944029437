import { Alert, Box, Typography } from "@mui/material";
import React from "react";
import "./freezeStyle.css";
import { useState } from "react";
import Currencies from "../Utils/Currencies.json";
import { useEffect } from "react";
import { getAllImagesAndUrl } from "../pages/BookingDashboard/apis/BrandingModalApi";


function FreezeExpence({ menu }) {
  //------------------Currency Symbol--------------------
  const [currency, setCurrency] = useState("");
  useEffect(() => {
    let currencySymbol = "";
    const res = Currencies?.map((item) => {
      if (item.code === localStorage.getItem("hotelCurrency")) {
        currencySymbol = item.symbol;
      }
    });
    setCurrency(currencySymbol);
  }, []);


  //------------whitelabelled------------------------------
  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    userBrandingSatus();
  }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: 1,
      }}
    >
      <Box className="freezebody">
        <div class="lock"></div>
        <div class="message">
          <Typography align="center" sx={{ mt: 1, fontSize: "1.6rem" }}>
            {menu}
          </Typography>
          <Typography align="center" sx={{ mt: 1, fontSize: "1.4rem" }}>
            Unlock {menu} for {currency}
            {menu === "Expense Manager"
              ? currency === "Rs."
                ? "999 per month. "
                : "49 per month. "
              : currency === "Rs."
              ? "1999 per month. "
              : "99 per month. "}
            Contact{" "}
            {whiteLabelled === "DISABLED" && (
              <a href="mailto:support@stayflexi.com" style={{ color: "white" }}>
                support@stayflexi.com
              </a>
            )}
            {whiteLabelled === "DISABLED" && " or "}
            your account manager.

          </Typography>
        </div>
      </Box>
    </Box>
  );
}

export default FreezeExpence;
