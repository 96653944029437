import React from "react";
import Currencies from "../../../Utils/Currencies.json";
import { Link } from "react-router-dom";
import moment from "moment";

//--------Currency symbol-----------
let currencySymbol = "";
const res = Currencies?.map((item) => {
  if (item.code === localStorage.getItem("hotelCurrency")) {
    currencySymbol = item.symbol;
  }
});

// getting the hotel id from the params
const url = new URL(window.location.href);
const hotelId = url.searchParams.get("hotelId");

const cellRenderer = (param) => {
  return (
    <Link
      to={{
        pathname: `/folio/${param?.value}`,
        search: `?hotelId=${hotelId}`,
      }}
      target="_blank"
    >
      {param?.value?.substring(param.value.lastIndexOf("_") + 1)}
    </Link>
  );
};
//date formatting---------------

// const timeStampToDate = (timestamp) => {
//   if (!timestamp) {
//     return "";
//   }
//   const date = new Date(timestamp);
//   return moment(date).format("MMM DD, YYYY");
// };

const paymentDateManagement = (dateString) => {
  if (!dateString) {
    return "";
  }
  var dateComponents = dateString.split("-");
  if (dateComponents?.length < 3) {
    return dateString;
  }
  var year = parseInt(dateComponents[2], 10);
  var month = parseInt(dateComponents[1], 10) - 1;
  var day = parseInt(dateComponents[0], 10);

  var dateObject = new Date(year, month, day);
  return moment(dateObject).format("MMM DD, YYYY");
};

// export const OnlinePaymentColumns = [
//   {
//     field: "pm_timestamp",
//     headerName: "Date",
//     valueGetter: (params) => {
//       return paymentDateManagement(params?.data?.pm_timestamp);
//     },
//     sortable: true,
//   },
//   {
//     field: "pm_amount",
//     headerName: `Amount (${currencySymbol})`,
//     sortable: true,
//   },
//   {
//     field: "pm_bookingid",
//     headerName: "Booking id",
//     sortable: true,
//     cellRendererFramework: (params) => cellRenderer(params),
//   },
//   // {
//   //   field: "pm_payment_type",
//   //   headerName: "Guest name",
//   //   sortable: true,
//   // },
//   {
//     field: "pm_payment_type",
//     headerName: "Payment type",
//     sortable: true,
//   },

//   {
//     field: "pm_gateway_name",
//     headerName: "Gateway name",
//     sortable: true,
//   },

//   {
//     field: "id",
//     headerName: "Payment gateway id",
//     sortable: true,
//   },
// ];
// export const OfflinePaymentColumns = [
//   {
//     field: "service_date",
//     headerName: "Date",
//     valueGetter: (params) => {
//       return timeStampToDate(params?.data?.service_date);
//     },
//     sortable: true,
//   },
//   {
//     field: "service_amount",
//     headerName: `Amount (${currencySymbol})`,
//     valueGetter: (params) => {
//       return Math.abs(params?.data?.service_amount)?.toFixed(2);
//     },
//     sortable: true,
//   },
//   {
//     field: "booking_id",
//     headerName: "Booking id",
//     sortable: true,
//     cellRendererFramework: (params) => cellRenderer(params),
//   },
//   // {
//   //   field: "pm_payment_type",
//   //   headerName: "Guest name",
//   //   sortable: true,
//   // },
//   {
//     field: "service_amount_type",
//     headerName: "Payment type",
//     sortable: true,
//   },

//   // {
//   //   field: "pm_gateway_name",
//   //   headerName: "Gateway name",
//   //   sortable: true,
//   // },

//   {
//     field: "id",
//     headerName: "Payment gateway id",
//     sortable: true,
//   },
// ];

// valueGetter: (params) => {
//   return params?.data?.amount?.toFixed(2);
// }

export const PaymentColumns = [
  {
    field: "date",
    headerName: "Date",
    valueGetter: (params) => {
      return paymentDateManagement(params?.data?.date);
    },
    sortable: true,
  },
  {
    field: "amount",
    headerName: `Amount (${currencySymbol})`,
    sortable: true,
    valueGetter: (params) => {
      return params?.data?.amount?.toFixed(2);
    },
  },
  {
    field: "bookingId",
    headerName: "Booking id",
    sortable: true,
    cellRendererFramework: (params) => cellRenderer(params),
  },
  {
    field: "guestName",
    headerName: "Guest name",
    sortable: true,
  },
  {
    field: "paymentType",
    headerName: "Payment type",
    sortable: true,
  },

  {
    field: "pgName",
    headerName: "Gateway name",
    sortable: true,
  },

  {
    field: "pgId",
    headerName: "Payment gateway id",
    sortable: true,
  },
  {
    field: "moduleSource",
    headerName: "Source",
    sortable: true,
  },
];

export const RefundPaymentColumns = [
  {
    field: "date",
    headerName: "Date",
    valueGetter: (params) => {
      return paymentDateManagement(params?.data?.date);
    },
    sortable: true,
  },
  {
    field: "amount",
    headerName: `Refund amount (${currencySymbol})`,
    sortable: true,
    valueGetter: (params) => {
      return params?.data?.amount?.toFixed(2);
    },
  },
  {
    field: "bookingId",
    headerName: "Booking id",
    sortable: true,
    cellRendererFramework: (params) => cellRenderer(params),
  },

  {
    field: "guestName",
    headerName: "Guest name",
    sortable: true,
  },
  {
    field: "paymentType",
    headerName: "Payment type",
    sortable: true,
  },

  {
    field: "pgName",
    headerName: "Gateway name",
    sortable: true,
  },

  {
    field: "pgId",
    headerName: "Payment gateway id",
    sortable: true,
  },
  {
    field: "moduleSource",
    headerName: "Source",
    sortable: true,
  },
];

export const TransfersPaymentColumns = [
  {
    field: "date",
    headerName: "Date",
    valueGetter: (params) => {
      return paymentDateManagement(params?.data?.date);
    },
    sortable: true,
  },
  {
    field: "amount",
    headerName: `Transfer amount (${currencySymbol})`,
    sortable: true,
    valueGetter: (params) => {
      return params?.data?.amount?.toFixed(2);
    },
  },
  {
    field: "paymentAmount",
    headerName: `Payment amount (${currencySymbol})`,
    sortable: true,
    valueGetter: (params) => {
      return params?.data?.paymentAmount?.toFixed(2);
    },
  },
  {
    field: "bookingId",
    headerName: "Booking id",
    sortable: true,
    cellRendererFramework: (params) => cellRenderer(params),
  },
  {
    field: "guestName",
    headerName: "Guest name",
    sortable: true,
  },
  {
    field: "pgName",
    headerName: "Gateway name",
    sortable: true,
  },

  {
    field: "pgId",
    headerName: "Gateway id",
    sortable: true,
  },
  {
    field: "moduleSource",
    headerName: "Source",
    sortable: true,
  },
];

export const ReversalPaymentColumns = [
  {
    field: "date",
    headerName: "Date",
    valueGetter: (params) => {
      return paymentDateManagement(params?.data?.date);
    },
    sortable: true,
  },
  {
    field: "amount",
    headerName: `Reversed amount (${currencySymbol})`,
    sortable: true,
    valueGetter: (params) => {
      return params?.data?.amount?.toFixed(2);
    },
  },
  {
    field: "paymentAmount",
    headerName: `Payment amount (${currencySymbol})`,
    sortable: true,
    valueGetter: (params) => {
      return params?.data?.paymentAmount?.toFixed(2);
    },
  },

  {
    field: "bookingId",
    headerName: "Booking id",
    sortable: true,
    cellRendererFramework: (params) => cellRenderer(params),
  },

  {
    field: "guestName",
    headerName: "Guest name",
    sortable: true,
  },
  {
    field: "pgName",
    headerName: "Gateway name",
    sortable: true,
  },

  {
    field: "pgId",
    headerName: "Gateway id",
    sortable: true,
  },
  {
    field: "moduleSource",
    headerName: "Source",
    sortable: true,
  },
];
