import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";

export const ButtonHelper = (width, content, onClick, onSubmit, onChange) => (
  <button
    className="buttonHelper"
    style={{ width: width }}
    onClick={onClick}
    onSubmit={onSubmit}
    onChange={onChange}
  >
    {content}
  </button>
);

export const LoadingButtonHelper = (
  width,
  content,
  onClick,
  onSubmit,
  onChange
) => (
  <LoadingButton
    loading
    className="buttonHelper"
    style={{ width: width }}
    onClick={onClick}
    onSubmit={onSubmit}
    onChange={onChange}
  >
    {content}
  </LoadingButton>
);

export const OutlinedButton = ({
  onClick,
  onFocus,
  onChange,
  text,
  width,
  height,
  disabled,
  key,
  padding,
  fontSize,
}) => (
  <Button
    key={key}
    className="rounded pl-2 pr-2 btn btn-outline-dark"
    onClick={onClick}
    onChange={onChange}
    disabled={disabled}
    style={{
      border: "2.5px solid black",
      width: width,
      textTransform: "none",
      height: height,
      padding: padding,
      fontSize: fontSize,
    }}
  >
    {text}
  </Button>
);

export const SecondaryButton = ({
  onClick,
  onFocus,
  onChange,
  text,
  width,
  height,
  disabled = false,
}) => (
  <Button
    className="rounded pl-2 pr-2 border border-0"
    style={{
      backgroundColor: "#EBEBEB",
      width: width,
      height: height,
      textTransform: "none",
      color: disabled === false ? "black" : "grey",
    }}
    onClick={onClick}
    onChange={onChange}
    disabled={disabled}
  >
    {text}
  </Button>
);
