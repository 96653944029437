import React, {useState} from "react";
import NewLogin from "./NewLogin";
import EmailSuccess from "./NewEmailSuccessPage";


const LoginComponent = () => {

  const [emailVerified, setEmailVerified] = useState(false)
  const [mailId, setMailId] = useState("")

  const verifiedMail = (emailId) => {
    setMailId(emailId)
  }
  const setEmailFalse = () => {
    setEmailVerified(false)
  }

  const setEmailTrue = () => {
    setEmailVerified(true)
  }

  return (
    <div>
      {!emailVerified && <NewLogin loginTrue={setEmailTrue} mailId={mailId} setMail={verifiedMail} />}
      {emailVerified && <EmailSuccess loginTrue={setEmailFalse} setMail={verifiedMail} mailId={mailId} ></EmailSuccess>}
    </div>

  )
}

export default LoginComponent