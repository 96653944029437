import React from "react";

function MockLoaderTable() {
    return (
      <div className={`mockLoaderTable d-flex flex-column p-3 justify-content-between box-shadow-main`}>
        <div className="d-flex w-100 align-items-center justify-content-between mt-4">
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between mt-5">
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between mt-5">
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between mt-5">
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
          <div className="box w-auto p-2" style={{minHeight: '2rem'}}>{' '}</div>
        </div>
      </div>
    );
  }
  
  export default MockLoaderTable;
  