import React, { forwardRef, useEffect, useState } from "react";
import { getUnassignedReservations } from "../api/ReservationApi";
import { InventoryDateformat } from "../../InventoryCalendar/constants/InventoryConstants";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close, HorizontalRule } from "@mui/icons-material";
import moment from "moment";
import {
  channelLogoMap,
  channelLogoMapDash,
  dateFormat,
} from "../constants/Calendar-constants";
import ReassignView from "./ReassignView";
import ReleaseBooking from "./ReleaseBookingModal";
import { Link } from "react-router-dom";

const UnassignedView = (props, ref) => {
  const { date, closeFunc, data, reload } = props;
  const [unassigned, setUnassigned] = useState([]);
  const [showReassign, setShowReassign] = useState(false);
  const [showRelease, setShowRelease] = useState(false);
  const [eventData, setEventData] = useState({});
  const [cancelRes, setCancelRes] = useState(false);
  const [unAssignedCount, setunAssignedCount] = useState(0);
  const [onHoldBookingCount, setonHoldBookingCount] = useState(0);
  const [enquiriesCount, setenquiriesCount] = useState(0);
  const [stateSelected, setstateSelected] = useState("UNASSIGNED");
  const [showOptions, setShowOptions] = useState([
    { title: "Unassigned", id: "unassign" },
    { title: "Hold", id: "hold" },
  ]);
  const [unassignedCopy, setUnassignedCopy] = useState([]);
  const [searchTerm, setsearchTerm] = useState("");

  useEffect(() => {
    const unAssignedData = unassignedCopy?.filter(
      (item) =>
        ((item.reservationStatus === "CONFIRMED" ||
          item.reservationStatus === "ADMIN_CONFIRMED" ||
          item.reservationStatus === "DONE") &&
          item?.physicalRoomId === "UNASSIGNED") ||
        (item.reservationStatus !== "ON_HOLD" && item?.enquiry === "false")
    );
    setunAssignedCount(unAssignedData);
    const onHolddata = unassignedCopy?.filter(
      (item) =>
        item.reservationStatus === "ON_HOLD" && item?.enquiry === "false"
    );
    setonHoldBookingCount(onHolddata);

    const enquiries = unassignedCopy?.filter(
      (item) => item.reservationStatus === "ON_HOLD" && item?.enquiry === "true"
    );
    setenquiriesCount(enquiries);
  }, [unassignedCopy]);

  useEffect(() => {
    if (stateSelected === "UNASSIGNED") {
      const data = unassignedCopy?.filter(
        (item) =>
          ((item.reservationStatus === "CONFIRMED" ||
            item.reservationStatus === "ADMIN_CONFIRMED" ||
            item.reservationStatus === "DONE") &&
            item?.physicalRoomId === "UNASSIGNED") ||
          (item.reservationStatus !== "ON_HOLD" && item?.enquiry === "false")
      );
      setUnassigned(data);
    } else if (stateSelected === "ONHOLD BOOKING") {
      const data = unassignedCopy?.filter(
        (item) =>
          item.reservationStatus === "ON_HOLD" && item?.enquiry === "false"
      );

      setUnassigned(data);
    } else {
      const data = unassignedCopy?.filter(
        (item) =>
          item.reservationStatus === "ON_HOLD" && item?.enquiry === "true"
      );

      setUnassigned(data);
    }
  }, [unassignedCopy, stateSelected]);

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   if (unassigned.length > 0) {
  //     const containerEl = document.getElementById("external-events");
  //     const draggable = new Draggable(containerEl, {
  //       itemSelector: ".fc-event",
  //       eventData: function(eventEl) {
  //         const eventData = JSON.parse(eventEl.getAttribute("mydata"));
  //         return {
  //           title: eventData.userName,
  //           create: true,
  //           editable: true,
  //           resourceEditable: true,
  //           actual_start: eventData.displayCheckin,
  //           actual_end: eventData.displayCheckout,
  //           start: moment(eventData.displayCheckin, dateFormat).toISOString(),
  //           end: moment(eventData.displayCheckout, dateFormat).toISOString(),
  //           bookingSource: eventData.source,
  //           bookingId: eventData.bookingId,
  //           reservationId: eventData.id,
  //           roomTypeName: eventData.type,
  //           ratePlanName: eventData.ratePlanName,
  //         };
  //       },
  //     });
  //     return () => draggable.destroy();
  //   }
  // }, [unassigned]);

  const getData = async () => {
    setUnassigned([]);
    setUnassignedCopy([]);
    const resp = await getUnassignedReservations({
      startDate: moment(date.start).format(InventoryDateformat),
      endDate: moment(date.end).format(InventoryDateformat),
    });

    // setUnassigned(resp);
    setUnassignedCopy(resp);
  };

  return (
    <Grid container sx={{ width: "100%" }} ref={ref}>
      <Card variant="outlined" sx={{ width: "100%" }}>
        <CardHeader
          title="Unassigned and Hold reservations"
          action={
            <IconButton onClick={closeFunc}>
              <Close></Close>
            </IconButton>
          }
        >
          {" "}
        </CardHeader>
        <CardContent>
          <ButtonGroup
            size="secondary"
            aria-label="large button group"
            className="mb-3"
          >
            <Button
              key="one"
              onClick={(e) => {
                if (stateSelected !== "UNASSIGNED") {
                  setUnassigned([]);
                }
                setstateSelected("UNASSIGNED");
              }}
              variant={
                stateSelected === "UNASSIGNED" ? "custom-button" : "outlined"
              }
            >
              {`Unassigned Bookings (${unAssignedCount?.length})`}
            </Button>
            <Button
              key="Two"
              onClick={(e) => {
                if (stateSelected !== "ONHOLD BOOKING") {
                  setUnassigned([]);
                }
                setstateSelected("ONHOLD BOOKING");
              }}
              variant={
                stateSelected === "ONHOLD BOOKING"
                  ? "custom-button"
                  : "outlined"
              }
            >
              {`On-hold Bookings (${onHoldBookingCount?.length})`}
            </Button>
            <Button
              key="THREE"
              onClick={(e) => {
                if (stateSelected !== "ENQUIRY ONHOLD") {
                  setUnassigned([]);
                }
                setstateSelected("ENQUIRY ONHOLD");
              }}
              variant={
                stateSelected === "ENQUIRY ONHOLD"
                  ? "custom-button"
                  : "outlined"
              }
            >
              {`Enquiries (${enquiriesCount?.length})`}
            </Button>
          </ButtonGroup>
          {/* <div style={{ marginBottom: "16px" }}>
            {[
              { title: "Unassigned", id: "unassign" },
              { title: "Hold", id: "hold" },
            ].map((opt) => (
              <Chip
                sx={{ mr: 1 }}
                key={opt.id}
                color={
                  showOptions.findIndex((op) => op.id === opt.id) > -1
                    ? "primary"
                    : "default"
                }
                label={opt.title}
                onClick={() => {
                  let arr = showOptions;

                  if (showOptions.findIndex((op) => op.id === opt.id) === -1)
                    arr.push(opt);
                  else arr = arr.filter((ele) => ele.id !== opt.id);

                  const temp_arr = [];
                  arr.forEach((option) => {
                    if (option.id === "unassign")
                      temp_arr.push(
                        ...unassigned.filter(
                          (reservation) =>
                            reservation.reservationStatus !== "ON_HOLD"
                        )
                      );
                    if (option.id === "hold")
                      temp_arr.push(
                        ...unassigned.filter(
                          (reservation) =>
                            reservation.reservationStatus === "ON_HOLD"
                        )
                      );
                  });
                  setShowOptions(arr);
                  setUnassignedCopy(temp_arr);
                }}
              ></Chip>
            ))}
          </div> */}
          <div id="external-events">
            <TextField
              onChange={(e) => setsearchTerm(e.target.value)}
              placeholder="Search by email"
              label="Search by email, name, phone and bookingId"
              // className="w-75"
              notched={false}
              type="search"
              size="small"
              style={{
                borderRadius: "5px",
                width: "100%",
                marginBottom: "2rem",
                marginTop: "17px",
              }}
            />
            {unassigned?.filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.bookingId.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (
                val.userName.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (
                val.userEmail.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (
                val.userPhone.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              }
            })?.length > 0 &&
              unassigned
                ?.filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.bookingId
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.userName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.userEmail
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.userPhone
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((resrv, index) => (
                  <Card
                    key={index}
                    variant="outlined"
                    sx={{ mb: 1 }}
                    className="fc-event"
                  >
                    <CardHeader
                      sx={{ pb: 1 }}
                      title={resrv?.userName}
                      action={
                        <Grid className="d-flex align-items-center">
                          {channelLogoMap[resrv?.source]?.includes(".png") && (
                            <img
                              src={channelLogoMap[resrv?.source]}
                              style={{ height: "2rem" }}
                            />
                          )}
                          {/* {resrv?.reservationStatus === "ON_HOLD" && (
                        <Button
                          onClick={() => {
                            setEventData(resrv);
                            setCancelRes(true);
                            setShowRelease(true);
                          }}
                          color="error"
                        >
                          Void
                        </Button>
                      )} */}
                          <Button
                            onClick={() => {
                              setEventData(resrv);
                              // if (resrv.reservationStatus !== "ON_HOLD")
                              setShowReassign(true);
                              // else {
                              //   setCancelRes(false);
                              //   setShowRelease(true);
                              // }
                            }}
                          >
                            Assign
                            {/* {resrv.reservationStatus === "ON_HOLD"
                          ? "Confirm"
                          : "assign"} */}
                          </Button>
                        </Grid>
                      }
                    ></CardHeader>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item container>
                          <div className="d-flex flex-column">
                            {/* {channelLogoMap[resrv?.source].includes(".png") && (
                          <img
                            src={channelLogoMap[resrv?.source]}
                            style={{ height: "2.5rem", width: "4rem" }}
                          />
                        )} */}
                            {/* <Typography>{`${resrv.reservationStatus}`}</Typography> */}
                            <Typography
                              style={{
                                color:
                                  resrv.reservationStatus === "ON_HOLD"
                                    ? "#F7D59C"
                                    : "#96C7C1",

                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {/* {resrv?.physicalRoomId === "UNASSIGNED"
                                ? "UNASSIGNED" */}
                              {/* : */}
                              {resrv.reservationStatus === "ADMIN_CONFIRMED"
                                ? "CHECKED-IN"
                                : resrv.reservationStatus}
                            </Typography>
                            <Typography>
                              <Link
                                to={{
                                  pathname: `/folio/${resrv.bookingId}`,
                                  search: `?hotelId=${
                                    resrv.bookingId.split("_")[1]
                                  }`,
                                }}
                              >{`${resrv.bookingId}`}</Link>
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item container>
                          <Typography>{`${moment(
                            resrv.displayCheckin,
                            dateFormat
                          ).format("MMM Do, YYYY h A")}`}</Typography>
                          <HorizontalRule
                            sx={{ ml: 1, mr: 1 }}
                            htmlColor="silver"
                          />
                          <Typography>{`${moment(
                            resrv.displayCheckout,
                            dateFormat
                          ).format("MMM Do, YYYY h A")}`}</Typography>
                        </Grid>
                        {resrv.reservationStatus !== "ON_HOLD" && (
                          <Grid item container>
                            <Typography>{`${resrv.ratePlanName}`}</Typography>
                            <HorizontalRule
                              sx={{ ml: 1, mr: 1 }}
                              htmlColor="silver"
                            />
                            <Typography>{`${resrv.type}`}</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
          </div>
        </CardContent>
      </Card>
      <div>
        {showReassign && (
          <ReassignView
            open={showReassign}
            handleAddEventSidebar={() => {
              setShowReassign(false);
              setEventData({});
            }}
            getData={getData}
            eventData={eventData}
            refetch={() => {
              reload();
              setShowReassign(false);
              setEventData({});
            }}
          />
        )}
        {showRelease && (
          <ReleaseBooking
            open={showRelease}
            closeModal={() => {
              setShowRelease(false);
              setEventData({});
            }}
            option={!cancelRes ? "hold" : "void"}
            reservationData={eventData}
            clickFunc={() => {
              reload();
              setShowRelease(false);
              setEventData({});
            }}
          />
        )}
      </div>
    </Grid>
  );
};

export default forwardRef(UnassignedView);
