import React, {useMemo, useState} from 'react'
import TextField from '@mui/material/TextField';
import {useFormik} from 'formik';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import countryList from 'react-select-country-list'

function EditCustomerModal({onClose}) {

    // console.log("cR", options);
    // service date
    const [serviceDate, setServiceDate] = useState(new Date())
    const handleServiceDateChange = (newDate) => {
        setServiceDate(newDate)
    }

    // service amount type
    const serviceAmountTypes = ["DEBIT", "CREDIT", "CASH DEPOSIT"]
    const [serviceAmountType, setServiceAmountType] = useState(serviceAmountTypes[0]);
    const handleServiceAmountTypeChange = (event) => {
        setServiceAmountType(event.target.value);
    };

    // folio item name
    const [folioItemName, setFolioItemName] = useState('');
    const handleFolioItemNameChange = (event) => {
        setFolioItemName(event.target.value);
    };

    // applicable tax
    const applicableTaxes = [""]
    const [applicableTax, setApplicableTax] = useState(applicableTaxes[0]);
    const handleApplicableTax = (event) => {
        setApplicableTax(event.target.value);
    };

    // service amount
    const [serviceAmount, setServiceAmount] = useState('');
    const handleServiceAmountChange = (event) => {
        setServiceAmount(event.target.value);
    };

    // final service amount
    const [finalServiceAmount, setFinalServiceAmount] = useState('');
    const handleFinalServiceAmountChange = (event) => {
        setFinalServiceAmount(event.target.value);
    };

    // customer name
    const [customerName, setCustomerName] = useState('');
    const handleCustomerNameChange = (event) => {
        setCustomerName(event.target.value);
    };

    // customer email
    const [customerEmail, setCustomerEmail] = useState('');
    const handleCustomerEmailChange = (event) => {
        setCustomerEmail(event.target.value);
    };

    // customer mobile
    const [customerMobile, setCustomerMobile] = useState('');
    const handleCustomerMobileChange = (event) => {
        setCustomerMobile(event.target.value);
    };

    // customer address
    const [customerAddress, setCustomerAddress] = useState('');
    const handleCustomerAddressChange = (event) => {
        setCustomerAddress(event.target.value);
    };

    // customer country
    const options = useMemo(() => countryList().getData(), [])

    const [country, setCountry] = useState('IN');

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    // customer state
    const [customerState, setCustomerState] = useState('');
    const handleCustomerStateChange = (event) => {
        setCustomerState(event.target.value);
    };

    // customer city
    const [customerCity, setCustomerCity] = useState('');
    const handleCustomerCityChange = (event) => {
        setCustomerCity(event.target.value);
    };

    // customer zip
    const [customerZip, setCustomerZip] = useState('');
    const handleCustomerZipChange = (event) => {
        setCustomerZip(event.target.value);
    };

    // customer gov id
    const [customerGovId, setCustomerGovId] = useState('');
    const handleCustomerGovIdChange = (event) => {
        setCustomerGovId(event.target.value);
    };

    // customer gov id number
    const [customerGovIdNum, setCustomerGovIdNum] = useState('');
    const handleCustomerGovIdNumChange = (event) => {
        setCustomerGovIdNum(event.target.value);
    };

    // form for editing the customer details
    const editCustomerForm = useFormik({
        initialValues:{
            
        }
    })
    

    return (
        <div className="editModalWrapper">
            <div className="editCustomerHeader">
                Edit Customer on Booking
            </div>
            <div className="sep" /> 
            <div className="editCustomerDetails">
                <div className="customerName">
                    <TextField
                    id="outlined-multiline-flexible"
                    label="Customer Name"
                    multiline
                    maxRows={4}
                    value={customerName}
                    onChange={handleCustomerNameChange}
                    sx={{width:300}}
                    // helperText = "Either select from the options below or you can add New Tax Category!"
                    />
                </div>
                <div className="customerEmail">
                    <TextField
                    id="outlined-multiline-flexible"
                    label="Customer Email"
                    multiline
                    maxRows={4}
                    value={customerEmail}
                    onChange={handleCustomerEmailChange}
                    sx={{width:300}}
                    // helperText = "Either select from the options below or you can add New Tax Category!"
                    />
                </div>
                <div className="customerMobile">
                    <TextField
                    id="outlined-multiline-flexible"
                    label="Customer Mobile"
                    multiline
                    maxRows={4}
                    value={customerMobile}
                    onChange={handleCustomerMobileChange}
                    sx={{width:300}}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                    // helperText = "Either select from the options below or you can add New Tax Category!"
                    />
                </div>
                <div className="customerAddress">
                    <TextField
                    id="outlined-multiline-flexible"
                    label="Customer Address"
                    multiline
                    maxRows={4}
                    value={customerAddress}
                    onChange={handleCustomerAddressChange}
                    sx={{width:300}}
                    // helperText = "Either select from the options below or you can add New Tax Category!"
                    />
                </div>
                <div className="customerCountry">
                    <TextField
                    id="outlined-select-currency"
                    select
                    label="Select Country"
                    value={country}
                    onChange={handleCountryChange}
                    // helperText="Please select your country"
                    sx={{width:300}}

                    >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                </div>
                <div className="customerState">
                    <TextField
                    id="outlined-multiline-flexible"
                    label="State"
                    multiline
                    maxRows={4}
                    value={customerState}
                    onChange={handleCustomerStateChange}
                    sx={{width:300}}
                    // helperText = "Either select from the options below or you can add New Tax Category!"
                    />
                </div>
                <div className="customerCity">
                    <TextField
                    id="outlined-multiline-flexible"
                    label="City"
                    multiline
                    maxRows={4}
                    value={customerCity}
                    onChange={handleCustomerCityChange}
                    sx={{width:300}}
                    // helperText = "Either select from the options below or you can add New Tax Category!"
                    />
                </div>
                <div className="customerZip">
                    <TextField
                    id="outlined-multiline-flexible"
                    label="Zip"
                    multiline
                    maxRows={4}
                    value={customerZip}
                    onChange={handleCustomerZipChange}
                    sx={{width:300}}
                    // helperText = "Either select from the options below or you can add New Tax Category!"
                    />
                </div>
                <div className="customerGovId">
                    <TextField
                    id="outlined-multiline-flexible"
                    label="Gov ID"
                    multiline
                    maxRows={4}
                    value={customerGovId}
                    onChange={handleCustomerGovIdChange}
                    sx={{width:300}}
                    // helperText = "Either select from the options below or you can add New Tax Category!"
                    />
                </div>
                <div className="customerGovIdNum">
                    <TextField
                    id="outlined-multiline-flexible"
                    label="Gov Id Number"
                    multiline
                    maxRows={4}
                    value={customerGovIdNum}
                    onChange={handleCustomerGovIdNumChange}
                    sx={{width:300}}
                    // helperText = "Either select from the options below or you can add New Tax Category!"
                    />
                </div>
                <div className="customerGovIdImg">
                    <OutlinedInput
                    label="Gov Id Image"
                    value={customerGovIdNum}
                    onChange={handleCustomerGovIdNumChange}
                    sx={{width:300}}
                    type = "file"
                    labelWidth = "200px"
                    // helperText = "Either select from the options below or you can add New Tax Category!"
                    />
                </div>
            </div>
            <div className="sep" />
            <div className="addServiceBtns">
                <button className="cancelBtn" onClick={onClose}>
                    Cancel
                </button>
                <button className="addBtn">
                    Save Customer
                </button>
            </div>
        </div>
    )
}

export default EditCustomerModal
