import React from "react";
import HotelIcon from "@mui/icons-material/Hotel";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GridOnIcon from "@mui/icons-material/GridOn";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import AssessmentIcon from "@mui/icons-material/Assessment";

export const Sidebar = [
  {
    mykey: 1,
    title: "Account Ledger",
    icon: <AccountBalanceIcon />,

    items: [],
  },
  // {
  //   title: "Payments",
  //   icon: <GridOnIcon />,
  //   items: [],
  //   mykey: 2,
  // },
  // {
  //   title: "Orders",
  //   icon: <AccessibilityIcon />,
  //   items: [],
  //   mykey: 3,
  // },
  // {
  //   title: "Refunds",
  //   icon: <HotelIcon />,
  //   items: [],
  //   mykey: 4,
  // },
  // {
  //   title: "All",
  //   icon: <LegendToggleIcon />,
  //   items: [],
  //   mykey: 5,
  // },
  // {
  //   title: "Direct billing",
  //   icon: <BackupTableIcon />,
  //   items: [],
  //   mykey: 6,
  // },
  //   {
  //     title: "Promocodes",
  //     icon: <BackupTableIcon />,
  //     items: [],
  //     mykey: 7,
  //   },
  //   {
  //     title: (
  //       <Stack direction="row" spacing={1}>
  //         <div>Rate Shopper</div>{" "}
  //         <div
  //           style={{ fontSize: "12px" }}
  //           className="d-flex align-items-center border rounded bg-warning text-white pl-2 pr-2"
  //         >
  //           Beta
  //         </div>
  //       </Stack>
  //     ),
  //     icon: <AssessmentIcon />,
  //     items: [],
  //     mykey: 8,
  //   },
];
