import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link as DomLink } from "react-router-dom";
import * as Yup from "yup";
import { getAllImagesAndUrl } from "../../../pages/BookingDashboard/apis/BrandingModalApi";
import { getHotel, login, masterLogin } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";

const EmailSuccess = (props) => {
  const LoginSchema = Yup.object().shape({
    passw: Yup.string().required("Password is required"),
  });
  const MasterLoginSchema = Yup.object().shape({
    username: Yup.string().required("master email is required"),
    passw: Yup.string().required("master password is required"),
  });
  const masterAdminCheck =
    window.location.pathname.split("/")[1] === "masterAdmin" ? true : false;
  // const theme = useTheme()
  // const matches = useMediaQuery('(min-width:600px)');
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { loginTrue, mailId } = props;
  const formik = useFormik({
    initialValues: { passw: "" },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      validUser(values);
    },
  });

  const masterFormik = useFormik({
    initialValues: { username: "", passw: "" },
    validationSchema: MasterLoginSchema,
    onSubmit: async (values) => {
      validMasterUser(values);
    },
  });

  const validMasterUser = async (values) => {
    setLoading(true);
    setInvalid(false);
    try {
      const temporary = await masterLogin(
        mailId,
        values.username,
        values.passw
      );
      const resp = temporary.data;
      const hotelData = await getHotel(
        mailId,
        resp.groupProperty,
        `${resp.token}||master`
      );
      const temp = Object.assign(resp);
      temp.hotels = hotelData.data;
      temp.email = mailId;
      temp.token = `${resp.token}||master`;
      localStorage.setItem("hotelId", hotelData.data[0].hotelId);
      localStorage.setItem("token", `${resp.token}||master`);
      localStorage.setItem("rootEmail", values.username);
      setLoading(false);
      props.login(temp);
      localStorage.setItem("HotelEmail", mailId);
    } catch (e) {
      setInvalid(true);
      setLoading(false);
    }
  };

  const validUser = async (values) => {
    setLoading(true);
    setInvalid(false);
    try {
      const temporary = await login(mailId, values.passw);
      localStorage.setItem("HotelEmail", mailId);
      if (temporary.data.token === "Invalid login hours") {
        setInvalidLogin(true);
        setLoading(false);
      } else {
        const resp = temporary.data;
        const hotelData = await getHotel(
          mailId,
          resp.groupProperty,
          resp.token
        );
        const temp = Object.assign(resp);
        temp.hotels = hotelData.data;
        temp.email = mailId;
        localStorage.setItem("hotelId", hotelData.data[0].hotelId);
        localStorage.setItem("token", resp.token);
        setLoading(false);
        props.login(temp);
        localStorage.setItem("HotelEmail", mailId);
      }
    } catch (e) {
      setInvalid(true);
      setLoading(false);
    }
  };

  const [showHelper, setshowHelper] = useState("");
  useEffect(async () => {
    await getAllData();
  }, []);

  const getAllData = async () => {
    try {
      const data = await getAllImagesAndUrl();
      setshowHelper(data.status);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <form
      noValidate
      onSubmit={
        masterAdminCheck ? masterFormik.handleSubmit : formik.handleSubmit
      }
    >
      <Grid container display="grid" spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" paddingBottom={1}>
            {masterAdminCheck ? "Master login" : "Welcome back"}
          </Typography>
          <Typography variant="body1">{mailId}</Typography>
        </Grid>
        {masterAdminCheck && (
          <Grid item xs={12} spacing={10}>
            <TextField
              value={masterFormik.values.username}
              onChange={(e) => {
                masterFormik.setFieldValue("username", e.target.value);
                if (invalid) setInvalid(false);
              }}
              fullWidth
              type={"text"}
              onBlur={masterFormik.handleBlur}
              name="username"
              id="username"
              sx={{ marginBottom: "1rem" }}
              autoFocus
              error={
                (masterFormik.touched.username &&
                  Boolean(masterFormik.errors.username)) ||
                invalid
              }
              helperText={
                (masterFormik.touched.username &&
                  masterFormik.errors.username) ||
                (invalid && "Invalid master email")
              }
              label="Master email"
            ></TextField>
            <TextField
              value={masterFormik.values.passw}
              onChange={(e) => {
                masterFormik.setFieldValue("passw", e.target.value);
                if (invalid) setInvalid(false);
              }}
              fullWidth
              type={showPassword ? "text" : "password"}
              onBlur={masterFormik.handleBlur}
              name="passw"
              id="passw"
              error={
                (masterFormik.touched.passw &&
                  Boolean(masterFormik.errors.passw)) ||
                invalid
              }
              helperText={
                (masterFormik.touched.passw && masterFormik.errors.passw) ||
                (invalid && "Invalid master password")
              }
              label="Master password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>
        )}
        {!masterAdminCheck && (
          <Grid item xs={12}>
            <TextField
              value={formik.values.passw}
              onChange={(e) => {
                formik.setFieldValue("passw", e.target.value);
                if (invalid) setInvalid(false);
                if (invalidLogin) setInvalidLogin(false);
              }}
              fullWidth
              type={showPassword ? "text" : "password"}
              onBlur={formik.handleBlur}
              name="passw"
              id="passw"
              autoFocus
              error={
                (formik.touched.passw && Boolean(formik.errors.passw)) ||
                invalid ||
                invalidLogin
              }
              helperText={
                (formik.touched.passw && formik.errors.passw) ||
                (invalid && "Invalid password") ||
                (invalidLogin && "Invalid login hours")
              }
              label="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>
        )}
        {showHelper !== "ENABLED" && !masterAdminCheck && (
          <Grid item xs={12}>
            <Link
              underline="hover"
              component={DomLink}
              to={{
                pathname: masterAdminCheck
                  ? "/masterAdmin/forget-password"
                  : "/auth/forget-password",
                state: {
                  email: mailId,
                },
              }}
            >
              {" "}
              Forgot your password?{" "}
            </Link>
          </Grid>
        )}
        {window.location.hostname === "pms.isha.in" && (
          <Grid item xs={12}>
            <Link
              underline="hover"
              component={DomLink}
              to={{
                pathname: masterAdminCheck
                  ? "/masterAdmin/forget-password"
                  : "/auth/forget-password",
                state: {
                  email: mailId,
                },
              }}
            >
              {" "}
              Forgot your password?{" "}
            </Link>
          </Grid>
        )}
        <Grid item xs={12}>
          <LoadingButton
            sx={{ height: "150%" }}
            loading={loading}
            type="submit"
            fullWidth
            variant="custom-button"
            size="large"
          >
            Sign In
          </LoadingButton>
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <Link onClick={loginTrue} underline="hover" to="/">
            {" "}
            Back{" "}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default injectIntl(connect(null, auth.actions)(EmailSuccess));
