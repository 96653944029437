import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import "../../../../src/index.scss";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import DimmingDots from "./Views/LoaderCustom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import InfoIcon from "@mui/icons-material/Info";
import BalanceIcon from "@mui/icons-material/Balance";
import { MdGroups } from "react-icons/md";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { Modal } from "react-bootstrap";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Drawer,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Slide,
  Tooltip,
  Typography,
  useMediaQuery,
  Badge,
  Switch,
  Divider,
} from "@mui/material";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
// import { getBookingData } from "./api/ReservationApi";
import {
  channelLogoMap,
  dateFormat,
  getSlotType,
  multiOptions,
  navigationParamsForCalendar,
  views,
} from "../CalendarPage/constants/Calendar-constants";
import { BookingDataModel } from "../CalendarPage/models/BookingDataModel";
import moment from "moment";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  FiberManualRecord,
  Lock,
  MoreVert,
} from "@mui/icons-material";
import { shallowEqual, useSelector } from "react-redux";
import { isNull, isUndefined } from "lodash";
import { getAllNonHiddenRoomTypes } from "../InventoryCalendar/api/InventoryApi";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import "../../../index.scss";
import "@fullcalendar/resource-timeline/main.min.css";
import * as AiIcons from "react-icons/ai";
import { AMPLITUDE_KEY, ENV } from "./constants/AmplitudeConstants";
import amplitude from "amplitude-js";
import { getAllRoomTypess } from "../RevenueManagement/RatePlans/apis/RatePlanApis";
import { Dropdown } from "react-bootstrap";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import { toast } from "material-react-toastify";
import GroupModalView from "./GroupModalView";
import GroupEditModalView from "./GroupEditModalView";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import GroupExtendModalView from "./models/GroupExtendModalView";
import GroupDragModalView from "./models/GroupDragModalView";
import { connect } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 0,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: "90%",
  },
  drawers: {
    flexShrink: 0,
    width: "55%",
  },
  drawerPaper: {
    width: "90%",
  },
  drawerPapers: {
    width: "55%",
  },
}));

const GroupTimeline = ({ data }) => {
  const [allHotelIds, setAllHotelIds] = useState(null);
  function handleSetAllHotelIds() {
    const res = Array.from(new Set(data.hotels.map((item) => item.hotelId)));
    setAllHotelIds(res);
  }
  useEffect(() => {
    if (data) {
      handleSetAllHotelIds();
    }
  }, [data]);

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const [groupData, setgroupData] = useState([]);
  const getAllDatas = () => {
    setLoading(true);
    handleSpringDataMutationRequest("POST", `common/group-reservation-grid`, {
      emailId: localStorage.getItem("groupEmail"),
      numDays: 7,
      startDate: moment(new Date()).format("DD-MM-YYYY HH:00:00"),
      parallelModel: true,
    })
      .then((res) => {
        setgroupData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error in fecthing data");
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllDatas();
  }, []);

  //   const hotelSlot = getSlotType(hotel.slotType);
  const Noonslot = { day: 0, hour: 8 };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  //   const hotelId = hotel.hotelId;
  const [calendarRefArray, setCalendarRefArray] = useState([]);
  const calendarRef = useRef(null);
  let calRefArray = useRef([]);
  const navigationParams = Object.assign(navigationParamsForCalendar);
  let [actionType, setActionType] = useState("");
  let [showModal, setShowModal] = useState({});
  let [show, setShow] = useState(false);
  let [initialData, setInitialData] = useState(new BookingDataModel({}));
  const [calendarApiArray, setcalendarApiArray] = useState([]);
  let [calendarApi, setCalendarApi] = useState(null);
  let [loading, setLoading] = useState(false);
  // let [snackbar, setSnackbar] = useState(false);
  const [rooms, setRooms] = useState([
    { text: "Available Rooms", id: "availableRooms" },
    { text: "Blocked Rooms", id: "blockedRooms" },
    { text: "Dirty Rooms", id: "dirtyRooms" },
    { text: "Clean Rooms", id: "cleanRooms" },
    { text: "Occupied Rooms", id: "bookedRooms" },
    { text: "All", id: "All" },
    { text: "Unselect", id: "Unselect" },
  ]);
  const [selectedRoom, setSelectedRoom] = useState([]);
  const [today, setToday] = useState(new Date());
  const [roomNumbers, setRoomNumbers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const [multiOption, setMultiOption] = useState("");
  const onOpenEditCustomerModal = () => setOpenEditCustomerModal(true);
  const onCloseEditCustomerModal = () => setOpenEditCustomerModal(false);
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const onOpenServiceModal = () => setOpenServiceModal(true);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const windowWidth = window.screen.innerWidth;
  const [state, setState] = useState({ right: false });
  const [showUnassigned, setShowUnassigned] = useState(false);
  const [unassignedDate, setUnassignedDate] = useState(new Date());
  const [originalAllRoomInfo, setOriginalAllRoomInfo] = useState([]);
  const [showRoomName, setshowRoomName] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dirtyRoomId, setdirtyRoomId] = useState("");
  const [initailDataArray, setinitailDataArray] = useState([]);
  const [roomNumberArray, setroomNumberArray] = useState([]);
  const [hotelId, sethotelId] = useState("");
  const [roomInfo, setroomInfo] = useState(null);
  const [hotelSlotType, sethotelSlotType] = useState("");
  const [hotelCurrency, sethotelCurrency] = useState("INR");
  const [hotelCountry, sethotelCountry] = useState("India");

  // Check if the property is xytron enabled
  const handleGetXytronEnabled = (hId) => {
    try {
      handleSpringDataRequest(
        `core/api/v1/xytron/is-connected?hotelId=${hId}`
      ).then((res) => {
        sessionStorage.setItem(`xytronEnabled${hId}`, res ? res : false);
      });
    } catch (err) {
      console.log(
        "Error in capturing if property is xytron enabled at the group level : ",
        err
      );
    }
  };

  useEffect(() => {
    if (allHotelIds) {
      for (let hId of allHotelIds) {
        handleGetXytronEnabled(hId);
      }
    }
  }, [allHotelIds]);

  //Amplitude check on create booking page
  const amplitudeEventProperties = {
    hotel_id: hotel?.hotelId,
    environment: ENV,
    email: hotel?.accessControl?.email,
    role: hotel?.accessControl?.ADMINRIGHTS,
  };

  useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_KEY, hotel?.hotelId, {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
    });
    amplitude
      .getInstance()
      .identify(
        new amplitude.Identify()
          .set("hotel_id", hotel?.hotelId)
          .set("email", hotel?.accessControl?.email)
          .set("role", hotel?.accessControl?.ADMINRIGHTS)
      );
    amplitude
      .getInstance()
      .logEvent("CREATE BOOKING PAGE LOADED", amplitudeEventProperties);
  }, []);

  const classes = useStyles();
  const changeDateTime = (data) => {
    showModal.event.setDates(data.start, data.end);
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  const moveToNewRoom = (id) => {
    showModal.event.setResources([id]);
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  const getStartEndDates = () => {
    let title = calendarApiArray[0].view.type;
    let currentDate = calendarApiArray[0].getDate();
    let endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + (views[title] - 1));
    return { start: currentDate, end: endDate };
  };

  const lockEvent = (lockType) => {
    showModal.event.setExtendedProp("locked_status", lockType);
    handleAddEventSidebar();
  };

  const removeEvent = () => {
    showModal.event.remove();
    if (calendarApiArray[0] !== null) {
      calendarApiArray[0].refetchEvents();
    }
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  // Creating a new event when selecting in a empty space

  const handleDateSelect = (
    data,
    bookings,
    hotelId,
    slot,
    country,
    currency
  ) => {
    if (data.event.extendedProps.status === "COMBO") return;
    if (data.event.extendedProps.status === "BLOCKED")
      setActionType("edit-blocked");
    else setActionType("edit");
    setroomInfo(bookings);
    sethotelId(hotelId);
    sethotelCurrency(currency);
    sethotelCountry(country);
    sethotelSlotType(slot);
    setShowModal(data);
    setShow(true);
  };

  // When event position changes for Drag and drop and extension
  const handleDragStop = (data) => {
    if (
      data.event.getResources()[0].id !== data.oldEvent.getResources()[0].id &&
      data.oldEvent.startStr !== data.event.startStr &&
      data.oldEvent.endStr !== data.event.endStr
    ) {
      data.revert();
      return;
    }
    setShowModal(data);
    setShow(true);
  };

  // Closing the modal for creating new event
  const handleAddEventSidebar = () => {
    setShow(!show);
    setShowModal(null);
  };

  // Reverting the changes when drag and drop is cancelled
  const modify = () => {
    showModal.revert();
    handleAddEventSidebar();
  };

  // Opening a modal for new event in the empty boxes

  const addNew = (data, bookings, hotelId, slot, country, currency) => {
    setroomInfo(bookings);
    sethotelId(hotelId);
    sethotelCurrency(currency);
    sethotelCountry(country);
    sethotelSlotType(slot);
    setActionType("add");
    setShowModal(data);
    setShow(true);
  };

  // Adding the new event to the calendar
  const setNewData = (obj, val) => {
    if (!val) {
      calendarApiArray[0].addEvent(obj);
      if (calendarApiArray[0] !== null) {
        calendarApiArray[0].refetchEvents();
      }
    }

    handleAddEventSidebar();
  };

  const checkForEmptyCalendar = () => {
    if (isNull(calendarApiArray)) {
      return false;
    }
    if (isUndefined(calendarApiArray[0]?.view)) {
      return false;
    }

    return true;
  };

  const fetchBookingDataWithoutLoad = async () => {
    const params = getParamsForView("any", calendarApiArray);
    initialData.roomsData = [];
    initialData.bookingData = [];

    if (params?.roomTypes?.includes("blockedRooms")) {
      params.blockedRooms = true;
    } else if (params?.roomTypes?.includes("availableRooms")) {
      params.availableRooms = true;
    } else if (params?.roomTypes?.includes("dirtyRooms")) {
      params.dirtyRooms = true;
    } else if (params?.roomTypes?.includes("cleanRooms")) {
      params.cleanRooms = true;
    } else if (params?.roomTypes?.includes("bookedRooms")) {
      params.bookedRooms = true;
    }
    if (params?.roomTypes !== undefined && params?.roomTypes !== null) {
      params.roomTypes = params?.roomTypes.filter(
        (item) =>
          item !== "blockedRooms" &&
          item !== "availableRooms" &&
          item !== "dirtyRooms" &&
          item !== "cleanRooms" &&
          item !== "bookedRooms"
      );
    } else {
      params.roomTypes = null;
    }

    // getUnassignedCount(params);

    // const data = await getBookingData(params);
    // const resp = new BookingDataModel(data, params.viewType, "THREE_SLOT");

    const roomsAray = [];
    const InitialArray = [];
    let refArray = [];
    setLoading(true);
    handleSpringDataMutationRequest("POST", `common/group-reservation-grid`, {
      emailId: localStorage.getItem("groupEmail"),
      numDays:
        sessionStorage.getItem("types") === "month"
          ? 30
          : sessionStorage.getItem("types") === "day"
          ? 1
          : 7,
      startDate: params.startDate,

      parallelModel: true,
    })
      .then((res) => {
        const grouping = res?.map((item) => {
          let res = new BookingDataModel(
            item.returnInfo,
            params.viewType,
            item.timeType === "NOON" ? "THREE_SLOT" : item.timeType
          );
          roomsAray.push(res.roomsData);
          InitialArray.push(res);
        });
        // calendarApi.removeAllEvents();

        setinitailDataArray(InitialArray);
        setroomNumberArray(roomsAray);
        // setCalendarRefArray(refArray);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error in fecthing data");
        setLoading(false);
      });
  };

  //Fix
  const [unassingedCount, setunassingedCount] = useState(0);

  // const getUnassignedCount = (params) => {
  //   handleSpringDataMutationRequest(
  //     "POST",
  //     `core/api/v1/reservation/getUnassignCount`,
  //     {
  //       hotelId: "23925",
  //       numOfDays:
  //         sessionStorage.getItem("types") === "month"
  //           ? 30
  //           : sessionStorage.getItem("types") === "day"
  //           ? 1
  //           : 7,
  //       roomIdsSort: true,
  //       roomTypes: params === undefined ? null : params.roomTypes,
  //       startDate:
  //         params === undefined
  //           ? hotel?.hotelCurrency === "USD"
  //             ? moment(new Date(new Date().getTime() - 86400000))
  //                 .set({ hour: 0, minute: 0, second: 0 })
  //                 .format(dateFormat)
  //             : moment(new Date())
  //                 .set({ hour: 0, minute: 0, second: 0 })
  //                 .format(dateFormat)
  //           : params.startDate,
  //       viewType:
  //         params === undefined ? "resourceTimelineWeek" : params?.viewType,
  //     }
  //   )
  //     .then((res) => setunassingedCount(Number(res)))
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   getUnassignedCount();
  // }, [sessionStorage.getItem("types")]);

  const reloadData = () => {
    let tempData = getParamsForView("month", calendarApiArray);
    fetchBookingDataWithParams(tempData, "month");
  };

  const fetchBookingDataWithParams = async (params, type) => {
    setLoading(true);

    const resps = calendarApiArray.map((item) => {
      item.removeAllEvents();
    });
    // calendarApi.removeAllEvents();
    initialData.roomsData = [];
    initialData.bookingData = [];

    // getUnassignedCount(params);

    if (params?.roomTypes?.includes("blockedRooms")) {
      params.blockedRooms = true;
    } else if (params?.roomTypes?.includes("availableRooms")) {
      params.availableRooms = true;
    } else if (params?.roomTypes?.includes("dirtyRooms")) {
      params.dirtyRooms = true;
    } else if (params?.roomTypes?.includes("cleanRooms")) {
      params.cleanRooms = true;
    } else if (params?.roomTypes?.includes("bookedRooms")) {
      params.bookedRooms = true;
    }

    if (params?.roomTypes !== null && params?.roomTypes !== undefined) {
      params.roomTypes =
        params?.roomTypes.length > 0 &&
        params?.roomTypes.filter(
          (item) =>
            item !== "blockedRooms" &&
            item !== "availableRooms" &&
            item !== "dirtyRooms" &&
            item !== "cleanRooms" &&
            item !== "bookedRooms"
        );
    } else {
      params.roomTypes = null;
    }
    if (
      params?.roomTypes !== null &&
      params?.roomTypes !== undefined &&
      params?.roomTypes === false
    ) {
      params.roomTypes = null;
    }

    // const data = await getBookingData(params);
    let resp;
    const roomsAray = [];
    const InitialArray = [];
    let refArray = [];
    setLoading(true);
    handleSpringDataMutationRequest("POST", `common/group-reservation-grid`, {
      emailId: localStorage.getItem("groupEmail"),
      numDays:
        sessionStorage.getItem("types") === "month"
          ? 30
          : sessionStorage.getItem("types") === "day"
          ? 1
          : 7,
      startDate: params.startDate,
      parallelModel: true,
    })
      .then((res) => {
        if (type === "month" || sessionStorage.getItem("types") === "month") {
          //   resp = new BookingDataModel(data, params.viewType, hotel.slotType);
          const grouping = res?.map((itemimg) => {
            let res = new BookingDataModel(
              itemimg.returnInfo,
              params.viewType,
              itemimg.timeType === "NOON" ? "THREE_SLOT" : itemimg.timeType
            );

            roomsAray.push(res.roomsData);
            InitialArray.push(res);
          });
        } else {
          //   resp = new BookingDataModel(data, params.viewType, "THREE_SLOT");

          const grouping = res?.map((itemimg) => {
            let res = new BookingDataModel(
              itemimg.returnInfo,
              params.viewType,
              itemimg.timeType === "NOON" ? "THREE_SLOT" : itemimg.timeType
            );
            roomsAray.push(res.roomsData);
            InitialArray.push(res);
            // refArray.push(calendarRef);
          });
        }

        // setRoomNumbers(resp.roomsData);

        // setInitialData(resp);
        setinitailDataArray(InitialArray);
        setroomNumberArray(roomsAray);
        // setCalendarRefArray(refArray);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error in fecthing data");
        setLoading(false);
      });
  };

  const fetchBookingData = async () => {
    try {
      setLoading(true);
      let temp = Object.assign(navigationParams);
      temp.startDate =
        hotel?.hotelCurrency === "USD"
          ? moment(new Date(new Date().getTime() - 86400000))
              .set({ hour: 0, minute: 0, second: 0 })
              .format(dateFormat)
          : moment(new Date())
              .set({ hour: 0, minute: 0, second: 0 })
              .format(dateFormat);

      if (temp?.roomTypes?.includes("blockedRooms")) {
        temp.blockedRooms = true;
      } else if (temp?.roomTypes?.includes("availableRooms")) {
        temp.availableRooms = true;
      } else if (temp?.roomTypes?.includes("dirtyRooms")) {
        temp.dirtyRooms = true;
      } else if (temp?.roomTypes?.includes("cleanRooms")) {
        temp.cleanRooms = true;
      } else if (temp?.roomTypes?.includes("bookedRooms")) {
        temp.bookedRooms = true;
      }

      if (temp?.roomTypes !== null && temp?.roomTypes !== undefined) {
        temp.roomTypes =
          temp?.roomTypes.length > 0 &&
          temp?.roomTypes.filter(
            (item) =>
              item !== "blockedRooms" &&
              item !== "availableRooms" &&
              item !== "dirtyRooms" &&
              item !== "cleanRooms" &&
              item !== "bookedRooms"
          );
      } else {
        temp.roomTypes = null;
      }

      // const data = await getBookingData(temp);
      //   const resp = new BookingDataModel(
      //     data,
      //     "resourceTimelineWeek",
      //     "THREE_SLOT"
      //   );
      //   setRoomNumbers(resp.roomsData);
      //   setOriginalAllRoomInfo(data.allRoomReservations.singleRoomReservations);

      //   setInitialData(resp);

      const roomsAray = [];
      const InitialArray = [];
      let refArray = [];
      let calendarApiAray = [];
      const grouping = groupData?.map((item) => {
        let res = new BookingDataModel(
          item.returnInfo,
          "resourceTimelineWeek",
          item.timeType === "NOON" ? "THREE_SLOT" : item.timeType
        );
        roomsAray.push(res.roomsData);
        InitialArray.push(res);

        refArray.push(calendarRef);

        // calendarApiAray.push(calendarRef?.current?.getApi());
      });
      setinitailDataArray(InitialArray);
      setroomNumberArray(roomsAray);

      // setCalendarRefArray(refArray);

      setLoading(false);
    } catch (er) {
      console.log(er);
    }
  };

  // useEffect(() => {
  //   let apiArray = [];
  //   if (calendarRefArray?.length > 0) {
  //   }
  //   calendarRefArray.map((refval) => {
  //     if (refval !== null && refval !== undefined) {
  //       apiArray.push(refval?.current?.getApi());
  //     }
  //   });

  // setcalendarApiArray(apiArray);
  // }, [calendarRefArray]);

  const getParamsForView = (val, arr) => {
    if (arr.length > 0) {
      let title = arr[0].view.type;
      let currentDate = arr[0].getDate();

      let tempParams = Object.assign(navigationParams);
      if (views[title] === 30) {
        if (val === "subtract") {
          currentDate.setDate(currentDate.getDate() - views[title]);
        }
        if (val === "add") {
          currentDate.setDate(currentDate.getDate() + views[title]);
        }
      } else {
        if (val === "subtract") {
          currentDate.setDate(currentDate.getDate() - views[title]);
        }
        if (val === "add") {
          currentDate.setDate(currentDate.getDate() + views[title]);
        }
      }

      tempParams.viewType = title;
      tempParams.numOfDays = views[title];
      tempParams.availableRooms = selectedRoom?.includes("availableRooms");
      tempParams.blockedRooms = selectedRoom?.includes("blockedRooms");
      tempParams.dirtyRooms = selectedRoom?.includes("dirtyRooms");
      tempParams.cleanRooms = selectedRoom?.includes("cleanRooms");
      tempParams.bookedRooms = selectedRoom?.includes("bookedRooms");
      tempParams.startDate = moment(currentDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .format(dateFormat);
      const temporary = [];
      if (
        selectedRoom.some((e) => e.id === "All") ||
        selectedRoom.length === 0
      ) {
        tempParams.roomTypes = rooms.forEach((e) => {
          if (e.id !== "All" && e.id !== "Unselect") temporary.push(e.id);
        });
      } else {
        selectedRoom.forEach((room) => {
          temporary.push(room.id);
        });
        tempParams.roomTypes = temporary;
      }
      tempParams.clusterRooms = false;
      return tempParams;
    } else {
      let title = calendarApi.view.type;
      let currentDate = calendarApi.getDate();

      let tempParams = Object.assign(navigationParams);
      if (views[title] === 30) {
        if (val === "subtract") {
          currentDate.setDate(currentDate.getDate() - views[title]);
        }
        if (val === "add") {
          currentDate.setDate(currentDate.getDate() + views[title]);
        }
      } else {
        if (val === "subtract") {
          currentDate.setDate(currentDate.getDate() - views[title]);
        }
        if (val === "add") {
          currentDate.setDate(currentDate.getDate() + views[title]);
        }
      }

      tempParams.viewType = title;
      tempParams.numOfDays = views[title];
      tempParams.availableRooms = selectedRoom?.includes("availableRooms");
      tempParams.blockedRooms = selectedRoom?.includes("blockedRooms");
      tempParams.dirtyRooms = selectedRoom?.includes("dirtyRooms");
      tempParams.cleanRooms = selectedRoom?.includes("cleanRooms");
      tempParams.bookedRooms = selectedRoom?.includes("bookedRooms");
      tempParams.startDate = moment(currentDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .format(dateFormat);
      const temporary = [];
      if (
        selectedRoom.some((e) => e.id === "All") ||
        selectedRoom.length === 0
      ) {
        tempParams.roomTypes = rooms.forEach((e) => {
          if (e.id !== "All" && e.id !== "Unselect") temporary.push(e.id);
        });
      } else {
        selectedRoom.forEach((room) => {
          temporary.push(room.id);
        });
        tempParams.roomTypes = temporary;
      }
      tempParams.clusterRooms = false;
      return tempParams;
    }
  };

  const getRoomsData = async () => {
    const resp = await getAllRoomTypess();
    const temp_rooms = [];

    for (const [key, value] of Object.entries(resp)) {
      rooms.push({ text: value, id: key });
    }
    setRooms(rooms);
    setSelectedRoom("All");
  };
  // To detect the changes in the calendar and re-rendering
  useEffect(() => {
    if (calendarApi === null && groupData?.length) {
      //   getRoomsData();
      fetchBookingData();

      setCalendarApi(calendarRef?.current?.getApi());
    }
  }, [calendarApi, groupData, calendarRef]);

  const getSource = (source) => {
    if (source !== undefined) {
      if (channelLogoMap[source] === undefined) return "";
      return channelLogoMap[source];
    }
  };

  useEffect(() => {
    if (
      calendarApiArray[0] !== null &&
      calendarApiArray[0] !== undefined &&
      calendarApiArray[0] !== ""
    ) {
      if (
        sessionStorage.getItem("types") !== null &&
        sessionStorage.getItem("types") !== undefined &&
        sessionStorage.getItem("types") !== "" &&
        calendarApiArray !== null &&
        calendarApiArray !== undefined &&
        calendarApiArray?.length > 0
      ) {
        if (sessionStorage.getItem("types") === "day") {
          const arr = [];
          const res = calendarApiArray.map((itemss) => {
            itemss.changeView("resourceTimelineDay");
            arr.push(itemss);
          });
          setcalendarApiArray(arr);
          let tempData = getParamsForView("day", calendarApiArray);

          fetchBookingDataWithParams(tempData, "day");
        } else if (sessionStorage.getItem("types") === "week") {
          const arr = [];
          const res = calendarApiArray.map((itemss) => {
            itemss.changeView("resourceTimelineWeek");
            arr.push(itemss);
          });
          setcalendarApiArray(arr);

          let tempData = getParamsForView("week", arr);
          fetchBookingDataWithParams(tempData, "week");
        } else if (sessionStorage.getItem("types") === "month") {
          const arr = [];
          const res = calendarApiArray.map((itemss) => {
            itemss.changeView("resourceTimelineMonthCustom");
            arr.push(itemss);
          });
          setcalendarApiArray(arr);

          let tempData = getParamsForView("month", arr);
          fetchBookingDataWithParams(tempData, "month");
        }
      } else {
        sessionStorage.setItem("types", "week");
      }
    }
  }, [sessionStorage.getItem("types")]);

  // const [checked, setChecked] = useState(false);

  const handleChangeName = (event) => {
    // setChecked(event.target.checked);
    if (event.target.checked) {
      let tempData = getParamsForView(
        sessionStorage.getItem("types"),
        calendarApiArray
      );
      tempData.clusterRooms = true;
      fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
    } else {
      let tempData = getParamsForView(
        sessionStorage.getItem("types"),
        calendarApiArray
      );
      tempData.clusterRooms = false;
      fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
    }

    setshowRoomName(event.target.checked);
    sessionStorage.setItem("showRoomName", event.target.checked);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("showRoomName") === undefined ||
      sessionStorage.getItem("showRoomName") === null ||
      sessionStorage.getItem("showRoomName") === ""
    ) {
      setshowRoomName(false);
    } else {
      const data =
        sessionStorage.getItem("showRoomName") === "true" ? true : false;

      setshowRoomName(data);
    }
  }, [sessionStorage.getItem("showRoomName")]);

  useEffect(() => {
    if (groupData?.length > 0)
      calRefArray.current = Array.from({ length: groupData?.length }, () =>
        React.createRef()
      );
  }, [groupData]);

  useEffect(() => {
    let tempArray = [];
    if (calRefArray.current?.length > 0) {
      calRefArray.current.map((calRef) => {
        tempArray.push(calRef.getApi());
      });
    }
    setcalendarApiArray(tempArray);
    if (tempArray.length > 0) {
      let tempData = getParamsForView("week", tempArray);
      fetchBookingDataWithParams(tempData, "week");
    }
  }, [calRefArray.current]);

  return groupData.length > 0 &&
    roomNumberArray?.length > 0 &&
    initailDataArray?.length > 0 ? (
    <>
      <div>
        {show && actionType === "add" && showModal.resource ? (
          <GroupModalView
            open={show}
            viewType={calendarApiArray[0].view.type}
            reload={fetchBookingDataWithoutLoad}
            reloadagain={reloadData}
            calendarApi={calendarApi}
            eventType={actionType}
            eventData={showModal}
            open={show}
            handleAddEventSidebar={handleAddEventSidebar}
            addEvent={setNewData}
            roomInformation={
              roomInfo.roomTypeInformation[
                showModal?.resource?.extendedProps?.roomTypeId.split(",")[0]
              ]
            }
            hotelId={hotelId}
            hotelSlotType={hotelSlotType}
            hotelCurrency={hotelCurrency}
            currency={hotelCountry}
          />
        ) : null}
        {show && actionType === "DragorDrop" ? (
          <GroupDragModalView
            viewType={calendarApiArray[0].view.type}
            reload={fetchBookingDataWithoutLoad}
            calendarApi={calendarApiArray[0]}
            eventType={actionType}
            eventData={showModal}
            open={show}
            handleAddEventSidebar={modify}
            addEvent={changeDateTime}
            moveEvent={moveToNewRoom}
            roomInformation={groupData}
            roomTypeInformation={roomInfo}
            edit={"edit"}
            hotelId={hotelId}
            hotelSlotType={hotelSlotType}
            hotelCurrency={hotelCurrency}
            currency={hotelCurrency}
          ></GroupDragModalView>
        ) : null}
        {show && actionType === "extend" ? (
          <GroupExtendModalView
            viewType={calendarApiArray[0].view.type}
            eventData={showModal}
            open={show}
            handleAddEventSidebar={modify}
            addEvent={changeDateTime}
            reload={fetchBookingDataWithoutLoad}
            hotelId={hotelId}
            hotelSlotType={hotelSlotType}
            hotelCurrency={hotelCurrency}
            currency={hotelCountry}
          ></GroupExtendModalView>
        ) : null}
        {show && actionType === "edit" ? (
          <GroupEditModalView
            viewType={calendarApiArray[0].view.type}
            reload={fetchBookingDataWithoutLoad}
            lockEvent={lockEvent}
            removeEvent={removeEvent}
            calendarApi={calendarApi}
            eventType={actionType}
            eventData={showModal}
            open={show}
            hotelCountry={hotelCountry}
            // viewFolio={(one, two, three) => {
            //   toggleDrawer(one, two, three);
            // }}
            handleAddEventSidebar={handleAddEventSidebar}
            addEvent={handleAddEventSidebar}
            roomInformation={originalAllRoomInfo}
            roomTypeInfo={roomInfo.roomTypeInformation}
            hotelId={hotelId}
            hotelSlotType={hotelSlotType}
          ></GroupEditModalView>
        ) : null}

        {show && actionType === "edit-blocked" && (
          <></>
          //   <UnblockView
          //     reload={fetchBookingDataWithoutLoad}
          //     type="unblock"
          //     eventData={showModal}
          //     open={show}
          //     closeFunc={handleAddEventSidebar}
          //     roomInformation={originalAllRoomInfo}
          //     roomTypeInfo={initialData.roomTypeInformation}
          //   ></UnblockView>
        )}
      </div>
      {groupData?.map((item, index) => (
        <Grid
          container
          sx={{ padding: 2, "&:lastChild": 3 }}
          width="100%"
          height="100%"
          spacing={2}
        >
          {index === 0 && (
            <Grid item container xs={12} spacing={2}>
              <Grid
                item
                xs={2}
                sm={2}
                md={1}
                lg={1}
                xl={1}
                alignSelf="center"
                justifyContent="flex-start"
                container
              >
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    color="primary"
                    sx={{ overflow: "auto" }}
                    style={{ border: "none" }}
                    onClick={() => {
                      let tempData = getParamsForView(
                        "subtract",
                        calendarApiArray
                      );
                      fetchBookingDataWithParams(tempData);
                      const arr = [];
                      const res = calendarApiArray.map((itemss, index) => {
                        itemss.prev();
                      });
                    }}
                  >
                    <ArrowBackIosNew />
                  </IconButton>

                  <Switch checked={showRoomName} onChange={handleChangeName} />
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={8}
                sm={8}
                md={7}
                lg={7}
                xl={7}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  container
                  justifyContent="center"
                  alignSelf="center"
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={today}
                      onChange={(newValue) => {
                        setToday(newValue);
                        const arr = [];
                        const res = calendarApiArray.map((itemss) => {
                          itemss.gotoDate(newValue);
                          arr.push(itemss);
                        });

                        // calendarApi.gotoDate(newValue);
                        setcalendarApiArray(arr);
                        let tempData = getParamsForView("date", arr);
                        fetchBookingDataWithParams(tempData);
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="h5">
                            {checkForEmptyCalendar()
                              ? calendarApiArray[0].view.title
                              : moment(new Date()).format("MMMM D, YYYY")}
                          </Typography>

                          <div ref={inputRef} {...inputProps}>
                            {InputProps?.endAdornment}
                          </div>
                        </Box>
                      )}
                    ></DatePicker>
                  </LocalizationProvider>
                  {unassingedCount > 0 && (
                    <></>
                    // <Badge
                    //   color="primary"
                    //   badgeContent={initialData.totalUnassignedReservations}
                    // >
                    //   <IconButton >
                    //     <EventSeat color="action" />
                    //   </IconButton>
                    // </Badge>
                    // <Tooltip
                    //   arrow
                    //   title={`${unassingedCount} Unassigned and Hold reservations`}
                    // >
                    //   {unassingedCount > 0 && (
                    //     <Typography
                    //       sx={{ padding: 2, cursor: "pointer" }}
                    //       onClick={() => {
                    //         setUnassignedDate(getStartEndDates());
                    //         setShowUnassigned(true);
                    //       }}
                    //     >
                    //       {`(${unassingedCount})`}
                    //     </Typography>
                    //   )}
                    // </Tooltip>
                  )}
                </Grid>
              </Grid>
              <Grid
                spacing={2}
                item
                xs={2}
                sm={2}
                md={2}
                lg={4}
                xl={4}
                alignSelf="center"
                justifyContent="flex-end"
                container
              >
                <Grid item container justifyContent="flex-end" xs={12}>
                  <ButtonGroup
                    sx={{ overflow: "auto", paddingLeft: 2, paddingRight: 3 }}
                    orientation={matches ? "horizontal" : "vertical"}
                    size={matches ? "medium" : "small"}
                  >
                    <Button
                      sx={{ textTransform: "none" }}
                      variant={
                        checkForEmptyCalendar() &&
                        calendarApiArray[0].view.type === "resourceTimelineDay"
                          ? "custom-button"
                          : "outlined"
                      }
                      onClick={() => {
                        sessionStorage.setItem("types", "day");
                        const arr = [];
                        const res = calendarApiArray.map((itemss) => {
                          itemss.changeView("resourceTimelineDay");
                          arr.push(itemss);
                        });
                        setcalendarApiArray(arr);
                        let tempData = getParamsForView("day", arr);
                        fetchBookingDataWithParams(tempData, "day");
                      }}
                    >
                      Day
                    </Button>
                    <Button
                      sx={{ textTransform: "none" }}
                      variant={
                        checkForEmptyCalendar() &&
                        calendarApiArray[0].view.type === "resourceTimelineWeek"
                          ? "custom-button"
                          : "outlined"
                      }
                      onClick={() => {
                        sessionStorage.setItem("types", "week");
                        const arr = [];
                        const res = calendarApiArray.map((itemss) => {
                          itemss.changeView("resourceTimelineWeek");
                          arr.push(itemss);
                        });
                        setcalendarApiArray(arr);
                        let tempData = getParamsForView("week", arr);
                        fetchBookingDataWithParams(tempData, "week");
                      }}
                    >
                      Week
                    </Button>
                    <Button
                      sx={{ textTransform: "none" }}
                      variant={
                        checkForEmptyCalendar() &&
                        calendarApiArray[0].view.type ===
                          "resourceTimelineMonthCustom"
                          ? "custom-button"
                          : "outlined"
                      }
                      onClick={() => {
                        sessionStorage.setItem("types", "month");
                        const arr = [];
                        const res = calendarApiArray.map((itemss) => {
                          itemss.changeView("resourceTimelineMonthCustom");
                          arr.push(itemss);
                        });

                        setcalendarApiArray(arr);

                        let tempData = getParamsForView("month", arr);
                        fetchBookingDataWithParams(tempData, "month");
                      }}
                    >
                      Month
                    </Button>
                  </ButtonGroup>

                  <IconButton
                    color="primary"
                    onClick={() => {
                      let tempData = getParamsForView("add", calendarApiArray);
                      fetchBookingDataWithParams(tempData);
                      const arr = [];
                      const res = calendarApiArray.map((itemss, index) => {
                        itemss.next();
                      });
                    }}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item display={"inline-flex"} xs={12} height="100%">
            {loading && (
              <Grid
                item
                xs={12}
                position={"fixed"}
                top="50%"
                left="50%"
                container
              >
                <DimmingDots />
              </Grid>
            )}
            <div
              style={{ width: showUnassigned ? "70%" : "100%" }}
              className="demo-app-main"
            >
              <FullCalendar
                // nowIndicator={true}
                refetchResourcesOnNavigate={true}
                eventResizableFromStart={true}
                resourceAreaWidth={100}
                schedulerLicenseKey="0974064232-fcs-1639721704"
                selectOverlap={item.timeType === "PURE_HOURLY"}
                views={{
                  resourceTimelineWeek: {
                    nowIndicator: true,
                    titleFormat: { month: "short", day: "2-digit" },
                    contentHeight: "auto",
                    height: "auto",
                    selectOverlap: item.timeType === "PURE_HOURLY",
                    slotLabelDidMount: (el) => {
                      if (
                        el.level === 1 ||
                        el.text === "12am" ||
                        el.text === "8am" ||
                        el.text === "4pm"
                      ) {
                        el.el.className = el.el.className + " dont-display";
                      }
                      if (el.level === 0) {
                        el.el.className = el.el.className + " special-display";
                        el.el.childNodes[0].className =
                          el.el.childNodes[0].className + " spe-height";
                      }
                    },
                    slotLabelWillUnmount: (el) => {
                      if (
                        el.level === 1 ||
                        el.text === "12am" ||
                        el.text === "8am" ||
                        el.text === "4pm"
                      ) {
                        el.el.className = el.el.className + " dont-display";
                      }
                      if (el.level === 0) {
                        el.el.className = el.el.className + " special-display";
                        el.el.childNodes[0].className =
                          el.el.childNodes[0].className + " spe-height";
                      }
                    },
                    slotMinTime: "00:00:00",
                    slotMaxTime: "24:00:00",
                    slotDuration:
                      item.timeType === "NOON"
                        ? Noonslot
                        : getSlotType(item.timeType),
                    //   ? "23:00:00" : hourToString[initialData.checkInTimes[initialData.checkInTimes.length - 1]].text,
                    slotLabelContent: (day) => {
                      if (day.level === 0) {
                        return (
                          <span>
                            <Tooltip
                              arrow
                              title={`Go to ${day.date.toDateString()}`}
                            >
                              <Typography
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const arr = [];
                                  setToday(day.date);

                                  const res = calendarApiArray.map((itemss) => {
                                    itemss.changeView("resourceTimelineDay");
                                    itemss.gotoDate(day.date);
                                    arr.push(itemss);
                                  });
                                  setcalendarApiArray(arr);
                                  // calendarApi.changeView("resourceTimelineDay");

                                  // calendarApi.gotoDate(day.date);
                                  let tempData = getParamsForView("date", arr);
                                  fetchBookingDataWithParams(tempData);
                                }}
                                sx={{
                                  color: "#2C3E50 !important",
                                  display: "flex",
                                  paddingRight: "4px",
                                  fontWeight: 600,
                                }}
                                component={"div"}
                                color={"inherit"}
                              >
                                {day.date.toDateString()}
                              </Typography>
                            </Tooltip>
                          </span>
                        );
                      } else {
                        return (
                          <Typography sx={{ color: "#2C3E50 !important" }}>
                            {day.text}
                          </Typography>
                        );
                      }
                    },
                    eventDidMount: (eve) => {
                      if (
                        eve.event.extendedProps.status === "CONFIRMED" ||
                        eve.event.extendedProps.status === "ADMIN_CONFIRMED"
                      ) {
                        var div = document.createElement("div");
                        div.className =
                          "fc-event-resizer fc-event-resizer-start";
                        var div1 = document.createElement("div");
                        div.className = "fc-event-resizer fc-event-resizer-end";
                        eve.el.appendChild(div);
                        eve.el.appendChild(div1);
                      }
                    },
                    eventContent: (arg) => {
                      const start = moment(
                        arg.event._instance.range.start
                      ).format("DD-MM-YYYY");
                      const end = moment(arg.event._instance.range.end).format(
                        "DD-MM-YYYY"
                      );

                      let days = moment(arg.event._instance.range.end).diff(
                        moment(arg.event._instance.range.start),
                        "days"
                      );

                      let len = arg.event.title.length;
                      let finalTital;
                      if (days === 0 && len > 10) {
                        finalTital = arg.event.title.substring(0, 6) + "...";
                      } else if (days === 1 && len > 30) {
                        finalTital = arg.event.title.substring(0, 25) + "...";
                      } else if (days === 2 && len > 45) {
                        finalTital = arg.event.title.substring(0, 40) + "...";
                      } else {
                        finalTital = arg.event.title;
                      }
                      return (
                        <div>
                          <div>
                            <div
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                fontWeight: 600,
                                fontSize: "1rem",
                                paddingLeft: "1rem",
                                color: "#2C3F51",
                              }}
                            >
                              {arg.event.extendedProps.locked_status ===
                                "LOCKED" && (
                                <Lock
                                  color={
                                    arg.event.extendedProps.status ===
                                      "CHECKED_OUT" ||
                                    arg.event.extendedProps.status ===
                                      "CONFIRMED" ||
                                    arg.event.extendedProps.status === "DONE"
                                      ? "primary"
                                      : "inherit"
                                  }
                                  sx={{ marginRight: 1 }}
                                />
                              )}

                              {finalTital}

                              {
                                <img
                                  src={getSource(
                                    arg.event.extendedProps.bookingSource
                                  )}
                                  style={{
                                    height: "2rem",
                                    marginLeft: "10px",
                                    borderRadius: "100%",
                                  }}
                                />
                              }
                              {arg.event.extendedProps.balanceDue > 0 && (
                                <BalanceIcon
                                  style={{
                                    marginLeft: "10px",
                                    color: "darkcyan",
                                  }}
                                />
                              )}
                              {arg.event.extendedProps.bookingNotes ===
                                "YES" && (
                                <SpeakerNotesIcon
                                  style={{
                                    marginLeft: "10px",
                                    color: "darkcyan",
                                  }}
                                />
                              )}
                              {arg.event.extendedProps.groupBooking ===
                                true && (
                                <GroupsIcon
                                  style={{
                                    marginLeft: "10px",
                                    color: "darkcyan",
                                  }}
                                />
                              )}

                              {arg.event.extendedProps.selfServiceStatus ===
                                "REQUESTED" && (
                                <RadioButtonUncheckedIcon
                                  style={{
                                    marginLeft: "10px",
                                    color: "darkcyan",
                                  }}
                                />
                              )}

                              {arg.event.extendedProps.selfServiceStatus ===
                                "APPROVED" && (
                                <CheckCircleIcon
                                  style={{
                                    marginLeft: "10px",
                                    color: "darkcyan",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          {/* </LightTooltip> */}
                        </div>
                      );
                    },
                  },
                  resourceTimelineDay: {
                    nowIndicator: true,
                    titleFormat: { month: "short", day: "2-digit" },
                    contentHeight: "auto",
                    height: "auto",
                    selectOverlap: false,
                    slotDuration: "01:00:00",
                    slotLabelContent: (data) => {
                      return (
                        <Typography
                          sx={{ color: "#2C3E50 !important", fontWeight: 600 }}
                        >
                          {data.text}
                        </Typography>
                      );
                    },
                    slotLaneContent: (day) => {
                      return (
                        <div>
                          <Typography>{day.time}</Typography>
                        </div>
                      );
                    },
                    eventContent: (arg) => {
                      return (
                        <div>
                          <div
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontWeight: 600,
                              fontSize: "1rem",
                              paddingLeft: "1rem",
                              color: "#2C3F51",
                            }}
                          >
                            {arg.event.extendedProps.locked_status ===
                              "LOCKED" && (
                              <Lock
                                color={
                                  arg.event.extendedProps.status ===
                                    "CHECKED_OUT" ||
                                  arg.event.extendedProps.status ===
                                    "CONFIRMED" ||
                                  arg.event.extendedProps.status === "DONE"
                                    ? "primary"
                                    : "inherit"
                                }
                                sx={{ marginRight: 1 }}
                              />
                            )}
                            {arg.event.title}

                            {
                              <img
                                src={getSource(
                                  arg.event.extendedProps.bookingSource
                                )}
                                style={{
                                  height: "2rem",
                                  marginLeft: "10px",
                                  borderRadius: "100%",
                                }}
                              />
                            }
                            {arg.event.extendedProps.balanceDue > 0 && (
                              <BalanceIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.bookingNotes === "YES" && (
                              <SpeakerNotesIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.groupBooking === true && (
                              <GroupsIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}

                            {arg.event.extendedProps.selfServiceStatus ===
                              "REQUESTED" && (
                              <RadioButtonUncheckedIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.selfServiceStatus ===
                              "APPROVED" && (
                              <CheckCircleIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                          </div>
                        </div>
                        // </LightTooltip>
                      );
                    },
                  },
                  resourceTimelineMonthCustom: {
                    nowIndicator: true,
                    titleFormat: { month: "short", day: "2-digit" },
                    contentHeight: "auto",
                    height: "auto",
                    type: "resourceTimeline",
                    selectOverlap: item.timeType === "PURE_HOURLY",
                    eventMaxStack: 2,
                    dayCount: 30,
                    duration: { days: 30 },
                    slotLabelContent: (day) => {
                      return (
                        <div style={{ width: "100%" }}>
                          {day.level === 0 && (
                            <span style={{ display: "flex" }}>
                              <Tooltip
                                title={`Go to ${day.date.toDateString()}`}
                                arrow
                              >
                                <Typography
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    //   calendarApi.changeView("resourceTimelineDay");
                                    setToday(day.date);
                                    //   calendarApi.gotoDate(day.date);
                                    let arr = [];
                                    const res = calendarApiArray.map(
                                      (itemss) => {
                                        itemss.changeView(
                                          "resourceTimelineDay"
                                        );
                                        itemss.gotoDate(day.date);
                                        arr.push(itemss);
                                      }
                                    );
                                    setcalendarApiArray(arr);
                                    let tempData = getParamsForView(
                                      "date",
                                      arr
                                    );
                                    fetchBookingDataWithParams(tempData);
                                  }}
                                  sx={{
                                    color: "#2C3E50 !important",
                                    display: "flex",
                                    paddingRight: "4px",
                                    fontWeight: 600,
                                  }}
                                  component={"div"}
                                >
                                  {day.text}
                                </Typography>
                              </Tooltip>
                            </span>
                          )}
                        </div>
                      );
                    },
                    eventContent: (arg) => {
                      return (
                        <div>
                          <div
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontWeight: 600,
                              fontSize: "1rem",
                              paddingLeft: "1rem",
                              color: "#2C3F51",
                            }}
                          >
                            {arg.event.extendedProps.locked_status ===
                              "LOCKED" && (
                              <Lock
                                color={
                                  arg.event.extendedProps.status ===
                                    "CHECKED_OUT" ||
                                  arg.event.extendedProps.status ===
                                    "CONFIRMED" ||
                                  arg.event.extendedProps.status === "DONE"
                                    ? "primary"
                                    : "inherit"
                                }
                                sx={{ marginRight: 1 }}
                              />
                            )}
                            {arg.event.title}

                            {
                              <img
                                src={getSource(
                                  arg.event.extendedProps.bookingSource
                                )}
                                style={{
                                  height: "2rem",
                                  marginLeft: "10px",
                                  borderRadius: "100%",
                                }}
                              />
                            }
                            {arg.event.extendedProps.balanceDue > 0 && (
                              <BalanceIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.bookingNotes === "YES" && (
                              <SpeakerNotesIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.groupBooking === true && (
                              <GroupsIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.selfServiceStatus ===
                              "REQUESTED" && (
                              <RadioButtonUncheckedIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.selfServiceStatus ===
                              "APPROVED" && (
                              <CheckCircleIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                          </div>
                        </div>
                        // </LightTooltip>
                      );
                    },
                  },
                }}
                droppable={false}
                contentHeight={"auto"}
                height="auto"
                scrollTime={"00:00"}
                forceEventDuration={true}
                firstDay={
                  checkForEmptyCalendar()
                    ? calendarApiArray[index].getDate().getDay()
                    : new Date().getDay()
                }
                lazyFetching={true}
                plugins={[interactionPlugin, resourceTimelinePlugin]}
                headerToolbar={false}
                resourceLabelContent={(arg) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        color: `${
                          showRoomName
                            ? arg.resource.extendedProps.roomTypeColor ===
                              "#ffffff"
                              ? "black"
                              : arg.resource.extendedProps.roomTypeColor
                            : "black"
                        }`,
                      }}
                      onClick={(e) => {
                        setdirtyRoomId(arg.resource.title);
                        if (
                          arg.resource.extendedProps.dirtyColor === "bg-danger"
                        ) {
                          handleOpen();
                        }
                      }}
                    >
                      <LightTooltip
                        title={arg.resource.extendedProps.roomType}
                        arrow
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {arg.resource.title}
                        </Typography>
                      </LightTooltip>
                      {arg.resource.extendedProps.dirtyColor ===
                        "bg-danger" && (
                        <FiberManualRecord
                          color="error"
                          fontSize="small"
                          sx={{ ml: 0.5, alignSelf: "center" }}
                        />
                      )}
                      {showRoomName && (
                        <LightTooltip
                          title={arg.resource.extendedProps.roomType}
                          arrow
                        >
                          <Typography sx={{ ml: 0.5, fontWeight: "bold" }}>
                            {` - ${arg.resource.extendedProps.roomTypeCode}`}
                          </Typography>
                        </LightTooltip>
                      )}
                    </div>
                  );
                }}
                eventResizeStart={() => {
                  setActionType("extend");
                  sethotelId(item.hotelId);
                  sethotelCurrency(item.currency);
                  sethotelCountry(item.country);
                  sethotelSlotType(item.timeType);
                }}
                eventDragStart={() => {
                  setActionType("DragorDrop");
                  sethotelId(item.hotelId);
                  sethotelCurrency(item.currency);
                  sethotelCountry(item.country);
                  sethotelSlotType(item.timeType);
                }}
                initialView="resourceTimelineWeek"
                initialDate={
                  // hotel.hotelCurrency === "INR"
                  1 === 1
                    ? new Date().toISOString()
                    : new Date(Date.now() - 3600 * 1000 * 24).toISOString()
                }
                selectable={true}
                events={
                  initailDataArray[index].bookingData

                  // initialData.bookingData
                }
                eventClick={(event) =>
                  handleDateSelect(
                    event,
                    initailDataArray[index],
                    item.hotelId,
                    item.timeType,
                    item.country,
                    item.currency
                  )
                }
                // resources={roomNumbers}
                resources={roomNumberArray[index]}
                resourceOrder={function (a, b) {
                  return parseInt(a.initalId) < parseInt(b.initalId)
                    ? -1
                    : a.initalId > b.initalId
                    ? 1
                    : 0;
                }}
                stickyHeaderDates={"auto"}
                eventOverlap={true}
                select={(event) =>
                  addNew(
                    event,
                    initailDataArray[index],
                    item.hotelId,
                    item.timeType,
                    item.country,
                    item.currency
                  )
                }
                // ref={calendarRefArray[index]}
                ref={(el) => (calRefArray.current[index] = el)}
                eventChange={handleDragStop}
                resourceAreaHeaderContent={item?.hotelName}
                eventClassNames={(arg) => {
                  if (arg.event.extendedProps.enquiry === "true") {
                    return ["fc-enquiry-event"];
                  } else {
                    if (arg.event.extendedProps.status === "CONFIRMED") {
                      return ["fc-confirmed-event"];
                    }

                    if (arg.event.extendedProps.status === "CHECKED_OUT") {
                      return ["fc-checkedout-event"];
                    }

                    if (arg.event.extendedProps.status === "DONE") {
                      return ["fc-checkedout-event"];
                    }
                    if (arg.event.extendedProps.status === "BLOCKED")
                      return ["fc-blocked-event"];

                    if (arg.event.extendedProps.status === "COMBO")
                      return ["fc-combo-room"];
                    return ["timeline-calendar"];
                  }
                }}
              />
            </div>
            {/* {showUnassigned && (
        <div
          style={{
            width: "30%",
            maxHeight: "100%",
            padding: "0px 16px 16px 16px",
          }}
        >
          <Slide in>
            <UnassignedView
              data={initialData}
              date={unassignedDate}
              closeFunc={() => {
                setShowUnassigned(false);
              }}
              reload={fetchBookingDataWithoutLoad}
            ></UnassignedView>
          </Slide>
        </div>
      )} */}
          </Grid>
        </Grid>
      ))}
    </>
  ) : (
    <MockLoaderTable />
  );
};

// export default GroupTimeline;

function mapStateToProps(state) {
  return { data: state.auth };
}

export default connect(mapStateToProps)(GroupTimeline);
