// import React from "react";
// import CancelIcon from "@mui/icons-material/Cancel";
// import LockIcon from "@mui/icons-material/Lock";
// import LockOpenIcon from "@mui/icons-material/LockOpen";
// import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
// import MoveDownIcon from "@mui/icons-material/MoveDown";
// import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
// import DateRangeIcon from "@mui/icons-material/DateRange";
// import CropFreeIcon from "@mui/icons-material/CropFree";
// import AccountTreeIcon from "@mui/icons-material/AccountTree";
// import HideImageIcon from "@mui/icons-material/HideImage";
// import EventRepeatIcon from "@mui/icons-material/EventRepeat";
// import TodayIcon from "@mui/icons-material/Today";
// import TimelapseIcon from "@mui/icons-material/Timelapse";
// import ReceiptIcon from "@mui/icons-material/Receipt";
// import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import FileUploadIcon from "@mui/icons-material/FileUpload";
// import PrintIcon from "@mui/icons-material/Print";
// import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

// function CardforButtons({
//   value,
//   heading,
//   transform,
//   marginLeft,
//   cardWidth,
//   cardHeight,
//   fontSize,
//   boxShadow,
// }) {
//   return (
//     <div
//       className="informationCardWrapper"
//       style={{
//         boxShadow: boxShadow,
//         transform: transform,
//         marginLeft: marginLeft,
//         width: "9.5rem",
//         height: "5rem",
//       }}
//     >
//       <div className="figure" style={{ marginTop: "5px", fontSize: "0" }}>
//         {heading === "View folio" && (
//           <ReceiptIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {(heading === "Upload Back id" || heading === "Upload front id") && (
//           <FileUploadIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Print Reg. Card" && (
//           <PrintIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Confirm booking" && (
//           <ConfirmationNumberIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}

//         {heading === "Send Magic Link" && (
//           <AutoFixHighIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Check-in" && (
//           <CheckCircleOutlineIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Settle dues" && (
//           <AttachMoneyIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Checkout" && (
//           <CheckCircleIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Hold booking" && (
//           <TimelapseIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Cancel booking" && (
//           <CancelIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Set to no show" && (
//           <HideImageIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Lock booking" && (
//           <LockIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Unlock booking" && (
//           <LockOpenIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Unassign room" && (
//           <HorizontalSplitIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Modify checkin" && (
//           <EventRepeatIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Modify checkout" && (
//           <TodayIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Edit reservation" && (
//           <DateRangeIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Split Room" && (
//           <AccountTreeIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Move Room" && (
//           <MoveDownIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//         {heading === "Rollback booking" && (
//           <SettingsBackupRestoreIcon
//             style={{ fontSize: "2rem", color: "gray" }}
//           />
//         )}

//         {heading === "Release inventory" && (
//           <CropFreeIcon style={{ fontSize: "2rem", color: "gray" }} />
//         )}
//       </div>
//       <div
//         className="heading"
//         style={{
//           width: "11.5rem",
//           color: "black",
//         }}
//       >
//         {heading}
//       </div>
//     </div>
//   );
// }

// export default CardforButtons;

import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CropFreeIcon from "@mui/icons-material/CropFree";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HideImageIcon from "@mui/icons-material/HideImage";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import TodayIcon from "@mui/icons-material/Today";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PrintIcon from "@mui/icons-material/Print";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

function CardforButtons({
  value,
  heading,
  transform,
  marginLeft,
  cardWidth,
  cardHeight,
  fontSize,
  boxShadow,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  // Define the default and hover colors for icon and heading
  const defaultIconColor = "gray";
  const hoverIconColor = "#1ABBB3";
  const defaultHeadingColor = "black";
  const hoverHeadingColor = "#1ABBB3";

  // Define a function to get the icon based on the heading
  const getIcon = () => {
    switch (heading) {
      case "View folio":
        return (
          <ReceiptIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Upload Back id":
      case "Upload front id":
        return (
          <FileUploadIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Print Reg. Card":
        return (
          <PrintIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );

      case "Confirm booking":
        return (
          <ConfirmationNumberIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );

      case "Send Magic Link":
        return (
          <AutoFixHighIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );

      case "Check-in":
        return (
          <CheckCircleOutlineIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );

      case "Settle dues":
        return (
          <AttachMoneyIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );

      case "Checkout":
        return (
          <CheckCircleIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Hold booking":
        return (
          <TimelapseIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Cancel booking":
        return (
          <CancelIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Set to no show":
        return (
          <HideImageIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Lock booking":
        return (
          <LockIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Unlock booking":
        return (
          <LockOpenIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Unassign room":
        return (
          <HorizontalSplitIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Modify checkin":
        return (
          <EventRepeatIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Modify checkout":
        return (
          <TodayIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Edit reservation":
        return (
          <DateRangeIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Split Room":
        return (
          <AccountTreeIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Move Room":
        return (
          <MoveDownIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Rollback booking":
        return (
          <SettingsBackupRestoreIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      case "Release inventory":
        return (
          <CropFreeIcon
            style={{
              fontSize: "2rem",
              color: isHovered ? hoverIconColor : defaultIconColor,
            }}
          />
        );
      // Add cases for other headings and icons here
      default:
        return null;
    }
  };

  return (
    <div
      className="informationCardWrapper"
      style={{
        boxShadow: boxShadow,
        transform: transform,
        marginLeft: marginLeft,
        width: "9.5rem",
        height: "5rem",
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="figure" style={{ marginTop: "5px", fontSize: "0" }}>
        {getIcon()}
      </div>
      <div
        className="heading"
        style={{
          width: "11.5rem",
          color: isHovered ? hoverHeadingColor : defaultHeadingColor,
        }}
      >
        {heading}
      </div>
    </div>
  );
}

export default CardforButtons;
