import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  Grow,
  IconButton,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { dateFormat, displaySlots } from "../constants/Calendar-constants";
import { blockMultipleRoomsWithHotelId } from "../../InventoryCalendar/api/InventoryApi";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "material-react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const GroupBlockView = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const {
    open,
    eventData,
    closeFunc,
    reload,
    modalClose,
    reloadagain,
    viewType,
    hotelId,
    hotelSlotType,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentDateSelection, setCurrentDateSelection] = useState([
    {
      startDate: eventData.start,
      endDate:
        viewType !== "resourceTimelineDay"
          ? viewType === "resourceTimelineWeek" &&
            hotelSlotType !== "NOON" &&
            displaySlots[eventData.end.getHours()] !== 0
            ? eventData.end
            : new Date(eventData.end).setDate(eventData.end.getDate() - 1)
          : eventData.end,
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [reason, setReason] = useState("");

  const unblock = async () => {
    try {
      setLoading(true);
      const payload = {
        startDate: moment(currentDateSelection[0].startDate).format(dateFormat),
        endDate: moment(currentDateSelection[0].endDate).format(dateFormat),
        physicalRoomIds: [eventData.resource.id],
        reason: reason,
      };
      await blockMultipleRoomsWithHotelId([payload], hotelId);
      reload();
      if (sessionStorage.getItem("types") === "month") {
        reloadagain();
        reloadagain();
      }

      closeFunc();
    } catch (e) {
      toast.error(
        "Selected rooms could not be blocked since they have future reservations."
      );
      console.log(e);
      closeFunc();
    }
  };

  const getDateText = () => {
    let val = "";
    val =
      val +
      moment(currentDateSelection[0].startDate).format("DD MMM YYYY") +
      " - ";
    val = val + moment(currentDateSelection[0].endDate).format("DD MMM YYYY");
    return val;
  };

  return (
    <div className={classes.root}>
      <CommonSlider
        onBackdropClick={closeFunc}
        onClose={modalClose}
        open={open}
      >
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5">Block rooms</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => {
                modalClose();
              }}
              sx={{ float: "right" }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.textField}>
          <Grid item xs={12}>
            <Box className={classes.custom_box} bgcolor="primary.main">
              <Typography>
                {`You are currently blocking room number : ${eventData.resource.id}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div>
              <ClickAwayListener
                onClickAway={() => {
                  setShowDatePicker(false);
                  setAnchorEl(null);
                }}
              >
                <div>
                  <TextField
                    fullWidth
                    focused={showDatePicker}
                    id="currentDateSelection"
                    name="currentDateSelection"
                    InputProps={{ readOnly: true }}
                    value={getDateText()}
                    label="Date range"
                    onClick={(eve) => {
                      setAnchorEl(eve.currentTarget);
                      setShowDatePicker(true);
                    }}
                  ></TextField>
                  {showDatePicker && (
                    <Popper
                      style={{ zIndex: 2 }}
                      open={showDatePicker}
                      anchorEl={anchorEl}
                      placement="bottom"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          style={{
                            transformOrigin: (placement = "bottom-end"),
                          }}
                          {...TransitionProps}
                        >
                          <Paper
                            elevation={5}
                            className="inventory-daterange-custom"
                          >
                            <DateRange
                              color="#1BC5BD"
                              rangeColors={["#1BC5BD"]}
                              editableDateInputs={false}
                              moveRangeOnFirstSelection={false}
                              onChange={(data) => {
                                setCurrentDateSelection([{ ...data.range1 }]);
                              }}
                              ranges={currentDateSelection}
                            ></DateRange>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={reason}
              label="Reason"
              fullWidth
              multiline
              rows={3}
              onChange={(eve) => {
                setReason(eve.target.value);
              }}
            ></TextField>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            className={classes.textField}
          >
            <Fab
              disabled={loading}
              type="button"
              color="primary"
              onClick={unblock}
              variant="extended"
            >
              Block room
              {loading && (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              )}
            </Fab>
          </Grid>
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default GroupBlockView;
