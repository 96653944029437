import React, { useEffect, useState } from "react";
import InvoiceSummary from "./InvoiceSummary";
import InvoiceWrapper from "./InvoiceWrapper";
import { absoluteURL } from "../../Utils/index";
import axios from "axios";
import "react-responsive-modal/styles.css";
// import { Modal } from 'react-responsive-modal';
import AdvancedPortfolioWrapper from "./Advanced Portfolio/AdvancedPortfolioWrapper";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { djangoHandleDataRequests, handleSpringDataRequest } from "../../api";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { StyleSheet } from "@react-pdf/renderer";
import { connect } from "react-redux";
import MockInvoiceWrapper from "./MockInvoiceWrapper";
import MockInvoiceSummary from "./MockInvoiceSummary";
import Currencies from "../../Utils/Currencies.json";
import { useParams, useHistory } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import {
  AMPLITUDE_KEY,
  ENV,
} from "../CalendarPage/constants/AmplitudeConstants";
import amplitude from "amplitude-js";
import DeniedAccess from "../AccessControl/Views/DeniedAccess";

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  width: "fit-content",
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  padding: "12px",
  borderRadius: "25px",
  outline: 0,
  overflowY: "scroll",
  textAlign: "center",
};

// styling for the IOS Switch

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  borderRadius: 20,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? "#2ECA45"
            : "linear-gradient(-150deg, #1ab394, #1a91ae)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// creating the styles for downloading the invoice
// Create styles
const invoiceStyle = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

function Folios({
  onOpenCustModal,
  onOpenAddServiceModal,
  booking_id,
  onCloseInvoice,
  data,
  fetchBookingData,
  isGrid,
}) {
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;
  // const hotelCurrency = localStorage.getItem("hotelCurrency");
  const [hotelSymbol, setHotelSymbol] = useState("");
  const [loggedUserEmail, setLoggedUserEmail] = useState(null);
  const history = useHistory();

  const { bookingId } = useParams();

  //Night Audit
  const [isNightAuditOn, setIsNightAuditOn] = useState(false);
  const getNightAuditStatus = () => {
    handleSpringDataRequest(`core/api/v1/nightAudit/get-night-audit-status`)
      .then((data) => {
        setIsNightAuditOn(data);
      })
      .catch((err) => console.error(err));
  };
  // Storing the booking id
  // const [bookingId, setBookingId] = useState(null)
  // useEffect(() => {
  //     setBookingId(booking_id)
  // }, [booking_id])

  // Getting booking id

  //Amplitude check on Folio page
  const amplitudeEventProperties = {
    hotel_id: data?.hotelId,
    environment: ENV,
    email: data?.accessControl?.email,
    role: data?.accessControl?.ADMINRIGHTS,
  };

  useEffect(() => {
    amplitude.getInstance().init(AMPLITUDE_KEY, data?.hotelId, {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
    });
    amplitude
      .getInstance()
      .identify(
        new amplitude.Identify()
          .set("hotel_id", data?.hotelId)
          .set("email", data?.accessControl?.email)
          .set("role", data?.accessControl?.ADMINRIGHTS)
      );
    amplitude
      .getInstance()
      .logEvent("FOLIO PAGE LOADED", amplitudeEventProperties);
  }, []);

  const user_email = localStorage.getItem("rootEmail");
  const [isMasterUser, setisMasterUser] = useState(false);
  useEffect(() => {
    user_email !== null &&
      handleSpringDataRequest(`user/check-master-user?email=${user_email}`)
        .then((res) => setisMasterUser(res))

        .catch((err) => console.error("Please try again later."));
  }, []);

  useEffect(() => {
    // setLoggedUserEmail(data?.accessControl?.email);
    setLoggedUserEmail(localStorage.getItem("email"));
    getNightAuditStatus();
  }, []);

  useEffect(() => {
    if (isMasterUser && user_email !== null) {
      setLoggedUserEmail(user_email);
    }
  }, [isMasterUser]);

  console.log("logged user email : ", loggedUserEmail);

  // getting the hotel currency
  const getHotelCurrency = () =>
    Currencies?.filter((item) => {
      if (item.code === hotelCurrency) {
        sessionStorage.setItem("symbol", item.symbol);
        return setHotelSymbol(item.symbol);
      }
    });

  useEffect(() => {
    bookingId && getHotelCurrency();
  }, [bookingId]);

  // Showing up the mock loaders for further services at invoice level
  // 1. For wrapper
  const [wrapperMockLoader, setWrapperMockLoader] = useState(false);
  const handleTrueWrapperMockLoader = () => setWrapperMockLoader(true);
  const handleFalseWrapperMockLoader = () => setWrapperMockLoader(false);
  //2. For summary data
  const [summaryMockLoader, setSummaryMockLoader] = useState(false);
  const handleTrueSummaryMockLoader = () => setSummaryMockLoader(true);
  const handleFalseSummaryMockLoader = () => setSummaryMockLoader(false);

  // storing the api methods
  const [invoiceData, setInvoiceData] = useState(null);
  const [advancedInvoiceData, setAdvancedInvoiceData] = useState(null);

  // calling the api --- getting the invoice data
  const [userEmail, setUserEmail] = useState(null);

  // Getting the invoice data for the first time ---  from the cache
  const getData = () => {
    handleTrueWrapperMockLoader();
    setUserEmail(null);
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-data-render/?bookingId=${bookingId}&fromDb=false`
    )
      .then((res) => {
        setInvoiceData(res);
        handleFalseWrapperMockLoader();
      })
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error("Error fetching the details"));
  };

  const getUpdatedData = () => {
    handleTrueWrapperMockLoader();
    setInvoiceData(null);
    setUserEmail(null);
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-data-render/?bookingId=${bookingId}&fromDB=true`
    )
      .then((res) => setInvoiceData(res))
      .then((res) => handleFalseWrapperMockLoader())
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error("Error fetching the details"));
  };

  useEffect(() => {
    bookingId && getData();
  }, [bookingId]);

  // Setting the user email
  useEffect(() => {
    if (invoiceData) {
      setUserEmail(invoiceData?.invoice?.customer_email);
    }
  }, [bookingId, invoiceData]);

  // calling the advanced portfolio
  const getAdvancedData = () => {
    setAdvancedInvoiceData(null);
    djangoHandleDataRequests(
      `invoice/advancedInvoiceDataRender?bookingID=${bookingId}`
    )
      .then((res) => setAdvancedInvoiceData(res))
      .catch((err) =>
        toast.error("Error fetching the advanced invoice details")
      );
  };

  // Get the data for invoice summary
  const [summaryData, setSummaryData] = useState(null);
  const handleGetSummaryDataWithCache = () => {
    handleTrueSummaryMockLoader();
    setSummaryData(null);
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-summary-data/?bookingId=${bookingId}&fromDB=false`
    )
      .then((res) => setSummaryData(res))
      .then((res) => handleFalseSummaryMockLoader())
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error("Failed in getting the payment summary"));
  };

  const handleGetSummaryDataWithoutCache = () => {
    handleTrueSummaryMockLoader();
    setSummaryData(null);
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-summary-data/?bookingId=${bookingId}&fromDB=true`
    )
      .then((res) => setSummaryData(res))
      .then((res) => handleFalseSummaryMockLoader())
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error("Failed in getting the payment summary"));
  };

  // Get the room breakup data for Group bookings
  const [roomBreakupData, setRoomBreakupData] = useState(null);
  const handleGetRoomBreakupData = () => {
    handleSpringDataRequest(
      `core/api/v1/folio/get-room-breakup-data/?bookingId=${bookingId}`
    )
      .then((res) => setRoomBreakupData(res))
      .catch((err) => toast.error("error in getting room breakup data"));
  };

  // Get complimentary booking information (for isha)
  const [compBooking, setCompBooking] = useState(null);
  const handleGetComplimentaryBooking = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/get-complimentary-info/?bookingId=${bookingId}`
    )
      .then((res) => setCompBooking(res))
      .catch((err) =>
        toast.error("error in getting complimentary information")
      );
  };

  //Res ID to room mapping
  const [roomIdResMap, setRoomIdResMap] = useState([]);
  useEffect(() => {
    if (roomBreakupData?.length > 0) {
      roomBreakupData.map((resData, index) => {
        roomIdResMap.push({
          roomId: resData?.room_id,
          resId: resData?.res_id,
        });
      });
    }
  }, [roomBreakupData]);

  useEffect(() => {
    if (bookingId) {
      // handleGetSummaryDataWithCache();
      handleGetRoomBreakupData();
    }
  }, [bookingId]);

  // settle dues modals
  // cash modal
  const [openCashPaymentModal, setOpenCashPaymentModal] = useState(false);
  const onOpenCashPaymentModal = () => setOpenCashPaymentModal(true);
  const onCloseCashPaymentModal = () => setOpenCashPaymentModal(false);
  // offline card payment
  const [openOfflineCardPaymentModal, setOpenOfflineCardPaymentModal] =
    useState(false);
  const onOpenOfflineCardPaymentModal = () =>
    setOpenOfflineCardPaymentModal(true);
  const onCloseOfflineCardPaymentModal = () =>
    setOpenOfflineCardPaymentModal(false);
  // offline cheque payment
  const [openOfflineCheckPaymentModal, setOpenOfflineCheckPaymentModal] =
    useState(false);
  const onOpenOfflineCheckPaymentModal = () =>
    setOpenOfflineCheckPaymentModal(true);
  const onCloseOfflineCheckPaymentModal = () =>
    setOpenOfflineCheckPaymentModal(false);

  const windowWidth = window.screen.width;

  // advanced portfolio side drawer

  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const advancedPortfolio = (anchor) => (
    <Box
      sx={{
        width:
          anchor === "top" || anchor === "bottom" ? "auto" : windowWidth - 100,
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          padding: "25px",
        }}
      >
        <div style={{ width: "100%", position: "relative" }}>
          <img
            src={absoluteURL("../../media/folios/cross.svg")}
            alt=""
            style={{
              height: "25px",
              width: "25px",
              float: "right",
              marginRight: "25px",
              cursor: "pointer",
            }}
            onClick={toggleDrawer("right", false)}
          />
        </div>
        <AdvancedPortfolioWrapper
          onCloseAdvFolio={toggleDrawer("right", false)}
        />
      </div>
    </Box>
  );

  // OTA BOOKING INFO DATAs
  // Get the OTA booking info data
  const [otaBookingInfoData, setOtaBookingInfoData] = useState([]);
  const getOtaBookingInfoData = () => {
    djangoHandleDataRequests(
      `invoice/getOTADetailsForBooking/?bookingID=${bookingId}`
    )
      .then((res) => setOtaBookingInfoData(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(err));
  };

  // Getting custom pg data
  const [customPgData, setCustomPgData] = useState(null);
  const getCustomPgData = () => {
    djangoHandleDataRequests(`payments/getHotelCustomPgInfo/`)
      .then((res) => setCustomPgData(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    bookingId && getOtaBookingInfoData();
    bookingId && getCustomPgData();
  }, [bookingId]);

  // Get custom folio config
  const [customFolioConfig, setCustomFolioConfig] = useState(null);
  const getCustomFolioConfig = () => {
    djangoHandleDataRequests("invoice/getCustomFolioConfig/")
      .then((res) => setCustomFolioConfig(res !== null ? res : []))
      // .then((res) => fetchBookingData())
      .catch((err) => toast.error("Error getting the folio config data"));
  };

  useEffect(() => {
    bookingId && getCustomFolioConfig();
  }, [bookingId]);

  // Getting the hotel logo
  const [base64Img, setBase64Img] = useState(null);
  const handleBase64Img = () => {
    handleSpringDataRequest("core/api/v1/image/get-hotel-base64-logo/")
      .then((res) => setBase64Img(res))
      .catch((err) => toast.error("Error in fethcing the hotel logo!"));
  };

  // useEffect(() => {
  //   handleBase64Img();
  // }, []);

  // Fetch the hotel terms and conditions and display while printing the folio
  const [hotelTermsConditions, setHotelTermsConditions] = useState(null);
  const getHotelTermsConditions = () => {
    handleSpringDataRequest("core/api/v1/pc/get-hotel-tc")
      // .then((res) =>
      //   setHotelTermsConditions(JSON.parse(res.message).termCondition.join())
      // )
      .then(
        (res) =>
          res &&
          localStorage.setItem("tnc", JSON.parse(res.message).termCondition)
      )
      .catch((err) =>
        toast.error("Error in getting the hotel terms and conditions.")
      );
  };

  useEffect(() => {
    getHotelTermsConditions();
  }, [hotelId]);

  useEffect(() => {
    sessionStorage.setItem("folioType", false);
  }, []);

  // View payment modal
  // const [openViewManagePayments, setOpenViewManagePayments] = useState(false);
  // const handleOpenViewManagePayments = () => {
  //   setOpenViewManagePayments(true);
  // };
  // const handleCloseViewManagePayments = () => setOpenViewManagePayments(false);

  // getting the hotel currency
  // const hotelCurrency = localStorage.getItem("hotelCurrency");
  const [convertedAmount, setconvertedAmount] = useState(0);

  const [hotelConvertedCurrency, sethotelConvertedCurrency] =
    useState(hotelCurrency);
  const [hotelConvertedCurrencySymbol, sethotelConvertedCurrencySymbol] =
    useState(hotelSymbol);

  const getConvertedvalue = async () => {
    if (
      sessionStorage.getItem("defaultRate") === 0 &&
      sessionStorage.getItem("defaultRate") === undefined &&
      sessionStorage.getItem("defaultRate") === null
    ) {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}`
      );
      setconvertedAmount(data.data);
    } else {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}&amount=${sessionStorage.getItem("defaultRate")}`
      );
      setconvertedAmount(data.data);
    }
  };

  const updateCurrency = () => {
    sethotelConvertedCurrency(sessionStorage.getItem("convertedHotelCurrency"));
    sethotelConvertedCurrencySymbol(
      sessionStorage.getItem("convertedHotelCurrencySymbol")
    );
    getConvertedvalue();
  };

  // Custom Folio
  // FETCH - All the custom folios created
  const [customFolios, setCustomFolios] = useState([]);
  function handleGetAllCustomFolios() {
    handleSpringDataRequest(
      `core/api/v1/folio/get-custom-folio-list?bookingId=${bookingId}`
    )
      .then((res) => setCustomFolios(res))
      .catch((err) => toast.error(err));
  }
  useEffect(() => {
    handleGetAllCustomFolios();
  }, [bookingId]);

  // controlling the switch
  // There will be three default invoices
  // Summary invoice : -1, Adv. invoice : -2, Detailed invoice : -3

  const [checked, setChecked] = useState(
    sessionStorage.getItem("folioType") === null ||
      sessionStorage.getItem("folioType") === "false"
      ? "-1"
      : sessionStorage.getItem("folioType")
  );

  const handleFolioType = (event) => {
    setChecked(event.target.value);
    sessionStorage.setItem("folioType", String(event.target.value));
    if (event.target.value === "-1") {
      getUpdatedData();
    }
  };

  // Get the summary data in case custom folio type gets changed
  const [customFolioSummary, setCustomFolioSummary] = useState(null);
  function handleGetFolioSummaryData() {
    handleTrueSummaryMockLoader();
    setCustomFolioSummary(null);
    handleSpringDataRequest(
      `core/api/v1/folio/get-custom-folio-invoice-summary?bookingId=${bookingId}&customFolioId=${checked}`
    )
      .then((res) => setCustomFolioSummary(res))
      .then((res) => handleFalseSummaryMockLoader())
      .catch((err) => toast.error(err));
  }

  useEffect(() => {
    if (!["-1", "-2", "-3"].includes(checked)) {
      // debugger;
      handleGetFolioSummaryData();
    }
    // else {
    handleGetSummaryDataWithCache();
    // }
  }, [checked, bookingId]);

  //------------------
  const [igst, setIgst] = useState(null);
  const [igstLoader, setIgstLoader] = useState(false);
  useEffect(() => {
    setIgstLoader(true);
    handleSpringDataRequest("core/api/v1/igst-config/get")
      .then((data) => {
        setIgst(data);
        setIgstLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setIgstLoader(false);
      });
  }, []);

  return (
    <>
      {JSON.parse(data?.accessControl?.user_feature_map?.Invoices).read !==
      "YES" ? (
        <DeniedAccess />
      ) : (
        <div className="foliosWrapper">
          {/* <div className="foliosHeader">
          <div
            className="position-relative w-100 text-right mb-2"
            style={{ cursor: "pointer", width: "fit-content" }}
            // onClick={() => history.goBack()}
            onClick={() => window.close()}
          >
            <AiIcons.AiOutlineClose size="22.5px" />
          </div>
        </div> */}
          <div className="folioContentWrapper">
            {invoiceData &&
            loggedUserEmail &&
            (!["-1", "-2", "-3"].includes(checked)
              ? customFolioSummary
              : summaryData) &&
            summaryData &&
            bookingId &&
            customPgData &&
            customFolioConfig &&
            !igstLoader &&
            userEmail !== null ? (
              <div className="invoice">
                <InvoiceWrapper
                  bookingId={bookingId}
                  onClickCustEditBtn={() => onOpenCustModal()}
                  onClickAddServiceBtn={() => onOpenAddServiceModal()}
                  invoiceData={invoiceData}
                  updateInvoice={() => getUpdatedData()}
                  updateAdvInvoice={() => getAdvancedData()}
                  checked={checked}
                  handleFolioType={handleFolioType}
                  currency={hotelSymbol}
                  loggedUserEmail={loggedUserEmail}
                  summaryData={summaryData}
                  updateSummaryData={() => handleGetSummaryDataWithoutCache()}
                  // updateSummaryData={() => handleGetSummaryDataWithCache()}
                  otaBookingInfoData={otaBookingInfoData}
                  customPgData={customPgData}
                  updateOtaBookingInfo={() => getOtaBookingInfoData()}
                  updateHotelPgData={() => getCustomPgData()}
                  handleTrueWrapperMockLoader={() =>
                    handleTrueWrapperMockLoader()
                  }
                  handleFalseWrapperMockLoader={() =>
                    handleFalseWrapperMockLoader()
                  }
                  handleTrueSummaryMockLoader={() =>
                    handleTrueSummaryMockLoader()
                  }
                  handleFalseSummaryMockLoader={() =>
                    handleFalseSummaryMockLoader()
                  }
                  customFolioConfig={customFolioConfig}
                  hotelLogo={base64Img}
                  fetchBookingData={fetchBookingData}
                  isGrid={isGrid}
                  handleBase64Img={handleBase64Img}
                  updateCurrency={() => updateCurrency()}
                  roomBreakupData={roomBreakupData}
                  updateRoomBreakupData={() => handleGetRoomBreakupData()}
                  roomIdMapping={roomIdResMap}
                  customFolios={customFolios}
                  handleGetAllCustomFolios={handleGetAllCustomFolios}
                  customFolioSummary={customFolioSummary ?? []}
                  getUpdatedData={getUpdatedData}
                  igst={igst}
                  isNightAuditOn={isNightAuditOn}
                />
              </div>
            ) : (
              <div className="invoice">
                <MockInvoiceWrapper />
              </div>
            )}
            {invoiceData &&
            loggedUserEmail &&
            (!["-1", "-2", "-3"].includes(checked)
              ? customFolioSummary
              : summaryData) &&
            summaryData &&
            // base64Img &&
            bookingId &&
            customPgData &&
            customFolioConfig &&
            !igstLoader &&
            userEmail !== null ? (
              <div className="billBreakdown">
                <InvoiceSummary
                  bookingId={bookingId}
                  folioSummary={invoiceData}
                  updateInvoice={() => getUpdatedData()}
                  updateAdvInvoice={() => getAdvancedData()}
                  currency={hotelSymbol}
                  loggedUserEmail={loggedUserEmail}
                  summaryData={
                    ["-1", "-2", "-3"].includes(checked)
                      ? summaryData
                      : customFolioSummary
                  }
                  updateSummaryData={() => handleGetSummaryDataWithoutCache()}
                  // updateSummaryData={() => handleGetSummaryDataWithCache()}
                  updateSummaryDataFromCache={() =>
                    handleGetSummaryDataWithCache()
                  }
                  otaBookingInfoData={otaBookingInfoData}
                  customPgData={customPgData}
                  updateOtaBookingInfo={() => getOtaBookingInfoData()}
                  updateHotelPgData={() => getCustomPgData()}
                  handleTrueWrapperMockLoader={() =>
                    handleTrueWrapperMockLoader()
                  }
                  handleFalseWrapperMockLoader={() =>
                    handleFalseWrapperMockLoader()
                  }
                  handleTrueSummaryMockLoader={() =>
                    handleTrueSummaryMockLoader()
                  }
                  handleFalseSummaryMockLoader={() =>
                    handleFalseSummaryMockLoader()
                  }
                  customFolioConfig={customFolioConfig}
                  hotelLogo={base64Img}
                  fetchBookingData={fetchBookingData}
                  isGrid={isGrid}
                  handleBase64Img={handleBase64Img}
                  convertedAmount={convertedAmount}
                  hotelConvertedCurrency={hotelConvertedCurrency}
                  hotelConvertedCurrencySymbol={hotelConvertedCurrencySymbol}
                  hotelCurrency={hotelCurrency}
                  roomIdMapping={roomIdResMap}
                  checked={checked}
                  userEmail={userEmail}
                  // customFolios={customFolios}
                  igst={igst}
                  isNightAuditOn={isNightAuditOn}
                />
              </div>
            ) : (
              <>
                <div className="billBreakdown">
                  <MockInvoiceSummary />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(Folios);
