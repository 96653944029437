// ** React Imports
import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, MoreVert } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import {
  getReservationInfoWithHotelId,
  getReservationInfoDjangoWithHotelId,
  updateCustomerWithHotelId,
  updateCustomerResWithHotelId,
} from "../CalendarPage/api/ReservationApi";
import { ReservationInfoModel } from "../CalendarPage/models/RservationInfoModel";
import CustomerInformation from "../CalendarPage/Views/CustomerInformation";

import CustomerData from "../CalendarPage/Views/BookingInformation";
import CommonSlider from "../CalendarPage/Views/CommonSlider";

import DimmingDotsLoader from "../CalendarPage/Views/LoaderCustom";
import moment from "moment";
import { updateEnquiryStatusWithHotelId } from "../BookingDashboard/apis/BookingDashboardApi";
import {
  channelLogoMapDash,
  customErrorMessage,
} from "../CalendarPage/constants/Calendar-constants";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";

import {
  getAllGuestUsersWithHotelId,
  getBookingUserDetailsWithHotelId,
} from "../Promocodes/apis/PromocodeApi";
import Notes from "../CalendarPage/Views/Notes";
import GroupCustomerInformation from "./GroupCustomerInformation";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
}));

const GroupEditModalView = (props) => {
  let [reservationData, setReservationData] = useState(
    new ReservationInfoModel({})
  );
  let [loadingData, setLoadingData] = useState(true);
  let [load, setLoad] = useState(false);
  let [tabvalue, setTabValue] = useState("booking-information");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const [menuValue, setMenuValue] = useState("");

  const classes = useStyles();
  const {
    lockEvent,
    viewFolio,
    eventData,
    handleAddEventSidebar,
    open,
    addEvent,
    reload,
    removeEvent,
    roomInformation,
    roomTypeInfo,
    hotelId,
    hotelSlotType,
    hotelCountry,
    hotelCurrency,
  } = props;

  const [userEmail, setUserEmail] = useState(null);
  useEffect(() => {
    setUserEmail(reservationData?.user_email);
  }, [reservationData]);

  const [doNotRent, setDoNotRent] = useState(null);

  const getDoNotRentStatus = (email) => {
    handleSpringDataRequest(
      `core/api/v1/do-not-rent/get-all?emailId=${email}` +
        "&hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
      .then((res) => {
        setDoNotRent(res[0]);
      })
      .catch((err) => {
        console.log("The err is : ", err);
      });
  };

  useEffect(() => {
    userEmail && getDoNotRentStatus(userEmail);
  }, [userEmail]);

  const saveCustomer = async (data, id, name) => {
    setLoad(true);
    try {
      await updateCustomerWithHotelId(data, id, hotelId);
      eventData.event.setProp("title", name);
      reload();
      setLoad(false);
      // try(
      //   reloadReservationData()
      // )catch(e){

      // }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.log(e);
      setLoad(false);
    }
  };

  const saveCustomerRes = async (data, id, name) => {
    setLoad(true);
    try {
      await updateCustomerResWithHotelId(data, id, hotelId);
      eventData.event.setProp("title", name);
      reload();
      setLoad(false);
      reloadReservationData();
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.log(e);
      setLoad(false);
    }
  };

  const [otaLogo, setotaLogo] = useState("");
  const reloadReservationData = async () => {
    try {
      let data;
      if (`${process.env.REACT_APP_ENV}` === "LOCAL") {
        data = await getReservationInfoWithHotelId(
          eventData.event.extendedProps.reservationId,
          hotelId
        );
      } else {
        data = await getReservationInfoDjangoWithHotelId(
          eventData.event.extendedProps.reservationId,
          hotelId
        );

        let logo = channelLogoMapDash[data?.booking_source];
        if (logo === undefined) logo = "";
        setotaLogo(logo);
      }

      const temp = new ReservationInfoModel(data);
      setReservationData(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const [allGuestList, setAllGuestList] = useState([]);
  // const fetchBookingUserDetail = async () => {
  //   const data = await getBookingUserDetails(reservationData?.booking_id);
  // };

  const fetchGuestList = async () => {
    const booking_data = await getBookingUserDetailsWithHotelId(
      reservationData?.booking_id,
      hotelId
    );
    const data = await getAllGuestUsersWithHotelId(
      reservationData?.booking_id,
      hotelId
    );
    let primaryGuest = {
      name: reservationData?.user_name,
      email: reservationData?.user_email,
    };
    let updatedGuestList = [];
    updatedGuestList.push(primaryGuest);
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        if (data[i].email != primaryGuest.email) {
          updatedGuestList.push(data[i]);
        }
      }
    }
    setAllGuestList(updatedGuestList);
  };
  const [selectedReservationData, setselectedReservationData] = useState({});
  const fetchReservationData = async () => {
    setLoadingData(true);
    try {
      let data;
      if (`${process.env.REACT_APP_ENV}` === "LOCAL") {
        data = await getReservationInfoWithHotelId(
          eventData.event.extendedProps.reservationId,
          hotelId
        );
      } else {
        data = await getReservationInfoDjangoWithHotelId(
          eventData.event.extendedProps.reservationId,
          hotelId
        );

        let logo = channelLogoMapDash[data?.booking_source];
        if (logo === undefined) logo = "";
        setotaLogo(logo);
      }
      setselectedReservationData(data);

      const temp = new ReservationInfoModel(data);
      setReservationData(temp);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingData(false);
    }
  };

  const returnDataObj = (type) => {
    const temp_obj = Object.assign({}, eventData);
    temp_obj.oldEvent = {};
    if (type === "start") temp_obj.oldEvent.startStr = "";
    else if (type === "move") {
      const temp_id = eventData.event.getResources()[0].id;
      temp_obj.oldEvent.startStr = "";
      temp_obj.oldEvent.endStr = "";
      temp_obj.oldEvent.getResources = () => {
        return [{ id: temp_id }];
      };
      // let temp_date = temp_obj.event.start
      // let temp_end = temp_obj.event.end
      // temp_date.setDate(temp_date.getDate() - 1)
      // temp_end.setDate(temp_end.getDate() + 1)
      // temp_obj.oldEvent.setDates(temp_date, temp_end)
    } else temp_obj.oldEvent.startStr = temp_obj.event.startStr;
    return temp_obj;
  };

  const displayFalse = () => {
    setMenuValue("");
  };

  const checkinCheckout = (data) => {
    eventData.event.setDates(data.start, data.end);
    handleAddEventSidebar();
    reload();
  };

  var d = new Date(0);
  d.setUTCSeconds(reservationData?.enquiry_expiration_time);

  useEffect(() => {
    fetchReservationData();

    // fetchRoomTypeData(eventData.event.getResources()[0].extendedProps.roomTypeId)
  }, []);

  useEffect(() => {
    if (reservationData?.booking_id) {
      fetchGuestList(reservationData?.booking_id);
      //  fetchBookingUserDetail(reservationData?.booking_id);
    }
  }, [reservationData]);

  const Confirmed = async () => {
    const payload = {
      id: reservationData.booking_id,
    };
    await updateEnquiryStatusWithHotelId(payload, hotelId);
    handleAddEventSidebar();
  };

  //Rollback booking

  // handling the rollback booking

  // confirmation modal for handling the rollback of booking
  const [openRollbackBooking, setOpenRollbackBooking] = useState(false);
  const handleOpenRollbackBooking = () => setOpenRollbackBooking(true);
  const handleCloseRollbackBooking = () => setOpenRollbackBooking(false);
  const [rollbackLoader, setRollbackLoader] = useState(false);

  const handleRollbackBooking = (bookingId) => {
    // api call based on the status
    // if (invoiceData.invoice.status === "ADMIN_CONFIRMED") {
    setRollbackLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/reservation/rollback-checkin-booking" +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      {
        bookingId: bookingId,
        resIds: [],
      }
    )
      // .then((res) =>
      //   toast.success("Status is changed to CONFIRMED successfully.")
      // )
      .then((res) => setRollbackLoader(false))
      .then((res) => handleCloseRollbackBooking())
      .then((res) => window.location.reload())
      .catch((err) =>
        toast.error("Error in changing the status to CONFIRMED.")
      );
  };

  return (
    <div className={classes.root}>
      <CommonSlider open={open} onClose={handleAddEventSidebar}>
        <Grid
          container
          sx={{ height: "100%", width: "100%", display: "block" }}
        >
          <Grid container height={"max-content"} className={classes.textField}>
            <Grid
              container
              justifyContent="flex-start"
              alignSelf="center"
              item
              xs={10}
            >
              <Typography variant="h5">
                {reservationData?.is_enquiry === "true" ? (
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 mr-2">Booking Information</h5>
                    <Tooltip
                      title={`Expires at ${moment(
                        reservationData.enquiry_expiration_time
                      ).format("MMM D YYYY, h:mm:ss a")}`}
                      className="mb-0"
                    >
                      <p
                        className="badge badge-secondary mb-0"
                        style={{ fontSize: "13px" }}
                      >
                        Enquiry
                      </p>
                    </Tooltip>
                    {otaLogo.includes(".png") && (
                      <img src={otaLogo} style={{ height: "2rem" }} />
                    )}
                    {doNotRent && doNotRent?.do_not_rent === "YES" && (
                      <div
                        className="badge badge-danger"
                        style={{ fontSize: "11px" }}
                      >
                        Do-Not Rent
                      </div>
                    )}
                  </div>
                ) : (
                  // "Booking Information"
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 mr-2">Booking Information</h5>
                    {otaLogo.includes(".png") && (
                      <img src={otaLogo} style={{ height: "2rem" }} />
                    )}
                    {doNotRent && doNotRent?.do_not_rent === "YES" && (
                      <>
                        <div
                          className="badge badge-danger"
                          style={{ fontSize: "11px" }}
                        >
                          Do-Not Rent
                        </div>
                        {doNotRent?.severity === "LOW" && (
                          <div
                            className="badge badge-info ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            LOW
                          </div>
                        )}
                        {doNotRent?.severity === "MEDIUM" && (
                          <div
                            className="badge badge-warning ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            MEDIUM
                          </div>
                        )}
                        {doNotRent?.severity === "HIGH" && (
                          <div
                            className="badge badge-dark ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            HIGH
                          </div>
                        )}
                        {doNotRent?.severity === "BLACKLIST" && (
                          <div
                            className="badge badge-danger ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            BLACKLIST
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </Typography>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={2}>
              <IconButton title="close" onClick={handleAddEventSidebar}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>

          {loadingData && (
            <Grid
              sx={{ width: "100%", height: "inherit" }}
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <DimmingDotsLoader />
            </Grid>
          )}
          {!loadingData && (
            <Grid container height={"max-content"}>
              <Grid item xs={12}>
                <Tabs
                  sx={{
                    borderBottom: 1,
                    borderTop: 1,
                    borderColor: "divider",
                    width: "100%",
                  }}
                  variant="fullWidth"
                  value={tabvalue}
                  onChange={(event, newVal) => {
                    setTabValue(newVal);
                  }}
                >
                  <Tab
                    sx={{ alignItems: "baseline", textTransform: "none" }}
                    value="booking-information"
                    label="Booking Information"
                  ></Tab>
                  {/* <Tab
                    sx={{ alignItems: "baseline", textTransform: "none" }}
                    value="customer-information"
                    label="Customer Information"
                  ></Tab>
                  <Tab
                    sx={{ alignItems: "baseline", textTransform: "none" }}
                    value="notes"
                    label="Notes"
                  ></Tab> */}
                </Tabs>
              </Grid>
            </Grid>
          )}
          {!loadingData && (
            <Grid item>
              {/* {menuValue === "" && tabvalue === "customer-information" && (
                <CustomerData
                  allGuestList={allGuestList}
                  saveCustomer={saveCustomer}
                  saveCustomerRes={saveCustomerRes}
                  load={load}
                  reservationData={reservationData}
                  reloadReservationData={reloadReservationData}
                  doNotRent={doNotRent}
                  getDoNotRentStatus={getDoNotRentStatus}
                  setLoad={setLoad}
                  eventData={eventData}
                  fetchGuestList={() => fetchGuestList()}
                />
              )} */}
              {menuValue === "" && tabvalue === "booking-information" && (
                <GroupCustomerInformation
                  reservationData={reservationData}
                  reload={reload}
                  closeBackDrop={handleAddEventSidebar}
                  eventData={eventData}
                  resId={eventData.event.extendedProps.reservationId}
                  fetchReservationData={fetchReservationData}
                  selectedReservationData={selectedReservationData}
                  hotelId={hotelId}
                  // viewFolio={viewFolio}
                />
              )}
              {/* {menuValue === "" && tabvalue === "notes" && (
                <Notes
                  allGuestList={allGuestList}
                  reservationData={reservationData}
                  saveCustomer={saveCustomer}
                  saveCustomerRes={saveCustomerRes}
                  reload={reload}
                  load={load}
                  reservationData={reservationData}
                  reloadReservationData={reloadReservationData}
                  doNotRent={doNotRent}
                  getDoNotRentStatus={getDoNotRentStatus}
                  setLoad={setLoad}
                  closeBackDrop={handleAddEventSidebar}
                  eventData={eventData}
                  fetchGuestList={() => fetchGuestList()}
                />
              )} */}
            </Grid>
          )}
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default GroupEditModalView;
