import React, { useState, useEffect } from "react";
import {
  Stack,
  TextField,
  Typography,
  Divider,
  ClickAwayListener,
  Box,
  Button,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import * as HiIcons from "react-icons/hi";
import { DateRange } from "react-date-range";
import moment from "moment";
import {
  OutlinedButton,
  SecondaryButton,
} from "../../../../../Utils/ButtonHelper";
import { djangoHandleDataRequests } from "../../../../../api";
import { toast } from "material-react-toastify";
import { useParams } from "react-router";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 0,
  zIndex: 100,
  border: "1px solid",
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
};

function SalesStats({ customClass, startDate, endDate, hotelCurrency }) {
  //get Sales data

  const { shopId } = useParams();
  const [salesData, setsalesData] = useState(null);

  useEffect(() => {
    getSalesStatsData();
  }, [startDate, endDate]);

  const getSalesStatsData = () => {
    const new_end_date = new Date(new Date(endDate).getTime() + 86400000);
    shopId &&
      djangoHandleDataRequests(
        `pos/getTotalSales?shop_id=${shopId}&hotel_id=12353&start_date=${startDate}&end_date=${moment(
          new_end_date
        ).format("YYYY-MM-DD")}`
      )
        .then((res) => setsalesData(res))
        .catch((err) => toast.error("Error fetching the details"));
  };

  const getTotalSalesData = () => {
    if (salesData) {
      console.log(salesData);
      let cash = salesData?.cash === null ? 0 : salesData?.cash;
      let card = salesData?.card === null ? 0 : salesData?.card;
      let wallet = salesData?.wallet === null ? 0 : salesData?.wallet;
      // const part = salesData?.part === null ? 0 : salesData?.part;
      let other = salesData?.other === null ? 0 : salesData?.other;
      return (
        Number(cash) +
        Number(card) +
        Number(wallet) +
        // Number(part) +
        Number(other)
      );
    } else {
      return Number(0);
    }
  };
  return (
    <Stack
      className={customClass}
      style={{ width: "100%", position: "relative" }}
    >
      <Stack className="position-relative">
        <Stack className="d-flex justify-content-between pt-4" direction="row">
          <Typography variant="h6">Sales</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          className="d-flex position-relative justify-content-center mt-8"
        >
          <Typography style={{ fontSize: "17.5px", fontWeight: 500 }}>
            Total sales
          </Typography>
          <Typography style={{ fontSize: "17.5px", fontWeight: 500 }}>
            {hotelCurrency} {getTotalSalesData()}
          </Typography>
        </Stack>
        <Stack className="mt-6">
          <Stack
            direction="row"
            className="d-flex justify-content-between mt-2"
          >
            <Typography style={{ fontSize: "15px" }}>Cash</Typography>
            <Typography variant="h6" style={{ fontSize: "15px" }}>
              {hotelCurrency} {salesData?.cash === null ? 0 : salesData?.cash}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            className="d-flex justify-content-between mt-4"
          >
            <Typography style={{ fontSize: "15px" }}>Card</Typography>
            <Typography variant="h6" style={{ fontSize: "15px" }}>
              {hotelCurrency} {salesData?.card === null ? 0 : salesData?.card}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            className="d-flex justify-content-between mt-4"
          >
            <Typography style={{ fontSize: "15px" }}>Wallet</Typography>
            <Typography variant="h6" style={{ fontSize: "15px" }}>
              {hotelCurrency}{" "}
              {salesData?.wallet === null ? 0 : salesData?.wallet}
            </Typography>
          </Stack>
          {/* <Stack
            direction="row"
            className="d-flex justify-content-between mt-4"
          >
            <Typography style={{ fontSize: "15px" }}>Part</Typography>
            <Typography variant="h6" style={{ fontSize: "15px" }}>
              {salesData?.part === null ? 0 : salesData?.part}
            </Typography>
          </Stack> */}
          <Stack
            direction="row"
            className="d-flex justify-content-between mt-4"
          >
            <Typography style={{ fontSize: "15px" }}>Other</Typography>
            <Typography variant="h6" style={{ fontSize: "15px" }}>
              {hotelCurrency} {salesData?.other === null ? 0 : salesData?.other}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default SalesStats;
