import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
} from "recharts";
import { AmountFormatted } from "./util";

function GroupBarChart({ sourceData, colors, fullScreen, currency }) {
  const [maxHeight, setMaxHeight] = useState(1);
  useEffect(() => {
    var height = 1;
    sourceData.map((item) => {
      if (item?.Revenue > height) {
        height = item?.Revenue;
      }
    });
    setMaxHeight(Math.ceil(height));
    // console.log("sourceData", sourceData);
  }, [sourceData]);

  const [graphWidth, setGraphWidth] = useState();
  useEffect(() => {
    var screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (fullScreen) {
      setGraphWidth(Math.round((screenWidth * 44) / 100));
    } else {
      setGraphWidth(Math.round((screenWidth * 35) / 100));
    }
  }, [fullScreen]);
  return (
    <BarChart
      width={graphWidth}
      height={300}
      data={sourceData}
      margin={{
        top: 20,
      }}
    >
      {sourceData?.length < 1 && <CartesianGrid strokeDasharray="3 3" />}
      {sourceData?.length < 1 && (
        <g transform={`translate(${graphWidth / 2},${300 / 2})`}>
          <Text textAnchor="middle" verticalAnchor="middle">
            No data available
          </Text>
        </g>
      )}

      <XAxis
        dataKey="name"
        padding={{ left: 30, right: 30 }}
        tick={{ fontSize: 12, fill: "black", angle: -15, textAnchor: "end" }}
        tickFormatter={formattingXAxis}
      />

      <YAxis
        domain={[0, maxHeight]}
        label={{
          value: `Revenue (${localStorage.getItem("hotelCurrency")})`,
          angle: -90,
          position: "insideLeft",
        }}
        tickFormatter={AmountFormatted}
      />

      {/* <Tooltip /> */}
      <Tooltip
        content={<CustomTooltip currency={currency} />}
        cursor={{ fill: "transparent" }}
      />
      {/* <Legend /> */}
      <Bar dataKey="Revenue" fill={colors[3]} />
    </BarChart>
  );
}

export default GroupBarChart;

const CustomTooltip = ({ active, payload, label, currency }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        <div>
          {payload.map((pld) => (
            <div style={{ display: "inline-block", padding: 5 }}>
              <div style={{ color: "black" }}>
                {pld?.dataKey} : {currency} {pld?.value}
              </div>
              {/* <div style={{ color: "gray" }}> drivers</div> */}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

const formattingXAxis = (item) => {
  if (item?.length > 6) {
    return `${item?.slice(0, 6)}...`;
  }
  return item;
};
