import { Divider, Grid, Skeleton } from "@mui/material";
import React from "react";

export default function PaymentDashboardLoader() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Skeleton variant="rounded" height={200} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="rounded" height={200} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="rounded" height={200} />
      </Grid>
      <Divider />
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={300} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={300} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={300} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={300} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" height={600} />
      </Grid>
    </Grid>
  );
}
