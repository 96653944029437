import { Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import StockWrapper from "../StockWrapper";
import { connect } from "react-redux";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";

import { djangoHandleDataRequests } from "../../../api";
import { toast } from "material-react-toastify";
import ReportsLoader from "../../../Utils/ReportsLoader";
import { Divider, Typography } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";


function WarehouseStockReport({ data }) {
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;

  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  //columns of table
  const [rowsdats, setrowsdats] = useState(null);
  const [reportsColumns, setreportsColumns] = useState([]);

  // Get All report data
  const [reportsData, setreportsData] = useState([]);

  //Function to get report data
  const getAllRestaurantReportData = () => {
    setrowsdats(null);
    djangoHandleDataRequests(`pos/warehouse-current-stock?hotel_id=${hotelId}`)
      .then((res) => {
        setreportsData(res);
        setrowsdats(productsGenerator(res.length, res));
      })
      .catch((err) =>
        toast.error("Error in getting  data! Please try again later.")
      );
  };

  useEffect(() => {
    getAllRestaurantReportData();
  }, []);

  //Rows data generator
  const productsGenerator = (quantity, data) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        name: data[i].name,
        quantity: data[i].quantity,
        purchase_unit: data[i].purchase_unit,
        purchase_price: data[i].purchase_price,
        rm_category: data[i].rm_category,
        min_stock_level: data[i].min_stock_level,
        min_stock_level_unit: data[i].min_stock_level_unit,
        at_par_stock_level: data[i].at_par_stock_level,
        at_par_stock_level_unit: data[i].at_par_stock_level_unit,
      });
    }
    return items;
  };

  useEffect(() => {
    const names = [
      "Name",
      "Quantity",
      "Unit",
      `Price`,
      "Category",
      "Minimum stock level",
      "Unit",
      "At par stock level",
      "Unit",
    ];
    if (reportsData?.length > 0) {
      let arr = [];
      const array = Object.keys(reportsData[0]);
      for (var i = 0; i < array?.length; i++) {
        arr.push({
          dataField: array[i],
          text: names[i],
          sort: true,
        });
      }

      setreportsColumns(arr);
    }
  }, [reportsData]);

  return (
    <>
      <StockWrapper
        backBtn={false}
        hideDrawer={true}
        stockTypeName={"Warehouse Current Stock Report"}
        contentArea={
          <Stack className="position-relative mt-8">
            <>
              {/* <h1 className="mt-3 mb-3">Warehouse Current Stock Report</h1>
          <Divider className="mt-2 mb-2" /> */}
              {rowsdats ? (
                <div className="App">
                  {/* {reportsColumns.length > 0 && ( */}
                  <ToolkitProvider
                    keyField="id"
                    data={rowsdats}
                    columns={reportsColumns}
                    exportCSV
                    search
                  >
                    {(props) => (
                      <div>
                        <Stack
                          className="position-relative w-100 justify-content-between mt-8"
                          direction={"row"}
                        >
                          <Stack
                            className="position-relative"
                            direction="row"
                            spacing={2}
                          >
                            <SearchBar
                              {...props.searchProps}
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            />
                            {/* <Stack
                          spacing={4}
                          className="position-relative"
                          style={{ width: "fit-content" }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label="Select date"
                              inputFormat="MM/DD/YYYY"
                              value={dateVal}
                              onChange={handleChange}
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                            />
                          </LocalizationProvider>
                        </Stack> */}
                          </Stack>

                          <ExportCSVButton
                            {...props.csvProps}
                            style={{
                              display: "block",
                              background: "black",
                              color: "white",
                              marginTop: -1.5,
                              marginBottom: "auto",
                              marginLeft: "auto",
                            }}
                          >
                            Export CSV
                          </ExportCSVButton>
                        </Stack>
                        <Stack className="position-relativre mt-8">
                          {reportsColumns.length > 0 && rowsdats.length > 0 ? (
                            <BootstrapTable
                              pagination={paginationFactory({
                                sizePerPage: 10,
                              })}
                              {...props.baseProps}
                            />
                          ) : (
                            <Typography
                              className="position-relative mt-8 text-align-center"
                              style={{
                                fontSize: "15px",
                                marginTop: "15px",
                                color: "grey",
                                textAlign: "center",
                              }}
                            >
                              No data found!
                            </Typography>
                          )}
                        </Stack>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              ) : (
                <ReportsLoader />
              )}
            </>
          </Stack>
        }
      />
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(WarehouseStockReport);
