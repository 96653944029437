import { InputLabel, Select, Stack, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import StockWrapper from "../StockWrapper";
import { connect } from "react-redux";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";

import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataRequest,
} from "../../../api";
import { toast } from "material-react-toastify";
import ReportsLoader from "../../../Utils/ReportsLoader";
import { Divider, FormControl, MenuItem, Typography } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function WarehouseCumulativeReport({ data }) {
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;

  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  //columns of table
  const [rowsdats, setrowsdats] = useState(null);
  const [reportsColumns, setreportsColumns] = useState([]);

  // Get All report data
  const [reportsData, setreportsData] = useState([]);
  const [supplier, setsupplier] = useState("");

  // Getting the start date as well as the end date to load the data
  const [dateVal, setDateVal] = useState(dayjs(new Date()));
  const handleChange = (newVal) => {
    setDateVal(newVal);
  };

  //Function to get report data
  const getAllRestaurantReportData = () => {
    setrowsdats(null);
    djangoHandleDataMutationRequest("POST", `pos/cumulative-warehouse/`, {
      hotel_id: hotelId,
      today_start_date: moment(new Date(dateVal)).format("YYYY-MM-DD 00:00:00"),
      today_end_date: moment(new Date(dateVal)).format("YYYY-MM-DD 23:59:59"),
      prev_start_date: moment(new Date(dateVal))
        .subtract(1, "days")
        .format("YYYY-MM-DD 00:00:00"),
      prev_end_date: moment(new Date(dateVal))
        .subtract(1, "days")
        .format("YYYY-MM-DD 23:59:59"),
    })
      .then((res) => {
        setreportsData(res);
        setrowsdats(productsGenerator(res.length, res));
      })
      .catch((err) =>
        toast.error("Error in getting  data! Please try again later.")
      );
  };

  useEffect(() => {
    getAllRestaurantReportData();
  }, [dateVal]);

  //Rows data generator
  const productsGenerator = (quantity, data) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        id: data[i].id,
        name: data[i].name,
        category: data[i].category,
        opening_stock:
          data[i].opening_stock === 0 ? 0 : data[i].opening_stock.toFixed(3),
        purchase_qty:
          data[i].purchase_qty === 0 ? 0 : data[i].purchase_qty.toFixed(3),
        excess_rm: data[i].excess_rm === 0 ? 0 : data[i].excess_rm.toFixed(3),
        total_stock:
          data[i].total_stock === 0 ? 0 : data[i].total_stock.toFixed(3),
        consumption:
          data[i].consumption === 0 ? 0 : data[i].consumption.toFixed(3),
        wastage_qty:
          data[i].wastage_qty === 0 ? 0 : data[i].wastage_qty.toFixed(3),
        normal_loss:
          data[i].normal_loss === 0 ? 0 : data[i].normal_loss.toFixed(3),
        shortage_rm:
          data[i].shortage_rm === 0 ? 0 : data[i].shortage_rm.toFixed(3),
        total_consumption: data[i].total_consumption.toFixed(3),
        net_stock: data[i].net_stock.toFixed(3),
        stock_unit: data[i].stock_unit,
      });
    }
    return items;
  };

  useEffect(() => {
    const names = [
      "Id",
      "Name",
      "Category",
      "Opening Stock (A)",
      `Purchase Quantity (B)`,
      `Excess Raw Material (C)`,
      `Total Stock (A+B+C)`,
      `Consumption (D)`,
      `Wastage Quantity (E)`,
      `Normal Loss (F)`,
      `Shortage Raw Material (G)`,
      `Total Consumption (D+E+F+G)`,
      `Net Stock (A+B+C-D-E-F-G)`,
      `Stock Unit`,
    ];
    if (reportsData?.length > 0) {
      let arr = [];
      const array = Object.keys(reportsData[0]);
      for (var i = 0; i < array?.length; i++) {
        arr.push({
          dataField: array[i],
          text: names[i],
          sort: true,
          hidden: names[i] === "Id" ? true : false,
        });
      }

      setreportsColumns(arr);
    }
  }, [reportsData]);
  return (
    <>
      <StockWrapper
        backBtn={false}
        hideDrawer={true}
        stockTypeName={"Warehouse cumulative stock report"}
        contentArea={
          <Stack className="position-relative mt-8">
            <>
              {rowsdats ? (
                <div className="App">
                  {/* {reportsColumns.length > 0 && ( */}
                  <ToolkitProvider
                    keyField="id"
                    data={rowsdats}
                    columns={reportsColumns}
                    exportCSV
                    search
                  >
                    {(props) => (
                      <div>
                        <Stack
                          className="position-relative w-100 justify-content-between mt-8"
                          direction={"row"}
                        >
                          <Stack
                            className="position-relative"
                            direction="row"
                            spacing={2}
                          >
                            <SearchBar
                              {...props.searchProps}
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            />
                            <Stack
                              spacing={4}
                              className="position-relative"
                              style={{ width: "fit-content" }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  label="Select date"
                                  // inputFormat="MM/DD/YYYY"
                                  inputFormat="dd MMM yyyy"
                                  value={dateVal}
                                  onChange={handleChange}
                                  renderInput={(params) => (
                                    <TextField {...params} size="small" />
                                  )}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Stack>

                          <ExportCSVButton
                            {...props.csvProps}
                            style={{
                              display: "block",
                              background: "black",
                              color: "white",
                              marginTop: -1.5,
                              marginBottom: "auto",
                              marginLeft: "auto",
                            }}
                          >
                            Export CSV
                          </ExportCSVButton>
                        </Stack>
                        <Stack className="position-relativre mt-8">
                          {reportsColumns.length > 0 && rowsdats.length > 0 ? (
                            <BootstrapTable
                              pagination={paginationFactory({
                                sizePerPage: 10,
                              })}
                              {...props.baseProps}
                            />
                          ) : (
                            <Typography
                              className="position-relative mt-8 text-align-center"
                              style={{
                                fontSize: "15px",
                                marginTop: "15px",
                                color: "grey",
                                textAlign: "center",
                              }}
                            >
                              No data found!
                            </Typography>
                          )}
                        </Stack>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              ) : (
                <ReportsLoader />
              )}
            </>
          </Stack>
        }
      />
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(WarehouseCumulativeReport);
