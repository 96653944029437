import React from 'react';
import Stack from "@mui/material/Stack";


function FlashManagerReportLoader() {
  return (
    <div>
      <Stack spacing={5}>
        <div className="flashManagerLoader"></div>
        <div className="flashManagerLoader"></div>
        <div className="flashManagerLoader"></div>
        <Stack spacing={5} direction="row">
          <div className="flashManagerLoader" />
          <div className="flashManagerLoader" />
        </Stack>
      </Stack>
    </div>
  );
}

export default FlashManagerReportLoader;
