import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const GETCURRENTCONFIG = "core/api/v1/adultprice/get-current-configuration/";
const GETCHANGEDPRICE = "core/api/v1/adultprice/get-changed-price/";
const UPDATEPRICE = "core/api/v1/adultprice/update-price/";

export const getAllCurrentConfig = async (bookingID) => {
  return axios
    .get(COMMON_URL + GETCURRENTCONFIG + "?bookingId=" + bookingID)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllChangedPrice = async (resId, numAdults, numChildren) => {
  return axios
    .get(
      COMMON_URL +
        GETCHANGEDPRICE +
        "?reservationId=" +
        resId +
        "&numAdults=" +
        numAdults +
        "&numChildren=" +
        numChildren
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const saveUpdatedPrices = async (data, bookingID) => {
  return axios
    .post(COMMON_URL + UPDATEPRICE + "?bookingId=" + bookingID, {
      currentConfigurationList: data,
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
