import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Button,
  CardContent,
  OutlinedInput,
  IconButton,
  Grow,
  Paper,
  MenuItem,
  Popper,
  ClickAwayListener,
  MenuList,
  Tooltip,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Badge,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import { Link as DomLink, useHistory } from "react-router-dom";
import { format } from "date-fns";
import { connect } from "react-redux";
import * as auth from "../../modules/Auth/_redux/authRedux";
import { getHotel } from "../../modules/Auth/_redux/authCrud";
import BookingDataLoader from "./BookingDataLoader";
import BrandingModal from "./BrandingModal";
import { MoreVert } from "@mui/icons-material";
import CTAModal from "./CTAModal";
import "../FeeCategory/View/FeeCategoryModal.css";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { djangoHandleDataRequests, handleSpringDataRequest } from "../../api";
import { toast } from "material-react-toastify";
import ResponsiveDrawer from "../GroupSidebar/GroupSidebar";
import Freeze from "../../Utils/Freeze";

const BookingDashboard = (props) => {
  const { email, token } = props.data;
  const { freezed } = props;
  const [raised, setRaised] = useState({});
  const [bookingData, setBookingData] = useState(props.bookingData);
  const [searchTerm, setsearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const [multiOption, setMultiOption] = useState("");
  const [openBillingModal, setopenBillingModal] = useState(false);
  const [openBrandingModal, setopenBrandingModal] = useState(false);
  const [openCTASModal, setopenCTASModal] = useState(false);
  const hotelId = localStorage.getItem("hotelId");
  const [env, setEnv] = useState(process.env.REACT_APP_ENV);
  const [userRole, setUserRole] = useState("");
  const getUserRole = () => {
    handleSpringDataRequest(
      `core/api/v1/groupaccess/getUserRole?userEmail=${email}`
    )
      .then((res) => {
        setUserRole(res);
      })
      .catch((err) => {
        console.log("the err is : ", err);
      });
  };

  useEffect(() => {
    getUserRole();
  }, []);

  const toggleRaised = (num) => {
    raised[num] = !raised[num];
    setRaised({ ...raised });
  };

  const openTheBillingModal = (num) => {
    setopenBillingModal(true);
  };

  const hideTheBillingModal = () => {
    setopenBillingModal(false);
  };

  const openTheBrandingModal = () => {
    setopenBrandingModal(true);
  };

  const hideTheBrandingModal = () => {
    setopenBrandingModal(false);
  };

  const openTheCTAModal = () => {
    setopenCTASModal(true);
  };

  const hideTheCTAModal = () => {
    setopenCTASModal(false);
  };

  // quick search
  const onFilterTextChange = (event) => {
    setsearchTerm(event.target.value);
  };
  const [hotelIds, sethotelIds] = useState(null);
  const [groupId, setgroupId] = useState(null);
  useEffect(() => {
    // getAllData();
  }, []);
  const [loader, setloader] = useState(false);
  // const getAllData = async () => {
  //   try {
  //     setloader(true);
  //     const data = await getHotel(email, true, token);
  //     setBookingData(data.data);
  //     const tempHotelIds = data.data.map((res) => {
  //       return parseInt(res.hotelId);
  //     });
  //     setloader(false);
  //     tempHotelIds.sort();
  //     sethotelIds(tempHotelIds);
  //     setgroupId(tempHotelIds[0]);
  //     // console.log(data.data);
  //     // props.setAllHotels(data.data);
  //     // setloader(true);
  //     // for (let ele of data.data) {
  //     //   ele.accessControl = await getUserWizardRender(ele.hotelId, email);
  //     // }
  //     props.setAllHotels(data.data);
  //     setloader(false);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const [hotelData, sethotelData] = useState([]);

  useEffect(() => {
    if (bookingData) {
      const data = bookingData.filter(function (el) {
        if (hotelStatus !== "All") return el.status === hotelStatus;
        else return el.status;
      });

      return sethotelData(data);
    }
  }, [bookingData]);

  const [hotelStatus, sethotelStatus] = useState("All");

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChanges = (val) => {
    const data = bookingData.filter(function (el) {
      if (val !== "All") return el.status === val;
      else return el.status;
    });
    sethotelData(data);
    sethotelStatus(val);
  };

  const [accountInfo, setAccountInfo] = useState(null);
  const getCompanyData = (actId) => {
    handleSpringDataRequest(
      `core/api/v1/account/get-single/?groupId=${actId}&hotelId=${actId}`
    )
      .then((res) => {
        setAccountInfo(res);
      })
      .catch((err) => {
        console.log("the err is : ", err);
      });
  };

  useEffect(() => {
    // groupId && getCompanyData();
  }, [groupId]);

  const getActualGroupId = () => {
    handleSpringDataRequest(
      `core/api/v1/account/get-accountId/?hotelId=${groupId}`
    )
      .then((res) => {
        console.log("the updated groupId is : ", res);
        setgroupId(res);
        if (res !== "") getCompanyData(res);
      })
      .catch((err) => {
        console.log("the err is : ", err);
      });
  };

  useEffect(() => {
    groupId && getActualGroupId();
  }, [groupId]);

  return (
    <div
      style={{
        // position: "absolute",
        // top: "0",
        // bottom: "0",
        right: "0",
        left: "0",
        backgroundColor: "#ffffff",
      }}
    >
      <Modal
        open={freezed === "YES"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Freeze />
      </Modal>

      {/* <BillingModal
        show={openBillingModal}
        hide={hideTheBillingModal}
      ></BillingModal> */}
      {/* <BrandingModal
        show={openBrandingModal}
        hide={hideTheBrandingModal}
      ></BrandingModal> */}
      {/* <CTAModal show={openCTASModal} hide={hideTheCTAModal}></CTAModal> */}
      {/* <Grid container>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Card sx={{ marginBottom: "2rem" }}>
            <CardContent sx={{ paddingBottom: "15px!important" }}>
              <Grid item container spacing={3}>
                <Grid item xs={4} sm={3} md={12} lg={12} xl={12}>
                  <div className="d-flex flex-row" style={{ width: "28rem" }}>
                    <div className="d-flex flex-column">
                      <h3>Welcome to your group dashboard! </h3>

                      <p className="text-muted mb-0">
                        {" "}
                        Below are all of your hotels registered with the
                        platform. Click the button below to access that hotel's
                        dashboard
                      </p>
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        right: "0",
                        marginRight: "25px",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DomLink to="/logout">
                        <Button
                          // className="mr-auto bg-success text-white"
                          variant="custom-button"
                          // style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          Sign out{" "}
                        </Button>
                      </DomLink>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}
      <Grid
        item
        xs={12}
        sx={{ width: "90%", margin: "0 auto", borderRadius: "1rem" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ padding: "0px 3rem" }}
        >
          <div className="mb-4 align-items-center">
            <OutlinedInput
              onChange={onFilterTextChange}
              placeholder="Search Hotels"
              label="Search Hotels"
              // className="w-75"
              notched={false}
              type="search"
              size="small"
              style={{
                boxShadow: "0px 0px 16px 6px #0000001a",
                borderRadius: "5px",
                backgroundColor: "white",
                width: "65%",
              }}
            />
            <FormControl
              sx={{
                width: "20%",
                marginLeft: "3%",
                boxShadow: "0px 0px 16px 6px #0000001a",
                borderRadius: "5px",
                backgroundColor: "white",
              }}
              size="small"
            >
              <InputLabel>Hotel Status</InputLabel>
              <Select
                value={hotelStatus}
                onChange={(e) => handleChanges(e.target.value)}
                MenuProps={MenuProps}
                label="Hotel Status"
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"ACTIVE"}>Active</MenuItem>
                <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
                <MenuItem value={"ONBOARDING PENDING"}>
                  Onboarding pending
                </MenuItem>
                <MenuItem value={"TEST"}>Test</MenuItem>
              </Select>
            </FormControl>

            {userRole === "ADMIN" &&
              props.groupBrandingData.status !== "ENABLED" && (
                <Button
                  style={{ width: "10%", marginLeft: "2%", heigth: "3rem" }}
                  onClick={() =>
                    window.open(`https://join.stayflexi.com/`, "_blank")
                  }
                  variant="custom-button"
                  disabled={freezed === "YES"}
                >
                  Add listing
                </Button>
              )}
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ width: "90%", margin: "0 auto", borderRadius: "1rem" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ padding: "0px 3rem" }}
        >
          <h3
            className="text-muted"
            // style={{ marginLeft: "9.5rem", marginBottom: "0px" }}
          >
            Showing {hotelData?.length} properties
          </h3>
        </Grid>
      </Grid>
      {!loader ? (
        <Grid
          item
          container
          sx={{ paddingBottom: 3 }}
          maxHeight={"calc(100vh - 10rem)"}
          overflow="auto"
          style={{
            width: "90%",
            marginLeft: "5%",
            marginRight: "5%",
            padding: "3rem",
          }}
        >
          {hotelData ? (
            hotelData
              ?.filter((val) => {
                if (searchTerm == "") {
                  return val;
                } else if (
                  val.hotelName.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return val;
                } else if (
                  val.hotelId.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return val;
                }
              })
              ?.map((num, index) => {
                return (
                  <Grid key={num?.hotelId} item xs={12}>
                    <div>
                      <Card
                        onMouseOver={() => {
                          toggleRaised(num?.hotelId);
                        }}
                        onMouseOut={() => {
                          toggleRaised(num?.hotelId);
                        }}
                        raised={raised[num?.hotelId]}
                        sx={{ marginBottom: 2, borderRadius: "1rem" }}
                      >
                        <CardContent sx={{ paddingBottom: "15px!important" }}>
                          <Grid item container spacing={1}>
                            <Grid item xs={4} sm={3} md={2} lg={12} xl={12}>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                  <img
                                    className="mr-4"
                                    src={`${num?.hotelLogoUrl}?lastmod=${format(
                                      new Date(),
                                      "ddMMyyyyhhmmsstt"
                                    )}`}
                                    style={{
                                      borderRadius: "1rem",
                                      width: "110px",
                                      height: "110px",
                                    }}
                                  />
                                  <div className="d-flex flex-column w-100">
                                    <div
                                      className="d-flex justify-content-between align-items-start"
                                      style={{
                                        width: `${
                                          window.innerWidth < 830
                                            ? "28rem"
                                            : "50rem"
                                        }`,
                                      }}
                                    >
                                      <h3 className="pr-3">
                                        {num?.hotelName}{" "}
                                        <span
                                          style={{
                                            fontWeight: "lighter",
                                            fontSize: "1.25rem",
                                          }}
                                        >
                                          ( {num?.hotelId} )
                                        </span>
                                      </h3>
                                    </div>
                                    <p className="text-muted">{num?.address}</p>

                                    {num?.status === "ACTIVE" && (
                                      <Button
                                        variant="custom-button"
                                        sx={{ color: "white!important" }}
                                        className="mr-auto "
                                        href={`/dashboard?hotelId=${num?.hotelId}`}
                                        target="_blank"
                                        disabled={freezed === "YES"}
                                      >
                                        Go to dashboard
                                      </Button>
                                    )}
                                    {num?.status === "TEST" && (
                                      <Button
                                        variant="custom-button"
                                        sx={{ color: "white!important" }}
                                        className="mr-auto "
                                        href={`/dashboard?hotelId=${num?.hotelId}`}
                                        target="_blank"
                                        disabled={freezed === "YES"}
                                      >
                                        Go to dashboard
                                      </Button>
                                    )}
                                  </div>
                                </div>
                                <div style={{ marginLeft: "7rem" }}>
                                  {num.status === "ACTIVE" && (
                                    <p
                                      className="badge badge-secondary"
                                      style={styles.discountColor}
                                    >
                                      {num?.status}
                                    </p>
                                  )}
                                  {num.status === "INACTIVE" && (
                                    <p
                                      className="badge badge-secondary"
                                      style={styles.inActive}
                                    >
                                      {num?.status}
                                    </p>
                                  )}
                                  {num.status === "ONBOARDING PENDING" && (
                                    <p
                                      className="badge badge-secondary"
                                      style={styles.pending}
                                    >
                                      {num?.status}
                                    </p>
                                  )}
                                  {num.status === "TEST" && (
                                    <p
                                      className="badge badge-secondary"
                                      style={styles.pending}
                                    >
                                      {num?.status}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                );
              })
          ) : (
            <BookingDataLoader />
          )}
        </Grid>
      ) : (
        <BookingDataLoader />
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return { data: state.auth };
}

export default connect(mapStateToProps, auth.actions)(BookingDashboard);
const styles = {
  imageSize: {
    height: "100px",
    width: "4rem",
    marginRight: "3rem",
  },
  discountColor: {
    marginRight: "8 px",
    padding: "3 px 6 px",
    fontWeight: "700",
    textTransform: "uppercase",
    color: "#fff",
    backgroundColor: "rgba(25, 170, 141, 1)",
  },
  inActive: {
    marginRight: "8 px",
    padding: "3 px 6 px",
    fontWeight: "700",
    textTransform: "uppercase",
    color: "#fff",
    backgroundColor: "rgba(255, 0, 0, 0.45)",
  },
  pending: {
    marginRight: "8 px",
    padding: "3 px 6 px",
    fontWeight: "700",
    textTransform: "uppercase",
    color: "#fff",
    backgroundColor: "rgba(247, 213, 156, 1)",
  },
};
