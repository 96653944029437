import React, { useState } from "react";
import { connect } from "react-redux";
import GroupReportsWrapper from "./GroupReportsWrapper.js";
import {
  filterParams,
  cellRenderer,
  generateOriginalValue,
  handleNumDisplay,
} from "../Reports/utils/ReportUtils";
import moment from "moment";

function GroupPaymentsReport(props) {
  // getting the hotel id from the params
  //   const url = new URL(window.location.href);
  //   const hotelId = url.searchParams.get("hotelId");
  //   const hotelCurrency = data.accessControl.hotel.hotel_currency;

  // default hotel currency
  const hotelCurrency = props.data.hotelCurrency;
  // column definitions

  // defining the column headers, etc.
  const columns = [
    {
      field: "hotelId",
      headerName: "Hotel Id",
      hide: false,
      dataType: "varchar",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "hotelName",
      headerName: "Hotel Name",
      hide: false,
      dataType: "varchar",
    },
    {
      field: "capturedTime",
      headerName: "Date",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      dataType: "date",
    },
    {
      field: "bookingId",
      headerName: "Booking Id",
      hide: false,
      dataType: "varchar",
    },
    {
      field: "customerName",
      headerName: "Customer name",
      dataType: "varchar",
    },
    {
      field: "amount",
      headerName: `Amount (${hotelCurrency})`,
      dataType: "float",
      hide: false,
      aggFunc: "sum",
      valueGetter: (params) => generateOriginalValue(params, "amount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "refundAmount",
      headerName: `Refund amount (${hotelCurrency})`,
      dataType: "float",
      hide: false,
      aggFunc: "sum",
      valueGetter: (params) => generateOriginalValue(params, "refundAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "netAmount",
      headerName: `Net amount (${hotelCurrency})`,
      dataType: "float",
      hide: false,
      aggFunc: "sum",
      valueGetter: (params) => generateOriginalValue(params, "netAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "gateway",
      headerName: "Gateway",
      dataType: "varchar",
    },
    {
      field: "paymentStatus",
      headerName: "Payment status",
      dataType: "varchar",
    },
    {
      field: "paymentType",
      headerName: "Payment type",
      dataType: "varchar",
    },
    {
      field: "paymentIssuer",
      headerName: "Payment issuer",
      dataType: "varchar",
    },
    {
      field: "cardLast4",
      headerName: "Payment card last 4",
      dataType: "varchar",
    },
    {
      field: "transferAmount",
      headerName: `Transfer amount (${hotelCurrency})`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "transferAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "bookingSource",
      headerName: "Source",
      dataType: "varchar",
    },
    {
      field: "currencyCode",
      headerName: "Currency code",
      dataType: "varchar",
      hide: true,
    },
    {
      field: "customerContact",
      headerName: "Customer Contact",
      hide: true,
      dataType: "varchar",
    },
    {
      field: "customerEmail",
      headerName: "Customer Email",
      hide: true,
      dataType: "varchar",
    },
    {
      field: "orderId",
      headerName: "POS Order ID",
      hide: true,
      dataType: "varchar",
      printWidth: "7%",
    },
    {
      field: "pgChargeId",
      headerName: "Gateway Charge ID",
      hide: true,
      dataType: "varchar",
      printWidth: "7%",
    },
    {
      field: "userEmail",
      headerName: "User Email",
      hide: true,
      dataType: "varchar",
    },
  ];

  // Setting the pinned row
  const [pinnedRow, setPinnedRow] = useState(null);
  function createPinnedData(params, rowsData) {
    // let result = {
    //   bookingid_display: "Total",
    //   tax: 0,
    //   commission: 0,
    //   net_amount: 0,
    //   gross_amount: 0,
    //   tds: 0,
    // };

    let result = {};

    for (let i = 0; i < columns.length; i++) {
      if (["integer", "float"].includes(columns[i]["dataType"])) {
        result[columns[i]["field"]] = 0;
      }
    }

    function updateVals(i) {
      Object.keys(result).forEach((fields) => {
        result[fields] += i[`original_${fields}`];
      });
    }

    params.api.getSelectedRows().length === 0
      ? params.api.forEachNodeAfterFilter((row) => updateVals(row.data))
      : params.api.getSelectedRows().map((row) => updateVals(row));

    result["capturedTime"] = "Total";

    setPinnedRow([result]);
    params.api.setPinnedBottomRowData([result]);
  }

  return (
    <GroupReportsWrapper
      reportName={"Group Gateway Payments Report"}
      columns={columns}
      reportActualType={"payment"}
      createPinnedData={createPinnedData}
      pinnedRow={pinnedRow}
      grpEmail={props.grpEmail}
      resetToDefault={props.resetToDefault}
    />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.hotels[0] };
}
export default connect(mapStateToProps)(GroupPaymentsReport);
