import React, { useState, useEffect } from "react";
import {
  Stack,
  TextField,
  Typography,
  Divider,
  Tabs,
  Tab,
  Box,
  ClickAwayListener,
  Button,
} from "@mui/material";
import ShopWrapper from "../ShopWrapper";
import OrderStats from "./Components/OrderStats";
import TaxStats from "./Components/TaxStats";
import ProductStats from "./Components/ProductStats";
import DiscountStats from "./Components/DiscountStats";
import SalesStats from "./Components/SalesStats";
import { DateRange } from "react-date-range";
import moment from "moment";
import {
  OutlinedButton,
  SecondaryButton,
} from "../../../../Utils/ButtonHelper";
import { connect } from "react-redux";
import * as FcIcons from "react-icons/fc";
import UnauthorisedAccess from "../UnauthorisedAccess/UnauthorisedAccess";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  // border: "1px solid",
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

function DashboardPage({ data, shopAccessControl }) {
  // getting the hotel currency
  const hotelCurrency = data.accessControl.hotel.hotel_currency;
  const [value, setValue] = useState(new Date());

  // Click away listener tin order to toggle calendar state
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  // Getting the start date as well as the end date to load the data
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  return (
    <ShopWrapper
      contentArea={
        shopAccessControl.hasOwnProperty("POSDashboard") &&
        JSON.parse(shopAccessControl?.["POSDashboard"])?.read === "YES" ? (
          <Stack spacing={4} className="position-relative w-100 mt-4 ml-4 mb-4">
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box sx={{ position: "relative" }}>
                <button
                  onClick={handleClick}
                  className="border border-3 border-dark rounded p-2"
                  style={{ backgroundColor: "transparent" }}
                >
                  {moment(dateRange[0].startDate).format("DD MMM YYYY")}{" "}
                  {" to "}
                  {moment(dateRange[0].endDate).format("DD MMM YYYY")}
                </button>
                {open ? (
                  <Box sx={styles}>
                    <Stack spacing={3}>
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setDateRange([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        hotelCurrency={hotelCurrency}
                      />
                      <Stack
                        className="position-relative w-100 d-flex justify-content-between"
                        direction="row"
                      >
                        <SecondaryButton
                          text="Cancel"
                          onClick={() => setOpen(false)}
                        />
                        <Button
                          variant="custom-button"
                          onClick={() => handleClickAway()}
                        >
                          Apply
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                ) : null}
              </Box>
            </ClickAwayListener>
            <Stack
              className="position-relative"
              style={{ width: "100%" }}
              spacing={5}
            >
              <Stack
                className="position-relative"
                style={{ width: "100%" }}
                direction="row"
                spacing={2}
              >
                <Stack
                  style={{
                    width: "40%",
                    height: "30rem",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                  className="positon-relative rounded p-4"
                >
                  <OrderStats
                    startDate={moment(dateRange[0].startDate).format(
                      "YYYY-MM-DD"
                    )}
                    endDate={moment(dateRange[0].endDate).format("YYYY-MM-DD")}
                    hotelCurrency={hotelCurrency}
                  />
                </Stack>
                <Stack
                  className="position-relative rounded p-4"
                  style={{
                    position: "relative",
                    width: "30%",
                    height: "30rem",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                >
                  <SalesStats
                    startDate={moment(dateRange[0].startDate).format(
                      "YYYY-MM-DD"
                    )}
                    endDate={moment(dateRange[0].endDate).format("YYYY-MM-DD")}
                    hotelCurrency={hotelCurrency}
                  />
                </Stack>
                <Stack
                  className="position-relative rounded p-4"
                  style={{
                    position: "relative",
                    width: "25%",
                    height: "30rem",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                >
                  <ProductStats
                    startDate={moment(dateRange[0].startDate).format(
                      "YYYY-MM-DD"
                    )}
                    endDate={moment(dateRange[0].endDate).format("YYYY-MM-DD")}
                  />
                </Stack>
              </Stack>
              <Stack
                className="position-relative"
                style={{ width: "100%" }}
                direction="row"
                spacing={2}
              >
                <Stack
                  className="position-relative rounded p-4"
                  style={{
                    position: "relative",
                    width: "30%",
                    height: "10rem",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                >
                  <DiscountStats
                    startDate={moment(dateRange[0].startDate).format(
                      "YYYY-MM-DD"
                    )}
                    endDate={moment(dateRange[0].endDate).format("YYYY-MM-DD")}
                    hotelCurrency={hotelCurrency}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <UnauthorisedAccess />
        )
      }
    />
  );
}

function mapStateToProps(state) {
  return {
    data: state.auth.selectedHotel,
    shopAccessControl: state.auth.shopAccessControl,
  };
}
export default connect(mapStateToProps)(DashboardPage);
