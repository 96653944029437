import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const COMMON_OTA_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const GETALLBOOKING = "web-api/get-group-hotels";
const UPDATESTATUS = "core/api/v1/reservation/updateEnquiryStatus";

export const getAllBookings = async () => {
  return axios
    .get(COMMON_URL + GETALLBOOKING + "?emailId=" + "hello@stayflexi.com", {
      headers: { "api-key": "7a960ea605a10858f8a9ab9fbdd984678ce7e24c" },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateEnquiryStatus = async (data) => {
  return axios
    .post(COMMON_URL + UPDATESTATUS, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateEnquiryStatusWithHotelId = async (data, hotelId) => {
  return axios
    .post(
      COMMON_URL +
        UPDATESTATUS +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      data
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllCTAS = async (groupEmail) => {
  return axios
    .get(
      `${COMMON_OTA_URL}` +
        "core/api/v1/ota/get-all-cta/?groupEmail=" +
        groupEmail
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const saveCtaDetails = async (data) => {
  return axios
    .post(`${COMMON_OTA_URL}` + "api/v2/cta/addCTAForOTA/", data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const rejectCTA = async (data) => {
  return axios
    .post(`${COMMON_URL}` + "user/ota/reject", data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateCTADetails = async (data) => {
  return axios
    .put(`${COMMON_OTA_URL}` + "user/updateOTACustomerDetails", data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateCTAImages = async (data) => {
  return axios
    .post(
      `${COMMON_OTA_URL}` + "core/api/v1/image/update-ota-user-images",
      data
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllCTAImages = async (email) => {
  return axios
    .get(
      `${COMMON_OTA_URL}` +
        "core/api/v1/image/get-ota-user-images/?emailId=" +
        email
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getuserBookingImages = async (bookingId) => {
  return axios
    .get(
      COMMON_URL +
        "core/api/v1/image/get-user-id-for-booking/?bookingId=" +
        bookingId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
