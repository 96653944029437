// ** React Imports
import React, {useState} from "react";
import {Box, CircularProgress, Divider, Fab, Grid, IconButton, Typography,} from "@mui/material";
import {Close} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import CommonSlider from "./CommonSlider";
import {cancelBooking, releaseBookingId} from "../api/ReservationApi";
import {toast} from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import {customErrorMessage} from "../constants/Calendar-constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const ReleaseBooking = (props) => {
  const { open, option, closeModal, reservationData, clickFunc } = props;
  const [load, setLoad] = useState(false);
  const classes = useStyles();

  const buttonClick = async () => {
    setLoad(true);
    try {
      if (option === "hold") await releaseBookingId(reservationData.bookingId);
      else
        await cancelBooking({
          bookingId: reservationData.bookingId,
          applyRefund: true,
          isExternal: false,
        });
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.log(e);
    } finally {
      clickFunc();
    }
  };

  return (
    <div className={classes.root}>
      <CommonSlider
        onClose={() => {
          closeModal();
        }}
        open={open}
      >
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5">
              {option === "hold" ? "Release Booking" : "Cancel Booking"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: "right" }} onClick={closeModal}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.textField}>
          <Grid item xs={12}>
            <Box className={classes.custom_box} bgcolor="primary.main">
              {option === "hold" && (
                <Typography>
                  {`You are about to release booking  ${reservationData.bookingId}`}
                </Typography>
              )}
              {option === "cancel" && (
                <Typography>
                  {`You are about to cancel booking  ${reservationData.bookingId}`}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography>Do you want to continue?</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            className={classes.textField}
          >
            <Fab
              type="button"
              color="primary"
              onClick={() => {
                buttonClick();
              }}
              variant="extended"
              disabled={load}
            >
              {option === "hold" ? "Release" : "Cancel Booking"}
              {load && (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              )}
            </Fab>
          </Grid>
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default ReleaseBooking;
