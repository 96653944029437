import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

// Adding the filter params for another date format
export const filterParams = {
  debounceMs: 500,
  suppressAndOrCondition: true,
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    if (cellValue == null) {
      return 0;
    }
    //   console.log(moment(dateParts).format("DD-MM-YYYY"))
    var dateParts = moment(cellValue).format("DD-MM-YYYY");
    const formattedDateParts = dateParts.split("-");
    var year = Number(formattedDateParts[2]);
    var month = Number(formattedDateParts[1]) - 1;
    var day = Number(formattedDateParts[0]);
    var cellDate = new Date(year, month, day);

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate >= filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  },
};

export const filterParams2 = {
  debounceMs: 500,
  suppressAndOrCondition: true,
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    if (cellValue == null) {
      return 0;
    }
    var dateParts = moment(`${cellValue}`).format("DD-MM-YYYY");
    const formattedDateParts = dateParts.split("-");
    var year = Number(formattedDateParts[2]);
    var month = Number(formattedDateParts[1]) - 1;
    var day = Number(formattedDateParts[0]);
    var cellDate = new Date(year, month, day);

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  },
};

export const filterParamsForMMMDDYYYYHHmmA = {
  debounceMs: 500,
  suppressAndOrCondition: true,
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    if (cellValue == null) {
      return 0;
    }

    const formattedCellValue = moment(
      cellValue,
      "MMM DD, YYYY HH:mm A"
    ).startOf("day");
    const formattedFilterDate = moment(filterLocalDateAtMidnight).startOf(
      "day"
    );

    if (formattedCellValue.isBefore(formattedFilterDate)) {
      return -1;
    } else if (formattedCellValue.isAfter(formattedFilterDate)) {
      return 1;
    } else {
      return 0;
    }
  },
};

export const cellRenderer = (param, hotelId) => {
  let updatedBookingId = "";
  if (param?.value?.includes("SFBOOKING")) {
    updatedBookingId = param.value;
  } else {
    updatedBookingId =
      "SFBOOKING_" + String(hotelId) + "_" + String(param.value);
  }

  return (
    <Link
      to={{
        pathname: `/folio/${updatedBookingId}`,
        search: `?hotelId=${hotelId}`,
      }}
      target="_blank"
    >
      {param.value}
    </Link>
  );
};

export const handleDate = (selectedDate) => {
  return moment(selectedDate).format("YYYY-MM-DD");
};

export const handleFolioDate = (selectedDate) => {
  return moment(selectedDate).format("MMMM D, YYYY");
};

// Currency formatter
export function currencyFormatter(data) {
  let updatedData = Number(data);
  var formattedData = updatedData.toFixed(2);
  if (formattedData) {
    return Number(formattedData);
  } else {
    return 0.0;
  }
}

// Format the header name for the dynamic reports
// Format the header name of the columns
export const formatHeaderName = (headerName) => {
  const capitalizeHeaderName = headerName.replace(
    headerName[0],
    headerName[0].toUpperCase()
  );
  const formatHeaderName = capitalizeHeaderName.split("_");
  return formatHeaderName.join(" ");
};

// Handle single range calendar date format
export const formatSingleDateRange = (date, type) => {
  if (type === "default") {
    return moment(date).format("yyyy-MM-DD");
  }
};

// Preserve the original value into the AG grid
// This is done using the value getter
export function generateOriginalValue(params, field) {
  if (params.data[field]) {
    params.data[`original_${field}`] = Number(params.data[field]);
  } else {
    params.data[`original_${field}`] = 0.0;
  }
  return params.data[field];
}

// Format the data display for ag grid (both table and pinned row)
// into the float or number data type
export function handleNumDisplay(params, type) {
  let num;
  if (params.value) {
    num = Number(params.value);
  } else {
    num = 0;
  }
  switch (type) {
    case "integer":
      return parseInt(num);
    case "float":
      return parseFloat(num).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    default:
      return parseFloat(num).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
  }
}
