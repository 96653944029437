import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  CircularProgress,
  Button,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import Modal from "@mui/material/Modal";
import "../FlexibleSlot/views/FlexibleSlotModal.css";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import PDFExportPanel from "../Reports/pdfExport/PDFExportPanel";
import "ag-grid-enterprise";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-responsive-modal/styles.css";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import moment from "moment";
import { toast } from "material-react-toastify";
import { connect, shallowEqual, useSelector } from "react-redux";
import AuditReport from "../Reports/ManagerialReports/AuditReport/AuditReport";

function NightAuditReportModal({ show, hide, nigthAuditDate }) {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  return (
    <div>
      <Modal
        open={show}
        onClose={hide}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",

            width: "100%",
            heigth: "100vh !important",
            maxHeight: "100vh !important",
            bgcolor: "background.paper",
            boxShadow: "24",
            height: "fit-content",
            overflowY: "scroll",
            paddingTop: "8rem",
          }}
        >
          <div>
            <span
              style={{
                position: "absolute",
                left: "20px",
                top: "20px",
                cursor: "pointer",
              }}
              onClick={hide}
            >
              X
            </span>
            <span
              style={{
                position: "absolute",
                marginTop: "-6.4rem",
                marginLeft: "4rem",
                color: "gray",
                fontWeight: "bold",
              }}
            >
              Summary and detailed report of house status, reservations,
              payments, and taxes as of any given day. We recommend to run this
              report before closing a day.
            </span>

            <AuditReport
              hideIcon={true}
              configDate={new Date(nigthAuditDate)}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default NightAuditReportModal;

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
