import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import HeaderMenuWrapper from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";
import { Alert, Button, Collapse, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getBannerNotifications } from "../extras/dropdowns/apis/NotificationAPI";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllImagesAndUrl } from "../../../../app/pages/BookingDashboard/apis/BrandingModalApi";
import { isDateInDesiredBannerRange } from "../../../../app/pages/CalendarPage/constants/Calendar-constants";

function Header({ data, accountBalance, accountingVersion, checkUrl }) {
  const envType = process.env.REACT_APP_ENV;
  const uiService = useHtmlClassService();
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const [open, setOpen] = useState(true);
  const [alertData, setAlertData] = useState(null);
  const [pathName, setPathName] = useState("");
  // useEffect(() => {
  //   let tempVal = new URL(window.location.href);
  //   console.log("temp val: ", tempVal);
  // }, [window.location.href]);

  //Fetching whitelabelled status
  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const hotelTnC = localStorage.getItem("hotel_tnc");

  //Fetching the banner notifications
  useEffect(async () => {
    const data = await getBannerNotifications(hotelId);
    if (String(data?.status) == "200" && data?.data?.notifBody != null) {
      setAlertData(data?.data);
    }
    const brandingData = await getAllImagesAndUrl();
    if (brandingData?.status === null || brandingData?.status === undefined) {
      setWhiteLabelled("DISABLED");
    }
  }, []);

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  //System downtime banner display data
  const downTimeUpgradeTextIndia =
    "Planned maintenance notification: " +
    "System will be undergoing routine upgrade from April 3rd 4:30AM IST to 5:30AM IST. " +
    "You are advised to not use the system during this period. " +
    "System will be fully operational again after April 3rd 5:30AM IST.";
  const downTimeUpgradeTextOthers =
    "Planned maintenance notification: " +
    "System will be undergoing routine upgrade from April 2nd 6PM PDT to 7PM PDT. " +
    "You are advised to not use the system during this period. " +
    "System will be fully operational again after April 2nd 7PM PDT.";
  const [openDownTimeBanner, setOpenDownTimeBanner] = useState(true);

  //Need to update the boolean value to "false" to hide the Banner
  const displayDownTimeBanner = false; //Changed to "true" for production

  //Sytem update banner display
  const displaySystemBanner = false;

  const [openDisplayBanner, setOpenDisplayBanner] = useState(true);

  const [openTrainingBanner, setOpenTrainingBanner] = useState(true);
  const currentDate = new Date();

  const appBanner = true;
  const [appDisplayBanner, setAppDisplayBanner] = useState(true);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {!layoutProps.menuHeaderDisplay && <div />}
          {/*end::Header Menu Wrapper*/}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/* Alert Banner Display */}
      {url?.pathname === "/dashboard" &&
        accountBalance !== undefined &&
        accountBalance !== null &&
        accountingVersion === 2 &&
        whiteLabelled === "DISABLED" &&
        Number(accountBalance) < 0.0 && (
          <>
            <Alert severity="error" sx={{ fontSize: "1rem" }}>
              Your account balance is negative.
              {accountingVersion === 1 ? (
                <>
                  {/* {" "}
                  Contact the account team at
                  <a href="mailto:accounts@stayflexi.com">
                    {" "}
                    accounts@stayflexi.com
                  </a>{" "}
                  {data?.hotelCurrency === "INR" && (
                    <>
                      or call <a href="tel:+918095830314">+918095830314</a> to
                      avoid deactivation.
                    </>
                  )} */}
                </>
              ) : (
                <>
                  &nbsp;Visit Accounts & Billing section to pay the balance due.
                  For any questions, reach our accounts team at{" "}
                  <a href="mailto:accounts@stayflexi.com">
                    {" "}
                    accounts@stayflexi.com
                  </a>{" "}
                  {data?.hotelCurrency === "INR" && (
                    <>
                      or call <a href="tel:+918420482645">+918420482645</a> to
                      avoid deactivation
                    </>
                  )}
                  .
                  {/* <Link
                    to={{
                      pathname: "/accounts-dashboard",
                      state: {
                        bookingData: undefined,
                        hotelIds: undefined,
                        groupId: data?.accessControl?.hotel?.group_id,
                      },
                      search: `?hotelId=${hotelId}`,
                    }}
                  >
                    Pay Now
                  </Link>
                  to avoid deactivation */}
                </>
              )}
            </Alert>
          </>
        )}
      {url?.pathname === "/property-dashboard" &&
        (hotelTnC === undefined || hotelTnC === "") && (
          <Alert severity="error" sx={{ fontSize: "1rem" }}>
            Property terms and conditions are not set, please set them in "Hotel
            policies" under "Policies" tab
          </Alert>
        )}
      {/*System downtime banner*/}
      {displayDownTimeBanner === true && (
        <Collapse in={openDownTimeBanner}>
          <Alert
            severity="error"
            sx={{ marginTop: "5px" }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenDownTimeBanner(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {data?.hotelCurrency === "INR"
              ? downTimeUpgradeTextIndia
              : downTimeUpgradeTextOthers}
          </Alert>
        </Collapse>
      )}
      {/*Banner Notification for Training */}
      {url?.pathname === "/dashboard" &&
        data?.hotelCurrency === "INR" &&
        whiteLabelled === "DISABLED" &&
        isDateInDesiredBannerRange(currentDate) && (
          <Collapse in={openTrainingBanner}>
            <Alert
              severity="success"
              // sx={{ marginTop: "5px" }}
              sx={{
                fontSize: "0.97rem",
                display: ["BETA"].includes(envType) ? "none" : "flex",
              }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenTrainingBanner(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {`Join us  `}
              <a href="https://calendly.com/stayflexi-webinars" target="_blank">
                here
              </a>
              {` every Thursday at ${
                data?.hotelCurrency === "INR" ? "4 PM IST " : "3:30 AM PDT "
              }
              for Software Training! Learn the ropes of our software and stay up-to-date with the latest
              features. Perfect for new users and seasoned pros alike. Don't
              miss out!`}
            </Alert>
          </Collapse>
        )}
      {/*System update banner*/}
      {url?.pathname === "/dashboard" &&
        whiteLabelled === "DISABLED" &&
        displaySystemBanner === true && (
          <Collapse in={openDisplayBanner}>
            <Alert
              severity="success"
              // sx={{ marginTop: "5px" }}
              sx={{ fontSize: "0.97rem" }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenDisplayBanner(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              We've just released a host of exciting new updates to our product!
              Get the scoop on all the awesome new features, enhancements, and
              bug fixes right{" "}
              <a
                href="https://business.stayflexi.com/stayflexi-product-updates"
                target="_blank"
              >
                here
              </a>{" "}
              . Don't miss out -{" "}
              <a href="https://calendly.com/stayflexi-webinars" target="_blank">
                join us
              </a>{" "}
              for an interactive webinar and see it all in action!
            </Alert>
          </Collapse>
        )}
      {/*App update banner*/}
      {/* {url?.pathname === "/dashboard" &&
        whiteLabelled === "DISABLED" &&
        appBanner === true && (
          <Collapse in={appDisplayBanner}>
            <Alert
              severity="success"
              // sx={{ marginTop: "5px" }}
              sx={{ fontSize: "0.97rem" }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAppDisplayBanner(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Exciting news! Our latest mobile app version is here! Upgrade now
              for incredible new features and enhanced performance on{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.stayflexi.platform"
                target="_blank"
              >
                android
              </a>{" "}
              and{" "}
              <a
                href="https://apps.apple.com/us/app/stayflexi-orchestra/id1498345123"
                target="_blank"
              >
                iOS
              </a>
            </Alert>
          </Collapse>
        )} */}
      {alertData != null && (
        <>
          {/* <Collapse in={!open}>
            <Alert severity="warning">{alertData?.notifBody}</Alert>
          </Collapse> */}
          {/* Warning message Modal*/}
          {/* <Snackbar
            sx={{ height: "100%", width: "100%" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            key="snackBar"
          >
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ width: "100%", fontSize: "1.2rem" }}
            >
              {alertData?.notifBody}
            </Alert>
          </Snackbar> */}
        </>
      )}
      {/*end::Header*/}
    </>
  );
}
function mapStateToProps(state) {
  return {
    data: state.auth.selectedHotel,
    accountBalance: state.auth.accountBalance,
    accountingVersion: state.auth.accountingVersion,
  };
}

export default connect(mapStateToProps)(Header);
