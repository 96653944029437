// ** React Imports
import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Badge,
} from "@mui/material";
import { Close, MoreVert } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import {
  cancelBooking,
  cancelReservation,
  getReservationInfo,
  getReservationInfoDjango,
  holdBooking,
  invalidateNightAudit,
  invalidateNightAuditReservation,
  lockReservation,
  setToNoShow,
  setToNoShowBookingDotCom,
  unassignReservation,
  updateCustomer,
  updateCustomerRes,
  voidCancellationCharges,
} from "../api/ReservationApi";
import { ReservationInfoModel } from "../models/RservationInfoModel";
import CustomerInformation from "./CustomerInformation";
import HoldBooking from "./HoldBooking";
import CancelBooking from "./CancelBooking";
import CustomerData from "./BookingInformation";
import CommonSlider from "./CommonSlider";
import ExtendEventSidebar from "./ExtendModalView";
import DragEventSidebar from "./DragModalView";
import DimmingDotsLoader from "./LoaderCustom";
import moment from "moment";
import { updateEnquiryStatus } from "../../BookingDashboard/apis/BookingDashboardApi";
import {
  channelLogoMapDash,
  customErrorMessage,
} from "../constants/Calendar-constants";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import SplitRoom from "./SplitRoom";
import { shallowEqual, useSelector } from "react-redux";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import MoveRoom from "./MoveRoom";
import {
  getAllGuestUsers,
  getBookingUserDetails,
} from "../../Promocodes/apis/PromocodeApi";
import Notes from "./Notes";

import { getAllImagesAndUrl } from "../../BookingDashboard/apis/BrandingModalApi";

import {
  getAllCustomerNotes,
  getAllBookingNotes,
} from "../api/BookingAndCustomerNotesApi";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
}));

const EditEventSidebar = (props) => {
  let [reservationData, setReservationData] = useState(
    new ReservationInfoModel({})
  );
  let [loadingData, setLoadingData] = useState(true);
  let [load, setLoad] = useState(false);
  let [tabvalue, setTabValue] = useState("booking-information");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const [menuValue, setMenuValue] = useState("");
  const [waiveNoShowFee, setwaiveNoShowFee] = useState("no");

  const classes = useStyles();
  const {
    lockEvent,
    viewFolio,
    eventData,
    handleAddEventSidebar,
    open,
    addEvent,
    reload,
    removeEvent,
    roomInformation,
    roomTypeInfo,
    setShowModal,
    setActionType,
    setShow,
  } = props;

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const [subUser, setsubUser] = useState(hotel?.accessControl?.ADMINRIGHTS);
  const [hotelemail, sethotelemail] = useState(
    hotel?.accessControl?.hotel?.hotel_email
  );

  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
  }, []);

  const whitlableArrMap = [
    "STAYFLEXI_OD",
    "STAYFLEXI_OM",
    "STAYFLEXI MOBILE",
    "STAYFLEXI_GHA",
    "STAYFLEXI_BE",
    "STAYFLEXI_MOBILE",
  ];

  const [bookingnotesLength, setbookingnotesLength] = useState(0);
  const [customernotesLength, setcustomernotesLength] = useState(0);

  const cancelExisting = async (type, params, voidParams) => {
    if (voidParams.voidType === "") {
      toast.error("Please select any value");
    } else {
      setLoad(true);
      try {
        if (type) {
          // console.log("voidParams1 : ", voidParams, params)
          let isApplyRefund = voidParams?.voidType === "APPLY_REFUND";
          // console.log("isApplyRefund : ", isApplyRefund)
          params["applyCancellationPolicy"] = isApplyRefund;
          await cancelBooking(params);
          await invalidateNightAudit(params.bookingId);
          if (!isApplyRefund) {
            await voidCancellationCharges(voidParams);
          }
          removeEvent();
        } else {
          // console.log("voidParams2 : ", voidParams, params)
          let isApplyRefund = voidParams?.voidType === "APPLY_REFUND";
          // console.log("isApplyRefund : ", isApplyRefund)
          await cancelReservation(
            params,
            isApplyRefund,
            reservationData?.booking_id
          );
          if (!isApplyRefund) {
            await voidCancellationCharges(voidParams);
          }
          removeEvent();
        }
      } catch (e) {
        const message = e.message ? e.message : customErrorMessage;
        toast.error(
          typeof message === "string" || message instanceof String
            ? message
            : customErrorMessage
        );
        console.error(e);
        handleAddEventSidebar();
      }
    }
  };

  const [userEmail, setUserEmail] = useState(null);
  useEffect(() => {
    setUserEmail(reservationData?.user_email);
  }, [reservationData]);

  const [doNotRent, setDoNotRent] = useState(null);

  const getDoNotRentStatus = (email) => {
    handleSpringDataRequest(`core/api/v1/do-not-rent/get-all?emailId=${email}`)
      .then((res) => {
        setDoNotRent(res[0]);
      })
      .catch((err) => {
        console.log("The err is : ", err);
      });
  };

  useEffect(() => {
    userEmail && getDoNotRentStatus(userEmail);
  }, [userEmail]);

  const setNoShow = async (params, voidParams) => {
    if (voidParams.voidType === "") {
      toast.error("Please select any value");
    } else {
      let isApplyRefund = voidParams?.voidType === "APPLY_REFUND";
      // params["applyCancellationPolicy"] = isApplyRefund;
      // if (!isApplyRefund) {
      //   await voidCancellationCharges(voidParams);
      // }
      if (reservationData.booking_source === "BOOKING") {
        setLoad(true);
        try {
          await setToNoShowBookingDotCom(
            params.bookingId,
            params.isReservation,
            "booking",
            waiveNoShowFee,
            isApplyRefund
          );
          if (!isApplyRefund) {
            await voidCancellationCharges(voidParams);
          }
          await invalidateNightAudit(params.bookingId);
          removeEvent();
        } catch (e) {
          const message = e.message ? e.message : customErrorMessage;
          toast.error(
            typeof message === "string" || message instanceof String
              ? message
              : customErrorMessage
          );
          console.error(e);
          handleAddEventSidebar();
        }
      } else {
        setLoad(true);
        try {
          await setToNoShow(
            params.bookingId,
            params.isReservation,
            "booking",
            isApplyRefund
          );
          if (!isApplyRefund) {
            await voidCancellationCharges(voidParams);
          }
          await invalidateNightAudit(params.bookingId);
          removeEvent();
        } catch (e) {
          const message = e.message ? e.message : customErrorMessage;
          toast.error(
            typeof message === "string" || message instanceof String
              ? message
              : customErrorMessage
          );
          console.error(e);
          handleAddEventSidebar();
        }
      }
    }
  };

  const setLock = async (params) => {
    setLoad(true);
    try {
      await lockReservation(params).then(() => {
        lockEvent(params.status);
      });
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
      handleAddEventSidebar();
    }
  };

  const holdExisting = async (params) => {
    setLoad(true);
    try {
      await holdBooking(params);
      await invalidateNightAudit(params.bookingId);
      removeEvent();
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
      handleAddEventSidebar();
    }
  };

  const saveCustomer = async (data, id, name) => {
    setLoad(true);
    try {
      await updateCustomer(data, id);
      eventData.event.setProp("title", name);
      reload();
      setLoad(false);
      // try(
      //   reloadReservationData()
      // )catch(e){

      // }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.log(e);
      setLoad(false);
    }
  };

  const saveCustomerRes = async (data, id, name) => {
    setLoad(true);
    try {
      await updateCustomerRes(data, id);
      eventData.event.setProp("title", name);
      reload();
      setLoad(false);
      reloadReservationData();
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.log(e);
      setLoad(false);
    }
  };

  const unassign = async (params) => {
    setLoad(true);
    try {
      await unassignReservation(params);
      await invalidateNightAuditReservation(params.reservationId);
      removeEvent();
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.error(e);
    } finally {
      handleAddEventSidebar();
    }
  };
  // const fetchRoomTypeData = async (val) => {
  //   const data = await getRoomInfo(val)
  //   const temp = new RoomTYpeInfoModel(data)
  //   setRoomInfo(temp)
  // }

  // const fetchBookingData = async (val) => {
  //   const data = await getBookingInfo(val)
  //   const temp = new ReservationInfoModel(data)
  //   setBookingData(temp)
  //   return temp
  // }

  const [otaLogo, setotaLogo] = useState("");
  const reloadReservationData = async () => {
    try {
      let data;
      if (`${process.env.REACT_APP_ENV}` === "LOCAL") {
        data = await getReservationInfo(
          eventData.event.extendedProps.reservationId
        );
      } else {
        data = await getReservationInfoDjango(
          eventData.event.extendedProps.reservationId
        );
        // handleSpringDataRequest(
        //   `core/api/v1/image/get-booking-channel-logo/?source=${data?.booking_source}`
        // ).then((data) => {
        //   setotaLogo(data);
        // });
        let logo = channelLogoMapDash[data?.booking_source];
        if (logo === undefined) logo = "";

        if (
          whiteLabelled === "ENABLED" &&
          !whitlableArrMap.includes(data?.booking_source)
        ) {
          logo = "";
        }
        setotaLogo(logo);
      }

      const temp = new ReservationInfoModel(data);
      setReservationData(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const [allGuestList, setAllGuestList] = useState([]);
  // const fetchBookingUserDetail = async () => {
  //   const data = await getBookingUserDetails(reservationData?.booking_id);
  // };

  const fetchGuestList = async () => {
    const booking_data = await getBookingUserDetails(
      reservationData?.booking_id
    );
    const data = await getAllGuestUsers(reservationData?.booking_id);
    let primaryGuest = {
      name: reservationData?.user_name,
      email: reservationData?.user_email,
      guest_status: reservationData?.booking_status,
      reservationId: eventData.event.extendedProps.reservationId,
    };
    let updatedGuestList = [];
    updatedGuestList.push(primaryGuest);
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        if (data[i].email != primaryGuest.email) {
          updatedGuestList.push(data[i]);
        }
      }
    }
    setAllGuestList(updatedGuestList);
  };
  const [selectedReservationData, setselectedReservationData] = useState({});

  const fetchReservationData = async () => {
    setLoadingData(true);
    try {
      let data;
      if (`${process.env.REACT_APP_ENV}` === "LOCAL") {
        data = await getReservationInfo(
          eventData.event.extendedProps.reservationId
        );
      } else {
        data = await getReservationInfoDjango(
          eventData.event.extendedProps.reservationId
        );

        // handleSpringDataRequest(
        //   `core/api/v1/image/get-booking-channel-logo/?source=${data?.booking_source}`
        // ).then((data) => {
        //   setotaLogo(data);
        // });
        let logo = channelLogoMapDash[data?.booking_source];
        if (logo === undefined) logo = "";
        setotaLogo(logo);
      }
      setselectedReservationData(data);

      const temp = new ReservationInfoModel(data);
      setReservationData(temp);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingData(false);
    }
  };

  const returnDataObj = (type) => {
    const temp_obj = Object.assign({}, eventData);
    temp_obj.oldEvent = {};
    if (type === "start") temp_obj.oldEvent.startStr = "";
    else if (type === "move") {
      const temp_id = eventData.event.getResources()[0].id;
      temp_obj.oldEvent.startStr = "";
      temp_obj.oldEvent.endStr = "";
      temp_obj.oldEvent.getResources = () => {
        return [{ id: temp_id }];
      };
      // let temp_date = temp_obj.event.start
      // let temp_end = temp_obj.event.end
      // temp_date.setDate(temp_date.getDate() - 1)
      // temp_end.setDate(temp_end.getDate() + 1)
      // temp_obj.oldEvent.setDates(temp_date, temp_end)
    } else temp_obj.oldEvent.startStr = temp_obj.event.startStr;
    return temp_obj;
  };

  const displayFalse = () => {
    setMenuValue("");
  };

  const checkinCheckout = (data) => {
    eventData.event.setDates(data.start, data.end);
    handleAddEventSidebar();
    reload();
  };

  var d = new Date(0);
  d.setUTCSeconds(reservationData?.enquiry_expiration_time);

  useEffect(() => {
    fetchReservationData();
    getNightAuditStatus();
    // fetchRoomTypeData(eventData.event.getResources()[0].extendedProps.roomTypeId)
  }, []);

  useEffect(() => {
    if (reservationData?.booking_id) {
      fetchGuestList(reservationData?.booking_id);
      //  fetchBookingUserDetail(reservationData?.booking_id);
    }
  }, [reservationData]);

  const Confirmed = async () => {
    const payload = {
      id: reservationData.booking_id,
    };
    await updateEnquiryStatus(payload);
    handleAddEventSidebar();
  };

  //Release Inventory
  const [openReleaseInventory, setOpenReleaseInventory] = useState(false);
  const handleOpenReleaseInventoryModal = () => setOpenReleaseInventory(true);
  const handleCloseReleaseInventoryModal = () => setOpenReleaseInventory(false);

  //Rollback booking

  // handling the rollback booking

  // confirmation modal for handling the rollback of booking
  const [openRollbackBooking, setOpenRollbackBooking] = useState(false);
  const handleOpenRollbackBooking = () => setOpenRollbackBooking(true);
  const handleCloseRollbackBooking = () => setOpenRollbackBooking(false);
  const [rollbackLoader, setRollbackLoader] = useState(false);

  const handleRollbackBooking = (bookingId) => {
    // api call based on the status
    // if (invoiceData.invoice.status === "ADMIN_CONFIRMED") {
    setRollbackLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/reservation/rollback-checkin-booking",
      {
        bookingId: bookingId,
        resIds: [],
      }
    )
      // .then((res) =>
      //   toast.success("Status is changed to CONFIRMED successfully.")
      // )
      .then((res) => setRollbackLoader(false))
      .then((res) => handleCloseRollbackBooking())
      .then((res) => window.location.reload())
      .catch((err) =>
        toast.error("Error in changing the status to CONFIRMED.")
      );

    // }
    // else if (invoiceData.invoice.status === "CHECKED_OUT") {
    //   handleSpringDataMutationRequest(
    //     "POST",
    //     "core/api/v1/reservation/rollback-checkout-booking",
    //     {
    //       bookingId: bookingId,
    //       resIds: [],
    //     }
    //   )
    //     .then((res) => updateInvoice())
    //     .then((res) => updateSummaryData())
    //     .then((res) =>
    //       toast.success("Status is changed to CHECKED-IN successfully.")
    //     )
    //     .then((res) => setRollbackLoader(false))
    //     .then((res) => handleCloseRollbackBooking())
    //     .catch((err) =>
    //       toast.error("Error in changing the status to CHECKED-IN.")
    //     )
    //     .then((res) => setRollbackLoader(false))
    //     .then((res) => handleCloseRollbackBooking());
    // } else if (invoiceData.invoice.status === "CANCELLED") {
    //   handleSpringDataMutationRequest(
    //     "POST",
    //     "core/api/v1/reservation/rollback-cancel-booking",
    //     {
    //       bookingId: bookingId,
    //       resIds: [],
    //     }
    //   )
    //     .then((res) => updateInvoice())
    //     .then((res) => updateSummaryData())
    //     .then((res) =>
    //       toast.success("Status is changed to ADMIN_CONFIRMED successfully.")
    //     )
    //     .then((res) => setRollbackLoader(false))
    //     .then((res) => handleCloseRollbackBooking())
    //     .catch((err) =>
    //       toast.error("Error in changing the status to ADMIN_CONFIRMED.")
    //     )
    //     .then((res) => setRollbackLoader(false))
    //     .then((res) => handleCloseRollbackBooking());
    // }
  };

  const handleReleaseInventory = () => {
    setRollbackLoader(true);
    handleSpringDataRequest(
      `core/api/v1/reservation/release-inventory?reservationId=${eventData.event.extendedProps.reservationId}`
    )
      .then((res) => setRollbackLoader(false))
      .then((res) => toast.success("Successfully released the inventory"))
      .then((res) => reload())
      .then((res) => handleCloseReleaseInventoryModal())
      .catch((err) => toast.error(err.response.data));
  };

  const [isNightAuditOn, setIsNightAuditOn] = useState(false);
  const getNightAuditStatus = () => {
    handleSpringDataRequest(`core/api/v1/nightAudit/get-night-audit-status`)
      .then((data) => {
        setIsNightAuditOn(data);
      })
      .catch((err) => console.error(err));
  };

  const getAllBookingData = async () => {
    try {
      const data = await getAllBookingNotes(reservationData.booking_id);
      setbookingnotesLength(data.length);
    } catch (e) {
      console.error(e);
    }
  };

  const getAllCustomerData = async () => {
    try {
      const data = await getAllCustomerNotes(reservationData.user_email);
      setcustomernotesLength(data.length);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getAllBookingData();
    getAllCustomerData();
  }, [reservationData]);

  return (
    <div className={classes.root}>
      <CommonSlider open={open} onClose={handleAddEventSidebar}>
        <Grid
          container
          sx={{ height: "100%", width: "100%", display: "block" }}
        >
          <Grid container height={"max-content"} className={classes.textField}>
            <Grid
              container
              justifyContent="flex-start"
              alignSelf="center"
              item
              xs={10}
            >
              <Typography variant="h5">
                {reservationData?.is_enquiry === "true" ? (
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 mr-2">Booking Information</h5>
                    <Tooltip
                      title={`Expires at ${moment(
                        reservationData.enquiry_expiration_time
                      ).format("MMM D YYYY, h:mm:ss a")}`}
                      className="mb-0"
                    >
                      <p
                        className="badge badge-secondary mb-0"
                        style={{ fontSize: "13px" }}
                      >
                        {`Enquiry expires at ${moment(
                          reservationData.enquiry_expiration_time
                        ).format("MMM D YYYY, h:mm:ss a")}`}
                      </p>
                    </Tooltip>
                    {otaLogo?.includes(".png") && (
                      <img src={otaLogo} style={{ height: "2rem" }} />
                    )}
                    {doNotRent && doNotRent?.do_not_rent === "YES" && (
                      <div
                        className="badge badge-danger"
                        style={{ fontSize: "11px" }}
                      >
                        Do-Not Rent
                      </div>
                    )}
                  </div>
                ) : (
                  // "Booking Information"
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 mr-2">Booking Information</h5>
                    {otaLogo?.includes(".png") && (
                      <img src={otaLogo} style={{ height: "2rem" }} />
                    )}
                    {doNotRent && doNotRent?.do_not_rent === "YES" && (
                      <>
                        <div
                          className="badge badge-danger"
                          style={{ fontSize: "11px" }}
                        >
                          Do-Not Rent
                        </div>
                        {doNotRent?.severity === "LOW" && (
                          <div
                            className="badge badge-info ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            LOW
                          </div>
                        )}
                        {doNotRent?.severity === "MEDIUM" && (
                          <div
                            className="badge badge-warning ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            MEDIUM
                          </div>
                        )}
                        {doNotRent?.severity === "HIGH" && (
                          <div
                            className="badge badge-dark ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            HIGH
                          </div>
                        )}
                        {doNotRent?.severity === "BLACKLIST" && (
                          <div
                            className="badge badge-danger ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            BLACKLIST
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </Typography>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={2}>
              {isNightAuditOn &&
              selectedReservationData?.booking_status === "CHECKED_OUT" &&
              subUser.toLowerCase() === "subuser" ? (
                <></>
              ) : (
                <div>
                  {" "}
                  {/* <IconButton
                    title="more actions"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setMenuOpen(!menuOpen);
                    }}
                  >
                    <MoreVert />
                  </IconButton> */}
                  <Popper
                    style={{ zIndex: 3 }}
                    open={menuOpen}
                    anchorEl={anchorEl}
                    placement="bottom-end"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: (placement = "bottom-end"),
                        }}
                      >
                        <Paper>
                          <ClickAwayListener
                            onClickAway={() => {
                              setMenuOpen(false);
                            }}
                          >
                            <MenuList
                              id="composition-menu"
                              aria-labelledby="composition-button"
                            >
                              {selectedReservationData?.booking_status !==
                                "CHECKED_OUT" && (
                                <MenuItem
                                  onClick={() => {
                                    setMenuOpen(!menuOpen);
                                    setMenuValue("hold-booking");
                                  }}
                                  key="hold-booking"
                                >
                                  {" "}
                                  Hold booking
                                </MenuItem>
                              )}
                              {selectedReservationData?.booking_status !==
                                "CHECKED_OUT" &&
                                selectedReservationData?.booking_status !==
                                  "ADMIN_CONFIRMED" &&
                                JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.CancelBooking
                                ).read === "YES" && (
                                  <MenuItem
                                    onClick={() => {
                                      setMenuOpen(!menuOpen);
                                      setMenuValue("cancel-booking");
                                    }}
                                    key="cancel-booking"
                                  >
                                    Cancel booking
                                  </MenuItem>
                                )}
                              {selectedReservationData?.booking_status !==
                                "CHECKED_OUT" &&
                                reservationData?.is_enquiry === "false" && (
                                  <MenuItem
                                    onClick={() => {
                                      setMenuOpen(!menuOpen);
                                      setMenuValue("no-show");
                                    }}
                                    disabled={
                                      eventData.event.extendedProps.status !==
                                      "CONFIRMED"
                                    }
                                    key="no-show"
                                  >
                                    {" "}
                                    Set to no show
                                  </MenuItem>
                                )}
                              {selectedReservationData?.booking_status !==
                                "CHECKED_OUT" &&
                                JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.LockAndUnlockBooking
                                ).read === "YES" && (
                                  <MenuItem
                                    onClick={() => {
                                      setMenuOpen(!menuOpen);
                                      setMenuValue("lock-booking");
                                    }}
                                    key="lock-booking"
                                  >
                                    {eventData.event.extendedProps
                                      .locked_status !== "LOCKED"
                                      ? "Lock booking"
                                      : "Unlock booking"}
                                  </MenuItem>
                                )}
                              {selectedReservationData?.booking_status !==
                                "CHECKED_OUT" &&
                                JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.UnassignRoom
                                ).read === "YES" && (
                                  <MenuItem
                                    onClick={() => {
                                      setMenuOpen(!menuOpen);
                                      setMenuValue("unassign-room");
                                    }}
                                    key="unassign-room"
                                  >
                                    Unassign room
                                  </MenuItem>
                                )}
                              {selectedReservationData?.booking_status !==
                                "CHECKED_OUT" &&
                                JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.ModifyCheckIn
                                ).read === "YES" && (
                                  <MenuItem
                                    disabled={
                                      eventData.event.extendedProps.status ===
                                      "ADMIN_CONFIRMED"
                                    }
                                    onClick={() => {
                                      setMenuOpen(!menuOpen);
                                      setMenuValue("extend-checkin");
                                    }}
                                    key="extend-checkin"
                                  >
                                    Modify checkin
                                  </MenuItem>
                                )}
                              {selectedReservationData?.booking_status !==
                                "CHECKED_OUT" &&
                                JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.ModifyCheckOut
                                ).read === "YES" && (
                                  <MenuItem
                                    onClick={() => {
                                      setMenuOpen(!menuOpen);
                                      setMenuValue("extend-checkout");
                                    }}
                                    key="extend-checkout"
                                  >
                                    Modify checkout
                                  </MenuItem>
                                )}
                              {selectedReservationData?.booking_status !==
                                "CHECKED_OUT" &&
                                JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.EditReservation
                                ).read === "YES" && (
                                  <MenuItem
                                    onClick={() => {
                                      setMenuOpen(!menuOpen);
                                      setMenuValue("move");
                                    }}
                                    key="move"
                                  >
                                    Edit reservation
                                  </MenuItem>
                                )}
                              {/* {reservationData?.is_enquiry === "true" && (
                            <MenuItem onClick={Confirmed} key="confirm">
                              Confirmed booking
                            </MenuItem>
                          )} */}
                              {((subUser.toLowerCase() === "subuser" &&
                                hotelemail !==
                                  "reservation@maharashtratourism.gov.in") ||
                                subUser.toLowerCase() === "admin") &&
                                selectedReservationData?.booking_status !==
                                  "CHECKED_OUT" &&
                                reservationData?.is_enquiry === "false" &&
                                JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.SplitRoom
                                ).read === "YES" &&
                                hotel.slotType !== "PURE_HOURLY" && (
                                  <MenuItem
                                    onClick={() => {
                                      setMenuOpen(!menuOpen);
                                      setMenuValue("split-room");
                                    }}
                                    key="split-room"
                                  >
                                    Split Room
                                  </MenuItem>
                                )}
                              {((subUser.toLowerCase() === "subuser" &&
                                hotelemail !==
                                  "reservation@maharashtratourism.gov.in") ||
                                subUser.toLowerCase() === "admin") &&
                                selectedReservationData?.booking_status !==
                                  "CHECKED_OUT" && (
                                  <MenuItem
                                    onClick={() => {
                                      setMenuOpen(!menuOpen);
                                      setMenuValue("move-room");
                                    }}
                                    key="move-room"
                                  >
                                    Move Room
                                  </MenuItem>
                                )}
                              {/* <MenuItem onClick={() => {
                            setMenuOpen(!menuOpen)
                            setMenuValue("upgrade")
                          }} key="upgrade">Upgrade resrvation</MenuItem> */}
                              {reservationData.booking_status ===
                                "ADMIN_CONFIRMED" &&
                                JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.RollbackBooking
                                ).read === "YES" && (
                                  <MenuItem
                                    onClick={() => handleOpenRollbackBooking()}
                                  >
                                    Rollback booking
                                  </MenuItem>
                                )}

                              {reservationData.booking_status === "CANCELLED" &&
                                JSON.parse(
                                  hotel?.accessControl?.user_feature_map
                                    ?.RollbackBooking
                                ).read === "YES" && (
                                  <MenuItem
                                    onClick={() => handleOpenRollbackBooking()}
                                  >
                                    Rollback booking
                                  </MenuItem>
                                )}

                              {selectedReservationData?.booking_status ===
                                "CHECKED_OUT" && (
                                <MenuItem
                                  onClick={() =>
                                    handleOpenReleaseInventoryModal()
                                  }
                                  key="move-room"
                                >
                                  Release inventory
                                </MenuItem>
                              )}
                              {/* <MenuItem
                            onClick={() => {
                              setMenuOpen(!menuOpen);
                              setMenuValue("upgrade");
                            }}
                            key="upgrade"
                          >
                            Move and upgrade resrvation
                          </MenuItem> */}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              )}

              <IconButton title="close" onClick={handleAddEventSidebar}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          {/* Confirmation modal for rolling back this booking */}
          <Modal
            open={openRollbackBooking}
            onClose={handleCloseRollbackBooking}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="foliosConfirmationModal">
              <div className="folioConfirmationWrapper">
                <div className="folioConfContent">
                  Confirm rollback action on this booking?
                </div>
                <div className="folioConfButtons">
                  <button
                    className="cancel"
                    onClick={() => handleCloseRollbackBooking()}
                  >
                    Cancel
                  </button>
                  {rollbackLoader === false ? (
                    <button
                      className="approved"
                      onClick={() =>
                        handleRollbackBooking(reservationData.booking_id)
                      }
                      style={{ width: "150px" }}
                    >
                      Rollback booking
                    </button>
                  ) : (
                    <button className="approved" style={{ width: "150px" }}>
                      <CircularProgress size="15px" />
                    </button>
                  )}
                </div>
              </div>
            </Box>
          </Modal>

          {/* Open Release Inventory Modal*/}
          <Modal
            open={openReleaseInventory}
            onClose={handleCloseReleaseInventoryModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="foliosConfirmationModal">
              <div className="folioConfirmationWrapper">
                <div className="folioConfContent">
                  Do you want to release the inventory?
                </div>
                <div className="folioConfButtons">
                  <button
                    className="cancel"
                    onClick={() => handleCloseReleaseInventoryModal()}
                  >
                    Cancel
                  </button>
                  {rollbackLoader === false ? (
                    <button
                      className="approved"
                      onClick={() => handleReleaseInventory()}
                      style={{ width: "150px" }}
                    >
                      Release inventory
                    </button>
                  ) : (
                    <button className="approved" style={{ width: "150px" }}>
                      <CircularProgress size="15px" />
                    </button>
                  )}
                </div>
              </div>
            </Box>
          </Modal>

          {loadingData && (
            <Grid
              sx={{ width: "100%", height: "inherit" }}
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <DimmingDotsLoader />
            </Grid>
          )}
          {!loadingData && (
            <Grid container height={"max-content"}>
              <Grid item xs={12}>
                <Tabs
                  sx={{
                    borderBottom: 1,
                    borderTop: 1,
                    borderColor: "divider",
                    width: "100%",
                  }}
                  variant="fullWidth"
                  value={tabvalue}
                  onChange={(event, newVal) => {
                    setTabValue(newVal);
                  }}
                >
                  <Tab
                    sx={{ alignItems: "baseline", textTransform: "none" }}
                    value="booking-information"
                    label="Booking Information"
                  ></Tab>
                  <Tab
                    sx={{ alignItems: "baseline", textTransform: "none" }}
                    value="customer-information"
                    label="Customer Information"
                  ></Tab>
                  {bookingnotesLength + customernotesLength > 0 && (
                    <Badge
                      color="primary"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      style={{ marginRight: "-4.4rem", marginTop: "0.9rem" }}
                      badgeContent={`${
                        bookingnotesLength + customernotesLength
                      }`}
                    ></Badge>
                  )}
                  <Tab
                    sx={{ alignItems: "baseline", textTransform: "none" }}
                    value="notes"
                    label="Notes"
                  ></Tab>
                </Tabs>
              </Grid>
            </Grid>
          )}
          {!loadingData && (
            <Grid item>
              {menuValue === "" && tabvalue === "customer-information" && (
                <CustomerData
                  allGuestList={allGuestList}
                  saveCustomer={saveCustomer}
                  saveCustomerRes={saveCustomerRes}
                  load={load}
                  reservationData={reservationData}
                  reloadReservationData={reloadReservationData}
                  doNotRent={doNotRent}
                  getDoNotRentStatus={getDoNotRentStatus}
                  setLoad={setLoad}
                  eventData={eventData}
                  fetchGuestList={() => fetchGuestList()}
                  handleAddEventSidebar={handleAddEventSidebar}
                  setShowModal={setShowModal}
                  setTabValue={setTabValue}
                  setActionType={setActionType}
                  setShows={setShow}
                />
              )}
              {menuValue === "" && tabvalue === "booking-information" && (
                <CustomerInformation
                  reservationData={reservationData}
                  reload={reload}
                  closeBackDrop={handleAddEventSidebar}
                  eventData={eventData}
                  resId={eventData.event.extendedProps.reservationId}
                  fetchReservationData={fetchReservationData}
                  selectedReservationData={selectedReservationData}
                  allGuestList={allGuestList}
                  isNightAuditOn={isNightAuditOn}
                  subUser={subUser}
                  setMenuValue={setMenuValue}
                  handleOpenReleaseInventoryModal={
                    handleOpenReleaseInventoryModal
                  }
                  handleOpenRollbackBookings={handleOpenRollbackBooking}
                  // viewFolio={viewFolio}
                />
              )}
              {menuValue === "" && tabvalue === "notes" && (
                <Notes
                  allGuestList={allGuestList}
                  reservationData={reservationData}
                  saveCustomer={saveCustomer}
                  saveCustomerRes={saveCustomerRes}
                  reload={reload}
                  load={load}
                  reloadReservationData={reloadReservationData}
                  doNotRent={doNotRent}
                  getDoNotRentStatus={getDoNotRentStatus}
                  setLoad={setLoad}
                  closeBackDrop={handleAddEventSidebar}
                  eventData={eventData}
                  fetchGuestList={() => fetchGuestList()}
                />
              )}
            </Grid>
          )}
          {menuValue === "hold-booking" && (
            <HoldBooking
              value="hold-booking"
              title="Hold Booking"
              buttonText="Yes, Hold Booking"
              reservationData={reservationData}
              buttonFunction={holdExisting}
              closeBackDrop={handleAddEventSidebar}
              load={load}
              reload={reload}
              closeModal={displayFalse}
              reservation_id={eventData.event.extendedProps.reservationId}
              eventData={eventData}
            />
          )}
          {menuValue === "unassign-room" && (
            <HoldBooking
              value="unassign-room"
              title="Unassign Room"
              buttonText="Yes, un-assign"
              reservationData={reservationData}
              buttonFunction={unassign}
              closeBackDrop={handleAddEventSidebar}
              load={load}
              closeModal={displayFalse}
              reservation_id={eventData.event.extendedProps.reservationId}
              eventData={eventData}
            />
          )}
          {menuValue === "lock-booking" && (
            <HoldBooking
              value="lock-booking"
              title={
                eventData.event.extendedProps.locked_status !== "LOCKED"
                  ? "Lock Room"
                  : "Unlock Room"
              }
              buttonText={
                eventData.event.extendedProps.locked_status !== "LOCKED"
                  ? "Yes, Lock"
                  : "Yes, Unlock"
              }
              reservationData={reservationData}
              buttonFunction={setLock}
              load={load}
              closeBackDrop={handleAddEventSidebar}
              closeModal={displayFalse}
              reservation_id={eventData.event.extendedProps.reservationId}
              eventData={eventData}
            />
          )}
          {menuValue === "no-show" && (
            <CancelBooking
              value="no-show"
              title="Set No Show"
              buttonText="Yes"
              buttonFunction={setNoShow}
              reservationData={reservationData}
              load={load}
              closeBackDrop={handleAddEventSidebar}
              closeModal={displayFalse}
              reservation_id={eventData.event.extendedProps.reservationId}
              setwaiveNoShowFee={setwaiveNoShowFee}
              waiveNoShowFee={waiveNoShowFee}
            />
          )}
          {menuValue === "cancel-booking" && (
            <CancelBooking
              value="cancel-booking"
              title="Cancel Booking"
              buttonText="Yes, Cancel Booking"
              reservationData={reservationData}
              buttonFunction={cancelExisting}
              closeBackDrop={handleAddEventSidebar}
              load={load}
              closeModal={displayFalse}
              reservation_id={eventData.event.extendedProps.reservationId}
            />
          )}
          {menuValue === "extend-checkin" && (
            <ExtendEventSidebar
              reload={reload}
              eventData={returnDataObj("start")}
              open={menuValue === "extend-checkin"}
              handleAddEventSidebar={displayFalse}
              addEvent={checkinCheckout}
            ></ExtendEventSidebar>
          )}
          {menuValue === "extend-checkout" && (
            <ExtendEventSidebar
              reload={reload}
              eventData={returnDataObj("")}
              open={menuValue === "extend-checkout"}
              handleAddEventSidebar={displayFalse}
              addEvent={checkinCheckout}
            ></ExtendEventSidebar>
          )}
          {menuValue === "split-room" && (
            <SplitRoom
              value="split-room"
              title="Split Room"
              buttonText="Yes, Split room"
              roomID={reservationData.reservation_rooms}
              bookingId={reservationData.booking_id}
              buttonFunction={cancelExisting}
              closeBackDrop={handleAddEventSidebar}
              load={load}
              closeModal={displayFalse}
              reservation_id={eventData.event.extendedProps.reservationId}
              reload={reload}
              reservationData={reservationData}
            />
          )}
          {menuValue === "move-room" && (
            <MoveRoom
              value="move-room"
              title="Move Room"
              buttonText="Yes, Move room"
              bookingId={reservationData.booking_id}
              buttonFunction={cancelExisting}
              closeBackDrop={handleAddEventSidebar}
              load={load}
              closeModal={displayFalse}
              reservation_id={eventData.event.extendedProps.reservationId}
              reservationData={reservationData}
              reload={reload}
            />
          )}
          {menuValue === "move" && (
            <DragEventSidebar
              reload={reload}
              eventData={returnDataObj("move")}
              open={menuValue === "move"}
              handleAddEventSidebar={displayFalse}
              addEvent={checkinCheckout}
              roomInformation={roomInformation}
              roomTypeInfo={roomTypeInfo}
              editRes={true}
              reservationData={reservationData}
            ></DragEventSidebar>
          )}
          {menuValue === "upgrade" && (
            <DragEventSidebar
              reload={reload}
              eventData={returnDataObj("upgrade")}
              open={menuValue === "upgrade"}
              handleAddEventSidebar={displayFalse}
              addEvent={checkinCheckout}
              roomInformation={roomInformation}
              roomTypeInfo={roomTypeInfo}
              upgrade={"upgrade"}
            ></DragEventSidebar>
          )}
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default EditEventSidebar;
