import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const GETALLREPORTSSCHEDULE =
  "core/api/v1/reportScheduler/getAllReportSchedule";
const CREATEREPORTSCHEDULE = "core/api/v1/reportScheduler/addReportSchedule";
const GETALLREPORTTYPES = "core/api/v1/reportScheduler/getAllReportTypes";
const ACTIVATEREPORTS = "core/api/v1/reportScheduler/activateReportSchedule";
const DEACTIVATEREPORTS =
  "core/api/v1/reportScheduler/deactivateReportSchedule";

const DELETEREPORT = "core/api/v1/reportScheduler/deleteReportSchedule";

export const getAllReportSchedules = async () => {
  return axios
    .get(COMMON_URL + GETALLREPORTSSCHEDULE)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deactiviateReport = async (id) => {
  return axios
    .get(COMMON_URL + DEACTIVATEREPORTS + "?reportId=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const activiateReport = async (id) => {
  return axios
    .get(COMMON_URL + ACTIVATEREPORTS + "?reportId=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteReports = async (id) => {
  return axios
    .delete(COMMON_URL + DELETEREPORT + "?reportId=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
export const getAllReporttype = async () => {
  return axios
    .get(COMMON_URL + GETALLREPORTTYPES)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createschedule = async (data) => {
  return axios
    .post(COMMON_URL + CREATEREPORTSCHEDULE, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

// export const addNewFlexibleSlots = async (data) => {
//   return axios
//     .post(COMMON_URL + FLEXIBLESLOT, data)
//     .then((resp) => resp.data)
//     .catch((err) => {
//       throw new Error(err);
//     });
// };

// export const getAllFlexibleSlots = async (data) => {
//   return axios
//     .get(
//       COMMON_URL +
//         GETFLEXIBLESLOTS +
//         "?fromDate=" +
//         data.fromDate +
//         "&toDate=" +
//         data.toDate
//     )
//     .then((resp) => resp.data)
//     .catch((err) => {
//       throw new Error(err);
//     });
// };

// export const getAvailableRoom = async (params) => {
//   return axios
//     .get(COMMON_URL + GETROOM + "/?roomTypeId=" + params)
//     .then((resp) => resp.data)
//     .catch((err) => {
//       throw new Error(err);
//     });
// };

// export const getAllCustomers = async (search, searchByFeild) => {
//   return axios
//     .get(
//       COMMON_URL +
//         "core/api/v1/reservation/fetch-customers-elastic-search" +
//         "?search=" +
//         search +
//         "&searchByFeild=" +
//         searchByFeild
//     )
//     .then((resp) => resp.data)
//     .catch((err) => {
//       throw new Error(err);
//     });
// };

// export const imageUpdateByEmail = async (oldEmail, newEmail) => {
//   return axios
//     .get(
//       COMMON_URL +
//         "core/api/v1/image/update-id-to-new-email" +
//         "?oldEmailId=" +
//         oldEmail +
//         "&newEmailId=" +
//         newEmail
//     )
//     .then((resp) => resp.data)
//     .catch((err) => {
//       throw new Error(err);
//     });
// };
