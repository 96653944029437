import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
} from "recharts";
import { AmountFormatted, formatDate, formatDateXAxis } from "./util";

function GroupAreaChart({
  lineData,
  colors,
  revenueLine,
  fullScreen,
  currency,
}) {
  const [maxHeight, setMaxHeight] = useState(1);
  useEffect(() => {
    var height = 1;
    lineData.map((item) => {
      if (Number(item[revenueLine]) > height) {
        height = Number(item[revenueLine]);
      }
    });
    setMaxHeight(Math.ceil(height));
  }, [lineData, revenueLine]);

  const [graphWidth, setGraphWidth] = useState();
  useEffect(() => {
    var screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (fullScreen) {
      setGraphWidth(Math.round((screenWidth * 44) / 100));
    } else {
      setGraphWidth(Math.round((screenWidth * 35) / 100));
    }
  }, [fullScreen]);

  return (
    <AreaChart
      width={graphWidth}
      height={300}
      data={lineData}
      margin={{
        top: 20,
      }}
    >
      {lineData.length < 1 && <CartesianGrid strokeDasharray="3 3" />}
      {lineData.length < 1 && (
        <g transform={`translate(${graphWidth / 2},${300 / 2})`}>
          <Text textAnchor="middle" verticalAnchor="middle">
            No data available
          </Text>
        </g>
      )}
      <XAxis
        dataKey="date"
        padding={{ left: 30, right: 30 }}
        tick={{ fontSize: 12, fill: "black", angle: -15, textAnchor: "end" }}
        tickFormatter={formatDateXAxis}
      />

      <YAxis
        domain={[0, maxHeight]}
        label={{
          value: `Amount (${localStorage.getItem("hotelCurrency")})`,
          angle: -90,
          position: "insideLeft",
        }}
        tickFormatter={AmountFormatted}
      />

      <Tooltip
        content={<CustomTooltip currency={currency} />}
        cursor={{ fill: "transparent" }}
      />
      {/* <Legend /> */}
      {revenueLine?.map((elm, idx) => (
        <Area
          type="monotone"
          dataKey={elm}
          stroke={colors[3]}
          // fill={colors[3]}
          fillOpacity={1}
          fill={`url(#${elm})`}
        />
      ))}
      {revenueLine?.map((elm, idx) => (
        <defs>
          <linearGradient id={elm} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors[3]} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors[3]} stopOpacity={0} />
          </linearGradient>
        </defs>
      ))}
    </AreaChart>
  );
}

export default GroupAreaChart;

const CustomTooltip = ({ active, payload, label, currency }) => {
  if (active && payload && payload.length) {
    // console.log(payload);
    return (
      <div className="custom-tooltip">
        <p className="label">{`${formatDate(label)}`}</p>
        <div>
          {payload.map((pld) => (
            <div style={{ display: "inline-block", padding: 5 }}>
              <div style={{ color: "black" }}>
                {pld?.dataKey} : {currency} {pld?.value}
              </div>
              {/* <div style={{ color: "gray" }}> drivers</div> */}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};
