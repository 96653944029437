import React, { useEffect, useState } from "react";
import { createPicker } from "./utils";
import printDoc from "./printDoc";
import { connect } from "react-redux";
import { handleSpringDataRequest } from "../../../api/index";
import { toast } from "material-react-toastify";

const PDFExportPanel = (props) => {
  const [PDF_PAGE_ORITENTATION, SET_PDF_PAGE_ORIENTATION] =
    useState("landscape");
  const [PDF_WITH_HEADER_IMAGE, SET_PDF_WITH_HEADER_IMAGE] = useState(false);
  const [PDF_WITH_FOOTER_PAGE_COUNT, SET_PDF_WITH_FOOTER_PAGE_COUNT] =
    useState(true);
  const [PDF_HEADER_HEIGHT, SET_PDF_HEADER_HEIGHT] = useState(25);
  const [PDF_ROW_HEIGHT, SET_PDF_ROW_HEIGHT] = useState(15);

  const [PDF_ODD_BKG_COLOR, SET_PDF_ODD_BKG_COLOR] = useState("#fcfcfc");
  const [PDF_EVEN_BKG_COLOR, SET_PDF_EVEN_BKG_COLOR] = useState("#fff");
  const [PDF_WITH_CELL_FORMATTING, SET_PDF_WITH_CELL_FORMATTING] =
    useState(true);
  const [PDF_WITH_COLUMNS_AS_LINKS, SET_PDF_WITH_COLUMNS_AS_LINKS] =
    useState(true);

  const [PDF_SELECTED_ROWS_ONLY, SET_PDF_SELECTED_ROWS_ONLY] = useState(false);

  const PDF_HEADER_COLOR = "#f8f8f8";
  const PDF_INNER_BORDER_COLOR = "#dde2eb";
  const PDF_OUTER_BORDER_COLOR = "#babfc7";

  console.log(props?.directBilling);

  //Address state
  const [address, setAddress] = useState({
    address1: props?.props?.accessControl?.hotel?.hotel_address,
    address2: props?.props?.accessControl?.hotel?.hotel_address_line2,
    city: props?.props?.accessControl?.hotel?.hotel_city,
    state: props?.props?.accessControl?.hotel?.hotel_state,
    country: props?.props?.accessControl?.hotel?.hotel_country,
    pincode: props?.props?.accessControl?.hotel?.hotel_zipcode,
    hotel: props?.props?.accessControl?.hotel?.hotel_name,
  });

  const [docName, setdocName] = useState(props?.docName);

  const [base64Img, setBase64Img] = useState("");

  function getBase64Image() {
    handleSpringDataRequest("core/api/v1/image/get-hotel-base64-logo/")
      .then((res) => setBase64Img(res))
      .catch((err) => toast.error("Error receiving the hotel logo"));
  }

  useEffect(() => {
    getBase64Image();
  }, []);

  const [EXTENSION, setExtension] = useState(null);
  // get the image extension
  const getExtension = (img) => {
    if (img?.charAt(0) === "/") {
      setExtension("jpeg");
    } else if (img?.charAt(0) === "i") {
      setExtension("png");
    } else if (img?.charAt(0) === "U") {
      setExtension("webp");
    } else if (img?.charAt(0) === "R") {
      setExtension("gif");
    }
  };

  useEffect(() => {
    if (base64Img.length > 0) {
      getExtension(base64Img);
    }
  }, [base64Img]);

  console.log("base 64 extension : ", base64Img, EXTENSION)

  const PDF_LOGO = base64Img;
  // props?.props.hotelLogoUrl
  // "https://raw.githubusercontent.com/AhmedAGadir/ag-grid-todo-list-react-typescript/master/src/assets/new-ag-grid-logo.png";

  useEffect(() => {
    const pickrOddRowBkgColor = createPicker(
      ".color-picker-odd-row-bkg",
      PDF_ODD_BKG_COLOR
    );

    const pickEvenRowBkgColor = createPicker(
      ".color-picker-even-row-bkg",
      PDF_EVEN_BKG_COLOR
    );

    pickrOddRowBkgColor.on("save", (color, instance) => {
      const updatedColor = color.toHEXA().toString();
      SET_PDF_ODD_BKG_COLOR(updatedColor);
      instance.hide();
    });

    pickEvenRowBkgColor.on("save", (color, instance) => {
      const updatedColor = color.toHEXA().toString();
      SET_PDF_EVEN_BKG_COLOR(updatedColor);
      instance.hide();
    });
  }, []);

  // Handling the number of enteries
  const [enteries, setEnteries] = useState(0);
  useEffect(() => {
    if (PDF_SELECTED_ROWS_ONLY) {
      return setEnteries(props?.selectedNumEnteries);
    } else {
      return setEnteries(props?.numEnteries);
    }
  }, [PDF_SELECTED_ROWS_ONLY]);

  //Function to create adderss
  const concatAddress = () => {
    const addr1 =
      address &&
      address.address1 !== null &&
      address.address1 !== "NA" &&
      address.address1;
    const addr2 =
      address &&
      address.address2 !== null &&
      address.address2 !== "NA" &&
      address.address2;

    let finaladd = "";
    if (addr1 && addr1 !== undefined && (addr2 === null || addr2 === undefined))
      finaladd = addr1;
    else if (
      addr2 &&
      addr2 !== undefined &&
      (addr1 === null || addr1 === undefined)
    )
      finaladd = addr2;
    else finaladd = addr1 + ", " + addr2;
    return finaladd;
  };

  // Handling customer address
  const handleCustomerAddress = (address) => {
    if (!address.length > 0) {
      return "";
    } else if (address.includes("null")) {
      return "";
    } else if (address.includes("NA")) {
      return "";
    } else {
      let parsed = parseInt(address);
      return String(parsed) === String(NaN) ? [`${address}, `] : parsed + "";
    }
  };

  const [finalizedAddress, setFinalizedAddress] = useState({});

  useEffect(() => {
    const returnedadd = concatAddress();
    const createdAddress =
      handleCustomerAddress(address.city) +
      handleCustomerAddress(address.state) +
      handleCustomerAddress(address.country) +
      handleCustomerAddress(address.pincode);
    const hName = address.hotel;
    setFinalizedAddress({
      hotelName: hName,
      addressLines: returnedadd,
      cityAndStates: createdAddress,
    });
  }, [address]);

  const submitFormHandler = (event) => {
    event.preventDefault();

    const printParams = {
      PDF_HEADER_COLOR,
      PDF_INNER_BORDER_COLOR,
      PDF_OUTER_BORDER_COLOR,
      PDF_LOGO,
      PDF_PAGE_ORITENTATION,
      PDF_WITH_HEADER_IMAGE,
      PDF_WITH_FOOTER_PAGE_COUNT,
      PDF_HEADER_HEIGHT,
      PDF_ROW_HEIGHT,
      PDF_ODD_BKG_COLOR,
      PDF_EVEN_BKG_COLOR,
      PDF_WITH_CELL_FORMATTING,
      PDF_WITH_COLUMNS_AS_LINKS,
      PDF_SELECTED_ROWS_ONLY,
      EXTENSION,
    };

    printDoc(
      printParams,
      props.gridApi,
      props.columnApi,
      props.startdate,
      props.enddate,
      props.report_Type,
      enteries,
      props.housekeepingReport,
      finalizedAddress,
      docName,
      props.directBilling,
      props.shiftReport,
      props.shiftSummaryData
    );
  };

  return (
    <form onSubmit={submitFormHandler}>
      <h4 style={{ color: "black" }}>PDF Export Options</h4>
      <div className="mb-2">
        <input
          className="form-check-input"
          style={{ marginLeft: "5px" }}
          type="radio"
          name="orientation"
          id="landscape"
          value="landscape"
          checked={PDF_PAGE_ORITENTATION === "landscape"}
          onChange={(event) => {
            if (event.target.checked) {
              SET_PDF_PAGE_ORIENTATION(event.currentTarget.value);
            }
          }}
        />
        <label
          className="form-check-label"
          for="landscape"
          style={{ marginLeft: "20px" }}
        >
          Landscape
        </label>
        <input
          className="form-check-input"
          style={{ marginLeft: "15px" }}
          type="radio"
          name="orientation"
          id="portrait"
          value="portrait"
          checked={PDF_PAGE_ORITENTATION === "portrait"}
          onChange={(event) => {
            if (event.target.checked) {
              SET_PDF_PAGE_ORIENTATION(event.currentTarget.value);
            }
          }}
        />
        <label
          className="form-check-label"
          for="portrait"
          style={{ marginLeft: "30px" }}
        >
          Portrait
        </label>
      </div>
      {/* <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="headerImage"
          checked={PDF_WITH_HEADER_IMAGE}
          onChange={(event) => {
            SET_PDF_WITH_HEADER_IMAGE(event.target.checked);
          }}
        />
        <label className="form-check-label" for="headerImage">
          Header image (ag-Grid logo)
        </label>
      </div> */}
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="footerPageCount"
          checked={PDF_WITH_FOOTER_PAGE_COUNT}
          onChange={(event) => {
            SET_PDF_WITH_FOOTER_PAGE_COUNT(event.target.checked);
          }}
        />
        <label className="form-check-label" for="footerPageCount">
          Footer (page count)
        </label>
      </div>
      <div className="my-2">
        <input
          type="number"
          id="headerRowHeight"
          style={{ width: 50, marginRight: 5 }}
          value={PDF_HEADER_HEIGHT}
          onChange={(event) => {
            SET_PDF_HEADER_HEIGHT(parseInt(event.target.value));
          }}
        />
        <label for="headerRowHeight">Header height</label>
      </div>
      <div className="my-2">
        <input
          type="number"
          id="cellRowHeight"
          style={{ width: 50, marginRight: 5 }}
          value={PDF_ROW_HEIGHT}
          onChange={(event) => {
            SET_PDF_ROW_HEIGHT(parseInt(event.target.value));
          }}
        />
        <label for="cellRowHeight">Cell height</label>
      </div>
      <div className="color-picker-container" style={{ display: "none" }}>
        <div className="color-picker-odd-row-bkg" />
        <div>Odd row background color</div>
      </div>
      <div className="color-picker-container" style={{ display: "none" }}>
        <div className="color-picker-even-row-bkg" />
        <div>Even row background color</div>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="exportWithFormatting"
          checked={PDF_WITH_CELL_FORMATTING}
          onChange={(event) => {
            SET_PDF_WITH_CELL_FORMATTING(event.target.checked);
          }}
        />
        <label className="form-check-label" for="exportWithFormatting">
          Cell styles
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="exportColumnsAsLink"
          checked={PDF_WITH_COLUMNS_AS_LINKS}
          onChange={(event) => {
            SET_PDF_WITH_COLUMNS_AS_LINKS(event.target.checked);
          }}
        />
        <label className="form-check-label" for="exportColumnsAsLink">
          Hyperlinks
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectedRowsOnly"
          checked={PDF_SELECTED_ROWS_ONLY}
          onChange={(event) => {
            SET_PDF_SELECTED_ROWS_ONLY(event.target.checked);
          }}
        />
        <label className="form-check-label" for="selectedRowsOnly">
          Selected rows only
        </label>
      </div>
      {base64Img.length > 0 && EXTENSION ? (
        <button type="submit" className="btn btn-primary mt-3">
          Export to PDF
        </button>
      ) : (
        <button className="btn btn-primary mt-3">Preparing to export...</button>
      )}
    </form>
  );
};

// export default PDFExportPanel;

function mapStateToProps(state) {
  return { props: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(PDFExportPanel);
