import { Box, Skeleton } from "@mui/material";
import React from "react";

export function RectSkeleton() {
  return <Skeleton variant="rounded" width={"100%"} height={350} />;
}
export function CirculerSkeleton() {
  return <Skeleton variant="circular" width={350} height={350} />;
}

export function NoDataAvailable() {
  return (
    <Box
      sx={{
        height: 300,
        width: 300,
        border: "1px dashed gray",
        borderRadius: 150,
      }}
    >
      <p align="center" style={{ color: "grey", marginTop: 150 }}>
        No data available
      </p>
    </Box>
  );
}
