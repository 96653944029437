import React from "react";

const NewDimmingDotsLoader = ({}) => {
  return (
    <>
      <h2>No rooms are available please select different room type </h2>
      <div className="loader-new dimming-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export default NewDimmingDotsLoader;
