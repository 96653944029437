import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Grid } from "@mui/material";
import { updateEnquiryStatus } from "../BookingDashboard/apis/BookingDashboardApi";
import { CircularProgress } from "@mui/material";

const RequestToBookRender = () => {
  const url = new URL(window.location.href);
  const [data, setData] = useState();
  const hash = url.searchParams.get("hash");
  const [requestToBookEnable, setRequestToBookEnable] = useState();
  const [loader, setLoader] = useState(false);
  const [showFirst, setShowFirst] = useState(false);

  useEffect(async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SPRING_API_URL}` +
          "core/api/v1/reservation/get-guest-data",
        {
          hashval: hash.split(" ").join("+"),
        }
      )
      .then((resp) => {
        if (resp?.data) {
          setData(resp?.data);
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [hash]);

  const bookingResponse = async (flag) => {
    setLoader(true);
    let hotelId = data?.bookingId.split("_")[1];
    await axios
      .get(
        `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/be/request-to-book-response?hotelId=${hotelId}&bookingId=${data?.bookingId}&requestFlag=${flag}`
      )
      .then((resp) => {
        console.log("resp: ", resp);
      })
      .then((resp) => setLoader(false))
      .then(async (resp) => {
        setShowFirst(true);
        await axios
          .get(
            `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/be/get-request-status?hotelId=${hotelId}&bookingId=${data?.bookingId}`
          )
          .then((resp) => {
            if (resp?.data) {
              setRequestToBookEnable(resp?.data);
            }
          })
          .catch((err) => {
            throw new Error(err);
          });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  useEffect(async () => {
    if (data?.bookingId) {
      let hotelId = data?.bookingId.split("_")[1];
      await axios
        .get(
          `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/be/get-request-status?hotelId=${hotelId}&bookingId=${data?.bookingId}`
        )
        .then((resp) => {
          if (resp?.data) {
            setRequestToBookEnable(resp?.data);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  }, [data]);

  //Similar to mailer
  const declineBooking = () => {};
  return (
    <div style={{ display: "grid", marginTop: "-20%" }}>
      <div className="ishaHeader">
        {" "}
        <img
          src={
            "https://d13hqbjnktcib7.cloudfront.net/bookingChannels/sflogos.png"
          }
          style={{ heigth: "8rem", width: "10rem", marginLeft: "2rem" }}
          alt="logo"
        />
      </div>
      {requestToBookEnable === undefined ? (
        <div style={{ textAlign: "center", width: "100vw", marginTop: "-5%" }}>
          <CircularProgress size="100px" />
        </div>
      ) : (
        <>
          {(requestToBookEnable?.booking_status === "CONFIRMED" &&
            requestToBookEnable?.enquiry_status === "false") || //True condition
          requestToBookEnable?.invoice_id !== "" || // Response submitted
          requestToBookEnable?.booking_status !== "CONFIRMED" ? ( //False condition
            <div style={{ textAlign: "center", width: "100vw" }}>
              {" "}
              <h1>
                {showFirst
                  ? "The response regarding the provided booking " +
                    data?.bookingId +
                    " has been submitted."
                  : "The response regarding the provided booking " +
                    data?.bookingId +
                    " has already been submitted."}
              </h1>
            </div>
          ) : (
            <Grid
              spacing={0}
              container
              display="grid"
              justifyContent="center"
              alignItems="center"
              style={{ marginLeft: "25%", width: "60%", marginTop: "-10%" }}
            >
              <Grid item xs={12}>
                <h2>
                  Room Booking Request - Confirmation Required for Booking:{" "}
                  {data?.bookingId}
                </h2>
              </Grid>
              <Grid item xs={12}>
                <h3>Booking details</h3>
              </Grid>
              <Grid spacing={0} container style={{ fontSize: "17px" }}>
                {/* Guest name*/}
                <Grid item xs={3} textAlign={"start"}>
                  Guest name
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {data?.guestName}
                </Grid>
                <Grid item xs={4}></Grid>
                {/* Guest phone number*/}
                <Grid item xs={3} textAlign={"start"}>
                  Guest phone number
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {data?.guestNumber}
                </Grid>
                <Grid item xs={4}></Grid>
                {/* Guest check in date*/}
                <Grid item xs={3} textAlign={"start"}>
                  Guest check-in date
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {data?.checkIn}
                </Grid>
                <Grid item xs={4}></Grid>
                {/* Guest check out date*/}
                <Grid item xs={3} textAlign={"start"}>
                  Guest check-out date
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {data?.checkOut}
                </Grid>
                <Grid item xs={4}></Grid>
                {/* Guest number of adults*/}
                <Grid item xs={3} textAlign={"start"}>
                  Guest adults/children
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {data?.numberOfAdults + "/" + data?.numberOfChilds}
                </Grid>
                <Grid item xs={4}></Grid>
                {/* Guest room type*/}
                <Grid item xs={3} textAlign={"start"}>
                  Guest room type
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {data?.roomTypes}
                </Grid>
                <Grid item xs={4}></Grid>
                {/* Guest rate plan*/}
                <Grid item xs={3} textAlign={"start"}>
                  Guest rate plan
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {data?.ratePlan}
                </Grid>
                <Grid item xs={4}></Grid>
                {/* Guest special requests*/}
                <Grid item xs={3} textAlign={"start"}>
                  Guest special requests
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {data?.specialRequests}
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
              <Grid item xs={12}>
                <h3>Payment details</h3>
              </Grid>
              <Grid spacing={0} container style={{ fontSize: "17px" }}>
                {/* Guest amount*/}
                <Grid item xs={3} textAlign={"start"}>
                  Total amount
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {data?.currency + " " + data?.amount}
                </Grid>
                <Grid item xs={4}></Grid>
                {/* Guest payment method*/}
                {/* <Grid item xs={3} textAlign={"start"}>
                  Payment gateway
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {requestToBookEnable?.payment_gateway}
                </Grid>
                <Grid item xs={4}></Grid> */}
                {/*Invoice Id generated*/}
                {/* <Grid item xs={3} textAlign={"start"}>
                  Generated Invoice Id
                </Grid>
                <Grid item xs={1} textAlign={"start"}>
                  :
                </Grid>
                <Grid item xs={4} textAlign={"start"}>
                  {requestToBookEnable?.invoice_id}
                </Grid>
                <Grid item xs={4}></Grid> */}
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid spacing={2} container style={{ marginTop: "-20px" }}>
                <Grid item xs={6}>
                  <Button
                    variant="custom-button"
                    style={{ fontSize: "1.3rem" }}
                    onClick={() => bookingResponse(true)}
                    disabled={loader || requestToBookEnable?.invoice_id !== ""}
                  >
                    {loader ? (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    ) : (
                      <>Confirm booking</>
                    )}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="custom-button"
                    onClick={() => bookingResponse(false)}
                    style={{
                      background: "rgba(255, 0, 0, 0.8)",
                      fontSize: "1.3rem",
                    }}
                    disabled={loader || requestToBookEnable?.invoice_id !== ""}
                  >
                    {loader ? (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    ) : (
                      <>Decline booking</>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default RequestToBookRender;
