import React from "react";

function BookingDataLoader() {
  return (
    <div
      className={`mockLoaderTable d-flex flex-column  justify-content-between box-shadow-main`}
      style={{
        boxShadow: "none",
        marginTop: "4.5rem",
      }}
    >
      <div
        className="d-flex justify-content-between p-3"
        style={{
          boxShadow: "0px 0px 10px 5px #0000001a",
          width: "86%",
          borderRadius: "1rem",
          marginLeft: "7%",
          marginRight: "7%",
        }}
      >
        <div className="box p-2" style={{ minHeight: "9rem", width: "10%" }}>
          {" "}
        </div>
        <div
          className="flex-column  d-flex justify-content-start"
          style={{ width: "90%" }}
        >
          <div className="w-100 d-flex justify-content-between align-items-start">
            <div
              className="box w-50 p-2"
              style={{ minHeight: "2rem", marginRight: "5rem", flexGrow: "0" }}
            >
              {" "}
            </div>
            <div
              className="box p-2"
              style={{ minHeight: "1.5rem", flexGrow: "0", width: "10%" }}
            >
              {" "}
            </div>
          </div>
          <div
            className="box w-75 p-2 mt-4 mb-3"
            style={{ minHeight: "1.5rem", maxHeight: "1.5rem" }}
          >
            {" "}
          </div>
          <div
            className="box p-2 mt-3"
            style={{ height: "3rem", width: "20%", flexGrow: "0" }}
          >
            {" "}
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-between p-3"
        style={{
          boxShadow: "0px 0px 10px 5px #0000001a",
          width: "86%",
          marginTop: "1rem",
          borderRadius: "1rem",
          marginLeft: "7%",
          marginRight: "7%",
        }}
      >
        <div className="box p-2" style={{ minHeight: "9rem", width: "10%" }}>
          {" "}
        </div>
        <div
          className="flex-column  d-flex justify-content-start"
          style={{ width: "90%" }}
        >
          <div className="w-100 d-flex justify-content-between align-items-start">
            <div
              className="box w-50 p-2"
              style={{ minHeight: "2rem", marginRight: "5rem", flexGrow: "0" }}
            >
              {" "}
            </div>
            <div
              className="box p-2"
              style={{ minHeight: "1.5rem", flexGrow: "0", width: "10%" }}
            >
              {" "}
            </div>
          </div>
          <div
            className="box w-75 p-2 mt-4 mb-3"
            style={{ minHeight: "1.5rem", maxHeight: "1.5rem" }}
          >
            {" "}
          </div>
          <div
            className="box p-2 mt-3"
            style={{ height: "3rem", width: "20%", flexGrow: "0" }}
          >
            {" "}
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between p-3"
        style={{
          boxShadow: "0px 0px 10px 5px #0000001a",
          width: "86%",
          marginTop: "1rem",
          borderRadius: "1rem",
          marginLeft: "7%",
          marginRight: "7%",
        }}
      >
        <div className="box p-2" style={{ minHeight: "9rem", width: "10%" }}>
          {" "}
        </div>
        <div
          className="flex-column  d-flex justify-content-start"
          style={{ width: "90%" }}
        >
          <div className="w-100 d-flex justify-content-between align-items-start">
            <div
              className="box w-50 p-2"
              style={{ minHeight: "2rem", marginRight: "5rem", flexGrow: "0" }}
            >
              {" "}
            </div>
            <div
              className="box p-2"
              style={{ minHeight: "1.5rem", flexGrow: "0", width: "10%" }}
            >
              {" "}
            </div>
          </div>
          <div
            className="box w-75 p-2 mt-4 mb-3"
            style={{ minHeight: "1.5rem", maxHeight: "1.5rem" }}
          >
            {" "}
          </div>
          <div
            className="box p-2 mt-3"
            style={{ height: "3rem", width: "20%", flexGrow: "0" }}
          >
            {" "}
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between p-3"
        style={{
          boxShadow: "0px 0px 10px 5px #0000001a",
          width: "86%",
          marginTop: "1rem",
          borderRadius: "1rem",
          marginLeft: "7%",
          marginRight: "7%",
        }}
      >
        <div className="box p-2" style={{ minHeight: "9rem", width: "10%" }}>
          {" "}
        </div>
        <div
          className="flex-column  d-flex justify-content-start"
          style={{ width: "90%" }}
        >
          <div className="w-100 d-flex justify-content-between align-items-start">
            <div
              className="box w-50 p-2"
              style={{ minHeight: "2rem", marginRight: "5rem", flexGrow: "0" }}
            >
              {" "}
            </div>
            <div
              className="box p-2"
              style={{ minHeight: "1.5rem", flexGrow: "0", width: "10%" }}
            >
              {" "}
            </div>
          </div>
          <div
            className="box w-75 p-2 mt-4 mb-3"
            style={{ minHeight: "1.5rem", maxHeight: "1.5rem" }}
          >
            {" "}
          </div>
          <div
            className="box p-2 mt-3"
            style={{ height: "3rem", width: "20%", flexGrow: "0" }}
          >
            {" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingDataLoader;
