import React, { useState, useEffect } from "react";
import StockWrapper from "../StockWrapper";
import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  Modal,
  CircularProgress,
  ClickAwayListener,
} from "@mui/material";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import {
  handleSpringDataRequest,
  handleSpringDataMutationRequest,
} from "../../../api/index";
import Drawer from "@mui/material/Drawer";
import * as AiIcons from "react-icons/ai";
import moment from "moment";
import { toast } from "material-react-toastify";
import MockLoaderTable from "../../Folios/ViewManagePayments/MockLoaderTable";
import "material-react-toastify/dist/ReactToastify.css";
import { MdOutlineDriveFileMoveRtl } from "react-icons/md";
import { SecondaryButton } from "../../../Utils/ButtonHelper";
import { DateRange } from "react-date-range";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  paddingTop: "10px",
  paddingBottom: "10px",
};

const styling = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

function HousekeepingRawMaterial() {
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { SearchBar } = Search;
  // Check if the operation we are performing is EDIT or DELETE
  const [actionType, setActionType] = useState("create");
  const [supplier, setsupplier] = useState("");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //open and close date picker
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      dataField: "hotel_id",
      text: "Hotel Id",
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      //   hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "unit",
      text: "Unit",
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      isDummyField: true,
      text: "Actions",
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "115px",
      },
      formatter: (cell, row) => (
        <Stack direction="row" spacing={2}>
          <Tooltip title="Edit item">
            <Typography
              className="editIcon"
              onClick={() => {
                setActionType("update");
                setModifiedHKMaterialPayload(row);
                toggleDrawer(true);
              }}
            >
              <AiIcons.AiFillEdit size="18px" />
            </Typography>
          </Tooltip>
          <Tooltip title="Delete item">
            <Typography
              className="deleteIcon"
              onClick={() => {
                setModifiedHKMaterialPayload(row);
                handleOpenConfirmDeleteModal();
              }}
            >
              <AiIcons.AiFillDelete size="18px" />
            </Typography>
          </Tooltip>
        </Stack>
      ),
      editable: false,
    },
  ];

  // warehouse rm
  const [housekeepingRmData, setHousekeepingRmData] = useState(null);
  function handleGetWarehouseRm() {
    setHousekeepingRmData(null);
    handleSpringDataRequest("core/api/v1/hk-stock/get-all")
      .then((res) => setHousekeepingRmData(res))
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetWarehouseRm();
  }, []);

  // Map of units of measurement
  const [units, setUnits] = useState([
    "GRAM",
    "KG",
    "ML",
    "LITRE",
    "DISH",
    "PIECE",
    "GLASS",
    "MM",
    "METER",
    "PKTS",
    "NOS",
    "TIN",
    "BOX",
    "CAN",
    "BUN",
    "BAG",
    "QTY",
    "25 KG BAG",
    "BOTTLE",
    "JAR",
  ]);

  const [selectedUnits, setselectedUnits] = useState([
    "GRAM",
    "KG",
    "ML",
    "LITRE",
    "DISH",
    "PIECE",
    "GLASS",
    "MM",
    "METER",
    "PKTS",
    "NOS",
    "TIN",
    "BOX",
    "CAN",
    "BUN",
    "BAG",
    "QTY",
    "25 KG BAG",
    "BOTTLE",
    "JAR",
  ]);

  //Selected rows
  const [selectedRows, setSelectedRows] = useState([]);
  // row selection for operations
  // selecting the rows
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((selectedRows) => [...selectedRows, row.staff_id]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x !== row.staff_id));
    }
  };

  // selecting all rows at once
  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.staff_id);
    if (isSelect) {
      setSelectedRows(ids);
    } else {
      setSelectedRows([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedRows,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  // Modify update rm items
  const [modifyUpdateItems, setModifyUpdateItems] = useState(false);
  const toggleDrawer = (action) => {
    setModifyUpdateItems(action);
  };

  const [openBulkDrawer, setopenBulkDrawer] = useState(false);
  const toggleBulkDrawer = (action) => {
    setopenBulkDrawer(action);
  };
  //   Payload creation to add / update the warehouse material
  const [warehouseMaterialPayload, setWarehouseMaterialPayload] = useState({
    id: null,
    hotel_id: hotelId,
    name: "",
    quantity: 0,
    unit: "",
    description: "",
    status: "ACTIVE",
  });


  // Defining the modified payload state for adding and updating
  const [
    modifiedHKMaterialPayload,
    setModifiedHKMaterialPayload,
  ] = useState(null);
  //   Setting the modified payload to the initial state
  function resetWarehouseModifiedPayload() {
    setModifiedHKMaterialPayload(warehouseMaterialPayload);
  }
  useEffect(() => {
    resetWarehouseModifiedPayload();
  }, []);

  function resetBulkWarehouseModifiedPayload() {
    const data = [];
  }

  useEffect(() => {
    resetBulkWarehouseModifiedPayload();
  }, []);

  //   Function to add or update a field into the warehouse payload
  function handleChangeRMS(e) {
    const { name, value } = e.target;
    setModifiedHKMaterialPayload({
      ...modifiedHKMaterialPayload,
      [`${name}`]: value,
    });
  }

  // loader
  const [loading, setLoading] = useState(false);
  // Creating and updating the payload
  function handleCreateUpdatePayload() {
    if (modifiedHKMaterialPayload.name === "") {
      toast.error("Please enter name");
    } else if (modifiedHKMaterialPayload.quantity === "") {
      toast.error("Please enter quantity");
    } else if (modifiedHKMaterialPayload.unit === "") {
      toast.error("Please enter purchase unit");
    } else {
      setLoading(true);
      if (actionType === "create") {
        handleSpringDataMutationRequest(
          "POST",
          "core/api/v1/hk-stock/create",
          modifiedHKMaterialPayload
        )
          .catch((err) => toast.error(err))
          .then(() => setLoading(false))
          .then(() => toggleDrawer(false))
          .then(() => handleGetWarehouseRm());
      } else {
        handleSpringDataMutationRequest(
          "PUT",
          `core/api/v1/hk-stock/update`,
          modifiedHKMaterialPayload
        )
          .catch((err) => toast.error(err))
          .then(() => setLoading(false))
          .then(() => toggleDrawer(false))
          .then(() => handleGetWarehouseRm());
      }
    }
  }

  // Deleting the menu item
  // Delete confirmation modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  function handleOpenConfirmDeleteModal() {
    setConfirmDeleteModal(true);
  }
  function handleCloseConfirmDeleteModal() {
    setConfirmDeleteModal(false);
  }
  function handleDeleteMenuItem() {
    setLoading(true);
    handleSpringDataMutationRequest(
      "DELETE",
      `core/api/v1/hk-stock/delete?id=${modifiedHKMaterialPayload.id}`
    )
      .then((res) => toast.success(res))
      .catch((err) => toast.error(err))
      .then(() => handleGetWarehouseRm())
      .then(() => setLoading(false))
      .then(() => handleCloseConfirmDeleteModal());
  }

  console.log(
    "modifiedHKMaterialPayload",
    modifiedHKMaterialPayload
  );

  const list = () => (
    <Box
      role="presentation"
      className="addUpdateModal"
      style={{ overflowY: "yes" }}
    >
      <Stack className="headerWrapper">
        <Typography
          className="close"
          onClick={() => {
            toggleDrawer(false);
            resetWarehouseModifiedPayload();
          }}
        >
          <AiIcons.AiOutlineClose size="20px" />
        </Typography>
      </Stack>
      <Divider />

      <Stack className="fields">
        <TextField
          id="outlined-name"
          label="Material name"
          name="name"
          value={modifiedHKMaterialPayload?.name}
          onChange={handleChangeRMS}
          className="field"
          sx={{ width: "60%" }}
        />
        <TextField
          id="outlined-name"
          label="Quantity."
          name="quantity"
          value={modifiedHKMaterialPayload?.quantity}
          onChange={handleChangeRMS}
          className="field"
          sx={{ width: "29%" }}
          disabled={actionType === "update" && true}
        />
        <Box sx={{ width: "29%" }} className="field">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={modifiedHKMaterialPayload?.unit}
              label="Unit"
              name="unit"
              onChange={handleChangeRMS}
            >
              {units?.map((unit, index) => (
                <MenuItem value={unit} key={index}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <TextField
          id="outlined-name"
          label="Description"
          name="description"
          value={modifiedHKMaterialPayload?.description}
          onChange={handleChangeRMS}
          className="field"
          sx={{ width: "60%" }}
        />
      </Stack>

      <Stack className="ctas" direction="row" style={{ marginTop: "2rem" }}>
        <Button
          className="cancelBtn"
          style={{ marginTop: "2rem" }}
          onClick={() => {
            toggleDrawer(false);
            resetWarehouseModifiedPayload();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="custom-button"
          className="successBtn"
          onClick={!loading && handleCreateUpdatePayload}
          disabled={loading & true}
          style={{ marginTop: "2rem" }}
        >
          {!loading ? "Confirm" : <CircularProgress size="20px" />}
        </Button>
      </Stack>
    </Box>
  );

  return (
    <>
      <StockWrapper
        backBtn={false}
        hideDrawer={true}
        stockTypeName={"Housekeeping raw material list"}
        contentArea={
          <Stack className="position-relative mt-8">
            {housekeepingRmData ? (
              <ToolkitProvider
                keyField="staff_id"
                data={housekeepingRmData}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Stack
                      direction="row"
                      className="d-flex justify-content-between"
                      spacing={2}
                    >
                      <Stack className="searchBarWrapper">
                        <SearchBar
                          {...props.searchProps}
                          className="hkSearchBar"
                          style={{ height: "33.5px" }}
                        />
                      </Stack>
                      <div className="d-flex">
                        <Stack>
                          <Button
                            variant="custom-button"
                            onClick={() => {
                              resetWarehouseModifiedPayload();
                              setActionType("create");
                              toggleDrawer(true);
                            }}
                          >
                            Add raw material
                          </Button>
                        </Stack>
                      </div>
                    </Stack>
                    <BootstrapTable
                      {...props.baseProps}
                      tabIndexCell
                      headerWrapperClasses="foo"
                      bodyClasses="tableBody"
                      bordered={false}
                      //   selectRow={selectRow}
                      pagination={paginationFactory()}
                      limitTags={3}
                    />
                  </>
                )}
              </ToolkitProvider>
            ) : (
              <MockLoaderTable />
            )}
          </Stack>
        }
      />
      <Drawer
        anchor={"right"}
        open={modifyUpdateItems}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        {list()}
      </Drawer>

      {/* Modal confirming the deletion of the menu item */}
      <Modal
        open={confirmDeleteModal}
        onClose={handleCloseConfirmDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="deleteModalWrapper">
          <Stack direction="row" className="modalHeaderWrapper">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete {modifiedHKMaterialPayload?.name}
            </Typography>
            <Typography id="modal-modal-description">
              <AiIcons.AiOutlineClose />
            </Typography>
          </Stack>
          <Divider />
          <Stack className="confirm">
            Are you sure you want to delete{" "}
            {modifiedHKMaterialPayload?.name}. Once done, this cannot be
            rolled back.
          </Stack>
          <Stack direction="row" className="btns">
            <Button className="cancelBtn">Cancel</Button>
            <Button
              className="successBtn"
              variant="custom-button"
              onClick={!loading && handleDeleteMenuItem}
              disabled={loading && true}
            >
              {!loading ? "Confirm" : <CircularProgress size="25px" />}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default HousekeepingRawMaterial;
