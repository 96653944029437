import { toast, ToastContainer } from "material-react-toastify";
import React, { useState, useEffect } from "react";
import IshaHeader from "./IshaHeader";
import { styled } from "@mui/styles";
import { ExpandMore } from "@mui/icons-material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  dateFnsFormat,
  getApprovedByOverseas,
  getCountriesAndStates,
  getIshaGenderList,
  getIshaProgramsAttended,
  getIshaPurposeOfVisit,
  getIshaRelationWithPrimary,
} from "../CalendarPage/constants/Calendar-constants";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api/index";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import axios from "axios";

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const StyledAccordionSummary = styled((props) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  margin: "none",
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderBottom: "none",
}));

function GetIshaGuestDetails() {
  const ishaProgramsAttended = getIshaProgramsAttended();
  const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
  const ishaApprovedByOverseas = getApprovedByOverseas();
  const ishaPurposeOfVisit = getIshaPurposeOfVisit();
  const ishaRelationWithPrimary = getIshaRelationWithPrimary();
  const ishaGenderList = getIshaGenderList();
  const url = new URL(window.location.href);
  const hash_id = url.searchParams.get("hash_id");
  const [laoder, setlaoder] = useState(false);
  const [detailsSaved, setdetailsSaved] = useState(false);
  const [isAgeGreater, setisAgeGreater] = useState(false);

  const countries = getCountriesAndStates();

  const [selectedPrograms, setSelectedPrograms] = useState(["-"]);
  const updateSelectedPrograms = (ind, val) => {
    let updatedArr = selectedPrograms.map((value, index) => {
      if (index === ind) {
        return val;
      } else {
        return value;
      }
    });
    setSelectedPrograms(updatedArr);
  };

  const [roomGuestDetails, setroomGuestDetails] = useState([
    {
      roomType: "Delux room",
      ratePlanName: "Standard Plan",
      roomTypeId: "12353",
      ratePlanId: "12567",
      reservationId: "SFRES_20166_12493",
      noOfAdults: 2,
      noOfChilds: 0,
      bookingId: "SFBOOKING_20166_12494",
      roomId: "11",
      guestLists: [
        {
          primary: true,
          firstName: "gp2",
          lastName: "gp2",
          email: "gp2@g.com",
          mobile: "917656565687",
          gender: "Male",
          dob: "1981-02-11",
          country: "NA",
          nationality: "India",
          pincode: "435342",
          address: "fasdghjkafdsghjg",
          city: "Latur",
          state: "NA",
          purposeOfVisit: "",
          programsAttended: "Isha Kriya",
          occupation: "DEV",
          companyName: "",
          isPresent: true,
          relationWithGuest: "",
          reservationId: "SFRES_20166_12493",
          bookingId: "SFBOOKING_20166_12494",
          roomId: "11",
        },
        {
          primary: false,
          firstName: "gpa2",
          lastName: "sdgfnf",
          email: "gpa2@g.com",
          mobile: "9878676767",
          gender: "Male",
          dob: "1977-02-11",
          country: "India",
          nationality: "NA",
          pincode: "435342",
          address: "fasdghjkafdsghjg",
          city: "Latur",
          state: "Maharashtra",
          purposeOfVisit: "",
          programsAttended: "-,Inner Engineering,Advanced Programs",
          occupation: "NA",
          companyName: "",
          isPresent: true,
          relationWithGuest: "Husband",
          reservationId: "SFRES_20166_12493",
          bookingId: "SFBOOKING_20166_12494",
          roomId: "11",
        },
      ],
    },
    {
      roomType: "Super Delux Room",
      ratePlanName: "Standard Plan",
      roomTypeId: "12354",
      ratePlanId: "12567",
      reservationId: "SFRES_20166_12494",
      noOfAdults: 2,
      noOfChilds: 2,
      bookingId: "SFBOOKING_20166_12494",
      roomId: "22",
      guestLists: [
        {
          primary: true,
          firstName: "gp1",
          lastName: "gp1",
          email: "gp1@g.com",
          mobile: "918989896776",
          gender: "Male",
          dob: "2013-02-14",
          country: "India",
          nationality: "india",
          pincode: "435342",
          address: "fasdghjkafdsghjg",
          city: "Latur",
          state: "Maharashtra",
          purposeOfVisit: "-",
          programsAttended: "-",
          occupation: "DEV",
          companyName: "",
          isPresent: true,
          relationWithGuest: "",
          reservationId: "SFRES_20166_12494",
          bookingId: "SFBOOKING_20166_12494",
          roomId: "22",
        },
        {
          primary: false,
          firstName: "gpa1",
          lastName: "last",
          email: "gpa1@g.com",
          mobile: "8787878787",
          gender: "Male",
          dob: "2001-02-09",
          country: "India",
          nationality: "NA",
          pincode: "435342",
          address: "fasdghjkafdsghjg",
          city: "Latur",
          state: "Maharashtra",
          purposeOfVisit: "",
          programsAttended: "HathaYoga,Isha Kriya",
          occupation: "NA",
          companyName: "",
          isPresent: true,
          relationWithGuest: "Father",
          reservationId: "SFRES_20166_12494",
          bookingId: "SFBOOKING_20166_12494",
          roomId: "22",
        },
        {
          primary: false,
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          gender: "",
          dob: "",
          country: "",
          nationality: "",
          pincode: "",
          address: "",
          city: "",
          state: "",
          purposeOfVisit: "",
          programsAttended: "",
          occupation: "",
          companyName: "",
          isPresent: false,
          relationWithGuest: "",
          reservationId: "SFRES_20166_12494",
          bookingId: "SFBOOKING_20166_12494",
          roomId: "22",
        },
        {
          primary: false,
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          gender: "",
          dob: "",
          country: "",
          nationality: "",
          pincode: "",
          address: "",
          city: "",
          state: "",
          purposeOfVisit: "",
          programsAttended: "",
          occupation: "",
          companyName: "",
          isPresent: false,
          relationWithGuest: "",
          reservationId: "SFRES_20166_12494",
          bookingId: "SFBOOKING_20166_12494",
          roomId: "22",
        },
      ],
    },
  ]);

  const getIshaDetails = async () => {
    await axios
      .post(
        `${COMMON_URL}core/api/v1/reservation/get-all-guest-info?bookingId=${hash_id
          .split(" ")
          .join("+")}`,
        {
          bookingId: hash_id?.split(" ").join("+"),
          emails: [],
        }
      )
      .then((res) => setroomGuestDetails(res.data))
      .catch((err) => toast.error("Something Went Wrong"));
  };
  useEffect(() => {
    getIshaDetails();
  }, []);

  const handleProgramChange = (event, index) => {
    const {
      target: { value },
    } = event;
    let attendedPrograms = typeof value === "string" ? value.split(",") : value;

    updateSelectedPrograms(index, attendedPrograms);
    // if (index === 0) {
    //   formik.setFieldValue("programsAttended", attendedPrograms.toString());
    // } else {
    //   performPrimaryGuestUpdate(
    //     index,
    //     "custProgramsAttended",
    //     attendedPrograms.toString()
    //   );
  };
  // setSelectedPrograms(attendedPrograms);

  useEffect(() => {
    if (roomGuestDetails) {
      const roomData = roomGuestDetails?.map((room) => {
        const guest = room?.guestLists.map((data) => {
          if (data.dob === "") {
            let temp = data;
            temp["dob"] = moment(new Date()).format("YYYY-MM-DD");
            if (data?.primary === true) {
              setisAgeGreater(true);
            }
            return temp;
          } else {
            return data;
          }
        });
        let updatedRoom = room;
        updatedRoom["guestLists"] = guest;
        return updatedRoom;
      });
    }
  }, [roomGuestDetails]);

  // useEffect(() => {
  //   if (roomGuestDetails) {
  //     const roomData = roomGuestDetails?.map((room) => {
  //       const guest = room?.guestLists.map((data) => {
  //         if (data.primary === true) {
  //           let temp = data;
  //           temp["relationWithGuest"] = "SELF";
  //           return temp;
  //         } else {
  //           return data;
  //         }
  //       });
  //       let updatedRoom = room;
  //       updatedRoom["guestLists"] = guest;
  //       return updatedRoom;
  //     });
  //   }
  // }, [roomGuestDetails]);

  // useEffect(() => {
  //   if (roomGuestDetails) {
  //     const roomData = roomGuestDetails?.map((room) => {
  //       const guest = room?.guestLists.map((data) => {
  //         let temp = data;
  //         temp["programsAttended"] = data?.programsAttended?.split(",");
  //         return temp;
  //       });
  //       let updatedRoom = room;
  //       updatedRoom["guestLists"] = guest;
  //       return updatedRoom;
  //     });
  //     setroomGuestDetails(roomData)
  //   }

  // }, [roomGuestDetails]);

  const updateGuest = (index, parameter, value, reservationId) => {
    const selectedRoom = roomGuestDetails?.filter(
      (room) => room?.reservationId === reservationId
    );
    const guestDetails = selectedRoom[0]?.guestLists;
    let selectedGuest = guestDetails[index];
    selectedGuest[parameter] = value;
    const updatedArr = guestDetails.map((guestData, ind) => {
      if (ind === index) {
        return selectedGuest;
      } else {
        return guestData;
      }
    });
    selectedRoom[0]["guestLists"] = updatedArr;
    const updatedRoomData = roomGuestDetails.map((roomDetail, index) => {
      if (roomDetail?.roomTypeId === reservationId) {
        return selectedRoom[0];
      } else {
        return roomDetail;
      }
    });
    setroomGuestDetails(updatedRoomData);
  };

  const saveGuestDetails = async () => {
    const roomData = roomGuestDetails?.map((room) => {
      const guest = room?.guestLists.map((data) => {
        if (data.email === "") {
          let temp = data;

          temp["email"] =
            (data.firstName + data.lastName)
              .replace(/\s+/g, ".")
              .toLowerCase() +
            Math.round(Date.now() / 1000) +
            "@example.com";

          return temp;
        } else {
          return data;
        }
      });
      let updatedRoom = room;
      updatedRoom["guestLists"] = guest;
      return updatedRoom;
    });

    console.log("roomGuestDetails", roomGuestDetails);
    setlaoder(true);
    await axios
      .post(
        `${COMMON_URL}core/api/v1/reservation/update-guest-info`,
        roomGuestDetails
      )
      .then((res) => {
        toast.success("Successfully collected guest information");
        setlaoder(false);
        getIshaDetails();
        setdetailsSaved(true);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setlaoder(false);
      });
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        wid
      />
      <IshaHeader />
      {detailsSaved === false ? (
        <Card variant="outlined">
          <CardHeader
            sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
            title="Room wise guest details"
          ></CardHeader>
          <Divider />
          <CardContent>
            <Grid item xs={12} container padding={2}>
              {roomGuestDetails?.length > 0 &&
                roomGuestDetails?.map((roomData, index) => {
                  return (
                    <Grid item key={index} xs={12}>
                      <StyledAccordion
                        defaultExpanded={index === 0 ? true : false}
                      >
                        <StyledAccordionSummary>
                          <Grid container width="100%">
                            <Grid item xs={6} justifyContent="flex-start">
                              {" Room " +
                                (index + 1) +
                                " | " +
                                roomData?.roomType +
                                " (" +
                                roomData?.ratePlanName +
                                ")"}
                            </Grid>
                            {/* {index !== 0 && (
                                  <Grid
                                    item
                                    display="flex"
                                    xs={6}
                                    justifyContent="flex-end"
                                  >
                                    <div style={{ marginTop: "-10px" }}>
                                      <Checkbox
                                        {...label}
                                        value={sameDataCheck[index]}
                                        //   onChange={(e) => toggleShowExpiresin(e)}
                                        onChange={(e) =>
                                          toggleSameAsPrimary(index)
                                        }
                                      />
                                    </div>
                                    <span style={{ marginTop: "-3px" }}>
                                      Same as group owner
                                    </span>
                                  </Grid>
                                )} */}
                          </Grid>
                        </StyledAccordionSummary>

                        {roomData?.guestLists?.map((guest, index) => (
                          <StyledAccordionDetails>
                            <Accordion
                              defaultExpanded={index === 0 ? true : false}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                {guest?.primary === true
                                  ? "Primary guest details"
                                  : "Accompanying Guest details"}
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container sx={{ width: "100%" }}>
                                  <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={6}>
                                      <Stack position="relative">
                                        <TextField
                                          required
                                          name="First Name"
                                          id="customer_name"
                                          value={guest?.firstName}
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "firstName",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                          autoComplete="new-password"
                                          fullWidth
                                          label="First Name"
                                        ></TextField>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Stack>
                                        <TextField
                                          required
                                          name="Last Name"
                                          id="lastName"
                                          value={guest?.lastName}
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "lastName",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                          autoComplete="new-password"
                                          fullWidth
                                          label="Last Name"
                                        ></TextField>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Stack position="relative">
                                        <TextField
                                          name="customer_email"
                                          id="customer_email"
                                          fullWidth
                                          autoComplete="new-password"
                                          type="text"
                                          placeholder="Email Address"
                                          value={guest?.email}
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "email",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                          label="Enter Customer Email"
                                        ></TextField>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Stack position="relative">
                                        <TextField
                                          name="customer_phone"
                                          id="customer_phone"
                                          fullWidth
                                          autoComplete="new-password"
                                          placeholder="Phone number"
                                          label="Phone number"
                                          value={guest?.mobile}
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "mobile",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                        ></TextField>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Stack position="relative">
                                        <TextField
                                          select
                                          className="position-relative"
                                          name="customer_gender"
                                          id="customer_gender"
                                          fullWidth
                                          placeholder="Gender"
                                          value={guest?.gender}
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "gender",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                          label="Gender"
                                          variant="outlined"
                                        >
                                          {ishaGenderList.map((genVal) => {
                                            return (
                                              <MenuItem
                                                key={genVal}
                                                value={genVal}
                                              >
                                                {genVal}
                                              </MenuItem>
                                            );
                                          })}
                                        </TextField>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DatePicker
                                          inputFormat={dateFnsFormat}
                                          fullWidth
                                          disableMaskedInput
                                          disableCloseOnSelect={false}
                                          disableFuture
                                          mask="___ ___, ____"
                                          id="customer_dob"
                                          name="customer_dob"
                                          value={guest?.dob}
                                          label="Date of Birth"
                                          onChange={(e) => {
                                            if (guest?.primary === true) {
                                              const date =
                                                moment(e).format("YYYY-MM-DD");
                                              const todaysDate = moment(
                                                new Date()
                                              ).format("YYYY-MM-DD");
                                              let months = Math.abs(
                                                moment(date).diff(
                                                  todaysDate,
                                                  "days"
                                                )
                                              );
                                              if (months < 6750) {
                                                toast.error(
                                                  "Primary guest age should be greater than 18"
                                                );
                                                setisAgeGreater(true);
                                              } else {
                                                updateGuest(
                                                  index,
                                                  "dob",
                                                  moment(e).format(
                                                    "YYYY-MM-DD"
                                                  ),
                                                  roomData?.reservationId
                                                );
                                                setisAgeGreater(false);
                                              }
                                            } else {
                                              updateGuest(
                                                index,
                                                "dob",
                                                moment(e).format("YYYY-MM-DD"),
                                                roomData?.reservationId
                                              );
                                            }
                                          }}
                                          InputProps={{
                                            onKeyDown: (e) =>
                                              e.preventDefault(),
                                          }}
                                          // onAccept={(value) => {
                                          //   const newYear = new Date(
                                          //     formik.values.customer_dob
                                          //   ).getFullYear();
                                          //   const year = new Date(
                                          //     value
                                          //   ).getFullYear();
                                          //   const sameYear = year == newYear;
                                          //   setDateOfBirthSelection(
                                          //     sameYear
                                          //       ? value
                                          //       : formik.values
                                          //           .customer_dob || value
                                          //   );
                                          // }}
                                          autoOk={true}
                                          renderInput={(params) => {
                                            return (
                                              <TextField
                                                fullWidth
                                                {...params}
                                              />
                                            );
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                    {guest?.primary === true && (
                                      <Grid item xs={6}>
                                        <TextField
                                          fullWidth
                                          id="address"
                                          name="address"
                                          value={guest?.address}
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "address",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                          label="Address"
                                        ></TextField>
                                      </Grid>
                                    )}

                                    {guest?.primary === true && (
                                      <Grid item xs={6}>
                                        <TextField
                                          id="zipcode"
                                          name="zipcode"
                                          value={guest?.pincode}
                                          fullWidth
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "pincode",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                          label="Zipcode"
                                        ></TextField>
                                      </Grid>
                                    )}
                                    <Grid item xs={6}>
                                      <FormControl style={{ width: "100%" }}>
                                        <InputLabel id="demo-simple-select-label">
                                          Country
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          name="country"
                                          id="Country"
                                          label="Country"
                                          style={{ width: "100%" }}
                                          value={
                                            guest?.country === null ||
                                            guest?.country === undefined ||
                                            guest?.country.toLowerCase() ===
                                              "india" ||
                                            guest?.country === "NA" ||
                                            guest?.country === ""
                                              ? "india"
                                              : "other"
                                          }
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "country",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                          required="true"
                                          // MenuProps={MenuProps}
                                          placeholder="Country"
                                        >
                                          <MenuItem
                                            key={"india"}
                                            value={"india"}
                                          >
                                            {"India"}
                                          </MenuItem>
                                          <MenuItem
                                            key={"other"}
                                            value={"other"}
                                          >
                                            {"Other"}
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                      {/* <TextField
                                        id="country"
                                        name="Country"
                                        value={guest?.country}
                                        fullWidth
                                        onChange={(e) =>
                                          updateGuest(
                                            index,
                                            "country",
                                            e.target.value,
                                            roomData?.roomTypeId
                                          )
                                        }
                                        label="Country"
                                      ></TextField> */}
                                    </Grid>
                                    {guest?.primary === true && (
                                      <Grid item xs={6}>
                                        <TextField
                                          id="state"
                                          name="State"
                                          value={guest?.state}
                                          fullWidth
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "state",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                          label="State"
                                        ></TextField>
                                      </Grid>
                                    )}
                                    {guest?.primary === true && (
                                      <Grid item xs={6}>
                                        <TextField
                                          fullWidth
                                          id="city"
                                          name="city"
                                          value={guest?.city}
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "city",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                          label="City"
                                        ></TextField>
                                      </Grid>
                                    )}
                                    <Grid item xs={6}>
                                      <FormControl style={{ width: "100%" }}>
                                        <InputLabel id="demo-simple-select-label">
                                          Nationality
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          name="nationality"
                                          id="Nationality"
                                          label="Nationality"
                                          style={{ width: "100%" }}
                                          value={
                                            guest?.country === null ||
                                            guest?.country === undefined ||
                                            guest?.nationality.toLowerCase() ===
                                              "india" ||
                                            guest?.nationality === "NA" ||
                                            guest?.nationality === ""
                                              ? "india"
                                              : "other"
                                          }
                                          onChange={(e) =>
                                            updateGuest(
                                              index,
                                              "nationality",
                                              e.target.value,
                                              roomData?.reservationId
                                            )
                                          }
                                          required="true"
                                          // MenuProps={MenuProps}
                                          placeholder="Nationality"
                                        >
                                          <MenuItem
                                            key={"india"}
                                            value={"india"}
                                          >
                                            {"India"}
                                          </MenuItem>
                                          <MenuItem
                                            key={"other"}
                                            value={"other"}
                                          >
                                            {"Other"}
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        select
                                        id="guestPrimaryRelation"
                                        label="Relationship With Primary"
                                        name="guestPrimaryRelation"
                                        value={guest?.relationWithGuest}
                                        // disabled={guest?.primary === true}
                                        onChange={(e) =>
                                          updateGuest(
                                            index,
                                            "relationWithGuest",
                                            e.target.value,
                                            roomData?.reservationId
                                          )
                                        }
                                        fullWidth
                                      >
                                        {ishaRelationWithPrimary?.length > 0 &&
                                          ishaRelationWithPrimary.map(
                                            (relation, index) => {
                                              return (
                                                <MenuItem
                                                  key={relation}
                                                  value={relation}
                                                >
                                                  {relation}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                      </TextField>
                                    </Grid>

                                    <>
                                      {guest?.primary === true && (
                                        <Grid item xs={6}>
                                          <TextField
                                            select
                                            name="purposeOfVisit"
                                            id="purposeOfVisit"
                                            fullWidth
                                            placeholder="Purpose of visit"
                                            value={guest?.purposeOfVisit}
                                            style={{ width: "100%" }}
                                            onChange={(e) =>
                                              updateGuest(
                                                index,
                                                "purposeOfVisit",
                                                e.target.value,
                                                roomData?.reservationId
                                              )
                                            }
                                            label="Purpose of visit"
                                            variant="outlined"
                                          >
                                            {ishaPurposeOfVisit?.length > 0 &&
                                              ishaPurposeOfVisit.map(
                                                (purpose, index) => {
                                                  return (
                                                    <MenuItem
                                                      key={purpose}
                                                      value={purpose}
                                                    >
                                                      {purpose}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                          </TextField>
                                        </Grid>
                                      )}
                                      <Grid item xs={6}>
                                        <FormControl style={{ width: "100%" }}>
                                          <InputLabel id="demo-simple-select-label">
                                            Programs Attended
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            name="programsAttended"
                                            id="programsAttended"
                                            label="Programs attended"
                                            value={
                                              guest.programsAttended === null
                                                ? []
                                                : guest.programsAttended.split(
                                                    ","
                                                  )
                                            }
                                            onChange={(e) => {
                                              console.log(e.target.value);
                                              updateGuest(
                                                index,
                                                "programsAttended",
                                                e.target.value.toString(","),
                                                roomData?.reservationId
                                              );
                                            }}
                                            required="true"
                                            multiple
                                            style={{ width: "100%" }}
                                            // MenuProps={MenuProps}
                                            placeholder="Choose Programs Attended"
                                          >
                                            {ishaProgramsAttended?.length > 0 &&
                                              ishaProgramsAttended.map(
                                                (program, index) => {
                                                  return (
                                                    <MenuItem
                                                      key={program}
                                                      value={program}
                                                    >
                                                      {program}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      {guest?.primary === true && (
                                        <Grid item xs={6}>
                                          <TextField
                                            name="occupation"
                                            id="occupation"
                                            type="text"
                                            fullWidth
                                            placeholder="occupation"
                                            value={guest?.occupation}
                                            onChange={(e) =>
                                              updateGuest(
                                                index,
                                                "occupation",
                                                e.target.value,
                                                roomData?.reservationId
                                              )
                                            }
                                            label="Occupation"
                                            variant="outlined"
                                          />
                                        </Grid>
                                      )}
                                      {guest?.primary === true && (
                                        <Grid item xs={6}>
                                          <TextField
                                            name="companyName"
                                            id="companyName"
                                            fullWidth
                                            type="text"
                                            placeholder="Company Name"
                                            value={guest?.companyName}
                                            onChange={(e) =>
                                              updateGuest(
                                                index,
                                                "companyName",
                                                e.target.value,
                                                roomData?.reservationId
                                              )
                                            }
                                            label="Company Name"
                                            variant="outlined"
                                          />
                                        </Grid>
                                      )}
                                    </>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </StyledAccordionDetails>
                        ))}
                      </StyledAccordion>
                    </Grid>
                  );
                })}
            </Grid>
            <Button
              variant="custom-button"
              onClick={() => saveGuestDetails()}
              disabled={laoder ? laoder : isAgeGreater}
              style={{ float: "right", marginRight: "2rem" }}
            >
              {laoder ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                "Save details"
              )}
            </Button>
          </CardContent>
        </Card>
      ) : (
        <>
          {" "}
          <p
            style={{
              fontWeight: "300",
              fontSize: "2rem",
            }}
          >
            Thanks for uploading the details.
          </p>{" "}
          <p
            style={{
              fontWeight: "200",
              fontSize: "2rem",
              fontSize: "17px",
            }}
          >
            In love light and laughter, Isha Volunteers
          </p>
        </>
      )}
    </div>
  );
}

export default GetIshaGuestDetails;
