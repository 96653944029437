import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { checkForUser } from "../_redux/authCrud";

const LoginEmail = (props) => {
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter correct mail Id")
      .required("Email is required"),
  });
  // const theme = useTheme()
  // const matches = useMediaQuery('(min-width:600px)');
  const masterAdminCheck =
    window.location.pathname.split("/")[1] === "masterAdmin" ? true : false;
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const { loginTrue, setMail, mailId } = props;
  const formik = useFormik({
    initialValues: { email: mailId },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      validUser(values);
    },
  });

  const validUser = async (values) => {
    setLoading(true);
    setInvalid(false);
    try {
      const resp = await checkForUser(values.email);
      if (resp.data === "User Present") {
        setMail(values.email);
        loginTrue();
      } else {
        setInvalid(true);
      }
    } catch (e) {
      setInvalid(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container display="grid" spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" paddingBottom={1}>
            {masterAdminCheck ? "Master login" : "Hello"}
          </Typography>
          <Typography variant="body1">Sign in to your account</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoFocus
            value={formik.values.email}
            onChange={(e) => {
              formik.setFieldValue("email", e.target.value);
              if (invalid) setInvalid(false);
            }}
            onBlur={formik.handleBlur}
            name="email"
            id="email"
            error={
              (formik.touched.email && Boolean(formik.errors.email)) || invalid
            }
            helperText={
              (formik.touched.email && formik.errors.email) ||
              (invalid && "No user present")
            }
            label={masterAdminCheck ? "Hotel Email" : "Email"}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={loading}
            type="submit"
            sx={{ height: "150%" }}
            fullWidth
            variant="custom-button"
            size="large"
          >
            Sign In
          </LoadingButton>
        </Grid>

        {/* <Grid item container justifyContent="center" xs={12}>
          <Link to="/"> Need help signing in? </Link>
        </Grid> */}
      </Grid>
    </form>
  );
};

export default LoginEmail;
