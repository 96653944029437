import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Tooltip,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Edit } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { connect } from "react-redux";
import ReportScheduleModal from "./ReportScheduleModal";
import {
  activiateReport,
  deactiviateReport,
  deleteReports,
  getAllReportSchedules,
} from "./ReportScheduleApi";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { PowerSettingsNew } from "@mui/icons-material";
import { toast } from "material-react-toastify";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ReportWrapper from "../../Reports/ReportWrapper";

const ReportSchedule = ({ data }) => {
  const [raised, setRaised] = useState({});
  const [reportSchedule, setreportSchedule] = useState(false);
  const [allreportsdata, setallreportsdata] = useState();
  const [editData, setEditData] = useState();

  const toggleRaised = (num) => {
    raised[num] = !raised[num];
    setRaised({ ...raised });
  };

  const openAddReportScheduleModal = (num) => {
    setEditData(num);
    setreportSchedule(true);
  };

  const hideAddReportScheduleModal = () => {
    setreportSchedule(false);
  };

  useEffect(async () => {
    await getAllData();
  }, []);

  const getAllData = async () => {
    try {
      const data = await getAllReportSchedules();
      setallreportsdata(JSON.parse(data.message));
    } catch (e) {
      console.error(e);
    }
  };

  const DeActivate = async (id) => {
    const data = await deactiviateReport(id);
    if ((data.code = "200")) {
      toast.success(data.message);
    } else {
      toast.error("Something went wrong");
    }

    getAllData();
  };

  const Activate = async (id) => {
    const data = await activiateReport(id);
    if ((data.code = "200")) {
      toast.success(data.message);
    } else {
      toast.error("Something went wrong");
    }
    getAllData();
  };

  const deleteReport = async (id) => {
    const data = await deleteReports(id);
    if ((data.code = "200")) {
      toast.success(data.message);
    } else {
      toast.error("Something went wrong");
    }
    getAllData();
    handleClose();
  };

  const breadcrumbs = [
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Link
        key="1"
        to={{
          pathname: "./reports",
          search: `?hotelId=${localStorage.getItem("hotelId")}`,
        }}
        style={{ fontSize: "17.5px", textDecoration: "underline" }}
      >
        Reports
      </Link>
      <div
        style={{ fontSize: "17.5px", marginLeft: "10px", marginRight: "10px" }}
      >
        {" > "}
      </div>
      <Typography style={{ fontSize: "17.5px" }} color="text.primary">
        Reports schedules
      </Typography>
    </div>,
  ];
  const [show, setShow] = useState(false);
  const [selectedId, setselectedId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setselectedId(id);
    setShow(true);
  };
  return (
    <ReportWrapper
      backBtn={true}
      showReportTypes={false}
      reportName={"Schedule report"}
      contentArea={
        <div>
          <Grid container>
            <div className="d-flex flex-column">
              {/* <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs> */}
              {/* <h2 className="mt-4">Report schedules</h2> */}
              <p style={{ marginTop: "15px" }}>
                Use this page to create and edit audit report generation
                schedules. You can generate daily or weekly audit reports at the
                specific time, and have it mailed to the email address of your
                choice
              </p>
            </div>
            <Grid item container sx={{ marginBottom: 3 }} xs={12}>
              <Grid item xs={6} container justifyContent="flex-start">
                <Button
                  onClick={() => openAddReportScheduleModal()}
                  variant="custom-button"
                >
                  {" "}
                  Add new report schedule
                </Button>
              </Grid>
            </Grid>

            <ReportScheduleModal
              show={reportSchedule}
              hide={hideAddReportScheduleModal}
              getAllReports={getAllData}
              selectedReport={editData}
            ></ReportScheduleModal>

            <Modal show={show} onHide={handleClose} style={{ zIndex: "3000" }}>
              <Modal.Header closeButton>
                <Modal.Title>Reports schedules</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h3>Do you want to delete the report schedule?</h3>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="custom-button"
                  onClick={() => deleteReport(selectedId)}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
            {allreportsdata?.map((num, index) => {
              return (
                <Grid key={num.id} item xs={12}>
                  <Card
                    onMouseOver={() => {
                      toggleRaised(num.id);
                    }}
                    onMouseOut={() => {
                      toggleRaised(num.id);
                    }}
                    raised={raised[num.id]}
                    sx={{ marginBottom: 2 }}
                  >
                    <CardHeader
                      title={`  ${num.name}`}
                      action={
                        <Grid item spacing={2} container>
                          <Grid item>
                            <Tooltip title="Edit report schedule">
                              <IconButton color="primary">
                                <Edit
                                  onClick={() =>
                                    openAddReportScheduleModal(num)
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Disable report schedule">
                              <IconButton color="primary">
                                {num.status === "ACTIVE" && (
                                  <PowerSettingsNew
                                    onClick={() => DeActivate(num.id)}
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Enable report schedule">
                              <IconButton color="primary">
                                {num.status === "INACTIVE" && (
                                  <PlayCircleIcon
                                    onClick={() => Activate(num.id)}
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          {num.status === "INACTIVE" && (
                            <Grid item>
                              <Tooltip title="Delete report schedule">
                                <IconButton color="primary">
                                  <CancelIcon
                                    onClick={() => handleShow(num.id)}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      }
                    >
                      <Divider />
                    </CardHeader>
                    <Divider />
                    <CardContent>
                      <Grid item container spacing={3}>
                        <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                          <ListItemText
                            primary="Name"
                            secondary={num.name}
                          ></ListItemText>
                          <ListItemText
                            primary="Report Type"
                            secondary={num.reportType}
                          ></ListItemText>
                        </Grid>
                        <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                          <ListItemText
                            primary="Frequency"
                            secondary={num.frequency}
                          ></ListItemText>
                          <ListItemText
                            primary="Interval"
                            secondary={num.interval}
                          ></ListItemText>
                        </Grid>
                        <Grid item xs={4} sm={3} md={4} lg={4} xl={4}>
                          <ListItemText
                            primary="Delivery Time"
                            secondary={num.deliveryTime}
                          ></ListItemText>
                          <ListItemText
                            primary="Emails"
                            secondary={num.emails}
                          ></ListItemText>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </div>
      }
    />
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(ReportSchedule);
