import moment from "moment";

export function formatDateXAxis(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateParts = inputDate.split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // JavaScript months are zero-indexed
  const day = parseInt(dateParts[2]);

  const daySuffix = getDaySuffix(day);

  const formattedDate = `${day}${daySuffix} ${months[month]}`;

  return formattedDate;
}

export function formatDate(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateParts = inputDate.split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // JavaScript months are zero-indexed
  const day = parseInt(dateParts[2]);

  const daySuffix = getDaySuffix(day);

  const formattedDate = `${day}${daySuffix}, ${months[month]} ${year}`;

  return formattedDate;
}

export function formatDateXAxisPayment(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateParts = inputDate.split("-");
  const year = parseInt(dateParts[2]);
  const month = parseInt(dateParts[1]) - 1; // JavaScript months are zero-indexed
  const day = parseInt(dateParts[0]);

  const daySuffix = getDaySuffix(day);

  const formattedDate = `${day}${daySuffix} ${months[month]}`;

  return formattedDate;
}

export function formatDatePayment(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateParts = inputDate.split("-");
  const year = parseInt(dateParts[2]);
  const month = parseInt(dateParts[1]) - 1; // JavaScript months are zero-indexed
  const day = parseInt(dateParts[0]);

  const daySuffix = getDaySuffix(day);

  const formattedDate = `${day}${daySuffix}, ${months[month]} ${year}`;

  return formattedDate;
}
export function formatToday() {
  const currentDate = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = currentDate.getDate();
  const monthIndex = currentDate.getMonth();
  const year = currentDate.getFullYear();
  const daySuffix = getDaySuffix(day);
  const formattedDate = `${day}${daySuffix}, ${months[monthIndex]} ${year}`;
  return formattedDate;
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export const toSentenceCase = (sentence) => {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
};

export const AmountFormatted = (value) => {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(0) + "M";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(0) + "K";
  } else {
    return value?.toFixed(0).toString();
  }
};

export function convertDateFormat(inputDate) {
  var dateParts = inputDate.split("-");
  var day = dateParts[0];
  var month = dateParts[1];
  var year = dateParts[2];
  var outputDate = year + "-" + month + "-" + day;

  return outputDate;
}
