import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
} from "recharts";
import { AmountFormatted } from "../../GroupDashboard/Stats/util";

function PaymentBar({ sourceData, fullScreen, currency }) {
  //-----------------
  const removePayment = (sentence) => {
    const newSentence = sentence.split(" ");
    if (newSentence.length > 1) {
      const removedPay = newSentence?.filter((elm) => elm !== "payment");
      return removedPay.join(" ");
    }
    return sentence;
  };
  //----------------------------------------
  const [maxHeight, setMaxHeight] = useState(1);
  useEffect(() => {
    var height = 1;
    sourceData.map((item) => {
      if (item?.value > height) {
        height = item?.value;
      }
    });
    setMaxHeight(Math.ceil(height));
  }, [sourceData]);

  const [graphWidth, setGraphWidth] = useState();
  useEffect(() => {
    var screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (fullScreen) {
      setGraphWidth(Math.round((screenWidth * 45) / 100));
    } else {
      setGraphWidth(Math.round((screenWidth * 35) / 100));
    }
  }, [fullScreen]);
  return (
    <BarChart
      width={graphWidth}
      height={300}
      data={sourceData}
      margin={{
        top: 20,
      }}
    >
      {sourceData?.length < 1 && <CartesianGrid strokeDasharray="3 3" />}
      {sourceData?.length < 1 && (
        <g transform={`translate(${graphWidth / 2},${300 / 2})`}>
          <Text textAnchor="middle" verticalAnchor="middle">
            No data available
          </Text>
        </g>
      )}

      <XAxis
        dataKey="name"
        padding={{ left: 30, right: 30 }}
        // tick={{ fontSize: 12, fill: "black", angle: -15, textAnchor: "end" }}
        tickFormatter={removePayment}
      />

      <YAxis
        domain={[0, maxHeight]}
        label={{
          value: `Payment (${localStorage.getItem("hotelCurrency")})`,
          angle: -90,
          position: "insideLeft",
          className: "vertical-center-label",
        }}
        tickFormatter={AmountFormatted}
      />
      <Tooltip
        content={<CustomTooltip currency={currency} />}
        cursor={{ fill: "transparent" }}
      />
      <Bar dataKey="Payment" fill={"#6B7AA1"} />
    </BarChart>
  );
}

export default PaymentBar;

const CustomTooltip = ({ active, payload, label, currency }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{label}</p>

        <div>
          {payload.map((pld) => (
            <div style={{ display: "inline-block", padding: 5 }}>
              <div style={{ color: "black" }}>
                {pld?.dataKey} : {currency} {pld?.value}
              </div>
              {/* <div style={{ color: "gray" }}> drivers</div> */}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

// const formattingXAxis = (item) => {
//   if (item?.length > 6) {
//     return `${item?.slice(0, 6)}...`;
//   }
//   return item;
// };
