import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const Photo = ({ photo, remove, removeNewFile, p }) => {
  const [deleteItem, setDeleteItem] = useState(false);

  const handleRemove = () => {
    if (photo.imageFile === undefined) {
      setDeleteItem(true);
      remove(photo.photoId);
      setTimeout(() => setDeleteItem(false), 2000);
    } else {
      removeNewFile(p.index);
    }
  };

  return (
    <Stack
      position="relative"
      style={{
        width: "120px",
        opacity: deleteItem ? "0.5" : "1",
        padding: "2",
      }}
    >
      <img
        src={photo.imageUrl}
        style={{
          height: "125px",
          borderRadius: "10px",
          border: "1px solid",
          borderColor: "gray",
          marginRight: "8px",
          marginTop: "8px",
        }}
        alt=""
      />
      {photo.photoId !== "default" && (
        <IconButton
          children={
            <ClearIcon
              fontSize="xs"
              style={{
                pointerEvents: "none",
                // marginTop: "9px",
                // marginLeft: "2px",
              }}
            />
          }
          onClickCapture={handleRemove}
          isDisabled={deleteItem}
          style={{
            position: "absolute",
            backgroundColor: "white",
            // marginTop: "9px",
            // marginLeft: "2px",
            // padding: "0.75rem",
            // borderRadius: "full",
            marginTop: "0px !important",
          }}
        />
      )}
    </Stack>
  );
};

export default Photo;
