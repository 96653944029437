import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import "./CTAModal.css";
import {
  Button,
  CircularProgress,
  Grid,
  OutlinedInput,
  CardContent,
  Card,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { connect } from "react-redux";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import {
  getAllCTAImages,
  getAllCTAS,
  saveCtaDetails,
  rejectCTA,
  updateCTADetails,
  updateCTAImages,
} from "./apis/BookingDashboardApi";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import { RiFileEditFill } from "react-icons/ri";
import GroupSidebar from "../GroupSidebar/GroupSidebar";
import { setLocale } from "yup";
import { SettingsOverscan } from "@mui/icons-material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const CTAModal = (props) => {
  //States
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [addr1, setaddr1] = useState("");
  const [addr2, setaddr2] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  const [accountNumber, setaccountNumber] = useState("");
  const [idNumber, setidNumber] = useState("");
  const [loader, setloader] = useState(false);
  const [govtIdType, setgovtIdType] = useState("");
  const [govtIdvalue, setgovtIdvalue] = useState("");
  const [propName, setPropName] = useState("");
  const [allCtas, setallCtas] = useState(null);
  const [ctaFilterData, setCtaFilterData] = useState(null);
  const [searchTerm, setsearchTerm] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [raised, setRaised] = useState({});
  const [openCTAInfoModal, setOpenCTAInfoModal] = useState(false);
  const [otaAttachments, setOtaAttachments] = useState(null);
  const [allHotelIds, setAllHotelIds] = useState(props?.allActiveHotels);
  const [agentStatus, setAgentStatus] = useState("true");
  const [selectedHotelIds, setSelectedHotelIds] = useState([]);
  const groupEmail = props?.data?.email;
  //Get all ctas
  useEffect(async () => {
    if (groupEmail !== undefined) {
      await getAllData(groupEmail);
    }
  }, [groupEmail]);

  useEffect(() => {
    setAllHotelIds(props?.allActiveHotels);
  }, [props?.allActiveHotels]);

  const [allRowData, setAllRowData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const columns = [
    { field: "id", headerName: "Id" },
    { field: "creation_date", headerName: "Creation Date" },
    { field: "name", headerName: "Agent Name" },
    { field: "prop_name", headerName: "Proprietor Name" },
    { field: "email", headerName: "Email Address" },
    { field: "contact", headerName: "Contact Number" },
    { field: "account_no", headerName: "CTA Account Number" },
    { field: "cta_tax_id", headerName: "CTA Tax Id Number" },
    { field: "govt_id_type", headerName: "Govt Id Type" },
    { field: "govt_id_value", headerName: "Govt Id Value" },
    { field: "status", headerName: "Travel agent status" },
  ];

  const getAllData = async (email) => {
    try {
      setAllctaLoader(true);
      const data = await getAllCTAS(email);
      setallCtas(data.ctaList);
      let row_data = [];
      let index = 1;
      if (data?.ctaList?.length > 0) {
        let new_data = data?.ctaList.map((ctaData) => {
          let name =
            ctaData?.userInfo?.name?.split(" | ").length > 1
              ? ctaData?.userInfo?.name?.split(" | ")[0]
              : ctaData?.userInfo?.name;
          let propName =
            ctaData?.userInfo?.name?.split(" | ").length > 1
              ? ctaData?.userInfo?.name?.split(" | ")[1]
              : "";
          let temp_data = {
            id: index,
            creation_date: ctaData?.creationDate?.split(" ")[0],
            name: name,
            prop_name: propName,
            email: ctaData?.userInfo?.email,
            contact: ctaData?.userInfo?.contact,
            account_no: ctaData?.ctaLookupInfo
              ? ctaData?.ctaLookupInfo?.cta_account_no
              : "",
            cta_tax_id: ctaData?.ctaLookupInfo
              ? ctaData?.ctaLookupInfo?.cta_tax_id_no
              : "",
            govt_id_type: ctaData?.userInfo?.govt_id_type,
            govt_id_value: ctaData?.userInfo?.govt_id_value,
            status: ctaData?.valid ? "APPROVED" : "APPROVAL PENDING",
          };
          row_data.push(temp_data);
          index = index + 1;
        });
        setAllRowData(row_data);
      }
      if (agentStatus === "true") {
        let rowDataUpdated = row_data?.filter(
          (val) => val?.status === "APPROVED"
        );
        let dataUpdated = data?.ctaList?.filter((val) => val?.valid === true);
        setCtaFilterData(dataUpdated);
        setRowData(rowDataUpdated);
      } else {
        setCtaFilterData(data.ctaList);
        setRowData(row_data);
      }
      setAllctaLoader(false);
    } catch (e) {
      console.error(e);
    }
  };

  // quick search
  const onFilterTextChange = (event) => {
    setsearchTerm(event.target.value);
  };

  //Toggle on mouse over
  const toggleRaised = (num) => {
    raised[num] = !raised[num];
    setRaised({ ...raised });
  };

  //populate cta details on click
  const handleOpenCTAInfoModal = async (details) => {
    setEditDetailsFlag(false);
    const ctaDetail = details?.userInfo;
    const data = await getAllCTAImages(ctaDetail?.email);
    setOtaAttachments(data);
    let user_name = ctaDetail?.name;
    if (user_name.split(" | ").length > 1) {
      setname(user_name.split(" | ")[1]);
      setPropName(user_name.split(" | ")[0]);
    } else {
      setname(user_name);
      setPropName("");
    }
    //setname(ctaDetail?.name);
    setemail(ctaDetail?.email);
    setcontact(ctaDetail?.contact);
    setaddr1(ctaDetail?.address1);
    setaddr2(ctaDetail?.address2);
    setcity(ctaDetail.city);
    setcountry(ctaDetail.country);
    setstate(ctaDetail.state);
    setpincode(ctaDetail.zipcode);
    if (details?.valid === true) {
      setaccountNumber(
        details?.ctaLookupInfo ? details.ctaLookupInfo.cta_account_no : ""
      );
      setidNumber(
        details?.ctaLookupInfo ? details.ctaLookupInfo.cta_tax_id_no : ""
      );
    }
    setgovtIdType(ctaDetail?.govt_id_type);
    setgovtIdvalue(ctaDetail?.govt_id_value);
    setidNumber(
      details?.ctaLookupInfo ? details.ctaLookupInfo.cta_tax_id_no : ""
    );
    if (
      details?.creationDate !== undefined &&
      details?.creationDate.split(" ").length > 1
    ) {
      setCreationDate(details?.creationDate.split(" ")[0]);
    } else {
      setCreationDate(
        details?.creationDate !== undefined ? details?.creationDate : ""
      );
    }
    if (details?.hotels?.length > 0) {
      setSelectedHotelIds(details?.hotels);
    } else {
      setSelectedHotelIds([]);
    }
    setOpenCTAInfoModal(true);
  };

  //close cta modal
  const handleCloseCTAInfoModal = () => {
    setOpenCTAInfoModal(false);
  };

  //Save cta details
  const saveCtaDetail = async () => {
    let hotelArr = [];
    if (selectAllHotels) {
      hotelArr = allHotelIds.map((hotel) => {
        return hotel?.hotelId;
      });
    } else {
      hotelArr = selectedHotelIds;
    }
    if (hotelArr?.length === 0) {
      toast.error("Please select atleast one hotel to verify the CTA Agent.");
      return;
    }
    setloader(true);
    const payload = {
      cta_email: email,
      cta_account_no: accountNumber,
      cta_tax_id_no: idNumber,
      hotel_email: props.data.email,
      hotels: hotelArr,
    };
    const data = await saveCtaDetails(payload).catch((error) =>
      toast.error(error)
    );
    setloader(false);

    if (data === "OK") {
      getAllData(groupEmail).then(() => handleCloseCTAInfoModal());
    } else {
      toast.error("Something went wrong");
    }
  };

  //Add Travel Agent Modal
  const [show, setShow] = useState(false);
  const [ctaURL, setCtaURL] = useState("");
  const [title, setTitle] = useState("");

  const handleSelectionChange = (e) => {
    setSelectedHotelIds(e.target.value);
  };

  const [selectAllHotels, setSelectAllHotels] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (tit, url) => {
    setTitle(tit);
    setCtaURL(url);
    setShow(true);
  };

  //Attachments addition
  //Creating states for document upload
  const [profile, setProfile] = useState("");
  const [pan, setPan] = useState("");
  const [shopImg, setShopImg] = useState("");
  const [agreement, setAgreement] = useState("");
  const [shopReg, setShopReg] = useState("");
  const [afl, setAfl] = useState("");
  const [ndc, setNdc] = useState("");
  const [dd, setDd] = useState("");
  const [aadharFront, setAadharFront] = useState("");
  const [aadharBack, setAadharBack] = useState("");
  const [addressProof, setAddressProof] = useState("");

  // useEffect(() => {
  //   console.log("profile data: ", profile);
  // }, [profile]);
  // Create a reference to the hidden file input element
  const profileFileInput = React.useRef(null);
  const panFileInput = React.useRef(null);
  const shopImgFileInput = React.useRef(null);
  const agreementFileInput = React.useRef(null);
  const shopRegFileInput = React.useRef(null);
  const aflFileInput = React.useRef(null);
  const ndcFileInput = React.useRef(null);
  const ddFileInput = React.useRef(null);
  const aadharFrontFileInput = React.useRef(null);
  const aadharBackFileInput = React.useRef(null);
  const addressProofFileInput = React.useRef(null);

  //Setting up the sates of OTA documents
  const handleSetDocumentState = (name, docType, base64) => {
    switch (name) {
      case "profile":
        setProfile({ data: base64, type: "image/png" });
        break;
      case "pan":
        setPan({ data: base64, type: "image/png" });
        break;
      case "shopImg":
        setShopImg({ data: base64, type: "image/png" });
        break;
      case "agreement":
        setAgreement({ data: base64, type: "application/pdf" });
        break;
      case "shopReg":
        setShopReg({ data: base64, type: "application/pdf" });
        break;
      case "afl":
        setAfl({ data: base64, type: "application/pdf" });
        break;
      case "ndc":
        setNdc({ data: base64, type: "application/pdf" });
        break;
      case "dd":
        setDd({ data: base64, type: "application/pdf" });
        break;
      case "aadharFront":
        setAadharFront({ frontData: base64, frontType: "image/png" });
        break;
      case "aadharBack":
        setAadharBack({ backData: base64, backType: "image/png" });
        break;
      case "addressProof":
        setAddressProof({ data: base64, type: "application/pdf" });
        break;
      default:
      // code block
    }
  };

  //Converting documents to base64
  const convertToBase64 = (eve) => {
    // console.log(eve.target.files[0]);
    // console.log("name of doc : ", eve.target.name);
    const file = eve.target.files[0];
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
      .then((data) => {
        const type = data.split("/")[1].split(";")[0];
        const base64 = data.split(",")[1];
        handleSetDocumentState(eve.target.name, type, base64);
      })
      .catch((err) => console.log("error caught : ", err));
  };

  //filter addition for CTA
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChanges = (val) => {
    if (val === "true") {
      const data = allCtas.filter((val) => val?.valid === true);
      const rowUpdatedData = allRowData.filter(
        (val) => val?.status === "APPROVED"
      );
      setRowData(rowUpdatedData);
      setCtaFilterData(data);
    } else if (val === "false") {
      const data = allCtas.filter((val) => val?.valid === false);
      const rowUpdatedData = allRowData.filter(
        (val) => val?.status !== "APPROVED"
      );
      setRowData(rowUpdatedData);
      setCtaFilterData(data);
    } else {
      setCtaFilterData(allCtas);
      setRowData(allRowData);
    }
    setAgentStatus(val);
  };

  //page loader

  const rejectCTAFunc = async () => {
    setloader(true);
    const payload = {
      username: email,
    };
    const data = await rejectCTA(payload).catch((error) => toast.error(error));
    setloader(false);

    if (data === "Success") {
      getAllData(groupEmail).then(() => handleCloseCTAInfoModal());
    } else {
      toast.error("Something went wrong");
    }
  };

  const updateCTADetailsFunc = async () => {
    setloader(true);
    let updated_name = propName !== "" ? propName + " | " + name : name;
    let dataObject = {
      userName: updated_name,
      address: addr1,
      address1: addr2,
      city: city,
      state: state,
      country: country,
      zipCode: pincode,
      phoneNumber: contact,
      email: email,
      guestStatus: "True", //Guest True
      guestType: "CTA", //Guest CTA
      govtIdType: govtIdType,
      govtIdValue: govtIdvalue,
      taxId: idNumber,
      company_name: "", //reservation@maharashtra.gov.in
      creationDate: creationDate,
    };
    if (groupEmail === "reservation@maharashtratourism.gov.in") {
      dataObject["company_name"] = "reservation@maharashtratourism.gov.in";
    }
    const imageData = {
      emailId: email,
      profile: profile === "" ? {} : profile,
      panCard: pan === "" ? {} : pan,
      agreement: agreement === "" ? {} : agreement,
      ndc: ndc === "" ? {} : ndc,
      dd: dd === "" ? {} : dd,
      shopReg: shopReg === "" ? {} : shopReg,
      aadharCard: {
        frontData: aadharFront?.frontData,
        frontType: aadharFront?.frontType,
        backData: aadharBack?.backData,
        backType: aadharBack?.backType,
      },
      cfl: afl === "" ? {} : afl,
      shop: shopImg === "" ? {} : shopImg,
      addressProof: addressProof === "" ? {} : addressProof,
    };

    const detailData = await updateCTADetails(dataObject).catch((error) =>
      toast.error(error)
    );
    const imageResult = updateCTAImages(imageData).catch((error) =>
      toast.error(error)
    );
    if (
      detailData === "Updated Successfully" ||
      imageResult === "Operation Successful"
    ) {
      toast.success("CTA details updated successfully");
      getAllData(groupEmail).then(() => handleCloseCTAInfoModal());
    } else {
      toast.error("Something went wrong");
    }
    setloader(false);
  };

  const [editDetailsFlag, setEditDetailsFlag] = useState(false);
  const [allctaLoader, setAllctaLoader] = useState(false);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  return (
    <div>
      {/* Sending magic link modal */}
      <Modal
        show={openCTAInfoModal}
        onHide={() => handleCloseCTAInfoModal()}
        dialogClassName={"myModalBody"}
        contentClassName={"myModalContent"}
      >
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <Button variant="white" onClick={handleCloseCTAInfoModal}>
              {" "}
              X{" "}
            </Button>
          </div>
        </ModalTitle>
        <ModalBody>
          <div>
            <div
              className="d-flex flex-column"
              style={{
                marginLeft: "2rem",
              }}
            >
              <h2>Validate Travel Agents</h2>
              {/* <p className="text-muted">Use this page for your branding.</p> */}
              <Divider className="mb-2" />
              <div>
                <Checkbox
                  value={editDetailsFlag}
                  onChange={() => {
                    setEditDetailsFlag(!editDetailsFlag);
                  }}
                />
                Edit CTA details
                <Button
                  variant="custom-button"
                  className="float-right mr-4 mb-2"
                  onClick={rejectCTAFunc}
                  disabled={loader || editDetailsFlag}
                  style={{ background: "rgba(255, 0, 0, 0.8)" }}
                >
                  {loader ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Reject"
                  )}
                </Button>
                <Button
                  variant="custom-button"
                  className="float-right mr-4 mb-2"
                  onClick={saveCtaDetail}
                  disabled={loader || editDetailsFlag}
                >
                  {loader ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Verify"
                  )}
                </Button>
              </div>
            </div>
            <div
              className="d-flex "
              style={{
                marginLeft: "2rem",
                marginRight: "2rem",
                marginBottom: "1rem",
                margintop: "1rem",
              }}
            >
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="Name"
                style={{ marginRight: "1rem" }}
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="Email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div
              className="d-flex "
              style={{
                marginLeft: "2rem",
                marginRight: "2rem",
                marginBottom: "1rem",
              }}
            >
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="Proprietor Name"
                value={propName}
                onChange={(e) => setPropName(e.target.value)}
                sx={{
                  marginRight: "1rem",
                }}
              />
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="Contact"
                value={contact}
                onChange={(e) => setcontact(e.target.value)}
                sx={{
                  marginRight: "1rem",
                }}
              />
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="Address1"
                value={addr1}
                onChange={(e) => setaddr1(e.target.value)}
              />
            </div>
            <div
              className="d-flex "
              style={{
                marginLeft: "2rem",
                marginRight: "2rem",
                marginBottom: "1rem",
              }}
            >
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="Address2"
                value={addr2}
                onChange={(e) => setaddr2(e.target.value)}
                sx={{
                  marginRight: "1rem",
                }}
              />
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="City"
                value={city}
                onChange={(e) => setcity(e.target.value)}
              />
            </div>
            <div
              className="d-flex "
              style={{
                marginLeft: "2rem",
                marginRight: "2rem",
                marginBottom: "1rem",
              }}
            >
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="Pincode"
                value={pincode}
                sx={{
                  marginRight: "1rem",
                }}
                onChange={(e) => setpincode(e.target.value)}
              />
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="State"
                value={state}
                onChange={(e) => setstate(e.target.value)}
              />
            </div>
            <div
              className="d-flex "
              style={{
                marginLeft: "2rem",
                marginRight: "2rem",
                marginBottom: "1rem",
              }}
            >
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="Country"
                value={country}
                onChange={(e) => setcountry(e.target.value)}
                sx={{
                  marginRight: "2rem",
                }}
              />
              <TextField
                fullWidth
                label="CTA account number"
                value={accountNumber}
                onChange={(e) => setaccountNumber(e.target.value)}
                disabled={editDetailsFlag}
              />
            </div>
            <div
              className="d-flex "
              style={{
                marginLeft: "2rem",
                marginRight: "2rem",
                marginBottom: "1rem",
              }}
            >
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="Govt ID Type"
                style={{ marginRight: "1rem" }}
                value={govtIdType}
                onChange={(e) => setgovtIdType(e.target.value)}
              />
              <TextField
                fullWidth
                disabled={!editDetailsFlag}
                label="Govt ID Value"
                value={govtIdvalue}
                onChange={(e) => setgovtIdvalue(e.target.value)}
              />
            </div>
            <TextField
              fullWidth
              label="CTA Tax id number"
              value={idNumber}
              onChange={(e) => setidNumber(e.target.value)}
              disabled={editDetailsFlag}
              sx={{
                marginLeft: "2rem",
                marginRight: "1rem",
                width: "13.5rem",
                marginBottom: "1rem",
              }}
            />
            <TextField
              fullWidth
              label="CTA creation date"
              value={creationDate}
              onChange={(e) => setCreationDate(e.target.value)}
              sx={{
                marginRight: "1rem",
                width: "13.5rem",
                marginBottom: "1rem",
              }}
              disabled
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Enabled for hotels
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="hotelIds"
                id="hotelIds"
                label="Enabled for hotels"
                value={selectedHotelIds}
                onChange={handleSelectionChange}
                multiple
                style={{ width: "18rem" }}
                // MenuProps={MenuProps}
                placeholder="Enabled for hotels"
                disabled={selectAllHotels || editDetailsFlag}
              >
                {allHotelIds?.length > 0 &&
                  allHotelIds.map((hotel, index) => {
                    return (
                      <MenuItem key={hotel?.hotelId} value={hotel?.hotelId}>
                        {hotel?.hotelName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {editDetailsFlag === false && (
              <>
                <Checkbox
                  value={selectAllHotels}
                  onChange={() => {
                    setSelectAllHotels(!selectAllHotels);
                  }}
                />
                Select All
              </>
            )}

            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Profile
                </p>
                {otaAttachments?.profileUrl !== null &&
                  otaAttachments?.profileUrl !== "" && (
                    <img
                      src={otaAttachments?.profileUrl}
                      alt=""
                      width="100px"
                      height="60px"
                      style={{
                        marginRight: "1rem",
                        marginLeft: "2rem",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleShow("Profile", otaAttachments?.profileUrl)
                      }
                    />
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    profileFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.profileUrl !== null &&
                  otaAttachments?.profileUrl !== "" ? (
                    <>Edit profile</>
                  ) : (
                    <>Add profile</>
                  )}

                  <input
                    style={{
                      color: profile !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="profile"
                    type="file"
                    className="form-control"
                    accept=".jpg, .jpeg, .png"
                    name="profile"
                    ref={profileFileInput}
                    onChange={(eve) => {
                      let imageName = document.getElementById("profileName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="profileName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Aadhaar Front ID
                </p>
                {otaAttachments?.aadharFrontUrl !== null &&
                  otaAttachments?.aadharFrontUrl !== "" && (
                    <img
                      src={otaAttachments?.aadharFrontUrl}
                      alt=""
                      width="100px"
                      height="60px"
                      style={{
                        marginRight: "1rem",
                        marginLeft: "2rem",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleShow(
                          "Aadhaar Front ID",
                          otaAttachments?.aadharFrontUrl
                        )
                      }
                      // disabled={loading}
                    />
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    aadharFrontFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.aadharFrontUrl !== null &&
                  otaAttachments?.aadharFrontUrl !== "" ? (
                    <>Edit aadhar front</>
                  ) : (
                    <>Add aadhar front</>
                  )}

                  <input
                    style={{
                      color: aadharFront !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="aadharFront"
                    type="file"
                    className="form-control"
                    accept=".jpg, .jpeg, .png"
                    name="aadharFront"
                    ref={aadharFrontFileInput}
                    onChange={(eve) => {
                      let imageName =
                        document.getElementById("aadhaarFrontName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="aadhaarFrontName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Aadhaar Back ID
                </p>
                {otaAttachments?.aadharBackUrl !== null &&
                  otaAttachments?.aadharBackUrl !== "" && (
                    <img
                      src={otaAttachments?.aadharBackUrl}
                      alt=""
                      width="100px"
                      height="60px"
                      style={{
                        marginRight: "1rem",
                        marginLeft: "2rem",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleShow(
                          "Aadhaar Back ID",
                          otaAttachments?.aadharBackUrl
                        )
                      }
                    />
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    aadharBackFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.aadharBackUrl !== null &&
                  otaAttachments?.aadharBackUrl !== "" ? (
                    <>Edit aadhar back</>
                  ) : (
                    <>Add aadhar back</>
                  )}

                  <input
                    style={{
                      color: aadharBack !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="aadharBack"
                    type="file"
                    className="form-control"
                    accept=".jpg, .jpeg, .png"
                    name="aadharBack"
                    ref={aadharBackFileInput}
                    onChange={(eve) => {
                      let imageName =
                        document.getElementById("aadhaarBackName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="aadhaarBackName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Pan Card
                </p>
                {otaAttachments?.panCardUrl !== null &&
                  otaAttachments?.panCardUrl !== "" && (
                    <img
                      src={otaAttachments?.panCardUrl}
                      alt=""
                      width="100px"
                      height="60px"
                      style={{
                        marginRight: "1rem",
                        marginLeft: "2rem",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleShow("Pan card", otaAttachments?.panCardUrl)
                      }
                      // disabled={loading}
                    />
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    panFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.panCardUrl !== null &&
                  otaAttachments?.panCardUrl !== "" ? (
                    <>Edit pan card</>
                  ) : (
                    <>Add pan card</>
                  )}

                  <input
                    style={{
                      color: pan !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="pan"
                    type="file"
                    className="form-control"
                    accept=".jpg, .jpeg, .png"
                    name="pan"
                    ref={panFileInput}
                    onChange={(eve) => {
                      let imageName = document.getElementById("panName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="panName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Shop
                </p>
                {otaAttachments?.shopUrl !== null &&
                  otaAttachments?.shopUrl !== "" && (
                    <img
                      src={otaAttachments?.shopUrl}
                      alt=""
                      width="100px"
                      height="60px"
                      style={{
                        marginRight: "1rem",
                        marginLeft: "2rem",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleShow("Shop", otaAttachments?.shopUrl)
                      }
                      // disabled={loading}
                    />
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    shopImgFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.shopUrl !== null &&
                  otaAttachments?.shopUrl !== "" ? (
                    <>Edit shop image</>
                  ) : (
                    <>Add shop image</>
                  )}

                  <input
                    style={{
                      color: shopImg !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="shopImg"
                    type="file"
                    className="form-control"
                    accept=".jpg, .jpeg, .png"
                    name="shopImg"
                    ref={shopImgFileInput}
                    onChange={(eve) => {
                      let imageName = document.getElementById("shopImgName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="shopImgName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Agreement
                </p>
                {otaAttachments?.agreementUrl !== null &&
                  otaAttachments?.agreementUrl !== "" && (
                    <a href={otaAttachments?.agreementUrl}>
                      <Button
                        variant="custom-button"
                        style={{
                          marginRight: "1rem",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </Button>
                    </a>
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    agreementFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.agreementUrl !== null &&
                  otaAttachments?.agreementUrl !== "" ? (
                    <>Edit agreement</>
                  ) : (
                    <>Add agreemen</>
                  )}

                  <input
                    style={{
                      color: agreement !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="agreement"
                    type="file"
                    className="form-control"
                    accept=".pdf, .jpg, .jpeg, .png"
                    name="agreement"
                    ref={agreementFileInput}
                    onChange={(eve) => {
                      let imageName = document.getElementById("agreementName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="agreementName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Demand Draft
                </p>
                {otaAttachments?.demandDraftUrl !== null &&
                  otaAttachments?.demandDraftUrl !== "" && (
                    <a href={otaAttachments?.demandDraftUrl}>
                      <Button
                        variant="custom-button"
                        style={{
                          marginRight: "1rem",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </Button>
                    </a>
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    ddFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.demandDraftUrl !== null &&
                  otaAttachments?.demandDraftUrl !== "" ? (
                    <>Edit demand draft</>
                  ) : (
                    <>Add demand draft</>
                  )}

                  <input
                    style={{
                      color: dd !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="dd"
                    type="file"
                    className="form-control"
                    accept=".pdf, .jpg, .jpeg, .png"
                    name="dd"
                    ref={ddFileInput}
                    onChange={(eve) => {
                      let imageName = document.getElementById("ddName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="ddName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Forwarding letter
                </p>
                {otaAttachments?.forwardingLetterUrl !== null &&
                  otaAttachments?.forwardingLetterUrl !== "" && (
                    <a href={otaAttachments?.forwardingLetterUrl}>
                      <Button
                        variant="custom-button"
                        style={{
                          marginRight: "1rem",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </Button>
                    </a>
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    aflFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.forwardingLetterUrl !== null &&
                  otaAttachments?.forwardingLetterUrl !== "" ? (
                    <>Edit forwarding letter</>
                  ) : (
                    <>Add forwarding letter</>
                  )}

                  <input
                    style={{
                      color: afl !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="afl"
                    type="file"
                    className="form-control"
                    accept=".pdf, .jpg, .jpeg, .png"
                    name="afl"
                    ref={aflFileInput}
                    onChange={(eve) => {
                      let imageName = document.getElementById("aflName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="aflName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  No default certificate
                </p>
                {otaAttachments?.noDefaultCertificateUrl !== null &&
                  otaAttachments?.noDefaultCertificateUrl !== "" && (
                    <a href={otaAttachments?.noDefaultCertificateUrl}>
                      <Button
                        variant="custom-button"
                        style={{
                          marginRight: "1rem",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </Button>
                    </a>
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    ndcFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.noDefaultCertificateUrl !== null &&
                  otaAttachments?.noDefaultCertificateUrl !== "" ? (
                    <>Edit no default certificate</>
                  ) : (
                    <>Add no default certificate</>
                  )}

                  <input
                    style={{
                      color: ndc !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="ndc"
                    type="file"
                    className="form-control"
                    accept=".pdf, .jpg, .jpeg, .png"
                    name="ndc"
                    ref={ndcFileInput}
                    onChange={(eve) => {
                      let imageName = document.getElementById("ndcName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="ndcName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Shop registration
                </p>
                {otaAttachments?.shopRegUrl !== null &&
                  otaAttachments?.shopRegUrl !== "" && (
                    <a href={otaAttachments?.shopRegUrl}>
                      <Button
                        variant="custom-button"
                        style={{
                          marginRight: "1rem",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </Button>
                    </a>
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    shopRegFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.shopRegUrl !== null &&
                  otaAttachments?.shopRegUrl !== "" ? (
                    <>Edit shop registration</>
                  ) : (
                    <>Add shop registration</>
                  )}

                  <input
                    style={{
                      color: shopReg !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="shopReg"
                    type="file"
                    className="form-control"
                    accept=".pdf, .jpg, .jpeg, .png"
                    name="shopReg"
                    ref={shopRegFileInput}
                    onChange={(eve) => {
                      let imageName = document.getElementById("shopRegName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="shopRegName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              <div className="d-flex align-items-center mb-5 mt-5">
                <p
                  style={{
                    marginRight: "1rem",
                    marginLeft: "2rem",
                    width: "20%",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Address Proof (Shop/Home/ office)
                </p>
                {otaAttachments?.addressProof !== null &&
                  otaAttachments?.addressProof !== "" && (
                    <a href={otaAttachments?.addressProof}>
                      <Button
                        variant="custom-button"
                        style={{
                          marginRight: "1rem",
                          marginLeft: "2rem",
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </Button>
                    </a>
                  )}
                <Button
                  variant="custom-button"
                  className="mr-4 mt-3 ml-4"
                  endIcon={<RiFileEditFill />}
                  onClick={() => {
                    addressProofFileInput.current.click();
                  }}
                  disabled={!editDetailsFlag}
                >
                  {otaAttachments?.addressProof &&
                  otaAttachments?.addressProof !== "" ? (
                    <>Edit address proof</>
                  ) : (
                    <>Add address proof</>
                  )}

                  <input
                    style={{
                      color: addressProof !== "" ? "#20c997" : "black",
                      display: "none",
                    }}
                    id="addressProof"
                    type="file"
                    className="form-control"
                    accept=".pdf, .jpg, .jpeg, .png"
                    name="addressProof"
                    ref={addressProofFileInput}
                    onChange={(eve) => {
                      let imageName =
                        document.getElementById("addressProofName");
                      let inputImage = eve.target.files[0];
                      imageName.innerText = inputImage.name;
                      convertToBase64(eve);
                    }}
                  />
                </Button>
                <span
                  id="addressProofName"
                  style={{
                    color: "green",
                    fontSize: "small",
                    marginLeft: "1.2rem",
                    marginTop: "5px",
                  }}
                ></span>
              </div>
              {editDetailsFlag === true && (
                <>
                  <Divider className="mb-2" />
                  <div>
                    <Button
                      variant="custom-button"
                      className="float-right mr-4 mb-2"
                      onClick={updateCTADetailsFunc}
                      disabled={loader}
                    >
                      {loader ? (
                        <CircularProgress
                          size={15}
                          color="inherit"
                          sx={{ ml: 1.5 }}
                        />
                      ) : (
                        "Save CTA detail"
                      )}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        style={{ zIndex: "3000", width: "100%", height: "100%" }}
        dialogClassName={"myModalBodyCTAImg"}
        contentClassName={"myModalContentCTAImg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={ctaURL}
            alt=""
            width="100%"
            height="100%"
            style={{ marginRight: "1rem", marginLeft: "0rem" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="custom-button" >
                Save
              </Button> */}
        </Modal.Footer>
      </Modal>

      <div style={{ marginLeft: "2rem", marginRight: "2rem" }}>
        <div className="d-flex flex-column">
          <h2>Validate Travel Agents</h2>
          <p className="text-muted">
            List of all travel agents with their respective status. Click on the
            respective account to approve and edit the information{" "}
          </p>
        </div>
        <Divider className="mb-4" />
        <div>
          <AgGridReact
            rowData={rowData}
            columnDefs={columns}
            onGridReady={onGridReady}
          />
        </div>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ padding: "0px 3rem", marginBottom: "1rem" }}
        >
          <OutlinedInput
            onChange={onFilterTextChange}
            placeholder="Search CTAs"
            label="Search CTAs"
            // className="w-100 mb-4"
            notched={false}
            type="search"
            style={{
              boxShadow: "0px 0px 16px 6px #0000001a",
              borderRadius: "5px",
              backgroundColor: "white",
              width: "63%",
            }}
          />
          <FormControl
            sx={{
              width: "20%",
              marginLeft: "2%",
              boxShadow: "0px 0px 16px 6px #0000001a",
              borderRadius: "5px",
              backgroundColor: "white",
            }}
          >
            <InputLabel>Travel agent status</InputLabel>
            <Select
              value={agentStatus}
              onChange={(e) => handleChanges(e.target.value)}
              MenuProps={MenuProps}
              label="Travel agent status"
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"false"}>Approval pending</MenuItem>
              <MenuItem value={"true"}>Approved</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="custom-button"
            className="float-right mr-4 mb-2 mt-3"
            onClick={() => {
              gridApi.exportDataAsExcel();
            }}
          >
            Download as Excel
          </Button>
        </Grid>

        {ctaFilterData && allctaLoader === false ? (
          ctaFilterData
            ?.filter((val) => {
              if (searchTerm == "") {
                return val;
              } else if (
                val?.userInfo?.email
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (
                val?.userInfo?.contact
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (
                val?.userInfo?.name
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return val;
              }
            })
            ?.map((num, index) => {
              return (
                <Grid key={index} item xs={12} sx={{ padding: "0px 3rem" }}>
                  <div>
                    <Card
                      onMouseOver={() => {
                        toggleRaised(num?.userInfo.email);
                      }}
                      onMouseOut={() => {
                        toggleRaised(num?.userInfo.email);
                      }}
                      raised={raised[num?.userInfo.email]}
                      sx={{ marginBottom: 2, borderRadius: "1rem" }}
                      onClick={() => handleOpenCTAInfoModal(num)}
                    >
                      <CardContent sx={{ paddingBottom: "15px!important" }}>
                        <Grid item container spacing={3}>
                          <Grid item xs={4} sm={3} md={4} lg={12} xl={12}>
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-column w-100">
                                <div className="d-flex justify-content-between align-items-start">
                                  <div className="d-flex flex-column">
                                    <h4 className="pr-3">
                                      {num?.userInfo.name}
                                    </h4>
                                    <p className="pr-3">
                                      {num?.userInfo.email}
                                    </p>

                                    <p className="pr-3">
                                      {num?.userInfo.contact}
                                    </p>
                                  </div>
                                  {num.valid === true && (
                                    <p
                                      className="badge badge-secondary"
                                      style={styles.discountColor}
                                    >
                                      {"APPROVED"}
                                    </p>
                                  )}
                                  {num.valid === false && (
                                    <p
                                      className="badge badge-secondary"
                                      style={styles.inActive}
                                    >
                                      {"APPROVAL PENDING"}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              );
            })
        ) : (
          <MockLoaderTable />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth };
}
export default connect(mapStateToProps)(CTAModal);

const styles = {
  imageSize: {
    height: "100px",
    width: "4rem",
    marginRight: "3rem",
  },
  discountColor: {
    marginRight: "8 px",
    padding: "3 px 6 px",
    fontWeight: "700",
    textTransform: "uppercase",
    color: "#fff",
    backgroundColor: "rgba(25, 170, 141, 1)",
  },
  inActive: {
    marginRight: "8 px",
    padding: "3 px 6 px",
    fontWeight: "700",
    textTransform: "uppercase",
    color: "#fff",
    backgroundColor: "rgba(255, 0, 0, 0.45)",
  },
};
