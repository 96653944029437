import React from "react";

function ReportsLoader() {
  return (
    <>
      <div className="mt-4 d-flex position-relative justify-content-between w-90">
        <div className="position-relative box w-auto p-2" style={{ minHeight: "3rem" }}>
          {" "}
        </div>
        <div className="position-relative box w-auto p-2" style={{ minHeight: "3rem" }}>
          {" "}
        </div>
        <div className="position-relative box w-auto p-2" style={{ minHeight: "3rem" }}>
          {" "}
        </div>
        <div className="position-relative box w-auto p-2" style={{ minHeight: "3rem" }}>
          {" "}
        </div>
      </div>

      <div
        className={`mockInvoiceTable d-flex flex-column p-3 justify-content-between box-shadow-main`}
      >
        <div className="d-flex w-100 align-items-center justify-content-between mt-4">
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between mt-5">
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between mt-5">
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between mt-5">
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between mt-5">
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between mt-5">
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
          <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
            {" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportsLoader;
