import React, { lazy, Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
// Components
const BookingDashboard = lazy(() => import("./BookingDashboard"));

function AuthorisedPages() {
  return (
    <Switch>
      <ContentRoute
        exact
        path="/hotel-dashboard"
        component={BookingDashboard}
      />
      {/* <Redirect from="*" to="/hotel-dashboard"></Redirect> */}
    </Switch>
  );
}

export default AuthorisedPages;
