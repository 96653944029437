import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, Box, Modal } from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ReferalModal from "../../_metronic/layout/components/extras/dropdowns/support/ReferalModal";
import { connect } from "react-redux";
import { toast } from "material-react-toastify";
import { useHistory } from "react-router-dom";
import AccountsDashBoard from "./AccountsAndBilling/AccountsDashBoard";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../api";
import Lottie from "react-lottie";
import successAnimationData from "../../../src/app/pages/CalendarPage/Views/lotties/celebration.json";
import { getCsmDetails } from "../../_metronic/layout/components/extras/dropdowns/apis/NotificationAPI";

function DashboardRecommendation(props) {
  const history = useHistory();
  const [showAccountandBilling, setshowAccountandBilling] = useState(false);
  const [referalModal, setreferalModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(true);
  const [selectedComponent, setselectedComponent] = useState("");
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const envType = process.env.REACT_APP_ENV;

  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const openpaymentModal = (comp) => {
    setselectedComponent(comp);
    setPaymentModal(true);
  };

  /* Closes the Support Modal */
  const hidepaymentModal = () => {
    setPaymentModal(false);
  };

  const openReferalModal = () => {
    setreferalModal(true);
  };

  /* Closes the Support Modal */
  const hideReferalModal = () => {
    setreferalModal(false);
  };

  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState("");
  const handleMenuModal = (menu) => {
    setMenu(menu);
    setOpen(true);
  };
  const [confirmModal, setconfirmModal] = useState(false);

  const getId = () => {
    if (menu === "Reputation management") {
      return 27;
    } else if (menu === "Expense Manager") {
      return envType === "BETA" ? 90 : 87;
    }
  };

  const getImage = () => {
    if (menu === "Reputation management") {
      return "https://storage.googleapis.com/prod-images-bucket/misc/AI-powered%20Reputation%20Management%20(10).png";
    } else if (menu === "Expense Manager") {
      return "https://storage.googleapis.com/prod-images-bucket/misc/3.png";
    }
  };

  const getAmount = () => {
    if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return 0;
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return 999;
    } else if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return 0;
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return 49;
    }
  };
  const [paymentLoader, setpaymentLoader] = useState(false);
  const startTrial = () => {
    setpaymentLoader(true);
    const payload = {
      hotelId: hotelId,
      groupId: localStorage.getItem("mainGroupId"),
      description: `${menu} upgrade`,
      type: "ADDON",
      amount: getAmount(),
      addon_feature_id: getId(),
    };
    // if (menu !== "Expense Manager" && menu !== "Reputation management") {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/feature-upgrade/single-feature-upgrade`,
      payload
    )
      .then(() => {
        setpaymentLoader(false);
        setOpen(false);
        setconfirmModal(true);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setpaymentLoader(false);
      });
    // } else {
    //   handleSpringDataRequest(
    //     `core/api/v1/feature-upgrade/add-feature-to-featureSet?featureId=${
    //       menu === "Expense Manager" ? 90 : 27
    //     }`
    //   )
    //     .then(() => {
    //       setpaymentLoader(false);
    //       setOpen(false);
    //       setconfirmModal(true);
    //     })
    //     .catch((err) => {
    //       toast.error(err.response.data);
    //       setpaymentLoader(false);
    //     });
    // }
  };

  const getText = () => {
    if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return (
        <div>
          Upgrade for INR{" "}
          <span style={{ textDecoration: "line-through" }}>999</span> 0 / month
        </div>
      );
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return (
        <div>
          Upgrade for INR{" "}
          <span style={{ textDecoration: "line-through" }}>3000</span> 999 /
          month
        </div>
      );
    } else if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return (
        <div>
          Upgrade for{" "}
          <span style={{ textDecoration: "line-through" }}>$49</span> $0 / month
        </div>
      );
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return (
        <div>
          Upgrade for{" "}
          <span style={{ textDecoration: "line-through" }}>$299</span> $49 /
          month
        </div>
      );
    }
  };

  const [csmData, setCsmData] = useState(null);
  useEffect(async () => {
    const groupId = props?.data?.accessControl?.hotel?.group_id;
    if (groupId && csmData == null) {
      await getCsmDetails(groupId).then((resp) => {
        if (String(resp?.status) === "200") {
          const data = resp?.data;
          setCsmData(data);
        }
      });
    }
  }, [props?.data?.accessControl?.hotel?.group_id]);

  return showAccountandBilling ? (
    <AccountsDashBoard
      groupId={localStorage.getItem("actGroupId")}
      fromRecommendation={true}
    />
  ) : (
    <div className="w-100">
      <div className="d-flex flex-column">
        <h1 style={{ display: "flex", justifyContent: "center" }}>
          Recommendations to drive more revenues and optimize costs
        </h1>
        <p style={{ display: "flex", justifyContent: "center" }}>
          These recommendations are exclusively curated for your property by our
          AI. Some of the best properties running on Stayflexi use below
          recommendations to drive more bookings and optimize their operational
          costs.
        </p>
        <p style={{ display: "flex", justifyContent: "center" }}>
          You can also contact your account manager for any consultation on how
          to get the best out of Stayflexi.
        </p>
      </div>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={6} md={3}>
          <div
            className="informationCardWrapper"
            style={{
              height: "15rem",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <div
              style={{
                color: "black",
                fontWeight: "500",
                fontSize: "18px",
                marginLeft: "1rem",
                marginTop: "1rem",
              }}
            >
              {" "}
              Setup auto payment for billing
            </div>
            <p
              className="font-weight-bold mt-2 ml-4"
              style={{ color: "gray", textAlign: "left" }}
            >
              We have automated our billing, we will be using Razorpay Emandate
              to automatically collect our subscription fees. Deadline for
              setting up automated billing is Sep 31st. Please click below and
              complete setup to avoid any account deactivation.
            </p>
            <Button
              variant="custom-button"
              style={{ position: "absolute", bottom: "1rem" }}
              onClick={() => {
                setshowAccountandBilling(true);
              }}
            >
              Setup auto payment
            </Button>
          </div>
        </Grid>
        {JSON.parse(
          props?.data?.accessControl?.user_feature_map?.ExpenseManager
        ).read !== "YES" && (
          <Grid item xs={12} sm={6} md={3}>
            <div
              className="informationCardWrapper"
              style={{
                height: "15rem",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <div
                style={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "18px",
                  marginLeft: "1rem",
                  marginTop: "1rem",
                }}
              >
                {" "}
                Enable Expenses Manager
              </div>
              <p
                className="font-weight-bold mt-2 ml-4"
                style={{ color: "gray", textAlign: "left" }}
              >
                Track all your property expenses in one place. You can add
                expenses, withdrawals, and top up the cash counter. Expenses
                manager is a great way to track your cash and staff expenses.
              </p>
              <Button
                variant="custom-button"
                style={{ position: "absolute", bottom: "1rem" }}
                onClick={() => {
                  handleMenuModal("Expense Manager");
                }}
              >
                Activate Expenses Manager
              </Button>
            </div>
          </Grid>
        )}
        {JSON.parse(
          props?.data?.accessControl?.user_feature_map?.ReputationManagement
        ).read !== "YES" && (
          <Grid item xs={12} sm={6} md={3}>
            <div
              className="informationCardWrapper"
              style={{
                height: "15rem",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <div className="d-flex" style={{ marginTop: "1rem" }}>
                {" "}
                <div
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "18px",
                    marginLeft: "1rem",
                  }}
                >
                  {" "}
                  Reputation Manager, powered by AI
                </div>
                <span
                  class="badge badge-info"
                  style={{
                    fontWeight: "bold",
                    marginLeft: "1rem",
                    height: "2rem",
                  }}
                >
                  Free
                </span>
              </div>

              <p
                className="font-weight-bold mt-2 ml-4"
                style={{ color: "gray", textAlign: "left" }}
              >
                You can reply to booking.com reviews with the help of FlexiGPT.
              </p>
              <Button
                variant="custom-button"
                style={{ position: "absolute", bottom: "1rem" }}
                onClick={() => {
                  handleMenuModal("Reputation management");
                }}
              >
                Activate reputation manager
              </Button>
            </div>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3}>
          <div
            className="informationCardWrapper"
            style={{
              height: "15rem",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <div
              style={{
                color: "black",
                fontWeight: "500",
                fontSize: "18px",
                marginLeft: "1rem",
                marginTop: "1rem",
              }}
            >
              {"Refer & Earn"}
            </div>
            <p
              className="font-weight-bold mt-2 ml-4"
              style={{ color: "gray", textAlign: "left" }}
            >
              {`For each successful hotel referral, we pay you ${
                props.data.hotelCurrency === "INR" ? "Rs. 2000" : "$200"
              }. Unlimited
            referrals.`}
            </p>
            <Button
              variant="custom-button"
              style={{ position: "absolute", bottom: "1rem" }}
              onClick={() => {
                openReferalModal();
              }}
            >
              Submit referral
            </Button>
          </div>
        </Grid>
      </Grid>
      <div className="d-flex w-100"></div>
      <div className="d-flex w-100 mt-4"></div>

      <ReferalModal show={referalModal} hide={hideReferalModal}></ReferalModal>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            height: "45rem",
            width: "82rem",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Upgrade for new features</div>
              <div className="close" onClick={() => setOpen(false)}>
                X
              </div>
            </div>

            <div className="d-flex">
              <div className="amountWrapper">
                <h1 style={{ marginTop: "2rem" }}>
                  {menu === "Expense Manager" &&
                    "Manage all your expenses in single place."}
                  {menu === "Reputation management" &&
                    "AI powered review management"}
                </h1>
                <h3 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                  {menu === "Expense Manager" &&
                    `Upgrade to expenses manager to steamline your property expenses.`}
                  {menu === "Reputation management" &&
                    `Manage all your booking.com and airbnb reviews in one place. Powered by FlexiGPT, you can automatically reply to reviews with guest sentiment analysis.`}
                </h3>

                {menu === "Reputation management" && (
                  <>
                    <ul>
                      <li>FlexiGPT AI-powered</li>
                      <li>Booking & Airbnb reviews</li>
                    </ul>

                    <div className="d-flex justify-content-center mt-0">
                      <span
                        class="badge badge-warning"
                        style={{
                          fontWeight: "bold",
                          height: "4rem",
                          width: "30rem",
                          marginTop: "3rem",
                          fontSize: "20px",
                          lineHeight: "28px",
                          marginTop: "0px",
                        }}
                      >
                        {`Limited time offer, 100% discounted`}
                      </span>
                    </div>
                  </>
                )}
                {menu === "Expense Manager" && (
                  <>
                    <ul>
                      <li>Cash counter</li>
                      <li>Expenses tracking</li>
                      <li>Withdrawals</li>
                      <li>Cash topups</li>
                    </ul>
                    <div className="d-flex justify-content-center">
                      <span
                        class="badge badge-warning"
                        style={{
                          fontWeight: "bold",
                          height: "4rem",
                          width: "30rem",
                          marginTop: "3rem",
                          fontSize: "20px",
                          lineHeight: "28px",
                        }}
                      >
                        {`Limited time offer, ${
                          props?.data?.hotelCurrency === "INR"
                            ? "66.66%"
                            : "83.33%"
                        } discounted`}
                      </span>
                    </div>
                  </>
                )}

                <div
                  style={{
                    position: "absolute",
                    bottom: "2rem",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="custom-button"
                    style={{
                      position: "absolute",
                      bottom: "4rem",
                      width: "46%",
                      fontWeight: "bold",
                      height: "4rem",
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      if (props?.data?.accessControl?.ADMINRIGHTS === "ADMIN") {
                        startTrial();
                      } else {
                        toast.warning(
                          "Please contact admin to  enable this feature"
                        );
                      }
                    }}
                    disabled={paymentLoader}
                  >
                    {paymentLoader ? (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    ) : (
                      getText()
                    )}
                  </Button>
                  <p
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      cursor: "pointer",
                      marginTop: "2rem",
                      color: "#1BC5BD",
                    }}
                    onClick={() =>
                      window.open(
                        Object.entries(csmData).length > 0
                          ? csmData.account_csm_calendar_link
                          : "https://calendly.com/bharath-gowda/30min",
                        "_blank"
                      )
                    }
                  >
                    Talk to sales
                  </p>
                </div>
              </div>

              <div>
                <img
                  style={{
                    height: "36rem",
                    width: "40rem",
                  }}
                  src={getImage()}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={confirmModal}
        onClose={() => setconfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            height: "25rem",
            width: "70rem",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Upgrade for new features</div>
              <div className="close" onClick={() => setconfirmModal(false)}>
                X
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              <Lottie options={successAnimation} height={150} width={150} />
              <h1>
                Successfully upgraded please logout and login to access this
                feature
              </h1>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(DashboardRecommendation);
