import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as AiIcons from "react-icons/ai";
import {
  getFinalPrice,
  getGeoCodeDetails,
  getPriceList,
} from "../api/ReservationApi";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  dateFnsFormat,
  getApprovedByOverseas,
  getCountriesAndStates,
  getGenderList,
  getIshaGenderList,
  getIshaProgramsAttended,
  getIshaPurposeOfVisit,
} from "../constants/Calendar-constants";
import { debounce, isNull, isUndefined } from "lodash";
import {
  checkPromoCodeQuickBook,
  getAllPromocodes,
  getAllPromocodesPriceList,
} from "../../Promocodes/apis/PromocodeApi";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import moment from "moment";
import { toast } from "material-react-toastify";
import { getHotelAddons } from "../../HotelAddons/HotelAddonsAPI";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Modal } from "react-bootstrap";
import Webcam from "react-webcam";
import ReactImageUploading from "react-images-uploading";
import { format } from "date-fns";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { AiOutlineClose } from "react-icons/ai";
import NewGuestView from "./NewGuestView";
import NewChildView from "./NewChildView";
import { getAllCustomers } from "../../FlexibleSlot/api/FlexibleSlotApi";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchModal from "./SearchModal";
import GroupDayLevelPricing from "./GroupDayLevelPricing";
import CardSwipe from "react-card-swipe";
import { shallowEqual, useSelector } from "react-redux";

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const StyledAccordionSummary = styled((props) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  margin: "none",
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderBottom: "none",
}));

const GroupBookingDetails = (props) => {
  const {
    data,
    hotelType,
    formik,
    checkin,
    checkout,
    currentSelection,
    showRooms,
    priceWithTax,
    taxNames,
    perDayList,
    hotelCurrency,
    datas,
    roomIds,
    updateRoomData,
    updatePrimaryGuestIdProof,
    updateOtherGuestIdProofs,
    updatePrimaryGuestDocuments,
    updateOtherGuestDocumentsFunc,
    updateRefData,
    printRefData,
    selectedRatePlan,
    roomSelectionCount,
    hotelCountry,
    addCardFirstName,
    setAddCardFirstName,
    addCardLastName,
    setAddCardLastName,
    addCardNumber,
    setAddCardNumber,
    addCardType,
    setAddCardType,
    addCardCVV,
    setAddCardCVV,
    addCardExpiry,
    setAddCardExpiry,
    error,
    setError,
    paymentMode,
    setpaymentMode,
    paymentAmount,
    setpaymentAmount,
    remark,
    setremark,
    getCardType,
    checkLuhn,
    cardNumberCVVFormat,
    checkExpiry,
    showCollectpayment,
    setshowCollectpayment,
    toggleCollectpayment,
  } = props;
  // useEffect(() => {
  //   console.log("datasdatas", datas);
  // }, [datas]);

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const [subUser, setsubUser] = useState(hotel?.accessControl?.ADMINRIGHTS);
  const [hotelemail, sethotelemail] = useState(
    hotel?.accessControl?.hotel?.hotel_email
  );

  const ishaProgramsAttended = getIshaProgramsAttended();
  const ishaApprovedByOverseas = getApprovedByOverseas();
  const ishaPurposeOfVisit = getIshaPurposeOfVisit();
  const ishaGenderList = getIshaGenderList();
  const genderList = getGenderList();

  const countries = getCountriesAndStates();
  const diffTime = Math.abs(checkout - checkin);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const [dayList, setDayList] = useState({});
  const [subtotal, setSubtotal] = useState({ sub: 0, tax: 0 });
  const [dicountType, setdicountType] = useState("percent");
  const [discountAmount, setdiscountAmount] = useState(0);
  const [showDiscountButton, setshowDiscountButton] = useState(true);
  const [discountApplied, setdiscountApplied] = useState("");
  const [showPromoButton, setshowPromoButton] = useState(true);
  const [priceAfterTax, setpriceAfterTaxes] = useState(
    formik.values.totalPrice
  );
  const [priceBeforetax, setpriceBeforetax] = useState(subtotal.sub);
  const [taxPrice, settaxPrice] = useState(subtotal.tax);

  const [counterss, setcounterss] = useState(0);
  const [showCompanyModal, setshowCompanyModal] = useState(false);
  const [searchableValue, setsearchableValue] = useState("");
  const [allCompanyDetails, setallCompanyDetails] = useState([]);

  useEffect(() => {
    CardSwipe.init({
      success: handleSwipeSuccess,
      debug: false,
    });
  }, []);

  const handleSwipeSuccess = (swipeData) => {
    const expMonth = swipeData?.expMonth;
    const expYear = swipeData?.expYear;
    setAddCardFirstName(swipeData?.firstName);
    setAddCardLastName(swipeData?.lastName);
    setAddCardNumber(swipeData?.account);
    setAddCardExpiry(expMonth + expYear);
  };

  useEffect(() => {
    handleSpringDataRequest(`core/api/v1/guest-company-details/get-all`)
      .then((res) => setallCompanyDetails(res))
      .catch((err) => console.error(err));
  }, []);
  const hideShowCompanyModal = () => {
    setshowCompanyModal(false);
  };

  useEffect(() => {
    formik.setFieldValue("companyGstNumber", searchableValue);
  }, [searchableValue]);

  useEffect(() => {
    if (
      formik.values.totalPrice &&
      counterss === 0 &&
      subtotal.sub &&
      subtotal.tax
    ) {
      setpriceAfterTaxes(formik.values.totalPrice);
      settaxPrice(subtotal.tax);
      setpriceBeforetax(subtotal.sub);
      setcounterss(1);
    }
  }, [formik.values.totalPrice, subtotal.sub, subtotal.tax]);

  //Setting new tax id
  const taxIdChanged = (e) => {
    formik.setFieldValue("taxId", e.target.value);
  };

  // //Setting the date of birth selection in formik(
  // const setDateOfBirthSelection = (dob, isTime) => {
  //   try {
  //     formik.setFieldValue("customer_dob", dob);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const setDateOfBirthSelectionOther = (ind, val) => {
  //   try {
  //     performPrimaryGuestUpdate(ind, "customerDOB", val);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  const govIdUS = ["Passport", "Drivers License", "Others"];

  // Government IDs --- Indian properties
  const govIdIndia = ["Passport", "Drivers License", "Aadhar", "Others"];
  const [imageUpload, setimageUpload] = useState(["Camera"]);

  useEffect(() => {
    getFinalData();
  }, []);

  const getFinalData = async () => {
    const arr = [];
    Array.from(currentSelection).forEach((roomId) => {
      let obj = Object.assign({}, priceWithTax[roomId]);
      obj.allPerDayPrices = perDayList[roomId].allPerDayPrices;
      arr.push(obj);
    });
    try {
      const data = await getPriceList(arr);
      const finaldata = await getFinalPrice(
        JSON.stringify(data),
        data.rateAndTax.rate + data.rateAndTax.tax,
        data.rateAndTax.rate + data.rateAndTax.tax
      );
      if (
        JSON.parse(
          props?.data?.accessControl?.user_feature_map?.EnquiryDashboard
        ).read === "YES"
      ) {
        let subTotalTax = 0;
        let subtotalAmount = 0;

        const dataaaa = arr.map((e) => {
          subTotalTax = subTotalTax + e.taxes;
          subtotalAmount = subtotalAmount + e.subtotal;
        });
        setSubtotal({
          sub: subtotalAmount,
          tax: subTotalTax,
        });
        formik.setFieldValue(
          "totalPrice",
          (subtotalAmount + subTotalTax).toFixed(2)
        );
      } else {
        setDayList(data);
        setSubtotal({
          sub: finaldata.rateAndTax.rate,
          tax: finaldata.rateAndTax.tax,
        });
        formik.setFieldValue(
          "totalPrice",
          (finaldata.rateAndTax.rate + finaldata.rateAndTax.tax).toFixed(2)
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  const inputProps = {
    step: 0.01,
  };

  const finalPriceChange = async (val) => {
    const finaldata = await getFinalPrice(
      JSON.stringify(dayList),
      dayList.rateAndTax.rate + dayList.rateAndTax.tax,
      val
    );
    setSubtotal({
      sub: finaldata.rateAndTax.rate,
      tax: finaldata.rateAndTax.tax,
    });
  };

  //promo code addition
  const [code, setcode] = useState("secretcode");

  const [PromocodeData, setPromocodeData] = useState();
  const [promoValue, setpromoValue] = useState("");
  const [promoPricingList, setpromoPricingList] = useState("");
  const [PromoValidation, setPromoValidation] = useState("");
  const [secretCoupon, setsecretCoupon] = useState("");
  const [RoomsReuqested, setRoomsReuqested] = useState([]);
  const [copyPromocodeData, setcopyPromocodeData] = useState([]);

  const getPricingLists = async () => {
    let arr = [];
    let roomsRequested = [];
    if (datas?.length > 0) {
      for (let i = 0; i < datas?.length; i++) {
        const data = await getAllPromocodesPriceList(
          moment(checkin).format("DD-MM-YYYY HH:00:00"),
          moment(checkout).format("DD-MM-YYYY HH:00:00"),
          datas[i]?.roomTypeId,
          datas[i]?.ratePlan?.ratePlanId
        );
        roomsRequested.push({
          roomTypeId: datas[i]?.roomTypeId,
          ratePlanId: datas[i]?.ratePlan?.ratePlanId,
          adultChildList: [
            {
              numAdults: 1,
              numChildren: 0,
            },
          ],
          dayLevelPriceList: data,
        });
        arr.push(data);
      }
    }
    setRoomsReuqested(roomsRequested);
    setpromoPricingList(arr);
  };
  const [selectedPromocodeDatas, setselectedPromocodeDatas] = useState([]);
  const applyingPromo = async () => {
    setPromoValidation("");
    let total = 0;
    const datssa = datas?.map((res) => {
      total += res?.roomCount;
    });

    const promoDatas = copyPromocodeData.filter(
      (e) => e.promoCode === promoValue
    );
    setselectedPromocodeDatas(promoDatas);
    const payload = {
      hotelId: data?.hotelId,
      promoCode: promoValue,
      checkin: moment(checkin).format("DD-MM-YYYY HH:00:00"),
      checkout: moment(checkout).format("DD-MM-YYYY HH:00:00"),
      numRooms: total,
      amountBeforeTax: subtotal.sub.toFixed(2),
      taxAmount: subtotal.tax.toFixed(2),
      percentage: promoDatas[0].discount,
      roomsRequested: RoomsReuqested,
    };
    const discount = Number(promoDatas[0].discount);

    const dataas = await checkPromoCodeQuickBook(payload);
    setPromoValidation(dataas);

    if (dataas.validPromo === true) {
      setshowPromoButton(false);

      formik.setFieldValue(
        "appliedPromocode",
        `${promoValue}|${
          promoDatas[0]?.promoCodeType === "PROMOCODE" ? "OFFER" : "COUPON CODE"
        }`
      );
      formik.setFieldValue(
        "promoAmount",
        parseFloat(subtotal?.sub?.toFixed(2)) -
          (subtotal?.sub?.toFixed(2) -
            subtotal?.sub?.toFixed(2) *
              (parseFloat(promoDatas[0].discount) / 100))
      );
    }
  };

  const removePromo = () => {
    // formik.setFieldValue("totalPrice", Number(priceAfterTax).toFixed(2));
    setshowPromoButton(true);
    // setSubtotal({
    //   sub: priceBeforetax,
    //   tax: taxPrice,
    // });
    formik.setFieldValue("promoAmount", 0);
    setPromoValidation("");
    setpromoValue("");
  };

  const getPromoAllData = async () => {
    try {
      const data = await getAllPromocodes();
      const mydata = JSON.parse(data?.message);
      setcopyPromocodeData(mydata?.filter((e) => e.activeStatus === "ACTIVE"));
      const filterData = mydata?.filter(
        (e) => e.activeStatus === "ACTIVE" && e.promoCodeType === "PROMOCODE"
      );
      setPromocodeData(filterData);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getPromoAllData();
  }, []);

  useEffect(() => {
    getPricingLists();
  }, []);

  //Discount addition
  const applyDiscount = () => {
    if (
      (dicountType === "percent" && Number(discountAmount) > 100) ||
      (dicountType === "percent" && Number(discountAmount) < 0)
    ) {
      toast.error("Please enter value in between 0-100");
    } else if (dicountType === "flat" && Number(discountAmount) < 0) {
      toast.error("Please enter correct value");
    } else {
      const price = subtotal?.sub?.toFixed(2);
      if (dicountType === "percent") {
        formik.setFieldValue(
          "promoAmount",
          parseFloat(price) - (price - price * (discountAmount / 100))
        );
      } else {
        formik.setFieldValue(
          "promoAmount",
          parseFloat(price) - (parseFloat(price) - parseFloat(discountAmount))
        );
      }

      // .catch((err) => toast.error("Something went wrong"));

      setshowDiscountButton(false);
      formik.setFieldValue("appliedPromocode", "DISCOUNT|DISCOUNT");

      setdiscountApplied("true");
    }
  };

  const removeDiscount = () => {
    setdiscountAmount("");
    setshowDiscountButton(true);
    setdiscountApplied("");
    formik.setFieldValue("promoAmount", 0);
    setPromoValidation("");
    setpromoValue("");
  };

  //Addons addition
  const [hotelAddons, sethotelAddons] = useState([]);
  const [selectedAddons, setselectedAddons] = useState([]);

  useEffect(async () => {
    const data = await getHotelAddons();
    let roomTypeIdList = [];
    const dummyVar1 = datas.map((roomData) =>
      roomTypeIdList.push(roomData?.roomTypeId)
    );
    const selectedAddons = data.filter(function (el) {
      if (el?.room_type_id.split(",")?.length > 1) {
        let isPresent = false;
        let dummyVar3 = el?.room_type_id.split(",").map((roomType) => {
          if (roomTypeIdList.includes(roomType)) {
            isPresent = true;
          }
        });
        return isPresent;
      } else {
        return roomTypeIdList.includes(el.room_type_id);
      }
    });
    var todaysDate = moment(new Date()).unix();

    const arr = [];
    const datsasa = selectedAddons?.map((e, index) => {
      var startDateUnix = moment(
        selectedAddons[index].start_date,
        "DD-MM-YYYY HH:MM:SS"
      ).unix();
      var endDateUnix = moment(
        selectedAddons[index].end_date,
        "DD-MM-YYYY HH:MM:SS"
      ).unix();
      if (todaysDate >= startDateUnix && todaysDate <= endDateUnix) {
        arr.push(e);
      }
    });

    sethotelAddons(arr);
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setselectedAddons(typeof value === "string" ? value.split(",") : value);
  };

  const [selectedAddonsArray, setselectedAddonsArray] = useState([]);
  const [addonsProductArray, setaddonsProductArray] = useState([]);

  useEffect(() => {
    const arr = [];
    const selectedAddonDastas = [];
    if (selectedAddons.length > 0) {
      for (let i = 0; i < selectedAddons.length; i++) {
        const data = hotelAddons.filter((e) => e.id === selectedAddons[i]);
        arr.push(data[0]);
        const obj = {
          id: data[0].id,
          name: data[0]?.name,
          quantity: 1,
          priceWithoutTax: data[0].addon_value,
          taxPercentage: data[0].tax_percentage,
          priceWithTax:
            data[0].addon_value +
            data[0].addon_value * (data[0].tax_percentage / 100),
          description: data[0].description,
        };
        selectedAddonDastas.push(obj);
      }
    }
    setselectedAddonsArray(arr);
    setaddonsProductArray(selectedAddonDastas);
    formik.setFieldValue("addons", selectedAddonDastas);
  }, [selectedAddons]);

  const columns = [
    {
      dataField: "name",
      text: "Name",

      editable: false,
    },
    {
      dataField: "quantity",
      text: "Quantity",
    },
    {
      dataField: "priceWithoutTax",
      text: "Price without tax",
    },
    {
      dataField: "taxPercentage",
      text: "Tax percentage",

      editable: false,
    },
    {
      dataField: "priceWithTax",
      text: "Price with tax",
      sort: true,
      editable: false,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Price should be numeric",
          };
        }
        return true;
      },
    },
  ];

  //Enquiry flow
  const [showExpiresin, setshowExpiresin] = useState(false);
  const [expirationTime, setexpirationTime] = useState("HOURS");
  const [ExpirationSource, setExpirationSource] = useState("AFTER");
  const [EnquiryExpiryTime, setEnquiryExpiryTime] = useState("");

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const toggleShowExpiresin = (e) => {
    if (e.target.checked === true) {
      formik.setFieldValue("is_enquiry", true);
      setshowExpiresin(true);
      localStorage.setItem("IsEnquiry", true);
    } else {
      formik.setFieldValue("is_enquiry", false);
      localStorage.setItem("IsEnquiry", false);
      setshowExpiresin(false);
    }
  };

  // const performPrimaryGuestUpdate = (roomInd, attribute, value) => {
  //   let tempRoomData = roomWiseGuestData[roomInd];
  //   tempRoomData["primaryGuestData"][attribute] = value;
  //   const updatedRoomData = roomWiseGuestData.map((roomDetail, index) => {
  //     if (index == roomInd) {
  //       return tempRoomData;
  //     } else {
  //       return roomDetail;
  //     }
  //   });
  //   setRoomWiseGuestData(updatedRoomData);
  // };

  // useEffect(() => {
  //   updateRoomData(roomWiseGuestData);
  // }, [roomWiseGuestData]);

  //Camera upload
  const updateImageUploadArray = (ind, value) => {
    let updatedArr = imageUpload.map((image, index) => {
      if (index === ind) {
        return value;
      } else {
        return image;
      }
    });
    setimageUpload(updatedArr);
  };

  const webcamRef = React.useRef(null);

  const [frontImages, setFrontImages] = useState([]);
  const [backImages, setBackImages] = useState([]);

  const updateFrontImageArray = (ind, value) => {
    let updatedArr = frontImages.map((image, index) => {
      if (index === ind) {
        const newVal = value;
        return newVal;
      } else {
        return image;
      }
    });

    setFrontImages(updatedArr);
  };

  const updateBackImageArray = (ind, value) => {
    let updatedArr = backImages.map((image, index) => {
      if (index === ind) {
        return value;
      } else {
        return image;
      }
    });
    setBackImages(updatedArr);
  };

  const captureFront = React.useCallback(
    (ind) => {
      const imageSrc = webcamRef.current.getScreenshot();
      updateFrontImageArray(ind, imageSrc);
    },
    [webcamRef, frontImages]
  );

  const captureBack = React.useCallback(
    (ind) => {
      const imageSrc = webcamRef.current.getScreenshot();
      updateBackImageArray(ind, imageSrc);
    },
    [webcamRef, backImages]
  );

  const videoConstraints = {
    width: 400,
    height: 300,
    facingMode: "user",
  };

  const [showFrontIdModalList, setshowFrontIdModalList] = useState([]);

  const updateFrontIdModalList = (ind, val) => {
    let updatedArr = showFrontIdModalList.map((value, index) => {
      if (index === ind) {
        return val;
      } else {
        return value;
      }
    });
    setshowFrontIdModalList(updatedArr);
  };

  const updateBackIdModalList = (ind, val) => {
    let updatedArr = showBackIdModalList.map((value, index) => {
      if (index === ind) {
        return val;
      } else {
        return value;
      }
    });
    setshowBackIdModalList(updatedArr);
  };

  const [showBackIdModalList, setshowBackIdModalList] = useState([]);

  const handleCloseFrontIdModalInd = (ind) => {
    updateFrontIdModalList(ind, false);
  };
  const handleOpenFrontIdModalInd = (ind) => {
    updateFrontIdModalList(ind, true);
  };

  const handleCloseBackIdModalInd = (ind) => {
    updateBackIdModalList(ind, false);
  };
  const handleOpenBackIdModalInd = (ind) => {
    updateBackIdModalList(ind, true);
  };

  //for device upload
  const [frontDeviceImages, setFrontDeviceImages] = useState([]);

  const [backDeviceImages, setBackDeviceImages] = useState([]);

  const [showFrontImagePopup, setshowFrontImagePopup] = useState(false);
  const [showPopUpImages, setshowPopUpImages] = useState("");

  const handleshowFrontImagePopupClose = () => setshowFrontImagePopup(false);
  const handleFrontImagePopupShow = (data) => {
    setshowPopUpImages(data);
    setshowFrontImagePopup(true);
  };

  const updateFrontDeviceImages = (ind, val) => {
    let updatedArr = frontDeviceImages.map((value, index) => {
      if (index === ind) {
        return val;
      } else {
        return value;
      }
    });
    setFrontDeviceImages(updatedArr);
  };

  const updateBackDeviceImages = (ind, val) => {
    let updatedArr = backDeviceImages.map((value, index) => {
      if (index === ind) {
        return val;
      } else {
        return value;
      }
    });
    setBackDeviceImages(updatedArr);
  };

  //ETA, ETD
  const currentDate = new Date();

  const [allETA, setAllETA] = useState([]);
  const [allETD, setAllETD] = useState([]);

  const updateAllETA = (ind, val) => {
    let updatedArr = allETA.map((value, index) => {
      if (index === ind) {
        return val;
      } else {
        return value;
      }
    });
    setAllETA(updatedArr);
  };

  const updateAllETD = (ind, val) => {
    let updatedArr = allETD.map((value, index) => {
      if (index === ind) {
        return val;
      } else {
        return value;
      }
    });
    setAllETD(updatedArr);
  };

  //   const [ETA, setETA] = useState(new Date());
  //   const [ETD, setETD] = useState(new Date());

  const handleETAChange = (e, index) => {
    updateAllETA(index, e);
    // setETA(e);
    // if (index === 0) {
    //   formik.setFieldValue("ETA", moment(e).format("YYYY-MM-DD HH:mm:ss"));
    // } else {
    //   performPrimaryGuestUpdate(
    //     index,
    //     "customerETA",
    //     moment(e).format("YYYY-MM-DD HH:mm:ss")
    //   );
    // }
    // newRoomWiseData[index].primaryGuestData.customerETA.value = moment(
    //   e
    // ).format("YYYY-MM-DD HH:mm:ss");
  };

  const handleETDChange = (e, index) => {
    updateAllETD(index, e);
    // if (index === 0) {
    //   formik.setFieldValue("ETD", moment(e).format("YYYY-MM-DD HH:mm:ss"));
    // } else {
    //   performPrimaryGuestUpdate(
    //     index,
    //     "customerETD",
    //     moment(e).format("YYYY-MM-DD HH:mm:ss")
    //   );
    // }
    // newRoomWiseData[index].primaryGuestData.customerETD.value = moment(
    //   e
    // ).format("YYYY-MM-DD HH:mm:ss");
  };

  //   useEffect(() => {
  //     setETA(new Date(checkin));
  //     setETD(new Date(checkout));
  //   }, [checkin, checkout]);

  // Add guest details right drawer
  const [selectedPrograms, setSelectedPrograms] = useState(["-"]);
  const updateSelectedPrograms = (ind, val) => {
    let updatedArr = selectedPrograms.map((value, index) => {
      if (index === ind) {
        return val;
      } else {
        return value;
      }
    });
    setSelectedPrograms(updatedArr);
  };

  const handleProgramChange = (event, index) => {
    const {
      target: { value },
    } = event;
    let attendedPrograms = typeof value === "string" ? value.split(",") : value;

    updateSelectedPrograms(index, attendedPrograms);
    // if (index === 0) {
    //   formik.setFieldValue("programsAttended", attendedPrograms.toString());
    // } else {
    //   performPrimaryGuestUpdate(
    //     index,
    //     "custProgramsAttended",
    //     attendedPrograms.toString()
    //   );
    // }
    // newRoomWiseData[index].primaryGuestData.custProgramsAttended.value =
    //   attendedPrograms;
    // setSelectedPrograms(attendedPrograms);
  };

  //   console.log("hoteltype: ", hotelType);

  //same as primary check
  const [sameDataCheck, setSameDataCheck] = useState([]);
  const updateSameDataCheck = (ind, flag) => {
    let updatedArr = sameDataCheck.map((value, index) => {
      if (index === ind) {
        return flag;
      } else {
        return value;
      }
    });
    setSameDataCheck(updatedArr);
  };

  // const performCopyOfPrimaryData = (ind) => {
  //   let updatedPrimaryGuestData = {
  //     customerName: formik.values.customer_name,
  //     customerEmail: formik.values.customer_email,
  //     customerPhone: formik.values.customer_phone,
  //     customerGender: formik.values.customer_gender,
  //     customerDOB: formik.values.customer_dob,
  //     custGovtIDType: formik.values.govtIdType,
  //     custGovtIDValue: formik.values.govtIdValue,
  //     custAddress1: formik.values.address1,
  //     custAddress2: formik.values.address2,
  //     custZipcode: formik.values.zipcode,
  //     custCountry: formik.values.country,
  //     custState: formik.values.state,
  //     custCity: formik.values.city,
  //     custNationality: formik.values.nationality,
  //     custOccupation: formik.values.occupation,
  //     custCompany: formik.values.companyName,
  //     custApprovedByOverseas: formik.values.approvedByOverseas,
  //     custPurposeOfVisit: formik.values.purposeOfVisit,
  //     custProgramsAttended: formik.values.attendedPrograms,
  //     custEmergencyContactName: formik.values.emergencyContactName,
  //     custEmergencyContact: formik.values.emergencyContact,
  //     custETA: "",
  //     custETD: "",
  //     custOCOCheckin: formik.values.ocoCheckIn,
  //   };
  //   let tempRoomData = roomWiseGuestData[ind];
  //   tempRoomData["primaryGuestData"] = updatedPrimaryGuestData;
  //   const updatedRoomData = roomWiseGuestData.map((roomDetail, index) => {
  //     if (index == ind) {
  //       return tempRoomData;
  //     } else {
  //       return roomDetail;
  //     }
  //   });
  //   setRoomWiseGuestData(updatedRoomData);
  // };

  // const clearAllPrimaryData = (ind) => {
  //   let updatedPrimaryGuestData = {
  //     customerName: "",
  //     customerEmail: "",
  //     customerPhone: "",
  //     customerGender: "Male",
  //     customerDOB: "",
  //     custGovtIDType: "",
  //     custGovtIDValue: "",
  //     custAddress1: "",
  //     custAddress2: "",
  //     custZipcode: "",
  //     custCountry: "",
  //     custState: "",
  //     custCity: "",
  //     custNationality: "",
  //     custOccupation: "",
  //     custCompany: "",
  //     custApprovedByOverseas: "New",
  //     custPurposeOfVisit: "",
  //     custProgramsAttended: "",
  //     custEmergencyContactName: "",
  //     custEmergencyContact: "",
  //     custETA: "",
  //     custETD: "",
  //     custOCOCheckin: "No",
  //   };
  //   let tempRoomData = roomWiseGuestData[ind];
  //   tempRoomData["primaryGuestData"] = updatedPrimaryGuestData;
  //   const updatedRoomData = roomWiseGuestData.map((roomDetail, index) => {
  //     if (index == ind) {
  //       return tempRoomData;
  //     } else {
  //       return roomDetail;
  //     }
  //   });
  //   setRoomWiseGuestData(updatedRoomData);
  // };

  // const toggleSameAsPrimary = (ind) => {
  //   let new_val = !sameDataCheck[ind];
  //   console.log("new value for ind: ", ind, " val: ", new_val);
  //   if (new_val === true) {
  //     performCopyOfPrimaryData(ind);
  //   } else {
  //     clearAllPrimaryData(ind);
  //   }
  //   updateSameDataCheck(ind, new_val);
  // };

  //Passport image - isha requirement
  const [passportImageUpload, setPassportImageUpload] = useState(["Camera"]);
  const updatePassportImageUpload = (ind, val) => {
    let updatedArr = passportImageUpload.map((image, index) => {
      if (index === ind) {
        return val;
      } else {
        return image;
      }
    });
    setPassportImageUpload(updatedArr);
  };

  const [showPassportModal, setShowPassportModal] = useState([]);
  const updateShowPassportModal = (ind, val) => {
    let updatedArr = showPassportModal.map((image, index) => {
      if (index === ind) {
        return val;
      } else {
        return image;
      }
    });
    setShowPassportModal(updatedArr);
  };

  const handleClosePassportModal = (index) =>
    updateShowPassportModal(index, false);
  const handleOpenPassportModal = (index) =>
    updateShowPassportModal(index, true);

  const [passportImage, setPassportImage] = useState([]);
  const updatePassportImage = (ind, val) => {
    let updatedArr = passportImage.map((image, index) => {
      if (index === ind) {
        return val;
      } else {
        return image;
      }
    });
    setPassportImage(updatedArr);
  };

  const updatePassportImages = (ind, val) => {
    let updatedArr = passportImages.map((image, index) => {
      if (index === ind) {
        return val;
      } else {
        return image;
      }
    });
    setPassportImages(updatedArr);
  };

  const passportCapture = React.useCallback(
    (ind) => {
      const imageSrc = webcamRef.current.getScreenshot();
      updatePassportImage(ind, imageSrc);
    },
    [webcamRef, passportImage]
  );
  const [passportImages, setPassportImages] = useState([]);

  //Add attachments
  const [document1, setDocument1] = useState("");
  const [document2, setDocument2] = useState("");
  const handleSetDocumentState = (name, docType, base64) => {
    switch (name) {
      case "document1":
        setDocument1({ data: base64, type: docType });
        break;
      case "document2":
        setDocument2({ data: base64, type: docType });
        break;
      default:
      // code block
    }
  };

  const [documentList1, setDocumentList1] = useState([]);
  const updateDocList1 = (roomInd, val) => {
    let updatedArr = documentList1.map((image, index) => {
      if (index === roomInd) {
        return val;
      } else {
        return image;
      }
    });
    setDocumentList1(updatedArr);
  };
  const [documentList2, setDocumentList2] = useState([]);
  const updateDocList2 = (roomInd, val) => {
    let updatedArr = documentList2.map((image, index) => {
      if (index === roomInd) {
        return val;
      } else {
        return image;
      }
    });
    setDocumentList2(updatedArr);
  };
  const handleSetDocumentData = (roomInd, name, docType, base64) => {
    switch (name) {
      case roomInd + "document1":
        updateDocList1(roomInd, { data: base64, type: docType });
        break;
      case roomInd + "document2":
        updateDocList2(roomInd, { data: base64, type: docType });
        break;
      default:
      // code block
    }
  };

  const [primaryGuestDocuments, setPrimaryGuestDocuments] = useState([]);
  useEffect(() => {
    if (newRoomWiseData?.length <= 0) {
      return;
    }
    //loop through all room details to fetch the primary guest email and set the front images data
    let primaryDocList = [];
    let dummy_email = newRoomWiseData[0].primaryGuestData?.customerEmail?.value;
    if (dummy_email === "") {
      dummy_email =
        newRoomWiseData[0].primaryGuestData?.customerName?.value
          .replace(/\s+/g, ".")
          .toLowerCase() +
        data?.hotelId +
        Math.round(Date.now() / 1000) +
        "@example.com";
      if (documentList1[0] !== "" || documentList2[0] !== "") {
        formik.setFieldValue("customer_email", dummy_email);
      }
    }
    // let document_payload = {
    //   emailId: formik.values.customer_email,
    //   hotelId: data?.hotelId,
    // };
    // if (document1?.data) {
    //   document_payload["attachmentOne"] = {
    //     base64Data: document1?.data,
    //     dataType: document1?.type,
    //   };
    // }
    // if (document2?.data) {
    //   document_payload["attachmentTwo"] = {
    //     base64Data: document2?.data,
    //     dataType: document2?.type,
    //   };
    // }
    let document_payload = {
      emailId: dummy_email,
      hotelId: data?.hotelId,
    };
    if (documentList1[0]?.data) {
      document_payload["attachmentOne"] = {
        base64Data: documentList1[0]?.data,
        dataType: documentList1[0]?.type,
      };
    }
    if (documentList2[0]?.data) {
      document_payload["attachmentTwo"] = {
        base64Data: documentList2[0]?.data,
        dataType: documentList2[0]?.type,
      };
    }
    if (documentList1[0]?.data || documentList2[0]?.data) {
      primaryDocList.push(document_payload);
    }

    newRoomWiseData.map((roomDetail, roomInd) => {
      if (roomInd !== 0) {
        let user_email = roomDetail?.primaryGuestData?.customerEmail?.value;
        let user_name = roomDetail?.primaryGuestData?.customerName?.value;
        if (user_email === "") {
          user_email =
            user_name.replace(/\s+/g, ".").toLowerCase() +
            data?.hotelId +
            Math.round(Date.now() / 1000) +
            "@example.com";
        }
        let document_payload = {
          emailId: user_email,
          hotelId: data?.hotelId,
        };
        if (documentList1[roomInd]?.data) {
          document_payload["attachmentOne"] = {
            base64Data: documentList1[0]?.data,
            dataType: documentList1[0]?.type,
          };
        }
        if (documentList2[roomInd]?.data) {
          document_payload["attachmentTwo"] = {
            base64Data: documentList2[0]?.data,
            dataType: documentList2[0]?.type,
          };
        }
        if (documentList1[roomInd]?.data || documentList2[roomInd]?.data) {
          // performPrimaryGuestUpdate(roomInd, "customerEmail", user_email);
          newRoomWiseData[roomInd].primaryGuestData.customerEmail.value =
            user_email;
          primaryDocList.push(document_payload);
        }
      }
    });
    setPrimaryGuestDocuments(primaryDocList);
    updatePrimaryGuestDocuments(primaryDocList);
  }, [documentList1, documentList2]);

  //Converting documents to base64
  const convertToBase64 = (roomInd, eve) => {
    const file = eve.target.files[0];
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
      .then((data) => {
        let type = data.split("/")[1].split(";")[0];
        if (type === "pdf") {
          type = "application/pdf";
        } else {
          type = "image/" + type;
        }
        const base64 = data.split(",")[1];
        handleSetDocumentData(roomInd, eve.target.name, type, base64);
      })
      .catch((err) => console.log("error caught : ", err));
  };

  // Create a reference to the hidden file input element
  const [hiddenFileInput1, setHiddenFileInput1] = useState([]);
  const [hiddenFileInput2, setHiddenFileInput2] = useState([]);

  //Add accompanying guest
  const [addGuestState, setAddGuestState] = useState([]);
  const toggleAddGuestDrawer =
    (anchor, open, roomInd, index) => async (event) => {
      if (
        event?.type === "keydown" &&
        (event?.key === "Tab" || event?.key === "Shift")
      ) {
        return;
      }
      // Updating add guest state
      const updatedArr = addGuestState.map((anchorList, ind) => {
        if (roomInd == ind) {
          const updatedRoomState = anchorList.map((anchorData, guestInd) => {
            if (index == guestInd) {
              const updatedState = { ...anchorData, [anchor]: open };
              return updatedState;
            }
            return anchorData;
          });
          return updatedRoomState;
        } else {
          return anchorList;
        }
      });
      setAddGuestState(updatedArr);
    };

  const addOtherGuestData = (guestData, roomInd) => {
    let tempRoomData = newRoomWiseData[roomInd];
    let tempOtherData = tempRoomData?.otherGuestData;
    tempOtherData.push(guestData);
    tempRoomData["otherGuestData"] = tempOtherData;
    const updatedRoomData = newRoomWiseData.map((roomDetail, index) => {
      if (index == roomInd) {
        return tempRoomData;
      } else {
        return roomDetail;
      }
    });
    setNewRoomWiseData(updatedRoomData);
  };

  const updateOtherGuestData = (guestData, roomInd, index) => {
    let tempRoomData = newRoomWiseData[roomInd];
    let tempArr = tempRoomData?.otherGuestData;
    const updatedArr = tempArr.map((roomGuestData, ind) => {
      if (ind === index) {
        return guestData;
      } else {
        return roomGuestData;
      }
    });
    tempRoomData["otherGuestData"] = updatedArr;
    const updatedRoomData = newRoomWiseData.map((roomDetail, index) => {
      if (index == roomInd) {
        return tempRoomData;
      } else {
        return roomDetail;
      }
    });
    setNewRoomWiseData(updatedRoomData);
  };

  const addGuestDetail = (anchor, roomInd, index) => {
    const defaultAddressDetail = {
      address1: newRoomWiseData[0].primaryGuestData?.custAddress1?.value,
      address2: newRoomWiseData[0].primaryGuestData?.custAddress2?.value,
      zipcode: newRoomWiseData[0].primaryGuestData?.custZipcode?.value,
      city: newRoomWiseData[0].primaryGuestData?.custCity?.value,
      state: newRoomWiseData[0].primaryGuestData?.custState?.value,
      country: newRoomWiseData[0].primaryGuestData?.custCountry?.value,
    };

    return (
      <Box
        sx={{ width: "100%" }}
        // role="presentation"
      >
        <div className="folioUpdateWrapper">
          <div className="folioInformationActionWrapper">
            <div className="folioInformation">
              <div className="fId">
                Room {roomInd + 1} add guest {index + 2} details
              </div>
            </div>
            <div
              className="closeToggleDrawer"
              onClick={toggleAddGuestDrawer("right", false, roomInd, index)}
            >
              <AiOutlineClose size="20px" color="black" />
            </div>
          </div>
          <Divider />

          {newRoomWiseData?.length > roomInd &&
          newRoomWiseData[roomInd]?.otherGuestData?.length > index ? (
            <NewGuestView
              onClose={toggleAddGuestDrawer(anchor, false, roomInd, index)}
              addGuestFunc={addOtherGuestData}
              updateGuestFunc={updateOtherGuestData}
              existingData={newRoomWiseData[roomInd]?.otherGuestData}
              roomIndex={roomInd}
              dataIndex={index}
              email={
                newRoomWiseData[roomInd]?.otherGuestData[index]?.guestEmail
              }
              primaryAddress={defaultAddressDetail}
              existingIdProofData={otherGuestIdProofs}
              addIdProof={addGuestIdProof}
              updateIdProof={updateGuestIdProof}
              existingDocumentsData={otherGuestDocuments}
              addDocumentData={addOtherGuestDocuments}
              updateDocumentData={updateOtherGuestDocuments}
              checkin={checkin}
              roomDetail={newRoomWiseData[roomInd]}
              checkout={checkout}
            />
          ) : (
            <NewGuestView
              onClose={toggleAddGuestDrawer(anchor, false, roomInd, index)}
              addGuestFunc={addOtherGuestData}
              updateGuestFunc={updateOtherGuestData}
              existingData={newRoomWiseData[roomInd]?.otherGuestData}
              roomIndex={roomInd}
              dataIndex={index}
              email={
                newRoomWiseData[roomInd]?.otherGuestData[index]?.guestEmail
              }
              primaryAddress={defaultAddressDetail}
              existingIdProofData={otherGuestIdProofs}
              addIdProof={addGuestIdProof}
              updateIdProof={updateGuestIdProof}
              existingDocumentsData={otherGuestDocuments}
              addDocumentData={addOtherGuestDocuments}
              updateDocumentData={updateOtherGuestDocuments}
              checkin={checkin}
              roomDetail={newRoomWiseData[roomInd]}
              checkout={checkout}
            />
          )}
        </div>
      </Box>
    );
  };

  // STORING CAMERA/DEVICE UPLOAD OF OTHER GUESTS
  const [otherGuestIdProofs, setOtherGuestIdProofs] = useState([]);

  const addGuestIdProof = (idProof) => {
    let tempArr = [...otherGuestIdProofs];
    tempArr.push(idProof);
    setOtherGuestIdProofs(tempArr);
    updateOtherGuestIdProofs(tempArr);
  };

  const updateGuestIdProof = (idProof, email) => {
    const updatedArr = otherGuestIdProofs.map((idData, index) => {
      if (idData?.emailId === email) {
        const updatedState = idProof;
        return updatedState;
      } else {
        return idData;
      }
    });
    setOtherGuestIdProofs(updatedArr);
    updateOtherGuestIdProofs(updatedArr);
  };

  //Guest documents
  const [otherGuestDocuments, setOtherGuestDocuments] = useState([]);
  const addOtherGuestDocuments = (documents) => {
    let tempArr = [...otherGuestDocuments];
    tempArr.push(documents);
    setOtherGuestDocuments(tempArr);
    updateOtherGuestDocumentsFunc(tempArr);
  };
  const updateOtherGuestDocuments = (documents, email) => {
    const updatedArr = otherGuestDocuments.map((docData, index) => {
      if (docData?.emailId === email) {
        const updatedState = documents;
        return updatedState;
      } else {
        return docData;
      }
    });
    setOtherGuestDocuments(updatedArr);
    updateOtherGuestDocumentsFunc(updatedArr);
  };

  const [addChildState, setAddChildState] = useState([]);
  const toggleAddChildDrawer =
    (anchor, open, roomInd, index) => async (event) => {
      if (
        event?.type === "keydown" &&
        (event?.key === "Tab" || event?.key === "Shift")
      ) {
        return;
      }
      // Updating add guest state
      const updatedArr = addChildState.map((anchorList, ind) => {
        if (roomInd == ind) {
          const updatedRoomState = anchorList.map((anchorData, guestInd) => {
            if (index == guestInd) {
              const updatedState = { ...anchorData, [anchor]: open };
              return updatedState;
            }
            return anchorData;
          });
          return updatedRoomState;
        } else {
          return anchorList;
        }
      });
      setAddChildState(updatedArr);
    };

  const addChildGuestData = (guestData, roomInd) => {
    let tempRoomData = newRoomWiseData[roomInd];
    let tempOtherData = tempRoomData?.childGuestData;
    tempOtherData.push(guestData);
    tempRoomData["childGuestData"] = tempOtherData;
    const updatedRoomData = newRoomWiseData.map((roomDetail, index) => {
      if (index == roomInd) {
        return tempRoomData;
      } else {
        return roomDetail;
      }
    });
    setNewRoomWiseData(updatedRoomData);
  };

  const updateChildGuestData = (guestData, roomInd, index) => {
    let tempRoomData = newRoomWiseData[roomInd];
    let tempArr = tempRoomData?.childGuestData;
    const updatedArr = tempArr.map((roomGuestData, ind) => {
      if (ind === index) {
        return guestData;
      } else {
        return roomGuestData;
      }
    });
    tempRoomData["childGuestData"] = updatedArr;
    const updatedRoomData = newRoomWiseData.map((roomDetail, index) => {
      if (index == roomInd) {
        return tempRoomData;
      } else {
        return roomDetail;
      }
    });
    setNewRoomWiseData(updatedRoomData);
  };
  const addChildDetail = (anchor, roomInd, index) => {
    return (
      <Box
        sx={{ width: "100%" }}
        // role="presentation"
      >
        <div className="folioUpdateWrapper">
          <div className="folioInformationActionWrapper">
            <div className="folioInformation">
              <div className="fId">
                Room {roomInd + 1} Add child {index + 1} details
              </div>
            </div>
            <div
              className="closeToggleDrawer"
              onClick={toggleAddChildDrawer("right", false, roomInd, index)}
            >
              <AiOutlineClose size="20px" color="black" />
            </div>
          </div>
          <Divider />

          {newRoomWiseData?.length > roomInd &&
          newRoomWiseData[roomInd]?.childGuestData?.length > index ? (
            <NewChildView
              onClose={toggleAddChildDrawer(anchor, false, roomInd, index)}
              existingChildDetails={newRoomWiseData[roomInd]?.childGuestData}
              addChildDetail={addChildGuestData}
              updateChildDetail={updateChildGuestData}
              dataIndex={index}
              roomIndex={roomInd}
            />
          ) : (
            <NewChildView
              onClose={toggleAddChildDrawer(anchor, false, roomInd, index)}
              existingChildDetails={newRoomWiseData[roomInd]?.childGuestData}
              addChildDetail={addChildGuestData}
              updateChildDetail={updateChildGuestData}
              dataIndex={index}
              roomIndex={roomInd}
            />
          )}
        </div>
      </Box>
    );
  };

  //Autofill option for Group booking
  const [customerNameData, setcustomerNameData] = useState([]);
  const [customerEmailData, setcustomerEmailData] = useState([]);
  const [customerPhoneData, setcustomerPhoneData] = useState([]);
  const [showData, setShowData] = useState([]);

  useEffect(() => {
    console.log("show data: ", showData);
  }, [showData]);

  const updateCustomerNameData = (roomInd, data) => {
    const updatedNameData = customerNameData.map((nameData, index) => {
      if (index == roomInd) {
        return data;
      } else {
        return nameData;
      }
    });
    setcustomerNameData(updatedNameData);
  };

  const updateCustomerEmailData = (roomInd, data) => {
    const updatedEmailData = customerEmailData.map((emailData, index) => {
      if (index == roomInd) {
        return data;
      } else {
        return emailData;
      }
    });
    setcustomerEmailData(updatedEmailData);
  };

  const updateCustomerPhoneData = (roomInd, data) => {
    const updatedPhoneData = customerPhoneData.map((phoneData, index) => {
      if (index == roomInd) {
        return data;
      } else {
        return phoneData;
      }
    });
    setcustomerPhoneData(updatedPhoneData);
  };

  const updateShowData = (roomInd, val) => {
    const updatedShowData = showData.map((showVal, index) => {
      if (index == roomInd) {
        return val;
      } else {
        return showVal;
      }
    });
    setShowData(updatedShowData);
  };

  //For primary group owner
  // useEffect(async () => {
  //   let timeoutId;
  //   if (formik.values.customer_name) {
  //     timeoutId = setTimeout(
  //       () => customerElasticSearch(0, formik.values.customer_name, "name"),
  //       1000
  //     );
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [formik.values.customer_name]);
  // useEffect(async () => {
  //   handleInputChangeDebounced(0, formik.values.customer_name, "name");
  //   // if (formik.values.customer_name !== "") {
  //   //   const data = await getAllCustomers(formik.values.customer_name, "name");
  //   //   const sortedArr = data.reduce((acc, element) => {
  //   //     if (element.donotRent === "YES") {
  //   //       return [element, ...acc];
  //   //     }
  //   //     return [...acc, element];
  //   //   }, []);
  //   //   updateCustomerNameData(0, sortedArr);
  //   // }
  //   // if (formik.values.customer_name.length === 1) {
  //   //   updateCustomerNameData(0, []);
  //   // }
  //   // if (formik.values.customer_name === "") {
  //   //   updateShowData(0, true);
  //   // }
  // }, [formik.values.customer_name, handleInputChangeDebounced]);

  // useEffect(async () => {
  //   if (formik.values.customer_email !== "") {
  //     const data = await getAllCustomers(formik.values.customer_email, "email");
  //     const sortedArr = data.reduce((acc, element) => {
  //       if (element.donotRent === "YES") {
  //         return [element, ...acc];
  //       }
  //       return [...acc, element];
  //     }, []);
  //     updateCustomerEmailData(0, sortedArr);
  //   }
  //   if (formik.values.customer_email?.length === 1) {
  //     updateCustomerEmailData(0, []);
  //   }
  //   if (formik.values.customer_email === "") {
  //     updateShowData(0, true);
  //   }
  // }, [formik.values.customer_email, handleInputChangeDebounced]);

  // useEffect(async () => {
  //   if (formik.values.customer_phone !== "") {
  //     const data = await getAllCustomers(
  //       formik.values.customer_phone,
  //       "contact"
  //     );
  //     const sortedArr = data.reduce((acc, element) => {
  //       if (element.donotRent === "YES") {
  //         return [element, ...acc];
  //       }
  //       return [...acc, element];
  //     }, []);
  //     updateCustomerPhoneData(0, sortedArr);
  //   }
  //   if (formik.values.customer_phone.length === 1) {
  //     updateCustomerNameData(0, []);
  //   }
  //   if (formik.values.customer_phone === "") {
  //     updateShowData(0, true);
  //   }
  // }, [formik.values.customer_phone]);

  //For other rooms primary guest
  const customerElasticSearch = async (roomInd, value, type) => {
    if (value !== "") {
      const data = await getAllCustomers(value, type);
      const sortedArr = data.reduce((acc, element) => {
        if (element.donotRent === "YES") {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      if (type === "email") {
        updateCustomerEmailData(roomInd, sortedArr);
      } else if (type === "name") {
        updateCustomerNameData(roomInd, sortedArr);
      } else if (type === "phone") {
        updateCustomerPhoneData(roomInd, sortedArr);
      }
    }
    if (value?.length === 1) {
      if (type === "email") {
        updateCustomerEmailData(roomInd, []);
      } else if (type === "name") {
        updateCustomerNameData(roomInd, []);
      } else if (type === "phone") {
        updateCustomerPhoneData(roomInd, []);
      }
    }
    if (value === "") {
      updateShowData(roomInd, true);
    }
  };

  // const populateCustomerData = (roomInd, item) => {
  //   let updatedPrimaryGuestData = {
  //     customerName: item.name,
  //     customerEmail: item.email,
  //     customerPhone: item.contact,
  //     customerGender:
  //       item?.gender != null && item?.gender != "NA" ? item.gender : "Male",
  //     customerDOB:
  //       item?.dateOfBirth && item?.dateOfBirth != "NA" ? item?.dateOfBirth : "",
  //     custGovtIDType: item?.govtIdType ? item.govtIdType : "",
  //     custGovtIDValue: item?.govtIdValue ? item.govtIdValue : "",
  //     custAddress1: item?.addressLine1 ? item?.addressLine1 : "",
  //     custAddress2: item?.addressLine2 ? item?.addressLine2 : "",
  //     custZipcode: item?.zipcode && item?.zipcode != "NA" ? item?.zipcode : "",
  //     custCountry: item?.country && item?.country != "NA" ? item?.country : "",
  //     custState: item?.state && item?.state != "NA" ? item.state : "",
  //     custCity: item?.city && item?.city != "NA" ? item.city : "",
  //     custNationality: item?.nationality ? item?.nationality : "",
  //     custOccupation: item?.occupation ? item?.occupation : "",
  //     custCompany: item?.companyName ? item?.companyName : "",
  //     custApprovedByOverseas: "New",
  //     custPurposeOfVisit: "",
  //     custProgramsAttended: "",
  //     custEmergencyContactName: "",
  //     custEmergencyContact: "",
  //     custETA: "",
  //     custETD: "",
  //     custOCOCheckin: "No",
  //   };
  //   let tempRoomData = roomWiseGuestData[roomInd];
  //   tempRoomData["primaryGuestData"] = updatedPrimaryGuestData;
  //   const updatedRoomData = roomWiseGuestData.map((roomDetail, index) => {
  //     if (index == roomInd) {
  //       return tempRoomData;
  //     } else {
  //       return roomDetail;
  //     }
  //   });
  //   setRoomWiseGuestData(updatedRoomData);
  //   updateCustomerEmailData(roomInd, []);
  //   updateCustomerNameData(roomInd, []);
  //   updateCustomerPhoneData(roomInd, []);
  //   updateShowData(roomInd, false);
  // };

  // const populateCustomerFields = (item) => {
  //   formik.setFieldValue("customer_name", item.name);
  //   formik.setFieldValue("customer_phone", item.contact);
  //   formik.setFieldValue("customer_email", item.email);
  //   //Gender
  //   if (item?.gender != null && item?.gender != "NA") {
  //     formik.setFieldValue("gender", item?.gender);
  //   } else {
  //     formik.setFieldValue("gender", "Male");
  //   }
  //   //DOB
  //   if (item?.dateOfBirth && item?.dateOfBirth != "NA") {
  //     formik.setFieldValue("customer_dob", item?.dateOfBirth);
  //   } else {
  //     formik.setFieldValue("customer_dob", "");
  //   }
  //   //govtIdType
  //   if (item?.govtIdType) {
  //     formik.setFieldValue("govtIdType".item?.govtIdType);
  //   } else {
  //     formik.setFieldValue("govtIdType", "");
  //   }
  //   //govtIdValue
  //   if (item?.govtIdValue) {
  //     formik.setFieldValue("govtIdValue", item?.govtIdValue);
  //   } else {
  //     formik.setFieldValue("govtIdValue", "");
  //   }
  //   //addressLine1
  //   if (item?.addressLine1) {
  //     formik.setFieldValue("address1", item?.addressLine1);
  //   } else {
  //     formik.setFieldValue("address1", "");
  //   }
  //   //addressLine2
  //   if (item?.addressLine2) {
  //     formik.setFieldValue("address2", item?.addressLine2);
  //   } else {
  //     formik.setFieldValue("address2", "");
  //   }
  //   //city
  //   if (item?.city && item?.city != "NA") {
  //     formik.setFieldValue("city", item?.city);
  //   } else {
  //     formik.setFieldValue("city", "");
  //   }
  //   //state
  //   if (item?.state && item?.state != "NA") {
  //     formik.setFieldValue("state", item?.state);
  //   }
  //   //Country
  //   if (item?.country && item?.country != "NA") {
  //     formik.setFieldValue("country", item?.country);
  //   }
  //   //Zipcode
  //   if (item?.zipcode && item?.zipcode != "NA") {
  //     formik.setFieldValue("zipcode", item?.zipcode);
  //   }
  //   //nationality
  //   if (item?.nationality) {
  //     formik.setFieldValue("nationality", item?.nationality);
  //   }
  //   //occupation
  //   if (item?.occupation) {
  //     formik.setFieldValue("occupation", item?.occupation);
  //   }
  //   //company name
  //   if (item?.companyName) {
  //     formik.setFieldValue("companyName", item?.companyName);
  //   }
  //   updateCustomerEmailData(0, []);
  //   updateCustomerNameData(0, []);
  //   updateCustomerPhoneData(0, []);
  //   updateShowData(0, false);
  // };

  const emptyValuesRoom = (roomInd) => {
    updateCustomerEmailData(roomInd, []);
    updateCustomerNameData(roomInd, []);
    updateCustomerPhoneData(roomInd, []);
  };

  //main useeffect to initialize all values
  // useEffect(() => {
  //   let tempData = [];
  //   let tempImageUploadId = [];
  //   let tempImagesArray = [];
  //   let tempImageModalArray = [];
  //   let tempDeviceImagesArray = [];
  //   let tempSameCheck = [];
  //   const checkinDate = new Date(checkin);
  //   const checkoutDate = new Date(checkout);
  //   let tempETAList = [];
  //   let tempETDList = [];
  //   let tempAddGuestState = [];
  //   let tempAddChildState = [];
  //   let tempSelectedPrograms = [];
  //   let tempDocList = [];
  //   let tempRef1List = [];
  //   let tempRef2List = [];
  //   let showDataArray = [];
  //   let tempCustomerEmailData = [];
  //   datas.map((roomDetail) => {
  //     Object.keys(roomDetail.selectionData)
  //       .slice(0, roomDetail.roomCount)
  //       .map((selectionCount) => {
  //         let eachRoomTemp = [];
  //         let eachChildTemp = [];
  //         let tempRoomWise = {
  //           roomTypeName: roomDetail.original.roomTypeName,
  //           roomTypeId: roomDetail.roomTypeId,
  //           ratePlanName: roomDetail.ratePlan.ratePlanName,
  //           ratePlanId: roomDetail.ratePlan.ratePlanId,
  //           adults: roomDetail.selectionData[Number(selectionCount)].A,
  //           children: roomDetail.selectionData[Number(selectionCount)].C,
  //           primaryGuestData: {
  //             customerName: "",
  //             customerEmail: "",
  //             customerPhone: "",
  //             customerGender: "Male",
  //             customerDOB: "",
  //             custGovtIDType: "",
  //             custGovtIDValue: "",
  //             custAddress1: "",
  //             custAddress2: "",
  //             custZipcode: "",
  //             custCountry: "",
  //             custState: "",
  //             custCity: "",
  //             custNationality: "",
  //             custOccupation: "",
  //             custCompany: "",
  //             custApprovedByOverseas: "New",
  //             custPurposeOfVisit: "",
  //             custProgramsAttended: "",
  //             custEmergencyContactName: "",
  //             custEmergencyContact: "",
  //             custETA: moment(checkinDate).format("YYYY-MM-DD HH:mm:ss"),
  //             custETD: moment(checkoutDate).format("YYYY-MM-DD HH:mm:ss"),
  //             custOCOCheckin: "No",
  //           },
  //           otherGuestData: [],
  //           childGuestData: [],
  //         };
  //         let totalInsertion = Number(tempRoomWise?.adults) - 1;
  //         while (totalInsertion > 0) {
  //           eachRoomTemp.push({ right: false });
  //           totalInsertion = totalInsertion - 1;
  //         }
  //         let totalChildInsertion = Number(tempRoomWise?.children);
  //         while (totalChildInsertion > 0) {
  //           eachChildTemp.push({ right: false });
  //           totalChildInsertion = totalChildInsertion - 1;
  //         }

  //         tempImageUploadId.push("Camera");
  //         tempImageModalArray.push(false);
  //         tempImagesArray.push(undefined);
  //         tempData.push(tempRoomWise);
  //         tempDeviceImagesArray.push([]);
  //         tempSameCheck.push(false);
  //         tempETAList.push(checkinDate);
  //         tempETDList.push(checkoutDate);
  //         tempAddGuestState.push(eachRoomTemp);
  //         tempAddChildState.push(eachChildTemp);
  //         tempSelectedPrograms.push(["-"]);
  //         tempDocList.push("");
  //         tempRef1List.push(null);
  //         tempRef2List.push(null);
  //         showDataArray.push(true);
  //         tempCustomerEmailData.push([]);
  //       });
  //   });
  //   setshowFrontIdModalList(tempImageModalArray);
  //   setshowBackIdModalList(tempImageModalArray);
  //   setShowPassportModal(tempImageModalArray);
  //   setFrontImages(tempImagesArray);
  //   setBackImages(tempImagesArray);
  //   setimageUpload(tempImageUploadId);
  //   setPassportImageUpload(tempImageUploadId);
  //   setPassportImage(tempImagesArray);
  //   setFrontDeviceImages(tempDeviceImagesArray);
  //   setBackDeviceImages(tempDeviceImagesArray);
  //   setPassportImages(tempDeviceImagesArray);
  //   setRoomWiseGuestData(tempData);
  //   setSameDataCheck(tempSameCheck);
  //   setAllETA(tempETAList);
  //   setAllETD(tempETDList);
  //   setAddGuestState(tempAddGuestState);
  //   setAddChildState(tempAddChildState);
  //   setSelectedPrograms(tempSelectedPrograms);
  //   setDocumentList1(tempDocList);
  //   setDocumentList2(tempDocList);
  //   setHiddenFileInput1(tempRef1List);
  //   setHiddenFileInput2(tempRef2List);
  //   setShowData(showDataArray);
  //   setcustomerEmailData(tempCustomerEmailData);
  //   setcustomerNameData(tempCustomerEmailData);
  //   setcustomerPhoneData(tempCustomerEmailData);
  // }, []);

  useEffect(() => {
    if (newRoomWiseData?.length <= 0) {
      return;
    }
    //loop through all room details to fetch the primary guest email and set the front images data
    let idProofList = [];
    let dummy_email = newRoomWiseData[0].primaryGuestData?.customerEmail?.value;
    if (dummy_email === "") {
      dummy_email =
        newRoomWiseData[0].primaryGuestData?.customerName?.value
          .replace(/\s+/g, ".")
          .toLowerCase() +
        data?.hotelId +
        Math.round(Date.now() / 1000) +
        "@example.com";
      if (
        frontImages[0] !== undefined ||
        frontDeviceImages[0]?.length > 0 ||
        backImages[0] !== undefined ||
        backDeviceImages[0]?.length > 0 ||
        passportImage[0] !== undefined ||
        passportImages[0]?.length > 0
      ) {
        formik.setFieldValue("customer_email", dummy_email);
      }
    }
    //Need to update the code to not render everytime - for compression
    let userIdPayload = {
      emailId: dummy_email,
      frontData:
        frontImages[0] !== undefined
          ? frontImages[0].split(",")[1]
          : frontDeviceImages[0] !== undefined
          ? frontDeviceImages[0][1]
          : null,
      frontType:
        frontImages[0] !== undefined
          ? "jpeg"
          : frontDeviceImages[0] !== undefined
          ? frontDeviceImages[0][0]
          : null,
      backData:
        backImages[0] !== undefined
          ? backImages[0].split(",")[1]
          : backDeviceImages[0] !== undefined
          ? backDeviceImages[0][1]
          : null,
      backType:
        backImages[0] !== undefined
          ? "jpeg"
          : backDeviceImages[0] !== undefined
          ? backDeviceImages[0][0]
          : null,
      passportImageData:
        passportImage[0] != undefined
          ? passportImage[0].split(",")[1]
          : passportImages[0][0] !== undefined
          ? passportImages[0][0].data_url?.split(",")[1]
          : null,
      passportImageType:
        passportImage[0] != undefined
          ? "jpeg"
          : passportImages[0][0] !== undefined
          ? passportImages[0][0].file.type
          : null,
      hotelId: data?.hotelId,
    };
    if (
      frontImages[0] !== undefined ||
      frontDeviceImages[0]?.length > 0 ||
      backImages[0] !== undefined ||
      backDeviceImages[0]?.length > 0 ||
      passportImage[0] !== undefined ||
      passportImages[0]?.length > 0
    ) {
      idProofList.push(userIdPayload);
    }

    newRoomWiseData.map((roomDetail, roomInd) => {
      if (roomInd !== 0) {
        let user_email = roomDetail?.primaryGuestData?.customerEmail?.value;
        let user_name = roomDetail?.primaryGuestData?.customerName?.value;
        if (user_email === "") {
          user_email =
            user_name.replace(/\s+/g, ".").toLowerCase() +
            data?.hotelId +
            Math.round(Date.now() / 1000) +
            "@example.com";
        }
        const userIdPayload = {
          emailId: user_email,
          frontData:
            frontImages[roomInd] !== undefined
              ? frontImages[roomInd].split(",")[1]
              : frontDeviceImages[roomInd] !== undefined
              ? frontDeviceImages[roomInd][1]
              : null,
          frontType:
            frontImages[roomInd] !== undefined
              ? "jpeg"
              : frontDeviceImages[roomInd] !== undefined
              ? frontDeviceImages[roomInd][0]
              : null,
          backData:
            backImages[roomInd] !== undefined
              ? backImages[roomInd].split(",")[1]
              : backDeviceImages[roomInd] !== undefined
              ? backDeviceImages[roomInd][1]
              : null,
          backType:
            backImages[roomInd] !== undefined
              ? "jpeg"
              : backDeviceImages[roomInd] !== undefined
              ? backDeviceImages[roomInd][0]
              : null,
          passportImageData:
            passportImage[roomInd] != undefined
              ? passportImage[roomInd].split(",")[1]
              : passportImages[roomInd][0] !== undefined
              ? passportImages[roomInd][0].data_url?.split(",")[1]
              : null,
          passportImageType:
            passportImage[roomInd] != undefined
              ? "jpeg"
              : passportImages[roomInd][0] !== undefined
              ? passportImages[roomInd][0].file.type
              : null,
          hotelId: data?.hotelId,
        };
        if (
          frontImages[roomInd] !== undefined ||
          frontDeviceImages[roomInd]?.length > 0 ||
          backImages[roomInd] !== undefined ||
          backDeviceImages[roomInd]?.length > 0 ||
          passportImage[roomInd] !== undefined ||
          passportImages[roomInd]?.length > 0
        ) {
          // performPrimaryGuestUpdate(roomInd, "customerEmail", user_email);
          newRoomWiseData[roomInd].primaryGuestData.customerEmail.value =
            user_email;
          idProofList.push(userIdPayload);
        }
      }
    });
    updatePrimaryGuestIdProof(idProofList);
  }, [
    frontImages,
    frontDeviceImages,
    backImages,
    backDeviceImages,
    passportImage,
    passportImages,
  ]);
  const showRefContent = () => {
    printRefData();
  };

  const [newRoomWiseData, setNewRoomWiseData] = useState([]);
  const refPrimaryData = useRef({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    customerGender: "Male",
    customerDOB: "",
    custGovtIDType: "",
    custGovtIDValue: "",
    custAddress1: "",
    custAddress2: "",
    custZipcode: "",
    custCountry: "",
    custState: "",
    custCity: "",
    custNationality: "",
    custOccupation: "",
    custCompany: "",
    custApprovedByOverseas: "New",
    custPurposeOfVisit: "",
    custProgramsAttended: "",
    custEmergencyContactName: "",
    custEmergencyContact: "",
    custETA: moment(new Date(checkin)).format("YYYY-MM-DD HH:mm:ss"),
    custETD: moment(new Date(checkout)).format("YYYY-MM-DD HH:mm:ss"),
    custOCOCheckin: "No",
  });

  const [refDOBArray, setRefDOBArray] = useState([]);
  const [refShowDataArray, setRefShowDataArray] = useState([]);
  const [custCountryData, setCustCountryData] = useState([]);
  const updateCountryArray = (roomInd, country) => {
    const updatedArr = custCountryData.map((coun, index) => {
      if (roomInd === index) {
        return country?.target?.innerText;
      } else {
        return coun;
      }
    });
    setCustCountryData(updatedArr);
  };

  useEffect(() => {
    let tempArray = [];
    let tempDOBArray = [];
    let showDataArray = [];
    let tempImageUploadId = [];
    let tempImagesArray = [];
    let tempImageModalArray = [];
    let tempDeviceImagesArray = [];
    let tempSameCheck = [];
    const checkinDate = new Date(checkin);
    const checkoutDate = new Date(checkout);
    let tempETAList = [];
    let tempETDList = [];
    let tempAddGuestState = [];
    let tempAddChildState = [];
    let tempSelectedPrograms = [];
    let tempDocList = [];
    let tempRef1List = [];
    let tempRef2List = [];
    let tempCustomerEmailData = [];
    let tempCountryData = [];
    datas.map((roomDetail) => {
      Object.keys(roomDetail.selectionData)
        .slice(0, roomDetail.roomCount)
        .map((selectionCount) => {
          let eachRoomTemp = [];
          let eachChildTemp = [];
          let tempRoomWise = {
            roomTypeName: roomDetail.original.roomTypeName,
            roomTypeId: roomDetail.roomTypeId,
            ratePlanName: roomDetail.ratePlan.ratePlanName,
            ratePlanId: roomDetail.ratePlan.ratePlanId,
            adults: roomDetail.selectionData[Number(selectionCount)].A,
            children: roomDetail.selectionData[Number(selectionCount)].C,
            primaryGuestData: refPrimaryData,
            otherGuestData: [],
            childGuestData: [],
          };
          tempArray.push(tempRoomWise);
          let totalInsertion = Number(tempRoomWise?.adults) - 1;
          while (totalInsertion > 0) {
            eachRoomTemp.push({ right: false });
            totalInsertion = totalInsertion - 1;
          }
          let totalChildInsertion = Number(tempRoomWise?.children);
          while (totalChildInsertion > 0) {
            eachChildTemp.push({ right: false });
            totalChildInsertion = totalChildInsertion - 1;
          }
          tempCountryData.push(
            hotelCurrency === "INR" ? "India" : "United States"
          );
          tempImageUploadId.push("Camera");
          tempImageModalArray.push(false);
          tempImagesArray.push(undefined);
          tempDeviceImagesArray.push([]);
          tempSameCheck.push(false);
          tempETAList.push(checkinDate);
          tempETDList.push(checkoutDate);
          tempAddGuestState.push(eachRoomTemp);
          tempAddChildState.push(eachChildTemp);
          tempSelectedPrograms.push(["-"]);
          tempDocList.push("");
          tempRef1List.push(null);
          tempRef2List.push(null);
          showDataArray.push(true);
          tempCustomerEmailData.push([]);
          tempDOBArray.push("");
        });
    });
    setRefDOBArray(tempDOBArray);

    setRefShowDataArray(showDataArray);

    setNewRoomWiseData(tempArray);
    updateRefData(tempArray);

    setshowFrontIdModalList(tempImageModalArray);
    setshowBackIdModalList(tempImageModalArray);
    setShowPassportModal(tempImageModalArray);

    setFrontImages(tempImagesArray);
    setBackImages(tempImagesArray);
    setimageUpload(tempImageUploadId);

    setPassportImageUpload(tempImageUploadId);
    setPassportImage(tempImagesArray);

    setFrontDeviceImages(tempDeviceImagesArray);
    setBackDeviceImages(tempDeviceImagesArray);
    setPassportImages(tempDeviceImagesArray);

    setSameDataCheck(tempSameCheck);

    setAllETA(tempETAList);
    setAllETD(tempETDList);

    setAddGuestState(tempAddGuestState);
    setAddChildState(tempAddChildState);

    setSelectedPrograms(tempSelectedPrograms);

    setDocumentList1(tempDocList);
    setDocumentList2(tempDocList);

    setHiddenFileInput1(tempRef1List);
    setHiddenFileInput2(tempRef2List);

    setcustomerEmailData(tempCustomerEmailData);
    setcustomerNameData(tempCustomerEmailData);
    setcustomerPhoneData(tempCustomerEmailData);

    setCustCountryData(tempCountryData);
  }, []);

  const setRefDOBArrayIndex = (ind, val) => {
    try {
      const updatedDOBArray = refDOBArray.map((dob, index) => {
        if (index == ind) {
          return val;
        } else {
          return dob;
        }
      });
      setRefDOBArray(updatedDOBArray);
    } catch (e) {
      console.log(e);
    }
  };

  //Geocode addition
  const getGeoCodeValues = (zipcode, index) => {
    try {
      getGeoCodeDetails(zipcode).then((resp) => {
        const re = /^[0-9\b]+$/;
        //Cases where the city is not mentioned in response
        if (!re.test(resp.city)) {
          // if (index === 0) {
          //   formik.setFieldValue("city", resp.city);
          // } else {
          newRoomWiseData[index].primaryGuestData.custCity.value = resp.city;
          // performPrimaryGuestUpdate(index, "custCity", resp.city);
          // }
        } else {
          // if (index === 0) {
          //   formik.setFieldValue("city", "");
          // } else {
          newRoomWiseData[index].primaryGuestData.custCity.value = "";
          // performPrimaryGuestUpdate(index, "custCity", "");
          // }
        }
        // if (index === 0) {
        //   formik.setFieldValue("state", resp.state);
        //   formik.setFieldValue("country", resp.country);
        // } else {
        //newRoomWiseData[index].primaryGuestData.custState.value = resp.state;
        newRoomWiseData[index].primaryGuestData.custState.value = resp.state;
        newRoomWiseData[index].primaryGuestData.custCountry.value =
          resp.country;
        // performPrimaryGuestUpdate(index, "custState", resp.state);
        // performPrimaryGuestUpdate(index, "custCountry", resp.country);
        // }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputChangeDebounced = debounce((roomInd, inputValue, type) => {
    customerElasticSearch(roomInd, inputValue, type);
  }, 500);

  const populateRefCustomerData = (roomInd, item) => {
    newRoomWiseData[roomInd].primaryGuestData.customerName.value = item?.name;
    newRoomWiseData[roomInd].primaryGuestData.customerEmail.value = item?.email;
    newRoomWiseData[roomInd].primaryGuestData.customerPhone.value =
      item?.contact;
    newRoomWiseData[roomInd].primaryGuestData.customerGender.value =
      item?.gender != null && item?.gender != "NA" ? item.gender : "Male";
    newRoomWiseData[roomInd].primaryGuestData.customerDOB.value =
      item?.dateOfBirth && item?.dateOfBirth != "NA" ? item?.dateOfBirth : "";
    newRoomWiseData[roomInd].primaryGuestData.custGovtIDType.value =
      item?.govtIdType && item.govtIdType != "NA"
        ? item.govtIdType
        : "Passport";
    newRoomWiseData[roomInd].primaryGuestData.custGovtIDValue.value =
      item?.govtIdValue ? item.govtIdValue : "";
    newRoomWiseData[roomInd].primaryGuestData.custAddress1.value =
      item?.addressLine1 ? item.addressLine1 : "";
    newRoomWiseData[roomInd].primaryGuestData.custAddress2.value =
      item?.addressLine2 ? item.addressLine2 : "";
    newRoomWiseData[roomInd].primaryGuestData.custZipcode.value =
      item?.zipcode && item?.zipcode != "NA" ? item?.zipcode : "";
    newRoomWiseData[roomInd].primaryGuestData.custCountry.value =
      item?.country && item?.country != "NA" ? item?.country : "";
    newRoomWiseData[roomInd].primaryGuestData.custState.value =
      item?.state && item?.state != "NA" ? item.state : "";
    newRoomWiseData[roomInd].primaryGuestData.custCity.value =
      item?.city && item?.city != "NA" ? item.city : "";
    newRoomWiseData[roomInd].primaryGuestData.custNationality.value =
      item?.nationality ? item?.nationality : "";
    newRoomWiseData[roomInd].primaryGuestData.custOccupation.value =
      item?.occupation ? item?.occupation : "";
    if (hotelType === "ISHA") {
      newRoomWiseData[roomInd].primaryGuestData.custCompany.value =
        item?.companyName ? item?.companyName : "";
      newRoomWiseData[roomInd].primaryGuestData.custApprovedByOverseas.value =
        "New";
      newRoomWiseData[roomInd].primaryGuestData.custPurposeOfVisit.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custProgramsAttended.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custEmergencyContactName.value =
        "";
      newRoomWiseData[roomInd].primaryGuestData.custEmergencyContact.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custETA.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custETD.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custOCOCheckin.value = "No";
    }
    updateCustomerEmailData(roomInd, []);
    updateCustomerNameData(roomInd, []);
    updateCustomerPhoneData(roomInd, []);
    updateShowData(roomInd, false);
  };

  const setSameAsPrimaryData = (ind) => {
    let new_val = !sameDataCheck[ind];
    if (new_val === true) {
      copyPrimaryRefData(ind);
    } else {
      clearIndRefData(ind);
    }
    updateSameDataCheck(ind, new_val);
  };

  const copyPrimaryRefData = (roomInd) => {
    newRoomWiseData[roomInd].primaryGuestData.customerName.value =
      newRoomWiseData[0].primaryGuestData.customerName.value;
    newRoomWiseData[roomInd].primaryGuestData.customerEmail.value =
      newRoomWiseData[0].primaryGuestData.customerEmail.value;
    newRoomWiseData[roomInd].primaryGuestData.customerPhone.value =
      newRoomWiseData[0].primaryGuestData.customerPhone.value;
    newRoomWiseData[roomInd].primaryGuestData.customerGender.value =
      newRoomWiseData[0].primaryGuestData.customerGender.value;
    newRoomWiseData[roomInd].primaryGuestData.customerDOB.value =
      newRoomWiseData[0].primaryGuestData.customerDOB.value;
    newRoomWiseData[roomInd].primaryGuestData.custGovtIDType.value =
      newRoomWiseData[0].primaryGuestData.custGovtIDType.value;
    newRoomWiseData[roomInd].primaryGuestData.custGovtIDValue.value =
      newRoomWiseData[0].primaryGuestData.custGovtIDValue.value;
    newRoomWiseData[roomInd].primaryGuestData.custAddress1.value =
      newRoomWiseData[0].primaryGuestData.custAddress1.value;
    newRoomWiseData[roomInd].primaryGuestData.custAddress2.value =
      newRoomWiseData[0].primaryGuestData.custAddress2.value;
    newRoomWiseData[roomInd].primaryGuestData.custZipcode.value =
      newRoomWiseData[0].primaryGuestData.custZipcode.value;
    newRoomWiseData[roomInd].primaryGuestData.custCountry.value =
      newRoomWiseData[0].primaryGuestData.custCountry.value;
    newRoomWiseData[roomInd].primaryGuestData.custState.value =
      newRoomWiseData[0].primaryGuestData.custState.value;
    newRoomWiseData[roomInd].primaryGuestData.custCity.value =
      newRoomWiseData[0].primaryGuestData.custCity.value;
    newRoomWiseData[roomInd].primaryGuestData.custNationality.value =
      newRoomWiseData[0].primaryGuestData.custNationality.value;
    newRoomWiseData[roomInd].primaryGuestData.custOccupation.value =
      newRoomWiseData[0].primaryGuestData.custOccupation.value;
    if (hotelType === "ISHA") {
      newRoomWiseData[roomInd].primaryGuestData.custCompany.value =
        newRoomWiseData[0].primaryGuestData.custCompany.value;
      newRoomWiseData[roomInd].primaryGuestData.custApprovedByOverseas.value =
        newRoomWiseData[0].primaryGuestData.custApprovedByOverseas.value;
      newRoomWiseData[roomInd].primaryGuestData.custPurposeOfVisit.value =
        newRoomWiseData[0].primaryGuestData.custPurposeOfVisit.value;
      newRoomWiseData[roomInd].primaryGuestData.custProgramsAttended.value =
        newRoomWiseData[0].primaryGuestData.custProgramsAttended.value;
      newRoomWiseData[roomInd].primaryGuestData.custEmergencyContactName.value =
        newRoomWiseData[0].primaryGuestData.custEmergencyContactName.value;
      newRoomWiseData[roomInd].primaryGuestData.custEmergencyContact.value =
        newRoomWiseData[0].primaryGuestData.custEmergencyContact.value;
      newRoomWiseData[roomInd].primaryGuestData.custETA.value =
        newRoomWiseData[0].primaryGuestData.custETA.value;
      newRoomWiseData[roomInd].primaryGuestData.custETD.value =
        newRoomWiseData[0].primaryGuestData.custETD.value;
      newRoomWiseData[roomInd].primaryGuestData.custOCOCheckin.value =
        newRoomWiseData[0].primaryGuestData.custOCOCheckin.value;
    }
  };

  const clearIndRefData = (roomInd) => {
    newRoomWiseData[roomInd].primaryGuestData.customerName.value = "";
    newRoomWiseData[roomInd].primaryGuestData.customerEmail.value = "";
    newRoomWiseData[roomInd].primaryGuestData.customerPhone.value = "";
    newRoomWiseData[roomInd].primaryGuestData.customerGender.value = "Male";
    newRoomWiseData[roomInd].primaryGuestData.customerDOB.value = "";
    newRoomWiseData[roomInd].primaryGuestData.custGovtIDType.value = "";
    newRoomWiseData[roomInd].primaryGuestData.custGovtIDValue.value = "";
    newRoomWiseData[roomInd].primaryGuestData.custAddress1.value = "";
    newRoomWiseData[roomInd].primaryGuestData.custAddress2.value = "";
    newRoomWiseData[roomInd].primaryGuestData.custZipcode.value = "";
    newRoomWiseData[roomInd].primaryGuestData.custCountry.value = "";
    newRoomWiseData[roomInd].primaryGuestData.custState.value = "";
    newRoomWiseData[roomInd].primaryGuestData.custCity.value = "";
    newRoomWiseData[roomInd].primaryGuestData.custNationality.value = "";
    newRoomWiseData[roomInd].primaryGuestData.custOccupation.value = "";
    if (hotelType === "ISHA") {
      newRoomWiseData[roomInd].primaryGuestData.custCompany.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custApprovedByOverseas.value =
        "New";
      newRoomWiseData[roomInd].primaryGuestData.custPurposeOfVisit.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custProgramsAttended.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custEmergencyContactName.value =
        "";
      newRoomWiseData[roomInd].primaryGuestData.custEmergencyContact.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custETA.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custETD.value = "";
      newRoomWiseData[roomInd].primaryGuestData.custOCOCheckin.value = "No";
    }
  };

  // Group day level pricing

  const [currentRoomsSelected, setcurrentRoomsSelected] = useState([]);

  useEffect(() => {
    setcurrentRoomsSelected(Array.from(currentSelection));
  }, [currentSelection]);

  const [infoArraydlp, setinfoArraydlp] = useState([]);

  useEffect(() => {
    if (currentRoomsSelected?.length > 0) {
      const arr = [];

      const pay = currentRoomsSelected.map((res) => {
        Object.entries(roomSelectionCount[res]).map((item) => {
          let obj = {};
          obj["numAdults"] = item[1].A;
          obj["numChildren"] = item[1].C;
          obj["roomTypeId"] = res;
          obj["ratePlanId"] = selectedRatePlan[res].ratePlanId;
          arr.push(obj);
        });
        setinfoArraydlp(arr);
      });
    }
  }, [currentRoomsSelected]);

  const [dayLevelPricindData, setdayLevelPricindData] = useState({});
  const [copyDayLevelPricingData, setcopyDayLevelPricingData] = useState({});

  useEffect(() => {
    if (infoArraydlp?.length > 0 && parseFloat(formik.values.totalPrice) > 0) {
      const payload = {
        hotelId: data?.hotelId,
        checkin: moment(checkin).format("DD-MM-YYYY HH:00:00"),
        checkout: moment(checkout).format("DD-MM-YYYY HH:00:00"),
        rliList: infoArraydlp,
        newAmount: formik.values.totalPrice,
      };
      if (!isDaylevelPricingSet) {
        if (hotel.slotType === "PURE_HOURLY") {
          handleSpringDataMutationRequest(
            "POST",
            `core/api/v1/reservation/getGroupBookBreakdownNoon`,
            payload
          )
            .then((res) => {
              setcopyDayLevelPricingData(res);
              setdayLevelPricindData(res);
            })
            .catch((err) => console.error(err));
        } else {
          handleSpringDataMutationRequest(
            "POST",
            `core/api/v1/reservation/getGroupBookBreakdown`,
            payload
          )
            .then((res) => {
              setcopyDayLevelPricingData(res);
              setdayLevelPricindData(res);
            })
            .catch((err) => console.error(err));
        }
      }
    }
  }, [infoArraydlp, formik.values.totalPrice]);

  const [dayLevelPricingState, setdayLevelPricingState] = useState({
    right: false,
  });

  const toggleDayLevelPricingDrawer =
    (anchor, open, index) => async (event) => {
      if (
        event?.type === "keydown" &&
        (event?.key === "Tab" || event?.key === "Shift")
      ) {
        return;
      }

      setdayLevelPricingState({ ...dayLevelPricingState, [anchor]: open });
    };

  const [dayLevelTotalTaxAmount, setdayLevelTotalTaxAmount] = useState("");
  const [dayLevelPreTaxAmount, setdayLevelPreTaxAmount] = useState("");
  const [dayLevelTotalTax, setdayLevelTotalTax] = useState("");

  const [isDaylevelPricingSet, setisDaylevelPricingSet] = useState(false);

  useEffect(() => {
    if (isDaylevelPricingSet) {
      setSubtotal({
        sub: dayLevelPreTaxAmount,
        tax: dayLevelTotalTax,
      });
      formik.setFieldValue(
        "totalPrice",
        parseFloat(dayLevelTotalTaxAmount).toFixed(2)
      );
    }
  }, [
    isDaylevelPricingSet,
    dayLevelTotalTaxAmount,
    dayLevelPreTaxAmount,
    dayLevelTotalTax,
  ]);

  const dayLevelPricingComponent = (anchor) => (
    <Box
      sx={{ width: 650 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {
            <>
              <div className="folioInformation">
                <div className="fId">Day Level Pricing</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleDayLevelPricingDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          }

          {/* <Divider /> */}
        </div>
        <Divider />
        {/* <div className="folioTabs">
        <TabPanel value={value} index={0} className="tabsContent"> */}
        <GroupDayLevelPricing
          onCancelClick={toggleDayLevelPricingDrawer("right", false)}
          dayLevelPricindData={copyDayLevelPricingData}
          setdayLevelTotalTaxAmount={setdayLevelTotalTaxAmount}
          setdayLevelPreTaxAmount={setdayLevelPreTaxAmount}
          setdayLevelTotalTax={setdayLevelTotalTax}
          setisDaylevelPricingSet={setisDaylevelPricingSet}
          currency={hotelCurrency}
        />
        {/* </TabPanel>
      </div> */}
      </div>
    </Box>
  );

  useEffect(() => {
    formik.setFieldValue(
      "prpList",
      isDaylevelPricingSet ? copyDayLevelPricingData : null
    );
  }, [isDaylevelPricingSet]);

  return (
    <Grid item sx={{ padding: 2 }} container spacing={2} xs={12}>
      <Grid container item xs={8} spacing={2}>
        <Grid item xs={12}>
          <Grow in timeout={1000}>
            <Card variant="outlined">
              <CardHeader
                sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                title="Room wise guest details"
              ></CardHeader>
              <Divider />
              <CardContent>
                <Drawer
                  anchor={"right"}
                  open={dayLevelPricingState["right"]}
                  onClose={toggleDayLevelPricingDrawer("right", false)}
                  PaperProps={{
                    sx: { width: 650 },
                  }}
                >
                  {dayLevelPricindData && dayLevelPricingComponent("right")}
                </Drawer>
                <Grid item xs={12} container padding={2}>
                  {newRoomWiseData?.length > 0 &&
                    newRoomWiseData.map((roomData, index) => {
                      return (
                        <Grid item key={index} xs={12}>
                          <StyledAccordion
                            defaultExpanded={index === 0 ? true : false}
                          >
                            <StyledAccordionSummary>
                              <Grid container width="100%">
                                <Grid item xs={6} justifyContent="flex-start">
                                  {" Room " +
                                    (index + 1) +
                                    " | " +
                                    roomData?.roomTypeName +
                                    " (" +
                                    roomData?.ratePlanName +
                                    ")"}
                                </Grid>
                                {index !== 0 && (
                                  <Grid
                                    item
                                    display="flex"
                                    xs={6}
                                    justifyContent="flex-end"
                                  >
                                    <div style={{ marginTop: "-10px" }}>
                                      <Checkbox
                                        {...label}
                                        value={sameDataCheck[index]}
                                        //   onChange={(e) => toggleShowExpiresin(e)}
                                        onChange={(e) =>
                                          setSameAsPrimaryData(index)
                                        }
                                      />
                                    </div>
                                    <span style={{ marginTop: "-3px" }}>
                                      Same as group owner
                                    </span>
                                  </Grid>
                                )}
                              </Grid>
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                              <Accordion
                                defaultExpanded={index === 0 ? true : false}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls="panel1c-content"
                                  id="panel1c-header"
                                >
                                  {index === 0
                                    ? "Primary guest details (group owner)"
                                    : "Primary guest details"}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container sx={{ width: "100%" }}>
                                    <Grid item xs={12} container spacing={2}>
                                      <Grid item xs={6}>
                                        <Stack position="relative">
                                          <TextField
                                            required
                                            name="customer_name"
                                            id="customer_name"
                                            // value={
                                            //   index === 0
                                            //     ? formik.values.customer_name
                                            //     : roomData?.primaryGuestData
                                            //         ?.customerName
                                            // }
                                            inputRef={(ref) =>
                                              (newRoomWiseData[
                                                index
                                              ].primaryGuestData = {
                                                ...newRoomWiseData[index]
                                                  .primaryGuestData,
                                                customerName: ref,
                                              })
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            onChange={
                                              // index === 0
                                              //   ? formik.handleChange
                                              //   :
                                              (e) => {
                                                handleInputChangeDebounced(
                                                  index,
                                                  e.target.value,
                                                  "name"
                                                );
                                                // customerElasticSearch(
                                                //   index,
                                                //   e.target.value,
                                                //   "name"
                                                // );
                                              }
                                            }
                                            // onBlur={
                                            //   index === 0
                                            //     ? formik.handleBlur
                                            //     : undefined
                                            // }
                                            autoComplete="new-password"
                                            // error={
                                            //   index === 0 &&
                                            //   formik.touched.customer_name &&
                                            //   Boolean(
                                            //     formik.errors.customer_name
                                            //   )
                                            // }
                                            // helperText={
                                            //   index === 0 &&
                                            //   formik.touched.customer_name &&
                                            //   formik.errors.customer_name
                                            // }
                                            fullWidth
                                            label="Customer Name"
                                          ></TextField>
                                          <div
                                            className="position-absolute"
                                            style={{
                                              top: "4.5rem",
                                              zIndex: "10000",
                                            }}
                                          >
                                            {customerNameData[index]?.length >
                                              0 &&
                                              refShowDataArray[index] && (
                                                <Card
                                                  style={{
                                                    width: "36rem",
                                                    height: "60%",
                                                    overflowY: "yes",
                                                    zIndex: "1000",
                                                  }}
                                                >
                                                  <IconButton
                                                    style={{
                                                      display: "block",
                                                      marginLeft: "auto",
                                                      marginRight: "1rem",
                                                    }}
                                                    color="primary"
                                                  >
                                                    <CancelIcon
                                                      onClick={() =>
                                                        emptyValuesRoom(index)
                                                      }
                                                    />
                                                  </IconButton>
                                                </Card>
                                              )}
                                            {customerNameData[index]?.length >
                                              0 &&
                                              refShowDataArray[index] &&
                                              customerNameData[index]?.map(
                                                (item, cardInd) => (
                                                  <Card
                                                    style={{
                                                      width: "36rem",
                                                      height: "60%",
                                                      overflowY: "yes",
                                                      zIndex: "1000",
                                                    }}
                                                    onClick={() => {
                                                      populateRefCustomerData(
                                                        index,
                                                        item
                                                      );
                                                      // if (index === 0) {
                                                      //   populateCustomerFields(
                                                      //     item
                                                      //   );
                                                      // } else {
                                                      //   populateCustomerData(
                                                      //     index,
                                                      //     item
                                                      //   );
                                                      // }
                                                    }}
                                                  >
                                                    <CardContent
                                                      style={{ padding: "3%" }}
                                                    >
                                                      <div className="d-flex flex-column">
                                                        {/* <BsSearch className="mb-0 mr-2" /> */}
                                                        <div className="d-flex ">
                                                          <h4 className="mb-0 mr-4">
                                                            {item.name}
                                                          </h4>
                                                          {item.donotRent ===
                                                            "YES" && (
                                                            <>
                                                              <p className="badge badge-danger">
                                                                Do not rent
                                                              </p>
                                                              {item?.severity ===
                                                                "LOW" && (
                                                                <p className="badge badge-info ml-2">
                                                                  LOW
                                                                </p>
                                                              )}
                                                              {item?.severity ===
                                                                "MEDIUM" && (
                                                                <p className="badge badge-warning ml-2">
                                                                  MEDIUM
                                                                </p>
                                                              )}
                                                              {item?.severity ===
                                                                "HIGH" && (
                                                                <p className="badge badge-dark ml-2">
                                                                  HIGH
                                                                </p>
                                                              )}
                                                              {item?.severity ===
                                                                "BLACKLIST" && (
                                                                <p className="badge badge-danger ml-2">
                                                                  BLACKLIST
                                                                </p>
                                                              )}
                                                            </>
                                                          )}
                                                        </div>
                                                        <div className="d-flex mb-0">
                                                          <p className="mr-3">
                                                            {item.email}
                                                          </p>
                                                          <p>{item.contact}</p>
                                                        </div>
                                                        <p className="mb-0">
                                                          {item.notes}
                                                        </p>
                                                      </div>
                                                    </CardContent>
                                                  </Card>
                                                )
                                              )}
                                          </div>
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Stack position="relative">
                                          <TextField
                                            name="customer_email"
                                            id="customer_email"
                                            fullWidth
                                            autoComplete="new-password"
                                            type="text"
                                            placeholder="Email Address"
                                            // value={
                                            //   index === 0
                                            //     ? formik.values.customer_email
                                            //     : roomData?.primaryGuestData
                                            //         ?.customerEmail
                                            // }
                                            // value={
                                            //   newRoomWiseData[index]
                                            //     ?.primaryGuestData
                                            //     ?.customerEmail?.value
                                            // }
                                            onChange={
                                              // index === 0
                                              //   ? formik.handleChange
                                              //   :
                                              (e) => {
                                                handleInputChangeDebounced(
                                                  index,
                                                  e.target.value,
                                                  "email"
                                                );
                                                // customerElasticSearch(
                                                //   index,
                                                //   e.target.value,
                                                //   "email"
                                                // );
                                                // performPrimaryGuestUpdate(
                                                //   index,
                                                //   "customerEmail",
                                                //   e.target.value
                                                // );
                                              }
                                            }
                                            inputRef={(ref) =>
                                              (newRoomWiseData[
                                                index
                                              ].primaryGuestData = {
                                                ...newRoomWiseData[index]
                                                  .primaryGuestData,
                                                customerEmail: ref,
                                              })
                                            }
                                            // onBlur={
                                            //   index === 0
                                            //     ? formik.handleBlur
                                            //     : undefined
                                            // }
                                            label="Enter Customer Email"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          ></TextField>
                                          <div
                                            className="position-absolute"
                                            style={{
                                              top: "4.5rem",
                                              zIndex: "10000",
                                            }}
                                          >
                                            {customerEmailData[index]?.length >
                                              0 &&
                                              refShowDataArray[index] && (
                                                <Card
                                                  style={{
                                                    width: "28rem",
                                                    height: "60%",
                                                    overflowY: "yes",
                                                    zIndex: "1000",
                                                  }}
                                                >
                                                  <IconButton
                                                    style={{
                                                      display: "block",
                                                      marginLeft: "auto",
                                                      marginRight: "1rem",
                                                    }}
                                                    color="primary"
                                                  >
                                                    <CancelIcon
                                                      onClick={() =>
                                                        emptyValuesRoom(index)
                                                      }
                                                    />
                                                  </IconButton>
                                                </Card>
                                              )}
                                            {customerEmailData[index]?.length >
                                              0 &&
                                              refShowDataArray[index] &&
                                              customerEmailData[index]?.map(
                                                (item, cardInd) => (
                                                  <Card
                                                    style={{
                                                      width: "28rem",
                                                      height: "60%",
                                                      overflowY: "yes",
                                                      zIndex: "1000",
                                                    }}
                                                    onClick={() => {
                                                      // if (index === 0) {
                                                      //   populateCustomerFields(
                                                      //     item
                                                      //   );
                                                      // } else {
                                                      //   populateCustomerData(
                                                      //     index,
                                                      //     item
                                                      //   );
                                                      // }
                                                      populateRefCustomerData(
                                                        index,
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <CardContent
                                                      style={{ padding: "3%" }}
                                                    >
                                                      <div className="d-flex flex-column">
                                                        {/* <BsSearch className="mb-0 mr-2" /> */}
                                                        <div className="d-flex ">
                                                          <h4 className="mb-0 mr-4">
                                                            {item.name}
                                                          </h4>
                                                          {item.donotRent ===
                                                            "YES" && (
                                                            <>
                                                              <p className="badge badge-danger">
                                                                Do not rent
                                                              </p>
                                                              {item?.severity ===
                                                                "LOW" && (
                                                                <p className="badge badge-info ml-2">
                                                                  LOW
                                                                </p>
                                                              )}
                                                              {item?.severity ===
                                                                "MEDIUM" && (
                                                                <p className="badge badge-warning ml-2">
                                                                  MEDIUM
                                                                </p>
                                                              )}
                                                              {item?.severity ===
                                                                "HIGH" && (
                                                                <p className="badge badge-dark ml-2">
                                                                  HIGH
                                                                </p>
                                                              )}
                                                              {item?.severity ===
                                                                "BLACKLIST" && (
                                                                <p className="badge badge-danger ml-2">
                                                                  BLACKLIST
                                                                </p>
                                                              )}
                                                            </>
                                                          )}
                                                        </div>
                                                        <div className="d-flex mb-0">
                                                          <p className="mr-3">
                                                            {item.email}
                                                          </p>
                                                          <p>{item.contact}</p>
                                                        </div>
                                                        <p className="mb-0">
                                                          {item.notes}
                                                        </p>
                                                      </div>
                                                    </CardContent>
                                                  </Card>
                                                )
                                              )}
                                          </div>
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Stack position="relative">
                                          <TextField
                                            name="customer_phone"
                                            id="customer_phone"
                                            fullWidth
                                            autoComplete="new-password"
                                            placeholder="Phone Number"
                                            label="Phone Number With Country Code "
                                            // error={
                                            //   index === 0 &&
                                            //   formik.touched.customer_phone &&
                                            //   Boolean(
                                            //     formik.errors.customer_phone
                                            //   )
                                            // }
                                            // helperText={
                                            //   index === 0 &&
                                            //   formik.touched.customer_phone &&
                                            //   formik.errors.customer_phone
                                            // }
                                            // value={
                                            //   index === 0
                                            //     ? formik.values.customer_phone
                                            //     : roomData?.primaryGuestData
                                            //         ?.customerPhone
                                            // }
                                            // value={
                                            //   newRoomWiseData[index]
                                            //     ?.primaryGuestData
                                            //     ?.customerPhone?.value
                                            // }
                                            onChange={
                                              // index === 0
                                              //   ? formik.handleChange
                                              //   :
                                              (e) => {
                                                handleInputChangeDebounced(
                                                  index,
                                                  e.target.value,
                                                  "phone"
                                                );
                                                // customerElasticSearch(
                                                //   index,
                                                //   e.target.value,
                                                //   "phone"
                                                // );
                                                // performPrimaryGuestUpdate(
                                                //   index,
                                                //   "customerPhone",
                                                //   e.target.value
                                                // );
                                              }
                                            }
                                            // onBlur={
                                            //   index === 0
                                            //     ? formik.handleBlur
                                            //     : undefined
                                            // }
                                            inputRef={(ref) =>
                                              (newRoomWiseData[
                                                index
                                              ].primaryGuestData = {
                                                ...newRoomWiseData[index]
                                                  .primaryGuestData,
                                                customerPhone: ref,
                                              })
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          ></TextField>
                                          <div
                                            className="position-absolute"
                                            style={{
                                              top: "4.5rem",
                                              zIndex: "10000",
                                            }}
                                          >
                                            {customerPhoneData[index]?.length >
                                              0 &&
                                              refShowDataArray[index] && (
                                                <Card
                                                  style={{
                                                    width: "36rem",
                                                    height: "60%",
                                                    overflowY: "yes",
                                                    zIndex: "1000",
                                                  }}
                                                >
                                                  <IconButton
                                                    style={{
                                                      display: "block",
                                                      marginLeft: "auto",
                                                      marginRight: "1rem",
                                                    }}
                                                    color="primary"
                                                  >
                                                    <CancelIcon
                                                      onClick={() =>
                                                        emptyValuesRoom(index)
                                                      }
                                                    />
                                                  </IconButton>
                                                </Card>
                                              )}
                                            {customerPhoneData[index]?.length >
                                              0 &&
                                              refShowDataArray[index] &&
                                              customerPhoneData[index]?.map(
                                                (item, cardInd) => (
                                                  <Card
                                                    style={{
                                                      width: "36rem",
                                                      height: "60%",
                                                      overflowY: "yes",
                                                      zIndex: "1000",
                                                    }}
                                                    onClick={() => {
                                                      // if (index === 0) {
                                                      //   populateCustomerFields(
                                                      //     item
                                                      //   );
                                                      // } else {
                                                      //   populateCustomerData(
                                                      //     index,
                                                      //     item
                                                      //   );
                                                      // }
                                                      populateRefCustomerData(
                                                        index,
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <CardContent
                                                      style={{ padding: "3%" }}
                                                    >
                                                      <div className="d-flex flex-column">
                                                        {/* <BsSearch className="mb-0 mr-2" /> */}
                                                        <div className="d-flex ">
                                                          <h4 className="mb-0 mr-4">
                                                            {item.name}
                                                          </h4>
                                                          {item.donotRent ===
                                                            "YES" && (
                                                            <>
                                                              <p className="badge badge-danger">
                                                                Do not rent
                                                              </p>
                                                              {item?.severity ===
                                                                "LOW" && (
                                                                <p className="badge badge-info ml-2">
                                                                  LOW
                                                                </p>
                                                              )}
                                                              {item?.severity ===
                                                                "MEDIUM" && (
                                                                <p className="badge badge-warning ml-2">
                                                                  MEDIUM
                                                                </p>
                                                              )}
                                                              {item?.severity ===
                                                                "HIGH" && (
                                                                <p className="badge badge-dark ml-2">
                                                                  HIGH
                                                                </p>
                                                              )}
                                                              {item?.severity ===
                                                                "BLACKLIST" && (
                                                                <p className="badge badge-danger ml-2">
                                                                  BLACKLIST
                                                                </p>
                                                              )}
                                                            </>
                                                          )}
                                                        </div>
                                                        <div className="d-flex mb-0">
                                                          <p className="mr-3">
                                                            {item.email}
                                                          </p>
                                                          <p>{item.contact}</p>
                                                        </div>
                                                        <p className="mb-0">
                                                          {item.notes}
                                                        </p>
                                                      </div>
                                                    </CardContent>
                                                  </Card>
                                                )
                                              )}
                                          </div>
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Stack position="relative">
                                          <TextField
                                            select
                                            className="position-relative"
                                            name="customer_gender"
                                            id="customer_gender"
                                            fullWidth
                                            placeholder="Gender"
                                            defaultValue={"Male"}
                                            // value={
                                            //   newRoomWiseData[index]
                                            //     ?.primaryGuestData
                                            //     ?.customerGender?.value || ""
                                            // }
                                            // onChange={(e) => {
                                            //   index === 0
                                            //     ? formik.setFieldValue(
                                            //         "customer_gender",
                                            //         e.target.value
                                            //       )
                                            //     : console.log(e);
                                            // }}
                                            inputRef={(ref) =>
                                              (newRoomWiseData[
                                                index
                                              ].primaryGuestData = {
                                                ...newRoomWiseData[index]
                                                  .primaryGuestData,
                                                customerGender: ref,
                                              })
                                            }
                                            label="Gender"
                                            variant="outlined"
                                          >
                                            {hotelType === "ISHA"
                                              ? ishaGenderList?.length > 0 &&
                                                ishaGenderList.map((genVal) => {
                                                  return (
                                                    <MenuItem
                                                      key={genVal}
                                                      value={genVal}
                                                    >
                                                      {genVal}
                                                    </MenuItem>
                                                  );
                                                })
                                              : genderList?.length > 0 &&
                                                genderList.map((genVal) => {
                                                  return (
                                                    <MenuItem
                                                      key={genVal}
                                                      value={genVal}
                                                    >
                                                      {genVal}
                                                    </MenuItem>
                                                  );
                                                })}
                                          </TextField>
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <DatePicker
                                            inputFormat={dateFnsFormat}
                                            fullWidth
                                            disableMaskedInput
                                            disableCloseOnSelect={false}
                                            disableFuture
                                            mask="___ ___, ____"
                                            id="customer_dob"
                                            name="customer_dob"
                                            value={
                                              // index === 0
                                              //   ? formik.values.customer_dob
                                              //   :
                                              refDOBArray[index]
                                            }
                                            label="Date of Birth"
                                            onChange={
                                              // index === 0
                                              //   ? setDateOfBirthSelection
                                              //   :
                                              (val, isTime) =>
                                                setRefDOBArrayIndex(index, val)
                                            }
                                            inputRef={(ref) =>
                                              (newRoomWiseData[
                                                index
                                              ].primaryGuestData = {
                                                ...newRoomWiseData[index]
                                                  .primaryGuestData,
                                                customerDOB: ref,
                                              })
                                            }
                                            onYearChange={(value) => {
                                              // if (index === 0) {
                                              //   formik.setFieldValue(
                                              //     "customer_dob",
                                              //     value
                                              //   );
                                              //   setDateOfBirthSelection(
                                              //     value,
                                              //     null
                                              //   );
                                              // } else {
                                              // performPrimaryGuestUpdate(
                                              //   index,
                                              //   "customerDOB",
                                              //   value
                                              // );
                                              setRefDOBArrayIndex(index, value);
                                              // }
                                            }}
                                            InputProps={{
                                              onKeyDown: (e) =>
                                                e.preventDefault(),
                                            }}
                                            // onAccept={(value) => {
                                            //   const newYear = new Date(
                                            //     formik.values.customer_dob
                                            //   ).getFullYear();
                                            //   const year = new Date(
                                            //     value
                                            //   ).getFullYear();
                                            //   const sameYear = year == newYear;
                                            //   setDateOfBirthSelection(
                                            //     sameYear
                                            //       ? value
                                            //       : formik.values
                                            //           .customer_dob || value
                                            //   );
                                            // }}
                                            autoOk={true}
                                            renderInput={(params) => {
                                              params.error = Boolean(
                                                formik.errors.customer_dob
                                              );
                                              return (
                                                <TextField
                                                  helperText={
                                                    formik.errors.customer_dob
                                                  }
                                                  fullWidth
                                                  {...params}
                                                />
                                              );
                                            }}
                                          />
                                        </LocalizationProvider>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          select
                                          variant="outlined"
                                          label="Govt ID"
                                          // value={
                                          //   // index === 0
                                          //   //   ? formik.values.govtIdType
                                          //   //   :
                                          //   newRoomWiseData[index]
                                          //     ?.primaryGuestData?.custGovtIDType
                                          //     ?.value
                                          // }
                                          defaultValue={"Passport"}
                                          fullWidth
                                          placeholder="Select an ID type"
                                          id="govtIdType"
                                          name="govtIdType"
                                          // onChange={(e) => {
                                          //   if (index === 0) {
                                          //     formik.setFieldValue(
                                          //       "govtIdType",
                                          //       e.target.value
                                          //     );
                                          //   } else {
                                          //     // performPrimaryGuestUpdate(
                                          //     //   index,
                                          //     //   "custGovtIDType",
                                          //     //   e.target.value
                                          //     // );
                                          //   }
                                          // }}
                                          inputRef={(ref) =>
                                            (newRoomWiseData[
                                              index
                                            ].primaryGuestData = {
                                              ...newRoomWiseData[index]
                                                .primaryGuestData,
                                              custGovtIDType: ref,
                                            })
                                          }
                                        >
                                          {hotelCurrency === "INR"
                                            ? govIdIndia.map(
                                                (item, govindex) => (
                                                  <MenuItem
                                                    key={govindex + item}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                )
                                              )
                                            : govIdUS.map((item, govindex) => (
                                                <MenuItem
                                                  key={govindex + item}
                                                  value={item}
                                                >
                                                  {item}
                                                </MenuItem>
                                              ))}
                                        </TextField>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="GOVT ID Number"
                                          placeholder="GOVT ID Number"
                                          id="govtIdValue"
                                          name="govtIdValue"
                                          // value={
                                          //   index === 0
                                          //     ? formik.values.govtIdValue
                                          //     : newRoomWiseData[index]?.primaryGuestData
                                          //         ?.custGovtIDValue
                                          // }
                                          // onChange={
                                          //   index === 0 && formik.handleChange
                                          // }
                                          inputRef={(ref) =>
                                            (newRoomWiseData[
                                              index
                                            ].primaryGuestData = {
                                              ...newRoomWiseData[index]
                                                .primaryGuestData,
                                              custGovtIDValue: ref,
                                            })
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <FormControl fullWidth>
                                          <InputLabel id="demo-simple-select-label">
                                            Upload Image
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={imageUpload[index]}
                                            onChange={(e) =>
                                              updateImageUploadArray(
                                                index,
                                                e.target.value
                                              )
                                            }
                                            label="Upload Image"
                                          >
                                            <MenuItem value={"Device"}>
                                              Device upload
                                            </MenuItem>
                                            <MenuItem value={"Camera"}>
                                              Camera upload
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12}>
                                        {imageUpload[index] === "Camera" && (
                                          <div className="d-flex">
                                            <div className="d-flex flex-column mr-3">
                                              {frontImages[index] !==
                                                undefined && (
                                                <img
                                                  src={`${frontImages[index]}`}
                                                  style={{
                                                    width: "24rem",
                                                    heigth: "20rem",
                                                    borderRadius: "1rem",
                                                  }}
                                                />
                                              )}
                                              <Button
                                                variant="custom-button"
                                                className="mr-4 mt-3"
                                                onClick={() =>
                                                  handleOpenFrontIdModalInd(
                                                    index
                                                  )
                                                }
                                              >
                                                Upload front id
                                              </Button>
                                            </div>
                                            <div className="d-flex flex-column">
                                              {backImages[index] !==
                                                undefined && (
                                                <img
                                                  src={`${backImages[index]}`}
                                                  style={{
                                                    width: "24rem",
                                                    heigth: "20rem",
                                                    borderRadius: "1rem",
                                                  }}
                                                />
                                              )}
                                              <Button
                                                variant="custom-button"
                                                className="mt-3"
                                                onClick={() =>
                                                  handleOpenBackIdModalInd(
                                                    index
                                                  )
                                                }
                                              >
                                                Upload Back id
                                              </Button>
                                            </div>
                                          </div>
                                        )}
                                        {imageUpload[index] === "Device" && (
                                          <Grid item xs={12}>
                                            <div className="d-flex">
                                              <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                                                <h5
                                                  className="mb-3"
                                                  style={{
                                                    marginRight: "13px",
                                                  }}
                                                >
                                                  ID front
                                                </h5>
                                                <ReactImageUploading
                                                  value={
                                                    frontDeviceImages[index]
                                                  }
                                                  onChange={(image) => {
                                                    updateFrontDeviceImages(
                                                      index,
                                                      image
                                                    );
                                                  }}
                                                  dataURLKey="data_url"
                                                >
                                                  {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                  }) => (
                                                    // write your building UI
                                                    <Stack
                                                      // p="2"
                                                      // bgColor="gray.50"
                                                      // borderRadius="lg"
                                                      className="upload__image-wrapper"
                                                      sx={{
                                                        borderRadius: "10px",
                                                        height: "150px",
                                                        backgroundColor:
                                                          "#d3d3d336",
                                                        padding: "1rem",
                                                      }}
                                                    >
                                                      {imageList.length ===
                                                        0 && (
                                                        <IconButton
                                                          children={
                                                            <UploadIcon
                                                              icon="bi:upload"
                                                              htmlColor="black"
                                                            />
                                                          }
                                                          variant="outline"
                                                          // height="100%"
                                                          sx={{
                                                            borderRadius:
                                                              "20px",
                                                            backgroundColor:
                                                              "#d3d3d3",
                                                            height: "100%",
                                                          }}
                                                          onClick={
                                                            onImageUpload
                                                          }
                                                          {...dragProps}
                                                        ></IconButton>
                                                      )}
                                                      <Stack spacing="2">
                                                        {imageList.map(
                                                          (image, imgIndex) => (
                                                            <Stack
                                                              sx={{
                                                                position:
                                                                  "relative",
                                                                padding:
                                                                  "0.5rem",
                                                              }}
                                                              key={imgIndex}
                                                            >
                                                              <img
                                                                src={
                                                                  image[
                                                                    "data_url"
                                                                  ].includes(
                                                                    "base64"
                                                                  )
                                                                    ? image[
                                                                        "data_url"
                                                                      ]
                                                                    : image[
                                                                        "data_url"
                                                                      ] +
                                                                      "?lastmod=" +
                                                                      format(
                                                                        new Date(),
                                                                        "ddMMyyyyhhmmsstt"
                                                                      )
                                                                }
                                                                style={{
                                                                  height:
                                                                    "125px",
                                                                  width: "100%",
                                                                  borderRadius:
                                                                    "1rem",
                                                                  boxShadow:
                                                                    "md",
                                                                  border:
                                                                    "1px solid",
                                                                  borderColor:
                                                                    "gray.200",
                                                                }}
                                                                onClick={() =>
                                                                  handleFrontImagePopupShow(
                                                                    "front"
                                                                  )
                                                                }
                                                                alt=""
                                                              />

                                                              <IconButton
                                                                children={
                                                                  <CloseIcon
                                                                    fontSize="xs"
                                                                    htmlColor="black"
                                                                  />
                                                                }
                                                                size="xs"
                                                                sx={{
                                                                  position:
                                                                    "absolute",
                                                                  borderRadius:
                                                                    "100%",
                                                                  backgroundColor:
                                                                    "white",
                                                                  top: "0",
                                                                  // colorScheme="teal"
                                                                  marginTop:
                                                                    "0px !important",
                                                                  left: "0",
                                                                  "&:hover": {
                                                                    color:
                                                                      "black",
                                                                    backgroundColor:
                                                                      "#ffffffcc",
                                                                  },
                                                                }}
                                                                onClick={() =>
                                                                  onImageRemove(
                                                                    index
                                                                  )
                                                                }
                                                              />
                                                              <IconButton
                                                                children={
                                                                  <ChangeCircleIcon
                                                                    fontSize="xs"
                                                                    htmlColor="black"
                                                                  />
                                                                }
                                                                size="xs"
                                                                sx={{
                                                                  position:
                                                                    "absolute",
                                                                  borderRadius:
                                                                    "100%",
                                                                  top: "0",
                                                                  backgroundColor:
                                                                    "white",
                                                                  // colorScheme="teal"
                                                                  marginTop:
                                                                    "0px !important",
                                                                  right: "0",
                                                                  "&:hover": {
                                                                    color:
                                                                      "black",
                                                                    backgroundColor:
                                                                      "#ffffffcc",
                                                                  },
                                                                }}
                                                                onClick={() =>
                                                                  onImageUpdate(
                                                                    index
                                                                  )
                                                                }
                                                              />
                                                            </Stack>
                                                          )
                                                        )}
                                                      </Stack>
                                                    </Stack>
                                                  )}
                                                </ReactImageUploading>
                                              </div>
                                              <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                                                <h5 className="mb-3">
                                                  ID back
                                                </h5>
                                                <ReactImageUploading
                                                  value={
                                                    backDeviceImages[index]
                                                  }
                                                  onChange={(image) => {
                                                    updateBackDeviceImages(
                                                      index,
                                                      image
                                                    );
                                                  }}
                                                  dataURLKey="data_url"
                                                >
                                                  {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                  }) => (
                                                    // write your building UI
                                                    <Stack
                                                      // p="2"
                                                      // bgColor="gray.50"
                                                      // borderRadius="lg"
                                                      className="upload__image-wrapper"
                                                      sx={{
                                                        borderRadius: "10px",
                                                        height: "150px",
                                                        backgroundColor:
                                                          "#d3d3d336",
                                                        padding: "1rem",
                                                      }}
                                                    >
                                                      {imageList.length ===
                                                        0 && (
                                                        <IconButton
                                                          children={
                                                            <UploadIcon
                                                              icon="bi:upload"
                                                              htmlColor="black"
                                                            />
                                                          }
                                                          variant="outline"
                                                          sx={{
                                                            borderRadius:
                                                              "20px",
                                                            backgroundColor:
                                                              "#d3d3d3",
                                                            height: "100%",
                                                          }}
                                                          onClick={
                                                            onImageUpload
                                                          }
                                                          {...dragProps}
                                                        ></IconButton>
                                                      )}
                                                      <Stack spacing="2">
                                                        {imageList.map(
                                                          (image, index) => (
                                                            <Stack
                                                              sx={{
                                                                position:
                                                                  "relative",
                                                                padding:
                                                                  "0.5rem",
                                                              }}
                                                              key={index}
                                                            >
                                                              <img
                                                                src={
                                                                  image[
                                                                    "data_url"
                                                                  ].includes(
                                                                    "base64"
                                                                  )
                                                                    ? image[
                                                                        "data_url"
                                                                      ]
                                                                    : image[
                                                                        "data_url"
                                                                      ] +
                                                                      "?lastmod=" +
                                                                      format(
                                                                        new Date(),
                                                                        "ddMMyyyyhhmmsstt"
                                                                      )
                                                                }
                                                                style={{
                                                                  height:
                                                                    "125px",
                                                                  width: "100%",
                                                                  borderRadius:
                                                                    "1rem",
                                                                  boxShadow:
                                                                    "md",
                                                                  border:
                                                                    "1px solid",
                                                                  borderColor:
                                                                    "gray.200",
                                                                }}
                                                                onClick={() =>
                                                                  handleFrontImagePopupShow(
                                                                    "back"
                                                                  )
                                                                }
                                                                alt=""
                                                              />

                                                              <IconButton
                                                                children={
                                                                  <CloseIcon
                                                                    fontSize="xs"
                                                                    htmlColor="black"
                                                                  />
                                                                }
                                                                size="xs"
                                                                sx={{
                                                                  position:
                                                                    "absolute",
                                                                  borderRadius:
                                                                    "100%",
                                                                  backgroundColor:
                                                                    "white",
                                                                  top: "0",
                                                                  // colorScheme="teal"
                                                                  marginTop:
                                                                    "0px !important",
                                                                  left: "0",
                                                                  "&:hover": {
                                                                    color:
                                                                      "black",
                                                                    backgroundColor:
                                                                      "#ffffffcc",
                                                                  },
                                                                }}
                                                                onClick={() =>
                                                                  onImageRemove(
                                                                    index
                                                                  )
                                                                }
                                                              />
                                                              <IconButton
                                                                children={
                                                                  <ChangeCircleIcon
                                                                    fontSize="xs"
                                                                    htmlColor="black"
                                                                  />
                                                                }
                                                                size="xs"
                                                                sx={{
                                                                  position:
                                                                    "absolute",
                                                                  borderRadius:
                                                                    "100%",
                                                                  top: "0",
                                                                  backgroundColor:
                                                                    "white",
                                                                  // colorScheme="teal"
                                                                  marginTop:
                                                                    "0px !important",
                                                                  right: "0",
                                                                  "&:hover": {
                                                                    color:
                                                                      "black",
                                                                    backgroundColor:
                                                                      "#ffffffcc",
                                                                  },
                                                                }}
                                                                onClick={() =>
                                                                  onImageUpdate(
                                                                    index
                                                                  )
                                                                }
                                                              />
                                                            </Stack>
                                                          )
                                                        )}
                                                      </Stack>
                                                    </Stack>
                                                  )}
                                                </ReactImageUploading>
                                              </div>
                                            </div>
                                          </Grid>
                                        )}
                                      </Grid>
                                      <Grid item xs={6}>
                                        <TextField
                                          fullWidth
                                          id="address1"
                                          name="address1"
                                          // value={
                                          //   index === 0
                                          //     ? formik.values.address1
                                          //     : roomData?.primaryGuestData
                                          //         ?.custAddress1
                                          // }
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputRef={(ref) =>
                                            (newRoomWiseData[
                                              index
                                            ].primaryGuestData = {
                                              ...newRoomWiseData[index]
                                                .primaryGuestData,
                                              custAddress1: ref,
                                            })
                                          }
                                          // onChange={
                                          //   index === 0
                                          //     ? formik.handleChange
                                          //     : undefined
                                          //   // : (e) =>
                                          //   //     performPrimaryGuestUpdate(
                                          //   //       index,
                                          //   //       "custAddress1",
                                          //   //       e.target.value
                                          //   //     )
                                          // }
                                          // onBlur={
                                          //   index === 0
                                          //     ? formik.handleBlur
                                          //     : undefined
                                          // }
                                          label="Address Line"
                                        ></TextField>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <TextField
                                          id="address2"
                                          name="address2"
                                          // value={
                                          //   index === 0
                                          //     ? formik.values.address2
                                          //     : roomData?.primaryGuestData
                                          //         ?.custAddress2
                                          // }
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputRef={(ref) =>
                                            (newRoomWiseData[
                                              index
                                            ].primaryGuestData = {
                                              ...newRoomWiseData[index]
                                                .primaryGuestData,
                                              custAddress2: ref,
                                            })
                                          }
                                          // onChange={
                                          //   index === 0
                                          //     ? formik.handleChange
                                          //     : undefined
                                          //   // : (e) =>
                                          //   //     performPrimaryGuestUpdate(
                                          //   //       index,
                                          //   //       "custAddress2",
                                          //   //       e.target.value
                                          //   //     )
                                          // }
                                          // onBlur={
                                          //   index === 0
                                          //     ? formik.handleBlur
                                          //     : undefined
                                          // }
                                          fullWidth
                                          label="Address Line 1"
                                        ></TextField>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          id="zipcode"
                                          name="zipcode"
                                          // value={
                                          //   index === 0
                                          //     ? formik.values.zipcode
                                          //     : roomData?.primaryGuestData
                                          //         ?.custZipcode
                                          // }
                                          inputRef={(ref) =>
                                            (newRoomWiseData[
                                              index
                                            ].primaryGuestData = {
                                              ...newRoomWiseData[index]
                                                .primaryGuestData,
                                              custZipcode: ref,
                                            })
                                          }
                                          onInput={() => {
                                            newRoomWiseData[
                                              index
                                            ].primaryGuestData.custZipcode.value =
                                              newRoomWiseData[
                                                index
                                              ].primaryGuestData.custZipcode.value.replace(
                                                /[^0-9\b]/,
                                                ""
                                              );
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (
                                              e.target.value == "" ||
                                              re.test(e.target.value)
                                            ) {
                                              // if (index === 0) {
                                              //   formik.handleChange(e);
                                              // } else {
                                              //   // performPrimaryGuestUpdate(
                                              //   //   index,
                                              //   //   "custZipcode",
                                              //   //   e.target.value
                                              //   // );
                                              // }
                                              if (
                                                e.target.value.length >= 5 &&
                                                e.target.value.length <= 10
                                              ) {
                                                //call the api function and get the city state and country vals
                                                getGeoCodeValues(
                                                  e.target.value,
                                                  index
                                                );
                                              }
                                            }
                                          }}
                                          // onBlur={
                                          //   index === 0
                                          //     ? formik.handleBlur
                                          //     : undefined
                                          // }
                                          fullWidth
                                          label="Zipcode"
                                        ></TextField>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Autocomplete
                                          fullWidth
                                          // onChange={(eve, val) => {
                                          //   if (val !== formik.values.country)
                                          //     formik.setFieldValue(
                                          //       "state",
                                          //       null
                                          //     );
                                          //   formik.setFieldValue(
                                          //     "country",
                                          //     val
                                          //   );
                                          // }}
                                          options={Object.keys(countries)}
                                          // value={
                                          //   newRoomWiseData[index]
                                          //     ?.primaryGuestData?.custCountry
                                          //     ?.value || ""
                                          // }
                                          defaultValue={
                                            hotelCurrency === "INR"
                                              ? "India"
                                              : "United States"
                                          }
                                          onChange={(e) => {
                                            updateCountryArray(index, e);
                                          }}
                                          // value={formik.values.country}
                                          renderInput={(params) => {
                                            params.inputProps.autoComplete =
                                              "new-password";
                                            return (
                                              <TextField
                                                {...params}
                                                label="Country"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                inputRef={(ref) =>
                                                  (newRoomWiseData[
                                                    index
                                                  ].primaryGuestData = {
                                                    ...newRoomWiseData[index]
                                                      .primaryGuestData,
                                                    custCountry: ref,
                                                  })
                                                }
                                              />
                                            );
                                          }}
                                        ></Autocomplete>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Autocomplete
                                          fullWidth
                                          renderInput={(params) => {
                                            params.inputProps.autoComplete =
                                              "new-password";
                                            return (
                                              <TextField
                                                {...params}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                inputRef={(ref) =>
                                                  (newRoomWiseData[
                                                    index
                                                  ].primaryGuestData = {
                                                    ...newRoomWiseData[index]
                                                      .primaryGuestData,
                                                    custState: ref,
                                                  })
                                                }
                                                label="State"
                                              />
                                            );
                                          }}
                                          // onChange={(eve, val) => {
                                          //   if (index === 0) {
                                          //     formik.setFieldValue(
                                          //       "state",
                                          //       val
                                          //     );
                                          //   }
                                          // }}
                                          options={
                                            isNull(custCountryData[index]) ||
                                            isUndefined(
                                              countries[custCountryData[index]]
                                            )
                                              ? []
                                              : countries[
                                                  custCountryData[index]
                                                ]
                                          }
                                          // value={
                                          //   newRoomWiseData[index]
                                          //     ?.primaryGuestData?.custState
                                          //     ?.value || ""
                                          // }
                                          defaultValue={""}
                                        ></Autocomplete>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          id="city"
                                          name="city"
                                          // value={
                                          //   newRoomWiseData[index]
                                          //     ?.primaryGuestData?.custCity
                                          //     ?.value
                                          // }
                                          // value={
                                          //   index === 0
                                          //     ? formik.values.city
                                          //     : undefined
                                          //   // : roomData?.primaryGuestData
                                          //   //     ?.custCity
                                          // }
                                          inputRef={(ref) =>
                                            (newRoomWiseData[
                                              index
                                            ].primaryGuestData = {
                                              ...newRoomWiseData[index]
                                                .primaryGuestData,
                                              custCity: ref,
                                            })
                                          }
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          // onChange={
                                          //   index === 0
                                          //     ? formik.handleChange
                                          //     : (e) =>
                                          //         performPrimaryGuestUpdate(
                                          //           index,
                                          //           "custCity",
                                          //           e.target.value
                                          //         )
                                          // }
                                          // onBlur={
                                          //   index === 0
                                          //     ? formik.handleBlur
                                          //     : undefined
                                          // }
                                          label="City"
                                        ></TextField>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          name="nationality"
                                          id="nationality"
                                          fullWidth
                                          type="text"
                                          placeholder="Nationality"
                                          // value={
                                          //   index === 0
                                          //     ? formik.values.nationality
                                          //     : roomData?.primaryGuestData
                                          //         ?.custNationality
                                          // }
                                          inputRef={(ref) =>
                                            (newRoomWiseData[
                                              index
                                            ].primaryGuestData = {
                                              ...newRoomWiseData[index]
                                                .primaryGuestData,
                                              custNationality: ref,
                                            })
                                          }
                                          // onChange={
                                          //   index === 0
                                          //     ? formik.handleChange
                                          //     : undefined
                                          //   // : (e) =>
                                          //   //     performPrimaryGuestUpdate(
                                          //   //       index,
                                          //   //       "custNationality",
                                          //   //       e.target.value
                                          //   //     )
                                          // }
                                          // onBlur={
                                          //   index === 0
                                          //     ? formik.handleBlur
                                          //     : undefined
                                          // }
                                          label="Nationality"
                                          variant="outlined"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          name="occupation"
                                          id="occupation"
                                          type="text"
                                          fullWidth
                                          placeholder="occupation"
                                          // value={
                                          //   index === 0
                                          //     ? formik.values.occupation
                                          //     : roomData?.primaryGuestData
                                          //         ?.custOccupation
                                          // }
                                          inputRef={(ref) =>
                                            (newRoomWiseData[
                                              index
                                            ].primaryGuestData = {
                                              ...newRoomWiseData[index]
                                                .primaryGuestData,
                                              custOccupation: ref,
                                            })
                                          }
                                          // onChange={
                                          //   index === 0
                                          //     ? formik.handleChange
                                          //     : undefined
                                          //   // : (e) =>
                                          //   //     performPrimaryGuestUpdate(
                                          //   //       index,
                                          //   //       "custOccupation",
                                          //   //       e.target.value
                                          //   //     )
                                          // }
                                          // onBlur={
                                          //   index === 0
                                          //     ? formik.handleBlur
                                          //     : undefined
                                          // }
                                          label="Occupation"
                                          variant="outlined"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      </Grid>
                                      {hotelType === "ISHA" && ( //change before pushing
                                        <>
                                          <Grid item xs={4}>
                                            <TextField
                                              name="companyName"
                                              id="companyName"
                                              fullWidth
                                              type="text"
                                              placeholder="Company Name"
                                              // value={
                                              //   index === 0
                                              //     ? formik.values.companyName
                                              //     : roomData?.primaryGuestData
                                              //         ?.custCompany
                                              // }
                                              inputRef={(ref) =>
                                                (newRoomWiseData[
                                                  index
                                                ].primaryGuestData = {
                                                  ...newRoomWiseData[index]
                                                    .primaryGuestData,
                                                  custCompany: ref,
                                                })
                                              }
                                              // onChange={
                                              //   index === 0
                                              //     ? formik.handleChange
                                              //     : undefined
                                              //   // : (e) =>
                                              //   //     performPrimaryGuestUpdate(
                                              //   //       index,
                                              //   //       "custCompany",
                                              //   //       e.target.value
                                              //   //     )
                                              // }
                                              // onBlur={
                                              //   index === 0
                                              //     ? formik.handleBlur
                                              //     : undefined
                                              // }
                                              label="Company Name"
                                              variant="outlined"
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={4}>
                                            <TextField
                                              name="approvedByOverseas"
                                              id="approvedByOverseas"
                                              select
                                              className="position-relative"
                                              fullWidth
                                              type="text"
                                              placeholder="Approved by overseas"
                                              // value={
                                              //   index === 0
                                              //     ? formik.values
                                              //         .approvedByOverseas
                                              //     : roomData?.primaryGuestData
                                              //         ?.custApprovedByOverseas
                                              // }
                                              // value={
                                              //   newRoomWiseData[index]
                                              //     ?.primaryGuestData
                                              //     ?.custApprovedByOverseas
                                              //     ?.value || ""
                                              // }
                                              defaultValue={"New"}
                                              inputRef={(ref) =>
                                                (newRoomWiseData[
                                                  index
                                                ].primaryGuestData = {
                                                  ...newRoomWiseData[index]
                                                    .primaryGuestData,
                                                  custApprovedByOverseas: ref,
                                                })
                                              }
                                              // onChange={
                                              //   index === 0
                                              //     ? formik.handleChange
                                              //     : undefined
                                              //   // : (e) =>
                                              //   //     performPrimaryGuestUpdate(
                                              //   //       index,
                                              //   //       "custApprovedByOverseas",
                                              //   //       e.target.value
                                              //   //     )
                                              // }
                                              // onBlur={
                                              //   index === 0
                                              //     ? formik.handleBlur
                                              //     : undefined
                                              // }
                                              label="Approved by overseas"
                                              variant="outlined"
                                            >
                                              {ishaApprovedByOverseas?.length >
                                                0 &&
                                                ishaApprovedByOverseas.map(
                                                  (purpose, index) => {
                                                    return (
                                                      <MenuItem
                                                        key={purpose}
                                                        value={purpose}
                                                      >
                                                        {purpose}
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                            </TextField>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <TextField
                                              select
                                              name="purposeOfVisit"
                                              id="purposeOfVisit"
                                              fullWidth
                                              placeholder="Purpose of visit"
                                              // value={
                                              //   index === 0
                                              //     ? formik.values.purposeOfVisit
                                              //     : roomData?.primaryGuestData
                                              //         ?.custPurposeOfVisit
                                              // }
                                              defaultValue={"-"}
                                              inputRef={(ref) =>
                                                (newRoomWiseData[
                                                  index
                                                ].primaryGuestData = {
                                                  ...newRoomWiseData[index]
                                                    .primaryGuestData,
                                                  custPurposeOfVisit: ref,
                                                })
                                              }
                                              // onChange={
                                              //   index === 0
                                              //     ? formik.handleChange
                                              //     : undefined
                                              //   // : (e) =>
                                              //   //     performPrimaryGuestUpdate(
                                              //   //       index,
                                              //   //       "custPurposeOfVisit",
                                              //   //       e.target.value
                                              //   //     )
                                              // }
                                              // onBlur={
                                              //   index === 0
                                              //     ? formik.handleBlur
                                              //     : undefined
                                              // }
                                              label="Purpose of visit"
                                              variant="outlined"
                                            >
                                              {ishaPurposeOfVisit?.length > 0 &&
                                                ishaPurposeOfVisit.map(
                                                  (purpose, index) => {
                                                    return (
                                                      <MenuItem
                                                        key={purpose}
                                                        value={purpose}
                                                      >
                                                        {purpose}
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                            </TextField>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <FormControl>
                                              <InputLabel id="demo-simple-select-label">
                                                Programs Attended
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                name="programsAttended"
                                                id="programsAttended"
                                                label="Programs attended"
                                                value={selectedPrograms[index]}
                                                onChange={(e) =>
                                                  handleProgramChange(e, index)
                                                }
                                                inputRef={(ref) =>
                                                  (newRoomWiseData[
                                                    index
                                                  ].primaryGuestData = {
                                                    ...newRoomWiseData[index]
                                                      .primaryGuestData,
                                                    custProgramsAttended: ref,
                                                  })
                                                }
                                                required="true"
                                                multiple
                                                style={{ width: "18.3rem" }}
                                                // MenuProps={MenuProps}
                                                placeholder="Choose Programs Attended"
                                              >
                                                {ishaProgramsAttended?.length >
                                                  0 &&
                                                  ishaProgramsAttended.map(
                                                    (program, index) => {
                                                      return (
                                                        <MenuItem
                                                          key={program}
                                                          value={program}
                                                        >
                                                          {program}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <TextField
                                              name="emergencyContactName"
                                              id="emergencyContactName"
                                              fullWidth
                                              type="text"
                                              placeholder="Emergency Contact Name"
                                              // value={
                                              //   index === 0
                                              //     ? formik.values
                                              //         .emergencyContactName
                                              //     : roomData?.primaryGuestData
                                              //         ?.custEmergencyContactName
                                              // }
                                              inputRef={(ref) =>
                                                (newRoomWiseData[
                                                  index
                                                ].primaryGuestData = {
                                                  ...newRoomWiseData[index]
                                                    .primaryGuestData,
                                                  custEmergencyContactName: ref,
                                                })
                                              }
                                              // onChange={
                                              //   index === 0
                                              //     ? formik.handleChange
                                              //     : undefined
                                              //   // : (e) =>
                                              //   //     performPrimaryGuestUpdate(
                                              //   //       index,
                                              //   //       "custEmergencyContactName",
                                              //   //       e.target.value
                                              //   //     )
                                              // }
                                              // onBlur={
                                              //   index === 0
                                              //     ? formik.handleBlur
                                              //     : undefined
                                              // }
                                              label="Emergency Contact Name"
                                              variant="outlined"
                                            />
                                          </Grid>
                                          <Grid item xs={4}>
                                            {newRoomWiseData[index]
                                              .primaryGuestData.customerPhone
                                              ?.value != "" &&
                                            newRoomWiseData[index]
                                              .primaryGuestData
                                              .custEmergencyContact?.value ==
                                              newRoomWiseData[index]
                                                .primaryGuestData.customerPhone
                                                ?.value ? (
                                              <TextField
                                                name="emergencyContact"
                                                id="emergencyContact"
                                                fullWidth
                                                type="text"
                                                placeholder="Emergency Contact"
                                                // value={
                                                //   index === 0
                                                //     ? formik.values
                                                //         .emergencyContact
                                                //     : roomData?.primaryGuestData
                                                //         ?.custEmergencyContact
                                                // }
                                                inputRef={(ref) =>
                                                  (newRoomWiseData[
                                                    index
                                                  ].primaryGuestData = {
                                                    ...newRoomWiseData[index]
                                                      .primaryGuestData,
                                                    custEmergencyContact: ref,
                                                  })
                                                }
                                                // onChange={
                                                //   index === 0
                                                //     ? formik.handleChange
                                                //     : undefined
                                                //   // : (e) =>
                                                //   //     performPrimaryGuestUpdate(
                                                //   //       index,
                                                //   //       "custEmergencyContact",
                                                //   //       e.target.value
                                                //   //     )
                                                // }
                                                // onBlur={
                                                //   index === 0
                                                //     ? formik.handleBlur
                                                //     : undefined
                                                // }
                                                error
                                                helperText={
                                                  "Enter different number"
                                                }
                                                label="Emergency Contact"
                                                variant="outlined"
                                              />
                                            ) : (
                                              <TextField
                                                name="emergencyContact"
                                                id="emergencyContact"
                                                fullWidth
                                                type="text"
                                                placeholder="Emergency Contact"
                                                // value={
                                                //   index === 0
                                                //     ? formik.values
                                                //         .emergencyContact
                                                //     : roomData?.primaryGuestData
                                                //         ?.custEmergencyContact
                                                // }
                                                inputRef={(ref) =>
                                                  (newRoomWiseData[
                                                    index
                                                  ].primaryGuestData = {
                                                    ...newRoomWiseData[index]
                                                      .primaryGuestData,
                                                    custEmergencyContact: ref,
                                                  })
                                                }
                                                // onChange={
                                                //   index === 0
                                                //     ? formik.handleChange
                                                //     : undefined
                                                //   // : (e) =>
                                                //   //     performPrimaryGuestUpdate(
                                                //   //       index,
                                                //   //       "custEmergencyContact",
                                                //   //       e.target.value
                                                //   //     )
                                                // }
                                                // onBlur={
                                                //   index === 0
                                                //     ? formik.handleBlur
                                                //     : undefined
                                                // }
                                                label="Emergency Contact"
                                                variant="outlined"
                                              />
                                            )}
                                          </Grid>
                                          <Grid item xs={4}>
                                            <LocalizationProvider
                                              dateAdapter={AdapterDateFns}
                                            >
                                              <TimePicker
                                                label="Estimated time of arrival"
                                                value={allETA[index]}
                                                onChange={(e) =>
                                                  handleETAChange(e, index)
                                                }
                                                style={{ width: "18.3rem" }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    style={{ width: "18.3rem" }}
                                                  />
                                                )}
                                                inputRef={(ref) =>
                                                  (newRoomWiseData[
                                                    index
                                                  ].primaryGuestData = {
                                                    ...newRoomWiseData[index]
                                                      .primaryGuestData,
                                                    custETA: ref,
                                                  })
                                                }
                                              />
                                            </LocalizationProvider>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <LocalizationProvider
                                              dateAdapter={AdapterDateFns}
                                            >
                                              <TimePicker
                                                label="Estimated time of departure"
                                                value={allETD[index]}
                                                onChange={(e) =>
                                                  handleETDChange(e, index)
                                                }
                                                style={{ width: "18.3rem" }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    style={{ width: "18.3rem" }}
                                                  />
                                                )}
                                                inputRef={(ref) =>
                                                  (newRoomWiseData[
                                                    index
                                                  ].primaryGuestData = {
                                                    ...newRoomWiseData[index]
                                                      .primaryGuestData,
                                                    custETD: ref,
                                                  })
                                                }
                                              />
                                            </LocalizationProvider>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <TextField
                                              select
                                              className="position-relative"
                                              name="ocoCheckIn"
                                              id="ocoCheckIn"
                                              fullWidth
                                              placeholder="OCO checkin complete"
                                              // value={
                                              //   index === 0
                                              //     ? formik.values.ocoCheckIn
                                              //     : roomData?.primaryGuestData
                                              //         ?.custOCOCheckin
                                              // }
                                              defaultValue={"No"}
                                              inputRef={(ref) =>
                                                (newRoomWiseData[
                                                  index
                                                ].primaryGuestData = {
                                                  ...newRoomWiseData[index]
                                                    .primaryGuestData,
                                                  custOCOCheckin: ref,
                                                })
                                              }
                                              // onChange={(e) => {
                                              //   index === 0 &&
                                              //     formik.setFieldValue(
                                              //       "ocoCheckIn",
                                              //       e.target.value
                                              //     );
                                              // }}
                                              label="OCO checkin complete"
                                              variant="outlined"
                                            >
                                              <MenuItem
                                                key={"Yes"}
                                                value={"Yes"}
                                              >
                                                {"Yes"}
                                              </MenuItem>
                                              <MenuItem key={"No"} value={"No"}>
                                                {"No"}
                                              </MenuItem>
                                            </TextField>
                                          </Grid>
                                          <Grid item xs={6}>
                                            <FormControl fullWidth>
                                              <InputLabel id="demo-simple-select-label">
                                                Upload Passport Size Photo
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={
                                                  passportImageUpload[index]
                                                }
                                                onChange={(e) =>
                                                  updatePassportImageUpload(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                label="Upload Passport Size Photo"
                                              >
                                                <MenuItem value={"Device"}>
                                                  Device upload
                                                </MenuItem>
                                                <MenuItem value={"Camera"}>
                                                  Camera upload
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                          {passportImageUpload[index] ===
                                            "Camera" && (
                                            <Grid item xs={12}>
                                              <div className="d-flex">
                                                <div className="d-flex flex-column mr-3">
                                                  {passportImage[index] !==
                                                    undefined && (
                                                    <img
                                                      src={`${passportImage[index]}`}
                                                      style={{
                                                        width: "24rem",
                                                        heigth: "20rem",
                                                        borderRadius: "1rem",
                                                      }}
                                                    />
                                                  )}
                                                  <Button
                                                    variant="custom-button"
                                                    className="mr-2"
                                                    onClick={() =>
                                                      handleOpenPassportModal(
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Upload Passport Size Photo
                                                  </Button>
                                                </div>
                                              </div>
                                            </Grid>
                                          )}
                                          {passportImageUpload[index] ===
                                            "Device" && (
                                            <Grid item xs={12}>
                                              <div className="d-flex">
                                                <div className="d-flex flex-column mr-4 mt-3 mb-3 w-50">
                                                  <h5
                                                    className="mb-3"
                                                    style={{
                                                      marginRight: "13px",
                                                    }}
                                                  >
                                                    Passport Size Photo
                                                  </h5>
                                                  <ReactImageUploading
                                                    value={
                                                      passportImages[index]
                                                    }
                                                    onChange={(image) => {
                                                      updatePassportImages(
                                                        index,
                                                        image
                                                      );
                                                    }}
                                                    dataURLKey="data_url"
                                                  >
                                                    {({
                                                      imageList,
                                                      onImageUpload,
                                                      onImageUpdate,
                                                      onImageRemove,
                                                      isDragging,
                                                      dragProps,
                                                    }) => (
                                                      // write your building UI
                                                      <Stack
                                                        // p="2"
                                                        // bgColor="gray.50"
                                                        // borderRadius="lg"
                                                        className="upload__image-wrapper"
                                                        sx={{
                                                          borderRadius: "10px",
                                                          height: "150px",
                                                          backgroundColor:
                                                            "#d3d3d336",
                                                          padding: "1rem",
                                                        }}
                                                      >
                                                        {imageList.length ===
                                                          0 && (
                                                          <IconButton
                                                            children={
                                                              <UploadIcon
                                                                icon="bi:upload"
                                                                htmlColor="black"
                                                              />
                                                            }
                                                            variant="outline"
                                                            // height="100%"
                                                            sx={{
                                                              borderRadius:
                                                                "20px",
                                                              backgroundColor:
                                                                "#d3d3d3",
                                                              height: "100%",
                                                            }}
                                                            onClick={
                                                              onImageUpload
                                                            }
                                                            {...dragProps}
                                                          ></IconButton>
                                                        )}
                                                        <Stack spacing="2">
                                                          {imageList.map(
                                                            (image, index) => (
                                                              <Stack
                                                                sx={{
                                                                  position:
                                                                    "relative",
                                                                  padding:
                                                                    "0.5rem",
                                                                }}
                                                                key={index}
                                                              >
                                                                <img
                                                                  src={
                                                                    image[
                                                                      "data_url"
                                                                    ].includes(
                                                                      "base64"
                                                                    )
                                                                      ? image[
                                                                          "data_url"
                                                                        ]
                                                                      : image[
                                                                          "data_url"
                                                                        ] +
                                                                        "?lastmod=" +
                                                                        format(
                                                                          new Date(),
                                                                          "ddMMyyyyhhmmsstt"
                                                                        )
                                                                  }
                                                                  style={{
                                                                    height:
                                                                      "125px",
                                                                    width:
                                                                      "100%",
                                                                    borderRadius:
                                                                      "1rem",
                                                                    boxShadow:
                                                                      "md",
                                                                    border:
                                                                      "1px solid",
                                                                    borderColor:
                                                                      "gray.200",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleFrontImagePopupShow(
                                                                      "front"
                                                                    )
                                                                  }
                                                                  alt=""
                                                                />

                                                                <IconButton
                                                                  children={
                                                                    <CloseIcon
                                                                      fontSize="xs"
                                                                      htmlColor="black"
                                                                    />
                                                                  }
                                                                  size="xs"
                                                                  sx={{
                                                                    position:
                                                                      "absolute",
                                                                    borderRadius:
                                                                      "100%",
                                                                    backgroundColor:
                                                                      "white",
                                                                    top: "0",
                                                                    // colorScheme="teal"
                                                                    marginTop:
                                                                      "0px !important",
                                                                    left: "0",
                                                                    "&:hover": {
                                                                      color:
                                                                        "black",
                                                                      backgroundColor:
                                                                        "#ffffffcc",
                                                                    },
                                                                  }}
                                                                  onClick={() =>
                                                                    onImageRemove(
                                                                      index
                                                                    )
                                                                  }
                                                                />
                                                                <IconButton
                                                                  children={
                                                                    <ChangeCircleIcon
                                                                      fontSize="xs"
                                                                      htmlColor="black"
                                                                    />
                                                                  }
                                                                  size="xs"
                                                                  sx={{
                                                                    position:
                                                                      "absolute",
                                                                    borderRadius:
                                                                      "100%",
                                                                    top: "0",
                                                                    backgroundColor:
                                                                      "white",
                                                                    // colorScheme="teal"
                                                                    marginTop:
                                                                      "0px !important",
                                                                    right: "0",
                                                                    "&:hover": {
                                                                      color:
                                                                        "black",
                                                                      backgroundColor:
                                                                        "#ffffffcc",
                                                                    },
                                                                  }}
                                                                  onClick={() =>
                                                                    onImageUpdate(
                                                                      index
                                                                    )
                                                                  }
                                                                />
                                                              </Stack>
                                                            )
                                                          )}
                                                        </Stack>
                                                      </Stack>
                                                    )}
                                                  </ReactImageUploading>
                                                </div>
                                              </div>
                                            </Grid>
                                          )}
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                          >
                                            <Button
                                              for={index + "document1"}
                                              variant="custom-button"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                document
                                                  .getElementById(
                                                    index + "document1"
                                                  )
                                                  .click();
                                              }}
                                            >
                                              Attach file 1
                                              <input
                                                style={{
                                                  color:
                                                    documentList1[index] !== ""
                                                      ? "#20c997"
                                                      : "black",
                                                  display: "none",
                                                }}
                                                id={index + "document1"}
                                                type="file"
                                                className="form-control"
                                                accept=".pdf, .jpg, .jpeg, .png"
                                                name={index + "document1"}
                                                // ref={hiddenFileInput1[index]}
                                                onChange={(eve) => {
                                                  let imageName =
                                                    document.getElementById(
                                                      index + "document1Name"
                                                    );
                                                  let inputImage =
                                                    eve.target.files[0];
                                                  imageName.innerText =
                                                    inputImage.name;
                                                  convertToBase64(index, eve);
                                                }}
                                              />
                                              <br />
                                            </Button>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            md={4}
                                            lg={6}
                                            xl={6}
                                          >
                                            <Button
                                              for={index + "document2"}
                                              variant="custom-button"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                document
                                                  .getElementById(
                                                    index + "document2"
                                                  )
                                                  .click();
                                              }}
                                            >
                                              Attach file 2
                                              <input
                                                style={{
                                                  color:
                                                    documentList2[index] !== ""
                                                      ? "#20c997"
                                                      : "black",
                                                  display: "none",
                                                }}
                                                id={index + "document2"}
                                                type="file"
                                                className="form-control"
                                                accept=".pdf, .jpg, .jpeg, .png"
                                                name={index + "document2"}
                                                // ref={hiddenFileInput2[index]}
                                                onChange={(eve) => {
                                                  let imageName =
                                                    document.getElementById(
                                                      index + "document2Name"
                                                    );
                                                  let inputImage =
                                                    eve.target.files[0];
                                                  imageName.innerText =
                                                    inputImage.name;
                                                  convertToBase64(index, eve);
                                                }}
                                              />
                                              <br />
                                            </Button>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            md={4}
                                            lg={6}
                                            xl={6}
                                          >
                                            <span
                                              id={index + "document1Name"}
                                              style={{
                                                color: "green",
                                                fontSize: "small",
                                              }}
                                            ></span>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            md={4}
                                            lg={6}
                                            xl={6}
                                          >
                                            <span
                                              id={index + "document2Name"}
                                              style={{
                                                color: "green",
                                                fontSize: "small",
                                              }}
                                            ></span>
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                              {(roomData?.adults > 1 ||
                                roomData?.children > 0) && (
                                <Accordion defaultExpanded={false}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                  >
                                    Accompanying guest details
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {roomData?.adults > 1 &&
                                      Array.from(
                                        { length: roomData?.adults - 1 },
                                        (_, adultIndex) => {
                                          return (
                                            <>
                                              <Button
                                                variant="custom-button"
                                                className="ml-4"
                                                onClick={toggleAddGuestDrawer(
                                                  "right",
                                                  true,
                                                  index,
                                                  adultIndex
                                                )}
                                              >
                                                {`${
                                                  newRoomWiseData[index]
                                                    ?.otherGuestData?.length >
                                                  adultIndex
                                                    ? "Edit guest"
                                                    : "Add guest"
                                                } ${adultIndex + 2} details`}
                                              </Button>
                                              <Drawer
                                                anchor={"right"}
                                                open={
                                                  addGuestState[index][
                                                    adultIndex
                                                  ]["right"]
                                                }
                                                onClose={toggleAddGuestDrawer(
                                                  "right",
                                                  false,
                                                  index,
                                                  adultIndex
                                                )}
                                                PaperProps={{
                                                  sx: { width: 700 },
                                                }}
                                              >
                                                {addGuestDetail(
                                                  "right",
                                                  index,
                                                  adultIndex
                                                )}
                                              </Drawer>
                                            </>
                                          );
                                        }
                                      )}
                                    {roomData?.children > 0 &&
                                      Array.from(
                                        { length: roomData?.children },
                                        (_, childIndex) => {
                                          return (
                                            <>
                                              <Button
                                                variant="custom-button"
                                                className="ml-4"
                                                onClick={toggleAddChildDrawer(
                                                  "right",
                                                  true,
                                                  index,
                                                  childIndex
                                                )}
                                              >
                                                {`${
                                                  newRoomWiseData[index]
                                                    ?.childGuestData?.length >
                                                  childIndex
                                                    ? "Edit child"
                                                    : "Add child"
                                                } ${childIndex + 1} details`}
                                              </Button>
                                              <Drawer
                                                anchor={"right"}
                                                open={
                                                  addChildState[index][
                                                    childIndex
                                                  ]["right"]
                                                }
                                                onClose={toggleAddChildDrawer(
                                                  "right",
                                                  false,
                                                  index,
                                                  childIndex
                                                )}
                                                PaperProps={{
                                                  sx: { width: 700 },
                                                }}
                                              >
                                                {addChildDetail(
                                                  "right",
                                                  index,
                                                  childIndex
                                                )}
                                              </Drawer>
                                            </>
                                          );
                                        }
                                      )}
                                  </AccordionDetails>
                                </Accordion>
                              )}
                            </StyledAccordionDetails>
                          </StyledAccordion>
                          {/* Front id capture modal*/}
                          <Modal
                            show={showFrontIdModalList[index]}
                            onHide={() => handleCloseFrontIdModalInd(index)}
                            style={{ zIndex: "3000" }}
                          >
                            <Modal.Header closeButton> Front id</Modal.Header>
                            <Modal.Body>
                              {frontImages[index] === undefined ? (
                                <div>
                                  <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={frontImages[index]}
                                  alt="test-ilustartion"
                                />
                              )}
                              <Button
                                variant="custom-button"
                                onClick={() =>
                                  frontImages[index] === undefined
                                    ? captureFront(index)
                                    : updateFrontImageArray(index, undefined)
                                }
                              >
                                {frontImages[index] === undefined
                                  ? "Capture photo"
                                  : "take photo"}
                              </Button>
                            </Modal.Body>
                          </Modal>
                          {/* Back id capture modal*/}
                          <Modal
                            show={showBackIdModalList[index]}
                            onHide={() => handleCloseBackIdModalInd(index)}
                            style={{ zIndex: "3000" }}
                          >
                            <Modal.Header closeButton> Back id</Modal.Header>
                            <Modal.Body>
                              {backImages[index] === undefined ? (
                                <div>
                                  <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={backImages[index]}
                                  alt="test-ilustartion"
                                />
                              )}
                              <Button
                                variant="custom-button"
                                onClick={() =>
                                  backImages[index] === undefined
                                    ? captureBack(index)
                                    : updateBackImageArray(index, undefined)
                                }
                              >
                                {backImages[index] === undefined
                                  ? "Capture photo"
                                  : "take photo"}
                              </Button>
                            </Modal.Body>
                          </Modal>
                          {/* Passport image modal */}
                          <Modal
                            show={showPassportModal[index]}
                            onHide={() => handleClosePassportModal(index)}
                            style={{ zIndex: "3000" }}
                          >
                            <Modal.Header closeButton>
                              {" "}
                              Passport Size Photo
                            </Modal.Header>
                            <Modal.Body>
                              {passportImage[index] === undefined ? (
                                <div>
                                  <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={passportImage[index]}
                                  alt="test-ilustartion"
                                />
                              )}
                              <Button
                                variant="custom-button"
                                onClick={() =>
                                  passportImage[index] === undefined
                                    ? passportCapture(index)
                                    : updatePassportImage(index, undefined)
                                }
                              >
                                {passportImage[index] === undefined
                                  ? "Capture photo"
                                  : "take photo"}
                              </Button>
                            </Modal.Body>
                          </Modal>
                        </Grid>
                      );
                    })}
                </Grid>
              </CardContent>
            </Card>
          </Grow>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader
              sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
              title="Additional Details"
            ></CardHeader>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack position="relative">
                    <TextField
                      fullWidth
                      label="Special request"
                      id="special_requests"
                      name="special_requests"
                      value={formik.values.special_requests}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></TextField>
                  </Stack>
                </Grid>
                {hotelType === "ISHA" && (
                  <>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Button
                        for="document1"
                        variant="custom-button"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          hiddenFileInput1.current.click();
                        }}
                      >
                        Attach file 1
                        <input
                          style={{
                            color: document1 !== "" ? "#20c997" : "black",
                            display: "none",
                          }}
                          id="document1"
                          type="file"
                          className="form-control"
                          accept=".pdf, .jpg, .jpeg, .png"
                          name="document1"
                          ref={hiddenFileInput1}
                          onChange={(eve) => {
                            let imageName =
                              document.getElementById("document1Name");
                            let inputImage = eve.target.files[0];
                            imageName.innerText = inputImage.name;
                            convertToBase64(eve);
                          }}
                        />
                        <br />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
                      <Button
                        for="document2"
                        variant="custom-button"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          hiddenFileInput2.current.click();
                        }}
                      >
                        Attach file 2
                        <input
                          style={{
                            color: document2 !== "" ? "#20c997" : "black",
                            display: "none",
                          }}
                          id="document2"
                          type="file"
                          className="form-control"
                          accept=".pdf, .jpg, .jpeg, .png"
                          name="document2"
                          ref={hiddenFileInput2}
                          onChange={(eve) => {
                            let imageName =
                              document.getElementById("document2Name");
                            let inputImage = eve.target.files[0];
                            imageName.innerText = inputImage.name;
                            convertToBase64(eve);
                          }}
                        />
                        <br />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
                      <span
                        id="document1Name"
                        style={{ color: "green", fontSize: "small" }}
                      ></span>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
                      <span
                        id="document2Name"
                        style={{ color: "green", fontSize: "small" }}
                      ></span>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    label="Tax exempt ID"
                    value={formik.values.taxId}
                    onChange={taxIdChanged}
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Autocomplete
                    fullWidth
                    disabled={
                      formik.values.taxId === "" || formik.values.taxId === null
                    }
                    renderInput={(params) => {
                      return <TextField {...params} label="Taxes To Exempt" />;
                    }}
                    disableClearable
                    onChange={(e, v) => {
                      if (v.length > 0)
                        formik.setFieldValue("selectedTaxes", v);
                    }}
                    multiple
                    options={isNull(taxNames) ? [] : taxNames}
                    value={
                      formik.values.taxId === ""
                        ? []
                        : isNull(taxNames)
                        ? []
                        : formik.values.selectedTaxes
                    }
                  ></Autocomplete>
                </Grid>
                <SearchModal
                  show={showCompanyModal}
                  hide={hideShowCompanyModal}
                  allCompanyDetails={allCompanyDetails}
                  searchableValue={searchableValue}
                  setsearchableValue={setsearchableValue}
                ></SearchModal>
                {hotelCurrency === "INR" && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="d-flex align-item-center">
                      <p
                        className="mb-0 mr-3"
                        style={{ fontWeight: "bold", marginTop: "10px" }}
                      >
                        Enable the toggle to add company details
                      </p>
                      <Switch
                        checked={showCompanyModal}
                        onChange={() => setshowCompanyModal(true)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider style={{ marginBottom: "15px" }} />
                  {hotelAddons?.length > 0 ? (
                    JSON.parse(
                      props?.data?.accessControl?.user_feature_map
                        ?.AddHotelAddons
                    ).read === "YES" && (
                      <FormControl
                        sx={{ m: 1, width: "33rem", marginBottom: "1rem" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select addons
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select addons"
                          value={selectedAddons}
                          onChange={handleChange}
                          required="true"
                          multiple
                          style={{ width: "33rem" }}
                          // MenuProps={MenuProps}
                          placeholder="Select addons"
                        >
                          {hotelAddons.map((e) => (
                            <MenuItem value={e.id}>{e.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )
                  ) : (
                    <h4>No hotel addons</h4>
                  )}
                  {selectedAddons?.length > 0 && (
                    <div>
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={addonsProductArray}
                        columns={columns}
                        // defaultSorted={defaultSorted}
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                          afterSaveCell: (oldValue, newValue, row, column) => {
                            row.priceWithTax =
                              parseInt(row.quantity) *
                              (parseFloat(row.priceWithoutTax) +
                                parseFloat(row.priceWithoutTax) *
                                  (parseFloat(row.taxPercentage) / 100));
                          },
                        })}
                      />
                    </div>
                  )}
                </Grid>
                {JSON.parse(
                  props?.data?.accessControl?.user_feature_map?.ApplyCouponCode
                ).read === "YES" && (
                  <Grid item xs={12}>
                    <Divider style={{ marginBottom: "15px" }} />
                    <div className="d-flex flex-column">
                      <p
                        className="text-muted mb-2"
                        style={{ marginLeft: "10px" }}
                      >
                        {code === "discount"
                          ? `Applicable on total excluding taxes (Discount will be applied on total amount before tax)`
                          : "Applicable on total excluding taxes"}
                      </p>
                      <div className="d-flex  align-items-center">
                        <FormControl sx={{ m: 1, width: "250px" }}>
                          <InputLabel id="demo-simple-select-label">
                            Select coupon code / offer
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select code / discount"
                            value={code}
                            onChange={(e) => setcode(e.target.value)}
                            required="true"
                            // MenuProps={MenuProps}
                            placeholder="Select rate plan"
                          >
                            {((subUser.toLowerCase() === "subuser" &&
                              hotelemail !==
                                "reservation@maharashtratourism.gov.in") ||
                              subUser.toLowerCase() === "admin") && (
                              <MenuItem value={"secretcode"}>
                                Coupon code
                              </MenuItem>
                            )}
                            <MenuItem value={"promocode"}>Offer</MenuItem>
                            {((subUser.toLowerCase() === "subuser" &&
                              hotelemail !==
                                "reservation@maharashtratourism.gov.in") ||
                              subUser.toLowerCase() === "admin") && (
                              <MenuItem value={"discount"}>Discount</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        {code === "secretcode" && (
                          <div className="d-flex align-items-center">
                            <TextField
                              label="Enter secret coupon code"
                              value={promoValue}
                              onChange={(e) => setpromoValue(e.target.value)}
                              fullWidth
                            ></TextField>
                          </div>
                        )}

                        {code === "promocode" && (
                          <>
                            {PromocodeData?.length > 0 ? (
                              <FormControl sx={{ m: 1, width: "250px" }}>
                                <InputLabel id="demo-simple-select-label">
                                  Select offer
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Select rates"
                                  value={promoValue}
                                  onChange={(e) => {
                                    setpromoValue(e.target.value);
                                  }}
                                  required="true"
                                  // MenuProps={MenuProps}
                                  placeholder="Select rate plan"
                                >
                                  {PromocodeData.map((e) => (
                                    <MenuItem value={e.promoCode}>
                                      {e.promoCode}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : (
                              <h4>
                                No offers created, create from Revenue
                                Management tab to apply
                              </h4>
                            )}
                          </>
                        )}
                        {code === "discount" && (
                          <div className="d-flex">
                            <FormControl style={{ width: "8rem" }}>
                              <InputLabel id="demo-simple-select-label">
                                Select discount type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select discount type"
                                value={dicountType}
                                onChange={(e) => setdicountType(e.target.value)}
                                required="true"
                                // MenuProps={MenuProps}
                                placeholder="Select discount type"
                              >
                                <MenuItem value={"percent"}>Percent</MenuItem>
                                <MenuItem value={"flat"}>Flat</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              label="Enter discount value"
                              value={discountAmount}
                              style={{ width: "6rem", marginLeft: "1rem" }}
                              onChange={(e) => {
                                // if (dicountType === "percent") {
                                //   if (
                                //     e.target.value >= 0 &&
                                //     e.target.value <= 100
                                //   )
                                setdiscountAmount(e.target.value);
                                // } else {
                                //   if (e.target.value >= 0)
                                //     setdiscountAmount(e.target.value);
                                // }
                              }}
                            ></TextField>
                          </div>
                        )}
                        {showPromoButton &&
                          code !== "discount" &&
                          PromocodeData?.length > 0 && (
                            <Button
                              style={{ marginLeft: "0.5rem", height: "3rem" }}
                              variant="custom-button"
                              onClick={() => applyingPromo()}
                            >
                              Apply
                            </Button>
                          )}
                        {showDiscountButton && code === "discount" && (
                          <Button
                            style={{ marginLeft: "0.5rem", height: "3rem" }}
                            variant="custom-button"
                            onClick={() => applyDiscount()}
                          >
                            Apply
                          </Button>
                        )}
                        {code !== "discount" && PromocodeData?.length > 0 && (
                          <Button
                            style={{ marginLeft: "0.5rem", height: "3rem" }}
                            variant="custom-button"
                            onClick={() => removePromo()}
                          >
                            Remove
                          </Button>
                        )}
                        {code === "discount" && (
                          <Button
                            style={{ marginLeft: "0.5rem", height: "3rem" }}
                            variant="custom-button"
                            onClick={() => removeDiscount()}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                      {PromoValidation?.validPromo === false && (
                        <p
                          className="text-danger"
                          style={{ marginLeft: "1rem" }}
                        >
                          Enter a valid offer
                        </p>
                      )}{" "}
                      {PromoValidation?.validPromo === true && (
                        <p
                          className="text-success"
                          style={{ marginLeft: "1rem" }}
                        >
                          Successfully applied offer
                        </p>
                      )}
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider style={{ marginBottom: "15px" }} />
                  <div
                    className="d-flex align-items-center "
                    style={{ marginTop: "1rem", marginLeft: "1rem" }}
                  >
                    <Checkbox
                      {...label}
                      onChange={(e) => toggleShowExpiresin(e)}
                    />
                    <h4 className="mr-3 mb-0">Enquiry</h4>
                  </div>
                  {showExpiresin && (
                    <div className="d-flex mt-4 align-items-center">
                      <h6 className="mb-0 mr-2 ml-3">Enquiry expires in</h6>
                      <TextField
                        style={{
                          width: "9rem",
                          marginRight: "1rem",
                        }}
                        name="expiry_time"
                        id="expiry_time"
                        label="Expiry time"
                        value={formik.values.expiry_time}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <FormControl
                        style={{
                          width: "10rem",
                          height: "2.5rem",
                          marginRight: "1rem",
                        }}
                      >
                        <InputLabel id="expiry_type">Expiry type</InputLabel>
                        <Select
                          labelId="expiry_type"
                          name="expiry_type"
                          id="expiry_type"
                          label="Expiry type"
                          value={formik.values.expiry_type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="mr-4"
                          style={{ width: "10rem", height: "2.5rem" }}
                        >
                          <MenuItem value={"MINUTES"}>Minutes</MenuItem>
                          <MenuItem value={"HOURS"}>Hours</MenuItem>
                          <MenuItem value={"DAYS"}>Days</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl style={{ width: "10rem", height: "2.5rem" }}>
                        <InputLabel id="duration_type">
                          Duration type
                        </InputLabel>
                        <Select
                          labelId="duration_type"
                          name="duration_type"
                          id="duration_type"
                          label="Duration type"
                          value={formik.values.duration_type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{ width: "10rem", height: "2.5rem" }}
                        >
                          <MenuItem value={"BEFORE"}>Before checkin</MenuItem>
                          <MenuItem value={"AFTER"}>After booking</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
          </Card>
        </Grid>
        {hotelType === "ISHA" && (
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                title="Complimentary Request"
              ></CardHeader>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      name="requestorName"
                      id="requestorName"
                      fullWidth
                      type="text"
                      placeholder="Requestor name"
                      value={formik.values.requestorName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Requestor name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      name="requestorContact"
                      id="requestorContact"
                      type="text"
                      fullWidth
                      placeholder="Requestor contact"
                      value={formik.values.requestorContact}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Requestor contact"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      name="requestorDept"
                      id="requestorDept"
                      fullWidth
                      type="text"
                      placeholder="Requestor department"
                      value={formik.values.requestorDept}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Requestor department"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      name="requestorApprover"
                      id="requestorApprover"
                      type="text"
                      fullWidth
                      placeholder="Requestor approver"
                      value={formik.values.requestorApprover}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Requestor approver"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={4} spacing={2} style={{ height: "1rem" }}>
        <Grid item container xs={12}>
          <Card variant="outlined">
            <CardHeader
              sx={{
                backgroundColor: "rgba(0, 0, 0, .03)",
                fontWeight: "bold !important",
              }}
              title="Booking Details"
            ></CardHeader>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold !important" }}
                  >
                    Check-in: {checkin.toDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold !important" }}
                  >
                    Check-out: {checkout.toDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold !important" }}
                  >
                    Total length of stay: {`${diffDays} Days`}
                  </Typography>
                </Grid>
                <Divider sx={{ width: "inherit", padding: 1 }} />
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold !important" }}
                  >
                    Room Selections Summary
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {datas?.map((roomDetail) => {
                  return (
                    <Grid
                      key={roomDetail.roomTypeId}
                      paddingTop={1}
                      item
                      container
                      xs={12}
                    >
                      <Grid item xs={12}>
                        <Typography
                          paddingTop={1}
                          paddingBottom={1}
                          variant="body1"
                          sx={{ fontWeight: "bold !important" }}
                        >
                          {roomDetail.original.roomTypeName},{" "}
                          {roomDetail.ratePlan.ratePlanName}
                        </Typography>

                        <Grid item xs={12} container spacing={1}>
                          {Object.keys(roomDetail.selectionData)
                            .slice(0, roomDetail.roomCount)
                            .map((selectionCount) => {
                              return (
                                <Grid
                                  item
                                  key={selectionCount + roomDetail.roomTypeId}
                                  xs={12}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: "bold !important" }}
                                  >
                                    {`Room ${selectionCount} | Adults: ${
                                      roomDetail.selectionData[
                                        Number(selectionCount)
                                      ].A
                                    }, Children: ${
                                      roomDetail.selectionData[
                                        Number(selectionCount)
                                      ].C
                                    }`}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          {showRooms && (
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold !important" }}
                              >
                                Selected Rooms Ids :{" "}
                                {/* {roomDetail.selectedRooms
                                  .reduce((results, selectedId, indx) => {
                                    if (selectedId)
                                      results.push(
                                        roomDetail["combos"]["onlineRoomIds"][
                                          indx
                                        ]
                                      );
                                    return results;
                                  }, [])
                                  .join(",")} */}
                                {roomDetail.selectedRooms
                                  .reduce((results, selectedId, indx) => {
                                    if (selectedId) {
                                      if (
                                        indx >=
                                        roomDetail["combos"]["onlineRoomIds"]
                                          ?.length
                                      ) {
                                        results.push(
                                          roomDetail["combos"][
                                            "offlineRoomIds"
                                          ][
                                            indx -
                                              roomDetail["combos"][
                                                "onlineRoomIds"
                                              ]?.length
                                          ]
                                        );
                                      } else {
                                        results.push(
                                          roomDetail["combos"]["onlineRoomIds"][
                                            indx
                                          ]
                                        );
                                      }
                                    }
                                    return results;
                                  }, [])
                                  .join(",")}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        <Divider />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader
              sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
              title="Your price summary"
            ></CardHeader>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                {/* <Grid item container xs={12}>
                  <Grid item container justifyContent="flex-start" xs={6}>
                    <Typography variant="body2">
                      Traditional Model Price
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <Typography sx={{ textDecoration: "line-through" }}>
                      {completePrice}
                    </Typography>
                  </Grid>
                </Grid> */}
                <Grid item container xs={12}>
                  <Grid item container justifyContent="flex-start" xs={6}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold !important" }}
                    >
                      Room Price
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <Typography
                      sx={{ fontWeight: "bold !important" }}
                    >{`${hotelCurrency} ${subtotal?.sub?.toFixed(
                      2
                    )}`}</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container justifyContent="flex-start" xs={6}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold !important" }}
                    >
                      Taxes
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <Typography
                      sx={{ fontWeight: "bold !important" }}
                    >{`${hotelCurrency} ${subtotal?.tax?.toFixed(
                      2
                    )}`}</Typography>
                  </Grid>
                </Grid>
                {/* {(code === "promocode" || code === "secretcode") &&
                  PromoValidation?.validPromo === true && (
                    <Grid item container xs={12}>
                      <Grid item container justifyContent="flex-start" xs={6}>
                        <Typography variant="body2">
                          Discounted Value(Pre-Tax amount)
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent="flex-end" xs={6}>
                        <Typography>{`${hotelCurrency} ${
                          dicountType === "percent"
                            ? parseFloat(subtotal?.sub).toFixed(2) -
                              parseFloat(subtotal?.sub).toFixed(2) *
                                (parseFloat(discountAmount) / 100)
                            : parseFloat(subtotal?.sub).toFixed(2) -
                              parseFloat(discountAmount)
                        }`}</Typography>
                      </Grid>
                    </Grid>
                  )} */}
                {/* {code === "discount" && (
                  <Grid item container xs={12}>
                    <Grid item container justifyContent="flex-start" xs={6}>
                      <Typography variant="body2">
                        Discounted Value(Pre-Tax amount)
                      </Typography>
                    </Grid>
                    <Grid item container justifyContent="flex-end" xs={6}>
                      <Typography>{`${hotelCurrency} ${
                        parseFloat(subtotal?.sub?.toFixed(2)) -
                        (parseFloat(subtotal?.sub?.toFixed(2)) -
                          parseFloat(subtotal?.sub?.toFixed(2)) *
                            (parseFloat(selectedPromocodeDatas[0]?.discount) /
                              100))
                      }`}</Typography>
                    </Grid>
                  </Grid>
                )} */}
                <Grid item container xs={12}>
                  <Grid item container justifyContent="flex-start" xs={6}>
                    <Typography
                      sx={{ fontWeight: "bold !important" }}
                      variant="body2"
                    >
                      Total price
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <TextField
                      id="standard-basic"
                      hiddenLabel
                      variant="standard"
                      sx={{ fontWeight: "bold !important" }}
                      value={formik.values.totalPrice}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {hotelCurrency}
                          </InputAdornment>
                        ),
                      }}
                      disabled={
                        JSON.parse(
                          props?.data?.accessControl?.user_feature_map
                            ?.EnquiryDashboard
                        ).read !== "YES"
                          ? JSON.parse(
                              props?.data?.accessControl?.user_feature_map
                                ?.EditBookingPrice
                            ).read === "YES"
                            ? false
                            : true
                          : true
                      }
                      style={{ width: "6.4rem" }}
                      inputProps={inputProps}
                      onChange={(e) => {
                        formik.setFieldValue("totalPrice", e.target.value);
                        setisDaylevelPricingSet(false);
                      }}
                      onBlur={(e) => {
                        finalPriceChange(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Button
                    variant="custom-button"
                    onClick={
                      copyDayLevelPricingData?.length > 0 &&
                      toggleDayLevelPricingDrawer("right", true)
                    }
                  >
                    Day Level Pricing
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {hotelType !== "ISHA" && (
          <Grid item xs={12}>
            <Divider style={{ marginBottom: "15px" }} />{" "}
            <div className="d-flex align-items-center ">
              <Checkbox
                {...label}
                defaultChecked={hotelCountry === "United States" ? true : false}
                onChange={(e) => toggleCollectpayment(e)}
              />
              <h4 className="mr-3 mb-0">Collect payment</h4>
            </div>
            {showCollectpayment && (
              <>
                <div className="d-flex mt-4 align-items-center">
                  <FormControl sx={{ m: 1, width: "250px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select payment method
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select payment method"
                      value={paymentMode}
                      onChange={(e) => setpaymentMode(e.target.value)}
                      required="true"
                      // MenuProps={MenuProps}
                      placeholder="Select payment method"
                    >
                      {JSON.parse(
                        props?.data?.accessControl?.user_feature_map
                          ?.OtherPaymentModes
                      ).read === "YES" &&
                        hotelCountry === "United States" && (
                          <MenuItem value={"SWIPE CARD"}>SWIPE CARD</MenuItem>
                        )}
                      {JSON.parse(
                        props?.data?.accessControl?.user_feature_map
                          ?.CashPayment
                      ).read === "YES" && (
                        <MenuItem value={"CASH"}>CASH</MenuItem>
                      )}
                      {JSON.parse(
                        props?.data?.accessControl?.user_feature_map
                          ?.OfflineCardPayment
                      ).read === "YES" && (
                        <MenuItem value={"CARD"}>OFFLINE CARD PAYMENT</MenuItem>
                      )}
                      {hotelCurrency === "INR" && (
                        <MenuItem value={"UPI"}>UPI</MenuItem>
                      )}
                      {JSON.parse(
                        props?.data?.accessControl?.user_feature_map
                          ?.OtherPaymentModes
                      ).read === "YES" && (
                        <MenuItem value={"OTHER"}>OTHER</MenuItem>
                      )}
                      {JSON.parse(
                        props?.data?.accessControl?.user_feature_map
                          ?.OtherPaymentModes
                      ).read === "YES" && (
                        <MenuItem value={"BANK TRANSFER"}>
                          BANK TRANSFER
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>

                  {paymentMode !== "SWIPE CARD" && (
                    <div className="d-flex align-items-center">
                      <TextField
                        label="Amount"
                        value={paymentAmount}
                        onChange={(e) => setpaymentAmount(e.target.value)}
                        fullWidth
                        placeholder="Enter payment amount"
                        name="schedule_name"
                        id="schedule_name"
                      ></TextField>
                      {paymentAmount > 0 && (
                        <TextField
                          label="Remark"
                          value={remark}
                          onChange={(e) => setremark(e.target.value)}
                          fullWidth
                          placeholder="Enter remark"
                          name="schedule_name"
                          className="ml-2"
                          id="schedule_name"
                        ></TextField>
                      )}
                    </div>
                  )}
                </div>
                {hotelCountry === "United States" &&
                  paymentMode === "SWIPE CARD" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div style={{ paddingLeft: "7px" }}>
                          <div className="amountWrapper">
                            <div className="fieldWrapper d-flex justify-content-between">
                              <TextField
                                required
                                id="outlined-name"
                                type={"text"}
                                label="First name"
                                name="firstName"
                                value={addCardFirstName}
                                onChange={(e) =>
                                  /^[a-zA-Z ]*$/.test(e.target.value) &&
                                  setAddCardFirstName(e.target.value)
                                }
                                error={error.firstName}
                                helperText={
                                  error.firstName
                                    ? addCardFirstName?.length === 0
                                      ? "Enter first name"
                                      : "Enter correct name"
                                    : ""
                                }
                                sx={{ width: "48%" }}
                              />
                              <TextField
                                required
                                id="outlined-name"
                                label="Last name"
                                name="lastName"
                                type={"text"}
                                value={addCardLastName}
                                onChange={(e) =>
                                  /^[a-zA-Z ]*$/.test(e.target.value)
                                    ? setAddCardLastName(e.target.value)
                                    : setAddCardLastName(addCardLastName)
                                }
                                error={error.lastName}
                                helperText={
                                  error.lastName
                                    ? addCardLastName?.length === 0
                                      ? "Enter last name"
                                      : "Enter correct name"
                                    : ""
                                }
                                sx={{ width: "48%" }}
                              />
                            </div>
                            <div className="fieldWrapper d-flex mt-4">
                              <TextField
                                required
                                id="outlined-name"
                                label="Card Number"
                                name="cardNumber"
                                type={"number"}
                                value={addCardNumber}
                                onChange={(e) =>
                                  e.target.value.length <= 16 &&
                                  setAddCardNumber(e.target.value)
                                }
                                error={
                                  (!checkLuhn() && addCardNumber?.length > 0) ||
                                  error.number
                                    ? true
                                    : false
                                }
                                helperText={
                                  (!checkLuhn() && addCardNumber?.length > 0) ||
                                  error.number
                                    ? "invalid card number"
                                    : ""
                                }
                                sx={{ width: "100%" }}
                              />
                              {getCardType(addCardNumber?.slice(0, 16)) && (
                                <img
                                  style={{
                                    width: "60px",
                                    height: "40px",
                                    marginTop: "5px",
                                    marginLeft: "-80px",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    getCardType(addCardNumber?.slice(0, 16))
                                      ?.imageUrl
                                  }
                                  alt=" "
                                />
                              )}
                            </div>
                            <div className="fieldWrapper d-flex justify-content-between mt-4">
                              <TextField
                                // required
                                id="outlined-name"
                                label="Card CVV"
                                name="cardCVV"
                                type={"number"}
                                value={cardNumberCVVFormat(addCardCVV)}
                                onChange={(e) => setAddCardCVV(e.target.value)}
                                sx={{ width: "48%" }}
                              />
                              <TextField
                                required
                                id="outlined-name"
                                label="Card Expiry (MMYY)"
                                name="cardExpiry"
                                type={"number"}
                                value={addCardExpiry}
                                onChange={(e) =>
                                  e.target.value?.length < 5 &&
                                  setAddCardExpiry(e.target.value)
                                }
                                error={
                                  (!checkExpiry() &&
                                    addCardExpiry?.length > 0) ||
                                  error.expiry
                                }
                                helperText={
                                  (!checkExpiry() &&
                                    addCardExpiry?.length > 0) ||
                                  error.expiry
                                    ? "invalid expiry"
                                    : ""
                                }
                                sx={{ width: "48%" }}
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(GroupBookingDetails);
