import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import {
  Stack,
  Button,
  TextField,
  Typography,
  Tooltip,
  Divider,
  Chip,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api/index";
import noBooking from "./not_found.svg";
import MockLoaderTable from "./MockLoaderTable";
import { connect } from "react-redux";

function CounterPaymentsLogTable({
  counterPaymentsData,
  bookingId,
  hotelId,
  updateInvoice,
  upDateViewManage,
  loggedUserEmail,
  updateSummaryData,
  setOpenManagePay,
  data,
  hotelType,
  pgName,
}) {
  // getting the services data for the invoice

  const products = counterPaymentsData;

  const columns = [
    {
      dataField: "log_timestamp",
      text: "Log Time",
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "15%",
      },
      style: {
        fontSize: "12px",
        wordBreak: "break-all",
        width: "15%",
      },
      editable: false,
    },
    {
      dataField: "service_date",
      text: "Service Date",
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "10%",
      },
      style: {
        fontSize: "12px",
        wordBreak: "break-all",
        width: "10%",
      },
      editable: false,
      hidden: true,
    },
    {
      dataField: "user_email",
      text: "Action By",
      sort: true,
      headerStyle: {
        fontSize: "12px",
        wordBreak: "break-all",
        width: "12.5%",
      },
      style: {
        fontSize: "12px",
        wordBreak: "break-all",
        width: "12.5%",
      },
      editable: false,
    },
    {
      dataField: "service_description",
      text: "Payment type",
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "15%",
      },
      style: {
        fontSize: "12px",
        wordBreak: "break-all",
        width: "15%",
      },
      editable: false,
    },

    {
      dataField: "service_amount_desc",
      text: "Description",
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "15%",
      },
      style: {
        fontSize: "12px",
        wordBreak: "break-all",
        width: "15%",
      },
      formatter: (cell) => {
        return (
          <Typography style={{ position: "relative", wordWrap: "break-word" }}>
            {cell}
          </Typography>
        );
      },
      editable: false,
    },
    {
      dataField: "approved",
      text: "Accounts payment status",
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "12.5%",
      },
      style: {
        fontSize: "12px",
        wordBreak: "break-all",
        width: "12.5%",
      },
      editable: false,
      formatter: (cell) => {
        if (cell.toLowerCase() === "yes") {
          return "APPROVED";
        } else if (cell.toLowerCase() === "no") {
          return "REJECTED";
        } else {
          return cell;
        }
      },
      hidden:
        JSON.parse(
          data?.accessControl?.user_feature_map?.PaymentConfirmationFolio
        ).read !== "YES",
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "10%",
      },
      style: {
        fontSize: "12px",
        wordBreak: "break-all",
        width: "10%",
      },
      editable: false,
    },
    {
      dataField: "refund_amount",
      text: "Refunded Amount",
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "10%",
      },
      style: {
        fontSize: "12px",
        wordBreak: "break-all",
        width: "10%",
      },
      formatter: (cell) => {
        return parseFloat(cell).toFixed(2);
      },
      editable: false,
    },
    {
      dataField: "service_id",
      text: " ",
      sort: false,
      headerStyle: {
        fontSize: "12px",
        width: "10%",
      },
      // hidden: (cell, row) => {
      //   const cellData = counterPaymentsData?.filter(
      //     (item) => item.service_id === cell
      //   )[0];
      //   if (cellData?.amount > cellData?.refund_amount) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // },
      formatter: (cell, row) => {
        const cellData = counterPaymentsData?.filter(
          (item) => item.service_id === cell
        )[0];
        return cellData?.amount > cellData?.refund_amount ? (
          (JSON.parse(
            data?.accessControl?.user_feature_map?.PaymentChargeAccess
          ).read === "YES" &&
            hotelType === "ISHA") ||
          hotelType !== "ISHA" ? (
            JSON.parse(
              data?.accessControl?.user_feature_map?.CounterPaymentsRefund
            ).read === "YES" ? (
              <button
                className="btn viewManageCTABtns"
                onClick={() => handleRefundCounterOpen(row, cell)}
              >
                Refund
              </button>
            ) : (
              ""
            )
          ) : (
            ""
          )
        ) : (
          ""
        );
      },
      editable: false,
    },
    {
      isDummyField: true,
      text: " ",
      sort: false,
      headerStyle: {
        fontSize: "12px",
        width: "15%",
      },
      style: {
        fontSize: "12px",
        wordBreak: "break-all",
        width: "15%",
      },
      formatter: (cell, row) => {
        return (
          <Button
            variant="custom-button"
            onClick={() => {
              setCurrentSelectedMode(row?.service_description);
              setPayModeId(row?.service_id);
              handleOpenChangePayModeModal();
            }}
          >
            Change payment mode
          </Button>
        );
      },
    },
  ];

  // Change the payment mode
  // Change the payment modes for the offline payment modes
  const [currentSelectedMode, setCurrentSelectedMode] = useState("");
  const [currentUpdatedMode, setCurrentUpdatedMode] = useState("");
  const [payModeId, setPayModeId] = useState(null);

  const [openChangePayMode, setOpenChangePayMode] = useState(false);
  const handleOpenChangePayModeModal = () => {
    return setOpenChangePayMode(true);
  };
  const handleCloseChangePayModeModal = () => {
    setOpenChangePayMode(false);
    setCurrentSelectedMode("");
    setCurrentUpdatedMode("");
    setPayModeId(null);
  };
  // Available pay modes :
  const availablePayModes = [
    "Cash Payment",
    "Offline Card Payment",
    "Offline Check Payment",
    "UPI Payment",
    "Bank Transfer Payment",
    "Other Source Payment",
  ];

  // API to change the available payment modes
  const [payModeLoader, setPayModeLaoder] = useState(false);
  const handleChangePaymentMode = async () => {
    setPayModeLaoder(true);
    try {
      const res = await handleSpringDataRequest(
        `core/api/v1/payment-service/update-offline-payment-method/?bookingId=${bookingId}&serviceId=${payModeId}&newMethod=${currentUpdatedMode}`
      );
      toast.success(res);
    } catch (err) {
      toast.error(err);
    } finally {
      upDateViewManage();
      handleCloseChangePayModeModal();
      setPayModeLaoder(false);
    }
  };

  // refund modal

  const [openRefund, setOpenRefund] = useState(false);
  const [serviceId, setServiceId] = useState(0);
  const [amount, setAmount] = useState(0);
  const [preRefundAmt, setPreRefundAmt] = useState(0);
  function handleRefundCounterOpen(row, val) {
    setOpenRefund(true);
    setServiceId(val);
    setAmount(row?.amount);
    setPreRefundAmt(row?.refund_amount);
  }
  const handleRefundCounterClose = () => setOpenRefund(false);

  //refund  states

  const [counterRefundAmt, setCounterRefundAmt] = useState(0.0);
  const [counterRefundDate, setCounterRefundDate] = useState(new Date());
  const [counterRefundName, setCounterRefundName] = useState("");

  // refund modal loading
  const [openRefundLoading, setOpenRefundLoading] = useState(false);

  // refund modal --- api
  function handleRefundCounter() {
    setOpenRefundLoading(true);
    djangoHandleDataMutationRequest("POST", "invoice/refundOfflinePayment/", {
      hotelId: hotelId,
      booking_id: bookingId,
      service_id: serviceId,
      amount: Number(counterRefundAmt),
      offline_card_refund_date: moment(counterRefundDate).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      refund_reason: counterRefundName,
      payment_type: counterPaymentsData?.filter(
        (item) => item.service_id === serviceId
      )[0]?.service_amount_type,
      logged_user_email: loggedUserEmail,
    })
      .then((res) => upDateViewManage())
      .then((res) => toast.success("Successfully refunded the amount"))
      .catch((err) => toast.error("Error in refunding the amount"))
      .then((res) => handleRefundCounterClose())
      .then((res) => setCounterRefundAmt(0.0))
      .then((res) => setCounterRefundName(""))
      .then((res) => setCounterRefundDate(new Date()))
      .then((res) =>
        setTimeout(() => {
          setOpenRefundLoading(false);
        }, 1000)
      );
  }

  if (counterPaymentsData?.length === 0)
    return (
      <div
        className="d-flex flex-column align-items-center"
        style={{ marginTop: "9rem" }}
      >
        <img src={noBooking} alt="" />
        <p className="mt-3">No payments in this category</p>
      </div>
    );

  return (
    <>
      {products ? (
        <div className="invoiceSummTableWrapper">
          <ToolkitProvider
            keyField="service_id"
            data={products}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="folioTableWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bordered={false}
                    pagination={paginationFactory()}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <MockLoaderTable />
      )}

      {/* refund modal to refund the service items amount */}
      <Modal
        open={openRefund}
        onClose={handleRefundCounterClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Refund counter payments</div>
              <div className="close" onClick={() => handleRefundCounterClose()}>
                X
              </div>
            </div>
            <div className="fieldWrapper mt-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Refund date"
                  value={counterRefundDate}
                  onChange={(newValue) => {
                    setCounterRefundDate(newValue);
                  }}
                  name="refund_date"
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="fieldWrapper mt-3">
              <TextField
                id="outlined-name"
                label="Refund reason"
                name="refund_reason"
                value={counterRefundName}
                onChange={(e) => setCounterRefundName(e.target.value)}
                sx={{ width: "100%" }}
                helperText={
                  counterRefundName.length === 0 && (
                    <Typography className="text-danger">
                      Refund reason is required
                    </Typography>
                  )
                }
              />
            </div>
            <div className="fieldWrapper mt-3">
              <TextField
                id="outlined-name"
                label="Amount"
                type="text"
                name="refund_amount"
                value={counterRefundAmt}
                onChange={(e) =>
                  /^[0-9.]*$/.test(e.target.value)
                    ? setCounterRefundAmt(e.target.value)
                    : setCounterRefundAmt(counterRefundAmt)
                }
                sx={{ width: "100%" }}
                helperText={
                  counterRefundAmt > amount - preRefundAmt && (
                    <Typography className="text-danger">
                      Refund amount exceeding bill amount
                    </Typography>
                  )
                }
              />
            </div>

            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleRefundCounterClose()}
              >
                Cancel
              </button>
              {openRefundLoading === false ? (
                <button
                  className="submit"
                  onClick={() => handleRefundCounter()}
                  style={{ width: "150px" }}
                  disabled={
                    counterRefundAmt > 0 &&
                    counterRefundAmt <= amount - preRefundAmt &&
                    counterRefundName.length > 0
                      ? false
                      : true
                  }
                >
                  Refund
                </button>
              ) : (
                <button className="submit" style={{ width: "150px" }}>
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      {/* Change the payment mode modal */}
      <Modal
        open={openChangePayMode}
        onClose={handleCloseChangePayModeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            maxWidth: "450px",
            overflowX: "hidden",
            overflowY: "visible",
          }}
        >
          <Stack
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
            direction={"row"}
          >
            <Typography style={{ fontWeight: 500 }}>
              Change payment mode
            </Typography>
            <Typography
              style={{ cursor: "pointer", fontWeight: 500 }}
              onClick={handleCloseChangePayModeModal}
            >
              X
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            {availablePayModes?.map(
              (payMode) =>
                payMode !== currentSelectedMode && (
                  <Typography
                    style={{
                      cursor: "pointer",
                      marginRight: "5px",
                      marginBottom: "5px",
                    }}
                    onClick={() => setCurrentUpdatedMode(payMode)}
                  >
                    <Chip
                      variant={currentUpdatedMode !== payMode && "outlined"}
                      label={payMode}
                    />
                  </Typography>
                )
            )}
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            style={{
              position: "relative",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "15px",
            }}
          >
            <Typography onClick={handleCloseChangePayModeModal}>
              Cancel
            </Typography>
            <Button
              variant="custom-button"
              onClick={handleChangePaymentMode}
              disabled={payModeLoader}
            >
              {payModeLoader ? <CircularProgress size="15px" /> : "Confirm"}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(CounterPaymentsLogTable);
