import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { toast } from "material-react-toastify";
import MockLoaderTable from "./MockLoaderTable";
import { Button, CircularProgress, Divider } from "@mui/material";
import cellEditFactory from "react-bootstrap-table2-editor";

function IshaGpms({
  data,
  bookingId,
  hotelId,
  updateInvoice,
  updateSummaryData,
}) {
  const [gpmsData, setgpmsData] = useState([]);
  const [successRefund, setsuccessRefund] = useState([]);
  const [loader, setloader] = useState(false);
  const columns = [
    {
      dataField: "pg_charge_id",
      text: "Payment Charge ID",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      style: {
        wordBreak: "break-all",
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "payment_amount",
      text: "Payment Amount",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },

    {
      dataField: "refund_amount",
      text: "Refund Amount",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },

      style: (cell, row, rowIndex, colIndex) => {
        return {
          fontWeight: "500",
          textDecoration: "underline",
        };
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Price should be numeric",
          };
        }
        return true;
      },
    },

    {
      dataField: "cancel_charge",
      text: "Cancel Charge",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },

      style: (cell, row, rowIndex, colIndex) => {
        return {
          fontWeight: "500",
          textDecoration: "underline",
        };
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Price should be numeric",
          };
        }
        return true;
      },
    },

    {
      dataField: "refund_date",
      text: "Refund Date",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "refund_status",
      text: "Refund Status",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "id",
      text: " ",
      sort: false,
      headerStyle: {
        fontSize: "15px",
      },
      formatter: (cell, row) => {
        return (
          row.refund_status === "PENDING" && (
            <Button
              variant="custom-button"
              onClick={() => procesRefund(row, cell)}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                "Refund"
              )}
            </Button>
          )
        );
      },
      editable: false,
    },
  ];

  const successcolumns = [
    {
      dataField: "pg_charge_id",
      text: "Payment Refund ID",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      style: {
        wordBreak: "break-all",
        fontSize: "15px",
      },
      editable: false,
    },

    {
      dataField: "refund_amount",
      text: "Refund Amount",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },

      editable: false,
    },

    {
      dataField: "refund_date",
      text: "Refund Date",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "refund_status",
      text: "Refund Status",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
  ];

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    handleSpringDataRequest(
      `core/api/v1/payment-service/get-gpms-pending-refunds?bookingId=${bookingId}`
    )
      .then((res) => {
        setgpmsData(res.pendingRefunds);
        setsuccessRefund(res.otherRefunds);
      })
      .catch((err) => toast.error("Error in fetching the data"));
  };

  const procesRefund = (row, cell) => {
    const payload = {
      pendingRefundId: row.id,
      hotelId: hotelId,
      bookingId: bookingId,
      sfPaymentId: row.sf_payment_id,
      pgChargeId: row.pg_charge_id,
      refundAmt: row.refund_amount,
      cancelFee: row.cancel_charge,
    };
    setloader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/payment-service/process-gpms-pending-refund`,
      payload
    )
      .then((res) => {
        toast.success("Operation successfull");
        getAllData();
        updateInvoice();
        updateSummaryData();
        setloader(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        getAllData();
        setloader(false);
      });
  };

  return (
    <div>
      {" "}
      {gpmsData ? (
        <div className="invoiceSummTableWrapper">
          <h2 style={{ marginTop: "1rem" }}>Pending</h2>
          <ToolkitProvider
            keyField="service_id"
            data={gpmsData}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="folioTableWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bordered={false}
                    pagination={paginationFactory()}
                    cellEdit={cellEditFactory({
                      mode: "click",
                      blurToSave: true,
                      afterSaveCell: (oldValue, newValue, row, column) => {
                        console.log("row", row, newValue, column);
                        if (column.dataField === "refund_amount") {
                          row.refund_amount = newValue;
                        } else if (column.dataField === "cancel_charge") {
                          row.cancel_charge = newValue;
                        }
                      },
                    })}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <MockLoaderTable />
      )}
      <Divider style={{ marginTop: "2rem", marginBottom: "2rem" }} />
      {successRefund ? (
        <div className="invoiceSummTableWrapper">
          <h2>Processed</h2>
          <ToolkitProvider
            keyField="service_id"
            data={successRefund}
            columns={successcolumns}
            search
          >
            {(props) => (
              <>
                <div className="folioTableWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bordered={false}
                    pagination={paginationFactory()}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <MockLoaderTable />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(IshaGpms);
