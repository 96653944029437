import { LoadingButton } from "@mui/lab";
import { AppBar, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  Link as NewLink,
  Redirect,
  Switch,
  useHistory,
} from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import { getAllImagesAndUrl } from "../../../pages/BookingDashboard/apis/BrandingModalApi";
import LoginComponent from "./LoginComponent";
import NewForgetPasswordPage from "./NewForgetPassword";
import ResetPage from "./ResetPasswordPage";
import moment from "moment";
import IshaEnquiryValidation from "../../../pages/IshaFoundation/IshaEnquiryValidation";
import FolioInvoiceRender from "../../../pages/CTA/FolioInvoiceRender";
import PayNowLandingPage from "../../../pages/AccountsAndBilling/PayNowLandingPage";
import AccountsDashboardManagement from "../../../pages/AccountsAndBilling/AccountsDashboardManagement";
import GetIshaGuestDetails from "../../../pages/IshaFoundation/GetIshaGuestDetails";
import PaymentConfirmation from "./PaymentConfirmation";
import AddCardPayment from "./AddCardPayment";
import AirbnbConnect from "./AirbnbConnect";
import RequestToBookRender from "../../../pages/BookingEngine/RequestToBookRender";

export const SignIn = () => {
  const theme = useTheme();
  const history = useHistory();
  const [masterAdminCheck, setMasterAdminCheck] = useState(
    `${localStorage.getItem("token")}`.split("||")[1] === "master"
      ? true
      : false
  );
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [ImageLogo, setImageLogo] = useState();
  const getAllData = async () => {
    try {
      const data = await getAllImagesAndUrl();
      setImageLogo(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(async () => {
    await getAllData();
  }, []);
  const url = new URL(window.location.href);
  const expiryTimestamp = url.searchParams.get("hash");
  const hash_id = url.searchParams.get("hash_id");
  const customerEmail = url.searchParams.get("custEmail");
  return (
    <Grid container className="bg-white" width="100%" height="100%" padding={2}>
      <Grid item height="fit-content" container padding={1} xs={12} spacing={3}>
        {expiryTimestamp === null && hash_id === null && (
          <Grid
            container
            item
            justifyContent="flex-start"
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
          >
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <NewLink to="/">
                <img
                  style={{
                    maxHeight: "100px",
                    maxWidth: "250px",
                    cursor: "pointer",
                  }}
                  src={ImageLogo?.logoUrl}
                ></img>
              </NewLink>
            </Grid>
          </Grid>
        )}
        {ImageLogo?.status === "DISABLED" &&
        expiryTimestamp === null &&
        hash_id === null &&
        !masterAdminCheck ? (
          <Grid
            container
            justifyContent={matches ? "flex-end" : "flex-start"}
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
          >
            <Typography>
              Don't have an account yet?
              <Link
                sx={{ paddingLeft: 1 }}
                href="https://join.stayflexi.com"
                to="https://join.stayflexi.com"
                target="_blank"
              >
                Sign Up
              </Link>
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
        {masterAdminCheck ? (
          <Grid
            container
            justifyContent={matches ? "flex-end" : "flex-start"}
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
          >
            <LoadingButton
              sx={{ height: "fit-content", width: "fit-content" }}
              variant="solid"
              onClick={() => {
                setMasterAdminCheck(false);
                history.push("/auth/login");
              }}
            >
              Login as hotel
            </LoadingButton>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {expiryTimestamp === null && hash_id === null ? (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          width="100%"
          display="grid"
          gridTemplateColumns={matches ? "25%" : null}
          gridTemplateRows={matches ? "65%" : null}
          height="fit-content"
        >
          <Switch>
            <ContentRoute
              exact
              path="/enquiry-validation"
              component={IshaEnquiryValidation}
            ></ContentRoute>
            <ContentRoute
              exact
              path="/get-stay-invoice"
              component={FolioInvoiceRender}
            ></ContentRoute>
            <ContentRoute
              exact
              path="/request-to-book-status"
              component={RequestToBookRender}
            ></ContentRoute>

            <ContentRoute
              path="/masterAdmin/login"
              component={LoginComponent}
            />
            <ContentRoute path="/auth/login" component={LoginComponent} />
            <ContentRoute
              path="/masterAdmin/forget-password"
              component={NewForgetPasswordPage}
            />

            <ContentRoute
              path="/auth/forget-password"
              component={NewForgetPasswordPage}
            />
            <ContentRoute
              path="/auth/reset"
              render={(props) => <ResetPage {...props} />}
            />

            <ContentRoute path="/pay-now" component={PayNowLandingPage} />

            <ContentRoute
              path="/accounts-dashboard-management"
              component={AccountsDashboardManagement}
            />

            <ContentRoute
              path="/payment-confirmation"
              component={PaymentConfirmation}
            />

            <ContentRoute
              path="/addPaymentConfirmationCard"
              component={AddCardPayment}
            />

            <ContentRoute path="/airbnb-connect" component={AirbnbConnect} />
            <Redirect
              from="/"
              exact={true}
              to={masterAdminCheck ? "/masterAdmin/login" : "/auth/login"}
            />
            <Redirect
              from="*"
              exact={true}
              to={masterAdminCheck ? "/masterAdmin/login" : "/auth/login"}
            />
            <Redirect
              from="/auth"
              exact={true}
              to={masterAdminCheck ? "/masterAdmin/login" : "/auth/login"}
            />
          </Switch>
        </Grid>
      ) : expiryTimestamp !== null && hash_id === null ? (
        <Grid
          container
          item
          xs={12}
          // justifyContent="center"
          // alignItems="center"
          width="100%"
          // display="grid"
          // gridTemplateColumns={matches ? "25%" : null}
          // gridTemplateRows={matches ? "65%" : null}
          // height="fit-content"
        >
          <Switch>
            <ContentRoute
              exact
              path="/enquiry-validation"
              component={IshaEnquiryValidation}
            ></ContentRoute>
            <ContentRoute
              exact
              path="/get-stay-invoice"
              component={FolioInvoiceRender}
            ></ContentRoute>
            <ContentRoute
              exact
              path="/request-to-book-status"
              component={RequestToBookRender}
            ></ContentRoute>
          </Switch>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          // justifyContent="center"
          // alignItems="center"
          width="100%"
          // display="grid"
          // gridTemplateColumns={matches ? "25%" : null}
          // gridTemplateRows={matches ? "65%" : null}
          // height="fit-content"
        >
          <Switch>
            <ContentRoute
              exact
              path="/getGuestInfo"
              component={GetIshaGuestDetails}
            ></ContentRoute>
          </Switch>
        </Grid>
      )}
      {expiryTimestamp === null &&
        hash_id === null &&
        customerEmail === null && (
          <AppBar
            elevation={0}
            position="fixed"
            color="inherit"
            style={{ top: "auto", bottom: 0, alignItems: "center" }}
          >
            {ImageLogo?.status === "DISABLED" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography padding={2} sx={{ color: "#5C5C5C" }}>
                  {`© ${moment().format(
                    "YYYY"
                  )} Stayflexi, Inc. San Francisco, California`}
                </Typography>
                <Link
                  underline="hover"
                  href="https://business.stayflexi.com/terms"
                  to="https://business.stayflexi.com/terms"
                  padding={2}
                  target="_blank"
                >
                  Privacy
                </Link>
                <Link
                  underline="hover"
                  href="https://business.stayflexi.com/terms"
                  to="https://business.stayflexi.com/terms"
                  padding={2}
                  target="_blank"
                >
                  Legal
                </Link>
                <Link
                  underline="hover"
                  href="https://business.stayflexi.com/terms"
                  to="https://business.stayflexi.com/terms"
                  padding={2}
                  target="_blank"
                >
                  Contact
                </Link>
              </div>
            )}
            {ImageLogo?.status === null && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography padding={2} sx={{ color: "#5C5C5C" }}>
                  {`© ${moment().format(
                    "YYYY"
                  )} Stayflexi, Inc. San Francisco, California`}
                </Typography>
                <Link
                  underline="hover"
                  href="https://business.stayflexi.com/terms"
                  to="https://business.stayflexi.com/terms"
                  padding={2}
                  target="_blank"
                >
                  Privacy
                </Link>
                <Link
                  underline="hover"
                  href="https://business.stayflexi.com/terms"
                  to="https://business.stayflexi.com/terms"
                  padding={2}
                  target="_blank"
                >
                  Legal
                </Link>
                <Link
                  underline="hover"
                  href="https://business.stayflexi.com/terms"
                  to="https://business.stayflexi.com/terms"
                  padding={2}
                  target="_blank"
                >
                  Contact
                </Link>
              </div>
            )}
          </AppBar>
        )}
    </Grid>
  );
};
