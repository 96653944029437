import { Grid, Button } from "@mui/material";
import { toast } from "material-react-toastify";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { handleSpringDataRequest } from "../../api";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import RunNightAuditModal from "./RunNightAuditModal";

function RunNightAudit() {
  const [loader, setloader] = useState(false);
  const [openNightAudit, setopenNightAudit] = useState(false);
  const handleOpenNightAuditModal = () => setopenNightAudit(true);
  const handleCloseNightAuditModal = () => setopenNightAudit(false);

  return loader === false ? (
    <div>
      <Grid container>
        <div className="d-flex flex-column">
          <h2>Run Night Audit</h2>
          <p>Run Night Audit</p>
        </div>

        <RunNightAuditModal
          show={openNightAudit}
          hide={handleCloseNightAuditModal}
        ></RunNightAuditModal>

        <Grid item container sx={{ marginBottom: 3 }} xs={12}>
          <Grid item container>
            <div className="d-flex justify-content-between align-item-center w-100">
              <div className="w-100 d-flex">
                <div className="d-flex align-item-center">
                  <div
                    style={{
                      marginTop: "18px",
                      heigth: "35px !important",
                    }}
                  >
                    <Button
                      onClick={() => handleOpenNightAuditModal()}
                      variant="custom-button"
                    >
                      Run Night Audit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ) : (
    <MockLoaderTable />
  );
}

export default RunNightAudit;
