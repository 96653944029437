import { InputLabel, Select, Stack, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import StockWrapper from "../StockWrapper";
import { connect } from "react-redux";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";

import {
  djangoHandleDataRequests,
  handleSpringDataRequest,
} from "../../../api";
import { toast } from "material-react-toastify";
import ReportsLoader from "../../../Utils/ReportsLoader";
import { Divider, FormControl, MenuItem, Typography } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function ClosingStockKitchenReport({ data }) {
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;

  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  //columns of table
  const [rowsdats, setrowsdats] = useState(null);
  const [reportsColumns, setreportsColumns] = useState([]);

  // Get All report data
  const [reportsData, setreportsData] = useState([]);
  const [supplier, setsupplier] = useState("");

  // Getting the start date as well as the end date to load the data
  const [dateVal, setDateVal] = useState(dayjs(new Date()));
  const handleChange = (newVal) => {
    setDateVal(newVal);
  };

  // Get the closing stock
  const [selectedKitchen, setselectedKitchen] = useState();
  const [kitchenConfig, setKitchenConfig] = useState([]);
  function handleGetAllClosingStock() {
    handleSpringDataRequest("core/api/v1/kitchen/get-all")
      .then((res) => {
        setKitchenConfig(res);
        setselectedKitchen(res[0].id);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetAllClosingStock();
  }, []);

  //Function to get report data
  const getAllRestaurantReportData = () => {
    setrowsdats(null);
    djangoHandleDataRequests(
      `pos/closing-stock-kitchen?kitchen_id=${selectedKitchen}&&start_date=${moment(
        new Date(dateVal)
      ).format("YYYY-MM-DD 00:00:00")}&end_date=${moment(
        new Date(dateVal)
      ).format("YYYY-MM-DD 23:59:59")}&hotel_id=${hotelId}`
    )
      .then((res) => {
        setreportsData(res);
        setrowsdats(productsGenerator(res.length, res));
      })
      .catch((err) =>
        toast.error("Error in getting  data! Please try again later.")
      );
  };

  useEffect(() => {
    getAllRestaurantReportData();
  }, [selectedKitchen, dateVal]);

  //Rows data generator
  const productsGenerator = (quantity, data) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        rm_name: data[i].rm_name,
        rm_current_stock_qty: data[i].rm_current_stock_qty,
        rm_unit: data[i].rm_unit,
        comments: data[i].comments,
      });
    }
    return items;
  };

  useEffect(() => {
    const names = ["Name", "Current stock quantity", "Unit", `Comments`];
    if (reportsData?.length > 0) {
      let arr = [];
      const array = Object.keys(reportsData[0]);
      for (var i = 0; i < array?.length; i++) {
        arr.push({
          dataField: array[i],
          text: names[i],
          sort: true,
        });
      }

      setreportsColumns(arr);
    }
  }, [reportsData]);
  return (
    <>
      <StockWrapper
        backBtn={false}
        hideDrawer={true}
        stockTypeName={"Kitchen closing stock report"}
        contentArea={
          <Stack className="position-relative mt-8">
            <>
              {/* <h1 className="mt-3 mb-3">Warehouse Current Stock Report</h1>
<Divider className="mt-2 mb-2" /> */}
              {rowsdats ? (
                <div className="App">
                  {/* {reportsColumns.length > 0 && ( */}
                  <ToolkitProvider
                    keyField="id"
                    data={rowsdats}
                    columns={reportsColumns}
                    exportCSV
                    search
                  >
                    {(props) => (
                      <div>
                        <Stack
                          className="position-relative w-100 justify-content-between mt-8"
                          direction={"row"}
                        >
                          <Stack
                            className="position-relative"
                            direction="row"
                            spacing={2}
                          >
                            <SearchBar
                              {...props.searchProps}
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            />
                            <Stack
                              spacing={4}
                              className="position-relative"
                              style={{ width: "fit-content" }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  label="Select date"
                                  // inputFormat="MM/DD/YYYY"
                                  inputFormat="dd MMM yyyy"
                                  value={dateVal}
                                  onChange={handleChange}
                                  renderInput={(params) => (
                                    <TextField {...params} size="small" />
                                  )}
                                />
                              </LocalizationProvider>
                            </Stack>
                            <Stack
                            // spacing={4}
                            // className="position-relative"
                            // style={{ width: "fit-content" }}
                            >
                              <FormControl
                                fullWidth
                                // style={{ width: "10rem" }}
                                size="small"
                              >
                                {/* <InputLabel id="demo-simple-select-label">
                              Kitchen
                            </InputLabel> */}
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectedKitchen}
                                  label="Kitchen"
                                  size="small"
                                  name="Kitchen"
                                  onChange={(e) =>
                                    setselectedKitchen(e.target.value)
                                  }
                                >
                                  {kitchenConfig?.map((kitchen, index) => (
                                    <MenuItem
                                      value={String(kitchen.id)}
                                      key={index}
                                    >
                                      {kitchen?.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Stack>
                          </Stack>

                          <ExportCSVButton
                            {...props.csvProps}
                            style={{
                              display: "block",
                              background: "black",
                              color: "white",
                              marginTop: -1.5,
                              marginBottom: "auto",
                              marginLeft: "auto",
                            }}
                          >
                            Export CSV
                          </ExportCSVButton>
                        </Stack>
                        <Stack className="position-relativre mt-8">
                          {reportsColumns.length > 0 && rowsdats.length > 0 ? (
                            <BootstrapTable
                              pagination={paginationFactory({
                                sizePerPage: 10,
                              })}
                              {...props.baseProps}
                            />
                          ) : (
                            <Typography
                              className="position-relative mt-8 text-align-center"
                              style={{
                                fontSize: "15px",
                                marginTop: "15px",
                                color: "grey",
                                textAlign: "center",
                              }}
                            >
                              No data found!
                            </Typography>
                          )}
                        </Stack>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              ) : (
                <ReportsLoader />
              )}
            </>
          </Stack>
        }
      />
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(ClosingStockKitchenReport);
