import axios from "axios";

const BASE_URL = process.env.REACT_APP_SPRING_API_URL
export const LOGIN_URL = BASE_URL + `user/hotelAdmin/login`;
export const MASTER_LOGIN_URL = BASE_URL + `user/master/login`;
export const MASTER_RESET_PASS_URL = BASE_URL + `user/master/reset-master-password`;
export const HOTEL_URL = BASE_URL + `common/hotel-detail`;
export const user_check_url = BASE_URL + `user/checkForUser`;
export const new_password_mail = BASE_URL + 'user/sendPasswordResetLink';
export const validate_token_email = BASE_URL + 'user/token/isValidEmailToken';
export const reset_password = BASE_URL + "user/resetPassword"
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;
const SALT_KEY = '95bb23b5130268af63b5f93bd74f0d65'

export function login(email, password) {
  return axios.post(LOGIN_URL, { username: email, password: password });
}

export function masterLogin(email,username,password){
  return axios.post(MASTER_LOGIN_URL, { hotel_email: email, master_email: username, master_password: password,salt_key: SALT_KEY });
}

export const checkForUser = async (email) => {
  return axios.post(user_check_url, { email: email })
}

export function getHotel(email, groupProperty, token) {
  return axios.get(HOTEL_URL, {
    params: {
      isGroupProperty: groupProperty, emailId: email
    },
    headers: {
      Authorization: token
    }
  });
}

export const resetPasswordLink = async (email, baseUrl) => {
  return axios.post(new_password_mail, {
    email: email, baseUrl: baseUrl
  })
}

export const resetMasterPass = async (token, password) => {
  return axios.put(MASTER_RESET_PASS_URL, {
      newPassword: password, token: token
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'salt_key': 123434
      }
    }
  )
}

export const validateTokenReset = async (token) => {
  return axios.get(validate_token_email, { params: { token: token } })
}

export const resetPassword = async (body) => {
  return axios.put(reset_password, body)
}


export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
