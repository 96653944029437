import { Box, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import PaymentAgGridWrapper from "./PaymentAgGridWrapper";
import {
  // OfflinePaymentColumns,
  // OnlinePaymentColumns,
  PaymentColumns,
  RefundPaymentColumns,
  ReversalPaymentColumns,
  TransfersPaymentColumns,
} from "./PaymentColums";

function PaymentTab({
  payments,
  // offlinePayment,
  // onlinePayment,
  refunds,
  transfers,
  reversals,
}) {
  const [rowData, setRowData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tabvalue, setTabValue] = useState("payments");
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState([
    { date: "Total", amount: 0, paymentAmount: 0 },
  ]);

  useEffect(() => {
    console.log("rowData", rowData);
    if (rowData?.length > 0) {
      let amount = 0;
      let paymentAmount = 0;
      rowData.map((elm) => {
        amount = amount + (elm?.amount || 0);
        paymentAmount = paymentAmount + (elm?.paymentAmount || 0);
      });
      setPinnedBottomRowData([
        { date: "Total", amount: amount, paymentAmount: paymentAmount },
      ]);
    } else {
      setPinnedBottomRowData([{ date: "Total", amount: 0, paymentAmount: 0 }]);
    }
  }, [rowData]);

  useEffect(() => {
    setRowData(payments);
    setColumns(PaymentColumns);
  }, [payments]);

  const handleTabSelection = (newVal) => {
    setTabValue(newVal);
    switch (newVal) {
      case "payments": {
        setRowData(payments);
        setColumns(PaymentColumns);
        return;
      }
      case "refunds": {
        setRowData(refunds);
        setColumns(RefundPaymentColumns);
        return;
      }
      case "transfers": {
        setRowData(transfers);
        setColumns(TransfersPaymentColumns);
        return;
      }
      case "reversals": {
        setRowData(reversals);
        setColumns(ReversalPaymentColumns);
        return;
      }
      // case "online": {
      //   setRowData(onlinePayment);
      //   setColumns(OnlinePaymentColumns);
      //   return;
      // }
      // case "offline": {
      //   setRowData(offlinePayment);
      //   setColumns(OfflinePaymentColumns);
      //   return;
      // }
    }
  };
  return (
    <Box sx={{ mt: 4 }}>
      <Grid container height={"max-content"}>
        <Grid item xs={12}>
          <Tabs
            sx={{
              borderBottom: 1,
              borderTop: 1,
              borderColor: "divider",
              width: "100%",
            }}
            variant="fullWidth"
            value={tabvalue}
            onChange={(event, newVal) => {
              handleTabSelection(newVal);
            }}
          >
            <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              value="payments"
              label="Payments"
            ></Tab>
            {/* <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              value="offline"
              label="Offline payments"
            ></Tab> */}
            <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              value="refunds"
              label="Refunds"
            ></Tab>
            <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              value="transfers"
              label="Transfers"
            ></Tab>
            <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              value="reversals"
              label="Reversals"
            ></Tab>
            {/* <Tab
              sx={{ alignItems: "baseline", textTransform: "none" }}
              value="chargebacks"
              label="Chargebacks"
            ></Tab> */}
          </Tabs>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <PaymentAgGridWrapper
          rowData={rowData}
          columns={columns}
          pinnedBottomRowData={pinnedBottomRowData}
        />
      </Box>
    </Box>
  );
}

export default PaymentTab;
