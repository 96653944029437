import { arrayMoveImmutable } from "array-move";
import React, { lazy, useEffect, useState } from "react";
import { default as ReactImageUploading } from "react-images-uploading";
import Gallery from "react-photo-gallery";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Photo from "./Photo";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import imageCompression from "browser-image-compression";

const SortablePhoto = SortableElement((item, index) => (
  <Photo index={index} {...item} hideSortableGhost={true} />
));
const SortableGallery = SortableContainer(
  ({ items, remove, removeNewFile, deleteFlag }) => (
    <Gallery
      photos={items}
      renderImage={(props) => (
        <SortablePhoto
          {...props}
          p={props}
          remove={remove}
          removeNewFile={removeNewFile}
          deleteFlag={deleteFlag}
        />
      )}
    />
  )
);

export default ({
  hotelId,
  shopId,
  pType,
  uploadImg,
  setUploadImg,
  productId,
}) => {
  const [images, setImages] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);
  const maxNumber = 15;
  const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;

  const [hotelImgs, setHotelImgs] = useState([]);
  const [hotelImgs2, setHotelImgs2] = useState([]);

  const onChange = (imageList, addUpdateIndex) => {
    const imageListTransform = imageList.map((item) => {
      return {
        imageUrl: item.data_url,
        imageFile: item.file,
      };
    });
    const filteredHotelImg = hotelImgs2.filter(
      (item) => item.imageFile === undefined
    );
    setHotelImgs2(filteredHotelImg.concat(imageListTransform));
    setImages(imageList);
  };

  useEffect(() => {
    getImages();
  }, [productId]);

  useEffect(async () => {
    if (uploadImg) {
      uploadImages();
    }
  }, [uploadImg]);

  // useEffect(async () => {
  //   console.log("uploadProductImages", uploadProductImage);
  //   if (uploadProductImage) {
  //     uploadImages();
  //     setUploadProductImage(false);
  //   }
  // }, [uploadProductImage]);

  async function getImages() {
    let finalUrl;
    if (pType === "shops") {
      finalUrl = `${COMMON_URL}core/api/v1/image/get-shop-images/?hotel_id=${hotelId}&hotelId=${hotelId}&shopId=${shopId}`;
    } else if (pType === "products") {
      if (productId !== undefined)
        finalUrl = `${COMMON_URL}core/api/v1/image/get-product-images/?hotel_id=${hotelId}&hotelId=${hotelId}&productId=${productId}&shopId=${shopId}`;
    }

    await fetch(finalUrl, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        setHotelImgs(res.imageList ? res.imageList : []);
        res.imageList
          .sort((a, b) => (parseInt(a.orderId) > parseInt(b.orderId) ? 1 : -1))
          .map((item, idx) =>
            setHotelImgs2((prevState) => [
              ...prevState,
              { imageUrl: item.imageUrl, photoId: item.photoId },
            ])
          );
      })
      .catch((err) => console.error(err));
  }

  function handleHotelImgs() {
    console.log("hotelImgs", hotelImgs);

    if (hotelImgs) {
      let arr = hotelImgs2
        ?.filter((item) => item.photoId !== "default")
        ?.map((item, idx) => {
          if (item.imageFile === undefined) {
            return {
              orderId: idx + 1,
              photoId: item.photoId,
            };
          } else {
            return {
              orderId: idx + 1,
              imageData: item.imageUrl.split(",")[1],
              imageType: item.imageFile.type.split("/")[1],
            };
          }
        });

      console.log("hotelImgs", hotelImgs);
      console.log("hotelImgs2", hotelImgs2);

      return arr;
    }
    return null;
  }

  const compressAllImages = async () => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    if (hotelImgs) {
      let updatedArr = await Promise.all(
        hotelImgs2
          ?.filter((item) => item.photoId !== "default")
          ?.map(async (item, idx) => {
            if (item.imageFile === undefined) {
              return {
                orderId: idx + 1,
                photoId: item.photoId,
              };
            } else {
              let tempFile = item?.imageFile;
              const compressedImage = await imageCompression(tempFile, options);
              return {
                orderId: idx + 1,
                compressedImage: compressedImage,
                imageType: item.imageFile.type.split("/")[1],
              };
            }
          })
      );
      console.log("updated arr: ", updatedArr);
      const base64ImageDataArr = await Promise.all(
        updatedArr.map(async (image, idx) => {
          if (image?.compressedImage) {
            const reader = new FileReader();
            reader.readAsDataURL(image?.compressedImage);
            const base64Image = await new Promise((resolve, reject) => {
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });
            return {
              orderId: idx + 1,
              imageData: base64Image?.split(",")[1],
              imageType: image?.imageType,
            };
          } else {
            return { orderId: idx + 1, photoId: image?.photoId };
          }
        })
      );
      console.log("base64 image arr: ", base64ImageDataArr);
      return base64ImageDataArr;
    }
    return null;
  };

  const uploadImages = async () => {
    setImageUploading(true);
    //let hotelImgPayload = await handleHotelImgs();
    await compressAllImages().then(async (hotelImgPayload) => {
      let finalUrl;
      if (pType === "shops") {
        finalUrl = `${COMMON_URL}core/api/v1/image/update-shop-images/?hotel_id=${hotelId}&hotelId=${hotelId}&shopId=${shopId}`;
      } else if (pType === "products") {
        finalUrl = `${COMMON_URL}core/api/v1/image/update-product-images/?hotel_id=${hotelId}&hotelId=${hotelId}&productId=${productId}&shopId=${shopId}`;
      }
      await fetch(finalUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          addImages: hotelImgPayload,
        }),
      })
        .then(({ status }) => {
          if (status === 200) {
            setImageUploading(false);
            setUploadImg(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setUploadImg(false);
        });
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setHotelImgs2(arrayMoveImmutable(hotelImgs2, oldIndex, newIndex));
  };

  function deleteHotelImgs(filename) {
    let finalUrl;
    if (pType === "shops") {
      finalUrl = `${COMMON_URL}core/api/v1/image/update-shop-images/?hotel_id=${hotelId}&hotelId=${hotelId}&shopId=${shopId}`;
    } else if (pType === "products") {
      finalUrl = `${COMMON_URL}core/api/v1/image/update-product-images/?hotel_id=${hotelId}&hotelId=${hotelId}&productId=${productId}&shopId=${shopId}`;
    }
    fetch(finalUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        deleteImages: [filename],
      }),
    })
      .then(({ status }) => {
        if (status === 200) {
          setImages([]);
          setHotelImgs2([]);
          getImages();
        }
      })
      .catch((err) => console.log(err));
  }
  return (
    <Stack>
      <Stack>
        <Stack spacing="5">
          <h4>Add photos to enrich user experience!</h4>
          <p>
            Take photos and publish at least 6 photos to get your property more
            appealing. (Max photo 15)
          </p>
          <Stack spacing="5">
            <ReactImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
              maxFileSize={10485760}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
                errors,
              }) => (
                // write your building UI
                <Stack
                  style={{ padding: "1rem", borderRadius: "10px" }}
                  className="upload__image-wrapper"
                >
                  <Button
                    variant="outline"
                    // colorScheme="gray"
                    style={({}, isDragging ? { color: "red" } : undefined)}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <p>Click here to upload images</p>
                  </Button>
                  &nbsp;
                  <Stack spacing="2">
                    <SortableGallery
                      items={hotelImgs2}
                      remove={(x) => deleteHotelImgs(x)}
                      onSortEnd={onSortEnd}
                      removeNewFile={(index) =>
                        onImageRemove(index - hotelImgs?.length)
                      }
                      axis={"xy"}
                    />
                  </Stack>
                  {errors && (
                    <Stack>
                      {errors.maxNumber && (
                        <p>Number of selected images exceed 69</p>
                      )}
                      {errors.acceptType && (
                        <p>Your selected file type is not allow</p>
                      )}
                      {errors.maxFileSize && (
                        <p>Selected file size exceed 10MB</p>
                      )}
                      {errors.resolution && (
                        <p>
                          Selected file is not match your desired resolution
                        </p>
                      )}
                    </Stack>
                  )}
                </Stack>
              )}
            </ReactImageUploading>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
