import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalTitle, ModalBody } from "react-bootstrap";
import "./SupportStyle.css";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  IconButton,
  Collapse,
} from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import EventIcon from "@mui/icons-material/Event";
import CloseIcon from "@mui/icons-material/Close";
import SchoolIcon from "@mui/icons-material/School";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ChatIcon from "@mui/icons-material/Chat";
import { getCsmDetails } from "../apis/NotificationAPI";
const SupportModal = (props) => {
  const [supportMeet, setSupportMeet] = useState("");
  const [supportName, setSupportName] = useState("");
  const [supportTele, setSupportTele] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [supportLevel, setSupportLevel] = useState("LEVEL2");
  const [expandProdSupp, setExpandProdSupp] = useState(true);
  const [expandContact, setExpandContact] = useState(true);
  const [expandSupport, setExpandSupport] = useState(true);
  const [whiteLabelSupport, setWhiteLabelSupport] = useState("DISABLED");

  const expandCard = (cardName) => {
    if (cardName === "productSupport") {
      setExpandProdSupp(!expandProdSupp);
    } else if (cardName === "supportTeam") {
      setExpandContact(!expandContact);
    } else if (cardName === "relationContact") {
      setExpandSupport(!expandSupport);
    }
  };

  const initilizeWidget = () => {
    function initFreshChat() {
      window.fcWidget.init({
        token: "15ae2c70-2954-40bc-b456-4c0b35e7b32f",
        host: "https://wchat.freshchat.com",
        config: {
          headerProperty: {
            hideChatButton: true,
          },
        },
      });
      window.fcWidget.hide();
    }

    // function initialize(i, t) {
    //   let e;
    //   const data = i.getElementById(t)
    //     ? initFreshChat()
    //     : (((e = i.createElement("script")).id = t),
    //       (e.async = !0),
    //       (e.src = "https://wchat.freshchat.com/js/widget.js"),
    //       (e.onload = initFreshChat),
    //       i.head.appendChild(e));
    // }

    // function initiateCall() {
    //   initialize(document, "Freshchat-js-sdk");
    // }

    // initiateCall();

    initFreshChat();
    // To set unique user id in your system when it is available
    window.fcWidget.setExternalId(props.data.hotelId);

    window.fcWidget.user.setFirstName(
      props.data.hotelName + "[" + props.data.hotelId + "]"
    );

    window.fcWidget.user.setEmail(props.data.email);
    // window.fcWidget.open();
    // window.fcWidget.close();
  };

  // useEffect(() => {
  //   if (helpToggle === true) {
  //     window.fcWidget.open();
  //     window.fcWidget.show();
  //   } else if (helpToggle === false) {
  //     window.fcWidget.hide();
  //     window.fcWidget.close();
  //   }
  // }, [helpToggle]);
  useEffect(async () => {
    initilizeWidget();
  }, [props?.data?.accessControl?.hotel?.group_id]);

  useEffect(() => {
    if (props?.csmData) {
      const data = props?.csmData;
      if (data?.account_csm_name) {
        setSupportName(data?.account_csm_name);
      }
      if (data?.account_csm_phone) {
        setSupportTele(data?.account_csm_phone);
      }
      if (data?.account_csm_email) {
        setSupportEmail(data?.account_csm_email);
      }
      if (data?.account_csm_calendar_link) {
        setSupportMeet(data?.account_csm_calendar_link);
      }
      if (data?.account_support_level) {
        setSupportLevel(data?.account_support_level);
      }
      if (data?.whitelabel_support_options) {
        setWhiteLabelSupport(data?.whitelabel_support_options);
      }
    }
  }, [props?.csmData]);

  useEffect(() => {}, [
    supportName,
    supportTele,
    supportEmail,
    supportMeet,
    supportLevel,
  ]);

  return (
    <div>
      <Modal
        show={props?.show}
        onHide={props?.hide}
        dialogClassName={"myModalBody6533"}
        contentClassName={"myModalContent32323"}
      >
        <ModalTitle
          style={{
            fontSize: "1.5rem",
            marginLeft: "0.5rem",
            marginTop: "1.5rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ fontWeight: "bold" }}>
              Support
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton onClick={props?.hide}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </ModalTitle>
        <Divider style={{ marginTop: "0.5rem" }} />
        <ModalBody>
          {whiteLabelSupport === "DISABLED" && (
            <>
              <Card
                variant="outlined"
                style={{
                  borderRadius: "1rem",
                }}
              >
                <CardHeader
                  title="Stayflexi support tools"
                  titleTypographyProps={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                  action={
                    <IconButton
                      onClick={(event) => expandCard("productSupport")}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                />
                <Collapse in={expandProdSupp}>
                  <Divider variant="middle" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          target="_blank"
                          href="https://business.stayflexi.com/sf-product-updates"
                          className={"supportModalButton"}
                          startIcon={<SystemUpdateIcon />}
                        >
                          Product updates
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          className={"supportModalButton"}
                          target="_blank"
                          href="https://stayflexi.freshdesk.com/support/home"
                          startIcon={<SchoolIcon />}
                        >
                          Knowledge base
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          className={"supportModalButton"}
                          target="_blank"
                          href="https://stayflexi.freshdesk.com/support/tickets/new"
                          startIcon={<ConfirmationNumberIcon />}
                        >
                          Raise a ticket
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          className={"supportModalButton"}
                          target="_blank"
                          href="https://www.youtube.com/playlist?list=PLaPcDGXpN0pjuhJPWMBkjFpngOo7-uBO0"
                          startIcon={<OndemandVideoIcon />}
                        >
                          Product videos
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            </>
          )}
          <Card
            variant="outlined"
            style={{
              marginTop: "1rem",
              borderRadius: "1rem",
            }}
          >
            <CardHeader
              title="Contact support team"
              titleTypographyProps={{ fontSize: "1.2rem", fontWeight: "bold" }}
              action={
                <IconButton onClick={(event) => expandCard("supportTeam")}>
                  <ExpandMoreIcon />
                </IconButton>
              }
            />
            <Collapse in={expandContact}>
              <Divider variant="middle" />
              <CardContent>
                <Grid container spacing={2}>
                  {String(supportLevel) === "LEVEL3" ? (
                    <>
                      <Grid item xs={6}>
                        <ChatBubbleIcon /> Chat with us
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Button
                          variant="outlined"
                          onClick={(event) => {
                            window.fcWidget.open();
                            window.fcWidget.show();
                          }}
                          className="supportModalMeetButton"
                          startIcon={<ChatIcon />}
                        >
                          Live chat
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {String(supportLevel) === "LEVEL2" ||
                  String(supportLevel) === "LEVEL3" ? (
                    <>
                      <Grid item xs={6}>
                        <CallIcon /> Call
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        {whiteLabelSupport === "ENABLED" ? (
                          <>
                            <a
                              href={"tel:" + supportTele}
                              className={"supportModalLink"}
                            >
                              {supportTele}
                            </a>
                          </>
                        ) : (
                          <>
                            {String(
                              props?.data?.accessControl?.hotel?.hotel_country
                            ) === "India" ? (
                              <a
                                href="tel:0008000402800"
                                className={"supportModalLink"}
                              >
                                0008000402800 (toll free)
                              </a>
                            ) : (
                              <a
                                href="tel:+18442342363"
                                className={"supportModalLink"}
                              >
                                +1 844 234 2363 (toll free)
                              </a>
                            )}
                          </>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {String(supportLevel) === "LEVEL1" ||
                  String(supportLevel) === "LEVEL2" ||
                  String(supportLevel) === "LEVEL3" ? (
                    <>
                      <Grid item xs={6}>
                        <EmailIcon /> Email
                      </Grid>
                      {whiteLabelSupport === "ENABLED" ? (
                        <>
                          <Grid item xs={6} style={{ textAlign: "right" }}>
                            <a
                              href={"mailto:" + supportEmail}
                              className={"supportModalLink"}
                            >
                              {supportEmail}
                            </a>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={6} style={{ textAlign: "right" }}>
                            <a
                              href="mailto:support@stayflexi.com"
                              className={"supportModalLink"}
                            >
                              support@stayflexi.com
                            </a>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </CardContent>
            </Collapse>
          </Card>
          {supportName !== "" && whiteLabelSupport === "DISABLED" && (
            <Card
              variant="outlined"
              style={{
                marginTop: "1rem",
                borderRadius: "1rem",
              }}
            >
              <CardHeader
                title="Contact relationship manager"
                titleTypographyProps={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
                action={
                  <IconButton
                    onClick={(event) => expandCard("relationContact")}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                }
              />
              <Collapse in={expandSupport}>
                <Divider variant="middle" />
                <CardContent>
                  <Grid item xs={12}>
                    <span style={{ fontWeight: "bold" }}>{supportName}</span>
                    <div style={{ fontSize: "1rem", marginTop: "0.5rem" }}>
                      {supportName} is your dedicated contact who is familiar
                      with your account and business needs. They’re typically
                      available during business hours, but if they’re busy with
                      another customer, you can always contact Support for
                      immediate assistance.
                    </div>
                  </Grid>
                  <Divider
                    variant="middle"
                    style={{ marginBottom: "0.75rem", marginTop: "0.75rem" }}
                  />
                  <Grid container spacing={2}>
                    {supportMeet !== "" && (
                      <>
                        <Grid item xs={6}>
                          <EventIcon /> Schedule a meeting
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Button
                            href={supportMeet}
                            className={"supportModalMeetButton"}
                            target="_blank"
                            variant="outlined"
                            startIcon={<EventIcon />}
                          >
                            Schedule
                          </Button>
                        </Grid>
                      </>
                    )}
                    {supportTele !== "" && (
                      <>
                        <Grid item xs={6}>
                          <CallIcon /> Call
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <a
                            href={"tel:" + supportTele}
                            className={"supportModalLink"}
                          >
                            {supportTele}
                          </a>
                        </Grid>
                      </>
                    )}
                    {supportEmail !== "" && (
                      <>
                        <Grid item xs={6}>
                          <EmailIcon /> Email
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <a
                            href={"mailto:" + supportEmail}
                            className={"supportModalLink"}
                          >
                            {supportEmail}
                          </a>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Collapse>
            </Card>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(SupportModal);

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
