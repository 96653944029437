import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_API_URL}`;
const JAVA_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const GETACCESSCONTROL = "accesscontrol/getAccessControl/";
const AddNewUser = "accesscontrol/addUser/";
const ADDUSER = "accesscontrol/addUserWizardRender/";
const DELETEUSER = "accesscontrol/deleteUser";
const SAVEUSER = "user/hotelAdmin/save";
const EDITPASSWORD = "user/subUserAuth";

const url = new URL(window.location.href);
const hotelId = url.searchParams.get("hotelId");

export const getAllAccessControl = async () => {
  return axios
    .get(COMMON_URL + GETACCESSCONTROL)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addUser = async (data) => {
  return axios
    .post(COMMON_URL + AddNewUser, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getUserWizardRender = async (id, email) => {
  return axios
    .get(COMMON_URL + ADDUSER, { params: { hotel_id: id, email: email } })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deletesUser = async (id, email) => {
  return axios
    .get(
      COMMON_URL +
        DELETEUSER +
        "?hotel_id=" +
        hotelId +
        "&id=" +
        id +
        "&email=" +
        email
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const saveUserInDb = async (data) => {
  return axios
    .post(JAVA_URL + SAVEUSER, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const editpasswords = async (data) => {
  return axios
    .put(JAVA_URL + EDITPASSWORD, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
