import React from "react";

export const AvailableRoomLoadersForFolio = () => {
  return (
    <div className="roomWrapperLoader">
      {[1, 2, 3, 4].map((item) => (
        <div className="roomInfoLoader"></div>
      ))}
    </div>
  );
};
