import React, { useEffect, useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import { menu } from "./menu";
import { hasChildren } from "../PropertyConfiguration/PropertyDetails/utils";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { handleSpringDataRequest } from "../../api";
import BackupTableIcon from "@mui/icons-material/BackupTable";
export default function StockSidebar() {
  const history = useHistory();
  const location = useLocation();
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const [kitchenConfigData, setkitchenConfigData] = useState([]);
  function handleGetAllKitchenConfig() {
    handleSpringDataRequest("core/api/v1/kitchen/get-all")
      .then((res) => setkitchenConfigData(res))
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetAllKitchenConfig();
  }, []);
  const [isMasterUser, setisMasterUser] = useState(false);
  const email = localStorage.getItem("rootEmail");
  useEffect(() => {
    email !== null &&
      handleSpringDataRequest(`user/check-master-user?email=${email}`)
        .then((res) => setisMasterUser(res))
        .catch((err) => console.error("Please try again later."));
  }, []);

  const [stockTypes, setstockTypes] = useState([]);
  useEffect(() => {
    if (
      kitchenConfigData?.length === 0 ||
      kitchenConfigData[0].type === "single"
    ) {
      if (isMasterUser) {
        setstockTypes([
          {
            title: "Dashboard",
            icon: <BackupTableIcon />,
            parent_index: 8,
            parent_link: "stock-dashboard",
            items: [],
          },
          {
            title: "Reports",
            icon: <BackupTableIcon />,
            parent_index: 7,
            parent_link: "reports",
            items: [],
          },
          {
            title: "Purchase",
            icon: <BackupTableIcon />,
            parent_index: 6,
            parent_link: "po",
            items: [],
          },
          {
            title: "Consumption",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Closing stock",
                icon: <BackupTableIcon />,
                parent_index: 2,
                parent_link: "cls",
                items: [],
              },
              {
                title: "Raw material wastage",
                icon: <BackupTableIcon />,
                parent_index: 3,
                parent_link: "rmw",
                items: [],
              },
            ],
          },

          {
            title: "Configuration",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Vendors",
                icon: <BackupTableIcon />,
                parent_index: 0,
                parent_link: "rms",
                items: [],
              },
              {
                title: "Raw Material - Centralized",
                icon: <BackupTableIcon />,
                parent_index: 1,
                parent_link: "wrm",
                items: [],
              },

              {
                title: "Item Recipes",
                icon: <BackupTableIcon />,
                parent_index: 4,
                parent_link: "pr",
                items: [],
              },
              {
                title: "Outlet Configuration",
                icon: <BackupTableIcon />,
                parent_index: 5,
                parent_link: "kc",
                items: [],
              },
            ],
          },
          {
            title: "Housekeeping Configuration",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Centralised HK",
                icon: <BackupTableIcon />,
                parent_index: 23,
                parent_link: "hkrms",
                items: [],
              },
              {
                title: "Recepie",
                icon: <BackupTableIcon />,
                parent_index: 23,
                parent_link: "hkrecipe",
                items: [],
              },
              // {
              //   title: "Raw Material - Centralized",
              //   icon: <BackupTableIcon />,
              //   parent_index: 1,
              //   parent_link: "wrm",
              //   items: [],
              // },
            ],
          },
        ]);
      } else {
        setstockTypes([
          {
            title: "Dashboard",
            icon: <BackupTableIcon />,
            parent_index: 8,
            parent_link: "stock-dashboard",
            items: [],
          },
          {
            title: "Reports",
            icon: <BackupTableIcon />,
            parent_index: 7,
            parent_link: "reports",
            items: [],
          },
          {
            title: "Purchase",
            icon: <BackupTableIcon />,
            parent_index: 6,
            parent_link: "po",
            items: [],
          },
          {
            title: "Consumption",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Closing stock",
                icon: <BackupTableIcon />,
                parent_index: 2,
                parent_link: "cls",
                items: [],
              },
              {
                title: "Raw material wastage",
                icon: <BackupTableIcon />,
                parent_index: 3,
                parent_link: "rmw",
                items: [],
              },
            ],
          },

          {
            title: "Configuration",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Vendors",
                icon: <BackupTableIcon />,
                parent_index: 0,
                parent_link: "rms",
                items: [],
              },
              {
                title: "Raw Material - Centralized",
                icon: <BackupTableIcon />,
                parent_index: 1,
                parent_link: "wrm",
                items: [],
              },

              {
                title: "Item Recipes",
                icon: <BackupTableIcon />,
                parent_index: 4,
                parent_link: "pr",
                items: [],
              },
              // {
              //   title: "Outlet Configuration",
              //   icon: <BackupTableIcon />,
              //   parent_index: 5,
              //   parent_link: "kc",
              //   items: [],
              // },
            ],
          },
          {
            title: "Housekeeping Configuration",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Centralised HK",
                icon: <BackupTableIcon />,
                parent_index: 23,
                parent_link: "hkrms",
                items: [],
              },
              {
                title: "Recepie",
                icon: <BackupTableIcon />,
                parent_index: 23,
                parent_link: "hkrecipe",
                items: [],
              },
              // {
              //   title: "Raw Material - Centralized",
              //   icon: <BackupTableIcon />,
              //   parent_index: 1,
              //   parent_link: "wrm",
              //   items: [],
              // },
            ],
          },
        ]);
      }
    } else {
      if (isMasterUser) {
        setstockTypes([
          {
            title: "Dashboard",
            icon: <BackupTableIcon />,
            parent_index: 8,
            parent_link: "stock-dashboard",
            items: [],
          },
          {
            title: "Reports",
            icon: <BackupTableIcon />,
            parent_index: 7,
            parent_link: "reports",
            items: [],
          },
          {
            title: "Purchase",
            icon: <BackupTableIcon />,
            parent_index: 6,
            parent_link: "po",
            items: [],
          },
          {
            title: "Consumption",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Closing stock",
                icon: <BackupTableIcon />,
                parent_index: 2,
                parent_link: "cls",
                items: [],
              },
              {
                title: "Raw material wastage",
                icon: <BackupTableIcon />,
                parent_index: 3,
                parent_link: "rmw",
                items: [],
              },
            ],
          },
          {
            title: "Raw material",
            icon: <BackupTableIcon />,
            parent_index: 5,
            parent_link: "krm",
            items: [],
          },
          {
            title: "Configuration",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Vendors",
                icon: <BackupTableIcon />,
                parent_index: 0,
                parent_link: "rms",
                items: [],
              },
              {
                title: "Raw Material - Centralized",
                icon: <BackupTableIcon />,
                parent_index: 1,
                parent_link: "wrm",
                items: [],
              },

              {
                title: "Item recipes",
                icon: <BackupTableIcon />,
                parent_index: 4,
                parent_link: "pr",
                items: [],
              },
              {
                title: "Outlet Configuration",
                icon: <BackupTableIcon />,
                parent_index: 5,
                parent_link: "kc",
                items: [],
              },
            ],
          },
          {
            title: "Housekeeping Configuration",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Centralised HK",
                icon: <BackupTableIcon />,
                parent_index: 23,
                parent_link: "hkrms",
                items: [],
              },
              {
                title: "Recepie",
                icon: <BackupTableIcon />,
                parent_index: 23,
                parent_link: "hkrecipe",
                items: [],
              },
              // {
              //   title: "Raw Material - Centralized",
              //   icon: <BackupTableIcon />,
              //   parent_index: 1,
              //   parent_link: "wrm",
              //   items: [],
              // },
            ],
          },
        ]);
      } else {
        setstockTypes([
          {
            title: "Dashboard",
            icon: <BackupTableIcon />,
            parent_index: 8,
            parent_link: "stock-dashboard",
            items: [],
          },
          {
            title: "Reports",
            icon: <BackupTableIcon />,
            parent_index: 7,
            parent_link: "reports",
            items: [],
          },
          {
            title: "Purchase",
            icon: <BackupTableIcon />,
            parent_index: 6,
            parent_link: "po",
            items: [],
          },
          {
            title: "Consumption",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Closing stock",
                icon: <BackupTableIcon />,
                parent_index: 2,
                parent_link: "cls",
                items: [],
              },
              {
                title: "Raw material wastage",
                icon: <BackupTableIcon />,
                parent_index: 3,
                parent_link: "rmw",
                items: [],
              },
            ],
          },
          {
            title: "Raw material",
            icon: <BackupTableIcon />,
            parent_index: 5,
            parent_link: "krm",
            items: [],
          },
          {
            title: "Configuration",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Vendors",
                icon: <BackupTableIcon />,
                parent_index: 0,
                parent_link: "rms",
                items: [],
              },
              {
                title: "Raw Material - Centralized",
                icon: <BackupTableIcon />,
                parent_index: 1,
                parent_link: "wrm",
                items: [],
              },

              {
                title: "Item recipes",
                icon: <BackupTableIcon />,
                parent_index: 4,
                parent_link: "pr",
                items: [],
              },
              // {
              //   title: "Outlet Configuration",
              //   icon: <BackupTableIcon />,
              //   parent_index: 5,
              //   parent_link: "kc",
              //   items: [],
              // },
            ],
          },
          {
            title: "Housekeeping Configuration",
            icon: <BackupTableIcon />,
            parent_index: 100,
            parent_link: "test",
            items: [
              {
                title: "Centralised HK",
                icon: <BackupTableIcon />,
                parent_index: 23,
                parent_link: "hkrms",
                items: [],
              },
              {
                title: "Recepie",
                icon: <BackupTableIcon />,
                parent_index: 23,
                parent_link: "hkrecipe",
                items: [],
              },
              // {
              //   title: "Raw Material - Centralized",
              //   icon: <BackupTableIcon />,
              //   parent_index: 1,
              //   parent_link: "wrm",
              //   items: [],
              // },
            ],
          },
        ]);
      }
    }
  }, [kitchenConfigData, isMasterUser]);
  return stockTypes?.map((item, key) => <MenuItem key={key} item={item} />);
}
const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};
const SingleLevel = ({ item }) => {
  const history = useHistory();
  const location = useLocation();
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const generateLinkForTabs = (parentindex, parentLink) => {
    if (parentindex !== 100 && parentLink !== "test") {
      history.push({
        pathname: `/stock/${parentLink}`,
        search: `?hotelId=${hotelId}`,
        state: { parentindex: parentindex },
      });
    }
  };
  return (
    <ListItem
      button
      onClick={() => generateLinkForTabs(item?.parent_index, item?.parent_link)}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
};
const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ marginLeft: "1rem" }}>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
