import React from "react";

function MockInvoiceSummary() {
  return (
    <div className="invoiceSummary">
      <div className="summaryHeader">Folio summary</div>
      <div className="invoiceContent">
        <div className="feeBreakdownWrapper">
          <div className="feeBreakdown">
            {/* <div className="breakdownHeader">Total without taxes</div> */}
            <div className="box w-200 p-2" style={{ minHeight: "3rem" }}>
              {" "}
            </div>
          </div>
          <div className="feeBreakdown">
            {/* <div className="breakdownHeader">SGST</div> */}
            <div className="box w-200 p-2" style={{ minHeight: "3rem" }}>
              {" "}
            </div>
          </div>

          <div className="separator" />

          {/* taxes breakdown */}

          <div className="taxesBreakdownWrapper">
            <div className="taxBreakdown">
              <div className="box w-auto p-2" style={{ minHeight: "3rem" }}>
                {" "}
              </div>
              <div className="box w-auto p-2" style={{ minHeight: "3rem" }}>
                {" "}
              </div>
            </div>
          </div>

          <div className="separator" />

          {/* payments made with types */}

          <div>

            <div className="feeBreakdown">
              {/* <div className="breakdownHeader">Cash payment</div> */}
              <div
                className="box w-200 p-2"
                style={{ minHeight: "2rem", width: "4.5rem" }}
              >
                {" "}
              </div>
            </div>

            <div className="feeBreakdown">
              {/* <div className="breakdownHeader">Offline card payment</div> */}
              <div
                className="box w-200 p-2"
                style={{ minHeight: "2rem", width: "4.5rem" }}
              >
                {" "}
              </div>
            </div>

            <div className="separator" />
          </div>

          <div className="feeBreakdown">
            {/* <div className="breakdownHeader" style={{ fontWeight: "500" }}>
                Refund
              </div> */}
            <div className="box w-auto p-2" style={{ minHeight: "3rem" }}>
              {" "}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default MockInvoiceSummary;
