import React, { useState } from "react";
import { connect } from "react-redux";
import GroupReportsWrapper from "./GroupReportsWrapper.js";
import {
  filterParams,
  cellRenderer,
  generateOriginalValue,
  handleNumDisplay,
} from "../Reports/utils/ReportUtils";
import moment from "moment";

function GroupMasterReport(props) {
  // getting the hotel id from the params
  //   const url = new URL(window.location.href);
  //   const hotelId = url.searchParams.get("hotelId");
  //   const hotelCurrency = data.accessControl.hotel.hotel_currency;

  // default hotel currency
  const hotelCurrency = props.data.hotelCurrency;
  // column definitions
  const columns = [
    {
      field: "hotelId",
      headerName: "Hotel Id",
      hide: false,
      dataType: "varchar",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "hotelName",
      headerName: "Hotel Name",
      hide: false,
      dataType: "varchar",
    },
    {
      field: "bookingIdDisplayName",
      headerName: "Booking Id",
      // cellRendererFramework: (params) => cellRenderer(params),
      printWidth: "5%",
      dataType: "varchar",
    },
    {
      field: "otaBookingId",
      headerName: "OTA booking Id",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      hide: false,
      printWidth: "8%",
      dataType: "varchar",
    },
    {
      field: "bookingMadeOn",
      headerName: "Booking date",
      filter: "agDateColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: filterParams,
      dataType: "date",
      dateFormat: "MMM DD, YYYY HH:mm A",
    },
    {
      field: "bookingSrcDisplayName",
      headerName: "Source",
      printWidth: "8%",
      dataType: "varchar",
    },
    {
      field: "segment",
      headerName: "Segment",
      // valueFormatter: (params) => params.value,
      printWidth: "10%",
      dataType: "varchar",
    },
    {
      field: "statusDisplayName",
      headerName: "Booking status",
      printWidth: "7%",
      dataType: "varchar",
    },
    {
      field: "customerName",
      headerName: "Guest",
      sortable: true,
      printWidth: "10%",
      dataType: "varchar",
    },
    {
      field: "roomIds",
      headerName: "Room no.(s)",
      printWidth: "5%",
      dataType: "varchar",
    },
    {
      field: "adultsPax",
      headerName: "Adults PAX",
      printWidth: "5%",
      dataType: "varchar",
      // aggFunc: "sum",
      // valueGetter: (params) => generateOriginalValue(params, "adultsPax"),
      // valueFormatter: (params) => handleNumDisplay(params, "integer"),
      // pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
    },
    {
      field: "childrenPax",
      headerName: "Children PAX",
      printWidth: "5%",
      dataType: "varchar",
      // aggFunc: "sum",
      // valueGetter: (params) => generateOriginalValue(params, "childrenPax"),
      // valueFormatter: (params) => handleNumDisplay(params, "integer"),
      // pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
    },
    {
      field: "rooms",
      headerName: "No. of rooms",
      printWidth: "5%",
      dataType: "integer",
      aggFunc: "sum",
      valueGetter: (params) => generateOriginalValue(params, "rooms"),
      valueFormatter: (params) => handleNumDisplay(params, "integer"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
    },
    {
      field: "checkIn",
      headerName: "Check-in",
      filter: "agDateColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: filterParams,
      printWidth: "5%",
      dataType: "date",
      dateFormat: "MMM DD, YYYY HH:mm A",
    },
    {
      field: "checkOut",
      headerName: "Check-out",
      filter: "agDateColumnFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: filterParams,
      printWidth: "5%",
      dataType: "date",
      dateFormat: "MMM DD, YYYY HH:mm A",
    },
    {
      field: "roomNights",
      headerName: "Room nights",
      filter: "agNumberColumnFilter",
      // hide: true,
      printWidth: "3%",
      dataType: "integer",
      aggFunc: "sum",
      valueGetter: (params) => generateOriginalValue(params, "roomNights"),
      valueFormatter: (params) => handleNumDisplay(params, "integer"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
    },
    {
      field: "roomTypes",
      headerName: "Room types",
      enableValue: true,
      rowGroup: false,
      printWidth: "10%",
      dataType: "varchar",
    },
    {
      field: "ratePlans",
      headerName: "Rate plans",
      // hide: true,
      filter: "agNumberColumnFilter",
      printWidth: "10%",
      dataType: "varchar",
    },
    // {
    //   field: "room_taxes",
    //   headerName: `Cumulative room taxes (${hotelCurrency})`,
    //   aggFunc: "sum",
    //   enableValue: true,
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    //   filter: "agNumberColumnFilter",
    //   printWidth: "5%",
    //   dataType: "float",
    // },
    // {
    //   field: "total_amount_with_services",
    //   headerName: `Total amount (${hotelCurrency})`,
    //   enableValue: true,
    //   aggFunc: "sum",
    //   filter: "agNumberColumnFilter",
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    //   printWidth: "5%",
    //   dataType: "float",
    // },
    // {
    //   field: "ota_commission",
    //   headerName: `OTA commission (${hotelCurrency})`,
    //   aggFunc: "sum",
    //   enableValue: true,
    //   hide: true,
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    //   filter: "agNumberColumnFilter",
    //   printWidth: "5%",
    //   dataType: "float",
    // },
    {
      field: "balanceDue",
      headerName: `Balance due (${hotelCurrency})`,
      aggFunc: "sum",
      printWidth: "5%",
      filter: "agNumberColumnFilter",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "balanceDue"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
    },
    // {
    //   field: "room_revenue",
    //   headerName: `Cumulative room revenue (${hotelCurrency})`,
    //   aggFunc: "sum",
    //   valueFormatter: (params) => {
    //     return Number(params.value.toFixed(2));
    //   },
    //   printWidth: "5%",
    //   filter: "agNumberColumnFilter",
    //   dataType: "float",
    // },
    {
      field: "customInvoiceId",
      headerName: "Custom folio Id",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      hide: true,
      dataType: "varchar",
    },
    {
      field: "identification",
      headerName: "Customer identification",
      hide: true,
      dataType: "varchar",
    },

    {
      field: "bookingEmail",
      headerName: "Customer email",
      hide: true,
      dataType: "varchar",
    },

    // { field: "email", headerName: "User Email", hide: true },
    {
      field: "actualCheckin",
      headerName: "Actual check-in",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      hide: true,
      dataType: "date",
    },
    {
      field: "actualCheckout",
      headerName: "Actual Check-out",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      hide: true,
      dataType: "date",
    },
    // {
    //   field: "payment_made",
    //   headerName: `Payment made (${hotelCurrency})`,
    //   filter: "agNumberColumnFilter",
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    //   dataType: "float",
    //   printWidth: "5%",
    //   // hide: true,
    // },
    // {
    //   field: "deposit_made",
    //   headerName: `Deposit made (${hotelCurrency})`,
    //   filter: "agNumberColumnFilter",
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    //   hide: true,
    //   printWidth: "5%",
    //   dataType: "float",
    // },
    {
      field: "customerPhone",
      headerName: "Customer phone",
      hide: true,
      dataType: "varchar",
    },
    {
      field: "customerCity",
      headerName: "Customer city",
      hide: true,
      dataType: "varchar",
    },
    {
      field: "customerZipcode",
      headerName: "Customer zipcode",
      hide: true,
      dataType: "varchar",
    },
    {
      field: "customerState",
      headerName: "Customer state",
      hide: true,
      dataType: "varchar",
    },
    {
      field: "customerCountry",
      headerName: "Customer country",
      hide: true,
      dataType: "varchar",
    },
    {
      field: "bookingAmount",
      headerName: `Booking amount (${hotelCurrency})`,
      filter: "agNumberColumnFilter",
      hide: true,
      printWidth: "5%",
      dataType: "float",
      aggFunc: "sum",
      valueGetter: (params) => generateOriginalValue(params, "bookingAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
    },
    // {
    //   field: "services_amount",
    //   headerName: `Service amount (${hotelCurrency})`,
    //   dataType: "float",
    //   hide: true,
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + params.value;
    //   },
    //   printWidth: "5%",
    //   filter: "agNumberColumnFilter",
    // },
    // {
    //   field: "external_payment",
    //   headerName: "External source payment",
    //   dataType: "float",
    //   hide: true,
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    //   printWidth: "5%",
    //   filter: "agNumberColumnFilter",
    // },
    // {
    //   field: "external_payment_card",
    //   headerName: "External card payment",
    //   dataType: "float",
    //   hide: true,
    //   printWidth: "5%",
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    // },
    // {
    //   field: "refund",
    //   headerName: "Refund",
    //   dataType: "float",
    //   hide: true,
    //   printWidth: "5%",
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    //   filter: "agNumberColumnFilter",
    // },
    // {
    //   field: "offline_payment",
    //   headerName: `Offline payment (${hotelCurrency})`,
    //   hide: true,
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    //   printWidth: "5%",
    //   filter: "agNumberColumnFilter",
    //   dataType: "float",
    // },
    // {
    //   field: "ota_tax",
    //   headerName: `OTA tax (${hotelCurrency})`,
    //   hide: true,
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    //   printWidth: "5%",
    //   filter: "agNumberColumnFilter",
    //   dataType: "float",
    // },
    // {
    //   field: "ota_net_amount",
    //   headerName: `OTA net amount (${hotelCurrency})`,
    //   hide: true,
    //   valueFormatter: (params) => {
    //     return parseFloat(params.value).toFixed(2);
    //   },
    //   printWidth: "5%",
    //   filter: "agNumberColumnFilter",
    //   dataType: "float",
    // },
    {
      field: "specialRequests",
      headerName: "Special requests",
      hide: true,
      dataType: "varchar",
    },
    // {
    //   field: "online_payment",
    //   headerName: `Online payment (${hotelCurrency})`,
    //   hide: true,
    //   valueFormatter: (params) => {
    //     return `${hotelCurrency}` + parseFloat(params.value).toFixed(2);
    //   },
    //   printWidth: "5%",
    //   dataType: "float",
    //   filter: "agNumberColumnFilter",
    // },
    {
      field: "appliedPromocode",
      headerName: "Applied coupon code or offer",
      dataType: "varchar",
      printWidth: "5%",
      hide: true,
    },
    // {
    //   field: "pm_card_last4",
    //   headerName: "Last 4 digit of credit card",
    //   hide: true,
    //   printWidth: "3%",
    //   dataType: "integer",
    // },
    // {
    //   field: "pm_payment_type",
    //   headerName: "Mode of payment",
    //   hide: true,
    //   printWidth: "3%",
    //   dataType: "varchar",
    // },
    // {
    //   field: "promo_amount",
    //   headerName: `Promo amount (${hotelCurrency})`,
    //   valueFormatter: (params) =>
    //     params.value
    //       ? `${hotelCurrency} ` + parseFloat(params.value).toFixed(2)
    //       : `${hotelCurrency} ` + 0.0,
    //   filter: "agNumberColumnFilter",
    //   dataType: "float",
    //   printWidth: "5%",
    //   hide: true,
    // },
    {
      field: "bookingReferenceId",
      headerName: `Booking reference Id`,
      filter: "agNumberColumnFilter",
      hide: true,
      dataType: "varchar",
    },
    {
      field: "servicesList",
      headerName: `Services list`,
      hide: true,
      dataType: "varchar",
    },
  ];

  // Getting the pinned data
  // Setting the pinned row
  const [pinnedRow, setPinnedRow] = useState(null);
  function createPinnedData(params, rowsData) {
    // let result = {
    //   bookingid_display_name: "Total",
    //   total_amount_with_services: 0,
    //   payment_made: 0,
    //   offline_payment: 0,
    //   refund: 0,
    //   services_amount: 0,
    //   ota_net_amount: 0,
    //   external_payment: 0,
    //   external_payment_card: 0,
    //   rooms: 0,
    //   online_payment: 0,
    //   booking_amount: 0,
    //   room_taxes: 0,
    //   ota_commission: 0,
    //   ota_tax: 0,
    //   balance_due: 0,
    //   room_revenue: 0,
    //   room_nights: 0,
    //   promo_amount: 0,
    // };
    let result = {};

    for (let i = 0; i < columns.length; i++) {
      if (["integer", "float"].includes(columns[i]["dataType"])) {
        result[columns[i]["field"]] = 0;
      }
    }

    // function updateVals(i) {
    //   result.payment_made += i.payment_made;
    //   result.total_amount_with_services += i.total_amount_with_services;
    //   result.offline_payment += i.offline_payment;
    //   result.online_payment += i.online_payment;
    //   result.refund += i.refund;
    //   result.services_amount += i.services_amount;
    //   result.ota_net_amount += i.ota_net_amount;
    //   result.external_payment += i.external_payment;
    //   result.external_payment_card += i.external_payment_card;
    //   result.rooms += parseInt(i.rooms);
    //   result.booking_amount += i.booking_amount;
    //   result.room_taxes += i.room_taxes;
    //   result.ota_commission += i.ota_commission;
    //   result.ota_tax += i.ota_tax;
    //   result.balance_due += i.balance_due;
    //   result.room_revenue += i.room_revenue;
    //   result.room_nights += i.room_nights;
    //   result.promo_amount += i.promo_amount;
    // }

    function updateVals(i) {
      Object.keys(result).forEach((fields) => {
        result[fields] += i[`original_${fields}`];
      });
    }

    params.api.getSelectedRows().length === 0
      ? params.api.forEachNodeAfterFilter((row) => updateVals(row.data))
      : params.api.getSelectedRows().map((row) => updateVals(row));

    result["bookingIdDisplayName"] = "Total";

    setPinnedRow([result]);
    params.api.setPinnedBottomRowData([result]);
  }

  return (
    <GroupReportsWrapper
      reportName={"Group Master Report"}
      columns={columns}
      reportActualType={"master"}
      createPinnedData={createPinnedData}
      pinnedRow={pinnedRow}
    />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.hotels[0] };
}
export default connect(mapStateToProps)(GroupMasterReport);
