/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { isNull, isUndefined } from "lodash";
import { ToastContainer } from "material-react-toastify";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import "react-responsive-modal/styles.css";
import { Redirect, Route, Switch } from "react-router-dom";
import * as auth from "../app/modules/Auth/_redux/authRedux";
// import BookingDashboard from "../app/pages/BookingDashboard/BookingDashboardIndex";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import AuthorisedPages from "./pages/BookingDashboard/AuthorisedPages";
// import loadable from "@loadable/component";
import { Logout, SignIn } from "./modules/Auth";
import GroupReportsContainer from "./pages/GroupReports/GroupReportsContainer";
import BookingDashboard from "../app/pages/BookingDashboard/BookingDashboard";
import AccountsDashBoard from "../app/pages/AccountsAndBilling/AccountsDashBoard";
import GroupDashboard from "../app/pages/GroupDashboard/GroupDashboard";
import CTAModal from "../app/pages/BookingDashboard/CTAModal";
import GroupSidebar from "../app/pages/GroupSidebar/GroupSidebar";
import GroupChannelManagerReport from "./pages/GroupReports/GroupChannelManagerReport";
import GroupSalesReport from "./pages/GroupReports/GroupSalesReport";
import GroupMasterReport from "./pages/GroupReports/GroupMasterReport";
import GroupRoomRevenueReport from "./pages/GroupReports/GroupRoomRevenueReport";
import GroupPaymentsReport from "./pages/GroupReports/GroupPaymentsReport";

function Routes(props) {
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");

  const isAuthorized = props.data.token != null && props.data.hotels != null;
  let present = props?.data?.hotels?.find((hotel) => hotel.hotelId === hotelId);

  const func = () => {
    if (!isAuthorized) {
      return { isHotelPresent: false };
    }

    if (isUndefined(hotelId) || isNull(hotelId)) {
      return { isHotelPresent: false };
    }

    if (isUndefined(present) || isNull(present)) {
      return { isHotelPresent: false };
    }

    props.selectHotel(present);
    return {
      // isAuthorized: auth.user != null,
      isHotelPresent: true,
    };
  };
  const { isHotelPresent } = useMemo(() => {
    return func();
  }, [isAuthorized, hotelId, present]);

  // useEffect(() => {
  //   debugger
  //   if (!isAuthorized) {
  //     isAuthorized = check()

  //   }
  // }, [])

  return (
    <Switch>
      {!isAuthorized && (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <SignIn />
        </Route>
      )}
      <Route path="/logout" component={Logout} />

      {!isAuthorized &&
        /*Redirect to `/auth` when user is not authorized*/
        (`${localStorage.getItem("token")}`.split("||")[1] === "master" ? (
          <Redirect to="/masterAdmin/login" />
        ) : (
          <Redirect to="/auth/login" />
        ))}

      {isAuthorized && !isHotelPresent && (
        <>
          {" "}
          <Route exact path="/hotel-dashboard" component={GroupSidebar} />
          {/* <GroupSidebar /> */}
          <Route
            exact
            path="/hotel-dashboard/group-master-report"
            component={GroupMasterReport}
          />
          <Route
            exact
            path="/hotel-dashboard/group-cm-report"
            component={GroupChannelManagerReport}
          />
          <Route
            exact
            path="/hotel-dashboard/group-sales-report"
            component={GroupSalesReport}
          />
          <Route
            exact
            path="/hotel-dashboard/group-roomrev-report"
            component={GroupRoomRevenueReport}
          />
          <Route
            exact
            path="/hotel-dashboard/group-payments-report"
            component={GroupPaymentsReport}
          />
          <Redirect
            from="*"
            to={{
              pathname: "/hotel-dashboard",
              state: { selectedSection: "hotelDashboard" },
            }}
          />
          {/* <BookingDashboard />{" "} */}
          {/* <Route path="/hotel-dashboard" component={BookingDashboard} />
          <Route path="/accounts-billing" component={AccountsDashBoard} />
          <Route path="/group-reports" component={GroupReportsContainer} />
          <Route path="/group-dashboard" component={GroupDashboard} />
          <Route path="/travel-agents" component={CTAModal} />
          <Redirect from="*" to="/hotel-dashboard" /> */}
          {/* <AuthorisedPages /> */}
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            wid
            // pauseOnHover
          />
        </>
      )}

      {isAuthorized && isHotelPresent && (
        <Layout>
          <BasePage />
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            wid
            // pauseOnHover
          />
        </Layout>
      )}
    </Switch>
  );
}
function mapStateToProps(state) {
  return { data: state.auth };
}

export default connect(mapStateToProps, auth.actions)(Routes);
