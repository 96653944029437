import React from "react";
import Stack from "@mui/material/Stack";

function MockInvoiceWrapper() {
  return (
    <div className="invoiceWrapper">
      <div className="hotelDetailsWrapper">
        <div className="hotelDetails">
          <div className="box w-auto p-2" style={{ minHeight: "5rem", minWidth:"5rem", borderRadius:"50%" }}>
            {" "}
          </div>
          <div className="basicInfo">
            <div
              className="box w-auto p-2"
              style={{ minHeight: "2rem", minWidth:"3.5rem" }}
            >
              {" "}
            </div>
            <div
              className="box w-auto p-2"
              style={{ minHeight: "2rem", minWidth:"3rem" }}
            >
              {" "}
            </div>
          </div>
        </div>
        <div className="folioInfo">
          <div className="box w-auto p-2" style={{ minHeight: "2rem", minWidth:"3rem" }}>
            {" "}
          </div>
          <div className="folioActionWrapper">
            <div
              className="box w-auto p-2"
              style={{ minHeight: "2rem", minWidth:"3rem" }}
            >
              {" "}
            </div>
          </div>
        </div>
      </div>
      <div className="horizontalLine" />
      <div className="customerDetailsWrapper">
        {/* <div className="custDetailHeader">Bill to : </div> */}
        <div
          className="box w-auto p-2"
          style={{ minHeight: "3.5rem", minWidth:"150px" }}
        >
          {" "}
        </div>
        <div className="customerDetail">
          <div className="details">
            <Stack spacing={2}>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  {/* <div className="custSubHeading">Address</div> */}
                  <div className="box w-auto p-2" style={{ minHeight: "5rem" }}>
                    {" "}
                  </div>
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  {/* <div className="custSubHeading">Email</div> */}
                  <div className="box w-auto p-2" style={{ minHeight: "5rem" }}>
                    {" "}
                  </div>
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  {/* <div className="custSubHeading">Phone</div> */}
                  <div className="box w-auto p-2" style={{ minHeight: "5rem" }}>
                    {" "}
                  </div>
                </Stack>
              </div>
            </Stack>
          </div>
          <div className="details">
            <Stack spacing={2}>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  {/* <div className="custSubHeading">Customer identification</div> */}
                  <div
                    className="editCustIdBtn box w-auto p-2 box-shadow-main"
                    style={{ minHeight: "5rem" }}
                  >
                    {" "}
                  </div>
                  {/* <div className="custSubVal">null</div> */}
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  {/* <div className="custSubHeading">Checkin</div> */}
                  <div
                    className="box w-auto p-2 box-shadow-main"
                    style={{ minHeight: "5rem" }}
                  >
                    {" "}
                  </div>
                </Stack>
              </div>
              <div className="detail">
                <Stack spacing={5} direction="row">
                  {/* <div className="custSubHeading">Checkout</div> */}
                  <div
                    className="box w-auto p-2 box-shadow-main"
                    style={{ minHeight: "5rem" }}
                  >
                    {" "}
                  </div>
                </Stack>
              </div>
            </Stack>
          </div>
        </div>
        <div
          className={`mockInvoiceTable d-flex flex-column p-3 justify-content-between box-shadow-main`}
        >
          <div className="d-flex w-100 align-items-center justify-content-between mt-4">
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
          </div>
          <div className="d-flex w-100 align-items-center justify-content-between mt-5">
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
          </div>
          <div className="d-flex w-100 align-items-center justify-content-between mt-5">
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
          </div>
          <div className="d-flex w-100 align-items-center justify-content-between mt-5">
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
            <div className="box w-auto p-2" style={{ minHeight: "2rem" }}>
              {" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MockInvoiceWrapper;
