// ** React Imports
import { Close, ErrorOutline, PriorityHigh } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  tooltipClasses,
  Fab,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import * as yup from "yup";
import { handleSpringDataMutationRequest } from "../../../api";
import { getAvailableRoom } from "../../FlexibleSlot/api/FlexibleSlotApi";
import {
  editBooking,
  getBlockedRooms,
  getCheckinCheckoutSlots,
  getNonBlockedRooms,
  getQuickEditPricePayload,
  getUpgradePricePayload,
  moveReservation,
  swapReservation,
  editReservation,
  getNonBlockedRoomsWithStatus,
  handleGetDoorLockDetails,
} from "../api/ReservationApi";
import {
  customErrorMessage,
  dateFnsFormat,
  dateFormat,
  displaySlots,
  displayTimes,
} from "../constants/Calendar-constants";
import { PriceModel } from "../models/PricesModel";
import CommonSlider from "../../CalendarPage/Views/CommonSlider";
import DimmingDotsLoader from "../../CalendarPage/Views/LoaderCustom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  footer: {
    margin: theme.spacing(3),
    float: "right",
  },
}));

const validationSchema = yup.object({
  booking_start: yup.date(),
  booking_end: yup.date(),
  perNightPrice: yup.number(),
  priceBeforeTax: yup.number(),
  priceAfterTax: yup.number(),
  taxValue: yup.number(),
  numNights: yup.number().nullable(),
  roomTypeId: yup.string().required("Please select a room id"),
  roomName: yup.string(),
});

const GroupDragModalView = (props) => {
  const {
    viewType,
    reload,
    eventData,
    handleAddEventSidebar,
    open,
    addEvent,
    roomInformation,
    moveEvent,
    edit,
    editRes,
    hotelId,
    hotelSlotType,
    hotelCurrency,
    currency,
    reloadagain,
  } = props;

  let [pricingRequestModel, setPricingRequestModel] = useState(
    new PriceModel({})
  );

  const getRoomNames = () => {
    const idset = new Set();
    const data = {};

    const selectedHotel = roomInformation?.filter(
      (item) => item.hotelId === hotelId
    );
    const datas =
      selectedHotel[0].returnInfo?.allRoomReservations?.singleRoomReservations?.forEach(
        (room) => {
          if (!idset.has(room.roomTypeId)) {
            data[room.roomTypeId] = room;
            idset.add(room.roomTypeId);
          }
        }
      );
    return data;
  };
  const roomTypeInfo = getRoomNames();

  let [loadingData, setLoadingData] = useState(false);
  let [load, setLoad] = useState(false);
  const [checkinSlots, setCheckinSlots] = useState([]);
  const [checkoutSlots, setCheckoutSlots] = useState([]);
  const [selectedCheckinSlot, setSelectedCheckinSlot] = useState(0);
  const [selectedCheckoutSlot, setSelectedCheckoutSlot] = useState(0);
  const [allRoomstrue, setallRoomstrue] = useState(false);
  const [roomIds, setRoomsIds] = useState([
    eventData.event.getResources()[0].extendedProps.roomTypeId,
  ]);
  const [nonBlockedRoomWithStatus, setnonBlockedRoomWithStatus] = useState([]);
  const [isSwap, setIsSwap] = useState(false);
  const isUpgrage =
    eventData.oldEvent.startStr === eventData.event.startStr &&
    eventData.oldEvent.endStr === eventData.event.endStr
      ? true
      : false;
  const isMove =
    eventData.event.getResources()[0].id ===
      eventData.oldEvent.getResources()[0].id &&
    eventData.oldEvent.startStr !== eventData.event.startStr &&
    eventData.oldEvent.endStr !== eventData.event.endStr
      ? true
      : false;

  const [showError, setShowError] = useState(false);
  const [blockedRoomList, setblockedRoomList] = useState(null);
  const [remark, setremark] = useState("");

  useEffect(async () => {
    setLoad(true);

    const ss = await fetch(
      `${
        process.env.REACT_APP_SPRING_API_URL
      }core/api/v1/room/get-blocked-rooms?startDate=${moment(
        eventData.event.startStr
      ).format("DD-MM-YYYY HH:mm:ss")}&endDate=${moment(
        eventData.event.endStr
      ).format("DD-MM-YYYY HH:mm:ss")}` +
        "&hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token").includes("master")
            ? localStorage.getItem("token").split("||")[0] + "||master"
            : localStorage.getItem("token"),
        },
      }
    );
    const data = await ss.json();

    // const data = await getBlockedRooms(
    //   moment(eventData.event.startStr).format("DD-MM-YYYY HH:mm:ss"),
    //   moment(eventData.event.endStr).format("DD-MM-YYYY HH:mm:ss")
    // );
    setblockedRoomList(data);
    setLoad(false);
  }, []);

  useEffect(() => {
    if (
      edit === "edit" &&
      isMove === true &&
      (hotelSlotType.toLowerCase() !== "noon" ||
        eventData.event.extendedProps.status.toLowerCase() ===
          "admin_confirmed")
    ) {
      setLoad(true);
      handleAddEventSidebar();
      setLoad(false);
    }
  }, [isMove]);

  console.log("idsa", eventData.event.getResources()[0].id);

  const performCheckin = async (params) => {
    setLoad(true);
    try {
      if (editRes && isMove) {
        // if (blockedRoomList?.includes(eventData.event.getResources()[0].id)) {
        //   toast.error("Cannot swap reservation with blocked rooms");
        //   handleAddEventSidebar();
        // } else {
        let tempParams = {};
        tempParams.bookingId = eventData.event.extendedProps.bookingId;
        tempParams.checkin = moment(params.actual_start).format(dateFormat);
        tempParams.checkout = moment(params.actual_end).format(dateFormat);
        tempParams.editCost = params.bookingAmount;

        try {
          const res = await fetch(
            `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/reservation/edit-booking` +
              "&hotelId=" +
              hotelId +
              "&hotel_id=" +
              hotelId,
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
              },
              body: JSON.stringify(tempParams),
            }
          );
          toast.success("Successfully edited reservation");
          eventData.event.setExtendedProp(
            "actual_start",
            formik.values.booking_start
          );
          eventData.event.setExtendedProp(
            "actual_end",
            formik.values.booking_start
          );
          addEvent({
            start: params.start,
            end: params.end,
          });

          /* XYTRON CALL IS NOT REQUIRED FOR THE EDIT BOOKING */
          // const allResIds =
          //   eventData.event.extendedProps.reservationId.split(" ");
          // console.log(
          //   "eventData.event.extendedProps.status : ",
          //   eventData.event.extendedProps.status,
          //   allResIds
          // );
          // if (eventData.event.extendedProps.status === "ADMIN_CONFIRMED") {
          //   for (let resId of allResIds) {
          //     await handleGetDoorLockDetails({
          //       bookingId: eventData.event.extendedProps.bookingId,
          //       // reservationId: eventData.event.extendedProps.reservationId,
          //       reservationId: resId,
          //       actionRequired: "recreate",
          //     });
          //   }
          // }
        } catch (err) {
          toast.error("Rooms not available. Please re-try some other dates.");
        }
        // fetch(
        //   `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/reservation/edit-booking` +
        //     "&hotelId=" +
        //     hotelId +
        //     "&hotel_id=" +
        //     hotelId,
        //   {
        //     method: "PUT",
        //     headers: {
        //       Accept: "application/json",
        //       "Content-Type": "application/json",
        //       Authorization: localStorage.getItem("token"),
        //     },
        //     body: JSON.stringify(tempParams),
        //   }
        // )
        // .then((res) => {
        //   toast.success("Successfully edited reservation");
        //   eventData.event.setExtendedProp(
        //     "actual_start",
        //     formik.values.booking_start
        //   );
        //   eventData.event.setExtendedProp(
        //     "actual_end",
        //     formik.values.booking_start
        //   );
        //   addEvent({
        //     start: params.start,
        //     end: params.end,
        //   });
        // })
        // .catch((err) => {
        //   toast.error("Rooms not available. Please re-try some other dates.");
        // });

        // try {
        //   const res = await handleSpringDataMutationRequest(
        //     "PUT",
        //     `core/api/v1/reservation/edit-booking`,
        //     tempParams
        //   );

        //   eventData.event.setExtendedProp(
        //     "actual_start",
        //     formik.values.booking_start
        //   );
        //   eventData.event.setExtendedProp(
        //     "actual_end",
        //     formik.values.booking_start
        //   );
        //   addEvent({
        //     start: params.start,
        //     end: params.end,
        //   });

        //   console.log(
        //     "eventData.event.extendedProps.status : ",
        //     eventData.event.extendedProps.status
        //   );
        //   if (eventData.event.extendedProps.status === "ADMIN_CONFIRMED") {
        //     await handleGetDoorLockDetails({
        //       bookingId: eventData.event.extendedProps.bookingId,
        //       reservationId: eventData.event.extendedProps.reservationId,
        //       actionRequired: "recreate",
        //     });
        //   }
        //   toast.success("Successfully edited reservation");
        // } catch (err) {
        //   toast.error("Rooms not available. Please re-try some other dates.");
        // }
        // handleSpringDataMutationRequest(
        //   "PUT",
        //   `core/api/v1/reservation/edit-booking`,
        //   tempParams
        // )
        //   .then((res) => {
        //     toast.success("Successfully edited reservation");
        //     eventData.event.setExtendedProp(
        //       "actual_start",
        //       formik.values.booking_start
        //     );
        //     eventData.event.setExtendedProp(
        //       "actual_end",
        //       formik.values.booking_start
        //     );
        //     addEvent({
        //       start: params.start,
        //       end: params.end,
        //     });
        //   })
        //   .catch((err) => {
        //     toast.error("Rooms not available. Please re-try some other dates.");
        //   });
        // await editBooking(tempParams);
      }
      // }

      if (isUpgrage) {
        if (isSwap)
          if (blockedRoomList?.includes(eventData.event.getResources()[0].id)) {
            toast.error("Cannot swap reservation with blocked rooms");
          } else {
            const ss = await fetch(
              `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/reservation/swapReservation` +
                "?hotelId=" +
                hotelId +
                "&hotel_id=" +
                hotelId,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: localStorage
                    .getItem("token")
                    .includes("master")
                    ? localStorage.getItem("token").split("||")[0] + "||master"
                    : localStorage.getItem("token"),
                },
                body: JSON.stringify({
                  hotelId: hotelId,
                  reservationId: params.reservationId,
                  physicalRoomId: eventData.event.getResources()[0].id,
                  remark: remark,
                }),
              }
            );
            // await handleGetDoorLockDetails({
            //   reservationId: params.reservationId,
            //   bookingId: eventData.event.extendedProps.bookingId,
            //   actionRequired: "bypass",
            // });
            reload();
            handleAddEventSidebar();
            // await swapReservation({
            //   hotelId: hotelId,
            //   reservationId: params.reservationId,
            //   physicalRoomId: eventData.event.getResources()[0].id,
            // });
          }
        else {
          const ss = await fetch(
            `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/reservation/moveReservation?` +
              "&hotelId=" +
              hotelId +
              "&hotel_id=" +
              hotelId,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token").includes("master")
                  ? localStorage.getItem("token").split("||")[0] + "||master"
                  : localStorage.getItem("token"),
              },
              body: JSON.stringify(params),
            }
          );
          // const data = await ss.json();
          // const data = await moveReservation(params);
          await handleGetDoorLockDetails({
            reservationId: params.reservationId,
            bookingId: eventData.event.extendedProps.bookingId,
            actionRequired: "recreate",
          });
          reload();
          handleAddEventSidebar();
        }
        if (props.upgrade !== "upgrade") {
          moveEvent(params.physicalRoomId);
        }
      }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );

      handleAddEventSidebar();
    }
  };

  const fetchUpdatedBookingPricing = async () => {
    try {
      setLoad(true);
      let resp = {};
      if (editRes) {
        resp = await getQuickEditPricePayload(
          pricingRequestModel.getEditBookingparams()
        );
      }
      if (isUpgrage) {
        pricingRequestModel.destRoomTypeId =
          formik.values.roomName === "null" ? null : formik.values.roomName;
        pricingRequestModel.destRoomId = formik.values.roomTypeId;

        const response = await fetch(
          `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/reservation/getUpgradePricePayload` +
            "?hotelId=" +
            hotelId +
            "&hotel_id=" +
            hotelId,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token").includes("master")
                ? localStorage.getItem("token").split("||")[0] + "||master"
                : localStorage.getItem("token"),
            },
            body: JSON.stringify(pricingRequestModel.getUpgradeBookingparams()),
          }
        );

        resp = await response.json();
        // resp = await getUpgradePricePayload(
        //   pricingRequestModel.getUpgradeBookingparams()
        // );
      }

      setData(resp);
      setLoad(false);
    } catch (e) {
      toast.error("Error in getting prices");
    }
  };
  const setData = (resp) => {
    formik.setFieldValue(
      "perNightPrice",
      Number(resp.perNightPrice).toFixed(2)
    );
    formik.setFieldValue(
      "priceBeforeTax",
      Number(resp.priceBeforeTax).toFixed(2)
    );
    formik.setFieldValue(
      "priceAfterTax",
      Number(resp.priceAfterTax).toFixed(2)
    );
    formik.setFieldValue("taxValue", Number(resp.taxValue).toFixed(2));
  };

  const fetchInitialPricing = async (checkin, checkout) => {
    let resp = {};
    const pricesModel = new PriceModel({
      hotelId: hotelId,
      resIds: eventData.event.extendedProps.reservationId,
      checkin: moment(checkin).format(dateFormat),
      checkout: moment(checkout).format(dateFormat),
      newRate: null,
      bookingId: eventData.event.extendedProps.bookingId,
    });
    setPricingRequestModel(pricesModel);
    if (editRes) {
      resp = await getQuickEditPricePayload(pricesModel.getEditBookingparams());
    }
    if (isUpgrage) {
      pricesModel.destRoomTypeId =
        eventData.event.getResources()[0].extendedProps.roomTypeId;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/reservation/getUpgradePricePayload` +
            "?hotelId=" +
            hotelId +
            "&hotel_id=" +
            hotelId,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token").includes("master")
                ? localStorage.getItem("token").split("||")[0] + "||master"
                : localStorage.getItem("token"),
            },
            body: JSON.stringify(pricingRequestModel.getUpgradeBookingparams()),
          }
        );
      } catch {
        toast.error("Error in getting prices");
      }
    }

    setData(resp);
  };

  const setCustomCheckin = (slots, checkoutSlotsData) => {
    const temp_date = new Date(eventData.event.start);
    const temp_date_1 = new Date(eventData.event.end);
    if (slots.length === 1) {
      if (temp_date.getDate() === temp_date_1.getDate()) {
        temp_date_1.setDate(temp_date_1.getDate() + 1);
        // temp_date.setDate(temp_date.getDate() - 1);
      }
      temp_date.setHours(slots[0]);
      temp_date_1.setHours(checkoutSlotsData[0]);
      formik.setFieldValue("booking_start", temp_date);
      formik.setFieldValue("booking_end", temp_date_1);
      setSelectedCheckinSlot(slots[0]);
      setSelectedCheckoutSlot(checkoutSlotsData[0]);
    } else if (slots.length === 3) {
      if (viewType === "resourceTimelineWeek") {
        const slot_number_start =
          displaySlots[eventData.event.start.getHours()];
        const slot_number_end = displaySlots[eventData.event.end.getHours()];
        temp_date.setHours(slots[slot_number_start]);
        temp_date_1.setHours(checkoutSlotsData[slot_number_end]);
        formik.setFieldValue("booking_start", temp_date);
        formik.setFieldValue("booking_end", temp_date_1);
        setSelectedCheckinSlot(slots[slot_number_start]);
        setSelectedCheckoutSlot(checkoutSlotsData[slot_number_end]);
      } else {
        if (temp_date.getDate() === temp_date_1.getDate()) {
          temp_date.setHours(slots[0]);
          temp_date_1.setHours(checkoutSlotsData[checkoutSlotsData.length - 1]);
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(slots[0]);
          setSelectedCheckoutSlot(
            checkoutSlotsData[checkoutSlotsData.length - 1]
          );
        } else {
          temp_date.setHours(slots[0]);
          temp_date_1.setHours(checkoutSlotsData[checkoutSlotsData.length - 1]);
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(slots[0]);
          setSelectedCheckoutSlot(
            checkoutSlotsData[checkoutSlotsData.length - 1]
          );
        }
      }
    } else {
      if (viewType !== "resourceTimelineDay") {
        if (temp_date.getDate() === temp_date_1.getDate()) {
          temp_date.setHours(slots[slots.length / 2 - 1]);
          temp_date_1.setHours(checkoutSlotsData[checkoutSlotsData.length - 2]);
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(slots[slots.length / 2 - 1]);
          setSelectedCheckoutSlot(
            checkoutSlotsData[checkoutSlotsData.length - 2]
          );
        } else {
          temp_date.setHours(slots[slots.length / 2 - 1]);
          temp_date_1.setHours(checkoutSlotsData[checkoutSlotsData.length - 2]);
          formik.setFieldValue("booking_start", temp_date);
          formik.setFieldValue("booking_end", temp_date_1);
          setSelectedCheckinSlot(slots[slots.length / 2 - 1]);
          setSelectedCheckoutSlot(
            checkoutSlotsData[checkoutSlotsData.length - 2]
          );
        }
      } else {
        setSelectedCheckinSlot(temp_date.getHours());
        setSelectedCheckoutSlot(temp_date_1.getHours());
      }
    }
    return { start: temp_date, end: temp_date_1 };
  };

  const fetchCheckinPricing = async () => {
    setLoadingData(true);
    try {
      if (editRes) {
        const slots = await getCheckinCheckoutSlots({
          checkin: moment(eventData.event.start).format(dateFormat),
          checkout:
            hotelSlotType === "NOON"
              ? moment(
                  new Date(eventData.event.end).setDate(
                    eventData.event.end.getDate() + 1
                  )
                ).format(dateFormat)
              : moment(eventData.event.end).format(dateFormat),
        });
        const changed = setCustomCheckin(
          slots.checkinSlots.sort((a, b) => a - b),
          slots.checkoutSlots.sort((a, b) => a - b)
        );
        await fetchInitialPricing(changed.start, changed.end);
        setCheckinSlots(slots.checkinSlots.sort((a, b) => a - b));
        setCheckoutSlots(slots.checkoutSlots.sort((a, b) => a - b));
      } else {
        await fetchInitialPricing(
          formik.values.booking_start,
          formik.values.booking_end
        );
      }

      setLoadingData(false);
    } catch (e) {
      setLoadingData(false);
      setShowError(true);
    }
  };

  useEffect(() => {
    if (isUpgrage) {
      setLoad(true);
      fetchRoomIds();
      setLoad(false);
    }
  }, []);

  const fetchRoomIdsWithPricing = async (roomTypeId) => {
    try {
      setallRoomstrue(false);
      if (roomTypeId !== "null") {
        const response = await fetch(
          `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/room/non-blocked-rooms-with-status?roomTypeId=${roomTypeId}&resId=${eventData.event.extendedProps.reservationId}` +
            "&hotelId=" +
            hotelId +
            "&hotel_id=" +
            hotelId,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token").includes("master")
                ? localStorage.getItem("token").split("||")[0] + "||master"
                : localStorage.getItem("token"),
            },
          }
        );
        const ida = await response.json();

        // let ida = await getNonBlockedRoomsWithStatus(
        //   roomTypeId,
        //   eventData.event.extendedProps.reservationId
        // );
        setnonBlockedRoomWithStatus(ida);
        const data = ida?.map((item) => item.roomId);
        if (!data.includes(formik.values.roomTypeId)) {
          formik.setFieldValue("roomTypeId", "");
        } else {
          formik.setFieldValue(
            "roomTypeId",
            eventData.oldEvent.getResources()[0].id
          );
        }

        setRoomsIds(
          data.filter((id) => id !== eventData.oldEvent.getResources()[0].id)
        );
      } else {
        setallRoomstrue(true);

        const res = await fetch(
          `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/room/non-blocked-rooms?roomTypeId=${roomTypeId}&resId=${eventData.event.extendedProps.reservationId}` +
            "&hotelId=" +
            hotelId +
            "&hotel_id=" +
            hotelId,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token").includes("master")
                ? localStorage.getItem("token").split("||")[0] + "||master"
                : localStorage.getItem("token"),
            },
          }
        );
        const ids = await res.json();
        // const ids = await getNonBlockedRooms(
        //   roomTypeId,
        //   eventData.event.extendedProps.reservationId
        // );

        const idsData = String(ids).split(",");

        if (!idsData.includes(formik.values.roomTypeId))
          formik.setFieldValue("roomTypeId", "");

        setRoomsIds(
          idsData.filter((id) => id !== eventData.oldEvent.getResources()[0].id)
        );
        // const data = await getAvailableRoom(null);
        // setRoomsIds(data.split(","));
        // formik.setFieldValue("roomTypeId", data.split(",")[0]);
      }
    } catch (e) {
      setRoomsIds([]);
      formik.setFieldValue("roomTypeId", "");
    }
  };

  const fetchRoomIds = async () => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }core/api/v1/room/non-blocked-rooms-with-status?roomTypeId=${
          eventData.event.getResources()[0].extendedProps.roomTypeId
        }&resId=${eventData.event.extendedProps.reservationId}` +
          "&hotelId=" +
          hotelId +
          "&hotel_id=" +
          hotelId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token").includes("master")
              ? localStorage.getItem("token").split("||")[0] + "||master"
              : localStorage.getItem("token"),
          },
        }
      );
      const ida = await response.json();

      // let ida = await getNonBlockedRoomsWithStatus(
      //   eventData.event.getResources()[0].extendedProps.roomTypeId,
      //   eventData.event.extendedProps.reservationId
      // );
      setnonBlockedRoomWithStatus(ida);
      const data = ida?.map((item) => item.roomId);

      formik.setFieldValue(
        "roomTypeId",
        eventData.oldEvent.getResources()[0].id
      );

      const res = await fetch(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }core/api/v1/room/non-blocked-rooms?roomTypeId=${
          eventData.event.getResources()[0].extendedProps.roomTypeId
        }&resId=${eventData.event.extendedProps.reservationId}` +
          "&hotelId=" +
          hotelId +
          "&hotel_id=" +
          hotelId,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token").includes("master")
              ? localStorage.getItem("token").split("||")[0] + "||master"
              : localStorage.getItem("token"),
          },
        }
      );
      const ids = await res.json();
      // const ids = await getNonBlockedRooms(
      //   eventData.event.getResources()[0].extendedProps.roomTypeId,
      //   eventData.event.extendedProps.reservationId
      // );
      const idsData = String(ids).split(",");
      if (!data.includes(eventData.event.getResources()[0].id))
        formik.setFieldValue("roomTypeId", "");

      setRoomsIds(
        data.filter((id) => id !== eventData.oldEvent.getResources()[0].id)
      );
      formik.setFieldValue(
        "roomTypeId",
        eventData.oldEvent.getResources()[0].id
      );
    } catch (e) {
      setRoomsIds([]);
      formik.setFieldValue("roomTypeId", "");
    }
  };

  const classes = useStyles();

  useEffect(() => {
    setLoad(true);
    fetchCheckinPricing();
    setLoad(false);
  }, []);

  const checkDateSelectionCheckin = async (checkinDate, isTime) => {
    const tempo = new Date(checkinDate);
    try {
      setLoad(true);
      if (isTime) formik.setFieldValue("booking_start", checkinDate);
      else await changeSlots(checkinDate, "booking_start");
      if (tempo > formik.values.booking_end) {
        const new_end_date = new Date(
          new Date(checkinDate).getTime() + 86400000
        );

        formik.setFieldValue("booking_end", new_end_date);
        pricingRequestModel.checkout = moment(new_end_date).format(dateFormat);
      }
      pricingRequestModel.checkin = moment(checkinDate).format(dateFormat);

      pricingRequestModel.resetParams();
      fetchUpdatedBookingPricing();
      setLoad(false);
      delete formik.errors.booking_start;
      delete formik.errors.booking_end;
    } catch (e) {
      formik.setFieldError("booking_start", "Room selection not available");
      formik.setFieldError("booking_end", "Room selection not available");
    }
  };

  const checkDateSelectionCheckout = async (checkoutDate, isTime) => {
    try {
      setLoad(true);
      if (isTime) formik.setFieldValue("booking_end", checkoutDate);
      else await changeSlots(checkoutDate, "booking_end");
      pricingRequestModel.checkout = moment(checkoutDate).format(dateFormat);
      pricingRequestModel.resetParams();
      fetchUpdatedBookingPricing();
      setLoad(false);
      delete formik.errors.booking_start;
      delete formik.errors.booking_end;
    } catch (e) {
      formik.setFieldError("booking_end", "Room selection not available");
      formik.setFieldError("booking_start", "Room selection not available");
    }
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      booking_start: new Date(eventData.event.extendedProps.actual_start),
      booking_end: new Date(eventData.event.extendedProps.actual_end),
      perNightPrice: 0,
      priceBeforeTax: 0,
      priceAfterTax: 0,
      taxValue: 0,
      roomTypeId: eventData.event.getResources()[0].id,
      roomName: eventData.event.getResources()[0].extendedProps.roomTypeId,
      udpated_start_date: new Date(eventData.event.start),
    },
    validate: () => {
      let errors = {};
      if (formik.errors.booking_start)
        errors.booking_start = formik.errors.booking_start;
      if (formik.errors.booking_end)
        errors.booking_end = formik.errors.booking_end;

      return errors;
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var temporary = {};
      if (editRes && isMove) {
        temporary = {
          start: getCustomDate().start,
          end: getCustomDate().end,
          roomNumber: eventData.event.getResources()[0].id,
          bookingAmount: values.priceAfterTax,
          hotelId: hotelId,
          actual_end: values.booking_end,
          actual_start: values.booking_start,
        };
      }
      if (isUpgrage) {
        temporary = {
          reservationId: eventData.event.extendedProps.reservationId,
          physicalRoomId: values.roomTypeId,
          hotelId: hotelId,
          destRoomTypeId: values.roomName,
          moveCost: values.priceAfterTax,
          remark: remark,
        };
      }

      temporary = performCheckin(temporary);
      // window.location.reload();
    },
  });

  const changeSlots = async (date, type) => {
    const slots = await getCheckinCheckoutSlots({
      checkin:
        type === "booking_start"
          ? moment(date).format(dateFormat)
          : moment(formik.values.booking_start).format(dateFormat),
      checkout:
        type === "booking_start"
          ? moment(formik.values.booking_end).format(dateFormat)
          : moment(date).format(dateFormat),
    });
    if (type === "booking_start") {
      let index_1 = checkinSlots.indexOf(selectedCheckinSlot);
      let arr_1 = slots.checkinSlots.sort((a, b) => a - b);
      const temp_date = date;
      temp_date.setHours(arr_1[index_1]);
      formik.setFieldValue("booking_start", temp_date);
      setCheckinSlots(arr_1);
      setSelectedCheckinSlot(arr_1[index_1]);
    } else {
      let index = checkoutSlots.indexOf(selectedCheckoutSlot);
      let arr = slots.checkoutSlots.sort((a, b) => a - b);
      const temp_date_1 = date;
      temp_date_1.setHours(arr[index]);
      formik.setFieldValue("booking_end", temp_date_1);
      setCheckoutSlots(arr);
      setSelectedCheckoutSlot(arr[index]);
    }
  };

  const getCustomDate = () => {
    const date1 = new Date(formik.values.booking_start);
    const date2 = new Date(formik.values.booking_end);
    if (viewType === "resourceTimelineWeek") {
      if (checkinSlots.length === 1) {
        date1.setHours(displayTimes[1]);
        date2.setHours(displayTimes[1]);
      } else if (checkinSlots.length === 3) {
        const slot1 = checkinSlots.indexOf(selectedCheckinSlot);
        const slot2 = checkoutSlots.indexOf(selectedCheckoutSlot);
        date1.setHours(displayTimes[slot1]);
        date2.setHours(displayTimes[slot2]);
      }
    }
    return { start: date1, end: date2 };
  };

  // Edit reservation on dragging
  const editResOnDrag = async () => {
    setLoad(true);

    const ss = await fetch(
      `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/reservation/edit-reservation` +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token").includes("master")
            ? localStorage.getItem("token").split("||")[0] + "||master"
            : localStorage.getItem("token"),
        },
        body: JSON.stringify({
          physicalRoomId: String(eventData.event?.getResources()[0]?.id),
          reservationId: String(eventData?.event?.extendedProps?.reservationId),
          startDate: moment(formik.values.udpated_start_date).format(
            "DD-MM-YYYY"
          ),
        }),
      }
    );
    // const data = await editReservation({
    //   physicalRoomId: String(eventData.event?.getResources()[0]?.id),
    //   reservationId: String(eventData?.event?.extendedProps?.reservationId),
    //   startDate: moment(formik.values.udpated_start_date).format("DD-MM-YYYY"),
    // });
    setLoad(false);
    addEvent({
      start: new Date(eventData.event.start),
      end: new Date(eventData.event.end),
    });
    reload();
  };

  const [checkinDates, setcheckinDates] = useState(new Date());
  const [checkoutDates, setcheckoutDates] = useState(new Date());

  useEffect(() => {
    setcheckinDates(moment(formik.values.booking_start).format("YYYY-MM-DD"));
    setcheckoutDates(moment(formik.values.booking_end).format("YYYY-MM-DD"));
  }, [formik.values.booking_end, formik.values.booking_start]);

  console.log("formik.values.roomTypeId", formik.values.roomTypeId);

  console.log(
    "eventData.event.getResources()[0].id",
    eventData.event.getResources()[0].id
  );
  console.log("formik.values.roomTypeId", formik.values.roomTypeId);
  useEffect(() => {
    if (
      formik.values.roomTypeId === null ||
      formik.values.roomTypeId === undefined ||
      formik.values.roomTypeId === ""
    ) {
      formik.setFieldValue("roomTypeId", eventData.event.getResources()[0].id);
      fetchUpdatedBookingPricing();
    } else if (
      formik.values.roomTypeId !== eventData.event.getResources()[0].id
    ) {
    }
  }, [formik.values.roomTypeId]);

  return (
    <div className={classes.root}>
      <CommonSlider open={open} onClose={handleAddEventSidebar}>
        <Grid container sx={{ height: "100%", width: "100%" }}>
          <Grid container height={"max-content"} className={classes.textField}>
            <Grid item xs={10} alignSelf="center">
              <Typography variant="h5">
                {(editRes || isMove) && "Edit Reservation"}
                {isUpgrage && "Move Reservation"}
                {}
              </Typography>
              {blockedRoomList?.includes(
                eventData.event.getResources()[0].id
              ) &&
                editRes === undefined &&
                isMove && (
                  <Typography className="mt-4" style={{ color: "red" }}>
                    Swapping with blocked rooms is restricted.
                  </Typography>
                )}
            </Grid>
            <Grid item xs={2}>
              <IconButton
                sx={{ float: "right" }}
                onClick={handleAddEventSidebar}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          {loadingData && (
            <Grid
              sx={{ width: "100%", height: "max-content" }}
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <DimmingDotsLoader />
            </Grid>
          )}
          {editRes || isUpgrage ? (
            !showError &&
            !loadingData && (
              <form
                noValidate
                style={{ height: "inherit" }}
                onSubmit={formik.handleSubmit}
              >
                {isUpgrage && (
                  <Grid container className={classes.textField} spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="Previous room id"
                        value={eventData.oldEvent.getResources()[0].id}
                      />
                    </Grid>
                    {isSwap && (
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                          fullWidth
                          disabled
                          label="Changed room id"
                          value={eventData.event.getResources()[0].id}
                        />
                      </Grid>
                    )}
                    {!isSwap && (
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                          fullWidth
                          select
                          disabled={isSwap}
                          label="Changed room id"
                          required
                          value={formik.values.roomTypeId}
                          disabled={load ? true : false}
                          error={
                            formik.touched.roomTypeId &&
                            Boolean(formik.errors.roomTypeId)
                          }
                          helperText={
                            formik.touched.roomTypeId &&
                            formik.errors.roomTypeId
                          }
                          onChange={(e) => {
                            formik.setFieldValue("roomTypeId", e.target.value);
                            pricingRequestModel.resetParams();
                            if (!isSwap) fetchUpdatedBookingPricing();
                          }}
                        >
                          {allRoomstrue
                            ? roomIds &&
                              roomIds.map((id) => (
                                <MenuItem key={id} value={id}>
                                  {id}
                                </MenuItem>
                              ))
                            : nonBlockedRoomWithStatus &&
                              nonBlockedRoomWithStatus.map((id) => (
                                <MenuItem
                                  key={id?.roomId}
                                  value={id?.roomId}
                                  style={{
                                    color: `${
                                      id?.dirtyStatus === "CLEAN"
                                        ? "#1BC5BD"
                                        : "#B96E6E"
                                    }`,
                                    fontWeight: "bold",
                                  }}
                                >
                                  <Stack
                                    direction={"row"}
                                    spacing={2}
                                    // style={{ display: "flex", justifyContent: "space-between" }}
                                  >
                                    <Typography sx={{ color: "black" }}>
                                      {id.roomId}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        color: `${
                                          id?.dirtyStatus === "CLEAN"
                                            ? "#1BC5BD"
                                            : "#B96E6E"
                                        }`,
                                      }}
                                    >
                                      {id.dirtyStatus}
                                    </Typography>
                                  </Stack>
                                </MenuItem>
                              ))}
                        </TextField>
                      </Grid>
                    )}
                    {!isSwap && (
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                          disabled
                          fullWidth
                          label="Previous room type"
                          value={
                            eventData?.oldEvent?.getResources()[0]
                              ?.extendedProps?.roomType
                          }
                        />
                      </Grid>
                    )}
                    {!isSwap && (
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                          select
                          fullWidth
                          label="Changed room type"
                          value={formik.values.roomName}
                          disabled={load ? true : false}
                          onChange={(e) => {
                            formik.setFieldValue("roomName", e.target.value);

                            pricingRequestModel.destRoomTypeId =
                              e.target.value === "null" ? null : e.target.value;
                            fetchRoomIdsWithPricing(e.target.value);
                          }}
                        >
                          <MenuItem value={"null"}> All </MenuItem>
                          {Object.keys(roomTypeInfo).map((room) => (
                            <MenuItem key={room} value={room}>
                              {" "}
                              {roomTypeInfo[room].roomTypeName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                    {isUpgrage && (
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => {
                                if (isSwap)
                                  formik.setFieldValue("roomTypeId", "");
                                else
                                  formik.setFieldValue(
                                    "roomTypeId",
                                    eventData.event.getResources()[0].id
                                  );
                                setIsSwap(!isSwap);
                              }}
                              checked={isSwap}
                            />
                          }
                          label={"Swap"}
                        ></FormControlLabel>
                      </Grid>
                    )}
                  </Grid>
                )}
                {editRes && (
                  <Grid container spacing={2} className={classes.textField}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid item xs={8} sm={8} md={8} lg={10} xl={10}>
                        <DatePicker
                          inputFormat={dateFnsFormat}
                          fullWidth
                          disableMaskedInput
                          id="booking_start"
                          name="booking_start"
                          className={classes.textField}
                          value={formik.values.booking_start}
                          label="Checkin"
                          onChange={checkDateSelectionCheckin}
                          onBlur={formik.handleBlur}
                          disabled={
                            eventData.event.extendedProps.status !==
                            "ADMIN_CONFIRMED"
                              ? load
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => {
                            params.error = Boolean(formik.errors.booking_start);
                            return (
                              <TextField
                                required
                                helperText={formik.errors.booking_start}
                                fullWidth
                                {...params}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                        <TextField
                          select
                          value={selectedCheckinSlot}
                          onChange={(e) => {
                            const temp = formik.values.booking_start;
                            temp.setHours(
                              e.target.value === 24 ? 0 : e.target.value
                            );
                            setSelectedCheckinSlot(e.target.value);
                            checkDateSelectionCheckin(temp, true);
                          }}
                          label="Time"
                          fullWidth
                          disabled={
                            eventData.event.extendedProps.status ===
                            "ADMIN_CONFIRMED"
                              ? load
                                ? true
                                : false
                              : true
                          }
                        >
                          {checkinSlots.map((slot) => (
                            <MenuItem
                              key={slot}
                              value={slot}
                              disabled={
                                eventData.event.extendedProps.status ===
                                "ADMIN_CONFIRMED"
                              }
                            >
                              {`${slot > 12 ? slot - 12 : slot} ${
                                (slot - 12) / 12 >= 0 && (slot - 12) / 12 !== 1
                                  ? "PM"
                                  : "AM"
                              }`}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={10} xl={10}>
                        <DatePicker
                          fullWidth
                          id="booking_end"
                          mask="____ ___, ____"
                          disableMaskedInput
                          name="booking_end"
                          className={classes.textField}
                          minDate={formik.values.booking_start}
                          placeholder="Booking End Date"
                          value={formik.values.booking_end}
                          label={`Checkout ( ${moment(checkoutDates).diff(
                            checkinDates,
                            "days"
                          )} Nights )`}
                          variant="inline"
                          autoOk={true}
                          inputFormat={dateFnsFormat}
                          onChange={checkDateSelectionCheckout}
                          onBlur={formik.handleBlur}
                          disabled={load ? true : false}
                          renderInput={(params) => {
                            params.error = Boolean(formik.errors.booking_end);
                            return (
                              <TextField
                                required
                                helperText={formik.errors.booking_end}
                                fullWidth
                                {...params}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                        <TextField
                          select
                          value={selectedCheckoutSlot}
                          onChange={(e) => {
                            const temp = formik.values.booking_end;
                            temp.setHours(e.target.value);
                            setSelectedCheckoutSlot(
                              e.target.value === 24 ? 0 : e.target.value
                            );
                            checkDateSelectionCheckout(temp, true);
                          }}
                          label="Time"
                          fullWidth
                          disabled={load ? true : false}
                        >
                          {checkoutSlots.map((slot) => (
                            <MenuItem key={slot} value={slot}>
                              {`${slot > 12 ? slot - 12 : slot} ${
                                (slot - 12) / 12 >= 0 && (slot - 12) / 12 !== 1
                                  ? "PM"
                                  : "AM"
                              }`}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </LocalizationProvider>
                  </Grid>
                )}
                {!isSwap && (
                  <Grid container className={classes.textField} spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        required
                        name="perNightPrice"
                        id="perNightPrice"
                        error={
                          formik.touched.perNightPrice &&
                          Boolean(formik.errors.perNightPrice)
                        }
                        helperText={
                          formik.touched.perNightPrice &&
                          formik.errors.perNightPrice
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currency}
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Per night excluding taxes"
                        type="number"
                        value={formik.values.perNightPrice}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.setFieldValue("perNightPrice", e.target.value);
                          setTimeout(() => {
                            if (
                              e.target.value !== "" &&
                              e.target.value !== null
                            ) {
                              pricingRequestModel.newRate = e.target.value;
                              pricingRequestModel.newRateType =
                                "PER_NIGHT_PRETAX";
                              fetchUpdatedBookingPricing();
                            }
                          }, 2000);
                        }}
                        disabled={load ? true : false}
                        label="Per night excluding taxes"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        required
                        name="priceBeforeTax"
                        id="priceBeforeTax"
                        error={
                          formik.touched.priceBeforeTax &&
                          Boolean(formik.errors.priceBeforeTax)
                        }
                        helperText={
                          formik.touched.priceBeforeTax &&
                          formik.errors.priceBeforeTax
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currency}
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Total excluding tax"
                        type="number"
                        value={formik.values.priceBeforeTax}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "priceBeforeTax",
                            e.target.value
                          );
                          setTimeout(() => {
                            if (
                              e.target.value !== "" &&
                              e.target.value !== null
                            ) {
                              pricingRequestModel.newRate = e.target.value;
                              pricingRequestModel.newRateType = "TOTAL_PRETAX";
                              fetchUpdatedBookingPricing();
                            }
                          }, 2000);
                        }}
                        label="Total excluding tax"
                        disabled={load ? true : false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <TextField
                        fullWidth
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currency}
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                        name="taxValue"
                        id="taxValue"
                        placeholder="Total tax"
                        disabled
                        type="number"
                        value={formik.values.taxValue}
                        label="Total tax"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <TextField
                        fullWidth
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currency}
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                        name="priceAfterTax"
                        id="priceAfterTax"
                        placeholder="Total with tax"
                        type="number"
                        error={Boolean(formik.errors.priceAfterTax)}
                        // helperText={formik.errors.priceAfterTax}
                        value={formik.values.priceAfterTax}
                        disabled={load ? true : false}
                        onChange={(e) => {
                          formik.setFieldValue("priceAfterTax", e.target.value);
                          setTimeout(() => {
                            if (
                              e.target.value !== "" &&
                              e.target.value !== null
                            ) {
                              pricingRequestModel.newRate = e.target.value;
                              pricingRequestModel.newRateType = "TOTAL_WITHTAX";
                              fetchUpdatedBookingPricing();
                            }
                          }, 2000);
                        }}
                        onBlur={formik.handleBlur}
                        label="Total with tax"
                      />
                    </Grid>
                  </Grid>
                )}
                <Divider />
                {editRes && (
                  <Typography
                    component={"div"}
                    sx={{ padding: 2, display: "flex" }}
                  >
                    <PriorityHigh color="primary" />
                    <Typography variant="subtitle2" color={"#888888"}>
                      If it is a group booking all the reservations will be
                      moved to the selected date and time
                    </Typography>
                  </Typography>
                )}
                {isUpgrage && (
                  <Grid
                    container
                    spacing={2}
                    className={classes.textField}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      fullWidth
                      style={{ marginLeft: "1.2rem" }}
                      onChange={(e) => setremark(e.target.value)}
                      label="Remark"
                      value={remark}
                    />
                  </Grid>
                )}
                <div>
                  <Fab
                    className={classes.footer}
                    type="submit"
                    disabled={load || loadingData}
                    variant="extended"
                    color="primary"
                  >
                    {editRes && "Edit reservation"}
                    {isUpgrage && "Move reservation"}
                    {load && (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    )}
                  </Fab>
                </div>
              </form>
            )
          ) : (
            <Stack sx={{ width: "100%", height: "inherit" }}>
              <form
                noValidate
                style={{ height: "inherit" }}
                onSubmit={formik.handleSubmit}
              >
                <Grid container className={classes.textField} spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      disabled
                      label="Room id"
                      value={eventData.event?.getResources()[0]?.id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid item xs={8} sm={8} md={8} lg={10} xl={10}>
                        <DatePicker
                          fullWidth
                          id="booking_end"
                          mask="____ ___, ____"
                          disableMaskedInput
                          name="booking_end"
                          className={classes.textField}
                          placeholder="Booking End Date"
                          value={formik.values.udpated_start_date}
                          label="Date"
                          variant="inline"
                          autoOk={true}
                          inputFormat={dateFnsFormat}
                          onBlur={formik.handleBlur}
                          disabled={true}
                          renderInput={(params) => {
                            params.error = Boolean(
                              formik.errors.udpated_start_date
                            );
                            return (
                              <TextField
                                required
                                helperText={formik.errors.udpated_start_date}
                                fullWidth
                                {...params}
                              />
                            );
                          }}
                        />
                      </Grid>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Divider />
                <div>
                  <Fab
                    className={classes.footer}
                    onClick={() => editResOnDrag()}
                    // type="submit"
                    disabled={
                      blockedRoomList === null ||
                      load ||
                      loadingData ||
                      (blockedRoomList?.includes(
                        eventData.event.getResources()[0].id
                      ) &&
                        editRes === undefined &&
                        isMove)
                    }
                    variant="extended"
                    color="primary"
                  >
                    {editRes === undefined && isMove && "Edit reservation"}
                    {load && (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    )}
                  </Fab>
                </div>
              </form>
            </Stack>
          )}
          {showError && (
            <Grid
              sx={{ width: "100%", height: "max-content" }}
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <ErrorOutline sx={{ width: "50%", height: "50%" }} color="error">
                {/* <Grid item >
                <Typography> These was some error please try again !</Typography>
              </Grid> */}
              </ErrorOutline>
              <Grid item>
                <Typography>
                  {" "}
                  These was some error please try again !
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default GroupDragModalView;
